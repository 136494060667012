import React from 'react';
import { Container, Button, Input, Title, ErrorMessage } from './styles';
import { useLogin } from '../../hooks';
import { LogIn } from 'lucide-react';

export const LoginMobile = () => {
  // Get login hook data with mobile-specific redirect path
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    isLoading,
    handleLogin
  } = useLogin('/mobile/operator');

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Container>
      <Title>Welcome Back!</Title>
      <Input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Username"
        onKeyPress={handleKeyPress}
        disabled={isLoading}
      />
      <Input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password"
        onKeyPress={handleKeyPress}
        disabled={isLoading}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button 
        onClick={handleLogin}
        disabled={isLoading}
      >
        {isLoading ? (
          'Logging in...'
        ) : (
          <>
            <LogIn style={{ marginRight: '8px' }} />
            Login
          </>
        )}
      </Button>
    </Container>
  );
};

export default LoginMobile;

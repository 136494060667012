import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const DateHeader = styled.h3`
  margin: 0 0 0.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${theme.colors.border.light};
  color: ${theme.colors.text.primary};
  font-size: 1rem;
  font-weight: 600;
`;

export const TimelineList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const TimelineItem = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.75rem;
  background-color: ${theme.colors.background.default};
  border-radius: ${theme.borderRadius.md};
  border-left: 4px solid ${props => props.color || theme.colors.primary.main};
  box-shadow: ${theme.shadows.xs};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${theme.colors.background.hover};
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.sm};
  }
`;

export const EventTime = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${theme.colors.text.secondary};
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const EventContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const EventType = styled.div`
  font-weight: 600;
  color: ${props => props.color || theme.colors.text.primary};
`;

export const EventDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  font-size: 0.875rem;
  color: ${theme.colors.text.secondary};

  span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const UserInfo = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const PalletInfo = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${theme.colors.primary.main};
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const NoEvents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: ${theme.colors.text.secondary};
  font-style: italic;
`;

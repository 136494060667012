import React, { useState, useEffect } from 'react';
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import { useProcessing } from '@lib/contexts';
import { useToast } from '@lib/contexts';
import { usePwaStatus } from '@lib/hooks/usePwaStatus';
import { Clipboard } from 'lucide-react';
import ScanTab from '@features/shared/components/ScanTab';
import { DownloadPage } from '@features/shared/components/DownloadPage';
import KleinpakDashboard from '../../KleinpakDashboard';
import { PROCESS_STAGES } from '@features/shared/constants/processStages';
import {
  handleShipPallet,
  handleDeletePallet,
  handleAssignOrder,
  handleReturnPallet,
  handleSplitPallet
} from '@features/shared/services/palletService';
import { putPalletIntoProduction } from '@features/shared/services/productionService';
import MobileOrderAssignmentModal from '../../MobileOrderAssignmentModal';
import { api } from '@lib/api';
import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton,
  Message,
  Subtitle,
  InfoCard,
  ProductionForm,
  Label,
  Input,
  Button,
  DashboardLink
} from './styles';

export const KleinpakConsole = () => {
  const { locationId, userId } = useOutletContext();
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { isStandalone } = usePwaStatus();
  const [activeMenu, setActiveMenu] = useState('overview');
  
  // Check for tab query parameter to set active tab
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam && ['overview', 'scan', 'production', 'download'].includes(tabParam)) {
      setActiveMenu(tabParam);
    }
  }, [location.search]);
  const [selectedPalletIds, setSelectedPalletIds] = useState([]);
  const [lineNumber, setLineNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for order assignment modal
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [palletIdsToAssign, setPalletIdsToAssign] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersError, setOrdersError] = useState(null);

  // Fetch orders for the order assignment modal
  const fetchOrders = async () => {
    if (!locationId) {
      setOrdersError('Location ID is missing');
      return;
    }

    setOrdersLoading(true);
    setOrdersError(null);

    try {
      const response = await api.get(`/api/orders/location/${locationId}?isKleinpakOrder=true`);
      if (Array.isArray(response.data)) {
        setOrders(response.data);
      } else {
        console.warn('API response is not an array:', response.data);
        setOrders([]);
        setOrdersError('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrdersError(error.message || 'Failed to load orders');
      setOrders([]);
    } finally {
      setOrdersLoading(false);
    }
  };

  // Pallet action handlers
  const handleShipPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Shipping pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleShipPallet(id, locationId)));
      showToast({ message: 'Pallets shipped successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error shipping pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleDeletePallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Deleting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleDeletePallet(id, locationId)));
      showToast({ message: 'Pallets deleted successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error deleting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleAssignPalletsToOrder = async (palletIds) => {
    // Store the pallet IDs to assign
    setPalletIdsToAssign(palletIds);
    
    // Fetch orders before opening the modal
    showProcessing({ message: 'Loading orders...', showOverlay: true });
    try {
      await fetchOrders();
      
      // Check if we have orders before opening the modal
      if (orders.length === 0 && !ordersError) {
        showToast({ 
          message: 'No active orders available for assignment. Create a new order first.', 
          variant: 'warning' 
        });
        return;
      }
      
      // Open the order assignment modal
      setIsOrderModalOpen(true);
    } catch (error) {
      showToast({ 
        message: `Error loading orders: ${error.message}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
    }
  };

  const handleOrderAssignment = async (orderId, extraBoxCount) => {
    showProcessing({ message: 'Assigning pallets to order...', showOverlay: true });
    try {
      // Use the stored pallet IDs to assign to the selected order
      await Promise.all(palletIdsToAssign.map(id => handleAssignOrder(id, orderId)));
      
      // Also associate the current operator with this order
      console.log(`Associating operator ${userId} with order ${orderId}`);
      await api.post(`/api/orders/${orderId}/operator`, { 
        operatorId: userId 
      });
      
      // Refresh dashboard after assignment
      setTimeout(() => {
        // Force reload dashboard after a short delay to let the server process the changes
        window.location.href = `/mobile/operator/kleinpak?tab=overview`;
      }, 1000);
      
      showToast({ message: 'Pallets assigned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error assigning pallets: ${error.message}`, variant: 'error' });
      throw error; // Re-throw to let the modal handle the error
    } finally {
      hideProcessing();
    }
  };

  const handleReturnPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Returning pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleReturnPallet(id, locationId)));
      showToast({ message: 'Pallets returned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error returning pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleSplitPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Splitting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleSplitPallet(id, locationId)));
      showToast({ message: 'Pallets split successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error splitting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handlePutIntoProduction = async (palletIds) => {
    setSelectedPalletIds(palletIds);
    setActiveMenu('production');
  };
  
  // Helper function to set the console type for pallets
  const setConsoleTypeForPallets = (palletIds) => {
    // This function would be used to update pallets with the 'kleinpak' console type
    // when they are processed in this console
    return palletIds.map(id => ({
      id,
      consoleType: 'kleinpak',
      // Default to unprocessed stage when first handled in Kleinpak console
      processStage: PROCESS_STAGES.UNPROCESSED
    }));
  };

  const handleProductionSubmit = async (e) => {
    e.preventDefault();
    
    try {
      showProcessing({ message: 'Adding pallets to production...', showOverlay: true });
      await Promise.all(
        selectedPalletIds.map(id => 
          putPalletIntoProduction(id, locationId, parseInt(lineNumber))
        )
      );
      showToast({ message: 'Pallets added to production successfully', variant: 'success' });
      setLineNumber('');
      setSelectedPalletIds([]);
      setActiveMenu('overview');
    } catch (error) {
      showToast({ message: `Error adding pallets to production: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleRetry = () => {
    setError(null);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'overview':
        return <KleinpakDashboard />;
      case 'scan':
        return (
          <ScanTab
            // Only pass the handlers we want to keep for kleinpak
            onShipPallet={null}
            onDeletePallet={null}
            onAssignOrder={handleAssignPalletsToOrder}
            onReturnPallet={null}
            onSplitPallet={null}
            onPutIntoProduction={handlePutIntoProduction}
            locationId={locationId}
            consoleType="kleinpak"
          />
        );
      case 'production':
        return (
          <>
            <Message>Add to Production Line</Message>
            <ProductionForm onSubmit={handleProductionSubmit}>
              <Label>
                Production Line Number:
                <Input
                  type="number"
                  min="1"
                  value={lineNumber}
                  onChange={(e) => setLineNumber(e.target.value)}
                  required
                  placeholder="Enter line number"
                />
              </Label>
              <Button type="submit" disabled={!lineNumber}>
                Add to Production
              </Button>
            </ProductionForm>
          </>
        );
      case 'download':
        return <DownloadPage />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container>
        <ScrollableMenu>
          {['overview', 'scan', ...(selectedPalletIds.length > 0 ? ['production'] : [])].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <LoadingContainer>
          <LoadingSpinner />
          <div>Loading console data...</div>
        </LoadingContainer>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ScrollableMenu>
          {['overview', 'scan', ...(selectedPalletIds.length > 0 ? ['production'] : [])].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <ContentArea>
          <ErrorContainer>
            <div>Error: {error}</div>
            <RetryButton onClick={handleRetry}>
              Retry
            </RetryButton>
          </ErrorContainer>
        </ContentArea>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollableMenu>
        <MenuButton
          active={activeMenu === 'overview'}
          onClick={() => setActiveMenu('overview')}
        >
          Overview
        </MenuButton>
        <MenuButton
          active={activeMenu === 'scan'}
          onClick={() => setActiveMenu('scan')}
        >
          Scan
        </MenuButton>
        {selectedPalletIds.length > 0 && (
          <MenuButton
            active={activeMenu === 'production'}
            onClick={() => setActiveMenu('production')}
          >
            Production
          </MenuButton>
        )}
        {!isStandalone && (
          <MenuButton
            active={activeMenu === 'download'}
            onClick={() => setActiveMenu('download')}
          >
            Download
          </MenuButton>
        )}
      </ScrollableMenu>
      
      <ContentArea>
        {renderContent()}
      </ContentArea>

      {/* Order Assignment Modal */}
      <MobileOrderAssignmentModal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        onAssign={handleOrderAssignment}
        locationId={locationId}
        preloadedOrders={orders}
        ordersLoading={ordersLoading}
        ordersError={ordersError}
      />
    </Container>
  );
};

export default KleinpakConsole;

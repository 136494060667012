import { useCallback, useState } from 'react';
import { api } from '@lib/api';
import { useToast } from '@lib/contexts';
import { useLoading } from '@lib/contexts';
import { analyticsService } from '@features/analytics/services';

export const useOrderHandlers = ({
  selectedLocation,
  onAsyncAction,
  loadOrders,
  loadPallets,
  orders,
  pallets,
  setShowAddOrder
}) => {
  const { showToast } = useToast();
  const { showLoading, hideLoading } = useLoading();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedPalletForAssignment, setSelectedPalletForAssignment] = useState(null);

  const handleAssignOrder = useCallback(async (palletId) => {
    const activeOrders = orders.filter(order => !order.is_completed);
    
    // Only show the modal if there are active orders
    if (activeOrders.length > 0) {
      if (Array.isArray(palletId)) {
        // For batch operations, include full pallet data
        const selectedPallets = pallets.filter(p => palletId.includes(p.id));
        if (selectedPallets.length === 0) {
          showToast({
            message: 'No valid pallets selected for assignment',
            variant: 'error'
          });
          return;
        }
        setSelectedPalletForAssignment({
          id: palletId,
          isBatch: true,
          pallets: selectedPallets
        });
      } else {
        // For single pallet, find and pass the complete pallet object
        const palletData = pallets.find(p => p.id === palletId);
        if (!palletData) {
          showToast({
            message: 'Selected pallet not found',
            variant: 'error'
          });
          return;
        }
        // Don't set isBatch for single pallet assignments
        setSelectedPalletForAssignment(palletData);
      }
      setShowAssignModal(true);
    } else {
      showToast({
        message: 'No active orders available for assignment. Create a new order first.',
        variant: 'warning'
      });
    }
  }, [selectedLocation, orders, pallets, showToast]);

  const handleAssignOrderSubmit = useCallback(async (orderId, extraBoxCount, palletData) => {
    try {
      showLoading('Assigning pallets to order...');
      
      console.log('handleAssignOrderSubmit called with:', { orderId, extraBoxCount, palletData });

      if (!palletData) {
        throw new Error('No pallet selected for assignment');
      }

      let response;
      // Check if it's a batch assignment by looking for the isBatch flag
      if (palletData.isBatch === true) {
        // Batch assignment
        if (!Array.isArray(palletData.id)) {
          throw new Error('Invalid batch pallet data: missing ID array');
        }
        console.log('Batch assigning pallets:', palletData.id, 'to order:', orderId);
        
        // Get current state of all pallets before assignment
        const currentStates = [];
        for (const id of palletData.id) {
          try {
            const state = await api.get(`/api/pallets/${id}`);
            currentStates.push(state);
          } catch (err) {
            console.error(`Failed to get current state for pallet ${id}:`, err);
            // Add a placeholder to maintain array index alignment
            currentStates.push({ data: { id } });
          }
        }
        
        try {
          response = await api.post('/api/pallets/batch/assign', {
            palletIds: palletData.id,
            orderId
          });
        } catch (error) {
          // Check if the operation actually succeeded despite the error
          if (error.response?.status === 200 || (error.name === 'SyntaxError' && error.message.includes('JSON'))) {
            console.log('Batch assignment likely succeeded despite error:', error);
            // Create a synthetic success response
            response = {
              data: {
                success: true,
                pallets: palletData.id.map(id => ({ id, orderId }))
              }
            };
          } else {
            throw error;
          }
        }

        // Verify response structure with safe fallbacks
        const responseData = response?.data || { pallets: [] };
        
        // Log assignment events for each pallet with proper null checks
        for (let i = 0; i < palletData.id.length; i++) {
          const id = palletData.id[i];
          try {
            // Safely find the updated pallet in the response
            const updatedPallet = responseData.pallets && Array.isArray(responseData.pallets) 
              ? responseData.pallets.find(p => p && p.id === id)
              : null;
              
            await analyticsService.logPalletEvent({
              pallet_id: id,
              event_type: 'pallet_assigned',
              user_id: selectedLocation.userId,
              location_id: selectedLocation.id,
              order_id: orderId,
              previous_state: currentStates[i]?.data || { id },
              new_state: updatedPallet || { id, orderId } // Fallback if pallet not found
            });
          } catch (err) {
            console.error(`Failed to log event for pallet ${id}:`, err);
            // Continue with other pallets even if logging fails for one
          }
        }
      } else {
        // Single pallet assignment
        if (!palletData.id) {
          throw new Error('Invalid pallet data: missing ID');
        }
        console.log('Assigning single pallet:', palletData.id, 'to order:', orderId);
        
        // Get current state before assignment with error handling
        let currentState = { data: { id: palletData.id } }; // Default fallback
        try {
          currentState = await api.get(`/api/pallets/${palletData.id}`);
        } catch (err) {
          console.error('Failed to get current pallet state:', err);
          // Continue with default state
        }
        
        try {
          response = await api.post(`/api/pallets/${palletData.id}/assign`, {
            orderId
          });
        } catch (error) {
          // Check if the operation actually succeeded despite the error
          if (error.response?.status === 200 || (error.name === 'SyntaxError' && error.message.includes('JSON'))) {
            console.log('Assignment likely succeeded despite error:', error);
            // Create a synthetic success response
            response = {
              data: { id: palletData.id, orderId, success: true }
            };
          } else {
            throw error;
          }
        }

        // Create fallback data if response is empty
        const responseData = response?.data || { id: palletData.id, orderId };

        // Log assignment event with proper null checks
        try {
          await analyticsService.logPalletEvent({
            pallet_id: palletData.id,
            event_type: 'pallet_assigned',
            user_id: selectedLocation.userId,
            location_id: selectedLocation.id,
            order_id: orderId,
            previous_state: currentState.data || { id: palletData.id },
            new_state: responseData
          });
        } catch (err) {
          console.error('Failed to log pallet assignment event:', err);
          // Continue even if logging fails
        }
      }

      // Check for explicit failure indication in response
      if (response?.data?.ok === false) {
        throw new Error(response.data?.message || 'Failed to assign pallets to order');
      }

      // Handle extra boxes with proper error handling
      if (extraBoxCount > 0) {
        try {
          const orderPallets = pallets.filter(p => p.order_id === orderId);
          const palletsWithBoxes = orderPallets.filter(p => p.colliCount);
          
          if (palletsWithBoxes.length > 0) {
            // Find the pallet with the most boxes to subtract from
            const targetPallet = palletsWithBoxes.reduce((prev, current) => 
              (prev.colliCount > current.colliCount) ? prev : current
            );

            if (targetPallet.colliCount >= extraBoxCount) {
              await api.put(`/api/pallets/update/${targetPallet.id}`, {
                ...targetPallet,
                colliCount: targetPallet.colliCount - extraBoxCount
              });
            }
          }
        } catch (error) {
          console.error('Error handling extra boxes:', error);
          // Continue even if extra box handling fails
        }
      }

      // Refresh data once at the end
      try {
        await Promise.all([
          loadPallets(selectedLocation.id),
          loadOrders(selectedLocation.id)
        ]);
      } catch (error) {
        console.error('Error refreshing data after assignment:', error);
        // Continue even if refresh fails
      }

      showToast({
        message: extraBoxCount !== null ? 
          'Order assigned and marked as completed' : 
          'Pallets assigned to order successfully',
        variant: 'success'
      });
      
      return true;
    } catch (error) {
      console.error('Order assignment error:', error);
      showToast({
        message: `Error assigning order: ${error.message}`,
        variant: 'error'
      });
      return false;
    } finally {
      hideLoading();
      setSelectedPalletForAssignment(null);
      setShowAssignModal(false);
    }
  }, [selectedPalletForAssignment, pallets, showToast, showLoading, hideLoading, loadPallets, loadOrders, selectedLocation]);

  const handleShipOrder = useCallback(async (orderId) => {
    const orderToShip = orders.find(o => o.id === orderId);
    if (!orderToShip) {
      showToast({
        message: 'Error: Order not found',
        variant: 'error'
      });
      return;
    }

    try {
      showLoading('Shipping order...');
      
      // Get current order state and its pallets before shipping
      const orderState = await api.get(`/api/orders/${orderId}`);
      const orderPallets = pallets.filter(p => p.order_id === orderId);
      const palletStates = await Promise.all(
        orderPallets.map(p => api.get(`/api/pallets/${p.id}`))
      );
      
      const response = await api.post(`/api/orders/ship/${orderId}`);
      
      // Log order shipping event
      await analyticsService.logPalletEvent({
        event_type: 'order_shipped',
        user_id: selectedLocation.userId,
        location_id: selectedLocation.id,
        order_id: orderId,
        previous_state: orderState.data,
        new_state: response.data,
        notes: `Shipped order ${orderId} with ${orderPallets.length} pallets`
      });

      // Log shipping events for each pallet in the order
      await Promise.all(
        orderPallets.map(async (pallet, index) => {
          await analyticsService.logPalletEvent({
            pallet_id: pallet.id,
            event_type: 'pallet_shipped',
            user_id: selectedLocation.userId,
            location_id: selectedLocation.id,
            order_id: orderId,
            previous_state: palletStates[index].data,
            new_state: { ...palletStates[index].data, status: 'shipped' }
          });
        })
      );

      await loadOrders(selectedLocation.id);
      await loadPallets(selectedLocation.id);
      
      showToast({
        message: 'Order shipped successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error shipping order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
    }
  }, [selectedLocation, orders, onAsyncAction, loadOrders, loadPallets]);

  const handleAddOrder = useCallback(async (orderData, isEdit = false) => {
    try {
      showLoading(isEdit ? 'Updating order...' : 'Creating order...');
      
      let response;
      if (isEdit) {
        // Get current state before update
        const currentState = await api.get(`/api/orders/${orderData.id}`);
        
        try {
          response = await api.put(`/api/orders/update/${orderData.id}`, {
            ...orderData,
            locationId: selectedLocation.id
          });
        } catch (error) {
          // Check if the operation actually succeeded despite the error
          if (error.response?.status === 200 || (error.name === 'SyntaxError' && error.message.includes('JSON'))) {
            console.log('Order update likely succeeded despite error:', error);
            // Create a synthetic success response
            response = {
              data: { ...orderData, id: orderData.id, success: true }
            };
          } else {
            throw error;
          }
        }

        // Log order update event
        await analyticsService.logPalletEvent({
          event_type: 'order_updated',
          user_id: selectedLocation.userId,
          location_id: selectedLocation.id,
          order_id: orderData.id,
          previous_state: currentState.data,
          new_state: response.data
        });
      } else {
        try {
          response = await api.post('/api/orders/create', {
            ...orderData,
            locationId: selectedLocation.id
          });
        } catch (error) {
          // Check if the operation actually succeeded despite the error
          if (error.response?.status === 200 || (error.name === 'SyntaxError' && error.message.includes('JSON'))) {
            console.log('Order creation likely succeeded despite error:', error);
            // Create a synthetic success response with a temporary ID that will be updated after reload
            response = {
              data: { ...orderData, id: 'temp-' + Date.now(), success: true }
            };
          } else {
            throw error;
          }
        }

        // Log order creation event (only if we have a response with an ID)
        if (response?.data?.id) {
          await analyticsService.logPalletEvent({
            event_type: 'order_created',
            user_id: selectedLocation.userId,
            location_id: selectedLocation.id,
            order_id: response.data.id,
            new_state: response.data
          });
        }
      }
      
      // Check for explicit failure indication in response
      if (response?.data?.ok === false) {
        throw new Error(response.data?.message || 'Operation failed');
      }
      
      try {
        // Ensure orders are reloaded before dismissing modal for real-time UI update
        console.log('Refreshing orders data to update UI...');
        await loadOrders(selectedLocation.id);
        console.log('Orders data refreshed successfully');
      } catch (loadError) {
        // Log error but don't fail the operation - the user still created the order successfully
        console.error('Error refreshing orders:', loadError);
        // Try again with a slight delay if the first attempt failed
        setTimeout(() => {
          loadOrders(selectedLocation.id).catch(e => 
            console.error('Failed retry to refresh orders:', e)
          );
        }, 500);
      }
      
      // Close the modal
      setShowAddOrder(false);
      
      showToast({
        message: isEdit ? 'Order updated successfully' : 'Order created successfully',
        variant: 'success'
      });
      
      return true;
    } catch (error) {
      console.error('Order operation error:', error);
      showToast({
        message: `Error ${isEdit ? 'updating' : 'creating'} order: ${error.message}`,
        variant: 'error'
      });
      return false;
    } finally {
      hideLoading();
    }
  }, [selectedLocation, loadOrders, setShowAddOrder, showToast, showLoading, hideLoading]);

  const handleDeleteOrder = useCallback(async (orderId) => {
    try {
      showLoading('Deleting order...');
      
      // Get current state before deletion
      const currentState = await api.get(`/api/orders/${orderId}`);
      
      await api.delete(`/api/orders/delete/${orderId}`);
      
      // Log order deletion event
      await analyticsService.logPalletEvent({
        event_type: 'order_deleted',
        user_id: selectedLocation.userId,
        location_id: selectedLocation.id,
        order_id: orderId,
        previous_state: currentState.data
      });

      await loadOrders(selectedLocation.id);
      
      showToast({
        message: 'Order deleted successfully',
        variant: 'success'
      });
    } catch (error) {
      showToast({
        message: `Error deleting order: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideLoading();
    }
  }, [selectedLocation, onAsyncAction, loadOrders]);

  const handleCloseAssignModal = useCallback(() => {
    setShowAssignModal(false);
    setSelectedPalletForAssignment(null);
  }, []);

  return {
    handleAssignOrder,
    handleShipOrder,
    handleAddOrder,
    handleDeleteOrder,
    showAssignModal,
    setShowAssignModal: handleCloseAssignModal,
    selectedPalletForAssignment,
    handleAssignOrderSubmit
  };
};

/**
 * Accessibility utilities for improving the user experience for all users,
 * including those using assistive technologies like screen readers.
 */

import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

// VisuallyHidden component for screen reader only content
const VisuallyHiddenSpan = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  word-wrap: normal;
`;

/**
 * VisuallyHidden component renders content only for screen readers
 * while hiding it visually from sighted users.
 */
export const VisuallyHidden = ({ children }) => {
  return <VisuallyHiddenSpan>{children}</VisuallyHiddenSpan>;
};

/**
 * LiveRegion component for announcing dynamic content changes to screen readers.
 * 
 * @param {string} message - The message to announce
 * @param {string} ariaLive - The aria-live value ('polite' or 'assertive')
 * @param {string} role - ARIA role (default: 'status')
 * @param {boolean} clearOnUnmount - Whether to clear the message when component unmounts
 */
export const LiveRegion = ({ 
  message, 
  ariaLive = 'polite', 
  role = 'status',
  clearOnUnmount = false
}) => {
  const [currentMessage, setCurrentMessage] = useState(message);
  
  useEffect(() => {
    setCurrentMessage(message);
    
    return () => {
      if (clearOnUnmount) {
        setCurrentMessage('');
      }
    };
  }, [message, clearOnUnmount]);
  
  return (
    <VisuallyHiddenSpan
      aria-live={ariaLive}
      aria-atomic="true"
      role={role}
    >
      {currentMessage}
    </VisuallyHiddenSpan>
  );
};

/**
 * InventoryUpdateAnnouncer component for announcing inventory updates.
 * 
 * @param {object} update - The inventory update object
 */
export const InventoryUpdateAnnouncer = ({ update }) => {
  if (!update) return null;
  
  let message = '';
  
  if (update.type === 'add') {
    message = `Added ${update.quantity} ${update.itemType} to ${update.location}.`;
  } else if (update.type === 'remove') {
    message = `Removed ${update.quantity} ${update.itemType} from ${update.location}.`;
  } else if (update.type === 'move') {
    message = `Moved ${update.quantity} ${update.itemType} from ${update.fromLocation} to ${update.toLocation}.`;
  } else if (update.type === 'status') {
    message = `${update.itemType} in ${update.location} status changed to ${update.status}.`;
  }
  
  return <LiveRegion message={message} />;
};

/**
 * useFocusTrap hook for trapping focus within a modal or dialog.
 * 
 * @param {boolean} active - Whether the focus trap is active
 * @param {function} onEscape - Callback when Escape key is pressed
 * @returns {object} - Ref to attach to the container element
 */
export const useFocusTrap = (active = true, onEscape = () => {}) => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    if (!active || !containerRef.current) return;
    
    const container = containerRef.current;
    const focusableElements = container.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    
    if (focusableElements.length === 0) return;
    
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];
    
    // Focus the first element when the trap becomes active
    firstElement.focus();
    
    const handleKeyDown = (e) => {
      // Handle Escape key
      if (e.key === 'Escape') {
        onEscape();
        return;
      }
      
      // Handle Tab key for focus trapping
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          // If shift+tab and on first element, move to last element
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          // If tab and on last element, move to first element
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };
    
    container.addEventListener('keydown', handleKeyDown);
    
    // Save previous active element to restore focus when trap is deactivated
    const previousActiveElement = document.activeElement;
    
    return () => {
      container.removeEventListener('keydown', handleKeyDown);
      
      // Restore focus when trap is deactivated
      if (previousActiveElement) {
        previousActiveElement.focus();
      }
    };
  }, [active, onEscape]);
  
  return containerRef;
};

/**
 * useArrowKeyNavigation hook for implementing keyboard navigation with arrow keys.
 * 
 * @param {array} items - Array of items to navigate
 * @param {function} onSelect - Callback when an item is selected
 * @param {string} orientation - 'vertical' or 'horizontal'
 * @returns {object} - Props to spread on the container element
 */
export const useArrowKeyNavigation = (
  items = [],
  onSelect = () => {},
  orientation = 'vertical'
) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  
  const handleKeyDown = (e) => {
    if (!items.length) return;
    
    // Handle arrow keys based on orientation
    if (orientation === 'vertical') {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex((prev) => (prev + 1) % items.length);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex((prev) => (prev - 1 + items.length) % items.length);
      }
    } else {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        setActiveIndex((prev) => (prev + 1) % items.length);
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        setActiveIndex((prev) => (prev - 1 + items.length) % items.length);
      }
    }
    
    // Handle Enter or Space to select
    if ((e.key === 'Enter' || e.key === ' ') && activeIndex >= 0) {
      e.preventDefault();
      onSelect(items[activeIndex], activeIndex);
    }
    
    // Handle Home and End keys
    if (e.key === 'Home') {
      e.preventDefault();
      setActiveIndex(0);
    } else if (e.key === 'End') {
      e.preventDefault();
      setActiveIndex(items.length - 1);
    }
  };
  
  return {
    activeIndex,
    setActiveIndex,
    containerProps: {
      role: orientation === 'vertical' ? 'listbox' : 'tablist',
      tabIndex: 0,
      'aria-orientation': orientation,
      onKeyDown: handleKeyDown,
    },
    getItemProps: (index) => ({
      role: orientation === 'vertical' ? 'option' : 'tab',
      tabIndex: activeIndex === index ? 0 : -1,
      'aria-selected': activeIndex === index,
      onClick: () => {
        setActiveIndex(index);
        onSelect(items[index], index);
      },
    }),
  };
};

/**
 * A11yTable component for creating accessible data tables.
 * 
 * @param {array} headers - Array of header objects with { id, label }
 * @param {array} rows - Array of row data
 * @param {string} caption - Table caption
 * @param {function} renderCell - Function to render a cell
 * @param {object} props - Additional props for the table
 */
export const A11yTable = ({ 
  headers, 
  rows, 
  caption,
  renderCell,
  ...props 
}) => {
  return (
    <table {...props}>
      {caption && <caption>{caption}</caption>}
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.id} scope="col" id={`header-${header.id}`}>
              {header.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={row.id || rowIndex}>
            {headers.map((header, colIndex) => {
              // First column is a row header
              const isRowHeader = colIndex === 0;
              const cellProps = isRowHeader
                ? { scope: 'row' }
                : { headers: `header-${header.id}` };
              
              return renderCell ? (
                renderCell(row, header, cellProps)
              ) : (
                <td key={header.id} {...cellProps}>
                  {row[header.id]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

/**
 * SkipLink component for keyboard users to skip to main content.
 */
export const SkipLink = styled.a`
  position: absolute;
  top: -40px;
  left: 0;
  background: ${({ theme }) => theme.colors.primary.main};
  color: white;
  padding: 8px;
  z-index: 100;
  transition: top 0.3s;

  &:focus {
    top: 0;
  }
`;

/**
 * Adds appropriate ARIA attributes to a sortable table header.
 * 
 * @param {string} columnId - The column ID
 * @param {string} sortColumn - The currently sorted column ID
 * @param {string} sortDirection - The sort direction ('asc' or 'desc')
 * @param {function} onSort - The sort function
 * @returns {object} - Props to spread on the table header
 */
export const getSortableColumnProps = (
  columnId,
  sortColumn,
  sortDirection,
  onSort
) => {
  const isSorted = sortColumn === columnId;
  
  return {
    onClick: () => onSort(columnId),
    role: 'button',
    tabIndex: 0,
    'aria-sort': isSorted
      ? sortDirection === 'asc'
        ? 'ascending'
        : 'descending'
      : 'none',
    onKeyDown: (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onSort(columnId);
      }
    },
  };
};

/**
 * Utility function to announce inventory changes to screen readers.
 * 
 * @param {string} message - The message to announce
 */
export const announceToScreenReader = (message) => {
  // Create a live region if it doesn't exist
  let liveRegion = document.getElementById('a11y-live-region');
  
  if (!liveRegion) {
    liveRegion = document.createElement('div');
    liveRegion.id = 'a11y-live-region';
    liveRegion.setAttribute('aria-live', 'polite');
    liveRegion.setAttribute('aria-atomic', 'true');
    liveRegion.style.position = 'absolute';
    liveRegion.style.width = '1px';
    liveRegion.style.height = '1px';
    liveRegion.style.overflow = 'hidden';
    liveRegion.style.clip = 'rect(0 0 0 0)';
    document.body.appendChild(liveRegion);
  }
  
  // Set the message
  liveRegion.textContent = message;
  
  // Clear the message after a delay
  setTimeout(() => {
    liveRegion.textContent = '';
  }, 3000);
};

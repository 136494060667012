/**
 * Collection management utility enforcing memory safety constraints
 */

// Strict bounds for collection sizes
export const COLLECTION_LIMITS = {
  MAX_ITEMS: 10000,           // Maximum items in any collection
  MAX_BATCH_SIZE: 1000,       // Maximum items to process in one batch
  DEFAULT_PAGE_SIZE: 100,     // Default page size for paginated results
  MAX_CACHE_ITEMS: 1000,      // Maximum items to keep in cache
  MAX_OBJECT_DEPTH: 5         // Maximum nesting depth for objects
};

/**
 * Validates collection size against defined bounds
 * @param {number} size - Size to validate
 * @param {number} limit - Maximum allowed size
 * @throws {Error} If size exceeds limit
 */
const validateSize = (size, limit) => {
  if (!Number.isInteger(size) || size < 0) {
    throw new Error('Size must be a non-negative integer');
  }
  if (size > limit) {
    throw new Error(`Collection size ${size} exceeds limit ${limit}`);
  }
};

/**
 * Creates a size-bounded array
 * @param {number} maxSize - Maximum size of the array
 * @returns {Object} Bounded array wrapper
 */
export const createBoundedArray = (maxSize = COLLECTION_LIMITS.MAX_ITEMS) => {
  const items = [];
  
  return {
    add: (item) => {
      validateSize(items.length + 1, maxSize);
      items.push(item);
    },
    remove: (index) => {
      if (index >= 0 && index < items.length) {
        items.splice(index, 1);
      }
    },
    get: (index) => items[index],
    getAll: () => [...items],
    clear: () => items.splice(0, items.length),
    size: () => items.length,
    isFull: () => items.length >= maxSize
  };
};

/**
 * Creates a size-bounded object map
 * @param {number} maxSize - Maximum number of entries
 * @returns {Object} Bounded map wrapper
 */
export const createBoundedMap = (maxSize = COLLECTION_LIMITS.MAX_ITEMS) => {
  const entries = new Map();
  
  return {
    set: (key, value) => {
      validateSize(entries.size + (entries.has(key) ? 0 : 1), maxSize);
      entries.set(key, value);
    },
    get: (key) => entries.get(key),
    has: (key) => entries.has(key),
    delete: (key) => entries.delete(key),
    clear: () => entries.clear(),
    size: () => entries.size,
    isFull: () => entries.size >= maxSize,
    entries: () => new Map(entries)
  };
};

/**
 * Processes items in batches to prevent memory spikes
 * @param {Array} items - Items to process
 * @param {Function} processor - Processing function for each batch
 * @param {number} batchSize - Size of each batch
 * @returns {Promise<Array>} Processed results
 */
export const processBatches = async (
  items,
  processor,
  batchSize = COLLECTION_LIMITS.MAX_BATCH_SIZE
) => {
  validateSize(items.length, COLLECTION_LIMITS.MAX_ITEMS);
  validateSize(batchSize, COLLECTION_LIMITS.MAX_BATCH_SIZE);

  const results = [];
  
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    const batchResults = await processor(batch);
    results.push(...batchResults);
  }
  
  return results;
};

/**
 * Creates a paginated result set
 * @param {Array} items - Full set of items
 * @param {number} page - Page number (1-based)
 * @param {number} pageSize - Items per page
 * @returns {Object} Paginated result
 */
export const paginateResults = (
  items,
  page = 1,
  pageSize = COLLECTION_LIMITS.DEFAULT_PAGE_SIZE
) => {
  validateSize(items.length, COLLECTION_LIMITS.MAX_ITEMS);
  validateSize(pageSize, COLLECTION_LIMITS.DEFAULT_PAGE_SIZE);

  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / pageSize);
  const currentPage = Math.max(1, Math.min(page, totalPages));
  const startIndex = (currentPage - 1) * pageSize;
  
  return {
    items: items.slice(startIndex, startIndex + pageSize),
    pagination: {
      currentPage,
      totalPages,
      pageSize,
      totalItems,
      hasNextPage: currentPage < totalPages,
      hasPrevPage: currentPage > 1
    }
  };
};

/**
 * Validates object nesting depth
 * @param {Object} obj - Object to check
 * @param {number} maxDepth - Maximum allowed depth
 * @throws {Error} If depth exceeds limit
 */
export const validateObjectDepth = (
  obj,
  maxDepth = COLLECTION_LIMITS.MAX_OBJECT_DEPTH
) => {
  const checkDepth = (value, currentDepth = 0) => {
    if (currentDepth > maxDepth) {
      throw new Error(`Object exceeds maximum nesting depth of ${maxDepth}`);
    }
    
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (typeof item === 'object' && item !== null) {
          checkDepth(item, currentDepth + 1);
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.values(value).forEach(val => {
        if (typeof val === 'object' && val !== null) {
          checkDepth(val, currentDepth + 1);
        }
      });
    }
  };
  
  checkDepth(obj);
};

export default {
  createBoundedArray,
  createBoundedMap,
  processBatches,
  paginateResults,
  validateObjectDepth,
  COLLECTION_LIMITS
};

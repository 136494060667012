import React from 'react';
import { Download } from 'lucide-react';
import {
  Container,
  Title,
  InstructionSection,
  InstructionStep,
  StepNumber,
  StepText,
  InstallButton,
  Icon
} from './styles';

export const DownloadPage = () => {
  const [deferredPrompt, setDeferredPrompt] = React.useState(null);
  const [isInstalled, setIsInstalled] = React.useState(false);

  React.useEffect(() => {
    // Check if app is already installed
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Listen for successful installation
    window.addEventListener('appinstalled', () => {
      setIsInstalled(true);
      setDeferredPrompt(null);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    
    if (outcome === 'accepted') {
      setDeferredPrompt(null);
    }
  };

  if (isInstalled) {
    return (
      <Container>
        <Title>App Already Installed</Title>
        <InstructionSection>
          <InstructionStep>
            <StepText>
              The app is already installed on your device. You can access it from your home screen.
            </StepText>
          </InstructionStep>
        </InstructionSection>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Install Mobile App</Title>
      
      {deferredPrompt ? (
        <InstructionSection>
          <InstallButton onClick={handleInstallClick}>
            <Icon>
              <Download size={24} />
            </Icon>
            Install App
          </InstallButton>
          <InstructionStep>
            <StepText>
              Click the button above to install the app on your device.
            </StepText>
          </InstructionStep>
        </InstructionSection>
      ) : (
        <InstructionSection>
          <InstructionStep>
            <StepNumber>1</StepNumber>
            <StepText>
              For iOS: Tap the share button in your browser and select "Add to Home Screen"
            </StepText>
          </InstructionStep>
          <InstructionStep>
            <StepNumber>2</StepNumber>
            <StepText>
              For Android: Look for the "Add to Home Screen" prompt in your browser or tap the menu button and select "Install App"
            </StepText>
          </InstructionStep>
        </InstructionSection>
      )}
    </Container>
  );
};

export default DownloadPage;

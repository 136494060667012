import ZONE_TYPES, { TYPE_GROUPS } from './zoneTypes';
export { ZONE_TYPES, TYPE_GROUPS };

// Revised zone configuration with grid dimensions, capacity, and positions 
// Based on the updated coordinates from the actual floor plan
// With consolidated zones as requested

export const DEFAULT_ZONES = [
  
  // Left column zones
  {
    id: 'zone-1',
    name: 'Zone 1',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 6, cols: 2 },
    position: { x: 0, y: 21 },
    section: 'general',
  },
  {
    id: 'zone-2',
    name: 'Zone 2',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 4, cols: 3 },
    position: { x: 0, y: 28 },
    section: 'general',
  },
  {
    id: 'zone-3',
    name: 'Zone 3',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 3, cols: 6 },
    position: { x: 0, y: 33 },
    section: 'general',
    forceVerticalRows: true, // Force vertical row orientation
  },
  
  // Bakker section
  {
    id: 'bakker',
    name: 'Bakker',
    type: 'general',
    allowedTypes: [ZONE_TYPES.BAKKER],
    dimensions: { rows: 6, cols: 5 },
    position: { x: 5, y: 18 },
    section: 'general',
  },
  
  // NewGreen
  {
    id: 'new-green',
    name: 'NewGreen',
    type: 'general',
    allowedTypes: [ZONE_TYPES.NEW_GREEN],
    dimensions: { rows: 1, cols: 2 },
    position: { x: 0, y: 19 },
    section: 'general',
  },
  
  // Unified Zone 7 with custom shape
  {
    id: 'zone-7',
    name: 'Zone 7',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 8, cols: 7 }, // Total logical dimensions (8 rows total)
    position: { x: 18, y: 16 },
    section: 'general',
    customLayout: true,
    segments: [
      { x: 18, y: 16, width: 7, height: 2 }, // Original zone-7 (rows 1-2)
      { x: 18, y: 21, width: 7, height: 4 }, // Main area (former 7.2) (rows 3-6)
      { x: 19, y: 25, width: 6, height: 1 }, // Indented row (former 7.1) (row 7)
      { x: 18, y: 26, width: 7, height: 1 }  // Bottom row (former 7.3) (row 8)
    ],
    // For the StorageMapGrid component
    gridLayout: {
      topArea: { x: 18, y: 16, width: 7, height: 2 },      // Original zone-7 (rows 1-2)
      mainArea: { x: 18, y: 21, width: 7, height: 4 },     // Main area (rows 3-6)
      indentedArea: { x: 19, y: 25, width: 6, height: 1 }, // Indented row (row 7)
      bottomArea: { x: 18, y: 26, width: 7, height: 1 }    // Bottom row (row 8)
    }
  },
  
  // Additional zones
  {
    id: 'zone-4',
    name: 'Zone 4',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 6, cols: 3 },  // Keep for map display
    position: { x: 8, y: 25 },
    section: 'general',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 3, cols: 6 } // True row-level dimensions
    }
  },
  
  // Zone 12
  {
    id: 'zone-12',
    name: 'Zone 12',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 3, cols: 8 },
    position: { x: 37, y: 19 },
    section: 'general',
    forceVerticalRows: true, // Force vertical row orientation
  },
  // Zone 13 (now part of merged zone-12-group)
  {
    id: 'zone-13-old',
    name: 'Zone 13 (Old)',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 1, cols: 8 },
    position: { x: 37, y: 18 }, // Updated to match new coordinates
    section: 'general',
    mergedGroupId: 'zone-12-group'
  },
  
  // Middle zone
  {
    id: 'zone-5',
    name: 'Zone 5',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 8, cols: 4 },  // Keep for map display
    position: { x: 13, y: 22 },
    section: 'general',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 4, cols: 8 } // True row-level dimensions
    }
  },
  
  // Bottom zones
  {
    id: 'zone-6',
    name: 'Zone 6',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 2, cols: 4 }, // Keep for map display
    position: { x: 18, y: 28 },
    section: 'general',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 4, cols: 2 } // True row-level dimensions
    }
  },
  
  // Bottom area zones
  {
    id: 'zone-8',
    name: 'Zone 8',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 2, cols: 3 }, // Keep for map display
    position: { x: 16, y: 34 }, // Updated to match new coordinates
    section: 'general',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 3, cols: 2 } // True row-level dimensions
    }
  },
  {
    id: 'zone-9',
    name: 'Zone 9',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 3, cols: 5 }, // Keep for map display
    position: { x: 20, y: 33 }, // Updated to match new coordinates
    section: 'general',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 5, cols: 3 } // True row-level dimensions
    }
  },
  
  // Central zones - Updated Zone 10 dimensions
  {
    id: 'zone-10',
    name: 'Zone 10',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 5, cols: 7 }, // Updated from 4x6 to 5x7 as requested
    position: { x: 28, y: 23 }, // Updated to match new coordinates
    section: 'general',
    forceVerticalRows: true, // Force vertical row orientation
  },
  {
    id: 'zone-11',
    name: 'Zone 11',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 6, cols: 7 },
    position: { x: 28, y: 16 },
    section: 'general',
    forceVerticalRows: true, // Force vertical row orientation
  },
  
  // Zone 13.2 (now part of merged zone-13-group)
  {
    id: 'zone-13-2',
    name: 'Zone 13.2',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 1, cols: 8 },
    position: { x: 37, y: 16 },
    section: 'general',
    mergedGroupId: 'zone-13-group'
  },
  // Zone 13.1 (now part of merged zone-13-group)
  {
    id: 'zone-13-1',
    name: 'Zone 13.1',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 2, cols: 6 },
    position: { x: 39, y: 14 },
    section: 'general',
    mergedGroupId: 'zone-13-group'
  },
  
  // New zones for map layout display
  {
    id: 'logistics-zone',
    name: '↑ Logistics',
    type: ZONE_TYPES.LOGISTICS,
    allowedTypes: [ZONE_TYPES.LOGISTICS], // Only allow logistics type
    dimensions: { rows: 12, cols: 7 }, // Twice the size of Zone 11
    position: { x: 8, y: 37 }, // Final position provided by user
    section: 'special',
  },
  {
    id: 'kleinpak-zone',
    name: '↑ Kleinpak',
    type: ZONE_TYPES.SPECIAL,
    allowedTypes: [ZONE_TYPES.SPECIAL], // Only allow special type
    dimensions: { rows: 12, cols: 7 }, // Twice the size of Zone 11
    position: { x: 32, y: 37 }, // Final position provided by user
    section: 'special',
  },
  
  // Refrigerated zones
  {
    id: 'k1',
    name: 'K1',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 6, cols: 5 },
    position: { x: 40, y: 30 },
    section: 'special',
    isRefrigerated: true,
  },
  {
    id: 'k2',
    name: 'K2',
    type: 'general',
    allowedTypes: Object.values(ZONE_TYPES),
    dimensions: { rows: 6, cols: 5 },
    position: { x: 40, y: 23 },
    section: 'special',
    isRefrigerated: true,
  },
  
  // Van Gelder 1 (part of merged van-gelder-group)
  {
    id: 'van-gelder-1',
    name: 'Van Gelder 1',
    type: 'general',
    allowedTypes: [ZONE_TYPES.VAN_GELDER],
    dimensions: { rows: 2, cols: 6 },
    position: { x: 28, y: 13 },
    section: 'general',
    mergedGroupId: 'van-gelder-group'
  },
  // Van Gelder 3 (part of merged van-gelder-group)
  {
    id: 'van-gelder-3',
    name: 'Van Gelder 3',
    type: 'general',
    allowedTypes: [ZONE_TYPES.VAN_GELDER],
    dimensions: { rows: 1, cols: 1 },
    position: { x: 44, y: 11 },
    section: 'general',
    mergedGroupId: 'van-gelder-group'
  },
  {
    id: 'van-gelder-2',
    name: 'Van Gelder 2',
    type: 'general',
    allowedTypes: [ZONE_TYPES.VAN_GELDER],
    dimensions: { rows: 1, cols: 3 },
    position: { x: 42, y: 12 }, // Updated position
    section: 'general',
  },
  
  // Special zones
  {
    id: 'oversorteer',
    name: 'Oversorteer',
    type: ZONE_TYPES.OVERSORTEER,
    allowedTypes: [ZONE_TYPES.OVERSORTEER],
    dimensions: { rows: 2, cols: 6 }, // Keep for map display
    position: { x: 28, y: 29 },
    section: 'special',
    rowViewConfig: {
      invertRowsAndColumns: true,
      dimensions: { rows: 6, cols: 2 } // True row-level dimensions
    }
  },
];

// Function to calculate capacity based on dimensions, considering row view config and custom layouts
export const calculateZoneCapacity = (zone, useRowView = false) => {
  if (zone.customLayout && zone.segments) {
    // For custom layouts, sum up the capacity of each segment
    return zone.segments.reduce((total, segment) => {
      return total + (segment.width * segment.height);
    }, 0);
  } else if (useRowView && zone.rowViewConfig?.dimensions) {
    return zone.rowViewConfig.dimensions.rows * zone.rowViewConfig.dimensions.cols;
  }
  return zone.dimensions.rows * zone.dimensions.cols;
};

// Preprocessed zone map for quick lookup
export const ZONE_MAP = DEFAULT_ZONES.reduce((acc, zone) => {
  acc[zone.id] = {
    ...zone,
    capacity: calculateZoneCapacity(zone),
  };
  return acc;
}, {});

// Find zones that can store a specific tomato type
export const getZonesForTomatoType = (tomatoType) => {
  return DEFAULT_ZONES.filter(zone => 
    zone.allowedTypes.includes(tomatoType)
  ).map(zone => ({
    ...zone,
    capacity: calculateZoneCapacity(zone),
  }));
};

// Updated adjacent zones mapping for consolidated zones
export const ADJACENT_ZONES = {
  // New zones for map layout display
  'logistics-zone': [],
  'kleinpak-zone': [],
  // Neighbors based on coordinate proximity
  'new-green': ['bakker', 'zone-1'],
  'bakker': ['new-green', 'zone-1', 'zone-3', 'zone-4'],
  'zone-1': ['new-green', 'bakker'],
  'zone-2': ['zone-3', 'zone-4'],
  'zone-3': ['zone-2', 'zone-4', 'zone-5'],
  'zone-4': ['zone-2', 'zone-3', 'zone-5', 'zone-7'],
  'zone-5': ['zone-3', 'zone-4', 'zone-6', 'zone-7'],
  'zone-6': ['zone-5', 'zone-8', 'zone-9'],
  'zone-7': ['zone-4', 'zone-5', 'zone-11'],
  'zone-8': ['zone-6', 'zone-9'],
  'zone-9': ['zone-8', 'zone-10'],
  'zone-10': ['zone-9', 'zone-11', 'zone-12'],
  'zone-11': ['zone-7', 'zone-10', 'zone-12', 'k2'],
  'zone-12': ['zone-11', 'zone-13', 'k2'],
  'zone-13': ['zone-12', 'zone-11'],
  'k1': ['k2', 'van-gelder-1'],
  'k2': ['k1', 'zone-11', 'zone-12'],
  'van-gelder-1': ['k1', 'van-gelder-2'],
  'van-gelder-2': ['van-gelder-1'],
  'oversorteer': ['zone-8', 'zone-9'],
};

const zoneConfig = {
  DEFAULT_ZONES,
  ZONE_MAP,
  ADJACENT_ZONES,
  calculateZoneCapacity,
  getZonesForTomatoType,
};

export default zoneConfig;

import React from 'react';
import styled from 'styled-components';

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    opacity: 0.8;
  }
`;

const QRImage = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

const QRCode = ({ data, size = 50, onClick, className }) => {
  if (!data) return null;

  return (
    <QRCodeContainer onClick={onClick} className={className}>
      <QRImage
        src={data}
        alt="QR Code"
        size={size}
      />
    </QRCodeContainer>
  );
};

export default QRCode;

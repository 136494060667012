import React, { useRef, useEffect, useState } from 'react';
import {
  LogSection,
  SectionHeader,
  ClearButton,
  LogContainer,
  LogEntry,
  LogTimestamp,
  LogAction,
  LogDetails,
  LogFilterContainer,
  FilterButton,
  LogCodeBlock
} from './styles';
import { clearLocalLogs } from './printerService';

const PrinterLogs = ({ logs, onClearLogs }) => {
  const logEndRef = useRef(null);
  const [filter, setFilter] = useState('ALL');

  useEffect(() => {
    logEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [logs]);

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const handleClearLogs = () => {
    // Clear both the displayed logs and localStorage logs
    onClearLogs();
    clearLocalLogs();
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredLogs = () => {
    if (!logs || !Array.isArray(logs)) return [];
    
    if (filter === 'ALL') return logs;
    
    return logs.filter(log => {
      if (filter === 'SUCCESS') return log.status === 'SUCCESS';
      if (filter === 'FAILURE') return log.status === 'FAILURE';
      if (filter === 'INFO') return log.status === 'INFO';
      if (filter === 'CONNECTION') return log.action.includes('CONNECTION');
      if (filter === 'PRINT') return log.action.includes('PRINT');
      return true;
    });
  };

  const renderLogs = () => {
    const filtered = filteredLogs();
    
    if (filtered.length === 0) {
      return <p>No printer activity logs available for the selected filter.</p>;
    }

    return filtered.slice(0, 100).map((log, index) => {
      if (!log) return null;

      // Format the details - if it looks like a printer command, display it nicely
      let formattedDetails = log.details || 'No details available';
      const isCommand = log.details && 
        (log.details.includes('^XA') || // ZPL
         log.details.startsWith('N\n') || // EPL
         log.details.includes('Raw'));   // Raw command reference
         
      return (
        <LogEntry key={index} status={log.status || 'UNKNOWN'}>
          <LogTimestamp>{formatTimestamp(log.timestamp || new Date())}</LogTimestamp>
          <LogAction>{log.action || 'UNKNOWN ACTION'}</LogAction>
          <LogDetails>
            <div><strong>Location:</strong> {log.location || 'Unknown'}</div>
            {log.printerType && <div><strong>Printer:</strong> {log.printerType}</div>}
            <div><strong>Status:</strong> {log.status || 'Unknown'}</div>
            {log.protocol && <div><strong>Protocol:</strong> {log.protocol}</div>}
            {log.labelType && <div><strong>Label Type:</strong> {log.labelType}</div>}
            {log.commandLanguage && <div><strong>Command Language:</strong> {log.commandLanguage}</div>}
            
            <div>
              <strong>Details:</strong> 
              {isCommand ? (
                <LogCodeBlock>{formattedDetails}</LogCodeBlock>
              ) : (
                formattedDetails
              )}
            </div>
          </LogDetails>
        </LogEntry>
      );
    });
  };

  return (
    <LogSection>
      <SectionHeader>
        Printer Activity Log
        <ClearButton onClick={handleClearLogs}>Clear Logs</ClearButton>
      </SectionHeader>
      
      <LogFilterContainer>
        <FilterButton 
          active={filter === 'ALL'} 
          onClick={() => handleFilterChange('ALL')}
        >
          All
        </FilterButton>
        <FilterButton 
          active={filter === 'SUCCESS'} 
          success
          onClick={() => handleFilterChange('SUCCESS')}
        >
          Success
        </FilterButton>
        <FilterButton 
          active={filter === 'FAILURE'} 
          failure
          onClick={() => handleFilterChange('FAILURE')}
        >
          Failures
        </FilterButton>
        <FilterButton 
          active={filter === 'INFO'} 
          info
          onClick={() => handleFilterChange('INFO')}
        >
          Info
        </FilterButton>
        <FilterButton 
          active={filter === 'CONNECTION'} 
          onClick={() => handleFilterChange('CONNECTION')}
        >
          Connection Tests
        </FilterButton>
        <FilterButton 
          active={filter === 'PRINT'} 
          onClick={() => handleFilterChange('PRINT')}
        >
          Print Jobs
        </FilterButton>
      </LogFilterContainer>
      
      <LogContainer>
        {renderLogs()}
        <div ref={logEndRef} />
      </LogContainer>
    </LogSection>
  );
};

export default PrinterLogs;

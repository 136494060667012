import React, { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { DEFAULT_ZONES } from '@features/map/constants/zoneConfig.v2';
import {
  shouldRenderRowsHorizontally,
  getMaxRows,
  getZoneDimensions,
  transformToActualRowIndex,
  transformToVisualRowIndex,
  shouldReverseRowOrder
} from '@lib/utils/zoneTransformUtils';
import { getZoneOrientation } from '@features/map/constants/zoneOrientations';
import { MapContainer, WarehouseLayout } from '@features/map/components/MapView.styles';
import styled from 'styled-components';

// Enhanced zone styling with prominent text display and red highlighting
// In row focus mode, we don't show the red highlight
const ZoneBox = styled.div`
  position: absolute;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  background: ${props => props.$isHighlighted && !props.$showRowsMode ? '#fee2e2' : '#f8fafc'};
  border: ${props => props.$isHighlighted && !props.$showRowsMode ? '3px solid #dc2626' : '1px solid #e2e8f0'};
  opacity: ${props => props.$isHighlighted ? 1 : 0.7};
  z-index: ${props => props.$isHighlighted ? 5 : 1};
  box-shadow: ${props => props.$isHighlighted && !props.$showRowsMode ? '0 0 10px rgba(220, 38, 38, 0.5)' : 'none'};
`;

// Row highlight box styling - used when we want to show rows within a zone
const RowBox = styled.div`
  position: absolute;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  background: ${props => props.$isScheduled ? '#e8f5e9' : 'transparent'};
  border: ${props => props.$isScheduled 
    ? '2px solid #4caf50' 
    : '1px dashed rgba(128, 128, 128, 0.4)'};
  opacity: ${props => props.$isScheduled ? 0.9 : 0.25};
  z-index: ${props => props.$isScheduled ? 6 : 3}; // Scheduled rows above ghost rows
  box-shadow: ${props => props.$isScheduled ? '0 0 8px rgba(76, 175, 80, 0.5)' : 'none'};
`;

// Row text styling
const RowText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  pointer-events: none;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.9);
  font-weight: ${props => props.$isScheduled ? 600 : 400};
  color: ${props => props.$isScheduled ? '#2e7d32' : 'rgba(128, 128, 128, 0.7)'};
  text-align: center;
  overflow: visible;
  font-size: ${props => props.$fontSize || '9px'};
  opacity: ${props => props.$isScheduled ? 1 : 0.7};
`;

// Styled text that won't affect zone dimensions
const ZoneText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  pointer-events: none;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.9);
  font-weight: 600;
  color: ${props => props.$isHighlighted ? '#b91c1c' : '#64748b'};
  text-align: center;
  overflow: visible;
  font-size: ${props => props.$fontSize || '10px'};
`;

// Responsive container that will adapt to the available space
const ResponsiveMapContainer = styled(MapContainer)`
  width: 100%;
  height: ${props => props.$height || '50vh'};
  max-height: 500px;
  min-height: 250px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f1f5f9;
  position: relative;

  @media (max-width: 768px) {
    height: ${props => props.$mobileHeight || '40vh'};
    min-height: 200px;
  }
`;

// Fullscreen background that always covers the entire container
const FullscreenBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 0;
`;

// Layout that supports absolute positioning of elements with CSS transform for scaling and positioning
const TransformedWarehouseLayout = styled(WarehouseLayout)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.$fullWidth ? '100%' : 'auto'};
  height: ${props => props.$fullHeight ? '100%' : 'auto'};
  transform: ${props => `translate(${props.$translateX}px, ${props.$translateY}px) scale(${props.$scale})`};
  transform-origin: 0 0;
  transition: transform 0.3s ease;
  z-index: 1;
`;

const ZoneHighlightMap = ({ 
  highlightedZone, 
  interactive = false,
  customHeight,
  mobileHeight,
  focusMode = 'all', // 'all' or 'zone' - determines if we show all zones or focus on the highlighted one
  highlightedRows = [], // Array of row objects to highlight within the zone
  useRowMode = false // Whether to use special row visualization mode with different zooming behavior
}) => {
  // State for transform properties
  const [transform, setTransform] = useState({
    scale: 0.5,
    translateX: 0,
    translateY: 0
  });
  
  // References
  const containerRef = useRef(null);
  const layoutRef = useRef(null);
  
  // Constants
  const PIXEL_RATIO = 10; // Each unit in zone config equals 10px
  
  // Calculate map dimensions and find highlighted zone object once
  const { mapDimensions, highlightedZoneObj } = useMemo(() => {
    // Find the maximum bounds of the map based on all zones
    const maxX = Math.max(...DEFAULT_ZONES.map(zone => 
      zone.position.x + zone.dimensions.cols
    )) * PIXEL_RATIO;
    
    const maxY = Math.max(...DEFAULT_ZONES.map(zone => 
      zone.position.y + zone.dimensions.rows
    )) * PIXEL_RATIO;
    
    // Find the highlighted zone using multiple matching strategies
    let foundZone = null;
    
    if (highlightedZone) {
      // Try exact match first
      foundZone = DEFAULT_ZONES.find(zone => zone.name === highlightedZone);
      
      // If no exact match, try case-insensitive
      if (!foundZone) {
        foundZone = DEFAULT_ZONES.find(zone => 
          zone.name.toLowerCase() === highlightedZone.toLowerCase()
        );
      }
      
      // If still no match, try to match zone number
      if (!foundZone) {
        const highlightedZoneMatch = highlightedZone.match(/Zone\s+(\d+(?:\.\d+)?)/i);
        
        if (highlightedZoneMatch) {
          const zoneNumber = highlightedZoneMatch[1];
          foundZone = DEFAULT_ZONES.find(zone => {
            const zoneNameMatch = zone.name.match(/Zone\s+(\d+(?:\.\d+)?)/i);
            return zoneNameMatch && zoneNameMatch[1] === zoneNumber;
          });
        }
      }
    }
    
    return {
      mapDimensions: { width: maxX, height: maxY },
      highlightedZoneObj: foundZone
    };
  }, [highlightedZone]);
  
  // Calculate optimal transform based on container size and focus target
  const calculateOptimalTransform = useCallback(() => {
    if (!containerRef.current) return;
    
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    
    // Default values for showing the entire map
    let newScale = 0.5;
    let newTranslateX = 0;
    let newTranslateY = 0;
    
    // CASE 1: We need to focus on a specific zone
    if (focusMode === 'zone' && highlightedZoneObj) {
      // Get the zone's dimensions and position in pixels
      const zoneX = highlightedZoneObj.position.x * PIXEL_RATIO;
      const zoneY = highlightedZoneObj.position.y * PIXEL_RATIO;
      const zoneWidth = highlightedZoneObj.dimensions.cols * PIXEL_RATIO;
      const zoneHeight = highlightedZoneObj.dimensions.rows * PIXEL_RATIO;
      
      // Calculate zone center coordinates
      const zoneCenterX = zoneX + (zoneWidth / 2);
      const zoneCenterY = zoneY + (zoneHeight / 2);
      
      if (useRowMode) {
        // ROW MODE: We want to see the rows within the zone clearly
        // Use a more zoomed-in scale and center precisely on the zone
        
        // Dynamic scale based on container vs zone size
        // We want the zone to take up about 50-60% of the container (reduced from 70%)
        const targetPercentage = 0.55; // Zone should take 55% of container
        const scaleX = (containerWidth * targetPercentage) / zoneWidth;
        const scaleY = (containerHeight * targetPercentage) / zoneHeight;
        newScale = Math.min(scaleX, scaleY); // Use the smaller scale for proper fitting
        
        // Ensure reasonable zoom level for row visibility (reduced maximum zoom)
        newScale = Math.max(Math.min(newScale, 1.5), 1.2);
        
        // Calculate the center position of the viewport
        const viewportCenterX = containerWidth / 2;
        const viewportCenterY = containerHeight / 2;
        
        // Calculate translation to center the zone in the viewport
        newTranslateX = viewportCenterX - (zoneCenterX * newScale);
        newTranslateY = viewportCenterY - (zoneCenterY * newScale);
        
        console.log(`Row mode focus: scale=${newScale.toFixed(2)}, translate=(${newTranslateX.toFixed(0)},${newTranslateY.toFixed(0)}), zone=${highlightedZoneObj.name}`);
      } else {
        // NORMAL ZONE MODE: Show zone with some surrounding context
        
        // Add padding around the zone (percentage of zone size)
        const paddingFactor = 0.7; // 70% padding
        const paddedWidth = zoneWidth * (1 + paddingFactor);
        const paddedHeight = zoneHeight * (1 + paddingFactor);
        
        // Calculate scale factors to fit the padded zone in the container
        const scaleX = containerWidth / paddedWidth;
        const scaleY = containerHeight / paddedHeight;
        
        // Use the smaller scale to ensure the zone fits completely
        newScale = Math.min(scaleX, scaleY);
        
        // Center the zone in the viewport
        const viewportCenterX = containerWidth / 2;
        const viewportCenterY = containerHeight / 2;
        
        newTranslateX = viewportCenterX - (zoneCenterX * newScale);
        newTranslateY = viewportCenterY - (zoneCenterY * newScale);
        
        console.log(`Zone focus mode: scale=${newScale.toFixed(2)}, translate=(${newTranslateX.toFixed(0)},${newTranslateY.toFixed(0)}), zone=${highlightedZoneObj.name}`);
      }
    } else {
      // CASE 2: Show the entire map
      // Calculate scale factors to fit the entire map in the container
      const scaleX = containerWidth / mapDimensions.width;
      const scaleY = containerHeight / mapDimensions.height;
      
      // Use the smaller scale to ensure everything fits
      newScale = Math.min(scaleX, scaleY) * 0.9; // 90% to add some padding
      
      // Center the map in the viewport
      const scaledMapWidth = mapDimensions.width * newScale;
      const scaledMapHeight = mapDimensions.height * newScale;
      
      newTranslateX = (containerWidth - scaledMapWidth) / 2;
      newTranslateY = (containerHeight - scaledMapHeight) / 2;
      
      console.log(`Full map view: scale=${newScale.toFixed(2)}, translate=(${newTranslateX.toFixed(0)},${newTranslateY.toFixed(0)})`);
    }
    
    // Update transform state
    setTransform({
      scale: newScale,
      translateX: newTranslateX,
      translateY: newTranslateY
    });
  }, [focusMode, highlightedZoneObj, useRowMode, mapDimensions]);
  
  // Setup resize observer to handle container size changes
  useEffect(() => {
    if (!containerRef.current) return;
    
    const handleResize = () => {
      calculateOptimalTransform();
    };
    
    // Initial calculation
    calculateOptimalTransform();
    
    // Add window resize listener
    window.addEventListener('resize', handleResize);
    
    // Setup ResizeObserver for container size changes
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(containerRef.current);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [calculateOptimalTransform]);
  
  // Debug logging for zone name matching
  useEffect(() => {
    console.log('ZoneHighlightMap - Highlighted zone:', highlightedZone);
    if (highlightedZoneObj) {
      console.log('ZoneHighlightMap - Found matching zone:', highlightedZoneObj.name);
    } else {
      console.log('ZoneHighlightMap - No matching zone found');
    }
  }, [highlightedZone, highlightedZoneObj]);
  
  // Calculate the appropriate font size based on zone dimensions
  const getFontSize = (width, height) => {
    const minDimension = Math.min(width, height);
    // Calculate a font size that scales with the zone but has min/max limits
    let fontSize = Math.max(minDimension * 0.3, 8);
    fontSize = Math.min(fontSize, 14);
    return `${fontSize}px`;
  };
  
  // Memoize the calculation of the zone elements
  const zoneElements = useMemo(() => {
    // Check if we're in row mode to hide the red highlighting
    const showRowsMode = focusMode === 'zone' && highlightedRows && highlightedRows.length > 0;
    
    return DEFAULT_ZONES.map(zone => {
      // Precise zone matching that respects zone boundaries
      const exactMatch = zone.name === highlightedZone;
      const caseInsensitiveMatch = zone.name.toLowerCase() === highlightedZone?.toLowerCase();
      
      // Extract zone numbers for precise comparison
      let zoneMatches = false;
      if (highlightedZone && !exactMatch && !caseInsensitiveMatch) {
        // Extract zone number from highlighted zone (e.g., "Zone 1" -> "1")
        const highlightedZoneMatch = highlightedZone.match(/Zone\s+(\d+(?:\.\d+)?)/i);
        const zoneNameMatch = zone.name.match(/Zone\s+(\d+(?:\.\d+)?)/i);
        
        if (highlightedZoneMatch && zoneNameMatch) {
          // Compare the actual zone numbers
          zoneMatches = highlightedZoneMatch[1] === zoneNameMatch[1];
        }
      }
      
      // Use exact match first, then case insensitive, then precise zone number matching
      const isHighlighted = exactMatch || caseInsensitiveMatch || zoneMatches;
      
      // If this is the highlighted zone in row mode, don't render it at all
      // This completely removes the zone when showing rows
      if (useRowMode && isHighlighted && focusMode === 'zone' && highlightedRows && highlightedRows.length > 0) {
        return null;
      }
      
      // Calculate dimensions based on the pixel ratio
      const width = zone.dimensions.cols * PIXEL_RATIO;
      const height = zone.dimensions.rows * PIXEL_RATIO;
      
      // Calculate appropriate font size for this zone
      const fontSize = getFontSize(width, height);
      
      return (
        <ZoneBox 
          key={zone.id}
          $isHighlighted={isHighlighted}
          $showRowsMode={showRowsMode}
          style={{
            left: `${zone.position.x * PIXEL_RATIO}px`,
            top: `${zone.position.y * PIXEL_RATIO}px`,
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <ZoneText $isHighlighted={isHighlighted} $fontSize={fontSize}>
            {zone.name}
          </ZoneText>
        </ZoneBox>
      );
    });
  }, [highlightedZone, focusMode, highlightedRows]);
  
  // Memoize row elements creation based on highlightedRows
  const rowElements = useMemo(() => {
    // Only show rows when we're in zone focus mode
    if (focusMode !== 'zone' || !highlightedZoneObj) {
      return null;
    }
    
    // Get the zone's dimensions and position to place rows inside it
    const zoneX = highlightedZoneObj.position.x * PIXEL_RATIO;
    const zoneY = highlightedZoneObj.position.y * PIXEL_RATIO;
    const zoneWidth = highlightedZoneObj.dimensions.cols * PIXEL_RATIO;
    const zoneHeight = highlightedZoneObj.dimensions.rows * PIXEL_RATIO;
    
    // Use utility functions to determine layout and dimensions
    const useActualDimensions = useRowMode && highlightedZoneObj.rowViewConfig?.invertRowsAndColumns;
    const shouldRenderHorizontally = shouldRenderRowsHorizontally(highlightedZoneObj, useActualDimensions);
    const maxRows = getMaxRows(highlightedZoneObj, useActualDimensions);
    
    // Enhanced debug logging for zone layout
    console.log(`Zone ${highlightedZoneObj.name} layout:`, {
      horizontal: shouldRenderHorizontally,
      maxRows,
      useActualDimensions,
      forceVertical: highlightedZoneObj.forceVerticalRows,
      shouldReverseRows: shouldReverseRowOrder(highlightedZoneObj)
    });
    
    // Add detailed logging for inverted zones
    if (highlightedZoneObj.rowViewConfig?.invertRowsAndColumns) {
      console.log('Inverted zone debug:', {
        zoneName: highlightedZoneObj.name,
        zoneId: highlightedZoneObj.id,
        isInverted: true,
        visualDimensions: highlightedZoneObj.dimensions,
        actualDimensions: highlightedZoneObj.rowViewConfig.dimensions,
        orientation: getZoneOrientation(highlightedZoneObj.id)
      });
    }
    
    // Helper function to check if a row belongs to the current zone
    const isRowInCurrentZone = (row) => {
      if (!row || !highlightedZoneObj) return false;
      
      // Get the normalized zone ID of the current highlighted zone
      const currentZoneId = highlightedZoneObj.id?.toLowerCase() || '';
      const currentZoneName = highlightedZoneObj.name?.toLowerCase() || '';
      
      // Match zone from row object - handle various formats
      const rowZoneId = row.zoneId?.toString().toLowerCase() || '';
      const rowZoneName = row.zoneName?.toString().toLowerCase() || '';
      
      // Direct matches
      if (rowZoneId === currentZoneId || rowZoneName === currentZoneName) {
        return true;
      }
      
      // Match standardized format: "zone-X" to "Zone X"
      if (rowZoneId === `zone-${currentZoneName.replace(/zone\s+/i, '').replace(/\s+/g, '-')}`) {
        return true;
      }
      
      // Match normalized zone ID: "zone-X" formats
      const highlightedZoneMatch = highlightedZone?.match(/Zone\s+(\d+(?:\.\d+)?)/i);
      if (highlightedZoneMatch && rowZoneId === `zone-${highlightedZoneMatch[1]}`) {
        return true;
      }
      
      return false;
    };
    
    // Get a set of scheduled row indices for quick lookup, but only for rows in this zone
    const scheduledRowIndices = new Set();
    if (highlightedRows && Array.isArray(highlightedRows)) {
      // Filter rows to only include those from the current zone
      highlightedRows.forEach(row => {
        if (row.rowIndex !== undefined && isRowInCurrentZone(row)) {
          scheduledRowIndices.add(row.rowIndex);
        }
      });
    }
    
    // Log how many rows were filtered
    console.log(`ZoneHighlightMap: Found ${scheduledRowIndices.size} rows in zone ${highlightedZoneObj.name} out of ${highlightedRows?.length || 0} total scheduled rows`);
    
    // Create array with all possible rows for this zone
    const allRows = Array.from({ length: maxRows }, (_, i) => i);
    
    // Calculate dimensions and spacing for rows based on orientation
    let rowWidth, rowHeight, rowSpacing;
    
    // Check if this is an inverted zone
    const isInverted = useActualDimensions && highlightedZoneObj.rowViewConfig?.invertRowsAndColumns;
    
    // Get the larger dimension (width or height) to ensure consistent row sizes
    const largerDimension = Math.max(zoneWidth, zoneHeight);
    const smallerDimension = Math.min(zoneWidth, zoneHeight);
    
    // Calculate target row size - more consistent across all zone types
    const targetRowSize = isInverted ? 
      Math.max(30, smallerDimension / 3) : // For inverted zones, use larger rows
      Math.min(25, smallerDimension / Math.max(maxRows, 1));
    
    if (shouldRenderHorizontally) {
      // For horizontal layout, rows are arranged side by side
      rowHeight = zoneHeight;  // Each row takes full zone height
      rowWidth = targetRowSize; // Use our consistent target size
      
      // More consistent spacing calculation
      const totalRowsWidth = rowWidth * maxRows;
      const remainingSpace = Math.max(0, zoneWidth - totalRowsWidth);
      rowSpacing = maxRows > 1 ? remainingSpace / (maxRows + 1) : remainingSpace / 2;
      
      // Ensure reasonable minimum spacing
      rowSpacing = Math.max(rowSpacing, isInverted ? 10 : 3);
    } else {
      // For vertical layout (standard), rows are stacked
      rowWidth = zoneWidth;  // Each row takes full zone width
      rowHeight = targetRowSize; // Use our consistent target size
      
      // More consistent spacing calculation
      const totalRowsHeight = rowHeight * maxRows;
      const remainingSpace = Math.max(0, zoneHeight - totalRowsHeight);
      rowSpacing = maxRows > 1 ? remainingSpace / (maxRows + 1) : remainingSpace / 2;
      
      // Ensure reasonable minimum spacing
      rowSpacing = Math.max(rowSpacing, isInverted ? 10 : 3);
    }
    
    // For inverted zones, ensure rows are more visible
    if (isInverted) {
      // Increase row thickness for inverted zones
      if (shouldRenderHorizontally) {
        rowWidth = Math.max(rowWidth, 30);
      } else {
        rowHeight = Math.max(rowHeight, 30);
      }
    }
    
    // Create row elements for all possible rows
    return allRows.map(rowIndex => {
      // Determine if this row is scheduled
      const isScheduled = scheduledRowIndices.has(rowIndex);
      
      // Find the corresponding row object if it's scheduled
      const scheduledRow = isScheduled && highlightedRows ? 
        highlightedRows.find(r => r.rowIndex === rowIndex) : null;
      
      // Get dimensions based on whether we're using actual or visual coordinates
      const dimensions = getZoneDimensions(highlightedZoneObj, useActualDimensions);
      
      // Transform row index if needed
      const displayRowIndex = useActualDimensions ? 
        transformToVisualRowIndex(rowIndex, highlightedZoneObj) : 
        rowIndex;
      
      // Add debug logging to track transformation values
      console.log('Row transformation:', {
        zoneId: highlightedZoneObj.id,
        rowIndex,
        displayRowIndex,
        actualRowIndex: useActualDimensions ? transformToActualRowIndex(rowIndex, highlightedZoneObj) : rowIndex,
        isInverted: useActualDimensions,
        orientation: shouldRenderHorizontally ? 'horizontal' : 'vertical'
      });
      
      // Calculate position and dimensions based on orientation
      let rowX, rowY, width, height;
      
      // Check if row order should be reversed - using updated logic
      const shouldReverse = shouldReverseRowOrder(highlightedZoneObj);
      
      // Log the row positioning details for debugging
      console.log('Row positioning:', {
        rowIndex,
        displayRowIndex,
        shouldReverse,
        orientation: getZoneOrientation(highlightedZoneObj.id),
        maxRows
      });
      
      if (shouldRenderHorizontally) {
        // Horizontal layout - rows positioned side by side
        // For reversed order, calculate position from the opposite end
        const rowPosition = shouldReverse ? 
          (maxRows - 1 - displayRowIndex) : 
          displayRowIndex;
          
        rowX = zoneX + rowSpacing + (rowPosition * (rowWidth + rowSpacing));
        rowY = zoneY;
        width = rowWidth;
        height = rowHeight;
      } else {
        // Vertical layout - rows stacked top to bottom
        // For reversed order, calculate position from the opposite end
        const rowPosition = shouldReverse ? 
          (maxRows - 1 - displayRowIndex) : 
          displayRowIndex;
          
        rowX = zoneX;
        rowY = zoneY + rowSpacing + (rowPosition * (rowHeight + rowSpacing));
        width = rowWidth;
        height = rowHeight;
      }
      
      // Calculate font size based on the row's smallest dimension
      const baseDimension = shouldRenderHorizontally ? rowWidth : rowHeight;
      const fontSize = `${Math.max(8, Math.min(12, baseDimension * 0.5))}px`;
      
      return (
        <RowBox
          key={`row-${scheduledRow?.id || `zone-${highlightedZoneObj.id}-row-${rowIndex}`}`}
          $isScheduled={isScheduled}
          style={{
            left: `${rowX}px`,
            top: `${rowY}px`,
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <RowText $fontSize={fontSize} $isScheduled={isScheduled}>
            Row {(useActualDimensions ? 
              transformToActualRowIndex(rowIndex, highlightedZoneObj) : 
              rowIndex) + 1}
          </RowText>
        </RowBox>
      );
    });
  }, [focusMode, highlightedZoneObj, highlightedRows, highlightedZone]);

  return (
    <ResponsiveMapContainer 
      ref={containerRef} 
      $height={customHeight} 
      $mobileHeight={mobileHeight}
    >
      <FullscreenBackground />
      <TransformedWarehouseLayout 
        ref={layoutRef}
        $scale={transform.scale}
        $translateX={transform.translateX}
        $translateY={transform.translateY}
        $fullWidth={true}
        $fullHeight={true}
      >
        {zoneElements}
        {rowElements}
      </TransformedWarehouseLayout>
    </ResponsiveMapContainer>
  );
};

export default ZoneHighlightMap;

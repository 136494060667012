import { useState, useEffect } from 'react';
import { useToast } from '@lib/contexts/ToastContext';
import { useProcessing } from '@lib/contexts/ProcessingContext';
import { addPalletToLocation } from '@features/operator/services/operatorService';

/**
 * Custom hook for managing storage assignment state and operations
 * @param {Object} props - Hook configuration
 * @param {string} props.locationId - Current location ID
 * @param {Function} props.onFinishPallet - Callback for pallet completion
 * @returns {Object} Storage assignment state and handlers
 */
export const useStorageAssignment = ({ locationId, onFinishPallet }) => {
  // Always declare hooks at the top level
  const [newlyCreatedPallet, setNewlyCreatedPallet] = useState(null);
  const [showAssignmentConfirm, setShowAssignmentConfirm] = useState(false);
  const [showStorageModal, setShowStorageModal] = useState(false);
  const [assignmentError, setAssignmentError] = useState(null);

  const { showToast } = useToast();
  const { showProcessing, hideProcessing } = useProcessing();

  // Log warnings in development for missing dependencies
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (!locationId) {
        console.warn('useStorageAssignment: locationId is missing, storage features will be disabled');
      }
      if (typeof onFinishPallet !== 'function') {
        console.warn('useStorageAssignment: onFinishPallet is not a function, storage features will be disabled');
      }
    }
  }, [locationId, onFinishPallet]);

  // Provide safe defaults for missing dependencies
  const safeOnFinishPallet = typeof onFinishPallet === 'function'
    ? onFinishPallet
    : () => {
        if (process.env.NODE_ENV === 'development') {
          console.warn('useStorageAssignment: Attempted to call onFinishPallet but it is not available');
        }
        return false;
      };

  /**
   * Handle storage assignment completion with bounded retries
   * @param {Object} result - Assignment result data
   */
  const handleStorageAssignment = async (result) => {
    // Return early if required dependencies are missing
    if (!locationId || typeof onFinishPallet !== 'function') {
      safeOnFinishPallet(null, false);
      return;
    }

    const MAX_RETRIES = 3;
    let retryCount = 0;

    setShowStorageModal(false);
    
    if (!result || result.error || !newlyCreatedPallet) {
      onFinishPallet(newlyCreatedPallet?.position, true);
      setNewlyCreatedPallet(null);
      return;
    }

    try {
      showProcessing({ message: 'Creating pallet and assigning to storage...' });

      while (retryCount < MAX_RETRIES) {
        try {
          // Log tomato data before adding pallet
          console.log('useStorageAssignment: Final pallet data before storage assignment:', {
            tomatoType: newlyCreatedPallet.tomatoType,
            tomatoOption: newlyCreatedPallet.tomatoOption,
            sortingGrade: newlyCreatedPallet.sortingGrade
          });

          // Use tomato data from result if provided, otherwise use from pallet
          const createdPallet = await addPalletToLocation({
            ...newlyCreatedPallet,
            position: String(newlyCreatedPallet.position).trim(),
            locationId,
            zoneId: result.zoneId,
            zoneName: result.zoneName,
            rowIndex: result.position.row,
            colIndex: result.position.col,
            sortingGrade: newlyCreatedPallet.sortingGrade,
            processStage: newlyCreatedPallet.processStage,
            // Explicitly include tomato type and option
            tomatoType: result.tomatoType || newlyCreatedPallet.tomatoType,
            tomatoOption: result.tomatoOption || newlyCreatedPallet.tomatoOption
          });

          onFinishPallet(newlyCreatedPallet.position, true, createdPallet);
          
          showToast({
            message: `Pallet assigned to ${result.zoneName || result.zoneId} Row ${result.position.row + 1}`,
            variant: 'success'
          });

          // Dispatch update event
          try {
            window.dispatchEvent(new CustomEvent('pallet-list-update', {
              detail: { type: 'add', pallet: createdPallet }
            }));
          } catch (eventError) {
            console.warn('Failed to dispatch update event:', eventError);
          }

          break;
        } catch (error) {
          retryCount++;
          if (retryCount === MAX_RETRIES) throw error;
          await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        }
      }
    } catch (error) {
      console.error('Error in storage assignment:', error);
      onFinishPallet(newlyCreatedPallet.position, false);
      showToast({
        message: `Assignment failed: ${error.message || 'Unknown error'}`,
        variant: 'error'
      });
    } finally {
      hideProcessing();
      setNewlyCreatedPallet(null);
      setAssignmentError(null);
    }
  };

  return {
    // State
    newlyCreatedPallet,
    showAssignmentConfirm,
    showStorageModal,
    assignmentError,
    
    // Setters
    setNewlyCreatedPallet,
    setShowAssignmentConfirm,
    setShowStorageModal,
    setAssignmentError,
    
    // Handlers
    handleStorageAssignment
  };
};

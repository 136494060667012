/**
 * SSEEventHandler.js
 * Manages SSE event processing and subscription logic
 */

import eventTranslator from '../EventTranslator';
import sseCoordinator from '../SSECoordinator';

class SSEEventHandler {
  constructor(clientId) {
    this.clientId = clientId;
    this.eventHandlers = new Map();
    this.lastEventId = null;
    this.lastSequence = 0;
    this.pendingStorageUpdates = new Map(); // Track pending storage zone updates
    this.lastHeartbeatTime = null;
  }

  /**
   * Subscribes to an event type
   * @param {string} eventType - The event type to subscribe to
   * @param {function} handler - The event handler
   * @returns {function} Unsubscribe function
   */
  subscribe(eventType, handler) {
    if (!this.eventHandlers.has(eventType)) {
      this.eventHandlers.set(eventType, new Set());
    }
    this.eventHandlers.get(eventType).add(handler);

    console.log('Event subscription added:', {
      eventType,
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });

    return () => this.unsubscribe(eventType, handler);
  }

  /**
   * Unsubscribes from an event type
   * @param {string} eventType - The event type to unsubscribe from
   * @param {function} handler - The event handler to remove
   */
  unsubscribe(eventType, handler) {
    const handlers = this.eventHandlers.get(eventType);
    if (handlers) {
      handlers.delete(handler);
      console.log('Event subscription removed:', {
        eventType,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Processes an SSE event with proper error boundaries
   * @param {MessageEvent} event - The SSE event to process
   */
  processEvent(event) {
    try {
      // Validate event data
      if (!event || !event.data) {
        throw new Error('Invalid event format');
      }

      console.log('Processing SSE event:', {
        id: event.lastEventId,
        data: event.data,
        timestamp: new Date().toISOString()
      });

      // Special handling for heartbeat events
      if (event.data === 'ping') {
        this._handleHeartbeatEvent();
        return;
      }

      // Parse JSON data for all other events
      let parsedData;
      try {
        parsedData = JSON.parse(event.data);
      } catch (error) {
        console.error('Failed to parse event data:', {
          error: error.message,
          data: event.data,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
        return;
      }
      
      // Update sequence tracking
      this.lastEventId = event.lastEventId;
      this.lastSequence = parsedData.sequence || 0;

      // Process event based on type
      switch (event.type) {
        case 'update':
          this._handleUpdateEvent(parsedData);
          break;
        case 'connected':
          this._handleConnectedEvent(parsedData);
          break;
        default:
          console.warn('Unknown event type:', {
            type: event.type,
            clientId: this.clientId,
            timestamp: new Date().toISOString()
          });
      }
    } catch (error) {
      console.error('Error processing event:', {
        error: error.message,
        stack: error.stack,
        eventType: event?.type,
        data: event?.data,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Handles update events with proper type checking
   * @param {Object} parsedData - The parsed event data
   * @private
   */
  _handleUpdateEvent(parsedData) {
    // Validate required fields
    if (!parsedData.type) {
      console.error('Update event missing type:', {
        data: parsedData,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      return;
    }

    // Special handling for storage position updates
    if (parsedData.type === 'STORAGE_POSITION_UPDATE') {
      this._handleStoragePositionUpdate(parsedData);
      return;
    }

    // Get all relevant handlers
    const handlersToNotify = this._getRelevantHandlers(parsedData);

    // Process event through coordinator to prevent duplicates
    handlersToNotify.forEach(({ eventType, handler }) => {
      try {
        const translatedEvent = eventTranslator.translate(parsedData.type, parsedData.data);
        
        const wasProcessed = sseCoordinator.processEvent(
          this.clientId,
          eventType,
          translatedEvent ? translatedEvent.data : parsedData.data,
          this.lastEventId
        );

        if (wasProcessed) {
          // Check for pending storage updates before processing
          if (parsedData.type === 'PALLET_UPDATE' && parsedData.data?.action === 'add') {
            const updatedData = this._applyPendingStorageUpdates(parsedData.data);
            handler(updatedData);
          } else {
            handler(translatedEvent ? translatedEvent.data : parsedData.data);
          }
        }
      } catch (error) {
        console.error('Error in event handler:', {
          error: error.message,
          stack: error.stack,
          eventType,
          type: parsedData.type,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
      }
    });
  }

  /**
   * Handles storage position update events
   * @param {Object} parsedData - The parsed event data
   * @private
   */
  _handleStoragePositionUpdate(parsedData) {
    try {
      const { data } = parsedData;
      if (data.action === 'assign_to_zone' && data.position) {
        const { palletId, zoneId } = data.position;
        
        // Create the storage zone once using correct format
        const storageZone = `${zoneId}-row-0`; // Default to row 0
        
        // Store the update for later application
        this.pendingStorageUpdates.set(palletId, {
          storageZone: storageZone,
          timestamp: Date.now()
        });

        console.log('Stored pending storage update:', {
          palletId,
          zoneId,
          storageZone,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });

        // Also immediately apply the update to existing pallets
        // First, find all relevant handlers for PALLET_UPDATED events
        const updateHandlers = this._getRelevantHandlers({
          type: 'PALLET_UPDATED'
        });

        // Construct a storage zone update event - only using storageZone property
        const storageUpdateEvent = {
          action: 'update_storage_zone',
          palletId,
          storageZone: storageZone,
          timestamp: Date.now()
        };

        // Notify all relevant handlers
        updateHandlers.forEach(({ handler }) => {
          try {
            console.log('Immediately applying storage zone update:', {
              palletId,
              storageZone,
              clientId: this.clientId,
              timestamp: new Date().toISOString()
            });
            handler(storageUpdateEvent);
          } catch (error) {
            console.error('Error applying immediate storage update:', {
              error: error.message,
              stack: error.stack,
              palletId,
              zoneId,
              clientId: this.clientId,
              timestamp: new Date().toISOString()
            });
          }
        });

        // Clean up old pending updates (older than 5 minutes)
        this._cleanupPendingUpdates();
      }
    } catch (error) {
      console.error('Error handling storage position update:', {
        error: error.message,
        stack: error.stack,
        data: parsedData,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Applies any pending storage updates to pallets
   * @param {Object} data - The pallet update data
   * @returns {Object} Updated data with storage zones applied
   * @private
   */
  _applyPendingStorageUpdates(data) {
    if (!data || !data.pallets || !Array.isArray(data.pallets)) {
      return data;
    }

    const updatedPallets = data.pallets.map(pallet => {
      if (!pallet) return pallet;
      
      const pendingUpdate = this.pendingStorageUpdates.get(pallet.id);
      if (pendingUpdate) {
        console.log('Applying pending storage update:', {
          palletId: pallet.id,
          storageZone: pendingUpdate.storageZone,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
        return { ...pallet, storageZone: pendingUpdate.storageZone };
      }
      return pallet;
    });

    return {
      ...data,
      pallets: updatedPallets
    };
  }

  /**
   * Cleans up old pending updates
   * @private
   */
  _cleanupPendingUpdates() {
    const now = Date.now();
    const maxAge = 5 * 60 * 1000; // 5 minutes

    for (const [palletId, update] of this.pendingStorageUpdates.entries()) {
      if (now - update.timestamp > maxAge) {
        this.pendingStorageUpdates.delete(palletId);
        console.log('Cleaned up stale pending update:', {
          palletId,
          age: `${Math.round((now - update.timestamp) / 1000)}s`,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
      }
    }
  }

  /**
   * Gets all relevant handlers for an event
   * @param {Object} parsedData - The parsed event data
   * @returns {Array} Array of handler objects
   * @private
   */
  _getRelevantHandlers(parsedData) {
    const relevantHandlers = [];

    this.eventHandlers.forEach((handlers, eventType) => {
      const shouldProcess = this._shouldProcessEvent(parsedData.type, eventType);
      
      if (shouldProcess) {
        handlers.forEach(handler => {
          relevantHandlers.push({ eventType, handler });
        });
      }
    });

    return relevantHandlers;
  }

  /**
   * Determines if an event should be processed for a given type
   * @param {string} serverEventType - The event type from server
   * @param {string} subscribedType - The subscribed event type
   * @returns {boolean} Whether the event should be processed
   * @private
   */
  _shouldProcessEvent(serverEventType, subscribedType) {
    // Direct match
    if (serverEventType === subscribedType) {
      return true;
    }

    // Special handling for pallet updates
    if (serverEventType === 'PALLET_UPDATE') {
      return ['PALLET_UPDATED', 'PALLETS_STATUS_CHANGED', 'PALLET_ASSIGNED_TO_ORDER']
        .includes(subscribedType);
    }

    // Special handling for assignments
    if (serverEventType === 'ASSIGNMENT_UPDATE') {
      return ['PALLET_ASSIGNED_TO_ORDER', 'PALLETS_STATUS_CHANGED']
        .includes(subscribedType);
    }

    // Special handling for storage updates
    if (serverEventType === 'STORAGE_POSITION_UPDATE') {
      return ['PALLET_UPDATED', 'PALLETS_STATUS_CHANGED']
        .includes(subscribedType);
    }

    return false;
  }

  /**
   * Handles connected events
   * @param {Object} data - The connected event data
   * @private
   */
  _handleConnectedEvent(data) {
    try {
      console.log('Connection established:', {
        clientId: data.clientId,
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error handling connected event:', {
        error: error.message,
        stack: error.stack,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Handles heartbeat events
   * @private
   */
  _handleHeartbeatEvent() {
    this.lastHeartbeatTime = Date.now();
    console.debug('Heartbeat received:', {
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Clears all event handlers and state
   */
  clearHandlers() {
    this.eventHandlers.clear();
    this.pendingStorageUpdates.clear();
    this.lastHeartbeatTime = null;
    console.log('All event handlers and state cleared:', {
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Gets the last event sequence number
   * @returns {number} The last sequence number
   */
  getLastSequence() {
    return this.lastSequence;
  }

  /**
   * Gets the last event ID
   * @returns {string|null} The last event ID
   */
  getLastEventId() {
    return this.lastEventId;
  }

  /**
   * Gets time since last heartbeat
   * @returns {number|null} Milliseconds since last heartbeat, or null if no heartbeat
   */
  getTimeSinceLastHeartbeat() {
    if (!this.lastHeartbeatTime) {
      return null;
    }
    return Date.now() - this.lastHeartbeatTime;
  }
}

export default SSEEventHandler;

import styled, { keyframes } from 'styled-components';
import { theme } from '@styles/theme';
import { zIndex } from '@styles/constants/zIndex';

// Define animations first to avoid initialization order issues
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  height: 100%;
  max-height: calc(100vh - 64px); // Account for header
  overflow: hidden;
  background-color: ${theme.colors.background.default};
`;

export const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: ${theme.colors.background.paper};
  color: ${theme.colors.text.primary};
  border: 1px solid ${theme.colors.border.light};
  border-radius: ${theme.borderRadius.md};
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 100px;
  height: 40px;
  
  &:hover:not(:disabled) {
    background-color: ${theme.colors.background.light};
    border-color: ${theme.colors.border.dark};
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .spinning {
    animation: ${spin} 1s linear infinite;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 1.5rem;
  flex: 1;
  min-height: 0; // Required for proper scrolling
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};
  padding: 1.5rem;
`;

export const SideSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.colors.background.default};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.border.light};
    border-radius: 4px;
    
    &:hover {
      background: ${theme.colors.border.dark};
    }
  }
`;


export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.modal + 1};
`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid ${theme.colors.background.paper};
  border-top-color: ${theme.colors.primary.main};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

// Responsive adjustments
export const ResponsiveContainer = styled(Container)`
  @media (max-width: 1200px) {
    padding: 1rem;
  }

  @media (max-width: 992px) {
    ${Content} {
      grid-template-columns: 1fr;
    }

    ${SideSection} {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  @media (max-width: 768px) {
    ${SideSection} {
      grid-template-columns: 1fr;
    }
  }
`;

import React from 'react';
import {
  ProgressContainer,
  ProgressBar,
  ProgressFill,
  ProgressText
} from '../styles';

const ProgressTracker = ({ processedPallets, totalPallets, progressPercentage }) => {
  // Ensure all values are valid numbers with defaults
  const processed = processedPallets || 0;
  const total = totalPallets || 0;
  const progress = progressPercentage || 0;
  
  return (
    <ProgressContainer>
      <ProgressBar>
        <ProgressFill progress={progress} />
      </ProgressBar>
      <ProgressText>
        <span>
          {processed}/{total} Pallets
        </span>
        <span>{Math.round(progress)}% Complete</span>
      </ProgressText>
    </ProgressContainer>
  );
};

export default ProgressTracker;

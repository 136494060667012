import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMobileOrderAssignment } from './hooks';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 0 20px 0;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PalletInfo = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 16px;
  margin-bottom: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ theme }) => theme.typography.body2};
`;

const InfoValue = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  font-weight: 500;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.error.light};
  color: ${({ theme }) => theme.colors.error.main};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 16px;
`;

const NoOrdersMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 16px;
`;

const MobileOrderAssignmentModal = ({
  isOpen,
  onClose,
  onAssign,
  palletDetails,
  orders = [],
  loading = false,
  error = null,
  palletRoute = 'standard'
}) => {
  // Filter orders based on the pallet route at the component level
  const filteredOrders = React.useMemo(() => {
    console.log(`Filtering ${orders.length} orders for route: ${palletRoute}`);
    if (!Array.isArray(orders)) {
      console.warn('orders is not an array:', orders);
      return [];
    }
    
    const isKleinpakRoute = palletRoute === 'kleinpak';
    return orders.filter(order => {
      const isKleinpakOrder = !!order.isKleinpakOrder;
      const matches = isKleinpakRoute ? isKleinpakOrder : !isKleinpakOrder;
      console.log(`Order ${order.id}: isKleinpakOrder=${isKleinpakOrder}, matches=${matches}`);
      return matches;
    });
  }, [orders, palletRoute]);

  const {
    selectedOrder,
    setSelectedOrder,
    extraBoxes,
    setExtraBoxes,
    handleAssign,
    resetForm,
    isAssigning
  } = useMobileOrderAssignment({
    onAssign,
    onClose,
    palletDetails
  });

  // Reset the form when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Assign Pallet to Order</Title>
        
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        
        <PalletInfo>
          <InfoRow>
            <InfoLabel>Pallet ID</InfoLabel>
            <InfoValue>{palletDetails?.id || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Type</InfoLabel>
            <InfoValue>{palletDetails?.tomatoType || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Box Type</InfoLabel>
            <InfoValue>{palletDetails?.boxType || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Weight</InfoLabel>
            <InfoValue>{palletDetails?.weight ? `${palletDetails.weight}kg` : 'Unknown'}</InfoValue>
          </InfoRow>
        </PalletInfo>
        
        {loading ? (
          <LoadingMessage>Loading orders...</LoadingMessage>
        ) : filteredOrders.length === 0 ? (
          <NoOrdersMessage>
            No {palletRoute} orders available. Please create an order first.
          </NoOrdersMessage>
        ) : (
          <FormGroup>
            <Label htmlFor="order-select">Select Order</Label>
            <Select
              id="order-select"
              value={selectedOrder?.id || ''}
              onChange={(e) => {
                const orderId = e.target.value;
                const selected = filteredOrders.find(order => order.id.toString() === orderId);
                setSelectedOrder(selected || null);
              }}
            >
              <option value="">-- Select an order --</option>
              {filteredOrders.map((order) => (
                <option key={order.id} value={order.id}>
                  {order.customer_name || 'Unnamed Order'} - {order.order_number || order.id}
                </option>
              ))}
            </Select>
          </FormGroup>
        )}
        
        {selectedOrder && (
          <FormGroup>
            <Label htmlFor="extra-boxes">Extra Boxes (Optional)</Label>
            <Input
              id="extra-boxes"
              type="number"
              value={extraBoxes}
              onChange={(e) => setExtraBoxes(e.target.value)}
              placeholder="Enter number of extra boxes"
            />
          </FormGroup>
        )}
        
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            onClick={handleAssign}
            disabled={!selectedOrder || isAssigning || loading}
          >
            {isAssigning ? 'Assigning...' : 'Assign'}
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default MobileOrderAssignmentModal;

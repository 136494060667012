import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useDashboard } from '../../contexts/DashboardContext';
import { useProcessing, useUpdate } from '@lib/contexts';
import { useToast } from '@lib/contexts';
import { tomatoOptions } from '@lib/constants/tomatoOptions';
import {
  fetchWeeklyTallyData,
  exportTallyToExcel,
  getCurrentWeekStart,
  formatTallyData,
  parseTallyData,
  saveWeeklyTallyCard
} from '../../services/weeklyTallyService';
import HistoryModal from './HistoryModal';
import {
  TallyCard,
  TallyHeader,
  TallyGrid,
  TallySection,
  TallyRow,
  TallyCell,
  TallyControls,
  TallyToggle,
  TallyButton,
  TallyTotal
} from './styles';

const DAYS_OF_WEEK = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
const ROW_TYPES = ['licht', 'midden', 'rood'];

// Default tomato sections - will be dynamically expanded based on actual data
const DEFAULT_TOMATO_SECTIONS = {
  Round: ['B', 'A grof +', 'A grof', 'A fijn'],
  Plum: ['B', '5-Pack', '6-Pack', 'A fijn']
};

const WeeklyPalletTally = () => {
  const [selectedType, setSelectedType] = useState('Round');
  const [tallyData, setTallyData] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const { selectedLocation } = useDashboard();
  const { user } = useUserSession();
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();

  const loadTallyData = useCallback(async () => {
    if (!selectedLocation?.id) return;

    showProcessing({ message: 'Loading tally data...', showOverlay: true });
    try {
      const startDate = getCurrentWeekStart();
      const data = await fetchWeeklyTallyData(
        selectedLocation.id,
        startDate,
        selectedType
      );
      setTallyData(parseTallyData(data));
    } catch (error) {
      console.error('Error loading tally data:', error);
      showToast({
        message: 'Failed to load tally data',
        variant: 'error'
      });
    } finally {
      hideProcessing();
    }
  }, [selectedLocation?.id, selectedType, showProcessing, hideProcessing, showToast, setTallyData]);

  // Create a memoized callback for the SSE refresh
  const refreshTallyData = useCallback(() => {
    if (selectedLocation?.id) {
      console.log('Refreshing weekly tally data due to SSE event');
      loadTallyData();
    }
  }, [selectedLocation?.id, loadTallyData]);

  // Handle SSE events for both pallet and storage updates
  const handleSSEEvent = useCallback((data) => {
    console.log('Received SSE event:', data);
    
    // Refresh tally data for relevant events
    if (data.type === 'PALLET_UPDATE') {
      // For pallet updates, check if it's a finished pallet
      if (data.pallets?.some(p => !p.is_unfinished || p.removed_from_storage)) {
        console.log('Refreshing tally due to finished pallet update');
        refreshTallyData();
      }
    } else if (data.type === 'STORAGE_POSITION_UPDATE') {
      // For storage position updates, always refresh as it affects storage status
      console.log('Refreshing tally due to storage position update');
      refreshTallyData();
    }
  }, [refreshTallyData]);
  // Get the connection status from the central UpdateContext
  const { isConnected } = useUpdate();

  // Effect for initial data loading when location/type changes
  useEffect(() => {
    if (selectedLocation?.id) {
      loadTallyData();
    }
  }, [selectedLocation?.id, selectedType, loadTallyData]);
  
  // Effect for subscribing to the main SSE system's events
  useEffect(() => {
    if (!selectedLocation?.id) {
      return;
    }
    
    // Create event listeners for the events we care about
    const handlePalletUpdate = (event) => {
      if (!event.detail) return;
      
      const { type, data } = event.detail;
      
      if (type === 'PALLET_UPDATE') {
        // For pallet updates, check if it's a finished pallet
        const pallets = data?.pallets || [];
        if (pallets.some(p => !p.is_unfinished || p.removed_from_storage)) {
          console.log('Refreshing tally due to finished pallet update');
          refreshTallyData();
        }
      }
    };
    
    const handleStorageUpdate = (event) => {
      if (!event.detail) return;
      
      const { type } = event.detail;
      
      if (type === 'STORAGE_POSITION_UPDATE') {
        // For storage position updates, always refresh as it affects storage status
        console.log('Refreshing tally due to storage position update');
        refreshTallyData();
      }
    };
    
    // Add the event listeners
    window.addEventListener('sse-event', handlePalletUpdate);
    window.addEventListener('sse-event', handleStorageUpdate);
    
    console.log('Weekly tally subscribed to main SSE events for location:', selectedLocation.id);
    
    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('sse-event', handlePalletUpdate);
      window.removeEventListener('sse-event', handleStorageUpdate);
      console.log('Weekly tally unsubscribed from main SSE events');
    };
  }, [selectedLocation?.id, refreshTallyData]);
  
  // Effect for snapshot scheduling
  useEffect(() => {
    if (!selectedLocation?.id) {
      return;
    }
    
    // Schedule daily snapshot at 23:00
    const now = new Date();
    const snapshotTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23, // 23:00
      0,
      0
    );
    
    // If it's past 23:00, schedule for next day
    if (now > snapshotTime) {
      snapshotTime.setDate(snapshotTime.getDate() + 1);
    }
    
    const delay = snapshotTime.getTime() - now.getTime();
    const MAX_TIMEOUT = 2147483647; // Maximum safe timeout value (~24.8 days)
    
    // Use a bounded timeout value for safety
    const safeDelay = Math.min(delay, MAX_TIMEOUT);
    
    const snapshotTimer = setTimeout(() => {
      try {
        saveWeeklyTallyCard(
          selectedLocation.id,
          getCurrentWeekStart(),
          selectedType,
          tallyData
        );
      } catch (error) {
        console.error('Error saving weekly tally snapshot:', error);
      }
    }, safeDelay);
    
    return () => {
      clearTimeout(snapshotTimer);
    };
  }, [selectedLocation?.id, selectedType]);

  const handleExport = async () => {
    if (!selectedLocation?.id) return;

    showProcessing({ message: 'Exporting to Excel...', showOverlay: true });
    try {
      await exportTallyToExcel(
        selectedLocation.id,
        getCurrentWeekStart(),
        selectedType
      );
      showToast({
        message: 'Export completed successfully',
        variant: 'success'
      });
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      showToast({
        message: 'Failed to export to Excel',
        variant: 'error'
      });
    } finally {
      hideProcessing();
    }
  };

  const handleHistoryClick = () => {
    setShowHistory(true);
  };

  const calculateTotal = (section, rowType) => {
    if (!tallyData[section]?.[rowType]) return 0;
    return tallyData[section][rowType].reduce((sum, count) => sum + count, 0);
  };

  // Get actual tally sections (keys) from the tallyData - falling back to defaults if empty
  const getTomatoSections = () => {
    const sections = Object.keys(tallyData);
    if (sections.length === 0) {
      return DEFAULT_TOMATO_SECTIONS[selectedType] || [];
    }
    return sections;
  };

  const calculateDayTotal = (dayIndex) => {
    const sections = getTomatoSections();
    return sections.reduce((total, section) => {
      return total + ROW_TYPES.reduce((sectionTotal, rowType) => {
        return sectionTotal + (tallyData[section]?.[rowType]?.[dayIndex] || 0);
      }, 0);
    }, 0);
  };

const calculateGrandTotal = () => {
  return DAYS_OF_WEEK.reduce((total, _, dayIndex) => {
    return total + calculateDayTotal(dayIndex);
  }, 0);
};

/**
 * Format tally value for display
 * @param {number} value - The tally value (may be decimal)
 * @returns {string} - Formatted value for display
 */
const formatTallyValue = (value) => {
  // If it's a whole number, display as integer
  if (Math.floor(value) === value) {
    return value.toString();
  }
  
  // Otherwise format with 1 decimal place
  return value.toFixed(1);
};

  return (
    <TallyCard>
      <TallyControls>
        <TallyToggle>
          <button 
            onClick={() => setSelectedType('Round')}
            className={selectedType === 'Round' ? 'active' : ''}
          >
            Round
          </button>
          <button 
            onClick={() => setSelectedType('Plum')}
            className={selectedType === 'Plum' ? 'active' : ''}
          >
            Plum
          </button>
        </TallyToggle>
        <div>
          <TallyButton onClick={handleExport}>Export to Excel</TallyButton>
          <TallyButton onClick={handleHistoryClick}>History</TallyButton>
        </div>
      </TallyControls>

      <TallyGrid>
        <TallyHeader>
          <div></div>
          {DAYS_OF_WEEK.map(day => (
            <div key={day}>{day}</div>
          ))}
          <div>Total</div>
        </TallyHeader>

        {getTomatoSections().map(section => (
          <TallySection key={section}>
            <div className="section-header">{section}</div>
            {ROW_TYPES.map(rowType => (
              <TallyRow key={`${section}-${rowType}`}>
                <div className="row-type">{rowType}</div>
          {DAYS_OF_WEEK.map((_, dayIndex) => (
            <TallyCell key={dayIndex}>
              {formatTallyValue(tallyData[section]?.[rowType]?.[dayIndex] || 0)}
            </TallyCell>
          ))}
          <TallyCell className="total">
            {formatTallyValue(calculateTotal(section, rowType))}
          </TallyCell>
              </TallyRow>
            ))}
          </TallySection>
        ))}

        <TallyTotal>
          <div>TOTAL</div>
          {DAYS_OF_WEEK.map((_, dayIndex) => (
            <div key={dayIndex}>{formatTallyValue(calculateDayTotal(dayIndex))}</div>
          ))}
          <div>{formatTallyValue(calculateGrandTotal())}</div>
        </TallyTotal>
      </TallyGrid>

      <HistoryModal
        isOpen={showHistory}
        onClose={() => setShowHistory(false)}
        locationId={selectedLocation?.id}
      />
    </TallyCard>
  );
};

export default WeeklyPalletTally;

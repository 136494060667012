import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { scanHistoryService } from '../../services/scanHistoryService';

const HistoryContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background};
  z-index: 40;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary}40;
    border-radius: 4px;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 32px 16px;
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.7;
`;

const ScanItem = styled(motion.div)`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const ScanTime = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 4px;
`;

const ScanData = styled.pre`
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  word-break: break-word;
  color: ${({ theme }) => theme.colors.text};
  font-family: monospace;
`;

const CloseIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
  </svg>
);

const containerVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};

const itemVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 20 }
};

export const ScanHistory = ({ onClose }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const loadHistory = async () => {
      const scans = await scanHistoryService.getScans();
      setHistory(scans.reverse()); // Show newest first
    };
    loadHistory();
  }, []);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  return (
    <AnimatePresence>
      <HistoryContainer
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Header>
          <Title>Scan History</Title>
          <CloseButton onClick={onClose} aria-label="Close history">
            <CloseIcon />
          </CloseButton>
        </Header>

        <ScrollContainer>
          {history.length === 0 ? (
            <EmptyState>
              <p>No scans yet</p>
              <small>Scanned codes will appear here</small>
            </EmptyState>
          ) : (
            <AnimatePresence>
              {history.map((scan, index) => (
                <ScanItem
                  key={scan.timestamp}
                  variants={itemVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ delay: index * 0.05 }}
                >
                  <ScanTime>{formatTimestamp(scan.timestamp)}</ScanTime>
                  <ScanData>
                    {JSON.stringify(scan.data, null, 2)}
                  </ScanData>
                </ScanItem>
              ))}
            </AnimatePresence>
          )}
        </ScrollContainer>
      </HistoryContainer>
    </AnimatePresence>
  );
};

import { api } from '@lib/api';

export const putPalletIntoProduction = async (palletId, locationId, lineNumber) => {
  try {
    const response = await api.post(`/pallets/${palletId}/production`, {
      locationId,
      lineNumber
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to put pallet into production');
  }
};

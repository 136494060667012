import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  // button styles here
`;

export const Button = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

// Add default export
export default Button;

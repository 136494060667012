import styled from 'styled-components';
import { theme } from '@styles/theme';
import { zIndex } from '@styles/constants/zIndex';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  background-color: ${theme.colors.background.paper};
  box-shadow: ${theme.shadows.lg};
  z-index: ${zIndex.modal};
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${props => props.color || theme.colors.primary.main};
  color: white;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
`;

export const Content = styled.div`
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const SectionTitle = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${theme.colors.border.light};
`;

export const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.text.secondary};
  font-weight: 500;
`;

export const Value = styled.div`
  color: ${theme.colors.text.primary};
  text-align: right;
  word-break: break-word;
  max-width: 60%;
`;

export const StateChanges = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const StateChange = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: ${theme.colors.background.default};
  border-radius: ${theme.borderRadius.sm};
  font-size: 0.875rem;
`;

export const ChangeValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.text.primary};
  
  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const Notes = styled.div`
  padding: 0.75rem;
  background-color: ${theme.colors.background.default};
  border-radius: ${theme.borderRadius.sm};
  font-size: 0.875rem;
  color: ${theme.colors.text.primary};
  white-space: pre-wrap;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: auto;
  padding: 0.75rem;
  background-color: ${theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: ${theme.borderRadius.sm};
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${theme.colors.primary.dark};
  }
`;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Alert, AlertDescription } from '@lib/components/ui';
import { UserContext } from '@lib/contexts';
import { Check, AlertTriangle, Loader } from 'lucide-react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { 
  palletTypes, 
  boxTypes, 
  tomatoOptions
} from '@lib/constants';
import {
  PROCESS_STAGES,
  SORTING_GRADES,
  getDisplayName
} from '@features/shared/constants/processStages';

const FormContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ hasError, theme }) => 
    hasError ? theme.colors.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ hasError, theme }) => 
      hasError ? `${theme.colors.error}20` : `${theme.colors.primary.main}20`};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darker};
    cursor: not-allowed;
  }
`;

const Select = styled(Input).attrs({ as: 'select' })`
  cursor: pointer;
  
  &:not([value=""]) {
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &[value=""] {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const ErrorText = styled.p`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme }) => theme.typography.body2};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}
`;

const ValidationIndicator = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.sm};
  top: 50%;
  transform: translateY(-50%);
  color: ${({ isValid, theme }) => 
    isValid ? theme.colors.success : theme.colors.error};
`;

export const PalletForm = ({
  onPalletAdded,
  onCancel,
  tomatoType,
  defaultProcessStage = PROCESS_STAGES.UNPROCESSED,
  initialData = null,
  locationId,
}) => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    processStage: initialData?.processStage || defaultProcessStage,
    sortingGrade: initialData?.sortingGrade || '',
    tomatoOption: initialData?.tomatoOption || '',
    tomatoType: initialData?.tomatoType || tomatoType || 'Round',
    weight: initialData?.weight || '',
    order_id: initialData?.order_id || '',
    quantity: initialData?.quantity || 1,
    colliCount: initialData?.colliCount || '',
  });

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [errors, setErrors] = useState({});
  const [availableOrders, setAvailableOrders] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);

  const debouncedValidation = React.useCallback(
    debounce(async (name, value, formData) => {
      try {
        const response = await axios.post('/api/pallets/validate', {
          ...formData,
          [name]: value,
          locationId
        });
        
        if (!response.data.valid && response.data.errors[name]) {
          setErrors(prev => ({
            ...prev,
            [name]: response.data.errors[name][0]
          }));
          setIsValid(false);
        } else {
          setIsValid(Object.keys(errors).length === 0);
        }
      } catch (error) {
        if (error.response?.status !== 401) {
          console.error('Validation error:', error);
          setErrors(prev => ({
            ...prev,
            [name]: 'Failed to validate field'
          }));
          setIsValid(false);
        }
      } finally {
        setIsValidating(false);
      }
    }, 300),
    [locationId]
  );

  const validateFieldSync = (name, value) => {
    switch (name) {
      case 'weight':
        if (!value) return '';
        const weight = parseFloat(value);
        if (isNaN(weight) || weight < 0) return 'Weight must be a positive number';
        if (weight > 2000) return 'Weight must not exceed 2000 kg';
        break;
      case 'colliCount':
        if (!value) return '';
        const count = parseInt(value);
        if (isNaN(count) || count < 0) return 'Box count must be a positive number';
        if (count > 200) return 'Box count must not exceed 200';
        break;
      case 'quantity':
        const qty = parseInt(value);
        if (isNaN(qty) || qty < 1) return 'Quantity must be at least 1';
        if (qty > 50) return 'Quantity must not exceed 50';
        break;
      case 'palletType':
        if (!value) return 'Pallet type is required';
        break;
      case 'boxType':
        if (!value) return 'Box type is required';
        break;
    }
    return '';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => {
      const newFormData = {
        ...prev,
        [name]: value
      };
      
      const syncError = validateFieldSync(name, value);
      if (syncError) {
        setErrors(prev => ({
          ...prev,
          [name]: syncError
        }));
        setIsValid(false);
      } else {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[name];
          return newErrors;
        });
        
        setIsValidating(true);
        debouncedValidation(name, value, newFormData);
      }
      
      return newFormData;
    });
  };

  const handleSubmit = async () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      const error = validateFieldSync(key, formData[key]);
      if (error) newErrors[key] = error;
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setFeedback({
        type: 'error',
        message: 'Please correct the errors before submitting'
      });
      return;
    }

    setLoading(true);
    try {
      await onPalletAdded({
        ...formData,
        locationId,
        userName: user.username,
        user_id: user.id,
      });
      setFeedback({
        type: 'success',
        message: 'Pallet added successfully'
      });
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.message || 'Failed to add pallet'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      {feedback && (
        <Alert className={`mb-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}
      
      <FormGrid>
        <FormGroup>
          <Label>Tomato Type</Label>
          <Select
            name="tomatoType"
            value={formData.tomatoType}
            onChange={handleInputChange}
            hasError={!!errors.tomatoType}
          >
            <option value="">Select Tomato Type</option>
            {Object.keys(tomatoOptions).map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.tomatoType && <ErrorText>{errors.tomatoType}</ErrorText>}
        </FormGroup>

        {Object.keys(tomatoOptions[formData.tomatoType] || {}).length > 0 && (
          <FormGroup>
            <Label>Tomato Option</Label>
            <Select
              name="tomatoOption"
              value={formData.tomatoOption}
              onChange={handleInputChange}
              hasError={!!errors.tomatoOption}
              disabled={!formData.tomatoType}
            >
              <option value="">Select Tomato Option</option>
              {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </Select>
            {errors.tomatoOption && <ErrorText>{errors.tomatoOption}</ErrorText>}
          </FormGroup>
        )}

        <FormGroup>
          <Label>Pallet Type</Label>
          <Select
            name="palletType"
            value={formData.palletType}
            onChange={handleInputChange}
            hasError={!!errors.palletType}
          >
            <option value="">Select Pallet Type</option>
            {palletTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.palletType && <ErrorText>{errors.palletType}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Box Type</Label>
          <Select
            name="boxType"
            value={formData.boxType}
            onChange={handleInputChange}
            hasError={!!errors.boxType}
          >
            <option value="">Select Box Type</option>
            {boxTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
          {errors.boxType && <ErrorText>{errors.boxType}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Weight (kg)</Label>
          <Input
            type="number"
            name="weight"
            value={formData.weight}
            onChange={handleInputChange}
            hasError={!!errors.weight}
            placeholder="Enter pallet weight"
            step="0.1"
            min="0"
            max="2000"
          />
          {errors.weight && <ErrorText>{errors.weight}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Pallet Count</Label>
          <Input
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleInputChange}
            hasError={!!errors.quantity}
            placeholder="Number of pallets"
            min="1"
            max="50"
          />
          {errors.quantity && <ErrorText>{errors.quantity}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Box Count (Colli)</Label>
          <Input
            type="number"
            name="colliCount"
            value={formData.colliCount}
            onChange={handleInputChange}
            hasError={!!errors.colliCount}
            placeholder="Number of boxes on pallet"
            min="0"
            max="200"
          />
          {errors.colliCount && <ErrorText>{errors.colliCount}</ErrorText>}
        </FormGroup>

        <FormGroup>
          <Label>Process Stage</Label>
          <Select
            name="processStage"
            value={formData.processStage}
            onChange={handleInputChange}
            hasError={!!errors.processStage}
          >
            <option value="">Select Process Stage</option>
            {Object.values(PROCESS_STAGES).map(stage => (
              <option key={stage} value={stage}>
                {getDisplayName(stage, 'process')}
              </option>
            ))}
          </Select>
          {errors.processStage && <ErrorText>{errors.processStage}</ErrorText>}
        </FormGroup>

        {/* Only show sorting grade for tomato-related processes */}
        {formData.tomatoType && (
          <FormGroup>
            <Label>Sorting Grade (Optional)</Label>
            <Select
              name="sortingGrade"
              value={formData.sortingGrade}
              onChange={handleInputChange}
              hasError={!!errors.sortingGrade}
            >
              <option value="">Select Sorting Grade</option>
              {Object.values(SORTING_GRADES).map(grade => (
                <option key={grade} value={grade}>
                  {getDisplayName(grade, 'sorting')}
                </option>
              ))}
            </Select>
            {errors.sortingGrade && <ErrorText>{errors.sortingGrade}</ErrorText>}
          </FormGroup>
        )}

        {availableOrders.length > 0 && (
          <FormGroup>
            <Label>Assign to Order (Optional)</Label>
            <Select
              name="order_id"
              value={formData.order_id}
              onChange={handleInputChange}
              hasError={!!errors.order_id}
            >
              <option value="">Leave Unassigned</option>
              {availableOrders.map(order => (
                <option key={order.id} value={order.id}>
                  {order.customer_name} ({order.remaining_pallets} pallets needed)
                </option>
              ))}
            </Select>
            {errors.order_id && <ErrorText>{errors.order_id}</ErrorText>}
          </FormGroup>
        )}
      </FormGrid>

      <ButtonGroup>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={loading || Object.keys(errors).length > 0}
        >
          {loading ? (
            <>
              <Loader className="animate-spin" size={16} />
              Processing...
            </>
          ) : (
            initialData ? 'Update Pallet' : 'Add Pallet'
          )}
        </Button>
      </ButtonGroup>
    </FormContainer>
  );
};

export default PalletForm;

import styled from 'styled-components';

/**
 * Dialog container with fixed positioning and overlay
 */
export const QuickAssignDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

/**
 * Main content container with consistent styling
 */
export const QuickAssignContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
  width: 90%;
  max-width: 420px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

/**
 * Title component with icon alignment
 */
export const QuickAssignTitle = styled.h3`
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  
  svg {
    color: #2563eb;
  }
`;

/**
 * Info section with consistent spacing and borders
 */
export const QuickAssignInfo = styled.div`
  margin-bottom: 16px;
  padding: 12px;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  
  p {
    margin: 6px 0;
    font-size: 14px;
  }
  
  strong {
    font-weight: 600;
  }
`;

/**
 * Button container with consistent spacing
 */
export const QuickAssignButtons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 16px;
`;

/**
 * Message component with status variants
 */
export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-radius: 4px;
  background: ${props => props.$warning ? '#fff7ed' : '#f0f9ff'};
  border: 1px solid ${props => props.$warning ? '#fdba74' : '#bae6fd'};
  color: ${props => props.$warning ? '#9a3412' : '#0c4a6e'};
  margin: 8px 0;
  font-size: 13px;
  
  svg {
    color: ${props => props.$warning ? '#f97316' : '#0ea5e9'};
    flex-shrink: 0;
  }
`;

/**
 * Column visualization container
 */
export const ColumnVisualizer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 12px 0;
  background: #f1f5f9;
  padding: 8px;
  border-radius: 4px;
`;

/**
 * Column label with consistent styling
 */
export const ColumnLabel = styled.div`
  text-align: center;
  font-size: 12px;
  color: #475569;
  margin-top: 4px;
  font-weight: 500;
`;

/**
 * Legend container with consistent spacing
 */
export const Legend = styled.div`
  display: flex;
  gap: 12px;
  margin: 8px 0 12px;
  font-size: 12px;
  color: #64748b;
`;

/**
 * Legend item with icon alignment
 */
export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

/**
 * Legend color box with consistent sizing
 */
export const LegendBox = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: ${props => props.$color || '#e2e8f0'};
`;

/**
 * Base button styles
 */
const BaseButton = styled.button`
  padding: 12px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #bfdbfe;
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

/**
 * Cancel button with secondary styling
 */
export const CancelButton = styled(BaseButton)`
  background: #f1f5f9;
  border: 1px solid #cbd5e1;
  color: #475569;
  
  &:hover:not(:disabled) {
    background: #e2e8f0;
  }
`;

/**
 * Confirm button with primary styling
 */
export const ConfirmButton = styled(BaseButton)`
  background: #2563eb;
  border: none;
  color: white;
  
  &:hover:not(:disabled) {
    background: #1d4ed8;
  }
`;

/**
 * Position cell with status variants
 */
export const PositionCell = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background: ${props => {
    if (props.$suggested) return '#3b82f6';
    if (props.$occupied) return '#cbd5e1';
    return 'white';
  }};
  color: ${props => {
    if (props.$suggested) return 'white';
    if (props.$occupied) return '#1e293b';
    return '#64748b';
  }};
  border: 1px solid ${props => {
    if (props.$suggested) return '#2563eb';
    if (props.$occupied) return '#94a3b8';
    return '#e2e8f0';
  }};
  position: relative;
  
  ${props => props.$highlight && `
    box-shadow: 0 0 0 2px #f97316;
  `}
  
  ${props => props.$lastUsed && `
    &::after {
      content: '';
      position: absolute;
      top: -4px;
      right: -4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #f97316;
    }
  `}
`;

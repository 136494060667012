import React, { useState, useEffect, useCallback } from 'react';
import kleinpakSSEManager from '../../../../../lib/services/sse/KleinpakSSEManager';
import { getEnhancedPalletsInScheduledRows, getStandardStorageZoneString } from '../../../../../lib/utils/rowStorageUtils';
import { Package, MapPin, CheckCircle, ClipboardCheck } from 'lucide-react';
import styled, { keyframes, css } from 'styled-components';
import {
  ZoneGroupsContainer,
  ZoneCard,
  ZoneHeader,
  ZoneName,
  PalletCount,
  PalletIdList,
  PalletIdItem,
  EmptyPalletsMessage
} from '../styles';

// Animation keyframes
const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
`;

// Compact zone card
const CompactZoneCard = styled(ZoneCard)`
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 0;
  margin-bottom: 12px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  ${props => props.isCompleted && css`
    animation: ${fadeOut} 0.5s ease forwards;
  `}
`;

const CompactZoneHeader = styled(ZoneHeader)`
  background: ${props => props.isCompleted ? '#e8f5e9' : '#f1f5f9'};
  padding: 8px 12px;
  border-bottom: 1px solid #e2e8f0;
  border-radius: 4px 4px 0 0;
`;

const CompactPalletIdList = styled(PalletIdList)`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px;
  max-height: ${props => props.isOpen ? 'none' : '120px'};
  overflow-y: ${props => props.isOpen ? 'visible' : 'hidden'};
`;

const CompactPalletIdItem = styled(PalletIdItem)`
  background: ${props => {
    if (props.usedInMultipleOrders) return '#fff3e0'; // Orange background for pallets used in multiple orders
    if (props.assignedToCurrentOrder) return '#e8f5e9'; // Green background for pallets assigned to this order
    if (props.assignedToOtherOrder) return '#ffebee'; // Red background for pallets assigned to other orders
    return '#f8fafc'; // Default background
  }};
  border: 1px solid ${props => {
    if (props.usedInMultipleOrders) return '#ff9800'; // Orange border for pallets used in multiple orders
    if (props.assignedToCurrentOrder) return '#4caf50'; // Green border for pallets assigned to this order
    if (props.assignedToOtherOrder) return '#ef5350'; // Red border for pallets assigned to other orders
    return '#e2e8f0'; // Default border
  }};
  border-radius: 4px;
  padding: 4px 6px;
  text-align: center;
  font-family: monospace;
  font-size: 0.75rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  position: relative;
  
  color: ${props => {
    if (props.usedInMultipleOrders) return '#e65100'; // Orange text for pallets used in multiple orders
    if (props.assignedToCurrentOrder) return '#1b5e20'; // Green text for pallets assigned to this order
    if (props.assignedToOtherOrder) return '#d32f2f'; // Red text for pallets assigned to other orders
    return 'inherit'; // Default text color
  }};
  
  svg {
    margin-right: 3px;
    width: 10px;
    height: 10px;
  }
`;

const CompletedZoneMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8f5e9;
  color: #2e7d32;
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
  font-weight: 500;
  font-size: 0.8rem;
  border: 1px solid #c8e6c9;
  
  svg {
    margin-right: 8px;
  }
`;

const ZoneSummary = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ZoneStats = styled.div`
  font-size: 0.9rem;
  color: #495057;
  
  span {
    font-weight: 600;
    color: #0284c7;
  }
`;

const ExpandButton = styled.div`
  padding: 4px 8px;
  font-size: 0.7rem;
  color: #64748b;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ZoneGroupedPallets = ({ order, onZoneClick }) => {
  // State to temporarily keep completed zones visible for fade out animation
  const [completedZoneNames, setCompletedZoneNames] = useState([]);
  const [visibleCompletedZones, setVisibleCompletedZones] = useState([]);
  // State to track which zone's pallet list is expanded
  const [expandedZone, setExpandedZone] = useState(null);
  // State to track assigned pallet IDs and their status
  const [assignedPalletIds, setAssignedPalletIds] = useState({});
  // State to track which pallets are used in multiple orders
  const [multipleOrderPallets, setMultipleOrderPallets] = useState({});
  
  // Function to update pallet status (can be shared between different event types)
  const updatePalletStatus = useCallback((palletId, orderId, processStage, zone) => {
    console.log(`Pallet #${palletId} updated with order #${orderId}, process stage ${processStage}${zone ? `, zone ${zone}` : ''}`);
    
    setAssignedPalletIds(prev => {
      // Update the pallet status
      const updatedStatus = {
        ...prev,
        [palletId]: {
          orderId: orderId ? String(orderId) : null,
          processStage,
          zone
        }
      };
      
      // Check for pallets used in multiple orders and update that state
      const palletsInOrders = {};
      
      // Collect all order IDs that each pallet is assigned to
      Object.entries(updatedStatus).forEach(([id, status]) => {
        if (status.orderId) {
          if (!palletsInOrders[id]) {
            palletsInOrders[id] = new Set();
          }
          palletsInOrders[id].add(status.orderId);
        }
      });
      
      // Update the multipleOrderPallets state
      const newMultipleOrderPallets = {};
      Object.entries(palletsInOrders).forEach(([id, orderIds]) => {
        if (orderIds.size > 1) {
          newMultipleOrderPallets[id] = true;
        }
      });
      
      setMultipleOrderPallets(newMultipleOrderPallets);
      
      return updatedStatus;
    });
  }, []);
  
  // Initialize from order's pallets data
  useEffect(() => {
    if (order.pallets && Array.isArray(order.pallets)) {
      const initialPalletStatuses = {};
      
      // Track all pallets and which orders they're assigned to
      const orderPalletMap = {};
      
      order.pallets.forEach(pallet => {
        if (pallet.id) {
          // Track which orders each pallet is assigned to
          const palletId = pallet.id;
          const orderId = pallet.order_id || pallet.orderId;
          
          if (orderId) {
            if (!orderPalletMap[palletId]) {
              orderPalletMap[palletId] = new Set();
            }
            orderPalletMap[palletId].add(String(orderId));
          }
          
          initialPalletStatuses[palletId] = {
            orderId: orderId ? String(orderId) : null,
            processStage: pallet.processStage || 'Unprocessed',
            zone: pallet.zone || pallet.storageZone || null
          };
        }
      });
      
      // Identify pallets used in multiple orders
      const newMultipleOrderPallets = {};
      Object.entries(orderPalletMap).forEach(([palletId, orderIds]) => {
        if (orderIds.size > 1) {
          console.log(`Pallet ${palletId} is used in multiple orders:`, Array.from(orderIds));
          newMultipleOrderPallets[palletId] = true;
        }
      });
      
      setMultipleOrderPallets(newMultipleOrderPallets);
      setAssignedPalletIds(prev => ({
        ...prev,
        ...initialPalletStatuses
      }));
    }
  }, [order.id, order.pallets]);
  
  // Subscribe to SSE updates
  useEffect(() => {
    if (!order || !order.id) return;
    
    // Get the location ID from order
    const locationId = order.location_id || order.locationId;
    if (!locationId) {
      console.error('LocationId not available in order data');
      return;
    }
    
    // Initialize the SSE connection
    kleinpakSSEManager.initialize(locationId);
    
    // Subscribe to pallet assignment updates
    const assignmentSubscriptionId = kleinpakSSEManager.subscribe('assignmentUpdate', (data) => {
      if (!data || (!data.palletIds && !data.palletId)) return;
      
      const palletIds = data.palletIds || [data.palletId];
      const orderId = String(data.orderId);
      
      console.log(`ZoneGroupedPallets received assignment update: ${palletIds.length} pallets assigned to order ${orderId}`);
      
      // Update our record of assigned pallets
      palletIds.forEach(palletId => {
        updatePalletStatus(palletId, orderId, 'Processing', data.zone || null);
      });
    });
    
    // Subscribe to pallet updates for status changes
    const palletSubscriptionId = kleinpakSSEManager.subscribe('palletUpdate', (data) => {
      if (!data || !data.pallets || !Array.isArray(data.pallets)) return;
      
      console.log(`ZoneGroupedPallets received pallet updates: ${data.pallets.length} pallets`);
      
      // Update our record for each pallet
      data.pallets.forEach(pallet => {
        if (pallet.id) {
          const palletId = pallet.id;
          const orderId = pallet.orderId || pallet.order_id || null;
          const processStage = pallet.processStage || 'Unprocessed';
          const zone = pallet.zone || pallet.storageZone || null;
          
          updatePalletStatus(palletId, orderId, processStage, zone);
        }
      });
    });
    
    // For backward compatibility - handle legacy custom events during transition period
    const handleLegacyPalletAssigned = (event) => {
      const { palletId, orderId, processStage, zone } = event.detail;
      updatePalletStatus(palletId, orderId, processStage, zone);
    };
    
    window.addEventListener('palletAssigned', handleLegacyPalletAssigned);
    
    // Clean up subscriptions and event listeners when component unmounts
    return () => {
      kleinpakSSEManager.unsubscribe('assignmentUpdate', assignmentSubscriptionId);
      kleinpakSSEManager.unsubscribe('palletUpdate', palletSubscriptionId);
      window.removeEventListener('palletAssigned', handleLegacyPalletAssigned);
    };
  }, [order.id, order.location_id, order.locationId, updatePalletStatus]);
  
  // Get the pallets that are scheduled (allowed) for this order using combined scheduling methods
  let filteredPalletsSet = new Set();
  
  // Log the debug information about this order
  console.log('ZoneGroupedPallets: Starting filter with', {
    hasPallets: Boolean(order.pallets?.length),
    totalPallets: order.pallets?.length || 0,
    hasAllowedPalletIds: Boolean(order.allowed_pallet_ids?.length),
    allowedCount: order.allowed_pallet_ids?.length || 0,
    hasScheduledRows: Boolean(order.scheduled_rows?.length),
    scheduledRowsCount: order.scheduled_rows?.length || 0
  });
  
  if (!order.pallets || !Array.isArray(order.pallets)) {
    // No pallets available
    console.log('ZoneGroupedPallets: No pallets available in order data');
  } else {
    // PART 1: First, collect pallets from scheduled rows (if any)
    if (order.scheduled_rows && Array.isArray(order.scheduled_rows) && order.scheduled_rows.length > 0) {
      console.log(`ZoneGroupedPallets: Order has ${order.scheduled_rows.length} scheduled rows, gathering those pallets`);
      
      // Debug log the scheduled rows
      console.log('ZoneGroupedPallets: Scheduled rows details:', order.scheduled_rows.map(row => ({
        zoneId: row.zoneId,
        rowIndex: row.rowIndex,
        rowId: row.rowId
      })));
      
      // Try the enhanced approach with standardized lookup
      const enhancedResults = getEnhancedPalletsInScheduledRows(order.pallets, order.scheduled_rows, true);
      
      if (enhancedResults.length > 0) {
        console.log(`ZoneGroupedPallets: Enhanced matching found ${enhancedResults.length} pallets in scheduled rows`);
        
        // Add all row pallets to our set (filter out shipped ones)
        enhancedResults.forEach(pallet => {
          if (pallet.status !== 'shipped') {
            filteredPalletsSet.add(pallet);
          }
        });
      } else {
        // If enhanced approach found no matches, use backup method
        console.log(`ZoneGroupedPallets: Enhanced matching found no results, using backup approach`);
        
        // Create a map of all pallets by their zone/row location for quick lookup
        const palletsByLocation = new Map();
        
        // Process pallets and organize them by location
        order.pallets.forEach(pallet => {
          if (!pallet || pallet.status === 'shipped') return;
          
          // Try to determine this pallet's location using multiple approaches
          let locationKey = null;
          
          // APPROACH 1: Use storageZone string format (primary method)
          if (pallet.storageZone) {
            const match = String(pallet.storageZone).match(/^(zone-\d+)-row-(\d+)$/);
            if (match) {
              locationKey = `${match[1]}-${match[2]}`;
            }
          }
          
          // APPROACH 2: Use direct zoneId and rowIndex if Method 1 failed
          if (!locationKey && pallet.zoneId && pallet.rowIndex !== undefined) {
            locationKey = `${pallet.zoneId}-${pallet.rowIndex}`;
          }
          
          // APPROACH 3: Use position object if both previous methods failed
          if (!locationKey && pallet.position && pallet.position.zoneId && 
              (pallet.position.rowIndex !== undefined || pallet.position.row !== undefined)) {
            const rowIndex = pallet.position.rowIndex !== undefined ? 
                            pallet.position.rowIndex : pallet.position.row;
            locationKey = `${pallet.position.zoneId}-${rowIndex}`;
          }
          
          // If we found a location, add this pallet to that location's list
          if (locationKey) {
            if (!palletsByLocation.has(locationKey)) {
              palletsByLocation.set(locationKey, []);
            }
            palletsByLocation.get(locationKey).push(pallet);
          }
        });
        
        // Gather pallets from the scheduled rows
        order.scheduled_rows.forEach(row => {
          const rowLocationKey = `${row.zoneId}-${row.rowIndex}`;
          
          if (palletsByLocation.has(rowLocationKey)) {
            const palletsInThisRow = palletsByLocation.get(rowLocationKey);
            console.log(`ZoneGroupedPallets: Found ${palletsInThisRow.length} pallets in ${rowLocationKey}`);
            
            // Add to our set
            palletsInThisRow.forEach(pallet => {
              filteredPalletsSet.add(pallet);
            });
          }
        });
      }
      
      // If we found no pallets in scheduled rows, DON'T create placeholder entries
      // This is handled in the rendering logic based on the empty array
      
      console.log(`ZoneGroupedPallets: After row scheduling, have ${filteredPalletsSet.size} unique pallets`);
    }
    
    // PART 2: Next, add individually scheduled pallets (allowed_pallet_ids)
    if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
      console.log(`ZoneGroupedPallets: Order has ${order.allowed_pallet_ids.length} individually allowed pallets`);
      
      // Track how many new pallets were added
      let newIndividualPallets = 0;
      
      // Filter out shipped pallets and add allowed pallets
      order.pallets.forEach(pallet => {
        if (!pallet || pallet.status === 'shipped') return;
        
        const isAllowed = order.allowed_pallet_ids.includes(pallet.id) || 
                          order.allowed_pallet_ids.includes(String(pallet.id));
        
        if (isAllowed) {
          // Check if this is a new pallet (not already in our set)
          const isNew = !Array.from(filteredPalletsSet).some(p => String(p.id) === String(pallet.id));
          
          if (isNew) {
            filteredPalletsSet.add(pallet);
            newIndividualPallets++;
          }
        }
      });
      
      console.log(`ZoneGroupedPallets: Added ${newIndividualPallets} new individually scheduled pallets`);
    }
  }
  
  // Convert our set to an array for further processing
  let filteredPallets = Array.from(filteredPalletsSet);
  console.log(`ZoneGroupedPallets: Combined scheduling methods yielded ${filteredPallets.length} total pallets`);
  
  // PART 3: If no scheduled pallets found (from either method), only show pallets directly assigned to THIS order
  if (filteredPallets.length === 0 && (!order.scheduled_rows || order.scheduled_rows.length === 0) && 
      (!order.allowed_pallet_ids || order.allowed_pallet_ids.length === 0)) {
    console.log('ZoneGroupedPallets: No scheduled pallets found, showing only directly assigned pallets');
    
    filteredPallets = order.pallets.filter(pallet => 
      pallet && pallet.status !== 'shipped' && 
      pallet.order_id && String(pallet.order_id) === String(order.id)
    );
    
    console.log(`ZoneGroupedPallets: Found ${filteredPallets.length} pallets directly assigned to order`);
  }
  
  // Enhanced function to group pallets by zone with more comprehensive zone detection
  const getPalletsByZone = (pallets) => {
    const palletsByZone = {};
    
    if (!Array.isArray(pallets)) {
      console.log('ZoneGroupedPallets: getPalletsByZone received non-array input:', pallets);
      return palletsByZone;
    }
    
    console.log(`ZoneGroupedPallets: Grouping ${pallets.length} pallets by zone`);
    
    // Process each pallet to get zone information with enhanced detection
    pallets.forEach(pallet => {
      if (!pallet) {
        console.log('ZoneGroupedPallets: Skipping null/undefined pallet');
        return;
      }
      
      // Try several properties where zone might be stored in order of precedence
      let zone = null;
      let detectionMethod = '';
      
      // APPROACH 1: Primary - Parse the storageZone property for zone part
      if (pallet.storageZone) {
        // If in format 'zone-X-row-Y', extract just the zone part
        const match = String(pallet.storageZone).match(/^(zone-\d+)(-row-\d+)?$/);
        if (match) {
          zone = match[1]; // Just the zone part (e.g., "zone-1")
          detectionMethod = 'storageZone_parsed';
        } else {
          // If not in that format, use the whole value
          zone = pallet.storageZone;
          detectionMethod = 'storageZone_direct';
        }
      }
      
      // APPROACH 2: Fallbacks in order of preference
      if (!zone) {
        if (pallet.zone) {
          zone = pallet.zone;
          detectionMethod = 'zone';
        } else if (pallet.storage_zone) {
          zone = pallet.storage_zone;
          detectionMethod = 'storage_zone';
        } else if (pallet.zoneId) {
          zone = pallet.zoneId;
          detectionMethod = 'zoneId';
        } else if (pallet.storage && pallet.storage.zone) {
          zone = pallet.storage.zone;
          detectionMethod = 'storage.zone';
        } else if (pallet.position && pallet.position.zoneId) {
          zone = pallet.position.zoneId;
          detectionMethod = 'position.zoneId';
        } else if (pallet.assignment && pallet.assignment.zoneId) {
          zone = pallet.assignment.zoneId;
          detectionMethod = 'assignment.zoneId';
        } else if (pallet.storagePosition && pallet.storagePosition.zoneId) {
          zone = pallet.storagePosition.zoneId;
          detectionMethod = 'storagePosition.zoneId';
        } else if (pallet.location && pallet.location.zone) {
          zone = pallet.location.zone;
          detectionMethod = 'location.zone';
        }
      }
      
      // Default to 'Unassigned' if no zone information is found
      const zoneName = zone || 'Unassigned';
      
      // Log how we determined the zone for debugging
      if (zone) {
        console.log(`ZoneGroupedPallets: Pallet ${pallet.id} assigned to zone "${zoneName}" using ${detectionMethod}`);
      } else {
        console.log(`ZoneGroupedPallets: Pallet ${pallet.id} has no zone, assigning to "Unassigned"`);
      }
      
      // Create array for this zone if it doesn't exist
      if (!palletsByZone[zoneName]) {
        palletsByZone[zoneName] = [];
      }
      
      // Add pallet to the appropriate zone group
      palletsByZone[zoneName].push({
        ...pallet,
        // Ensure the zone is stored in a consistent property for all pallets
        zone: zoneName,
        storageZone: zoneName
      });
    });
    
    // Log summary of grouped pallets
    const zoneNames = Object.keys(palletsByZone);
    console.log(`ZoneGroupedPallets: Grouped pallets into ${zoneNames.length} zones:`, zoneNames);
    zoneNames.forEach(zone => {
      console.log(`  - ${zone}: ${palletsByZone[zone].length} pallets`);
    });
    
    return palletsByZone;
  };
  
  const palletsByZone = getPalletsByZone(filteredPallets);
  const zoneEntries = Object.entries(palletsByZone);
  
  // Sort zones to put "Unassigned" last and alphabetize the rest
  const sortedZoneEntries = [...zoneEntries].sort((a, b) => {
    if (a[0] === 'Unassigned') return 1;
    if (b[0] === 'Unassigned') return -1;
    return a[0].localeCompare(b[0]);
  });
  
  // Check for completed zones (where all pallets are assigned to this order and processing)
  const zoneCompletionStatus = {};
  let completedZonesCount = 0;
  
  sortedZoneEntries.forEach(([zoneName, zonePallets]) => {
    const isZoneCompleted = zonePallets.every(pallet => {
      const palletStatus = assignedPalletIds[pallet.id] || {};
      return palletStatus.orderId === String(order.id) && palletStatus.processStage === 'Processing';
    });
    
    zoneCompletionStatus[zoneName] = isZoneCompleted;
    
    if (isZoneCompleted) {
      completedZonesCount++;
      
      // If this is newly completed, add it to the visible completed zones
      if (!completedZoneNames.includes(zoneName)) {
        setCompletedZoneNames(prev => [...prev, zoneName]);
        setVisibleCompletedZones(prev => [...prev, zoneName]);
      }
    }
  });
  
  // Remove completed zones from visible list after animation
  useEffect(() => {
    const timeoutIds = visibleCompletedZones.map(zoneName => 
      setTimeout(() => {
        setVisibleCompletedZones(prev => 
          prev.filter(name => name !== zoneName)
        );
      }, 500) // Match this to the animation duration
    );
    
    return () => timeoutIds.forEach(id => clearTimeout(id));
  }, [visibleCompletedZones]);
  
  // Filter out completed zones from display after animation completes
  const displayZoneEntries = sortedZoneEntries.filter(([zoneName, _]) => 
    !zoneCompletionStatus[zoneName] || visibleCompletedZones.includes(zoneName)
  );
  
  if (sortedZoneEntries.length === 0) {
    return (
      <EmptyPalletsMessage>
        <Package size={24} style={{ opacity: 0.5, marginBottom: '8px' }} />
        <p style={{ margin: '0' }}>No pallets assigned to this order yet.</p>
        <p style={{ margin: '4px 0 0' }}>Scan pallets to add them to this order.</p>
      </EmptyPalletsMessage>
    );
  }
  
  // Show a message instead if all zones are completed
  if (displayZoneEntries.length === 0) {
    return (
      <>
        <ZoneSummary>
          <ZoneStats>
            <span>{completedZonesCount}</span> of <span>{sortedZoneEntries.length}</span> zones completed
          </ZoneStats>
        </ZoneSummary>
        
        <CompletedZoneMessage>
          <ClipboardCheck size={18} />
          All zones are complete! No more pallets to scan.
        </CompletedZoneMessage>
      </>
    );
  }
  
  // Toggle expansion of a zone's pallet list
  const toggleZoneExpansion = (zoneName, event) => {
    event.stopPropagation();
    setExpandedZone(expandedZone === zoneName ? null : zoneName);
  };

  return (
    <>
      {/* Show zone completion stats */}
      <ZoneSummary>
        <ZoneStats>
          <span>{completedZonesCount}</span> of <span>{sortedZoneEntries.length}</span> zones completed
        </ZoneStats>
      </ZoneSummary>
      
      <ZoneGroupsContainer>
        {displayZoneEntries.map(([zoneName, zonePallets]) => {
          const isZoneCompleted = zoneCompletionStatus[zoneName];
          const isExpanded = expandedZone === zoneName;
          // Count pallets that are assigned to this order and in processing stage
          const processedCount = zonePallets.filter(pallet => {
            const palletStatus = assignedPalletIds[pallet.id] || {};
            return palletStatus.orderId === String(order.id) && palletStatus.processStage === 'Processing';
          }).length;
          
          return (
            <CompactZoneCard 
              key={zoneName}
              isCompleted={isZoneCompleted}
              onClick={() => {
                if (!isZoneCompleted) {
                  // Check if this order uses scheduled rows
                  const hasScheduledRows = order.scheduled_rows && 
                                         Array.isArray(order.scheduled_rows) && 
                                         order.scheduled_rows.length > 0;
                  
                  if (hasScheduledRows) {
                    // For row-based orders, pass flag to use row-based navigation
                    console.log(`ZoneGroupedPallets: Using row-based navigation for zone ${zoneName} click`);
                    onZoneClick(zoneName, zonePallets, order, { isRowBasedNavigation: true });
                  } else {
                    // For regular orders, use standard navigation
                    onZoneClick(zoneName, zonePallets, order);
                  }
                }
              }}
            >
              <CompactZoneHeader isCompleted={isZoneCompleted}>
                <ZoneName>
                  <MapPin size={14} />
                  {zoneName}
                </ZoneName>
                <PalletCount>
                  {processedCount}/{zonePallets.length}
                </PalletCount>
              </CompactZoneHeader>
              
              {isZoneCompleted ? (
                <CompletedZoneMessage>
                  <CheckCircle size={14} />
                  All pallets scanned
                </CompletedZoneMessage>
              ) : (
                <CompactPalletIdList isOpen={isExpanded}>
                  {zonePallets.map(pallet => {
                    // Check the pallet's current status
                    const palletStatus = assignedPalletIds[pallet.id] || {};
                    const isAssignedToThisOrder = palletStatus.orderId === String(order.id);
                    const isAssignedToOtherOrder = palletStatus.orderId && !isAssignedToThisOrder;
                    const isProcessing = palletStatus.processStage === 'Processing';
                    // Check if pallet is used in multiple orders
                    const isUsedInMultipleOrders = multipleOrderPallets[pallet.id] === true;
                    
                    return (
                      <CompactPalletIdItem 
                        key={pallet.id}
                        assignedToCurrentOrder={isAssignedToThisOrder && isProcessing}
                        assignedToOtherOrder={isAssignedToOtherOrder && isProcessing}
                        usedInMultipleOrders={isUsedInMultipleOrders && isProcessing}
                      >
                        {isAssignedToThisOrder && isProcessing && <CheckCircle color="#4caf50" />}
                        {isAssignedToOtherOrder && isProcessing && <CheckCircle color="#ef5350" />}
                        {isUsedInMultipleOrders && isProcessing && <CheckCircle color="#ff9800" />}
                        #{String(pallet.id).substring(0, 6)}
                      </CompactPalletIdItem>
                    );
                  })}
                </CompactPalletIdList>
              )}
              
              {zonePallets.length > 10 && !isZoneCompleted && (
                <ExpandButton onClick={(e) => toggleZoneExpansion(zoneName, e)}>
                  {isExpanded ? "Show less" : "Show all"}
                </ExpandButton>
              )}
            </CompactZoneCard>
          );
        })}
      </ZoneGroupsContainer>
    </>
  );
};

export default ZoneGroupedPallets;

import React from 'react';
import {
  PrinterSection as StyledPrinterSection,
  SectionHeader,
  PrinterDetails,
  ConfigItem,
  FormGroup,
  Label,
  Select,
  Input,
  TextArea,
  ButtonGroup,
  Button,
  TestResult
} from './styles';
import { PRINTER_LANGUAGES, LABEL_TYPES, SAMPLE_COMMANDS, PROTOCOLS } from './constants';

const PrinterSection = ({
  printerType,
  status,
  onStatusChange,
  awsConfig,
  onTestConnection,
  onPrintTest,
  isLoading
}) => {
  const isRawMode = status.useRawCommand;
  const isTrapper = printerType === 'Trap Printer';
  
  // Get correct port based on printer type
  const port = isTrapper ? 
    awsConfig?.trapPort_7 || awsConfig?.trapPort : 
    awsConfig?.sortingPort_7 || awsConfig?.sortingPort;
    
  // Get IP from config
  const ip = awsConfig?.ip_7 || awsConfig?.ip;
  
  // Get protocols from config
  const rawProtocols = awsConfig?.protocols_7 || awsConfig?.protocols || '["LPR", "LPD"]';
  const availableProtocols = typeof rawProtocols === 'string' ? 
    JSON.parse(rawProtocols) : rawProtocols;

  const handleProtocolChange = (e) => {
    onStatusChange(prev => ({ ...prev, protocol: e.target.value }));
  };

  const handleCommandLanguageChange = (e) => {
    onStatusChange(prev => ({ 
      ...prev, 
      commandLanguage: e.target.value,
      rawCommand: SAMPLE_COMMANDS[e.target.value][prev.labelType]
    }));
  };

  const handleRawCommandToggle = (e) => {
    onStatusChange(prev => ({
      ...prev,
      useRawCommand: e.target.checked,
      rawCommand: e.target.checked ? 
        SAMPLE_COMMANDS[prev.commandLanguage][prev.labelType] : 
        prev.rawCommand
    }));
  };

  const handleLabelTypeChange = (e) => {
    const newLabelType = e.target.value;
    onStatusChange(prev => ({ 
      ...prev, 
      labelType: newLabelType,
      rawCommand: SAMPLE_COMMANDS[prev.commandLanguage][newLabelType]
    }));
  };

  const handleRawCommandChange = (e) => {
    onStatusChange(prev => ({ ...prev, rawCommand: e.target.value }));
  };

  const handleTestTextChange = (e) => {
    onStatusChange(prev => ({ ...prev, testText: e.target.value }));
  };

  return (
    <StyledPrinterSection>
      <SectionHeader>{printerType}</SectionHeader>
      <PrinterDetails>
        <ConfigItem>
          <strong>IP Address:</strong> {ip || 'Not available'}
        </ConfigItem>
        <ConfigItem>
          <strong>Port:</strong> {port || 'Not available'}
        </ConfigItem>
        <ConfigItem>
          <strong>Available Protocols:</strong> {
            Array.isArray(availableProtocols) ? 
              availableProtocols.join(', ') : 
              'Not available'
          }
        </ConfigItem>
      </PrinterDetails>

      <FormGroup>
        <Label>Protocol:</Label>
        <Select 
          value={status.protocol}
          onChange={handleProtocolChange}
        >
          {Array.isArray(availableProtocols) && 
           availableProtocols.length > 0 ? (
            availableProtocols.map(protocol => (
              <option key={protocol} value={protocol}>{protocol}</option>
            ))
          ) : (
            <>
              <option value={PROTOCOLS.LPR}>LPR</option>
              <option value={PROTOCOLS.LPD}>LPD</option>
            </>
          )}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>Command Language:</Label>
        <Select 
          value={status.commandLanguage}
          onChange={handleCommandLanguageChange}
        >
          <option value={PRINTER_LANGUAGES.EPL}>EPL (Eltron Programming Language)</option>
          <option value={PRINTER_LANGUAGES.ZPL}>ZPL (Zebra Programming Language)</option>
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>
          <input
            type="checkbox"
            checked={status.useRawCommand}
            onChange={handleRawCommandToggle}
          />
          {' '}Use Raw Commands
        </Label>
      </FormGroup>

      {isRawMode ? (
        <FormGroup>
          <Label>Raw Printer Commands:</Label>
          <TextArea
            value={status.rawCommand}
            onChange={handleRawCommandChange}
            placeholder={`Enter ${status.commandLanguage} commands...`}
            rows={10}
          />
        </FormGroup>
      ) : (
        <>
          <FormGroup>
            <Label>Label Type:</Label>
            <Select 
              value={status.labelType}
              onChange={handleLabelTypeChange}
            >
              <option value={LABEL_TYPES.QR}>QR Code Label</option>
              <option value={LABEL_TYPES.TEXT}>Text Label</option>
              <option value={LABEL_TYPES.BARCODE}>Barcode Label</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Test Text:</Label>
            <Input
              type="text"
              value={status.testText}
              onChange={handleTestTextChange}
              placeholder="Enter text for test label"
            />
          </FormGroup>
        </>
      )}

      <ButtonGroup>
        <Button
          onClick={onTestConnection}
          disabled={status.isLoading || isLoading}
        >
          {status.isLoading ? 'Testing...' : 'Test Connection'}
        </Button>

        <Button
          onClick={onPrintTest}
          disabled={status.isLoading || isLoading}
        >
          {status.isLoading ? 'Printing...' : 'Print Test Label'}
        </Button>
      </ButtonGroup>

      {status.testResult && (
        <TestResult success={status.testResult.success}>
          <p>{status.testResult.message}</p>
        </TestResult>
      )}
    </StyledPrinterSection>
  );
};

export default PrinterSection;

import { api } from '@lib/api';

export const analyticsService = {
  /**
   * Get distinct event types from the database
   * @returns {Promise<Array>} Array of distinct event types
   */
  async getEventTypes() {
    const response = await api.get('/api/analytics/event-types');
    return response.data;
  },
  /**
   * Get events with optional filtering
   * @param {Object} filters Filter criteria
   * @returns {Promise<Array>} Array of events
   */
  async getEvents(filters = {}) {
    const params = new URLSearchParams();
    
    if (filters.startDate) {
      // Check if startDate is already a string or needs conversion
      const startDateStr = typeof filters.startDate === 'string' 
        ? filters.startDate 
        : filters.startDate instanceof Date 
          ? filters.startDate.toISOString() 
          : filters.startDate;
      params.append('startDate', startDateStr);
    }
    if (filters.endDate) {
      // Check if endDate is already a string or needs conversion
      const endDateStr = typeof filters.endDate === 'string' 
        ? filters.endDate 
        : filters.endDate instanceof Date 
          ? filters.endDate.toISOString() 
          : filters.endDate;
      params.append('endDate', endDateStr);
    }
    if (filters.eventTypes) {
      params.append('eventTypes', JSON.stringify(filters.eventTypes));
    }
    if (filters.userId) {
      params.append('userId', filters.userId);
    }
    if (filters.locationId) {
      params.append('locationId', filters.locationId);
    }
    if (filters.palletId) {
      params.append('palletId', filters.palletId);
    }
    if (filters.orderId) {
      params.append('orderId', filters.orderId);
    }
    if (filters.limit) {
      params.append('limit', filters.limit);
    }
    if (filters.offset) {
      params.append('offset', filters.offset);
    }

    const response = await api.get(`/api/analytics/events?${params.toString()}`);
    return response.data;
  },

  /**
   * Get events for a specific pallet
   * @param {string} palletId The pallet ID
   * @returns {Promise<Array>} Array of events
   */
  async getPalletEvents(palletId) {
    const response = await api.get(`/api/analytics/events/pallet/${palletId}`);
    return response.data;
  },

  /**
   * Get events for a specific order
   * @param {string} orderId The order ID
   * @returns {Promise<Array>} Array of events
   */
  async getOrderEvents(orderId) {
    const response = await api.get(`/api/analytics/events/order/${orderId}`);
    return response.data;
  },

  /**
   * Get aggregated statistics
   * @param {Object} filters Filter criteria
   * @returns {Promise<Object>} Aggregated statistics
   */
  async getStats(filters = {}) {
    const params = new URLSearchParams();
    
    if (filters.startDate) {
      // Check if startDate is already a string or needs conversion
      const startDateStr = typeof filters.startDate === 'string' 
        ? filters.startDate 
        : filters.startDate instanceof Date 
          ? filters.startDate.toISOString() 
          : filters.startDate;
      params.append('startDate', startDateStr);
    }
    if (filters.endDate) {
      // Check if endDate is already a string or needs conversion
      const endDateStr = typeof filters.endDate === 'string' 
        ? filters.endDate 
        : filters.endDate instanceof Date 
          ? filters.endDate.toISOString() 
          : filters.endDate;
      params.append('endDate', endDateStr);
    }
    if (filters.locationId) {
      params.append('locationId', filters.locationId);
    }

    const response = await api.get(`/api/analytics/stats?${params.toString()}`);
    return response.data;
  },

  /**
   * Get user activity data
   * @param {Object} filters Filter criteria
   * @returns {Promise<Array>} User activity data
   */
  async getUserActivity(filters = {}) {
    const params = new URLSearchParams();
    
    if (filters.startDate) {
      // Check if startDate is already a string or needs conversion
      const startDateStr = typeof filters.startDate === 'string' 
        ? filters.startDate 
        : filters.startDate instanceof Date 
          ? filters.startDate.toISOString() 
          : filters.startDate;
      params.append('startDate', startDateStr);
    }
    if (filters.endDate) {
      // Check if endDate is already a string or needs conversion
      const endDateStr = typeof filters.endDate === 'string' 
        ? filters.endDate 
        : filters.endDate instanceof Date 
          ? filters.endDate.toISOString() 
          : filters.endDate;
      params.append('endDate', endDateStr);
    }
    if (filters.locationId) {
      params.append('locationId', filters.locationId);
    }

    const response = await api.get(`/api/analytics/user-activity?${params.toString()}`);
    return response.data;
  },

  /**
   * Add an intervention to an event
   * @param {string} eventId The event ID
   * @param {Object} interventionData Intervention details
   * @returns {Promise<Object>} The updated event
   */
  async addIntervention(eventId, interventionData) {
    const response = await api.post(
      `/api/analytics/events/${eventId}/intervention`,
      interventionData
    );
    return response.data;
  },

  /**
   * Log a pallet event
   * @param {Object} eventData Event data to log
   * @returns {Promise<Object>} The created event
   */
  async logPalletEvent(eventData) {
    const response = await api.post('/api/analytics/events', eventData);
    return response.data;
  }
};

/**
 * Service for managing zone occupancy with safety-critical constraints
 * Updated to work directly with pallets table storagezone field
 */

import api from '@lib/api/axios';
import { COLLECTION_LIMITS } from '../utils/collectionManager';
import { createPosition, getPositionKey } from '../utils/positionManager';
import { executeWithRetry, enhanceError } from '../utils/storageApiErrorHandler';
import { validateLocationId } from '../utils/validationUtils';

const API_URL = '/api/storage';

// Cache settings with strict bounds
const CACHE_SETTINGS = {
  enabled: true,
  defaultTTL: 60000, // 1 minute in milliseconds
  maxEntries: COLLECTION_LIMITS.MAX_CACHE_ITEMS
};

// Helper function to parse storagezone into position data
const parseStorageZone = (storageZone) => {
  if (!storageZone) return null;
  
  const match = storageZone.match(/^(.+)-row-(\d+)$/);
  if (!match) return null;
  
  return {
    zoneId: match[1],
    rowIndex: parseInt(match[2], 10)
  };
};

/**
 * Gets the current occupancy data for a specific zone
 * @param {string} zoneId - Zone ID to check
 * @param {number} locationId - Location ID (passed as query parameter)
 * @param {Object} options - Request options
 * @returns {Promise<Object>} Zone occupancy data
 * @throws {Error} If parameters are missing or invalid
 */
export const getZoneOccupancy = async (zoneId, locationId, options = {}) => {
  // Enhanced input validation with specific error messages
  if (!zoneId) {
    throw new Error('zoneId parameter is required');
  }
  
  if (!locationId) {
    throw new Error('locationId parameter is required');
  }
  
  // Validate and normalize locationId
  let locationIdNum;
  try {
    locationIdNum = validateLocationId(locationId);
  } catch (error) {
    throw new Error(`Invalid locationId parameter: ${error.message}`);
  }

  try {
    // Configure cache control parameters
    const params = {
      timestamp: Date.now(),
      locationId: locationIdNum
    };

    if (options.forceRefresh) {
      params.noCache = true;
      params.refresh = true;
    }

    // Log the request URL and parameters for debugging
    console.log('ZoneOccupancyService: Making request', {
      url: `${API_URL}/zones/${zoneId}/pallets`,
      params,
      method: 'GET'
    });

    // Execute API call to get all pallets in the zone
    const response = await executeWithRetry(
      () => api.get(`${API_URL}/zones/${zoneId}/pallets`, {
        params,
        timeout: 5000
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying zone occupancy fetch (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    // Convert pallet data into occupancy map
    const occupancyData = {};
    const pallets = response.data || [];
    
    if (pallets.length > COLLECTION_LIMITS.MAX_ITEMS) {
      throw new Error(`Zone occupancy exceeds maximum limit of ${COLLECTION_LIMITS.MAX_ITEMS} positions`);
    }

    // Process each pallet and organize by position
    pallets.forEach(pallet => {
      if (!pallet.storagezone) return;
      
      const positionData = parseStorageZone(pallet.storagezone);
      if (!positionData) return;
      
      // Only include pallets that belong to this zone
      if (positionData.zoneId !== zoneId) return;
      
      // Create position key using row index
      const posKey = getPositionKey(createPosition(positionData.rowIndex, 0));
      
      // Store pallet data with its position
      occupancyData[posKey] = {
        ...pallet,
        row: positionData.rowIndex,
        col: 0 // Since we only track rows now
      };
      
      // Log the processed data for debugging
      if (process.env.NODE_ENV === 'development') {
        console.debug('ZoneOccupancyService: Processed pallet data', {
          posKey,
          storageZone: pallet.storagezone,
          palletId: pallet.id,
          position: positionData
        });
      }
    });

    // Schedule background refresh if empty result
    if (Object.keys(occupancyData).length === 0 && !options.forceRefresh) {
      setTimeout(() => {
        getZoneOccupancy(zoneId, locationIdNum, { forceRefresh: true })
          .catch(error => console.warn('Background refresh failed:', error.message));
      }, 2000);
    }

    return occupancyData;
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      locationId: locationIdNum,
      operation: 'getZoneOccupancy'
    });
  }
};

/**
 * Updates zone occupancy with a new position
 * @param {string} zoneId - Zone ID to update
 * @param {number} locationId - Location ID
 * @param {Object} position - Position data
 * @param {Object} palletData - Pallet data
 * @returns {Promise<Object>} Updated occupancy data
 */
export const updateZoneOccupancy = async (zoneId, locationId, position, palletData) => {
  // Input validation
  if (!zoneId || !position || !palletData) {
    throw new Error('Missing required parameters');
  }
  const locationIdNum = validateLocationId(locationId);

  try {
    // Normalize position data
    const normalizedPosition = createPosition(position.row, position.col);
    const positionKey = getPositionKey(normalizedPosition);

    // Execute update with bounded retries using locationId as query param
    const response = await executeWithRetry(
      () => api.post(`${API_URL}/zones/${zoneId}/positions`, {
        position: normalizedPosition,
        positionKey,
        palletData
      }, {
        params: { locationId: locationIdNum }
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying occupancy update (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    // Force refresh zone data after update
    await getZoneOccupancy(zoneId, locationIdNum, { forceRefresh: true });

    return response.data;
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      locationId: locationIdNum,
      position,
      operation: 'updateZoneOccupancy'
    });
  }
};

/**
 * Removes a position from zone occupancy
 * @param {string} zoneId - Zone ID to update
 * @param {number} locationId - Location ID
 * @param {Object} position - Position to remove
 * @returns {Promise<boolean>} Success status
 */
export const removeFromZoneOccupancy = async (zoneId, locationId, position) => {
  // Input validation
  if (!zoneId || !position) {
    throw new Error('Missing required parameters');
  }
  const locationIdNum = validateLocationId(locationId);

  try {
    // Normalize position data
    const normalizedPosition = createPosition(position.row, position.col);
    const positionKey = getPositionKey(normalizedPosition);

    // Execute removal with bounded retries using locationId as query param
    await executeWithRetry(
      () => api.delete(
        `${API_URL}/zones/${zoneId}/positions/${positionKey}`,
        {
          params: { locationId: locationIdNum }
        }
      ),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying position removal (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    // Force refresh zone data after removal
    await getZoneOccupancy(zoneId, locationIdNum, { forceRefresh: true });

    return true;
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      locationId: locationIdNum,
      position,
      operation: 'removeFromZoneOccupancy'
    });
  }
};

export default {
  getZoneOccupancy,
  updateZoneOccupancy,
  removeFromZoneOccupancy
};

// Zone types for different tomato varieties and customers
export const ZONE_TYPES = {
  // Customer zones
  NEW_GREEN: 'new_green_customer',
  BAKKER: 'bakker_customer',
  VAN_GELDER: 'van_gelder_customer',
  
  // Tomato varieties - Plum
  A_FIJN_PLUM: 'a_fijn_plum',
  B_PLUM: 'b_plum',
  PACK_5_PLUM: '5_pack_plum',
  PACK_6_PLUM: '6_pack_plum',
  
  // Tomato varieties - Round
  A_GROF_ROUND: 'a_grof_round',
  B_ROUND: 'b_round',
  A_FIJN_ROUND: 'a_fijn_round',
  A_GROF_PLUS_ROUND: 'a_grof_plus_round',
  A_GROF_LIGHT_ROUND: 'a_grof_light_round',
  A_GROF_LIGHT: 'a_grof_light',
  A_GROF_RED: 'a_grof_red',
  A_FIJN_MIDDLE: 'a_fijn_middle',
  
  // Special varieties
  CHERRY: 'cherry',
  SALES: 'sales',
  OVERSORTEER: 'oversorteer',
  
  // Special zones
  FRIDGE: 'fridge',
  DOCK: 'dock',
  LOGISTICS: 'logistics',
  SPECIAL: 'special', // Generic special zone type
};

// Group types for filtering and visualization
export const TYPE_GROUPS = {
  CUSTOMER: [ZONE_TYPES.NEW_GREEN, ZONE_TYPES.BAKKER, ZONE_TYPES.VAN_GELDER],
  PLUM: [ZONE_TYPES.A_FIJN_PLUM, ZONE_TYPES.B_PLUM, ZONE_TYPES.PACK_5_PLUM, ZONE_TYPES.PACK_6_PLUM],
  ROUND: [
    ZONE_TYPES.A_GROF_ROUND, ZONE_TYPES.B_ROUND, ZONE_TYPES.A_FIJN_ROUND, 
    ZONE_TYPES.A_GROF_PLUS_ROUND, ZONE_TYPES.A_GROF_LIGHT_ROUND, 
    ZONE_TYPES.A_GROF_LIGHT, ZONE_TYPES.A_GROF_RED, ZONE_TYPES.A_FIJN_MIDDLE
  ],
  SPECIAL: [
    ZONE_TYPES.CHERRY, ZONE_TYPES.SALES, ZONE_TYPES.OVERSORTEER, 
    ZONE_TYPES.FRIDGE, ZONE_TYPES.DOCK, ZONE_TYPES.LOGISTICS, ZONE_TYPES.SPECIAL
  ]
};

export default ZONE_TYPES;

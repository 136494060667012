// Environment detection
export const isDevelopment = process.env.NODE_ENV === 'development';

// Development mode configuration
export const config = {
  // Base configuration
  isDevelopment,
  
  // Auth configuration
  bypassAuth: isDevelopment,
  skipPasswordChange: isDevelopment,
  
  // API configuration
  apiBaseUrl: isDevelopment 
    ? '' // Empty string for relative URLs, will use proxy
    : 'https://eyesu.ltd',
  
  // WebSocket configuration
  wsBaseUrl: isDevelopment
    ? 'ws://localhost:5000' // Direct WebSocket connection
    : 'wss://eyesu.ltd',
  
  // Development mode permissions
  devPermissions: isDevelopment ? {
    desktop_access: true,
    mobile_access: true,
    admin_tab: true,
    kleinpak_console: true,
    logistics_console: true,
    sorting_console: true,
    kleinpak_access: true,
    logistics_access: true,
    sorting_access: true,
    manage_users: true,
    manage_permissions: true,
    view_analytics: true,
    manage_orders: true,
    manage_pallets: true,
    view_storage_map: true,
    manage_storage_assignments: true
  } : null
};

/**
 * SSETokenManager.js
 * Manages authentication token lifecycle for SSE connections
 */

class SSETokenManager {
  constructor() {
    this.refreshing = false;
    this.refreshTimeout = 30000; // 30s timeout
  }

  /**
   * Gets the current authentication token
   * @returns {string|null} The authentication token
   */
  getAuthToken() {
    try {
      return localStorage.getItem('token');
    } catch (error) {
      console.error('Error getting auth token:', {
        error: error.message,
        timestamp: new Date().toISOString()
      });
      return null;
    }
  }

  /**
   * Checks if a JWT token is expired or about to expire
   * @param {string} token - The JWT token to check
   * @returns {boolean} True if the token is expired or will expire soon
   */
  isTokenExpired(token) {
    try {
      const payload = token.split('.')[1];
      if (!payload) return true;
      
      const decodedPayload = JSON.parse(atob(payload));
      if (!decodedPayload.exp) return false;
      
      const expirationTime = decodedPayload.exp * 1000;
      const currentTime = Date.now();
      
      // Allow 30 second buffer for clock differences
      const isExpired = currentTime > (expirationTime - 30000);
      
      if (isExpired) {
        console.log('Token is expired or about to expire:', {
          expiration: new Date(expirationTime).toISOString(),
          current: new Date(currentTime).toISOString(),
          secondsRemaining: Math.floor((expirationTime - currentTime) / 1000)
        });
      }
      
      return isExpired;
    } catch (error) {
      console.error('Error checking token expiration:', error);
      return true;
    }
  }

  /**
   * Refreshes the authentication token with timeout protection
   * @returns {Promise<string|null>} The new token, or null if refresh failed
   */
  async refreshToken() {
    if (this.refreshing) {
      console.log('Token refresh already in progress');
      return null;
    }

    try {
      this.refreshing = true;
      const refreshPromise = this._performTokenRefresh();
      const timeoutPromise = this._createTimeout();

      const result = await Promise.race([refreshPromise, timeoutPromise]);
      return result;
    } finally {
      this.refreshing = false;
    }
  }

  /**
   * Creates a timeout promise
   * @private
   */
  _createTimeout() {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.error('Token refresh timeout exceeded:', this.refreshTimeout);
        resolve(null);
      }, this.refreshTimeout);
    });
  }

  /**
   * Performs the actual token refresh operation
   * @private
   */
  async _performTokenRefresh() {
    try {
      const axiosModule = await import('@lib/api/axios');
      const axios = axiosModule.default;
      
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      
      const response = await axios.post('/api/auth/refresh-token', {
        refreshToken
      });
      
      if (response.data?.token) {
        localStorage.setItem('token', response.data.token);
        console.log('Token refreshed successfully');
        return response.data.token;
      }
      
      console.error('Refresh response did not contain token');
      return null;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  }
}

export default SSETokenManager;

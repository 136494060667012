import { useState, useEffect } from 'react';
import palletAssignmentHistoryService from '@features/map/services/palletAssignmentHistoryService';
import api from '@lib/api/axios';

// Constants
const PATTERN_FORMAT_VERSION = '1.0.0';
const PATTERN_VERSION_KEY = 'pallet_pattern_version';
const MAX_PREVIOUS_PALLETS = 50;

/**
 * Custom hook for managing pallet assignment patterns
 * @param {Object} props - Hook configuration
 * @param {string} props.locationId - Current location ID
 * @param {string} props.userId - Current user ID
 * @returns {Object} Pattern management state and utilities
 */
export const usePalletPatterns = ({ locationId, userId }) => {
  const [columnAssignData, setColumnAssignData] = useState(null);
  const [showColumnAssign, setShowColumnAssign] = useState(false);
  const [previousStorageData, setPreviousStorageData] = useState(null);
  const [showPreviousStorageDialog, setShowPreviousStorageDialog] = useState(false);

  // Log warnings in development for missing dependencies
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (!locationId) {
        console.warn('usePalletPatterns: locationId is missing, pattern features will be disabled');
      }
      if (!userId) {
        console.warn('usePalletPatterns: userId is missing, pattern features will be disabled');
      }
    }
  }, [locationId, userId]);

  // Initialize pattern version check
  useEffect(() => {
    const storedVersion = localStorage.getItem(PATTERN_VERSION_KEY);
    if (storedVersion !== PATTERN_FORMAT_VERSION) {
      palletAssignmentHistoryService.clearAllPatterns();
      localStorage.setItem(PATTERN_VERSION_KEY, PATTERN_FORMAT_VERSION);
    }
  }, []);

  /**
   * Check for previous pallets from the same position
   * @param {string} positionLabel - Position identifier
   * @returns {Promise<Object|null>} Previous storage data if found
   */
  const checkPreviousPositionPallets = async (positionLabel) => {
    // Return early if required dependencies are missing
    if (!locationId) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('usePalletPatterns: Cannot check previous pallets, locationId is missing');
      }
      return null;
    }
    if (!positionLabel) {
      throw new Error('Position label is required');
    }

    try {
      const today = new Date().toISOString().split('T')[0];
      
      const response = await api.get(`/api/pallets/${locationId}`, {
        params: {
          day: today,
          position: positionLabel,
          limit: MAX_PREVIOUS_PALLETS
        }
      });

      const previousPallets = (response.data.data || [])
        .filter(p => p.position === positionLabel && p.storageZone)
        .slice(0, MAX_PREVIOUS_PALLETS);

      const lastStoredPallet = previousPallets[0];
      
      if (lastStoredPallet?.storageZone) {
        const match = lastStoredPallet.storageZone.match(/^([\w-]+)-row-(\d+)$/);
        if (match) {
          return {
            zoneId: match[1],
            rowIndex: parseInt(match[2]),
            storageZone: lastStoredPallet.storageZone,
            timestamp: lastStoredPallet.addedDate
          };
        }
      }
      
      return null;
    } catch (error) {
      console.error('Error checking previous position pallets:', error);
      return null;
    }
  };

  /**
   * Find and apply matching pattern for a pallet
   * @param {Object} pallet - Pallet data
   * @returns {Promise<Object|null>} Pattern data if found
   */
  const findAndApplyPattern = async (pallet) => {
    // Return early if required dependencies are missing
    if (!locationId || !userId) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('usePalletPatterns: Cannot find pattern, locationId or userId is missing');
      }
      return null;
    }
    if (!pallet) {
      throw new Error('Pallet data is required');
    }

    try {
      // Check for matching pattern
      const pattern = palletAssignmentHistoryService.findMatchingPattern(pallet, userId);
      
      if (pattern) {
        return {
          type: 'pattern',
          data: pattern
        };
      }

      // Check for previous position storage
      const previousStorage = await checkPreviousPositionPallets(pallet.position);
      
      if (previousStorage) {
        return {
          type: 'previous',
          data: previousStorage
        };
      }

      return null;
    } catch (error) {
      console.error('Error finding pattern:', error);
      return null;
    }
  };

  /**
   * Save a new assignment pattern
   * @param {Object} pallet - Pallet data
   * @param {string} zoneId - Zone identifier
   * @param {number} row - Row index
   * @param {number} col - Column index
   * @returns {Promise<Object>} Saved pattern
   */
  const savePattern = async (pallet, zoneId, row, col) => {
    // Return early if required dependencies are missing
    if (!locationId || !userId) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('usePalletPatterns: Cannot save pattern, locationId or userId is missing');
      }
      return null;
    }
    if (!pallet || !zoneId || typeof row !== 'number' || typeof col !== 'number') {
      throw new Error('Invalid parameters for saving pattern');
    }

    return palletAssignmentHistoryService.saveAssignmentPattern(
      {
        ...pallet,
        position: String(pallet.position).trim()
      },
      zoneId,
      row,
      col,
      userId
    );
  };

  return {
    // State
    columnAssignData,
    showColumnAssign,
    previousStorageData,
    showPreviousStorageDialog,
    
    // Setters
    setColumnAssignData,
    setShowColumnAssign,
    setPreviousStorageData,
    setShowPreviousStorageDialog,
    
    // Pattern operations
    findAndApplyPattern,
    savePattern,
    checkPreviousPositionPallets
  };
};

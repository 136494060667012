import { useState, useCallback, useEffect } from 'react';
import { analyticsService } from '../services/analyticsService';
import { useToast } from '@lib/contexts';

export const useAnalytics = (locationId) => {
  const [events, setEvents] = useState([]);
  const [stats, setStats] = useState(null);
  const [userActivity, setUserActivity] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    eventTypes: [],
    userId: '',
    palletId: '',
    orderId: '',
    limit: 100,
    offset: 0
  });

  const { showToast } = useToast();

  // Fetch events with current filters
  const fetchEvents = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getEvents({
        ...filters,
        locationId
      });
      setEvents(data);
    } catch (error) {
      console.error('Error fetching events:', error);
      showToast({
        message: 'Failed to fetch events',
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [filters, locationId, showToast]);

  // Fetch stats with current filters
  const fetchStats = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getStats({
        startDate: filters.startDate,
        endDate: filters.endDate,
        locationId
      });
      setStats(data);
    } catch (error) {
      console.error('Error fetching stats:', error);
      showToast({
        message: 'Failed to fetch statistics',
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [filters.startDate, filters.endDate, locationId, showToast]);

  // Fetch user activity with current filters
  const fetchUserActivity = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getUserActivity({
        startDate: filters.startDate,
        endDate: filters.endDate,
        locationId
      });
      setUserActivity(data);
    } catch (error) {
      console.error('Error fetching user activity:', error);
      showToast({
        message: 'Failed to fetch user activity',
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [filters.startDate, filters.endDate, locationId, showToast]);

  // Get events for a specific pallet
  const getPalletEvents = useCallback(async (palletId) => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getPalletEvents(palletId);
      return data;
    } catch (error) {
      console.error('Error fetching pallet events:', error);
      showToast({
        message: 'Failed to fetch pallet events',
        variant: 'error'
      });
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [showToast]);

  // Get events for a specific order
  const getOrderEvents = useCallback(async (orderId) => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getOrderEvents(orderId);
      return data;
    } catch (error) {
      console.error('Error fetching order events:', error);
      showToast({
        message: 'Failed to fetch order events',
        variant: 'error'
      });
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [showToast]);

  // Add an intervention to an event
  const addIntervention = useCallback(async (eventId, interventionData) => {
    try {
      setIsLoading(true);
      const updatedEvent = await analyticsService.addIntervention(eventId, interventionData);
      
      // Refresh events to include the new intervention
      await fetchEvents();
      
      showToast({
        message: 'Intervention added successfully',
        variant: 'success'
      });
      
      return updatedEvent;
    } catch (error) {
      console.error('Error adding intervention:', error);
      showToast({
        message: 'Failed to add intervention',
        variant: 'error'
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [fetchEvents, showToast]);

  // Update filters
  const updateFilters = useCallback((newFilters) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters
    }));
  }, []);

  // Clear filters
  const clearFilters = useCallback(() => {
    setFilters({
      startDate: null,
      endDate: null,
      eventTypes: [],
      userId: '',
      palletId: '',
      orderId: '',
      limit: 100,
      offset: 0
    });
  }, []);

  // Fetch available event types
  const fetchEventTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await analyticsService.getEventTypes();
      setEventTypes(data);
    } catch (error) {
      console.error('Error fetching event types:', error);
      showToast({
        message: 'Failed to fetch event types',
        variant: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [showToast]);

  // Load initial data when locationId changes
  useEffect(() => {
    if (locationId) {
      fetchEvents();
      fetchStats();
      fetchUserActivity();
      fetchEventTypes();
    }
  }, [locationId, fetchEvents, fetchStats, fetchUserActivity, fetchEventTypes]);

  return {
    events,
    stats,
    userActivity,
    eventTypes,
    isLoading,
    filters,
    updateFilters,
    clearFilters,
    fetchEvents,
    fetchStats,
    fetchUserActivity,
    fetchEventTypes,
    getPalletEvents,
    getOrderEvents,
    addIntervention
  };
};

import React, { useState, useEffect } from 'react';
import { Check, AlertTriangle, ArrowRight, Package, User, QrCode, List, Rows } from 'lucide-react';
import { Alert, AlertDescription } from '@lib/components/ui';
import { palletTypes, boxTypes, tomatoOptions } from '@lib/constants';
import { api } from '@lib/api';
import styled from 'styled-components';
import PalletSelectionModal from '../PalletSelectionModal';
import RowSelectionModal from '../RowSelectionModal';

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const StepCircle = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.default};
  border: 2px solid;
  ${({ isActive, isCompleted, theme }) => {
    if (isCompleted) {
      return `
        background-color: ${theme.colors.primary.main};
        border-color: ${theme.colors.primary.main};
        color: ${theme.colors.common.white};
      `;
    }
    if (isActive) {
      return `
        border-color: ${theme.colors.primary.main};
        color: ${theme.colors.primary.main};
      `;
    }
    return `
      border-color: ${theme.colors.gray[300]};
      color: ${theme.colors.gray[500]};
    `;
  }}
`;

const StepTitle = styled.span`
  ${({ theme }) => theme.typography.body2};
  margin-left: ${({ theme }) => theme.spacing.sm};
  color: ${({ isActive, theme }) => 
    isActive ? theme.colors.text.primary : theme.colors.text.secondary};
  display: none;
  @media (min-width: 640px) {
    display: block;
  }
`;

const StepConnector = styled.div`
  flex: 1;
  height: 2px;
  margin: 0 ${({ theme }) => theme.spacing.md};
  background-color: ${({ isCompleted, theme }) => 
    isCompleted ? theme.colors.primary.main : theme.colors.gray[300]};
  transition: ${({ theme }) => theme.transitions.default};
`;

const FormContainer = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const FormSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  h2 {
    ${({ theme }) => theme.typography.h2};
    color: ${({ theme }) => theme.colors.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ hasError, theme }) => 
    hasError ? theme.colors.error : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ hasError, theme }) => 
      hasError ? theme.colors.error : theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ hasError, theme }) => 
      hasError ? `${theme.colors.error}20` : `${theme.colors.primary.main}20`};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darker};
    cursor: not-allowed;
  }
`;

const Select = styled(Input).attrs({ as: 'select' })`
  cursor: pointer;
`;

const TextArea = styled(Input).attrs({ as: 'textarea' })`
  min-height: 100px;
  resize: vertical;
`;

const ErrorText = styled.p`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme }) => theme.typography.body2};
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.secondary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}
`;

// New styled components for the Kleinpak toggle
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 2px solid ${({ theme }) => theme.colors.primary.main};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  accent-color: ${({ theme }) => theme.colors.primary.main};
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.body1};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary.dark};
  cursor: pointer;
`;

const CheckboxHighlight = styled.div`
  position: absolute;
  top: -12px;
  right: -5px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
`;

// Style for the pallet selection section
const PalletSelectionSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.background.hover};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px dashed ${({ theme }) => theme.colors.border.main};
`;

const PalletChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

const PalletChip = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.dark};
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
`;

export const OrderForm = ({
  onOrderAdded,
  onCancel,
  initialData = null,
  locationId,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    customer_name: initialData?.customer_name || '',
    // Keep these for backward compatibility but they won't be shown in the form
    palletType: initialData?.palletType || '',
    boxType: initialData?.boxType || '',
    tomatoType: initialData?.tomatoType || '',
    tomatoOption: initialData?.tomatoOption || '',
    processStage: 'unprocessed', // Default value, not shown in form anymore
    total_pallets: initialData?.total_pallets || 1,
    output_colli: initialData?.output_colli || 0,
    priority: initialData?.priority || 'normal',
    due_date: initialData?.due_date || '',
    notes: initialData?.notes || '',
    isKleinpakOrder: initialData?.isKleinpakOrder || false,
    processingLine: initialData?.processingLine || '',
    operator_id: initialData?.operator_id || '',
    allowed_pallet_ids: initialData?.allowed_pallet_ids || [],
    scheduled_row_ids: initialData?.scheduled_row_ids || [],
    id: initialData?.id // Preserve the ID when editing
  });

  const [operators, setOperators] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [palletModalOpen, setPalletModalOpen] = useState(false);
  const [selectedPallets, setSelectedPallets] = useState([]);
  const [rowModalOpen, setRowModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  
  // Fetch operators on component mount
  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await api.get('/api/users');
        // Filter users with operator role
        const operatorUsers = response.data.data.filter(
          user => user.role === 'operator'
        );
        setOperators(operatorUsers);
      } catch (error) {
        console.error('Error fetching operators:', error);
      }
    };
    
    fetchOperators();
  }, []);

  // Setup initial selected pallets and rows if editing an order
  useEffect(() => {
    if (initialData?.allowed_pallet_ids && initialData.allowed_pallet_ids.length > 0) {
      setSelectedPallets(initialData.allowed_pallet_ids);
    }
    
    if (initialData?.scheduled_rows && initialData.scheduled_rows.length > 0) {
      setSelectedRows(initialData.scheduled_rows);
    }
  }, [initialData]);

  const steps = [
    {
      title: 'Customer Details',
      fields: ['customer_name', 'due_date']
    },
    {
      title: 'Order Type',
      fields: ['isKleinpakOrder', 'processingLine', 'operator_id']
    },
    {
      title: 'Quantity Details',
      fields: ['total_pallets', 'allowed_pallet_ids']
    },
    {
      title: 'Additional Information',
      fields: ['notes']
    }
  ];

  const validateStep = (stepIndex) => {
    const stepFields = steps[stepIndex - 1].fields;
    const newErrors = {};

    stepFields.forEach(field => {
      switch (field) {
        case 'customer_name':
          if (!formData.customer_name) {
            newErrors.customer_name = 'Customer name is required';
          }
          break;
        case 'total_pallets':
          if (!formData.total_pallets || formData.total_pallets < 1) {
            newErrors.total_pallets = 'At least 1 pallet is required';
          }
          break;
        case 'due_date':
          if (formData.due_date) {
            // Create date objects for today and due date
            const dueDate = new Date(formData.due_date);
            const today = new Date();
            
            // Set both to midnight to compare only the date portion
            dueDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);
            
            // Compare dates without time component
            if (dueDate < today) {
              newErrors.due_date = 'Due date cannot be in the past';
            }
          }
          break;
        case 'output_colli':
          if (formData.output_colli < 0) {
            newErrors.output_colli = 'Output colli cannot be negative';
          }
          break;
        case 'processingLine':
          if (formData.isKleinpakOrder && !formData.processingLine) {
            newErrors.processingLine = 'Processing line is required for Kleinpak orders';
          }
          break;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => Math.min(prev + 1, steps.length));
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleSubmit = async () => {
    if (!validateStep(currentStep)) {
      return;
    }

    setLoading(true);
    try {
      // Include selected pallets and rows in the form data
      const orderData = {
        ...formData,
        location_id: locationId,
        allowed_pallet_ids: selectedPallets,
        scheduled_rows: selectedRows,
      };
      
      // Ensure due_date is properly formatted
      if (orderData.due_date) {
        // This ensures the date is properly formatted for the API
        // and will display correctly in the OrderCard
        const date = new Date(orderData.due_date);
        const formattedDate = date.toISOString().split('T')[0];
        orderData.due_date = formattedDate;
      }
      
      console.log('Submitting order with data:', orderData);
      
      // onOrderAdded is handleAddOrder from useOrderHandlers
      // It now returns true on success, false on failure
      const success = await onOrderAdded(orderData);
      
      if (success) {
        setFeedback({
          type: 'success',
          message: initialData ? 'Order updated successfully' : 'Order created successfully'
        });
      } else {
        // The error message is already shown via toast in handleAddOrder
        // Just close the modal without success feedback
        if (onCancel) {
          onCancel();
        }
      }
    } catch (error) {
      console.error('Order submission error:', error);
      setFeedback({
        type: 'error',
        message: error.message || (initialData ? 'Failed to update order' : 'Failed to create order')
      });
    } finally {
      setLoading(false);
    }
  };

  const renderStepIndicator = () => (
    <StepIndicator>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StepCircle
              isActive={index + 1 === currentStep}
              isCompleted={index + 1 < currentStep}
            >
              {index + 1 < currentStep ? (
                <Check className="w-4 h-4" />
              ) : (
                <span>{index + 1}</span>
              )}
            </StepCircle>
            <StepTitle isActive={index + 1 === currentStep}>
              {step.title}
            </StepTitle>
          </div>
          {index < steps.length - 1 && (
            <StepConnector isCompleted={index + 1 < currentStep} />
          )}
        </React.Fragment>
      ))}
    </StepIndicator>
  );

  const handlePalletSelection = (selectedPalletIds) => {
    setSelectedPallets(selectedPalletIds);
    // Also update the form data
    setFormData(prev => ({
      ...prev,
      allowed_pallet_ids: selectedPalletIds
    }));
  };
  
  const handleRowSelection = (selectedRowData) => {
    setSelectedRows(selectedRowData);
    // Also update the form data
    setFormData(prev => ({
      ...prev,
      scheduled_rows: selectedRowData
    }));
  };

  const renderField = (field) => {
    const hasError = !!errors[field];

    switch (field) {
      case 'customer_name':
        return (
          <Input
            type="text"
            value={formData.customer_name}
            onChange={(e) => handleInputChange('customer_name', e.target.value)}
            hasError={hasError}
            placeholder="Enter customer name"
          />
        );

      case 'tomatoType':
        return (
          <Select
            value={formData.tomatoType}
            onChange={(e) => handleInputChange('tomatoType', e.target.value)}
            hasError={hasError}
          >
            <option value="">Select Tomato Type</option>
            {Object.keys(tomatoOptions).map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
        );

      case 'tomatoOption':
        return (
          <Select
            value={formData.tomatoOption}
            onChange={(e) => handleInputChange('tomatoOption', e.target.value)}
            hasError={hasError}
            disabled={!formData.tomatoType}
          >
            <option value="">Select Tomato Option</option>
            {Object.keys(tomatoOptions[formData.tomatoType] || {}).map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        );

      case 'palletType':
        return (
          <Select
            value={formData.palletType}
            onChange={(e) => handleInputChange('palletType', e.target.value)}
            hasError={hasError}
          >
            <option value="">Select Pallet Type</option>
            {palletTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
        );

      case 'boxType':
        return (
          <Select
            value={formData.boxType}
            onChange={(e) => handleInputChange('boxType', e.target.value)}
            hasError={hasError}
          >
            <option value="">Select Box Type</option>
            {boxTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
        );

      case 'isKleinpakOrder':
        return (
          <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox
                checked={formData.isKleinpakOrder}
                onChange={(e) => handleInputChange('isKleinpakOrder', e.target.checked)}
                id="kleinpak-checkbox"
              />
              Kleinpak Order
            </CheckboxLabel>
            <CheckboxHighlight>Special</CheckboxHighlight>
          </CheckboxContainer>
        );

      case 'processingLine':
        if (!formData.isKleinpakOrder) {
          return null; // Don't render this field if isKleinpakOrder is false
        }
        return (
          <FormGroup>
            <Label>Processing Line {formData.isKleinpakOrder ? "(Required for Kleinpak)" : ""}</Label>
            <Select
              value={formData.processingLine}
              onChange={(e) => handleInputChange('processingLine', e.target.value)}
              hasError={hasError}
              style={{
                borderColor: formData.isKleinpakOrder ? 
                  hasError ? '#f44336' : '#1976d2' : null
              }}
            >
              <option value="">Select Processing Line</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(line => (
                <option key={line} value={line}>Line {line}</option>
              ))}
            </Select>
            {hasError && <ErrorText>{errors.processingLine}</ErrorText>}
          </FormGroup>
        );

      case 'allowed_pallet_ids':
        return (
          <div>
            <Label>Order Scheduling Options</Label>
            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
              <div style={{ flex: 1 }}>
                <p style={{ fontSize: '14px', marginBottom: '8px', color: '#666' }}>
                  Optionally specify which pallets operators are allowed to use for this order.
                </p>
                <Button
                  onClick={() => setPalletModalOpen(true)}
                  variant="primary"
                  style={{ width: '100%' }}
                >
                  <QrCode size={16} />
                  {selectedPallets.length > 0 ? 'Edit Selected Pallets' : 'Select Pallets'}
                </Button>
                <div style={{ fontSize: '13px', color: '#666', textAlign: 'center', marginTop: '4px' }}>
                  {selectedPallets.length} {selectedPallets.length === 1 ? 'pallet' : 'pallets'} selected
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <p style={{ fontSize: '14px', marginBottom: '8px', color: '#666' }}>
                  Schedule specific storage rows to be used for this order.
                </p>
                <Button
                  onClick={() => setRowModalOpen(true)}
                  variant="primary"
                  style={{ width: '100%' }}
                >
                  <Rows size={16} />
                  {selectedRows.length > 0 ? 'Edit Selected Rows' : 'Schedule Rows'} 
                  <span style={{ 
                    marginLeft: '8px', 
                    fontSize: '11px', 
                    padding: '1px 6px', 
                    borderRadius: '10px', 
                    background: '#e0f2fe', 
                    color: '#0284c7'
                  }}>
                    Enhanced Map View
                  </span>
                </Button>
                <div style={{ fontSize: '13px', color: '#666', textAlign: 'center', marginTop: '4px' }}>
                  {selectedRows.length} {selectedRows.length === 1 ? 'row' : 'rows'} scheduled
                </div>
              </div>
            </div>
            
            {(selectedPallets.length > 0 || selectedRows.length > 0) && (
              <PalletSelectionSection>
                {selectedPallets.length > 0 && (
                  <div style={{ marginBottom: '12px' }}>
                    <div style={{ fontWeight: 600, marginBottom: '8px' }}>Selected Pallets:</div>
                    <PalletChipsContainer>
                      {selectedPallets.slice(0, 5).map(palletId => (
                        <PalletChip key={palletId}>
                          Pallet #{palletId.toString().substring(0, 8)}
                        </PalletChip>
                      ))}
                      {selectedPallets.length > 5 && (
                        <PalletChip>+{selectedPallets.length - 5} more</PalletChip>
                      )}
                    </PalletChipsContainer>
                  </div>
                )}
                
                {selectedRows.length > 0 && (
                  <div>
                    <div style={{ fontWeight: 600, marginBottom: '8px' }}>Scheduled Rows:</div>
                    <PalletChipsContainer>
                      {selectedRows.slice(0, 5).map((row, index) => (
                        <PalletChip key={row.rowId || index} style={{ background: '#e0f7fa', color: '#006064' }}>
                          {row.zoneName || row.zoneId} - Row {row.rowIndex + 1}
                        </PalletChip>
                      ))}
                      {selectedRows.length > 5 && (
                        <PalletChip style={{ background: '#e0f7fa', color: '#006064' }}>
                          +{selectedRows.length - 5} more
                        </PalletChip>
                      )}
                    </PalletChipsContainer>
                  </div>
                )}
              </PalletSelectionSection>
            )}
          </div>
        );

      case 'total_pallets':
        return (
          <Input
            type="number"
            value={formData.total_pallets}
            onChange={(e) => handleInputChange('total_pallets', parseInt(e.target.value))}
            hasError={hasError}
            min="1"
            max="100"
            placeholder="Enter total number of pallets"
          />
        );

      case 'output_colli':
        return (
          <Input
            type="number"
            value={formData.output_colli}
            onChange={(e) => handleInputChange('output_colli', parseInt(e.target.value))}
            hasError={hasError}
            min="0"
            placeholder="Enter expected output boxes"
          />
        );

      case 'priority':
        return (
          <Select
            value={formData.priority}
            onChange={(e) => handleInputChange('priority', e.target.value)}
            hasError={hasError}
          >
            <option value="normal">Normal Priority</option>
            <option value="urgent">Urgent</option>
            <option value="low">Low Priority</option>
          </Select>
        );

      case 'due_date':
        return (
          <Input
            type="date"
            value={formData.due_date}
            onChange={(e) => handleInputChange('due_date', e.target.value)}
            hasError={hasError}
            min={new Date().toISOString().split('T')[0]}
          />
        );

      case 'processStage':
        return (
          <Select
            value={formData.processStage}
            onChange={(e) => handleInputChange('processStage', e.target.value)}
            hasError={hasError}
          >
            <option value="unprocessed">Unprocessed</option>
            <option value="in_processing">In Processing</option>
            <option value="processed">Processed</option>
            <option value="quality_check">Quality Check</option>
            <option value="ready_for_shipping">Ready for Shipping</option>
          </Select>
        );

      case 'operator_id':
        // Only show operator selection if isKleinpakOrder is true
        if (!formData.isKleinpakOrder) {
          return null;
        }
        return (
          <FormGroup>
            <Label>Assign Operator (Optional)</Label>
            <Select
              value={formData.operator_id}
              onChange={(e) => handleInputChange('operator_id', e.target.value)}
              hasError={hasError}
            >
              <option value="">Select Operator (Optional)</option>
              {operators.map(operator => (
                <option key={operator.id} value={operator.id}>
                  {operator.username}
                </option>
              ))}
            </Select>
            <p style={{ marginTop: '4px', fontSize: '12px', color: '#666' }}>
              <User size={12} style={{ display: 'inline', marginRight: '4px' }} />
              Assigning an operator will make this order visible in their dashboard
            </p>
          </FormGroup>
        );
        
      case 'notes':
        return (
          <TextArea
            value={formData.notes}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            hasError={hasError}
            placeholder="Enter any additional information or requirements"
          />
        );

      default:
        return null;
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
    
    // Special handling for isKleinpakOrder
    if (name === 'isKleinpakOrder' && !value) {
      // Clear processingLine and operator_id when kleinpak toggle is turned off
      setFormData(prev => ({ 
        ...prev, 
        processingLine: '',
        operator_id: '' 
      }));
    }
  };

  const renderCurrentStep = () => {
    const currentFields = steps[currentStep - 1].fields;

    return (
      <div className="space-y-4">
        {currentFields.map(field => {
          // Skip processingLine, we'll handle it specially
          if (field === 'processingLine') {
            return renderField(field);
          }
          
          return (
            <FormGroup key={field}>
              {field !== 'isKleinpakOrder' && (
                <Label>
                  {field === 'notes' 
                    ? 'Additional Information' 
                    : field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </Label>
              )}
              {renderField(field)}
              {errors[field] && field !== 'processingLine' && (
                <ErrorText>{errors[field]}</ErrorText>
              )}
            </FormGroup>
          );
        })}
      </div>
    );
  };

  return (
    <FormContainer>
      {renderStepIndicator()}

      <FormSection>
        <h2>{steps[currentStep - 1].title}</h2>
        {renderCurrentStep()}
      </FormSection>

      <ButtonGroup>
        <Button
          onClick={handlePrevious}
          disabled={currentStep === 1 || loading}
        >
          Previous
        </Button>

        {currentStep < steps.length ? (
          <Button
            variant="primary"
            onClick={handleNext}
            disabled={loading}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (initialData ? 'Updating Order...' : 'Creating Order...') : (initialData ? 'Update Order' : 'Create Order')}
          </Button>
        )}
      </ButtonGroup>

      {feedback && (
        <Alert className={`mt-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}

      {/* Pallet Selection Modal */}
      <PalletSelectionModal 
        isOpen={palletModalOpen}
        onClose={() => setPalletModalOpen(false)}
        locationId={locationId}
        initiallySelectedPalletIds={selectedPallets}
        onPalletsSelected={handlePalletSelection}
      />
      
      {/* Row Selection Modal */}
      <RowSelectionModal 
        isOpen={rowModalOpen}
        onClose={() => setRowModalOpen(false)}
        locationId={locationId}
        initiallySelectedRowIds={selectedRows.map(row => row.rowId)}
        onRowsSelected={handleRowSelection}
      />
    </FormContainer>
  );
};

export default OrderForm;

import styled from 'styled-components';

export const PalletCard = styled.div`
  background-color: ${props => props.theme.colors.background.paper};
  border-radius: ${props => props.theme.borderRadius.lg};
  padding: ${props => props.theme.spacing.lg};
  margin-bottom: ${props => props.theme.spacing.md};
  box-shadow: ${props => props.theme.shadows.sm};
  transition: ${props => props.theme.transitions.default};

  &:hover {
    box-shadow: ${props => props.theme.shadows.md};
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.lg};
  background-color: ${props => props.theme.colors.primary.main};
  color: ${props => props.theme.colors.background.paper};
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  font-weight: 500;
  cursor: pointer;
  transition: ${props => props.theme.transitions.default};

  &:hover {
    background-color: ${props => props.theme.colors.primary.dark};
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.sm};
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => {
    switch (props.variant) {
      case 'ship':
        return props.theme.colors.success + '20';
      case 'edit':
        return props.theme.colors.warning + '20';
      case 'delete':
        return props.theme.colors.error + '20';
      case 'info':
        return props.theme.colors.info + '20';
      default:
        return props.theme.colors.gray[200];
    }
  }};
  color: ${props => {
    switch (props.variant) {
      case 'ship':
        return props.theme.colors.success;
      case 'edit':
        return props.theme.colors.warning;
      case 'delete':
        return props.theme.colors.error;
      case 'info':
        return props.theme.colors.info;
      default:
        return props.theme.colors.text.primary;
    }
  }};
  cursor: pointer;
  transition: ${props => props.theme.transitions.default};

  &:hover {
    background-color: ${props => {
      switch (props.variant) {
        case 'ship':
          return props.theme.colors.success + '40';
        case 'edit':
          return props.theme.colors.warning + '40';
        case 'delete':
          return props.theme.colors.error + '40';
        case 'info':
          return props.theme.colors.info + '40';
        default:
          return props.theme.colors.gray[300];
      }
    }};
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
  border-radius: ${props => props.theme.borderRadius.sm};
  font-size: ${props => props.theme.typography.body2.fontSize};
  font-weight: 500;
  background-color: ${props => 
    props.status === 'completed' 
      ? props.theme.colors.success + '20'
      : props.theme.colors.gray[200]
  };
  color: ${props => 
    props.status === 'completed' 
      ? props.theme.colors.success
      : props.theme.colors.text.secondary
  };
`;

export const ResultCount = styled.div`
  font-size: ${props => props.theme.typography.body2.fontSize};
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing.md};
`;

export const OrderCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.lg};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const OrderCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.md};
`;

export const OrderCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: ${props => props.theme.typography.h4.fontSize};
    font-weight: 600;
    color: ${props => props.theme.colors.text.primary};
  }
`;

export const OrderCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.sm};

  p {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing.sm};
    color: ${props => props.theme.colors.text.secondary};
  }
`;

export const OrderProgressSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.lg};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${props => props.theme.colors.gray[200]};
  border-radius: ${props => props.theme.borderRadius.full};
  overflow: hidden;

  > div {
    height: 100%;
    background-color: ${props => props.theme.colors.primary.main};
    transition: width 0.3s ease;
  }
`;

export const OrderMetrics = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${props => props.theme.spacing.md};

  > div {
    p:first-child {
      font-size: ${props => props.theme.typography.body2.fontSize};
      color: ${props => props.theme.colors.text.secondary};
      margin-bottom: ${props => props.theme.spacing.xs};
    }

    p:last-child {
      font-size: ${props => props.theme.typography.body1.fontSize};
      font-weight: 600;
      color: ${props => props.theme.colors.text.primary};
    }
  }
`;

export const PriorityBadge = styled.button`
  display: inline-flex;
  align-items: center;
  padding: ${props => props.theme.spacing.xs} ${props => props.theme.spacing.sm};
  border: none;
  border-radius: ${props => props.theme.borderRadius.sm};
  font-size: ${props => props.theme.typography.body2.fontSize};
  font-weight: 500;
  cursor: pointer;
  background-color: ${props => 
    props.priority === 'urgent' 
      ? props.theme.colors.error + '20'
      : props.theme.colors.warning + '20'
  };
  color: ${props => 
    props.priority === 'urgent' 
      ? props.theme.colors.error
      : props.theme.colors.warning
  };
  transition: ${props => props.theme.transitions.default};

  &:hover {
    background-color: ${props => 
      props.priority === 'urgent' 
        ? props.theme.colors.error + '40'
        : props.theme.colors.warning + '40'
    };
  }
`;

export const OrderActions = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing.sm};
  margin-top: auto;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.sm};
  padding: ${props => props.theme.spacing.sm} ${props => props.theme.spacing.md};
  background-color: ${props => 
    props.active 
      ? props.theme.colors.primary.main + '20'
      : props.theme.colors.gray[200]
  };
  color: ${props => 
    props.active 
      ? props.theme.colors.primary.main
      : props.theme.colors.text.secondary
  };
  border: none;
  border-radius: ${props => props.theme.borderRadius.md};
  font-size: ${props => props.theme.typography.body2.fontSize};
  font-weight: 500;
  cursor: pointer;
  transition: ${props => props.theme.transitions.default};

  &:hover {
    background-color: ${props => 
      props.active 
        ? props.theme.colors.primary.main + '40'
        : props.theme.colors.gray[300]
    };
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { palletTypes, boxTypes } from '@lib/constants';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
  width: 90%;
  max-width: 400px;
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const InfoText = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const Select = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  cursor: pointer;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary.main}20`};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};
  ${({ theme }) => theme.typography.body2};
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const OperatorPalletModal = ({
  position,
  processStage,
  tomatoType,
  onConfirm,
  onCancel
}) => {
  const [formData, setFormData] = useState({
    palletType: '',
    boxType: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    onConfirm({
      ...formData,
      position,
      processStage,
      tomatoType
    });
  };

  const isValid = formData.palletType && formData.boxType;

  return (
    <ModalOverlay onClick={onCancel}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <Title>Add Pallet Details</Title>
        
        <InfoText>Position: {position}</InfoText>
        <InfoText>Process Stage: {processStage}</InfoText>
        <InfoText>Tomato Type: {tomatoType}</InfoText>

        <FormGroup>
          <Label>Pallet Type</Label>
          <Select
            name="palletType"
            value={formData.palletType}
            onChange={handleInputChange}
          >
            <option value="">Select Pallet Type</option>
            {palletTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label>Box Type</Label>
          <Select
            name="boxType"
            value={formData.boxType}
            onChange={handleInputChange}
          >
            <option value="">Select Box Type</option>
            {boxTypes.map(type => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Select>
        </FormGroup>

        <ButtonGroup>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

import styled from 'styled-components';
import { theme } from '@styles/theme';
import { zIndex } from '@styles/constants/zIndex';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90vw;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.xl};
  z-index: ${zIndex.modal + 1};
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.2s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${theme.colors.border.light};
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${theme.colors.text.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${theme.colors.background.hover};
    color: ${theme.colors.text.primary};
  }
`;

export const Content = styled.div`
  padding: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${theme.colors.border.light};
  border-radius: ${theme.borderRadius.md};
  font-family: inherit;
  font-size: 0.875rem;
  resize: vertical;
  min-height: 120px;
  color: ${theme.colors.text.primary};
  background-color: ${theme.colors.background.default};
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary.main};
  }

  &:disabled {
    background-color: ${theme.colors.background.disabled};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${theme.colors.text.placeholder};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 0.5rem;
`;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  border-radius: ${theme.borderRadius.sm};
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled(BaseButton)`
  background: none;
  border: 1px solid ${theme.colors.border.light};
  color: ${theme.colors.text.secondary};

  &:not(:disabled):hover {
    background-color: ${theme.colors.background.hover};
    border-color: ${theme.colors.border.dark};
    color: ${theme.colors.text.primary};
  }
`;

export const SubmitButton = styled(BaseButton)`
  background-color: ${theme.colors.primary.main};
  border: none;
  color: white;

  &:not(:disabled):hover {
    background-color: ${theme.colors.primary.dark};
  }
`;

import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';
import { PalletForm } from '../PalletForm';
import { withStorageIntegration } from '@features/operator/components/FinishMenu/StorageIntegration';
import { useProcessing } from '@lib/contexts/ProcessingContext';
import { useToast } from '@lib/contexts/ToastContext';
import { UserContext } from '@lib/contexts/UserContext';
import { api } from '@lib/api';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.md};
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.main}40;
  }
`;

const CloseIcon = styled(X)`
  width: 20px;
  height: 20px;
`;

const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  flex: 1;
`;

/**
 * Base AddPallet modal component with storage zone integration support
 */
export const StorageEnabledAddPalletBase = ({
  isOpen,
  onClose,
  onPalletAdded,
  locationId,
  tomatoType,
  initialData,
  isEditMode
}) => {
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  const { user } = useContext(UserContext);
  const [createdPalletData, setCreatedPalletData] = useState(null);

  if (!isOpen) return null;

  // Handle pallet creation
  const handleAddPallet = async (palletData) => {
    try {
      showProcessing({ 
        message: 'Creating pallet' + (palletData.quantity > 1 ? 's' : '') + '...', 
        showOverlay: true 
      });

      // API call to create pallet(s) with proper authentication
      const response = await api.post('/api/pallets/add', {
        ...palletData,
        locationId,
        userName: user?.username,
        user_id: user?.id
      });

      const createdPallet = response.data;
      setCreatedPalletData(createdPallet);

      // After successful creation, storage zone assignment will be handled by the HOC
      // The withStorageIntegration HOC will intercept the created pallet and show the zone selection UI
      
      showToast({ 
        message: `Successfully created ${palletData.quantity > 1 ? palletData.quantity + ' pallets' : 'a pallet'}`, 
        variant: 'success' 
      });

      if (onPalletAdded) {
        onPalletAdded(createdPallet);
      }

      // Don't close the modal yet - the storage integration will handle that
      // The withStorageIntegration HOC will show a zone selection modal/dialog

    } catch (error) {
      console.error('Error creating pallet:', error);
      showToast({ 
        message: `Error creating pallet: ${error.message}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
    }
  };

  const titleText = isEditMode ? 'Edit Pallet' : 'Add New Pallet';

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <Title>{titleText}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <PalletForm
            onPalletAdded={isEditMode ? onPalletAdded : handleAddPallet}
            onCancel={onClose}
            locationId={locationId}
            tomatoType={tomatoType}
            initialData={initialData}
            isEditMode={isEditMode}
          />
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

// Create an enhanced version with storage zone integration
// This will automatically show the zone selection UI after pallet creation
export const StorageEnabledAddPallet = withStorageIntegration(StorageEnabledAddPalletBase);

// Also export the base component
export default StorageEnabledAddPallet;

/**
 * SSEConnectionManager.js
 * Manages SSE connection lifecycle with bounded retry logic
 */

class SSEConnectionManager {
  constructor(options = {}) {
    // Connection configuration
    this.baseURL = options.baseURL || '';
    this.maxRetries = options.maxRetries || 8;
    this.retryDelay = options.retryDelay || 1000; // Initial delay: 1s
    this.maxRetryDelay = options.maxRetryDelay || 60000; // Max delay: 60s
    this.jitterFactor = options.jitterFactor || 0.3;

    // Internal state
    this.eventSource = null;
    this.retryAttempts = 0;
    this.connectionState = 'DISCONNECTED';
    this.stateChangeHandler = null;
    this.locationId = null;
    this.clientId = null;
    this.lastHeartbeatTime = null;
    this.connectionStartTime = null;
  }

  /**
   * Sets up the connection manager
   * @param {string} locationId - The location ID for the connection
   * @param {string} clientId - The client ID for the connection
   */
  setup(locationId, clientId) {
    this.locationId = locationId;
    this.clientId = clientId;
  }

  /**
   * Updates the connection state
   * @param {string} state - The new state
   * @private
   */
  _setState(state) {
    // Validate state transition
    const validStates = ['CONNECTED', 'CONNECTING', 'DISCONNECTED', 'ERROR', 'AUTH_ERROR', 'STALE'];
    if (!validStates.includes(state)) {
      console.warn('Invalid state transition attempted:', {
        currentState: this.connectionState,
        attemptedState: state,
        timestamp: new Date().toISOString()
      });
      return;
    }

    const prevState = this.connectionState;

    // Handle special state transitions
    if (state === 'CONNECTED' && prevState !== 'CONNECTED') {
      this.connectionStartTime = Date.now();
      this.lastHeartbeatTime = Date.now(); // Initialize heartbeat time on connection
    } else if (state !== 'CONNECTED') {
      this.connectionStartTime = null;
    }

    // Log state change
    console.log('SSE connection state changing:', {
      from: prevState,
      to: state,
      clientId: this.clientId,
      connectionDuration: this.connectionStartTime ? `${Math.round((Date.now() - this.connectionStartTime) / 1000)}s` : 'N/A',
      timestamp: new Date().toISOString()
    });

    this.connectionState = state;
    
    // Notify handler with standardized state object
    if (this.stateChangeHandler) {
      try {
        this.stateChangeHandler({ 
          connectionState: state,
          timestamp: new Date().toISOString(),
          clientId: this.clientId,
          previousState: prevState
        });
      } catch (error) {
        console.error('Error in state change handler:', {
          error: error.message,
          state,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
      }
    }
  }

  /**
   * Creates the SSE URL with proper parameters
   * @param {string} token - The authentication token
   * @param {string} lastEventId - The last event ID
   * @param {number} lastSequence - The last sequence number
   * @returns {URL} The configured URL
   * @private
   */
  _createConnectionURL(token, lastEventId, lastSequence) {
    // Check if baseURL already contains the full path
    let urlString;
    if (this.baseURL.includes(`/api/sse/location/${this.locationId}`)) {
      urlString = this.baseURL;
    } else {
      urlString = `${this.baseURL}/api/sse/location/${this.locationId}`;
    }

    const url = new URL(urlString);
    
    // Add required parameters
    url.searchParams.append('clientId', this.clientId);
    url.searchParams.append('token', token);
    
    // Add optional parameters if available
    if (lastEventId) {
      url.searchParams.append('lastEventId', lastEventId);
    }
    if (lastSequence > 0) {
      url.searchParams.append('lastSequence', lastSequence.toString());
    }

    return url;
  }

  /**
   * Calculates the retry delay with exponential backoff and jitter
   * @param {number} attempt - The current retry attempt
   * @returns {number} The calculated delay in milliseconds
   * @private
   */
  _calculateRetryDelay(attempt) {
    const baseDelay = Math.min(
      this.retryDelay * Math.pow(2, attempt - 1),
      this.maxRetryDelay
    );
    const jitter = baseDelay * this.jitterFactor * (Math.random() * 2 - 1);
    return Math.max(this.retryDelay, Math.min(baseDelay + jitter, this.maxRetryDelay));
  }

  /**
   * Creates and configures a new EventSource
   * @param {URL} url - The connection URL
   * @param {Object} handlers - Event handlers object
   * @returns {EventSource} The configured EventSource
   * @private
   */
  _createEventSource(url, handlers) {
    const eventSource = new EventSource(url.toString(), { withCredentials: true });

    // Set up core event handlers
    eventSource.onopen = () => {
      console.log('SSE connection opened:', {
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      this._setState('CONNECTED');
      this.retryAttempts = 0;
    };

    eventSource.onerror = (error) => {
      this._handleConnectionError(error);
    };

    // Add custom event handlers
    if (handlers) {
      Object.entries(handlers).forEach(([event, handler]) => {
        eventSource.addEventListener(event, (e) => {
          // Update heartbeat time for any successful event
          this.lastHeartbeatTime = Date.now();
          handler(e);
        });
      });
    }

    return eventSource;
  }

  /**
   * Handles connection errors with bounded retries
   * @param {Error} error - The connection error
   * @private
   */
  _handleConnectionError(error) {
    console.error('SSE connection error:', {
      error,
      clientId: this.clientId,
      retryAttempts: this.retryAttempts,
      timestamp: new Date().toISOString()
    });

    if (this.retryAttempts >= this.maxRetries) {
      console.error('Max retry attempts exceeded:', {
        clientId: this.clientId,
        maxRetries: this.maxRetries,
        timestamp: new Date().toISOString()
      });
      this._setState('ERROR');
      this.close();
      return;
    }

    this._setState('CONNECTING');
    this.retryAttempts++;

    const delay = this._calculateRetryDelay(this.retryAttempts);

    console.log('Scheduling connection retry:', {
      clientId: this.clientId,
      attempt: this.retryAttempts,
      delay: `${delay}ms`,
      timestamp: new Date().toISOString()
    });

    // Return promise for retry scheduling
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

  /**
   * Establishes an SSE connection with proper error handling
   * @param {Object} params - Connection parameters
   * @param {string} params.token - Authentication token
   * @param {string} params.lastEventId - Last event ID
   * @param {number} params.lastSequence - Last sequence number
   * @param {Object} params.handlers - Event handlers
   * @returns {Promise<boolean>} True if connection successful
   */
  async connect({ token, lastEventId, lastSequence, handlers }) {
    try {
      // Validate required parameters
      if (!this.locationId || !this.clientId) {
        throw new Error('Connection manager not properly initialized');
      }

      if (!token) {
        throw new Error('Authentication token required');
      }

      // Close existing connection if any
      if (this.eventSource) {
        this.close();
      }

      // Create and configure URL
      const url = this._createConnectionURL(token, lastEventId, lastSequence);
      
      console.log('Connecting to SSE endpoint:', {
        url: url.toString().replace(/token=([^&]+)/, 'token=REDACTED'),
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });

      this._setState('CONNECTING');

      // Create new EventSource
      this.eventSource = this._createEventSource(url, handlers);
      
      return true;
    } catch (error) {
      console.error('Error establishing SSE connection:', {
        error: error.message,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      
      this._setState('ERROR');
      return false;
    }
  }

  /**
   * Closes the SSE connection
   */
  close() {
    if (this.eventSource) {
      console.log('Closing SSE connection:', {
        clientId: this.clientId,
        connectionDuration: this.connectionStartTime ? `${Math.round((Date.now() - this.connectionStartTime) / 1000)}s` : 'N/A',
        timestamp: new Date().toISOString()
      });
      
      this.eventSource.close();
      this.eventSource = null;
      this.connectionStartTime = null;
      this.lastHeartbeatTime = null;
      this._setState('DISCONNECTED');
    }
  }

  /**
   * Sets the state change handler
   * @param {function} handler - The state change handler
   */
  setStateChangeHandler(handler) {
    if (typeof handler !== 'function') {
      throw new Error('State change handler must be a function');
    }

    console.log('Setting state change handler:', {
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });

    this.stateChangeHandler = handler;

    // Immediately notify handler of current state
    try {
      handler({ 
        connectionState: this.connectionState,
        timestamp: new Date().toISOString(),
        clientId: this.clientId
      });
    } catch (error) {
      console.error('Error in initial state handler call:', {
        error: error.message,
        state: this.connectionState,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Gets the current connection state
   * @returns {string} The connection state
   */
  getState() {
    return this.connectionState;
  }

  /**
   * Checks if the connection is currently active and healthy
   * @returns {boolean} True if connected and healthy
   */
  isConnected() {
    // Check basic connection state
    if (this.connectionState !== 'CONNECTED' || !this.eventSource) {
      return false;
    }

    // Check if we have recent activity
    if (this.lastHeartbeatTime) {
      const timeSinceLastHeartbeat = Date.now() - this.lastHeartbeatTime;
      // Consider connection stale if no activity in last minute
      if (timeSinceLastHeartbeat > 60000) {
        console.warn('Connection considered stale due to no recent activity:', {
          timeSinceLastHeartbeat: `${Math.round(timeSinceLastHeartbeat / 1000)}s`,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
        return false;
      }
    }

    return true;
  }
}

export default SSEConnectionManager;

import styled from 'styled-components';

// Main container that wraps the entire zone display (metadata + grid + occupancy bar)
export const StorageZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;
`;

// Metadata container (zone name & occupancy info) - outside the grid
export const StorageMetadata = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 600;
  color: #334155;
`;

// Zone name in the metadata
export const ZoneName = styled.span`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Occupancy text that appears in the metadata
export const MetadataOccupancy = styled.span`
  font-size: 11px;
  color: #64748b;
  font-weight: 500;
`;

// Container for the grid only
export const StorageGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
  background-color: #f8fafc;
  transition: all 0.2s ease;
  position: relative;

  ${props => props.$selected && `
    box-shadow: 0 0 0 2px #3b82f6;
  `}
  
  ${props => props.$isInteractive && `
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    }
  `}
`;

// Optional zone type indicator
export const ZoneType = styled.span`
  font-size: 10px;
  background-color: #e2e8f0;
  color: #475569;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 500;
  margin-left: 4px;
`;

// Grid container for pallet positions
export const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.$cols || 1}, 1fr);
  grid-template-rows: repeat(${props => props.$rows || 1}, 1fr);
  gap: 1px;
  padding: 2px;
  background-color: #f1f5f9;
  min-height: auto;
  position: relative;
  width: 100%;
  height: 100%;
`;

// Individual cell in the grid
export const PalletCell = styled.div`
  background-color: ${props => props.$occupied ? '#cbd5e1' : 'white'};
  border-radius: 2px;
  border: 1px solid ${props => props.$occupied ? '#94a3b8' : '#e2e8f0'};
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #64748b;
  
  /* Red edge highlight for occupied cells */
  ${props => props.$occupied && `
    box-shadow: 0 0 0 2px #ef4444;
    background-color: #f87171;
  `}
  
  ${props => props.$isSelectable && !props.$occupied && `
    cursor: pointer;
    
    &:hover {
      background-color: #f1f5f9;
      border-color: #94a3b8;
    }
  `}
  
  ${props => props.$isSelected && `
    background-color: #eff6ff;
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px #3b82f6;
  `}
`;

// Optional cell content (e.g. position label)
export const CellContent = styled.span`
  font-size: 9px;
  font-weight: 500;
  color: ${props => props.$occupied ? '#334155' : '#64748b'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Footer area with minimal capacity info
export const StorageFooter = styled.div`
  padding: 2px 6px;
  background-color: #f1f5f9;
  border-top: 1px solid #e2e8f0;
  font-size: 10px;
  color: #64748b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

// Occupancy text (e.g. "5/10")
export const OccupancyText = styled.span`
  font-size: 10px;
  font-weight: 500;
`;

// Occupancy bar
export const OccupancyBar = styled.div`
  height: 4px;
  background-color: #e2e8f0;
  border-radius: 2px;
  overflow: hidden;
  width: 50px;
  margin-left: 6px;
`;

// Fill for the occupancy bar
export const OccupancyFill = styled.div`
  height: 100%;
  background-color: ${props => {
    // Color based on occupancy percentage
    if (props.$percentage >= 90) return '#ef4444';
    if (props.$percentage >= 70) return '#f97316';
    return '#22c55e';
  }};
  width: ${props => `${props.$percentage || 0}%`};
  transition: width 0.3s ease;
`;

// Container for both occupancy text and bar
export const OccupancyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

// Dimension indicator (e.g. "5×6")
export const DimensionText = styled.span`
  font-size: 10px;
  color: #64748b;
`;

// Special indicators (e.g. refrigerated)
export const SpecialIndicator = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  
  svg {
    width: 12px;
    height: 12px;
    color: #0284c7;
  }
`;

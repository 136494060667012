import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useDashboard } from '@features/dashboard/contexts/DashboardContext';
import SSEManager from '../services/sse/SSEManager';
import ConnectionStatus from '../components/ui/ConnectionStatus';
import { getSseUrl } from '../../config/api';

const UpdateContext = createContext();

export const UpdateProvider = ({ children }) => {
  const { user } = useUserSession();
  const { selectedLocation, setPallets, setOrders, setOverviewStats } = useDashboard();
  const sseClientRef = useRef(null);
  const sseUnsubscribersRef = useRef([]);
  const [connectionState, setConnectionState] = useState('DISCONNECTED');
  const [error, setError] = useState(null);
  const [isPageVisible, setIsPageVisible] = useState(!document.hidden);
  const reconnectTimeoutRef = useRef(null);
  
  // Keep track of processed pallet updates to prevent duplication
  const processedPalletUpdatesRef = useRef(new Set());
  // Keep track of the current pallets state for deduplication
  const currentPalletsRef = useRef([]);
  // Keep track of the current orders state for deduplication
  const currentOrdersRef = useRef([]);
  
  // Update the refs when the state changes
  useEffect(() => {
    return () => {
      // Clean up the refs when the component unmounts
      processedPalletUpdatesRef.current.clear();
      currentPalletsRef.current = [];
      currentOrdersRef.current = [];
      
      // Clear any pending reconnect timeout
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, []);

  const handleImmediateStorageZoneUpdate = (update) => {
    try {
      if (!update || typeof update !== 'object') {
        console.warn('Invalid immediate storage zone update received:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      // This handles the special update_storage_zone action sent directly from SSEEventHandler
      if (update.action === 'update_storage_zone') {
        const { palletId, zoneId, storageZone } = update;
        // Use storageZone if provided, otherwise fall back to zoneId
        const actualStorageZone = storageZone || zoneId;
        
        if (!palletId || !actualStorageZone) {
          console.warn('Invalid storage zone update data:', {
            update,
            timestamp: new Date().toISOString()
          });
          return;
        }
        
        console.log('Processing immediate storage zone update:', {
          palletId,
          storageZone: actualStorageZone,
          timestamp: new Date().toISOString()
        });
        
        // Update the pallet with the new storage zone
        setPallets(currentPallets => {
          // Check if we have the pallet in the current list
          if (!Array.isArray(currentPallets)) return currentPallets;
          
          // Find the pallet to update
          const palletIndex = currentPallets.findIndex(p => p.id === palletId);
          if (palletIndex === -1) {
            console.log(`Pallet ${palletId} not found for immediate storage zone update`, {
              timestamp: new Date().toISOString()
            });
            return currentPallets;
          }
          
          // Clone the pallets array and update the specific pallet
          const updatedPallets = [...currentPallets];
          updatedPallets[palletIndex] = {
            ...updatedPallets[palletIndex],
            storageZone: actualStorageZone
          };
          
          console.log(`Immediately updated storage zone for pallet ${palletId} to ${actualStorageZone}`, {
            timestamp: new Date().toISOString()
          });
          
          return updatedPallets;
        });
      }
    } catch (error) {
      console.error('Error handling immediate storage zone update:', {
        error: error.message,
        stack: error.stack,
        update,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handleStoragePositionUpdate = (update) => {
    try {
      if (!update || typeof update !== 'object') {
        console.warn('Invalid storage position update received:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      // Handle assign_to_zone action specifically
      if (update.action === 'assign_to_zone' && update.position) {
        const { palletId, zoneId } = update.position;
        
        if (!palletId || !zoneId) {
          console.warn('Invalid storage position data:', {
            position: update.position,
            timestamp: new Date().toISOString()
          });
          return;
        }
        
        console.log('Processing storage zone assignment:', {
          palletId,
          zoneId,
          timestamp: new Date().toISOString()
        });

        // Store the update in a ref for future pallets
        if (!processedPalletUpdatesRef.current.pendingStorageUpdates) {
          processedPalletUpdatesRef.current.pendingStorageUpdates = new Map();
        }
        processedPalletUpdatesRef.current.pendingStorageUpdates.set(palletId, {
          storageZone: zoneId,
          timestamp: Date.now()
        });
        
        // Update the pallet with the new storage zone
        setPallets(currentPallets => {
          // Check if we have the pallet in the current list
          if (!Array.isArray(currentPallets)) return currentPallets;
          
          // Find the pallet to update
          const palletIndex = currentPallets.findIndex(p => p.id === palletId);
          if (palletIndex === -1) {
            console.log(`Pallet ${palletId} not found yet, update stored for future processing`, {
              timestamp: new Date().toISOString()
            });
            return currentPallets;
          }
          
          // Clone the pallets array and update the specific pallet
          const updatedPallets = [...currentPallets];
          updatedPallets[palletIndex] = {
            ...updatedPallets[palletIndex],
            storageZone: zoneId
          };
          
          console.log(`Updated storage zone for pallet ${palletId} to ${zoneId}`, {
            timestamp: new Date().toISOString()
          });
          
          return updatedPallets;
        });

        // Clean up old pending updates (older than 5 minutes)
        const now = Date.now();
        const maxAge = 5 * 60 * 1000; // 5 minutes
        const pendingUpdates = processedPalletUpdatesRef.current.pendingStorageUpdates;
        
        if (pendingUpdates) {
          for (const [pid, update] of pendingUpdates.entries()) {
            if (now - update.timestamp > maxAge) {
              pendingUpdates.delete(pid);
              console.log('Cleaned up stale pending storage update:', {
                palletId: pid,
                age: `${Math.round((now - update.timestamp) / 1000)}s`,
                timestamp: new Date().toISOString()
              });
            }
          }
        }
      }
    } catch (error) {
      console.error('Error handling storage position update:', {
        error: error.message,
        stack: error.stack,
        update,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handlePalletUpdate = (update) => {
    try {
      if (!update || typeof update !== 'object') {
        console.warn('Invalid pallet update received - not an object:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      const { action, pallets } = update;
      
      if (!action) {
        console.warn('Invalid pallet update received - missing action:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      if (!Array.isArray(pallets) || pallets.length === 0) {
        console.warn('Invalid pallet update received - invalid pallets array:', {
          action,
          pallets,
          timestamp: new Date().toISOString()
        });
        return;
      }

      // Log the full update for debugging
      console.debug('Processing pallet update data:', {
        action,
        palletCount: pallets.length,
        firstPallet: pallets[0],
        timestamp: new Date().toISOString()
      });
      
      // Generate a unique update ID based on the action and pallet IDs
      const updateId = `${action}_${pallets.map(p => p.id).sort().join('_')}`;
      
      // Check if we've already processed this exact update
      if (processedPalletUpdatesRef.current.has(updateId)) {
        console.debug('Skipping already processed pallet update:', {
          action,
          palletCount: pallets.length,
          updateId,
          timestamp: new Date().toISOString()
        });
        return;
      }
      
      // Add this update to the processed set
      processedPalletUpdatesRef.current.add(updateId);
      
      // Limit the size of the processed set to prevent memory leaks
      if (processedPalletUpdatesRef.current.size > 1000) {
        // Convert to array, remove oldest entries, convert back to set
        const processedArray = Array.from(processedPalletUpdatesRef.current);
        processedPalletUpdatesRef.current = new Set(processedArray.slice(-500));
      }
      
      console.log('Processing pallet update:', {
        action,
        palletCount: pallets.length,
        palletIds: pallets.map(p => p.id),
        timestamp: new Date().toISOString()
      });

      setPallets(currentPallets => {
        // Update the ref with the current state
        currentPalletsRef.current = currentPallets;
        
        if (!Array.isArray(currentPallets)) {
          console.warn('Current pallets is not an array:', {
            currentPallets,
            timestamp: new Date().toISOString()
          });
          return currentPallets;
        }

        switch (action) {
          case 'add': {
            // Create a map of existing pallets by ID for quick lookup
            const existingPalletsMap = new Map(currentPallets.map(p => [p.id, p]));
            
            // Filter out pallets that already exist and preserve storage zone info
            let newPallets = pallets.filter(p => !existingPalletsMap.has(p.id));
            
            if (newPallets.length === 0) {
              console.debug('No new pallets to add, all already exist:', {
                attemptedCount: pallets.length,
                timestamp: new Date().toISOString()
              });
              return currentPallets;
            }
            
            // Check for any pending storage updates
            const pendingStorageUpdates = processedPalletUpdatesRef.current.pendingStorageUpdates;
            
            // Preserve any existing storage zone information and apply pending updates
            newPallets = newPallets.map(pallet => {
              // First check for pending storage updates
              if (pendingStorageUpdates && pendingStorageUpdates.has(pallet.id)) {
                const pendingUpdate = pendingStorageUpdates.get(pallet.id);
                console.log(`Applying pending storage zone ${pendingUpdate.storageZone} for pallet ${pallet.id}`, {
                  timestamp: new Date().toISOString()
                });
                pendingStorageUpdates.delete(pallet.id); // Clean up after applying
                return { ...pallet, storageZone: pendingUpdate.storageZone };
              }
              
              // Then check for storageZone in the incoming pallet data
              if (pallet.storageZone) {
                console.log(`Preserving storage zone ${pallet.storageZone} for pallet ${pallet.id}`, {
                  timestamp: new Date().toISOString()
                });
              }
              
              // Finally check if there's an existing pallet with this ID that has storage zone info
              const existingPallet = existingPalletsMap.get(pallet.id);
              if (existingPallet?.storageZone && !pallet.storageZone) {
                console.log(`Keeping existing storage zone ${existingPallet.storageZone} for pallet ${pallet.id}`, {
                  timestamp: new Date().toISOString()
                });
                return { ...pallet, storageZone: existingPallet.storageZone };
              }
              
              return pallet;
            });
            
            console.log('Adding new pallets:', {
              newCount: newPallets.length,
              attemptedCount: pallets.length,
              withStorageZone: newPallets.filter(p => p.storageZone).length,
              timestamp: new Date().toISOString()
            });
            
            // Add new pallets at the beginning since they're newer
            return [...newPallets, ...currentPallets];
          }
          
          case 'update': {
            // Create a map of pallets to update by ID for quick lookup
            const updatePalletsMap = new Map(pallets.map(p => [p.id, p]));
            const palletIds = new Set(pallets.map(p => p.id));
            
            // Check if any of the pallets actually need updating
            const needsUpdate = currentPallets.some(p => 
              palletIds.has(p.id) && 
              JSON.stringify(p) !== JSON.stringify(updatePalletsMap.get(p.id))
            );
            
            if (!needsUpdate) {
              console.debug('No pallets need updating, all are identical:', {
                updateCount: pallets.length,
                timestamp: new Date().toISOString()
              });
              return currentPallets;
            }
            
            console.log('Updating pallets:', {
              updateCount: pallets.length,
              palletIds: Array.from(palletIds),
              timestamp: new Date().toISOString()
            });
            
            // Update existing pallets while preserving storage zone info
            return currentPallets.map(p => {
              if (!palletIds.has(p.id)) return p;
              
              const updatedPallet = updatePalletsMap.get(p.id);
              // Preserve existing storage zone if not provided in update
              if (p.storageZone && !updatedPallet.storageZone) {
                return { ...updatedPallet, storageZone: p.storageZone };
              }
              return updatedPallet;
            });
          }
          
          case 'delete': {
            const deleteIds = new Set(pallets.map(p => p.id));
            
            // Check if any of the pallets actually exist
            const palletsToDelete = currentPallets.filter(p => deleteIds.has(p.id));
            
            if (palletsToDelete.length === 0) {
              console.debug('No pallets to delete, none exist:', {
                attemptedCount: pallets.length,
                timestamp: new Date().toISOString()
              });
              return currentPallets;
            }
            
            console.log('Deleting pallets:', {
              deleteCount: palletsToDelete.length,
              attemptedCount: pallets.length,
              palletIds: Array.from(deleteIds),
              timestamp: new Date().toISOString()
            });
            
            // Remove deleted pallets
            return currentPallets.filter(p => !deleteIds.has(p.id));
          }
          
          default:
            console.warn('Unknown pallet update action:', {
              action,
              timestamp: new Date().toISOString()
            });
            return currentPallets;
        }
      });
    } catch (error) {
      console.error('Error handling pallet update:', {
        error: error.message,
        stack: error.stack,
        update,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handleOrderUpdate = (update) => {
    try {
      if (!update || typeof update !== 'object') {
        console.warn('Invalid order update received - not an object:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      const { action, orders } = update;
      
      if (!action) {
        console.warn('Invalid order update received - missing action:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      if (!Array.isArray(orders) || orders.length === 0) {
        console.warn('Invalid order update received - invalid orders array:', {
          action,
          orders,
          timestamp: new Date().toISOString()
        });
        return;
      }

      // Log the full update for debugging
      console.debug('Processing order update data:', {
        action,
        orderCount: orders.length,
        firstOrder: orders[0],
        timestamp: new Date().toISOString()
      });
      
      console.log('Processing order update:', {
        action,
        orderCount: orders.length,
        orderIds: orders.map(o => o.id),
        timestamp: new Date().toISOString()
      });

      setOrders(currentOrders => {
        // Update the ref with the current state
        currentOrdersRef.current = currentOrders;
        
        if (!Array.isArray(currentOrders)) {
          console.warn('Current orders is not an array:', {
            currentOrders,
            timestamp: new Date().toISOString()
          });
          return currentOrders;
        }

        switch (action) {
          case 'add': {
            // Create a map of existing orders by ID for quick lookup
            const existingOrdersMap = new Map(currentOrders.map(o => [o.id, o]));
            
            // Filter out orders that already exist
            const newOrders = orders.filter(o => !existingOrdersMap.has(o.id));
            
            if (newOrders.length === 0) {
              console.debug('No new orders to add, all already exist:', {
                attemptedCount: orders.length,
                timestamp: new Date().toISOString()
              });
              return currentOrders;
            }
            
            console.log('Adding new orders:', {
              newCount: newOrders.length,
              attemptedCount: orders.length,
              timestamp: new Date().toISOString()
            });
            
            return [...newOrders, ...currentOrders];
          }
          
          case 'update': {
            // Create a map of orders to update by ID for quick lookup
            const updateOrdersMap = new Map(orders.map(o => [o.id, o]));
            const orderIds = new Set(orders.map(o => o.id));
            
            // Check if any of the orders actually need updating
            const needsUpdate = currentOrders.some(o => 
              orderIds.has(o.id) && 
              JSON.stringify(o) !== JSON.stringify(updateOrdersMap.get(o.id))
            );
            
            if (!needsUpdate) {
              console.debug('No orders need updating, all are identical:', {
                updateCount: orders.length,
                timestamp: new Date().toISOString()
              });
              return currentOrders;
            }
            
            console.log('Updating orders:', {
              updateCount: orders.length,
              orderIds: Array.from(orderIds),
              timestamp: new Date().toISOString()
            });
            
            return currentOrders.map(o => 
              orderIds.has(o.id) ? updateOrdersMap.get(o.id) : o
            );
          }
          
          case 'delete': {
            const deleteIds = new Set(orders.map(o => o.id));
            
            // Check if any of the orders actually exist
            const ordersToDelete = currentOrders.filter(o => deleteIds.has(o.id));
            
            if (ordersToDelete.length === 0) {
              console.debug('No orders to delete, none exist:', {
                attemptedCount: orders.length,
                timestamp: new Date().toISOString()
              });
              return currentOrders;
            }
            
            console.log('Deleting orders:', {
              deleteCount: ordersToDelete.length,
              attemptedCount: orders.length,
              orderIds: Array.from(deleteIds),
              timestamp: new Date().toISOString()
            });
            
            return currentOrders.filter(o => !deleteIds.has(o.id));
          }
          
          default:
            console.warn('Unknown order update action:', {
              action,
              timestamp: new Date().toISOString()
            });
            return currentOrders;
        }
      });
    } catch (error) {
      console.error('Error handling order update:', {
        error: error.message,
        stack: error.stack,
        update,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handleAssignmentUpdate = (update) => {
    try {
      if (!update || typeof update !== 'object') {
        console.warn('Invalid assignment update received - not an object:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      const { palletIds, orderId } = update;
      
      if (!orderId) {
        console.warn('Invalid assignment update received - missing orderId:', {
          update,
          timestamp: new Date().toISOString()
        });
        return;
      }

      if (!Array.isArray(palletIds) || palletIds.length === 0) {
        console.warn('Invalid assignment update received - invalid palletIds array:', {
          palletIds,
          orderId,
          timestamp: new Date().toISOString()
        });
        return;
      }

      // Log the full update for debugging
      console.debug('Processing assignment update data:', {
        palletCount: palletIds.length,
        palletIds,
        orderId,
        timestamp: new Date().toISOString()
      });
      
      console.log('Processing assignment update:', {
        palletCount: palletIds.length,
        palletIds,
        orderId,
        timestamp: new Date().toISOString()
      });
      
      // Update both pallets and orders atomically
      setPallets(currentPallets => {
        // Update the ref with the current state
        currentPalletsRef.current = currentPallets;
        
        if (!Array.isArray(currentPallets)) {
          console.warn('Current pallets is not an array:', {
            currentPallets,
            timestamp: new Date().toISOString()
          });
          return currentPallets;
        }
        
        const updateIds = new Set(palletIds);
        
        // Check if any of the pallets actually need updating
        const palletsToUpdate = currentPallets.filter(p => 
          updateIds.has(p.id) && p.orderId !== orderId
        );
        
        if (palletsToUpdate.length === 0) {
          console.debug('No pallets need assignment update, all already assigned:', {
            attemptedCount: palletIds.length,
            timestamp: new Date().toISOString()
          });
          return currentPallets;
        }
        
        console.log('Updating pallet assignments:', {
          updateCount: palletsToUpdate.length,
          attemptedCount: palletIds.length,
          orderId,
          timestamp: new Date().toISOString()
        });
        
        return currentPallets.map(p => 
          updateIds.has(p.id) ? { ...p, orderId } : p
        );
      });

      setOrders(currentOrders => {
        // Update the ref with the current state
        currentOrdersRef.current = currentOrders;
        
        if (!Array.isArray(currentOrders)) {
          console.warn('Current orders is not an array:', {
            currentOrders,
            timestamp: new Date().toISOString()
          });
          return currentOrders;
        }
        
        // Find the order to update
        const orderToUpdate = currentOrders.find(o => o.id === orderId);
        
        if (!orderToUpdate) {
          console.warn('Order not found for assignment update:', {
            orderId,
            timestamp: new Date().toISOString()
          });
          return currentOrders;
        }
        
        // Get existing pallet IDs
        const existingPalletIds = Array.isArray(orderToUpdate.palletIds) ? 
          orderToUpdate.palletIds : [];
        
        // Filter out pallet IDs that are already assigned to this order
        const newPalletIds = palletIds.filter(id => !existingPalletIds.includes(id));
        
        if (newPalletIds.length === 0) {
          console.debug('No new pallets to assign to order, all already assigned:', {
            orderId,
            attemptedCount: palletIds.length,
            timestamp: new Date().toISOString()
          });
          return currentOrders;
        }
        
        console.log('Assigning new pallets to order:', {
          orderId,
          newCount: newPalletIds.length,
          attemptedCount: palletIds.length,
          timestamp: new Date().toISOString()
        });
        
        return currentOrders.map(o => {
          if (o.id === orderId) {
            return {
              ...o,
              palletIds: [...existingPalletIds, ...newPalletIds],
              assignedPallets: (typeof o.assignedPallets === 'number' ? o.assignedPallets : 0) + newPalletIds.length
            };
          }
          return o;
        });
      });
    } catch (error) {
      console.error('Error handling assignment update:', {
        error: error.message,
        stack: error.stack,
        update,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handleConnectionStateChange = (state) => {
    try {
      const { connectionState: newState, previousState } = state;
      
      console.log('Connection state changed:', {
        oldState: connectionState,
        newState,
        previousState,
        timestamp: new Date().toISOString()
      });

      // Always update the state to reflect the current connection status
      setConnectionState(newState);
      
      switch (newState) {
        case 'ERROR':
          setError('Failed to maintain connection');
          // Schedule reconnection attempt
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            console.log('Attempting reconnection after error...', {
              timestamp: new Date().toISOString()
            });
            connect();
          }, 5000);
          break;
          
        case 'CONNECTED':
          // Clear any error state when successfully connected
          setError(null);
          console.log('SSE connection established and healthy', {
            timestamp: new Date().toISOString()
          });
          break;
          
        case 'CONNECTING':
          // Clear any previous error when attempting to connect
          setError(null);
          break;
          
        case 'DISCONNECTED':
          // Only set error if we didn't intentionally disconnect
          if (previousState === 'CONNECTING' || previousState === 'CONNECTED') {
            setError('Connection lost. Will attempt to reconnect automatically.');
          }
          break;
      }
    } catch (error) {
      console.error('Error handling connection state change:', {
        error: error.message,
        stack: error.stack,
        state,
        timestamp: new Date().toISOString()
      });
    }
  };

  const setupEventHandlers = (client) => {
    try {
      if (!client || typeof client.subscribe !== 'function') {
        throw new Error('Invalid SSE client');
      }

      console.log('Setting up event handlers:', {
        timestamp: new Date().toISOString()
      });
      
      // Clean up any existing subscriptions
      if (sseUnsubscribersRef.current) {
        sseUnsubscribersRef.current.forEach(unsubscribe => unsubscribe());
        sseUnsubscribersRef.current = [];
      }
      
      // Store unsubscribe functions
      const unsubscribers = [
        client.subscribe('PALLET_UPDATE', handlePalletUpdate),
        client.subscribe('ORDER_UPDATE', handleOrderUpdate),
        client.subscribe('ASSIGNMENT_UPDATE', handleAssignmentUpdate),
        client.subscribe('STORAGE_POSITION_UPDATE', handleStoragePositionUpdate),
        client.subscribe('PALLET_UPDATED', handleImmediateStorageZoneUpdate)
      ];

      // Store unsubscribe functions in ref for cleanup
      sseUnsubscribersRef.current = unsubscribers;
      
      // Set up state change handler
      client.setStateChangeHandler(handleConnectionStateChange);
      
      console.log('Event handlers set up successfully:', {
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error setting up event handlers:', {
        error: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString()
      });
      throw error; // Re-throw to be handled by caller
    }
  };

  // Helper function to validate connection prerequisites
  const validateConnectionPrerequisites = () => {
    if (!user) {
      console.warn('Cannot connect: User authentication required', {
        timestamp: new Date().toISOString()
      });
      return 'Cannot connect: User authentication required';
    }

    if (!selectedLocation) {
      console.warn('Cannot connect: Please select a location', {
        timestamp: new Date().toISOString()
      });
      return 'Cannot connect: Please select a location';
    }

    if (!selectedLocation.id) {
      console.error('Cannot connect: Selected location is missing an ID', {
        selectedLocation,
        timestamp: new Date().toISOString()
      });
      return 'Cannot connect: Selected location is missing an ID';
    }

    return null;
  };

  const connect = useCallback(() => {
    // Clear any pending reconnect timeout
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    // Validate prerequisites
    const validationError = validateConnectionPrerequisites();
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      console.log('Getting SSE client from manager:', {
        locationId: selectedLocation.id,
        locationName: selectedLocation.name || 'Unknown',
        timestamp: new Date().toISOString()
      });

      // Get or initialize SSE client through the manager
      const sseManager = SSEManager.getInstance();
      const client = sseManager.initialize(selectedLocation.id, {
        baseURL: getSseUrl(selectedLocation.id)
      });
      
      // Set up event handlers
      setupEventHandlers(client);
      
      // Store client reference
      sseClientRef.current = client;

      console.log('Connecting to SSE:', {
        locationId: selectedLocation.id,
        timestamp: new Date().toISOString()
      });
      
      // Connect to SSE
      client.connect();

    } catch (error) {
      console.error('Error setting up SSE client:', {
        error: error.message,
        stack: error.stack,
        locationId: selectedLocation?.id,
        timestamp: new Date().toISOString()
      });
      
      setError('Failed to establish connection');
      
      // Schedule reconnection attempt
      reconnectTimeoutRef.current = setTimeout(() => {
        console.log('Attempting reconnection after setup error...', {
          timestamp: new Date().toISOString()
        });
        connect();
      }, 5000);
    }
  }, [selectedLocation, user]);

  const disconnect = useCallback(() => {
    // Clear any pending reconnect timeout
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }

    if (sseClientRef.current) {
      console.log('Disconnecting SSE client:', {
        timestamp: new Date().toISOString()
      });
      
      // Clean up subscriptions
      if (sseUnsubscribersRef.current) {
        sseUnsubscribersRef.current.forEach(unsubscribe => unsubscribe());
        sseUnsubscribersRef.current = [];
      }
      
      // Get the manager instance and clean up
      const sseManager = SSEManager.getInstance();
      sseManager.cleanup();
      sseClientRef.current = null;
    }
    
    setConnectionState('DISCONNECTED');
    setError(null);
    
    console.log('SSE client disconnected:', {
      timestamp: new Date().toISOString()
    });
  }, []);

  useEffect(() => {
    const initializeConnection = async () => {
      console.log('Initializing SSE connection:', {
        hasUser: !!user,
        hasSelectedLocation: !!selectedLocation,
        timestamp: new Date().toISOString()
      });
      
      // Ensure previous connection is closed
      disconnect();
      
      if (user && selectedLocation) {
        connect();
      }
    };

    initializeConnection();

    return () => {
      disconnect();
    };
  }, [user, selectedLocation, connect, disconnect]);

  // Handle visibility changes - only monitor visibility without disconnecting
  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisible = !document.hidden;
      console.log('Page visibility changed:', {
        isVisible,
        timestamp: new Date().toISOString()
      });
      setIsPageVisible(isVisible);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <UpdateContext.Provider 
      value={{ 
        isConnected: connectionState === 'CONNECTED',
        connectionState,
        error,
        isPageVisible
      }}
    >
      <ConnectionStatus status={connectionState} error={error} />
      {children}
    </UpdateContext.Provider>
  );
};

export const useUpdate = () => {
  const context = useContext(UpdateContext);
  if (context === undefined) {
    throw new Error('useUpdate must be used within an UpdateProvider');
  }
  return context;
};

/**
 * SSEHealthMonitor.js
 * Monitors SSE connection health and manages heartbeat checks
 */

class SSEHealthMonitor {
  constructor(options = {}) {
    // Health check configuration
    this.heartbeatInterval = options.heartbeatInterval || 30000; // 30s
    this.healthCheckTimer = options.healthCheckTimer || 15000; // 15s
    this.maxHeartbeatMisses = options.maxHeartbeatMisses || 2;
    this.staleThreshold = options.staleThreshold || 60000; // 60s
    
    // Internal state
    this.lastHeartbeatTime = null;
    this.lastEventTime = null;
    this.healthCheckInterval = null;
    this.isMonitoring = false;
    this.onConnectionStale = null;
    this.clientId = options.clientId || null;
    this.consecutiveMisses = 0;
  }

  /**
   * Starts health monitoring
   * @param {Function} onStale - Callback when connection becomes stale
   */
  startMonitoring(onStale) {
    if (this.isMonitoring) {
      this.stopMonitoring();
    }

    this.onConnectionStale = onStale;
    this.lastHeartbeatTime = Date.now();
    this.lastEventTime = Date.now();
    this.isMonitoring = true;
    this.consecutiveMisses = 0;

    // Set up periodic health check with bounded iterations
    this.healthCheckInterval = setInterval(() => {
      this.checkHealth();
    }, this.healthCheckTimer);

    console.log('Health monitoring started:', {
      heartbeatInterval: `${this.heartbeatInterval}ms`,
      checkInterval: `${this.healthCheckTimer}ms`,
      maxMisses: this.maxHeartbeatMisses,
      staleThreshold: `${this.staleThreshold}ms`,
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Stops health monitoring
   */
  stopMonitoring() {
    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
      this.healthCheckInterval = null;
    }
    
    this.isMonitoring = false;
    this.lastHeartbeatTime = null;
    this.lastEventTime = null;
    this.onConnectionStale = null;
    this.consecutiveMisses = 0;

    console.log('Health monitoring stopped:', {
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Records a heartbeat event
   */
  recordHeartbeat() {
    const now = Date.now();
    this.lastHeartbeatTime = now;
    this.lastEventTime = now;
    this.consecutiveMisses = 0; // Reset consecutive misses on successful heartbeat

    console.debug('Heartbeat recorded:', {
      clientId: this.clientId,
      consecutiveMisses: this.consecutiveMisses,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Records any event activity
   */
  recordActivity() {
    this.lastEventTime = Date.now();
  }

  /**
   * Checks connection health with bounded conditions
   * @private
   */
  checkHealth() {
    if (!this.isMonitoring || !this.lastHeartbeatTime) {
      return;
    }

    const now = Date.now();
    const timeSinceLastHeartbeat = now - this.lastHeartbeatTime;
    const timeSinceLastEvent = now - this.lastEventTime;
    const missedHeartbeats = Math.floor(timeSinceLastHeartbeat / this.heartbeatInterval);

    // Check for any recent activity first
    if (timeSinceLastEvent < this.heartbeatInterval) {
      // If we've had recent activity, consider the connection healthy
      this.consecutiveMisses = 0;
      return;
    }

    // Log warning for missed heartbeats
    if (missedHeartbeats > 0) {
      this.consecutiveMisses++;
      console.warn('Missed heartbeats detected:', {
        missedCount: missedHeartbeats,
        consecutiveMisses: this.consecutiveMisses,
        timeSinceLastHeartbeat: `${Math.round(timeSinceLastHeartbeat / 1000)}s`,
        timeSinceLastEvent: `${Math.round(timeSinceLastEvent / 1000)}s`,
        expectedInterval: `${Math.round(this.heartbeatInterval / 1000)}s`,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }

    // Check if connection is stale based on multiple criteria
    const isStale = this.consecutiveMisses >= this.maxHeartbeatMisses || 
                    timeSinceLastEvent >= this.staleThreshold;

    if (isStale) {
      console.warn('Connection appears to be stale:', {
        reason: this.consecutiveMisses >= this.maxHeartbeatMisses ? 'missed_heartbeats' : 'no_activity',
        consecutiveMisses: this.consecutiveMisses,
        timeSinceLastHeartbeat: `${Math.round(timeSinceLastHeartbeat / 1000)}s`,
        timeSinceLastEvent: `${Math.round(timeSinceLastEvent / 1000)}s`,
        maxAllowedMisses: this.maxHeartbeatMisses,
        staleThreshold: `${Math.round(this.staleThreshold / 1000)}s`,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });

      // Notify about stale connection
      if (this.onConnectionStale) {
        try {
          this.onConnectionStale();
        } catch (error) {
          console.error('Error in stale connection handler:', {
            error: error.message,
            stack: error.stack,
            clientId: this.clientId,
            timestamp: new Date().toISOString()
          });
        }
      }

      // Stop monitoring after detecting stale connection
      this.stopMonitoring();
    }
  }

  /**
   * Checks if monitoring is active
   * @returns {boolean} True if monitoring is active
   */
  isActive() {
    return this.isMonitoring;
  }

  /**
   * Gets time since last heartbeat
   * @returns {number|null} Milliseconds since last heartbeat, or null if no heartbeat recorded
   */
  getTimeSinceLastHeartbeat() {
    if (!this.lastHeartbeatTime) {
      return null;
    }
    return Date.now() - this.lastHeartbeatTime;
  }

  /**
   * Gets time since last activity
   * @returns {number|null} Milliseconds since last activity, or null if no activity recorded
   */
  getTimeSinceLastActivity() {
    if (!this.lastEventTime) {
      return null;
    }
    return Date.now() - this.lastEventTime;
  }

  /**
   * Checks if the connection appears healthy
   * @returns {boolean} True if connection appears healthy
   */
  isHealthy() {
    if (!this.isMonitoring || !this.lastHeartbeatTime || !this.lastEventTime) {
      return false;
    }

    const timeSinceLastEvent = Date.now() - this.lastEventTime;
    return timeSinceLastEvent < this.staleThreshold && this.consecutiveMisses < this.maxHeartbeatMisses;
  }
}

export default SSEHealthMonitor;

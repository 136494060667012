import React, { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { Edit, X, ArrowLeft, Scissors, Truck, MoreHorizontal, Map } from 'lucide-react';
import { parseDate } from '@lib/utils/dateUtils';
import { Table, TableContainer, TableHeader, TableRow, TableCell } from '@lib/components/Table';
import { getBadgeStatus, getDisplayName } from '@features/shared/constants/processStages';
import { Badge, ActionButton } from '../MainDashboard/styles';
import QRCode from '@lib/components/ui/QRCode';
import QRCodeModal from '@lib/components/ui/QRCodeModal';
// Helper function to format storage zone display - no parsing needed
const formatStorageZone = (storageZone) => {
  if (!storageZone) return null;
  
  // Direct display of the storage zone with simple text replacement
  // This eliminates the need for complex parsing in this component
  return storageZone.replace(/-row-(\d+)$/, (match, rowNum) => 
    ` - Row ${parseInt(rowNum, 10) + 1}`); // Add 1 to make it human-readable (1-based)
};

const DashboardTables = ({
  pallets,
  selectedPallets,
  selectAllChecked,
  onSelectAll,
  onSelectPallet,
  onSort,
  onShip,
  onEdit,
  onDelete,
  onReturn,
  onSplit,
  onAssignOrder,
  onShowInfo,
  onChangeStorageLocation,
}) => {
  const [selectedQRPallet, setSelectedQRPallet] = useState(null);
  const [sortState, setSortState] = useState({ field: null, direction: 'asc' });
  const tableRef = useRef(null);

  // Handle scroll for sticky header shadow
  useEffect(() => {
    const handleScroll = () => {
      const table = tableRef.current?.querySelector('table');
      if (table) {
        if (tableRef.current.scrollTop > 0) {
          table.classList.add('is-scrolled');
        } else {
          table.classList.remove('is-scrolled');
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
      return () => tableContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Handle sort
  const handleSort = (field) => {
    setSortState(prevState => ({
      field,
      direction: prevState.field === field && prevState.direction === 'asc' ? 'desc' : 'asc'
    }));
    onSort(field);
  };

  return (
    <>
      <TableContainer ref={tableRef} maxHeight="calc(100vh - 300px)">
        <Table>
          <thead>
            <tr>
              <TableHeader className="checkbox-header">
                <TableCell
                  isCheckbox
                  checked={selectAllChecked}
                  onChange={onSelectAll}
                  data-testid="select-all-checkbox"
                />
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('tomatoType')}
                active={sortState.field === 'tomatoType'}
                sortDirection={sortState.direction}
              >
                Type/Option
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('boxType')}
                active={sortState.field === 'boxType'}
                sortDirection={sortState.direction}
              >
                Box/Pallet
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('processStage')}
                active={sortState.field === 'processStage'}
                sortDirection={sortState.direction}
              >
                Process Stage
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('sortingGrade')}
                active={sortState.field === 'sortingGrade'}
                sortDirection={sortState.direction}
              >
                Sorting Grade
              </TableHeader>
              <TableHeader>QR Code</TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('colliCount')}
                active={sortState.field === 'colliCount'}
                sortDirection={sortState.direction}
              >
                Boxes
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('weight')}
                active={sortState.field === 'weight'}
                sortDirection={sortState.direction}
              >
                Weight
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('status')}
                active={sortState.field === 'status'}
                sortDirection={sortState.direction}
              >
                Status
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('storageZone')}
                active={sortState.field === 'storageZone'}
                sortDirection={sortState.direction}
              >
                Storage Zone
              </TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('orderCustomer')}
                active={sortState.field === 'orderCustomer'}
                sortDirection={sortState.direction}
              >
                Order
              </TableHeader>
              <TableHeader>Assign to Order</TableHeader>
              <TableHeader 
                sortable 
                onClick={() => handleSort('addedDate')}
                active={sortState.field === 'addedDate'}
                sortDirection={sortState.direction}
              >
                Added
              </TableHeader>
              <TableHeader>Actions</TableHeader>
            </tr>
          </thead>
          <tbody>
            {pallets.map((pallet, index) => (
              <TableRow
                key={pallet.id}
                index={index}
                selected={selectedPallets.has(pallet.id)}
                disabled={pallet.status === 'shipped'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onSelectPallet(pallet.id);
                  }
                }}
              >
                <TableCell
                  isCheckbox
                  checked={selectedPallets.has(pallet.id)}
                  onChange={() => onSelectPallet(pallet.id)}
                  disabled={pallet.status === 'shipped'}
                />
                <TableCell
                  primary={pallet.tomatoType}
                  secondary={pallet.tomatoOption}
                />
                <TableCell
                  primary={pallet.boxType}
                  secondary={pallet.palletType}
                />
                <TableCell>
                  <Badge status={getBadgeStatus(pallet.processStage, 'process')}>
                    {getDisplayName(pallet.processStage, 'process')
                      ? getDisplayName(pallet.processStage, 'process')
                      : 'Unprocessed'}
                  </Badge>
                </TableCell>
                <TableCell>
                  <Badge status={getBadgeStatus(pallet.sortingGrade?.toLowerCase(), 'sorting')}>
                    {pallet.sortingGrade
                      ? getDisplayName(pallet.sortingGrade.toLowerCase(), 'sorting')
                      : '-'}
                  </Badge>
                </TableCell>
                <TableCell className="qr-cell">
                  {pallet.qrCode && (
                    <QRCode
                      data={pallet.qrCode}
                      size={40}
                      onClick={() => setSelectedQRPallet(pallet)}
                    />
                  )}
                </TableCell>
                <TableCell primary={pallet.colliCount ? `${pallet.colliCount} boxes` : '-'} />
                <TableCell primary={pallet.weight ? `${parseFloat(pallet.weight).toFixed(2)}kg` : '-'} />
                <TableCell>
                  <Badge status={pallet.status}>
                    {pallet.status?.charAt(0).toUpperCase() + pallet.status?.slice(1)}
                  </Badge>
                </TableCell>
                <TableCell>
                  {pallet.storageZone ? (
                    <Badge status="success">
                      {formatStorageZone(pallet.storageZone)}
                    </Badge>
                  ) : (
                    <span className="text-gray-400">Not assigned</span>
                  )}
                </TableCell>
                <TableCell>
                  {pallet.customerName ? (
                    <div className="cell-content" style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <Badge status={pallet.status === 'shipped' ? 'shipped' : 'assigned'}>
                        {pallet.customerName}
                      </Badge>
                    
                    </div>
                  ) : (
                    <span className="text-gray-400">Not assigned</span>
                  )}
                </TableCell>
                <TableCell>
                  <button
                    onClick={() => onAssignOrder([pallet.id])}
                    className="text-blue-600 hover:text-blue-900"
                    disabled={pallet.status === 'shipped'}
                  >
                    Assign to Order
                  </button>
                </TableCell>
                <TableCell
                  primary={
                    pallet.addedDate 
                      ? format(parseDate(pallet.addedDate) || new Date(), 'MMM d, yyyy')
                      : '-'
                  }
                  secondary={
                    <>
                      Added by: {pallet.userName || 'Unknown'}
                      {pallet.lastEditor && pallet.lastEditor !== pallet.userName && (
                        <div>Edited by: {pallet.lastEditor}</div>
                      )}
                      {pallet.status === 'shipped' && pallet.shippedDate && (
                        <div>
                          Shipped: {format(parseDate(pallet.shippedDate) || new Date(), 'MMM d, yyyy')}
                          <br />
                          By: {pallet.shippedBy}
                        </div>
                      )}
                    </>
                  }
                />
                <TableCell className="actions-cell">
                  <div className="flex justify-end">
                    <ActionButton 
                      variant="info" 
                      onClick={() => onShowInfo(pallet)}
                    >
                      <MoreHorizontal size={16} />
                    </ActionButton>
                    {pallet.status !== 'shipped' && (
                      <>
                        <ActionButton variant="edit" onClick={() => onEdit(pallet)}>
                          <Edit size={16} />
                        </ActionButton>
                        {pallet.orderId && (
                          <ActionButton variant="return" onClick={() => onReturn(pallet)}>
                            <ArrowLeft size={16} />
                          </ActionButton>
                        )}
                        {parseInt(pallet.colliCount) > 0 && (
                          <ActionButton variant="split" onClick={() => onSplit(pallet)}>
                            <Scissors size={16} />
                          </ActionButton>
                        )}
                        {onChangeStorageLocation && (
                          <ActionButton variant="primary" onClick={() => onChangeStorageLocation(pallet.id)}>
                            <Map size={16} />
                          </ActionButton>
                        )}
                        <ActionButton variant="ship" onClick={() => onShip(pallet.id)}>
                          <Truck size={16} />
                        </ActionButton>
                        <ActionButton variant="delete" onClick={() => onDelete(pallet.id)}>
                          <X size={16} />
                        </ActionButton>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>

      <QRCodeModal
        isOpen={!!selectedQRPallet}
        onClose={() => setSelectedQRPallet(null)}
        pallet={selectedQRPallet}
      />
    </>
  );
};

export default DashboardTables;

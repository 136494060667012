import { useState, useEffect } from 'react';
import { api } from '@lib/api';
import { useToast } from '@lib/contexts';

const useAdminDashboard = () => {
  const { showToast } = useToast();
  
  // Data states
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Selection states
  const [selectedUser, setSelectedUser] = useState(null);
  const [userRestrictions, setUserRestrictions] = useState([]);
  
  // Modal states
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  
  // Form data states
  const [newUserData, setNewUserData] = useState({
    username: '',
    role: 'user',
    companyId: '',
    accessAllLocations: false,
    desktop_access: false,
    mobile_access: false
  });
  
  // Load companies and locations data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Load companies
        const companiesResponse = await api.get('/api/companies');
        setCompanies(Array.isArray(companiesResponse.data) ? companiesResponse.data : []);
        
        // Load locations for company ID 1
        const locationsResponse = await api.get('/api/locations/company/1');
        console.log('Locations response:', locationsResponse);
        
        // Handle nested data structure
        if (locationsResponse.data && locationsResponse.data.data) {
          setLocations(Array.isArray(locationsResponse.data.data) ? locationsResponse.data.data : []);
        } else {
          setLocations(Array.isArray(locationsResponse.data) ? locationsResponse.data : []);
        }
      } catch (error) {
        console.error('Error loading initial data:', error);
        setCompanies([]);
        setLocations([]);
      }
    };
    
    fetchData();
  }, []);

  // Load users data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersResponse = await api.get('/api/users');
        console.log('Users API response:', usersResponse);
        
        // The API returns a structure like { success: true, message: "...", data: [...] }
        // So we need to check if the response data has a data property
        if (usersResponse.data && usersResponse.data.data) {
          // This is the correct structure - data is nested
          setUsers(Array.isArray(usersResponse.data.data) ? usersResponse.data.data : []);
          console.log('Setting users from nested data:', usersResponse.data.data);
        } else {
          // Fallback to original logic
          setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);
          console.log('Setting users from direct data:', usersResponse.data);
        }
      } catch (error) {
        console.error('API Error details:', error.response || error);
        console.error('Error loading users:', error);
        showToast({
          message: `Error loading users: ${error.message}`,
          variant: 'error'
        });
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [showToast]);
  
  const loadUserRestrictions = async (userId) => {
    try {
      const response = await api.get(`/api/permissions/users/${userId}/restrictions`);
      console.log('User restrictions response:', response);
      
      // Handle nested data
      if (response.data && response.data.data) {
        setUserRestrictions(Array.isArray(response.data.data) ? response.data.data : []);
      } else {
        setUserRestrictions(Array.isArray(response.data) ? response.data : []);
      }
    } catch (error) {
      console.error('Error loading user restrictions:', error);
      showToast({
        message: `Error loading user restrictions: ${error.message}`,
        variant: 'error'
      });
      setUserRestrictions([]);
    }
  };
  
  const handleSelectUser = (user) => {
    setSelectedUser(user);
    loadUserRestrictions(user.id);
  };
  
  const handleCreateUser = async (userData = newUserData) => {
    try {
      if (!userData.username) {
        showToast({
          message: 'Username is required',
          variant: 'error'
        });
        return;
      }
      
      // Make a copy of userData to send to the API
      const userDataToSend = {
        ...userData,
        companyId: 1, // Always use company ID 1
        is_admin: userData.role === 'admin' // Set admin flag based on role
      };
      
      console.log('Creating user with data:', userDataToSend);
      
      // Create the user
      const createUserResponse = await api.post('/api/users/admin/create', userDataToSend);
      console.log('User created:', createUserResponse);
      
      // Get the new user ID
      let newUserId;
      
      if (createUserResponse.data && createUserResponse.data.data && createUserResponse.data.data.id) {
        newUserId = createUserResponse.data.data.id;
      } else if (createUserResponse.data && createUserResponse.data.id) {
        newUserId = createUserResponse.data.id;
      }
      
      console.log('New user ID:', newUserId);
      
      // If user doesn't have access to all locations and we have selected location IDs,
      // assign those locations to the user
      if (!userData.accessAllLocations && 
          userData.selectedLocationIds && 
          Array.isArray(userData.selectedLocationIds) &&
          userData.selectedLocationIds.length > 0 &&
          newUserId) {
        
        console.log(`Assigning locations to user ${newUserId}:`, userData.selectedLocationIds);
        
        // Assign each selected location to the user
        for (const locationId of userData.selectedLocationIds) {
          try {
            // Use the correct endpoint for assigning locations
            await api.post('/api/user-locations/assign', {
              userId: newUserId,
              locationId: locationId
            });
            console.log(`Location ${locationId} assigned to user ${newUserId}`);
          } catch (locationError) {
            console.error('Error assigning location to user:', locationError);
            console.error('Error details:', locationError.response?.data || locationError.message);
            // Continue with other locations even if one fails
          }
        }
      }
      
      // Add restrictions for features the user should not have access to
      if (userData.restrictedFeatures && Array.isArray(userData.restrictedFeatures) && newUserId) {
        console.log(`Adding feature restrictions for user ${newUserId}:`, userData.restrictedFeatures);
        
        for (const restrictionKey of userData.restrictedFeatures) {
          try {
            await api.post(`/api/permissions/users/${newUserId}/restrictions`, { 
              restrictionKey 
            });
            console.log(`Restriction ${restrictionKey} added for user ${newUserId}`);
          } catch (restrictionError) {
            console.error('Error adding restriction to user:', restrictionError);
            console.error('Error details:', restrictionError.response?.data || restrictionError.message);
            // Continue with other restrictions even if one fails
          }
        }
      }
      
      showToast({
        message: 'User created successfully',
        variant: 'success'
      });
      
      // Close modal and reset form
      setCreateUserModalOpen(false);
      setNewUserData({
        username: '',
        role: 'user',
        companyId: '',
        accessAllLocations: false,
        desktop_access: false,
        mobile_access: false
      });
      
      // Refresh user list
      const usersResponse = await api.get('/api/users');
      if (usersResponse.data && usersResponse.data.data) {
        setUsers(Array.isArray(usersResponse.data.data) ? usersResponse.data.data : []);
      } else {
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);
      }
    } catch (error) {
      console.error('Error creating user:', error);
      showToast({
        message: `Error creating user: ${error.response?.data?.message || error.message}`,
        variant: 'error'
      });
    }
  };
  
  const handleUpdateClientAccess = async (userId, accessType, value) => {
    if (!userId) return;
    
    try {
      // Update the user's client access
      await api.put(`/api/users/${userId}/client-access`, { 
        [accessType]: value,
        // If setting role to admin, also update the admin flag
        ...(accessType === 'role' && value === 'admin' ? { is_admin: true } : {})
      });
      
      showToast({
        message: 'Client access updated successfully',
        variant: 'success'
      });
      
      // Update the selected user in the UI
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({
          ...selectedUser,
          [accessType]: value
        });
      }
      
      // Refresh user list
      const usersResponse = await api.get('/api/users');
      if (usersResponse.data && usersResponse.data.data) {
        setUsers(Array.isArray(usersResponse.data.data) ? usersResponse.data.data : []);
      } else {
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);
      }
    } catch (error) {
      console.error('Error updating client access:', error);
      showToast({
        message: `Error updating client access: ${error.message}`,
        variant: 'error'
      });
    }
  };
  
  const handleDeleteUser = async (userId) => {
    if (!userId) return;
    
    try {
      await api.delete(`/api/users/${userId}`);
      
      showToast({
        message: 'User deleted successfully',
        variant: 'success'
      });
      
      // Clear selected user if it was the deleted user
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser(null);
      }
      
      // Refresh user list
      const usersResponse = await api.get('/api/users');
      if (usersResponse.data && usersResponse.data.data) {
        setUsers(Array.isArray(usersResponse.data.data) ? usersResponse.data.data : []);
      } else {
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      showToast({
        message: `Error deleting user: ${error.message}`,
        variant: 'error'
      });
    }
  };
  
  const handleToggleRestriction = async (restrictionKey) => {
    try {
      if (!selectedUser) return;
      
      // Check if this restriction already exists
      const hasRestriction = Array.isArray(userRestrictions) && 
        userRestrictions.some(r => r.restriction_key === restrictionKey);
      
      // INVERSION: If the restriction exists, we want to remove it to ALLOW access
      // If the restriction doesn't exist, we want to add it to DENY access
      if (hasRestriction) {
        // Remove the restriction (ALLOW the feature)
        await api.delete(`/api/permissions/users/${selectedUser.id}/restrictions/${encodeURIComponent(restrictionKey)}`);
      } else {
        // Add the restriction (DENY the feature)
        await api.post(`/api/permissions/users/${selectedUser.id}/restrictions`, { 
          restrictionKey 
        });
      }
      
      // Reload user restrictions
      loadUserRestrictions(selectedUser.id);
      
      showToast({
        message: 'User features updated successfully',
        variant: 'success'
      });
    } catch (error) {
      console.error('Error updating user features:', error);
      showToast({
        message: `Error updating user features: ${error.message}`,
        variant: 'error'
      });
    }
  };
  
  return {
    // States
    loading,
    users,
    companies,
    locations,
    selectedUser,
    userRestrictions,
    isCreateUserModalOpen,
    newUserData,
    
    // User related functions
    handleSelectUser,
    handleCreateUser,
    handleToggleRestriction,
    handleUpdateClientAccess,
    handleDeleteUser,
    setCreateUserModalOpen,
    setNewUserData,
  };
};

export default useAdminDashboard;

import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Header = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.border.light};
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const UserCard = styled.div`
  padding: 1.5rem;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.md};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const UserName = styled.h3`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
`;

export const UserStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: ${theme.colors.text.secondary};

  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const ActivityItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .progress-bar {
    width: 100%;
    height: 6px;
    background-color: ${theme.colors.background.default};
    border-radius: ${theme.borderRadius.full};
    overflow: hidden;
  }

  .progress {
    height: 100%;
    transition: width 0.3s ease;
  }
`;

export const EventType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${props => props.color || theme.colors.text.primary};
  font-weight: 500;

  span {
    color: ${theme.colors.text.secondary};
    font-weight: 400;
  }
`;

export const TimeRange = styled.div`
  font-size: 0.75rem;
  color: ${theme.colors.text.secondary};
  padding-top: 0.75rem;
  border-top: 1px solid ${theme.colors.border.light};
  font-style: italic;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 3rem;
  text-align: center;
  color: ${theme.colors.text.secondary};
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};

  svg {
    color: ${theme.colors.text.disabled};
  }

  p {
    margin: 0;
    font-size: 1rem;
  }
`;

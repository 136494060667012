import React from 'react';
import styled from 'styled-components';
import { useUserSession } from '@features/auth/hooks';
import RefreshAdminAuthButton from './RefreshAdminAuthButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 1.5rem;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.5;
`;

const StatusBox = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  background-color: ${({ isAdmin }) => isAdmin ? '#d4edda' : '#f8d7da'};
  color: ${({ isAdmin }) => isAdmin ? '#155724' : '#721c24'};
  border: 1px solid ${({ isAdmin }) => isAdmin ? '#c3e6cb' : '#f5c6cb'};
`;

const DashboardLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
`;

const AdminRefreshPage = () => {
  const { user } = useUserSession();
  const isAdmin = user?.admin === true;

  return (
    <Container>
      <Card>
        <Title>Admin Access Manager</Title>
        
        <Paragraph>
          This page allows you to refresh your admin permissions. If you've been
          granted admin access in the database but can't access the admin page,
          use the button below to refresh your permissions.
        </Paragraph>
        
        <StatusBox isAdmin={isAdmin}>
          <strong>Current Admin Status:</strong> {isAdmin ? 'Enabled' : 'Disabled'}
        </StatusBox>
        
        <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
          <RefreshAdminAuthButton />
        </div>
        
        <Paragraph>
          After refreshing, you'll be able to access the admin page at 
          <code style={{ margin: '0 0.5rem', padding: '0.2rem 0.4rem', backgroundColor: '#f8f9fa', borderRadius: '0.25rem' }}>
            /nimda
          </code> 
          if you have admin privileges.
        </Paragraph>
        
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <DashboardLink href="/dashboard">Return to Dashboard</DashboardLink>
          {isAdmin && (
            <DashboardLink href="/nimda" style={{ marginLeft: '1rem' }}>
              Go to Admin Page
            </DashboardLink>
          )}
        </div>
      </Card>
    </Container>
  );
};

export default AdminRefreshPage;

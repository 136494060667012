import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MobileOperatorDashboard } from '../components/MobileOperatorDashboard';
import { SortingConsole } from '../components/consoles/SortingConsole';
import { LogisticsConsole } from '../components/consoles/LogisticsConsole';
import { KleinpakConsole } from '../components/consoles/KleinpakConsole';
import ProtectedMobileConsole from '../components/ProtectedMobileConsole';
import { usePermission } from '@lib/contexts';
import { PalletDetailsPage } from '../components/PalletDetailsPage';
import KleinpakPalletDetailsPage from '../components/KleinpakPalletDetailsPage';
import StandardPalletDetailsPage from '../components/StandardPalletDetailsPage';
import MobileStorageMap from '../components/MobileStorageMap';
import KleinpakDashboard from '../components/KleinpakDashboard';

export const MobileOperatorRoutes = () => {
  const { hasConsoleAccess } = usePermission();
  
  // Determine which console to redirect to based on permissions
  const getDefaultConsole = () => {
    if (hasConsoleAccess('sorting')) return 'sorting';
    if (hasConsoleAccess('kleinpak')) return 'kleinpak';
    if (hasConsoleAccess('logistics')) return 'logistics';
    return 'sorting'; // Default fallback, the ProtectedMobileConsole will handle unauthorized access
  };
  return (
    <Routes>
      <Route path="/" element={<MobileOperatorDashboard />}>
        <Route index element={<Navigate to={getDefaultConsole()} replace />} />
        <Route path="sorting" element={<ProtectedMobileConsole consoleName="sorting" component={SortingConsole} />} />
        <Route path="logistics" element={<ProtectedMobileConsole consoleName="logistics" component={LogisticsConsole} />} />
        <Route path="kleinpak" element={<ProtectedMobileConsole consoleName="kleinpak" component={KleinpakConsole} />} />
        <Route path="kleinpak-dashboard" element={<KleinpakDashboard />} />
        <Route path="storage" element={<MobileStorageMap />} />
        <Route path="scan/:palletId" element={<PalletDetailsPage />} />
        <Route path="kleinpak-pallet/:palletId" element={<KleinpakPalletDetailsPage />} />
        <Route path="standard-pallet/:palletId" element={<StandardPalletDetailsPage />} />
      </Route>
    </Routes>
  );
};

export const mobileOperatorPaths = {
  root: '/mobile/operator',
  sorting: '/mobile/operator/sorting',
  logistics: '/mobile/operator/logistics',
  kleinpak: '/mobile/operator/kleinpak',
  kleinpakDashboard: '/mobile/operator/kleinpak-dashboard',
  storage: '/mobile/operator/storage',
  scan: '/mobile/operator/scan',
  palletDetails: '/mobile/operator/scan/:palletId',
  kleinpakPalletDetails: '/mobile/operator/kleinpak-pallet/:palletId',
  standardPalletDetails: '/mobile/operator/standard-pallet/:palletId'
};

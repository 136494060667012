import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 99, 71, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.loading};
  animation: ${fadeIn} 0.3s ease-in-out;
  backdrop-filter: blur(3px);
`;

const SpinnerContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerOuter = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.primary[300]};
  border-bottom-color: ${({ theme }) => theme.colors.primary[700]};
  animation: ${spin} 1.5s linear infinite;
`;

const SpinnerMiddle = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-left-color: ${({ theme }) => theme.colors.primary.main};
  border-right-color: ${({ theme }) => theme.colors.primary.main};
  animation: ${spin} 1.2s linear infinite reverse;
`;

const SpinnerInner = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => `radial-gradient(circle, ${theme.colors.primary[300]} 0%, ${theme.colors.primary.main} 100%)`};
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite;
  box-shadow: 0 0 10px rgba(255, 99, 71, 0.5);
`;

const Message = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.background.paper};
  ${({ theme }) => theme.typography.h6};
  text-align: center;
  max-width: 80%;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const LoadingOverlay = ({ message = 'Processing...' }) => {
  return (
    <Overlay>
      <SpinnerContainer>
        <SpinnerOuter />
        <SpinnerMiddle />
        <SpinnerInner />
      </SpinnerContainer>
      <Message>{message}</Message>
    </Overlay>
  );
};

export default LoadingOverlay;

import styled from 'styled-components';

// Styled components for dual view system
export const MapWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  max-width: 1200px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

export const MapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.lg};
  padding-bottom: ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const Controls = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  flex-wrap: wrap;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  font-size: 0.9rem;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

export const LocationWarning = styled.div`
  background: ${({ theme }) => theme.colors.warning.light};
  color: ${({ theme }) => theme.colors.warning.dark};
  border: 1px solid ${({ theme }) => theme.colors.warning.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  text-align: center;
`;

export const Legend = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
  }
`;

export const LegendColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme, group }) => {
    switch (group) {
      case 'customer':
        return `linear-gradient(135deg, ${theme.colors.success[300]} 0%, ${theme.colors.success.main} 100%)`;
      case 'plum':
        return `linear-gradient(135deg, ${theme.colors.secondary[300]} 0%, ${theme.colors.secondary.main} 100%)`;
      case 'round':
        return `linear-gradient(135deg, ${theme.colors.primary[300]} 0%, ${theme.colors.primary.main} 100%)`;
      case 'special':
        return `linear-gradient(135deg, ${theme.colors.info[300]} 0%, ${theme.colors.info.main} 100%)`;
      default:
        return theme.colors.background.default;
    }
  }};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

export const LegendText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 500;
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 800px;
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: auto;
  margin: 0 auto;
  box-shadow: ${({ theme }) => theme.shadows.inner};
  padding: ${({ theme }) => theme.spacing.md};

  @media (max-width: 1200px) {
    height: 700px;
  }

  @media (max-width: 900px) {
    height: 600px;
  }

  @media (max-width: 600px) {
    height: 500px;
  }
`;

export const WarehouseLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.paper};
`;

export const ExportModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ExportContent = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.shadows.lg};
`;

export const ExportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const ExportTitle = styled.h3`
  margin: 0;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const CodeBlock = styled.pre`
  background: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow-x: auto;
  white-space: pre-wrap;
  font-family: monospace;
`;

export const Zone = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme, $selected }) => 
    $selected ? theme.colors.primary.main : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  /* Adjust padding based on display mode */
  padding: ${({ theme, $displayMode }) => {
    switch ($displayMode) {
      case 'full':
        return theme.spacing.sm;
      case 'medium':
        return `${theme.spacing.xs} ${theme.spacing.sm}`;
      case 'minimal':
        return `2px`;
      default:
        return theme.spacing.sm;
    }
  }};
  cursor: ${({ $editMode }) => ($editMode ? 'move' : 'pointer')};
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme, $selected }) => 
    $selected ? theme.shadows.md : theme.shadows.sm};
  transform: ${({ $selected }) => ($selected ? 'scale(1.01)' : 'scale(1)')};
  position: absolute;
  overflow: hidden;
  
  /* Increase hover effect prominence for small zones */
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.light};
    box-shadow: ${({ theme, $displayMode }) => 
      $displayMode === 'minimal' ? theme.shadows.lg : theme.shadows.md};
    z-index: ${({ $editMode }) => ($editMode ? 5 : 1)};
  }
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: ${({ theme }) => theme.shadows.focus};
    z-index: 1;
  }
`;

export const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.$cols}, 1fr);
  grid-template-rows: repeat(${props => props.$rows}, 1fr);
  gap: 1px;
  height: calc(100% - 30px);
  margin-top: 4px;
  background: ${({ theme }) => theme.colors.border.light};
`;

export const PalletCell = styled.div`
  background: ${({ $occupied, theme }) => 
    $occupied ? theme.colors.primary.light : theme.colors.background.paper};
  min-height: 5px;
  min-width: 5px;
`;

export const ZoneInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CoordinateDisplay = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  font-size: 8px;
  padding: 2px 4px;
  border-radius: 2px;
  z-index: 2;
  pointer-events: none;
`;

export const ZoneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const ZoneName = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ZoneType = styled.div`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  opacity: 0.8;
`;

export const ZoneFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ZoneDimensions = styled.div`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const OccupancyBar = styled.div`
  height: 4px;
  width: 100%;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  margin-top: ${({ theme }) => theme.spacing.xs};
  overflow: hidden;
`;

export const OccupancyFill = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background: ${({ theme, percentage }) => {
    if (percentage > 90) return theme.colors.error.main;
    if (percentage > 70) return theme.colors.warning.main;
    return theme.colors.success.main;
  }};
  transition: width 0.3s ease;
`;

export const OccupancyText = styled.div`
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const RefrigeratedIcon = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xs};
  right: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.info.main};
  font-size: 0.8rem;
`;

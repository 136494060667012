import React, { useMemo } from 'react';
import { 
  Container, 
  Header, 
  Title, 
  Content, 
  StatGrid, 
  StatCard, 
  StatValue, 
  StatLabel,
  ChartContainer,
  ChartTitle,
  ChartDescription,
  NoData
} from './styles';
import { Activity, Package, Users, Clock } from 'lucide-react';
import { getEventTypeLabel, getEventTypeColor } from '../../utils';

export const AggregateStats = ({ stats }) => {
  const aggregatedData = useMemo(() => {
    if (!Array.isArray(stats)) return null;

    return stats.reduce((acc, stat) => {
      // Group by date
      const date = stat.date;
      if (!acc[date]) {
        acc[date] = {
          totalEvents: 0,
          totalPallets: 0,
          uniqueUsers: new Set(),
          eventTypes: {}
        };
      }

      // Aggregate stats
      acc[date].totalEvents += stat.event_count;
      acc[date].totalPallets += stat.pallet_count;
      acc[date].uniqueUsers.add(stat.user_count);

      // Count by event type
      if (!acc[date].eventTypes[stat.event_type]) {
        acc[date].eventTypes[stat.event_type] = 0;
      }
      acc[date].eventTypes[stat.event_type] += stat.event_count;

      return acc;
    }, {});
  }, [stats]);

  const totals = useMemo(() => {
    if (!aggregatedData) return null;

    return Object.values(aggregatedData).reduce((acc, dayStats) => {
      acc.totalEvents += dayStats.totalEvents;
      acc.totalPallets += dayStats.totalPallets;
      acc.uniqueUsers = new Set([...acc.uniqueUsers, ...dayStats.uniqueUsers]);

      // Aggregate event types
      Object.entries(dayStats.eventTypes).forEach(([type, count]) => {
        if (!acc.eventTypes[type]) {
          acc.eventTypes[type] = 0;
        }
        acc.eventTypes[type] += count;
      });

      return acc;
    }, {
      totalEvents: 0,
      totalPallets: 0,
      uniqueUsers: new Set(),
      eventTypes: {}
    });
  }, [aggregatedData]);

  if (!stats || stats.length === 0) {
    return (
      <NoData>
        <Activity size={48} />
        <p>No statistics available for the selected period.</p>
      </NoData>
    );
  }

  return (
    <Container>
      <Header>
        <Title>
          <Activity size={24} />
          Analytics Overview
        </Title>
      </Header>

      <Content>
        <StatGrid>
          <StatCard>
            <StatValue>
              <Activity size={20} />
              {totals.totalEvents.toLocaleString()}
            </StatValue>
            <StatLabel>Total Events</StatLabel>
          </StatCard>

          <StatCard>
            <StatValue>
              <Package size={20} />
              {totals.totalPallets.toLocaleString()}
            </StatValue>
            <StatLabel>Unique Pallets</StatLabel>
          </StatCard>

          <StatCard>
            <StatValue>
              <Users size={20} />
              {totals.uniqueUsers.size.toLocaleString()}
            </StatValue>
            <StatLabel>Active Users</StatLabel>
          </StatCard>

          <StatCard>
            <StatValue>
              <Clock size={20} />
              {Object.keys(aggregatedData).length}
            </StatValue>
            <StatLabel>Days of Activity</StatLabel>
          </StatCard>
        </StatGrid>

        <ChartContainer>
          <ChartTitle>Event Distribution</ChartTitle>
          <ChartDescription>
            Breakdown of events by type during the selected period
          </ChartDescription>

          {/* Event type distribution */}
          {Object.entries(totals.eventTypes).map(([type, count]) => {
            const percentage = (count / totals.totalEvents) * 100;
            return (
              <div key={type} style={{ marginBottom: '1rem' }}>
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  marginBottom: '0.25rem'
                }}>
                  <span style={{ color: getEventTypeColor(type) }}>
                    {getEventTypeLabel(type)}
                  </span>
                  <span>{count.toLocaleString()} ({percentage.toFixed(1)}%)</span>
                </div>
                <div style={{ 
                  width: '100%',
                  height: '8px',
                  backgroundColor: '#eee',
                  borderRadius: '4px',
                  overflow: 'hidden'
                }}>
                  <div style={{
                    width: `${percentage}%`,
                    height: '100%',
                    backgroundColor: getEventTypeColor(type),
                    transition: 'width 0.3s ease'
                  }} />
                </div>
              </div>
            );
          })}
        </ChartContainer>

        {/* Additional charts could be added here */}
      </Content>
    </Container>
  );
};

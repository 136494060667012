import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useKleinpakOrderAssignment } from './hooks';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 0 20px 0;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 8px;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PalletInfo = styled.div`
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 16px;
  margin-bottom: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ theme }) => theme.typography.body2};
`;

const InfoValue = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  font-weight: 500;
`;

const ProcessingSection = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 16px;
`;

const ProcessingTitle = styled.h3`
  ${({ theme }) => theme.typography.subtitle1};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 0 16px 0;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.error.light};
  color: ${({ theme }) => theme.colors.error.main};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 16px;
`;

const NoOrdersMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 16px;
`;

const ValidationError = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  ${({ theme }) => theme.typography.caption};
  margin-top: 4px;
`;

// New components for progress tracking
const ProgressContainer = styled.div`
  margin-bottom: 16px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  margin-bottom: 8px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: ${({ theme, progress }) => {
    // Color varies based on progress
    if (progress < 25) return theme.colors.error.main;
    if (progress < 75) return theme.colors.warning.main;
    return theme.colors.success.main;
  }};
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.3s ease-out;
`;

const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const TomatoTallyContainer = styled.div`
  margin-top: 16px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const TomatoTallyTitle = styled.h4`
  ${({ theme }) => theme.typography.subtitle2};
  margin: 0 0 8px 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const TomatoTallyItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  ${({ theme }) => theme.typography.body2};
`;

const TomatoType = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
`;

const TomatoCount = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: 500;
`;

const LineInfoContainer = styled.div`
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LineName = styled.span`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.colors.primary.dark};
`;

const LineBadge = styled.span`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  padding: 2px 8px;
  border-radius: 12px;
  ${({ theme }) => theme.typography.caption};
  font-weight: 600;
`;

const KleinpakOrderAssignmentModal = ({
  isOpen,
  onClose,
  onAssign,
  palletDetails,
  orders = [],
  loading = false,
  error = null
}) => {
  // Only use kleinpak orders that are not ready for shipping in this modal
  const kleinpakOrders = useMemo(() => {
    console.log(`Filtering ${orders.length} orders for Kleinpak modal`);
    if (!Array.isArray(orders)) {
      console.warn('orders is not an array:', orders);
      return [];
    }
    
    const filtered = orders.filter(order => 
      !!order.isKleinpakOrder && 
      // Filter out orders that are ready for shipping or completed
      (!order.ready_for_shipping && !order.is_completed)
    );
    console.log(`Found ${filtered.length} active Kleinpak orders from ${orders.length} total orders`);
    return filtered;
  }, [orders]);

  const {
    selectedOrder,
    setSelectedOrder,
    processingData,
    handleAssign,
    resetForm,
    isAssigning,
    validateProcessingData
  } = useKleinpakOrderAssignment({
    onAssign,
    onClose,
    palletDetails
  });

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  // Validation error for processing data
  const validationError = useMemo(() => {
    if (!selectedOrder) return null;
    return validateProcessingData();
  }, [selectedOrder, validateProcessingData]);

  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Title>Process & Assign Pallet</Title>
        
        {error && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
        
        <PalletInfo>
          <InfoRow>
            <InfoLabel>Pallet ID</InfoLabel>
            <InfoValue>{palletDetails?.id || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Type</InfoLabel>
            <InfoValue>{palletDetails?.tomatoType || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Box Type</InfoLabel>
            <InfoValue>{palletDetails?.boxType || 'Unknown'}</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Weight</InfoLabel>
            <InfoValue>{palletDetails?.weight ? `${palletDetails.weight}kg` : 'Unknown'}</InfoValue>
          </InfoRow>
        </PalletInfo>
        
        {loading ? (
          <LoadingMessage>Loading orders...</LoadingMessage>
        ) : kleinpakOrders.length === 0 ? (
          <NoOrdersMessage>
            No Kleinpak orders available. Please create a Kleinpak order first.
          </NoOrdersMessage>
        ) : (
          <FormGroup>
            <Label htmlFor="order-select">Select Kleinpak Order</Label>
            <Select
              id="order-select"
              value={selectedOrder?.id || ''}
              onChange={(e) => {
                const orderId = e.target.value;
                const selected = kleinpakOrders.find(order => order.id.toString() === orderId);
                setSelectedOrder(selected || null);
              }}
            >
              <option value="">-- Select an order --</option>
              {kleinpakOrders.map((order) => (
                <option key={order.id} value={order.id}>
                  {order.customer_name || 'Unnamed Order'} - {order.order_number || order.id}
                  {order.processingLine ? ` (Line ${order.processingLine})` : ''}
                </option>
              ))}
            </Select>
          </FormGroup>
        )}
        
        {selectedOrder && (
          <ProcessingSection>
            <ProcessingTitle>Order Progress</ProcessingTitle>

            {selectedOrder.processingLine && (
              <LineInfoContainer>
                <LineName>Processing Line</LineName>
                <LineBadge>Line {selectedOrder.processingLine}</LineBadge>
              </LineInfoContainer>
            )}
            
            <ProgressContainer>
              <ProgressBar>
                <ProgressFill progress={processingData.progressPercentage || 0} />
              </ProgressBar>
              <ProgressText>
                <span>
                  Output Order: {selectedOrder.total_pallets} Pallets
                </span>
                <span>{Math.round(processingData.progressPercentage || 0)}% Complete</span>
              </ProgressText>
              {processingData.processedPallets > 0 && (
                <div style={{ marginTop: '8px' }}>
                  <InfoRow>
                    <InfoLabel>Processing</InfoLabel>
                    <InfoValue>{processingData.processedPallets} Pallets</InfoValue>
                  </InfoRow>
                </div>
              )}
            </ProgressContainer>
            
            {processingData.tomatoTally && Object.keys(processingData.tomatoTally).length > 0 && processingData.processedPallets > 0 && (
              <TomatoTallyContainer>
                <TomatoTallyTitle>Tomato Type Tally</TomatoTallyTitle>
                {Object.entries(processingData.tomatoTally).map(([type, count]) => (
                  <TomatoTallyItem key={type}>
                    <TomatoType>{type}</TomatoType>
                    <TomatoCount>{count} pallets</TomatoCount>
                  </TomatoTallyItem>
                ))}
              </TomatoTallyContainer>
            )}
            
            <InfoRow style={{ marginTop: '12px' }}>
              <InfoLabel>Pallet to Add</InfoLabel>
              <InfoValue>
                {palletDetails?.tomatoType || 'Unknown'}
              </InfoValue>
            </InfoRow>
            
            {validationError && (
              <ValidationError>{validationError}</ValidationError>
            )}
          </ProcessingSection>
        )}
        
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            onClick={handleAssign}
            disabled={!selectedOrder || validationError || isAssigning || loading}
          >
            {isAssigning ? 'Processing...' : 'Process & Assign'}
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default KleinpakOrderAssignmentModal;

import { MAX_PALLETS, validatePalletData, getPalletOrderId, normalizeAllowedPalletIds } from './validationUtils';

/**
 * Groups pallets by their assigned zone
 * @param {Array} pallets - Array of pallets to group
 * @returns {Object} Pallets grouped by zone
 */
export const groupPalletsByZone = (pallets) => {
  const palletsByZone = {};
  
  if (!Array.isArray(pallets)) {
    return palletsByZone;
  }
  
  // Apply upper bound to prevent excessive processing
  const boundedPallets = pallets.slice(0, MAX_PALLETS);
  
  boundedPallets.forEach(pallet => {
    const zone = pallet.zone || 'Unassigned';
    
    if (!palletsByZone[zone]) {
      palletsByZone[zone] = [];
    }
    
    palletsByZone[zone].push(pallet);
  });
  
  return palletsByZone;
};

/**
 * Filters pallets by zone name
 * @param {Array} pallets - Array of pallets to filter
 * @param {string} zoneName - Zone name to filter by
 * @returns {Array} Filtered pallets
 */
export const filterPalletsByZone = (pallets, zoneName) => {
  if (!Array.isArray(pallets) || !zoneName) return [];
  
  return pallets.filter(pallet => {
    const palletZone = pallet.zone || '';
    
    // Direct match
    if (palletZone === zoneName) return true;
    
    // Handle storage zone format
    if (pallet.storageZone && typeof pallet.storageZone === 'string') {
      const zoneMatch = pallet.storageZone.match(/^(zone-\d+(?:\.\d+)?)/);
      if (zoneMatch) {
        const extractedZone = zoneMatch[1];
        const normalizedZoneName = zoneName.replace(/\s+/g, '-').toLowerCase();
        return extractedZone === normalizedZoneName || 
               extractedZone === `zone-${normalizedZoneName.replace('zone-', '')}`;
      }
    }
    
    return false;
  });
};

/**
 * Filters pallets by scheduled rows for a zone
 * @param {Array} allPallets - Array of all pallets
 * @param {Array} scheduledRows - Array of scheduled rows
 * @param {string} zoneName - Zone name to filter by
 * @returns {Array} Filtered pallets
 */
export const filterPalletsByScheduledRows = (allPallets, scheduledRows, zoneName) => {
  if (!Array.isArray(allPallets) || !Array.isArray(scheduledRows)) return [];
  
  // Filter rows for this zone
  const zoneRows = scheduledRows.filter(row => {
    const rowZoneName = row.zoneName || row.zoneId || '';
    const normalizedZoneName = zoneName.replace(/\s+/g, '-').toLowerCase();
    
    return rowZoneName === zoneName || 
           rowZoneName === normalizedZoneName ||
           rowZoneName === `zone-${normalizedZoneName.replace('zone-', '')}`;
  });
  
  if (zoneRows.length === 0) {
    console.log(`No scheduled rows found for zone ${zoneName}`);
    return filterPalletsByZone(allPallets, zoneName);
  }
  
  // Import row storage utils only when needed (code splitting)
  const { getEnhancedPalletsInScheduledRows } = require('../../../../../lib/utils/rowStorageUtils');
  return getEnhancedPalletsInScheduledRows(allPallets, zoneRows, true);
};

/**
 * Finds the last scanned pallet for an order
 * @param {Object} order - Order object
 * @returns {Object|null} Last scanned pallet or null
 */
export const findLastScannedPallet = (order) => {
  try {
    validatePalletData(order);
    
    const currentOrderId = String(order.id);
    const allowedPalletIds = normalizeAllowedPalletIds(order.allowed_pallet_ids);
    
    // Filter to strictly assigned pallets
    const orderPallets = order.pallets.filter(pallet => {
      if (!pallet) return false;
      
      const palletId = String(pallet.id);
      const palletOrderId = getPalletOrderId(pallet);
      const isAssignedToThisOrder = palletOrderId === currentOrderId;
      const hasValidProcessStage = pallet.processStage === 'Processing' || 
                                 pallet.processStage === 'Processed' ||
                                 pallet.isScanned === true;
      const isInAllowedList = allowedPalletIds.length === 0 || 
                             allowedPalletIds.includes(palletId);
      
      return isAssignedToThisOrder && hasValidProcessStage && isInAllowedList;
    });
    
    if (orderPallets.length === 0) return null;
    
    // Try to find pallet with explicit scan time
    const palletWithScanTime = orderPallets.find(p => p.lastScannedAt);
    if (palletWithScanTime) return palletWithScanTime;
    
    // Try to find processing pallet
    const processingPallets = orderPallets.filter(p => p.processStage === 'Processing');
    if (processingPallets.length > 0) {
      return processingPallets.sort((a, b) => {
        const aTime = a.updated_at || a.created_at || 0;
        const bTime = b.updated_at || b.created_at || 0;
        return new Date(bTime) - new Date(aTime);
      })[0];
    }
    
    // Fall back to most recently updated pallet
    return orderPallets.sort((a, b) => {
      const aTime = a.updated_at || a.created_at || 0;
      const bTime = b.updated_at || b.created_at || 0;
      return new Date(bTime) - new Date(aTime);
    })[0];
    
  } catch (error) {
    console.error('Error finding last scanned pallet:', error);
    return null;
  }
};

import React, { useMemo } from 'react';
import { 
  formatTimestamp, 
  getEventTypeLabel, 
  getEventTypeColor,
  extractStateChanges,
  formatFieldName
} from '../../utils';
import { 
  Container, 
  Header, 
  BackButton, 
  PalletInfo, 
  TimelineContainer, 
  TimelineItem, 
  EventTime, 
  EventContent, 
  EventType, 
  EventDetails, 
  UserInfo, 
  StateChanges, 
  StateChange, 
  ChangeValue, 
  NoEvents 
} from './styles';
import { User, Package, Clock, ArrowLeft, ArrowRight } from 'lucide-react';

export const PalletTimeline = ({ events, palletId, onEventSelect, onBack }) => {
  // Sort events by timestamp (newest first)
  const sortedEvents = useMemo(() => {
    if (!events || !Array.isArray(events)) return [];
    
    return [...events].sort((a, b) => 
      new Date(b.timestamp) - new Date(a.timestamp)
    );
  }, [events]);

  // Get pallet details from the first event
  const palletDetails = useMemo(() => {
    if (sortedEvents.length === 0) return null;
    
    // Find the most recent event with pallet details
    const eventWithDetails = sortedEvents.find(event => 
      event.pallet_number || event.tomato_type || event.box_type || event.pallet_type
    );
    
    return eventWithDetails || sortedEvents[0];
  }, [sortedEvents]);

  if (!events || events.length === 0) {
    return (
      <NoEvents>
        <BackButton onClick={onBack}>
          <ArrowLeft size={16} />
          Back to all events
        </BackButton>
        <p>No history found for pallet #{palletId}.</p>
      </NoEvents>
    );
  }

  return (
    <Container>
      <Header>
        <BackButton onClick={onBack}>
          <ArrowLeft size={16} />
          Back to all events
        </BackButton>
        
        <PalletInfo>
          <Package size={18} />
          <h3>
            Pallet {palletDetails?.pallet_number || `#${palletId}`}
            {palletDetails?.tomato_type && ` - ${palletDetails.tomato_type}`}
          </h3>
          {palletDetails?.box_type && <span>Box: {palletDetails.box_type}</span>}
          {palletDetails?.pallet_type && <span>Type: {palletDetails.pallet_type}</span>}
        </PalletInfo>
      </Header>

      <TimelineContainer>
        {sortedEvents.map(event => (
          <TimelineItem 
            key={event.id}
            onClick={() => onEventSelect(event)}
            color={getEventTypeColor(event.event_type)}
          >
            <EventTime>
              <Clock size={14} />
              {formatTimestamp(event.timestamp)}
            </EventTime>
            
            <EventContent>
              <EventType color={getEventTypeColor(event.event_type)}>
                {getEventTypeLabel(event.event_type)}
              </EventType>
              
              <EventDetails>
                <UserInfo>
                  <User size={14} />
                  {event.user_name || `User ${event.user_id}`}
                </UserInfo>
                
                {event.location_name && (
                  <span>
                    <ArrowRight size={14} />
                    Location: {event.location_name}
                  </span>
                )}
                
                {event.order_number && (
                  <span>
                    <ArrowRight size={14} />
                    Order: {event.order_number}
                  </span>
                )}
              </EventDetails>
              
              {/* Show state changes if available */}
              <StateChanges>
                {extractStateChanges(event).map((change, index) => (
                  <StateChange key={index}>
                    <span>{formatFieldName(change.field)}:</span>
                    <ChangeValue>
                      {change.oldValue !== null && change.oldValue !== undefined 
                        ? (typeof change.oldValue === 'object' 
                            ? JSON.stringify(change.oldValue) 
                            : String(change.oldValue)) 
                        : 'null'}
                      <ArrowRight size={12} />
                      {change.newValue !== null && change.newValue !== undefined 
                        ? (typeof change.newValue === 'object'
                            ? JSON.stringify(change.newValue)
                            : String(change.newValue))
                        : 'null'}
                    </ChangeValue>
                  </StateChange>
                ))}
              </StateChanges>
              
              {/* Show notes if available */}
              {event.notes && (
                <div style={{ marginTop: '0.5rem', fontStyle: 'italic' }}>
                  {event.notes}
                </div>
              )}
            </EventContent>
          </TimelineItem>
        ))}
      </TimelineContainer>
    </Container>
  );
};

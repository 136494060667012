import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.lg};
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.background.paper},
    ${({ theme }) => theme.colors.background.default}
  );
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const MenuButton = styled.button`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  border: none;
  height: 48px;
  padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  flex: 1;
  min-width: 140px;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    box-shadow: ${({ theme }) => theme.shadows.md};
    transform: translateY(-1px);
  }

  &:active {
    background: ${({ theme }) => theme.colors.primary.darker};
    box-shadow: ${({ theme }) => theme.shadows.sm};
    transform: translateY(0);
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const TopGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
`;

export const MiddleGrid = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

export const BottomGrid = styled(Grid)`
  grid-template-columns: repeat(3, 1fr);
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

export const Button = styled.button`
  background: ${props => 
    props.selected 
      ? `linear-gradient(135deg, ${props.theme.colors.primary.main}, ${props.theme.colors.primary.dark})`
      : props.theme.colors.background.paper};
  color: ${props => 
    props.selected 
      ? props.theme.colors.text.inverse 
      : props.theme.colors.text.primary};
  border: 1px solid ${props => 
    props.selected 
      ? 'transparent'
      : props.theme.colors.border.light};
  padding: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  font-weight: ${props => props.selected ? 600 : 400};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: ${props => props.selected ? 'pointer' : 'default'};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90px;
  box-shadow: ${props => 
    props.selected 
      ? props.theme.shadows.md
      : props.theme.shadows.sm};
  opacity: ${props => props.selected ? 1 : 0.6};
  
  &:hover {
    opacity: ${props => props.selected ? 1 : 0.6};
    transform: ${props => props.selected ? 'translateY(-1px)' : 'none'};
    border-color: ${props => 
      props.selected 
        ? 'transparent'
        : props.theme.colors.border.light};
  }

  &:active {
    transform: ${props => props.selected ? 'translateY(0)' : 'none'};
  }
`;

export const Divider = styled.hr`
  margin: ${({ theme }) => theme.spacing.lg} 0;
  border: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.border.light}00,
    ${({ theme }) => theme.colors.border.light},
    ${({ theme }) => theme.colors.border.light}00
  );
`;

export const Typography = styled.h2`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.md} 0;
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
  color: ${({ theme }) => theme.colors.text.primary};
  letter-spacing: ${({ theme }) => theme.typography.h3.letterSpacing};
  line-height: 1.4;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.modal};
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }
`;

export const FeedContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.xl};
  position: relative;
  overflow-y: auto;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.3s ease forwards;
  
  @media (min-width: 768px) {
    width: 90%;
    max-width: 600px;
    height: auto;
    max-height: 90vh;
    margin-top: 5vh;
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    box-shadow: ${({ theme }) => theme.shadows.xl};
  }

  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[400]};
    border-radius: ${({ theme }) => theme.borderRadius.full};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.md};
  right: ${({ theme }) => theme.spacing.md};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.primary.main};
  }

  &:active {
    background: ${({ theme }) => theme.colors.background.darker};
  }
`;

export const FeedItem = styled.div`
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.lg};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    transform: translateX(-2px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
`;

export const FeedItemInfo = styled.div`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const DeleteButton = styled.button`
  background: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.text.inverse};
  border: none;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

import { api } from '@api';
import { handleApiError } from '@utils/errorHandler';
import { config } from '@config/env';

export const loginUser = async (username, password) => {
  try {
    // In development mode, return mock user data
    if (config.isDevelopment) {
      console.log('Development mode: using mock login data');
      const mockUser = {
        id: '1',
        username: username,
        admin: true,
        first_login: false,
        permissions: config.devPermissions
      };
      const mockToken = 'dev-token';
      
      localStorage.setItem('token', mockToken);
      localStorage.setItem('userData', JSON.stringify(mockUser));
      
      return {
        token: mockToken,
        user: mockUser
      };
    }

    console.log('Making login request...');
    const response = await api.post('/api/users/login', { username, password });
    console.log('Login response:', response);
    
    const { token, user } = response.data.data;
    if (token) {
      console.log('Setting token and user data');
      localStorage.setItem('token', token);
      localStorage.setItem('userData', JSON.stringify({
        id: user.id,
        username: user.username,
        admin: user.admin,
        first_login: user.first_login,
        permissions: user.permissions
      }));
    } else {
      console.warn('No token in response:', response.data);
    }
    
    return response.data.data;
  } catch (error) {
    console.error('Login error in service:', error);
    throw handleApiError(error);
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  window.location.href = '/login';
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableCell = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  color: ${({ theme }) => theme.colors.text.primary};
  vertical-align: middle;
  height: 3.5rem;
  
  &.checkbox-cell {
    width: 48px;
    padding: 0;
    text-align: center;

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin: 0;
      cursor: pointer;
      vertical-align: middle;
      accent-color: ${({ theme }) => theme.colors.primary.main};

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &.qr-cell {
    width: 60px;
    text-align: center;
    padding: 0.5rem;
  }
  
  &.actions-cell {
    text-align: right;
    white-space: nowrap;
  }

  /* Content layout */
  .cell-content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .primary {
      color: ${({ theme }) => theme.colors.text.primary};
      font-weight: 500;
    }

    .secondary {
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 0.875rem;
    }
  }
`;

const TableCell = ({ 
  children, 
  className,
  isCheckbox,
  checked,
  onChange,
  primary,
  secondary,
  disabled,
  ...props
}) => {
  if (isCheckbox) {
    return (
      <StyledTableCell className="checkbox-cell" {...props}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </StyledTableCell>
    );
  }

  if (primary || secondary) {
    return (
      <StyledTableCell {...props}>
        <div className="cell-content">
          {primary && <div className="primary">{primary}</div>}
          {secondary && <div className="secondary">{secondary}</div>}
        </div>
      </StyledTableCell>
    );
  }

  return (
    <StyledTableCell className={className} {...props}>
      {children}
    </StyledTableCell>
  );
};

TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  disabled: PropTypes.bool,
};

TableCell.defaultProps = {
  isCheckbox: false,
  checked: false,
  disabled: false,
};

export default TableCell;

// Also export the styled component for flexibility
export { StyledTableCell };

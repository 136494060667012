import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.textDark};
`;

export const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin-bottom: 1.5rem;
`;

export const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  color: ${({ active, theme }) => active ? theme.colors.primary : theme.colors.textMedium};
  font-weight: ${({ active }) => active ? '600' : '400'};

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ active, theme }) => active ? theme.colors.primary : 'transparent'};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLighter};
  }
  
  th {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textDark};
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }
  
  tr:hover td {
    background-color: ${({ theme }) => theme.colors.backgroundLightest};
  }
`;

export const Button = styled.button`
  background-color: ${({ theme, variant }) => 
    variant === 'primary' ? theme.colors.primary : 
    variant === 'danger' ? theme.colors.danger : 
    'transparent'};
  color: ${({ theme, variant }) => 
    variant === 'primary' || variant === 'danger' ? 'white' : theme.colors.textDark};
  border: 1px solid ${({ theme, variant }) => 
    variant === 'primary' ? theme.colors.primary : 
    variant === 'danger' ? theme.colors.danger : 
    theme.colors.borderColor};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${({ theme, variant }) => 
      variant === 'primary' ? theme.colors.primaryDark : 
      variant === 'danger' ? theme.colors.dangerDark : 
      theme.colors.backgroundLight};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textDark};
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.25rem;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}20;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}20;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

export const CheckboxLabel = styled.label`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.textMedium};
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

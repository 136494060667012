/**
 * SSEManager.js
 * Singleton manager for SSE connections
 */

import SSEClient from './SSEClient';

class SSEManager {
  constructor() {
    this.client = null;
    this.locationId = null;
    this.subscribers = new Map();
  }

  /**
   * Get the singleton instance
   * @returns {SSEManager} The singleton instance
   */
  static getInstance() {
    if (!SSEManager.instance) {
      SSEManager.instance = new SSEManager();
    }
    return SSEManager.instance;
  }

  /**
   * Initialize or get the SSE client for a location
   * @param {string} locationId - The location ID
   * @param {object} options - Additional options for the SSE client
   * @returns {SSEClient} The SSE client instance
   */
  initialize(locationId, options = {}) {
    try {
      // Skip SSE initialization on login routes
      const isLoginRoute = window.location.pathname.includes('/login');
      if (isLoginRoute) {
        console.log('Skipping SSE initialization on login route');
        return null;
      }
      
      // If we already have a client for this location, check its state
      if (this.client && this.locationId === locationId) {
        // Check if the client is in a good state
        if (this.client.isConnected()) {
          console.log('Reusing existing SSE client:', {
            locationId,
            clientId: this.client.clientId,
            timestamp: new Date().toISOString()
          });
          return this.client;
        } else {
          console.log('Existing SSE client not connected, creating new one:', {
            locationId,
            oldClientId: this.client.clientId,
            timestamp: new Date().toISOString()
          });
          // Clean up the disconnected client
          this.cleanup();
        }
      }

      // Clean up existing client if it exists
      if (this.client) {
        console.log('Cleaning up existing SSE client:', {
          oldLocationId: this.locationId,
          newLocationId: locationId,
          timestamp: new Date().toISOString()
        });
        this.cleanup();
      }

      console.log('Initializing new SSE client:', {
        locationId,
        timestamp: new Date().toISOString()
      });

      // Create new client with validated options
      const validatedOptions = {
        ...options,
        // Ensure required options have defaults
        baseURL: options.baseURL || '',
        maxRetries: options.maxRetries || 8,
        retryDelay: options.retryDelay || 1000,
        // Add health check options
        healthCheckTimer: options.healthCheckTimer || 15000,
        maxHeartbeatMisses: options.maxHeartbeatMisses || 2
      };

      this.client = new SSEClient(locationId, validatedOptions);
      this.locationId = locationId;

      // Transfer any existing subscriptions to the new client
      if (this.subscribers.size > 0) {
        console.log('Transferring existing subscriptions:', {
          count: this.subscribers.size,
          timestamp: new Date().toISOString()
        });

        this.subscribers.forEach((handlers, eventType) => {
          handlers.forEach(handler => {
            try {
              this.client.subscribe(eventType, handler);
            } catch (error) {
              console.error('Error transferring subscription:', {
                error: error.message,
                eventType,
                timestamp: new Date().toISOString()
              });
            }
          });
        });
      }

      return this.client;
    } catch (error) {
      console.error('Error initializing SSE client:', {
        error: error.message,
        locationId,
        timestamp: new Date().toISOString()
      });
      
      // Clean up on initialization failure
      this.cleanup();
      return null;
    }
  }

  /**
   * Subscribe to an event type
   * @param {string} eventType - The event type to subscribe to
   * @param {function} handler - The event handler
   */
  subscribe(eventType, handler) {
    if (!this.subscribers.has(eventType)) {
      this.subscribers.set(eventType, new Set());
    }
    this.subscribers.get(eventType).add(handler);

    // If we have an active client, subscribe immediately
    if (this.client) {
      try {
        this.client.subscribe(eventType, handler);
        console.log('Subscribed to event:', {
          eventType,
          subscriberCount: this.subscribers.get(eventType).size,
          timestamp: new Date().toISOString()
        });
      } catch (error) {
        console.error('Error subscribing to event:', {
          error: error.message,
          eventType,
          timestamp: new Date().toISOString()
        });
      }
    }

    return () => this.unsubscribe(eventType, handler);
  }

  /**
   * Unsubscribe from an event type
   * @param {string} eventType - The event type to unsubscribe from
   * @param {function} handler - The event handler to remove
   */
  unsubscribe(eventType, handler) {
    const handlers = this.subscribers.get(eventType);
    if (handlers) {
      handlers.delete(handler);
      
      // Clean up empty event type sets
      if (handlers.size === 0) {
        this.subscribers.delete(eventType);
      }
      
      console.log('Unsubscribed from event:', {
        eventType,
        remainingHandlers: handlers.size,
        timestamp: new Date().toISOString()
      });
    }

    if (this.client) {
      try {
        this.client.unsubscribe(eventType, handler);
      } catch (error) {
        console.error('Error unsubscribing from event:', {
          error: error.message,
          eventType,
          timestamp: new Date().toISOString()
        });
      }
    }
  }

  /**
   * Get the current SSE client
   * @returns {SSEClient|null} The current SSE client
   */
  getClient() {
    return this.client;
  }

  /**
   * Clean up all resources
   */
  cleanup() {
    console.log('Starting SSE manager cleanup:', {
      hasClient: !!this.client,
      subscriberCount: this.subscribers.size,
      timestamp: new Date().toISOString()
    });

    if (this.client) {
      try {
        // First close the client connection
        this.client.close();
        
        // Then clean up all subscriptions
        this.subscribers.forEach((handlers, eventType) => {
          handlers.forEach(handler => {
            try {
              this.client.unsubscribe(eventType, handler);
            } catch (error) {
              console.error('Error cleaning up subscription:', {
                error: error.message,
                eventType,
                timestamp: new Date().toISOString()
              });
            }
          });
        });
        
        this.client = null;
      } catch (error) {
        console.error('Error during SSE client cleanup:', {
          error: error.message,
          timestamp: new Date().toISOString()
        });
      }
    }
    
    this.locationId = null;
    this.subscribers.clear();
    
    console.log('SSE manager cleanup completed', {
      timestamp: new Date().toISOString()
    });
  }
}

export default SSEManager;

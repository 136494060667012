import React, { createContext, useState, useContext, useEffect } from 'react';

const DateSettingsContext = createContext();

export const DateSettingsProvider = ({ children }) => {
  const [dateSettings, setDateSettings] = useState({
    mode: 'current', // 'current' or 'manual'
    manualDate: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    const storedSettings = localStorage.getItem('dateSettings');
    if (storedSettings) {
      setDateSettings(JSON.parse(storedSettings));
    }
  }, []);

  const updateDateSettings = (newSettings) => {
    setDateSettings(newSettings);
    localStorage.setItem('dateSettings', JSON.stringify(newSettings));
  };

  return (
    <DateSettingsContext.Provider value={{ dateSettings, updateDateSettings }}>
      {children}
    </DateSettingsContext.Provider>
  );
};

export const useDateSettings = () => {
  const context = useContext(DateSettingsContext);
  if (!context) {
    throw new Error('useDateSettings must be used within a DateSettingsProvider');
  }
  return context;
};

// Color utilities for zone visualization
import { TYPE_GROUPS } from '../zoneTypes';
import ZONE_SPECIFIC_COLORS from './zoneSpecificColors';

// Get gradient background for zones based on type and occupancy
export const getZoneGradient = (theme, type, occupancy, zoneId) => {
  // If we have a specific color for this zone, use it
  if (zoneId && ZONE_SPECIFIC_COLORS[zoneId]) {
    const baseColor = ZONE_SPECIFIC_COLORS[zoneId];
    // Apply a slight gradient effect based on the base color
    return `linear-gradient(135deg, 
      ${baseColor} 0%, 
      ${adjustOpacity(baseColor, 0.85)} 100%)`;
  }
  
  // Otherwise, fall back to the type-based colors
  const baseColors = getBaseColorsForType(theme, type);
  
  // Determine saturation based on occupancy
  const saturation = Math.min(0.8 + (occupancy / 200), 1);
  const lightness = Math.max(0.6 - (occupancy / 250), 0.4);
  
  // Create gradient with adjusted saturation
  return `linear-gradient(135deg, 
    ${adjustColor(baseColors.light, saturation, lightness)} 0%, 
    ${adjustColor(baseColors.dark, saturation, lightness)} 100%)`;
};

// Get border color for zones based on type and selection state
export const getZoneBorderColor = (theme, type, isSelected, zoneId) => {
  if (isSelected) {
    return theme.colors.primary.main;
  }
  
  // If we have a specific color for this zone, use a darker version for border
  if (zoneId && ZONE_SPECIFIC_COLORS[zoneId]) {
    return adjustOpacity(ZONE_SPECIFIC_COLORS[zoneId], 0.7); // Darker version for border
  }
  
  const baseColors = getBaseColorsForType(theme, type);
  return baseColors.border;
};

// Get base colors for different zone types
const getBaseColorsForType = (theme, type) => {
  // Customer zones
  if (type === 'new_green_customer') {
    return {
      light: theme.colors.success[300],
      dark: theme.colors.success[600],
      border: theme.colors.success.main,
    };
  }
  if (type === 'bakker_customer') {
    return {
      light: theme.colors.success[400],
      dark: theme.colors.success[700],
      border: theme.colors.success[600],
    };
  }
  if (type === 'van_gelder_customer') {
    return {
      light: theme.colors.success[200],
      dark: theme.colors.success[500],
      border: theme.colors.success[400],
    };
  }
  
  // Plum tomato zones
  if (type === 'a_fijn_plum') {
    return {
      light: theme.colors.secondary[200],
      dark: theme.colors.secondary[500],
      border: theme.colors.secondary[400],
    };
  }
  if (type === 'b_plum') {
    return {
      light: theme.colors.secondary[300],
      dark: theme.colors.secondary[600],
      border: theme.colors.secondary[500],
    };
  }
  if (type === '5_pack_plum' || type === '6_pack_plum') {
    return {
      light: theme.colors.secondary[400],
      dark: theme.colors.secondary[700],
      border: theme.colors.secondary[600],
    };
  }
  
  // Round tomato zones
  if (type === 'a_grof_round' || type === 'a_grof_plus_round') {
    return {
      light: theme.colors.primary[200],
      dark: theme.colors.primary[500],
      border: theme.colors.primary[400],
    };
  }
  if (type === 'b_round') {
    return {
      light: theme.colors.primary[300],
      dark: theme.colors.primary[600],
      border: theme.colors.primary[500],
    };
  }
  if (type === 'a_fijn_round') {
    return {
      light: theme.colors.primary[400],
      dark: theme.colors.primary[700],
      border: theme.colors.primary[600],
    };
  }
  if (type === 'a_grof_light_round' || type === 'a_grof_light') {
    return {
      light: theme.colors.info[200],
      dark: theme.colors.info[500],
      border: theme.colors.info[400],
    };
  }
  if (type === 'a_grof_red' || type === 'a_fijn_middle') {
    return {
      light: theme.colors.error[200],
      dark: theme.colors.error[500],
      border: theme.colors.error[400],
    };
  }
  
  // Special zones
  if (type === 'fridge') {
    return {
      light: theme.colors.info[100],
      dark: theme.colors.info[400],
      border: theme.colors.info[300],
    };
  }
  if (type === 'cherry' || type === 'sales') {
    return {
      light: theme.colors.warning[200],
      dark: theme.colors.warning[500],
      border: theme.colors.warning[400],
    };
  }
  if (type === 'oversorteer') {
    return {
      light: theme.colors.gray[300],
      dark: theme.colors.gray[600],
      border: theme.colors.gray[500],
    };
  }
  if (type === 'dock') {
    return {
      light: theme.colors.warning[100],
      dark: theme.colors.warning[400],
      border: theme.colors.warning[300],
    };
  }
  if (type === 'logistics') {
    return {
      light: theme.colors.info[100],
      dark: theme.colors.info[300],
      border: theme.colors.info[200],
    };
  }
  
  // General (multi-purpose) zones
  if (type === 'general') {
    return {
      light: theme.colors.background.paper,
      dark: theme.colors.gray[300],
      border: theme.colors.border.main,
    };
  }
  
  // Default fallback
  return {
    light: theme.colors.gray[200],
    dark: theme.colors.gray[500],
    border: theme.colors.gray[400],
  };
};

// Helper function to adjust color saturation and lightness
const adjustColor = (color, saturation, lightness) => {
  // This is a simplified approach. In a real application, you'd
  // want to convert hex to HSL, adjust, and convert back
  return color;
};

// Helper function to adjust opacity of an rgb color
const adjustOpacity = (color, opacity) => {
  if (color.startsWith('rgb(')) {
    // Extract RGB values
    const rgbValues = color.match(/\d+/g);
    if (rgbValues && rgbValues.length >= 3) {
      return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;
    }
  }
  return color;
};

// Get color for legend items based on zone type group
export const getLegendColors = (theme) => {
  return {
    customer: {
      light: theme.colors.success[300],
      dark: theme.colors.success[600],
    },
    plum: {
      light: theme.colors.secondary[300],
      dark: theme.colors.secondary[600],
    },
    round: {
      light: theme.colors.primary[300],
      dark: theme.colors.primary[600],
    },
    special: {
      light: theme.colors.info[300],
      dark: theme.colors.info[600],
    },
  };
};

// Get background color for pallet positions based on tomato type
export const getPalletPositionColor = (theme, tomatoType, ripeness = 'normal') => {
  // Determine base color by type group
  let baseColor;
  
  if (TYPE_GROUPS.CUSTOMER.includes(tomatoType)) {
    baseColor = theme.colors.success;
  } else if (TYPE_GROUPS.PLUM.includes(tomatoType)) {
    baseColor = theme.colors.secondary;
  } else if (TYPE_GROUPS.ROUND.includes(tomatoType)) {
    baseColor = theme.colors.primary;
  } else if (TYPE_GROUPS.SPECIAL.includes(tomatoType)) {
    baseColor = theme.colors.info;
  } else {
    baseColor = theme.colors.gray;
  }
  
  // Adjust based on ripeness
  switch (ripeness) {
    case 'green':
      return baseColor[200];
    case 'turning':
      return baseColor[400];
    case 'ripe':
      return baseColor[600]; 
    case 'overripe':
      return theme.colors.error.main;
    default: // normal
      return baseColor.main;
  }
};

import { useState, useCallback, useMemo } from 'react';

export const useDashboardFilters = ({ pallets = [], filters = {}, isLoading = true }) => {
  const [selectedPallets, setSelectedPallets] = useState(new Set());
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const filterPallets = useCallback((palletsToFilter) => {
    if (isLoading || !Array.isArray(palletsToFilter)) return [];
    
    let filtered = [...palletsToFilter];

    if (filters?.palletStatus) {
      filtered = filtered.filter(pallet => 
        pallet?.status === filters.palletStatus
      );
    }

    if (filters?.tomatoType) {
      filtered = filtered.filter(pallet => 
        pallet?.tomatoType === filters.tomatoType
      );
    }

    if (filters?.boxType) {
      filtered = filtered.filter(pallet => 
        pallet?.boxType === filters.boxType
      );
    }

    if (filters?.dateRange) {
      const today = new Date();
      const startDate = new Date(today);
      
      switch (filters.dateRange) {
        case 'today':
          startDate.setHours(0, 0, 0, 0);
          break;
        case 'week':
          startDate.setDate(today.getDate() - 7);
          break;
        case 'month':
          startDate.setMonth(today.getMonth() - 1);
          break;
        default:
          break;
      }

      filtered = filtered.filter(pallet => {
        if (!pallet?.addedDate) return false;
        const palletDate = new Date(pallet.addedDate);
        return palletDate >= startDate && palletDate <= today;
      });
    }

    return filtered;
  }, [filters, isLoading]);

  const sortPallets = useCallback((palletsToSort) => {
    if (!Array.isArray(palletsToSort) || !sortConfig.key) return palletsToSort;

    return [...palletsToSort].sort((a, b) => {
      if (!a || !b) return 0;
      
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue === undefined || bValue === undefined) return 0;

      if (typeof aValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [sortConfig]);

  const handleSelectPallet = useCallback((palletId) => {
    if (isLoading) return;
    
    setSelectedPallets(prev => {
      const newSet = new Set(prev);
      if (newSet.has(palletId)) {
        newSet.delete(palletId);
      } else {
        newSet.add(palletId);
      }
      return newSet;
    });
  }, [isLoading]);

  const handleSelectAll = useCallback((checked) => {
    if (isLoading || !Array.isArray(pallets)) {
      setSelectedPallets(new Set());
      return;
    }

    if (checked) {
      const filteredPallets = filterPallets(pallets);
      const allIds = filteredPallets
        .filter(pallet => pallet && pallet.id)
        .map(pallet => pallet.id);
      setSelectedPallets(new Set(allIds));
    } else {
      setSelectedPallets(new Set());
    }
  }, [pallets, filterPallets, isLoading]);

  const resetSelection = useCallback(() => {
    setSelectedPallets(new Set());
  }, []);

  const requestSort = useCallback((key) => {
    if (isLoading) return;
    
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, [isLoading]);

  const filteredAndSortedPallets = useMemo(() => {
    if (isLoading) return [];
    
    const filteredPallets = filterPallets(pallets);
    return sortPallets(filteredPallets);
  }, [pallets, filterPallets, sortPallets, isLoading]);

  const selectAllChecked = useMemo(() => {
    if (isLoading || !Array.isArray(filteredAndSortedPallets) || filteredAndSortedPallets.length === 0) {
      return false;
    }
    return filteredAndSortedPallets.length > 0 && 
           selectedPallets.size === filteredAndSortedPallets.length;
  }, [filteredAndSortedPallets, selectedPallets, isLoading]);

  return {
    filteredAndSortedPallets,
    handleSelectPallet,
    handleSelectAll,
    requestSort,
    selectedPallets,
    selectAllChecked,
    resetSelection,
    sortConfig,
    isLoading
  };
};

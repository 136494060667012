/**
 * OrderCard Styles
 * 
 * Redesigned for a more space-efficient layout with collapsed/expanded state
 * and more professional appearance.
 */

import styled, { css } from 'styled-components';

// Function to determine color based on progress percentage
const getProgressColor = (percentage) => {
  if (percentage <= 40) return '#4caf50'; // Green (unripe)
  if (percentage <= 80) return '#ff9800'; // Orange (ripening)
  return '#e53935'; // Red (ripe)
};

// Modernized card with hover expansion effect
export const Card = styled.div`
  /* Base card styling */
  position: relative;
  padding: ${({ theme, isExpanded }) => 
    isExpanded ? theme.spacing.md : `${theme.spacing.xs} ${theme.spacing.sm}`};
  height: ${({ isExpanded }) => isExpanded ? 'auto' : '40px'};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  transition: all 0.3s ease;
  overflow: hidden;
  
  /* Priority indicator strip */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: ${({ priority, readyForShipping, theme }) => {
      if (readyForShipping) return theme.colors.warning.main; // Orange for ready state
      switch (priority) {
        case 'urgent':
          return theme.colors.error.main;
        case 'low':
          return theme.colors.gray[300];
        default:
          return theme.colors.primary.main;
      }
    }};
    transition: ${({ theme }) => theme.transitions.default};
  }
  
  /* Status-based styling */
  ${({ completed, is_processing, theme }) => {
    // Completed orders
    if (completed) {
      return `
        opacity: 0.8;
        &::before {
          background-color: ${theme.colors.success.main};
        }
      `;
    }
    
    // Processing orders
    if (is_processing) {
      return `
        border-color: ${theme.colors.primary.main};
        box-shadow: 0 0 0 1px ${theme.colors.primary.alpha20};
      `;
    }
    
    return '';
  }}
  
  /* Expanded state on hover */
  &:hover {
    border-color: ${({ priority, theme }) => {
      switch (priority) {
        case 'urgent':
          return theme.colors.error.main;
        case 'low':
          return theme.colors.gray[300];
        default:
          return theme.colors.primary.main;
      }
    }};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
`;

// Compact content layout 
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

// Enhanced header with progress bar
export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ isExpanded, theme }) => 
    isExpanded ? theme.spacing.sm : '0'};
  padding-bottom: ${({ isExpanded, theme }) => 
    isExpanded ? theme.spacing.sm : '0'};
  border-bottom: ${({ isExpanded, theme }) => 
    isExpanded ? `1px solid ${theme.colors.border.light}` : 'none'};
  position: relative;
  
  h4 {
    ${({ theme }) => theme.typography.h4};
    margin: 0;
    color: ${({ theme }) => theme.colors.text.primary};
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

// Tomato-inspired progress bar
export const TomatoProgressBar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 4px;
  width: 100px;
  max-width: 30%;
  background-color: ${({ theme }) => theme.colors.background.darker};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing.sm};

  div {
    height: 100%;
    width: ${({ percentage }) => `${percentage}%`};
    background-color: ${({ percentage }) => getProgressColor(percentage)};
    transition: width 0.6s ease, background-color 0.6s ease;
  }
`;

// Compact information row
export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
`;

// Status section (collapsible)
export const StatusSection = styled.div`
  display: ${({ isExpanded }) => isExpanded ? 'flex' : 'none'};
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.xs};
  transition: all 0.3s ease;
`;

// Condensed badge
export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme, compact }) => 
    compact 
      ? `${theme.spacing.xs} ${theme.spacing.xs}`
      : `${theme.spacing.xs} ${theme.spacing.sm}`
  };
  border-radius: ${({ theme }) => theme.borderRadius.full};
  font-size: ${({ theme, compact }) => 
    compact 
      ? '0.65rem'
      : theme.typography.caption.fontSize
  };
  font-weight: 500;
  line-height: 1;
  background-color: ${({ status, theme }) => 
    status === 'completed' ? theme.colors.success.main : theme.colors.background.accent};
  color: ${({ status, theme }) => 
    status === 'completed' ? theme.colors.common.white : theme.colors.text.primary};
`;

// Compact priority badge
export const PriorityBadge = styled(Badge)`
  cursor: pointer;
  background-color: ${({ priority, processStage, theme }) => {
    if (processStage === 'ready_for_shipping') return theme.colors.warning.main;
    if (processStage === 'shipped') return theme.colors.success.main;
    switch (priority) {
      case 'urgent':
        return theme.colors.error.main;
      case 'low':
        return theme.colors.gray[300];
      default:
        return theme.colors.primary.main;
    }
  }};
  color: ${({ theme }) => theme.colors.common.white};
  transition: ${({ theme }) => theme.transitions.default};

  /* Hover effect */
  &:hover {
    transform: scale(1.05);
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }

  /* Urgent priority animation - only in expanded state */
  ${({ priority, theme, isExpanded }) => priority === 'urgent' && isExpanded && `
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 ${theme.colors.error.alpha};
      }
      70% {
        box-shadow: 0 0 0 6px transparent;
      }
      100% {
        box-shadow: 0 0 0 0 transparent;
      }
    }
  `}
`;

// Compact processing badge
export const ProcessingBadge = styled(Badge)`
  background-color: ${({ theme }) => theme.colors.primary.alpha20};
  color: ${({ theme }) => theme.colors.primary.dark};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

// Operator badge
export const OperatorBadge = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.background.paper};
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
`;

// Collapsible details section
export const DetailsSection = styled.div`
  display: ${({ isExpanded }) => isExpanded ? 'grid' : 'none'};
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
  transition: all 0.3s ease;
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
`;

// Streamlined details layout
export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};

  p {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
    font-size: 0.8rem;

    svg {
      color: ${({ theme }) => theme.colors.text.secondary};
      flex-shrink: 0;
      width: 14px;
      height: 14px;
    }
    
    strong {
      color: ${({ theme }) => theme.colors.text.primary};
      font-weight: 600;
    }
  }
`;

// Minimal metrics display
export const MetricsRow = styled.div`
  display: ${({ isExpanded }) => isExpanded ? 'flex' : 'none'};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.background.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.sm};
  
  div {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    
    span:first-child {
      font-size: 0.7rem;
      color: ${({ theme }) => theme.colors.text.secondary};
    }

    span:last-child {
      font-size: 0.85rem;
      color: ${({ theme }) => theme.colors.text.primary};
      font-weight: 600;
    }
  }
`;

// Collapsible pallets section
export const PalletsSection = styled.div`
  display: ${({ isExpanded }) => isExpanded ? 'block' : 'none'};
  margin-top: ${({ theme }) => theme.spacing.sm};
  
  h4 {
    ${({ theme }) => theme.typography.subtitle2};
    color: ${({ theme }) => theme.colors.text.primary};
    margin: 0 0 ${({ theme }) => theme.spacing.xs} 0;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    font-size: 0.8rem;
  }
`;

// More compact scheduled pallets list
export const ScheduledPallets = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.colors.background.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  max-height: 80px;
  overflow-y: auto;
`;

// Ultra-compact pallet item
export const PalletItem = styled.div`
  flex: 0 0 auto;
  padding: ${({ theme }) => `2px ${theme.spacing.xs}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: 0.65rem;
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${({ processStage, theme }) => {
    switch (processStage) {
      case 'Processing':
        return theme.colors.primary.alpha10;
      case 'Processed':
        return theme.colors.success.alpha10;
      default:
        return theme.colors.background.default;
    }
  }};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  color: ${({ theme }) => theme.colors.text.secondary};
  
  /* Status indicator dot */
  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ processStage, theme }) => {
      switch (processStage) {
        case 'Processing':
          return theme.colors.primary.main;
        case 'Processed':
          return theme.colors.success.main;
        default:
          return theme.colors.gray[400];
      }
    }};
  }
`;

// Modern action buttons
export const Actions = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

// Enhanced action button
export const ActionBtn = styled.button`
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  background: none;
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'ship':
        return theme.colors.primary.main;
      case 'edit':
        return theme.colors.secondary.main;
      case 'delete':
        return theme.colors.error.main;
      default:
        return theme.colors.text.primary;
    }
  }};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
    transform: translateY(-1px);
  }
  
  &:active {
    transform: translateY(0);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

// Toggle button for expandable sections
export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.sm};
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  svg {
    transition: transform 0.3s ease;
    transform: ${({ expanded }) => expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

// Expandable section with smooth transitions
export const ExpandableSection = styled.div`
  max-height: ${({ expanded }) => expanded ? '500px' : '0'};
  opacity: ${({ expanded }) => expanded ? '1' : '0'};
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: ${({ expanded, theme }) => expanded ? theme.spacing.sm : '0'};
  padding-left: ${({ theme }) => theme.spacing.md};
  border-left: 2px solid ${({ theme }) => theme.colors.border.light};
`;

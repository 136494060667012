import React, { useState, useEffect, useCallback } from 'react';
import kleinpakSSEManager from '../../../../lib/services/sse/KleinpakSSEManager';
import { MapPin, CheckCircle, QrCode, ArrowRight, AlertCircle, ThumbsUp, Award, Trophy, LayoutGrid, Compass } from 'lucide-react';
import { api } from '@lib/api';
import QRScanner from '@features/shared/components/QRScanner';
import storageAssignmentService from '@features/map/services/storageAssignmentService';
import usePersistentState from '@lib/hooks/usePersistentState';
import ZoneHighlightMap from './components/ZoneHighlightMap';
import { ZONE_MAP } from '@features/map/constants/zoneConfig.v2';
import { storageToDisplayIndex, getZoneOrientation, ORIENTATIONS } from '@features/map/constants/zoneOrientations';
import { transformToActualRowIndex } from '@lib/utils/zoneTransformUtils';
import {
  // Animation keyframes
  fadeIn,
  bounceIn,
  spin,
  
  // Modal components
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  
  // Zone view components
  ZoneInstructionCard,
  ZoneHighlight,
  MapContainer,
  ZoneInstructions,
  StepIndicator,
  StepDot,
  
  // Row visualization components
  RowsGrid,
  RowCard,
  RowIcon,
  RowName,
  RowDetails,
  OrientationIndicator,
  
  // Enhanced map components
  EnhancedMapContainer,
  RowLegend,
  LegendItem,
  LegendColor,
  
  // Pallet components
  PalletGrid,
  PalletCard,
  ScannedOverlay,
  PalletIdLarge,
  StatusBadge,
  
  // Scanner components
  ScanSection,
  ScanButton,
  
  // Celebration components
  CelebrationOverlay,
  CelebrationContent,
  CelebrationTitle,
  CelebrationMessage,
  TrophyIcon,
  CompletionButton,
  
  // Step constants
  STEPS
} from './RowLocationWorkflow.styles';

// Helper function to get a formatted display name for process stages
const getProcessStageDisplay = (processStage) => {
  if (!processStage) return 'Ready';
  
  // Convert camelCase or snake_case to Title Case with spaces
  return processStage
    .replace(/_/g, ' ')  // Replace underscores with spaces
    .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
    .replace(/^\w/, c => c.toUpperCase())  // Capitalize first letter
    .trim();  // Remove any extra spaces
};

// Helper functions for zone and row handling
const normalizeZoneName = (name) => {
  if (!name) return '';
  
  // If it's already in the correct format, return as is
  if (/^Zone \d+(\.\d+)?$/i.test(name)) {
    // Make sure "Zone" is capitalized and there's exactly one space after it
    return name.replace(/zone\s+/i, 'Zone ');
  }
  
  // Try to extract zone number from different formats (zone-1, zone1, zone_1)
  const match = name.toLowerCase().match(/zone[\s\-_]?(\d+(?:\.\d+)?)/);
  if (match) {
    // Ensure we return a consistently formatted string: "Zone X"
    // We use exactly one space between "Zone" and the number
    return `Zone ${match[1]}`;
  }
  
  // For non-standard zone names, return as is
  return name;
};

// Get the actual row number considering zone configuration and orientation
const getDisplayRowNumber = (rowIndex, zoneId) => {
  const zoneConfig = ZONE_MAP[zoneId];
  if (!zoneConfig) return rowIndex + 1;

  // Get total rows based on zone configuration
  let totalRows = zoneConfig.dimensions.rows;
  
  // Check for row view configuration which might invert dimensions
  if (zoneConfig.rowViewConfig?.invertRowsAndColumns) {
    totalRows = zoneConfig.rowViewConfig.dimensions.rows;
  }

  // Transform the row index based on zone orientation
  const displayIndex = storageToDisplayIndex(rowIndex, zoneId, totalRows);
  return displayIndex + 1;
};

// Get orientation display text
const getOrientationDisplay = (orientation) => {
  const orientationMap = {
    [ORIENTATIONS.EAST]: 'Facing East',
    [ORIENTATIONS.WEST]: 'Facing West',
    [ORIENTATIONS.NORTH]: 'Facing North',
    [ORIENTATIONS.SOUTH]: 'Facing South'
  };
  return orientationMap[orientation] || 'Unknown Orientation';
};

// Sort rows based on zone orientation and inversion configuration
const sortRowsByOrientation = (rows, zoneId) => {
  // Input validation
  if (!rows || !Array.isArray(rows) || !rows.length) {
    return [];
  }
  
  if (!zoneId) {
    console.error('sortRowsByOrientation: No zoneId provided');
    return [...rows].sort((a, b) => a.rowIndex - b.rowIndex);
  }
  
  // Clone the array to avoid mutating the original
  const sortedRows = [...rows];
  
  // Get the zone configuration
  const zoneConfig = ZONE_MAP[zoneId];
  if (!zoneConfig) {
    return sortedRows.sort((a, b) => a.rowIndex - b.rowIndex);
  }
  
  // Check if this zone has inverted rows and columns
  const hasInvertedRowsAndColumns = zoneConfig.rowViewConfig?.invertRowsAndColumns || false;
  
  // Get the zone orientation
  const orientation = getZoneOrientation(zoneId);
  
  // Log the sorting parameters for debugging
  console.log(`Sorting rows for zone ${zoneId}:`, {
    orientation,
    hasInvertedRowsAndColumns,
    rowCount: rows.length
  });
  
  // INVERTED LOGIC: For east-facing zones, we need to reverse the order of rows
  if (orientation === ORIENTATIONS.EAST) {
    return sortedRows.sort((a, b) => b.rowIndex - a.rowIndex);
  }
  
  // For inverted zones, sort based on the visual row index
  if (hasInvertedRowsAndColumns) {
    return sortedRows.sort((a, b) => {
      // Input validation
      if (a.rowIndex === undefined || b.rowIndex === undefined) {
        return 0;
      }
      
      // Get the visual row indices
      const aVisualIndex = storageToDisplayIndex(a.rowIndex, zoneId, zoneConfig.dimensions.rows);
      const bVisualIndex = storageToDisplayIndex(b.rowIndex, zoneId, zoneConfig.dimensions.rows);
      
      // Sort by visual index
      return aVisualIndex - bVisualIndex;
    });
  }
  
  // For other orientations, maintain the default order
  return sortedRows.sort((a, b) => a.rowIndex - b.rowIndex);
};

// Get the actual row dimensions considering zone configuration
const getRowDimensions = (zoneId) => {
  const zoneConfig = ZONE_MAP[zoneId];
  if (!zoneConfig) return { rows: 0, cols: 0 };

  // If zone has row view config with inverted dimensions, use those
  if (zoneConfig.rowViewConfig?.invertRowsAndColumns) {
    return zoneConfig.rowViewConfig.dimensions;
  }

  // For custom layouts, calculate total rows from segments
  if (zoneConfig.customLayout && zoneConfig.segments) {
    const totalRows = zoneConfig.segments.reduce((sum, segment) => sum + segment.height, 0);
    return { rows: totalRows, cols: zoneConfig.dimensions.cols };
  }

  return zoneConfig.dimensions;
};

/**
 * RowLocationWorkflow - Enhanced version of PalletLocationWorkflow for scheduled rows
 * This component extends the standard zone navigation to include row-specific visualization
 */
const RowLocationWorkflow = ({ 
  isOpen, 
  onClose, 
  zoneName, 
  rows, // Array of row objects to highlight
  pallets, 
  order,
  locationId,
  onScanComplete,
  // New props specific to row-based navigation
  selectedRow
}) => {
  // Persist current state across refreshes with a unique key based on order and zone
  const persistenceKey = `row_workflow_${locationId}_${order?.id}_${zoneName}`;
  
  // Persisted state
  const [currentStep, setCurrentStep] = usePersistentState(
    `${persistenceKey}_step`, 
    STEPS.SHOW_ZONE
  );
  const [scannedPalletIds, setScannedPalletIds] = usePersistentState(
    `${persistenceKey}_scanned_pallets`, 
    []
  );
  const [showCompletionCelebration, setShowCompletionCelebration] = usePersistentState(
    `${persistenceKey}_completion`, 
    false
  );
  
  // Track assignment status of all pallets
  const [palletAssignments, setPalletAssignments] = useState({});
  
  // Local state
  const [showScanner, setShowScanner] = useState(false);
  const [recentlyScannedId, setRecentlyScannedId] = useState(null);
  const [activeRow, setActiveRow] = useState(selectedRow ? selectedRow.id : null);
  
  // Reset step when modal is opened with a new zone/order
  useEffect(() => {
    if (isOpen && !currentStep) {
      setCurrentStep(STEPS.SHOW_ZONE);
      setShowScanner(false);
    }
  }, [isOpen, currentStep, setCurrentStep]);
  
  // Check if all pallets are scanned
  const allPalletsScanned = usePersistentState(
    `${persistenceKey}_all_scanned`,
    false
  );
  
  // Handler for pallet assignment updates
  const handlePalletAssignmentUpdate = useCallback((data) => {
    if (!data || (!data.palletIds && !data.palletId)) return;
    
    const palletIds = data.palletIds || [data.palletId];
    const orderId = String(data.orderId);
    
    console.log(`RowLocationWorkflow received assignment update for ${palletIds.length} pallets to order ${orderId}`);
    
    // Update our record of pallet assignments
    setPalletAssignments(prev => {
      const updated = { ...prev };
      palletIds.forEach(palletId => {
        updated[palletId] = {
          orderId,
          processStage: 'Processing' 
        };
      });
      return updated;
    });
  }, []);
  
  // Handler for pallet updates
  const handlePalletUpdate = useCallback((data) => {
    if (!data || !data.pallets || !Array.isArray(data.pallets)) return;
    
    console.log(`RowLocationWorkflow received updates for ${data.pallets.length} pallets`);
    
    // Update our records with the latest pallet information
    setPalletAssignments(prev => {
      const updated = { ...prev };
      
      data.pallets.forEach(pallet => {
        if (!pallet.id) return;
        
        const palletId = String(pallet.id);
        const orderId = pallet.orderId || pallet.order_id;
        const processStage = pallet.processStage || 'Unprocessed';
        
        updated[palletId] = {
          ...updated[palletId],
          orderId: orderId ? String(orderId) : null,
          processStage
        };
      });
      
      return updated;
    });
  }, []);
  
  // Subscribe to SSE updates
  useEffect(() => {
    if (!isOpen || !locationId) return;
    
    // Initialize the SSE connection
    kleinpakSSEManager.initialize(locationId);
    
    // Subscribe to assignment updates
    const assignmentSubscriptionId = kleinpakSSEManager.subscribe('assignmentUpdate', handlePalletAssignmentUpdate);
    
    // Subscribe to pallet updates
    const palletSubscriptionId = kleinpakSSEManager.subscribe('palletUpdate', handlePalletUpdate);
    
    // Initialize palletAssignments from existing pallets data
    setPalletAssignments(prev => {
      const initialAssignments = { ...prev };
      
      if (pallets && Array.isArray(pallets)) {
        pallets.forEach(pallet => {
          if (!pallet.id) return;
          
          initialAssignments[pallet.id] = {
            orderId: pallet.order_id || pallet.orderId || null,
            processStage: pallet.processStage || 'Unprocessed'
          };
        });
      }
      
      return initialAssignments;
    });
    
    // Clean up subscriptions
    return () => {
      kleinpakSSEManager.unsubscribe('assignmentUpdate', assignmentSubscriptionId);
      kleinpakSSEManager.unsubscribe('palletUpdate', palletSubscriptionId);
    };
  }, [isOpen, locationId, pallets, handlePalletAssignmentUpdate, handlePalletUpdate]);
  
  // Determine scan status and assignment status for each pallet
  const palletsWithStatus = pallets.map(pallet => {
    const palletId = String(pallet.id);
    const isScanned = scannedPalletIds.includes(palletId);
    const assignment = palletAssignments[palletId] || {};
    
    // Check if assigned to current order
    const isAssignedToCurrentOrder = assignment.orderId === String(order?.id) && 
                                   assignment.processStage === 'Processing';
    
    // Check if assigned to a different order
    const isAssignedToOtherOrder = assignment.orderId && 
                                 assignment.orderId !== String(order?.id) && 
                                 assignment.processStage === 'Processing';
    
    return {
      ...pallet,
      isScanned,
      isAssignedToCurrentOrder,
      isAssignedToOtherOrder,
      processStage: assignment.processStage || pallet.processStage || 'Unprocessed'
    };
  });
  
  // Update the all pallets scanned status
  useEffect(() => {
    if (pallets.length > 0 && scannedPalletIds.length >= pallets.length) {
      setShowCompletionCelebration(true);
    }
  }, [pallets.length, scannedPalletIds.length, setShowCompletionCelebration]);
  
  const handleNext = () => {
    if (currentStep === STEPS.SHOW_ZONE) {
      setCurrentStep(STEPS.SHOW_ROWS);
    } else if (currentStep === STEPS.SHOW_ROWS) {
      setCurrentStep(STEPS.LIST_PALLETS);
    } else if (currentStep === STEPS.LIST_PALLETS) {
      setCurrentStep(STEPS.SCAN_PALLET);
    }
  };
  
  const handleBack = () => {
    if (currentStep === STEPS.SHOW_ROWS) {
      setCurrentStep(STEPS.SHOW_ZONE);
    } else if (currentStep === STEPS.LIST_PALLETS) {
      setCurrentStep(STEPS.SHOW_ROWS);
    } else if (currentStep === STEPS.SCAN_PALLET) {
      setCurrentStep(STEPS.LIST_PALLETS);
    }
  };
  
  const handleStartScan = () => {
    // Completely disable SSE before activating scanner to free up resources
    kleinpakSSEManager.enableScanningMode();
    console.log('RowLocationWorkflow: Disabled SSE for scanning mode');
    setShowScanner(true);
  };
  
  const handleScanResult = async (scanData) => {
    setShowScanner(false);
    
    // Re-enable SSE connection as we're done with the scanner
    setTimeout(() => {
      kleinpakSSEManager.disableScanningMode();
      console.log('RowLocationWorkflow: Re-enabled SSE after successful scan');
    }, 500); // Small delay to ensure UI updates first
    
    try {
      if (!scanData || !scanData.id) {
        throw new Error('Invalid scan data');
      }
      
      // Check if this pallet is already scanned
      if (scannedPalletIds.includes(String(scanData.id))) {
        alert(`Pallet #${scanData.id} has already been scanned!`);
        return;
      }
      
      // Check if this pallet is allowed for this order
      const isPalletAllowed = order.allowed_pallet_ids 
        ? (order.allowed_pallet_ids.includes(scanData.id) || 
           order.allowed_pallet_ids.includes(String(scanData.id)))
        : true;
      
      if (!isPalletAllowed) {
        throw new Error(`Pallet #${scanData.id} is not allowed for this order`);
      }
      
      // Check if this pallet is from the correct zone - enhanced checking
      const isPalletInZone = pallets.some(p => {
        // Convert both IDs to strings for reliable comparison
        return String(p.id) === String(scanData.id) || p.id === scanData.id;
      });
      
      if (!isPalletInZone) {
        throw new Error(`Pallet #${scanData.id} is not located in zone ${zoneName}`);
      }
      
      // All checks passed, proceed with assigning the pallet and updating its status
      // 1. Assign pallet to order
      await api.post(`/api/pallets/${scanData.id}/assign`, {
        orderId: order.id,
        locationId,
        processStage: 'in_processing'
      });
      
      // 2. Update pallet process stage
      await api.patch(`/api/pallets/${scanData.id}`, {
        processStage: 'in_processing',
        locationId
      });
      
      // 3. Remove from storage position if it's stored
      await storageAssignmentService.removePalletFromStorage(
        scanData.id, 
        'Assigned to order and processing'
      );
      
      // 4. Use kleinpakSSEManager to process the assignment (this will emit the legacy event too)
      kleinpakSSEManager.processPalletAssignment({
        palletId: scanData.id,
        orderId: order.id,
        processStage: 'Processing',
        zone: zoneName
      });
      
      console.log(`Pallet #${scanData.id} assigned to order #${order.id} in zone ${zoneName}`);
      
      // 5. Add to scanned pallets list
      setScannedPalletIds(prev => [...prev, String(scanData.id)]);
      setRecentlyScannedId(String(scanData.id));
      
      // 6. Call the parent component callback
      onScanComplete(scanData.id);
      
      // 7. Check if all pallets have been scanned
      const updatedScannedCount = scannedPalletIds.length + 1;
      if (updatedScannedCount >= pallets.length) {
        setTimeout(() => {
          setShowCompletionCelebration(true);
        }, 1000);
      }
    } catch (error) {
      console.error('Scan error:', error);
      alert(error.message || 'Error processing scan');
      // Keep the modal open to allow another attempt
    }
  };
  
  // Handle row selection and filter pallets for that row
  const handleRowSelect = (rowId) => {
    setActiveRow(rowId);
    
    // Get the selected row
    const selectedRow = rows.find(r => r.id === rowId);
    if (selectedRow) {
      // Log the selected row for debugging
      const normalizedZoneId = normalizeZoneName(zoneName).toLowerCase().replace(/\s+/g, '-');
      const zoneConfig = ZONE_MAP[normalizedZoneId];
      const hasInvertedRowsAndColumns = zoneConfig?.rowViewConfig?.invertRowsAndColumns || false;
      
      // Use the enhanced properties if available (from RowGroupedPallets)
      const displayRowIndex = selectedRow.displayRowIndex !== undefined ? 
        selectedRow.displayRowIndex : 
        (hasInvertedRowsAndColumns ? 
          storageToDisplayIndex(selectedRow.rowIndex, normalizedZoneId, zoneConfig.dimensions.rows) : 
          selectedRow.rowIndex);
      
      console.log(`Selected row ${rowId}:`, {
        rowIndex: selectedRow.rowIndex,
        displayRowIndex,
        actualRowIndex: selectedRow.actualRowIndex,
        displayNumber: getDisplayRowNumber(selectedRow.rowIndex, normalizedZoneId),
        zoneId: normalizedZoneId,
        hasInvertedRowsAndColumns: selectedRow.isInvertedZone || hasInvertedRowsAndColumns
      });
    }
    
    // When a row is selected, proceed to the next step
    if (currentStep === STEPS.SHOW_ROWS) {
      setCurrentStep(STEPS.LIST_PALLETS);
    }
  };
  
  // Reset when closed
  const handleClose = () => {
    onClose();
  };
  
  // Handle completion
  const handleCompletionContinue = () => {
    setShowCompletionCelebration(false);
    onClose();
  };
  
  // Render step indicator - note we have four steps now
  const renderStepIndicator = () => (
    <StepIndicator>
      <StepDot active={currentStep === STEPS.SHOW_ZONE} />
      <StepDot active={currentStep === STEPS.SHOW_ROWS} />
      <StepDot active={currentStep === STEPS.LIST_PALLETS} />
      <StepDot active={currentStep === STEPS.SCAN_PALLET} />
    </StepIndicator>
  );
  
  // Render the initial zone navigation step
  const renderZoneStep = () => (
    <>
      {renderStepIndicator()}
      
      <ZoneInstructionCard>
        <MapPin size={24} color="#dc2626" style={{ marginBottom: '8px' }} />
        <h3>Navigate to Zone</h3>
        
        <ZoneHighlight style={{ background: '#fee2e2', color: '#b91c1c' }}>
          <MapPin size={20} />
          {zoneName}
        </ZoneHighlight>
        
        <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
          <ZoneHighlightMap 
            highlightedZone={normalizeZoneName(zoneName)}
            interactive={false}
            customHeight="45vh"
            mobileHeight="35vh"
            focusMode="all" // Show the full map in the initial view
          />
        </div>
        
        <ZoneInstructions>
          Go to the highlighted zone in the warehouse. Look for the <strong style={{ color: '#dc2626' }}>red area</strong> on the map. Click 'Next' when you've arrived.
        </ZoneInstructions>
      </ZoneInstructionCard>
    </>
  );
  
  // Render the row visualization step
  const renderRowsStep = () => {
    // Get normalized zone ID for consistent lookup
    const normalizedZoneId = normalizeZoneName(zoneName).toLowerCase().replace(/\s+/g, '-');
    
    // Get zone orientation
    const orientation = getZoneOrientation(normalizedZoneId);
    
    // Sort rows based on zone orientation
    const sortedRows = sortRowsByOrientation(rows, normalizedZoneId);
    
    return (
      <>
        {renderStepIndicator()}
        
        <ZoneInstructionCard>
          <LayoutGrid size={24} color="#4caf50" style={{ marginBottom: '8px' }} />
          <h3>Storage Rows in {zoneName}</h3>
          
          <OrientationIndicator>
            <Compass size={16} />
            <span className="orientation-text">
              {getOrientationDisplay(orientation)}
            </span>
            <span className="orientation-hint">
              Rows numbered left-to-right when facing zone
            </span>
          </OrientationIndicator>
          
          <p>These are the scheduled rows for this order. The highlighted rows contain pallets you need to scan.</p>
          
          <EnhancedMapContainer>
            <ZoneHighlightMap 
              highlightedZone={normalizeZoneName(zoneName)}
              interactive={false}
              customHeight="100%"
              mobileHeight="100%"
              focusMode="zone" // Zoom in on the specific zone for this step
              highlightedRows={rows} // Show rows within the zone
              useRowMode={true} // Use the specialized row visualization mode
            />
          </EnhancedMapContainer>
          
          <RowLegend>
            <LegendItem>
              <LegendColor color="#dc2626" />
              <span>Selected Zone</span>
            </LegendItem>
            <LegendItem>
              <LegendColor color="#4caf50" />
              <span>Scheduled Rows</span>
            </LegendItem>
          </RowLegend>
          
          <RowsGrid>
            {sortedRows.map(row => {
              // Get the zone configuration to check for inverted rows/columns
              const zoneConfig = ZONE_MAP[normalizedZoneId];
              const hasInvertedRowsAndColumns = zoneConfig?.rowViewConfig?.invertRowsAndColumns || false;
              
              // Transform the row index if the zone has inverted rows/columns
              const actualRowIndex = hasInvertedRowsAndColumns && row.rowIndex !== undefined
                ? transformToActualRowIndex(row.rowIndex, zoneConfig)
                : row.rowIndex;
              
              // Calculate pallets in this row
              const palletsInRow = pallets.filter(pallet => {
                // Input validation
                if (!pallet) return false;
                
                // Get the zone configuration to check for inverted rows/columns
                const zoneConfig = ZONE_MAP[normalizedZoneId];
                const hasInvertedRowsAndColumns = zoneConfig?.rowViewConfig?.invertRowsAndColumns || false;
                
                // Transform the row index if the zone has inverted rows/columns
                const actualRowIndex = hasInvertedRowsAndColumns && row.rowIndex !== undefined
                  ? transformToActualRowIndex(row.rowIndex, zoneConfig)
                  : row.rowIndex;
                
                // Check if pallet belongs to this row using multiple approaches
                if (pallet.storageZone) {
                  // Try to match 'zone-X-row-Y' format
                  const match = String(pallet.storageZone).match(/^zone-\d+-row-(\d+)$/);
                  if (match) {
                    const matchedRowIndex = parseInt(match[1]);
                    
                    // For inverted zones, compare with the actual row index
                    // For regular zones, compare with the display row index
                    const rowMatches = hasInvertedRowsAndColumns 
                      ? matchedRowIndex === actualRowIndex
                      : matchedRowIndex === row.rowIndex;
                    
                    if (rowMatches) return true;
                  }
                }
                
                // Try direct rowIndex property
                if (pallet.rowIndex !== undefined) {
                  // For inverted zones, compare with the actual row index
                  // For regular zones, compare with the display row index
                  const rowMatches = hasInvertedRowsAndColumns 
                    ? pallet.rowIndex === actualRowIndex
                    : pallet.rowIndex === row.rowIndex;
                  
                  if (rowMatches) return true;
                }
                
                // Try position.row property
                if (pallet.position && pallet.position.row !== undefined) {
                  // For inverted zones, compare with the actual row index
                  // For regular zones, compare with the display row index
                  const rowMatches = hasInvertedRowsAndColumns 
                    ? pallet.position.row === actualRowIndex
                    : pallet.position.row === row.rowIndex;
                  
                  if (rowMatches) return true;
                }
                
                return false;
              });
              
              // Count for this row
              const palletCount = palletsInRow.length;
              
              return (
                <RowCard 
                  key={row.id} 
                  isActive={row.id === activeRow}
                  onClick={() => handleRowSelect(row.id)}
                >
                  <RowIcon isActive={row.id === activeRow}>
                    <LayoutGrid size={20} />
                  </RowIcon>
                  <RowName>
                    Row {getDisplayRowNumber(row.rowIndex, normalizedZoneId)}
                  </RowName>
                  <RowDetails>
                    {palletCount} Pallets
                  </RowDetails>
                </RowCard>
              );
            })}
          </RowsGrid>
        </ZoneInstructionCard>
      </>
    );
  };
  
  // Render pallets step
  const renderPalletsStep = () => {
    const selectedRowIndex = rows.find(r => r.id === activeRow)?.rowIndex;
    const selectedRowZoneId = rows.find(r => r.id === activeRow)?.zoneId;
    
    // Get the zone configuration to check for inverted rows/columns
    const zoneConfig = selectedRowZoneId ? ZONE_MAP[selectedRowZoneId] : null;
    const hasInvertedRowsAndColumns = zoneConfig?.rowViewConfig?.invertRowsAndColumns || false;
    
    // Get the selected row object with enhanced properties
    const selectedRowObj = rows.find(r => r.id === activeRow);
    
    // Use enhanced properties if available
    const isInvertedZone = selectedRowObj?.isInvertedZone || hasInvertedRowsAndColumns;
    const displayRowIndex = selectedRowObj?.displayRowIndex !== undefined ? 
      selectedRowObj.displayRowIndex : selectedRowIndex;
    const actualRowIndex = selectedRowObj?.actualRowIndex !== undefined ? 
      selectedRowObj.actualRowIndex : 
      (isInvertedZone && selectedRowIndex !== undefined ? 
        transformToActualRowIndex(selectedRowIndex, zoneConfig) : 
        selectedRowIndex);
    
    console.log(`Row selection: display=${displayRowIndex}, actual=${actualRowIndex}, inverted=${isInvertedZone}`);
    
    console.log(`Enhanced row selection:`, {
      rowId: activeRow,
      displayRowIndex,
      actualRowIndex,
      isInvertedZone,
      selectedRowObj: selectedRowObj ? {
        id: selectedRowObj.id,
        rowIndex: selectedRowObj.rowIndex,
        displayRowIndex: selectedRowObj.displayRowIndex,
        actualRowIndex: selectedRowObj.actualRowIndex,
        isInvertedZone: selectedRowObj.isInvertedZone
      } : 'Not found'
    });
    
    // Filter pallets to only show those from the selected row
    const rowPallets = palletsWithStatus.filter(pallet => {
      // Input validation
      if (!pallet) return false;
      if (actualRowIndex === undefined) return false;
      
      // Check if pallet belongs to this row using multiple approaches
      if (pallet.storageZone) {
        // Try to match 'zone-X-row-Y' format, ensuring we check both zone and row
        const match = String(pallet.storageZone).match(/^(zone-\d+(?:\.\d+)?)-row-(\d+)$/);
        if (match) {
          const matchedZoneId = match[1];
          const matchedRowIndex = parseInt(match[2]);
          
          // Check both zone ID and row index match
          // Note: selectedRowZoneId might be in different format than the storage zone string
          const normalizedZoneId = selectedRowZoneId.replace(/\s+/g, '-').toLowerCase();
          const isZoneMatch = matchedZoneId === normalizedZoneId || 
                              matchedZoneId === `zone-${normalizedZoneId.replace('zone-', '')}`;
          
          // For inverted zones, compare with the actual row index
          // For regular zones, compare with the selected row index
          const rowMatches = isInvertedZone 
            ? matchedRowIndex === actualRowIndex
            : matchedRowIndex === selectedRowIndex;
          
          if (isZoneMatch && rowMatches) {
            return true;
          }
        }
      }
      
      // Try direct zoneId and rowIndex properties
      if (pallet.zoneId !== undefined && pallet.rowIndex !== undefined) {
        const isZoneMatch = pallet.zoneId === selectedRowZoneId || 
                           String(pallet.zoneId) === String(selectedRowZoneId);
        
        // For inverted zones, compare with the actual row index
        // For regular zones, compare with the selected row index
        const rowMatches = isInvertedZone 
          ? pallet.rowIndex === actualRowIndex
          : pallet.rowIndex === selectedRowIndex;
        
        return isZoneMatch && rowMatches;
      }
      
      // Try position object properties
      if (pallet.position && 
          pallet.position.zoneId !== undefined && 
          (pallet.position.row !== undefined || pallet.position.rowIndex !== undefined)) {
        
        const positionRowIndex = pallet.position.rowIndex !== undefined ? 
                               pallet.position.rowIndex : pallet.position.row;
        
        const isZoneMatch = pallet.position.zoneId === selectedRowZoneId || 
                           String(pallet.position.zoneId) === String(selectedRowZoneId);
        
        // For inverted zones, compare with the actual row index
        // For regular zones, compare with the selected row index
        const rowMatches = isInvertedZone 
          ? positionRowIndex === actualRowIndex
          : positionRowIndex === selectedRowIndex;
        
        return isZoneMatch && rowMatches;
      }
      
      return false;
    });
    
    return (
      <>
        {renderStepIndicator()}
        
        <div>
          <h3>Pallets in {zoneName} - Row {
            selectedRowIndex !== undefined ? 
            getDisplayRowNumber(
              selectedRowIndex, 
              normalizeZoneName(zoneName).toLowerCase().replace(/\s+/g, '-')
            ) : ''
          }</h3>
          
          {hasInvertedRowsAndColumns && (
            <p style={{ fontSize: '0.85rem', color: '#666', fontStyle: 'italic' }}>
              This is an inverted zone. Row numbers are displayed according to physical layout.
            </p>
          )}
          
          {rowPallets.length > 0 ? (
            <>
              <p>Find and scan one of these pallets to assign it to the order:</p>
              
              <PalletGrid>
                {rowPallets.map(pallet => (
                  <PalletCard 
                    key={pallet.id}
                    isScanned={pallet.isScanned}
                    isAssignedToCurrentOrder={pallet.isAssignedToCurrentOrder}
                    isAssignedToOtherOrder={pallet.isAssignedToOtherOrder}
                    style={pallet.id === recentlyScannedId ? { transform: 'scale(1.05)', boxShadow: '0 0 15px rgba(239, 83, 80, 0.5)' } : {}}
                  >
                    {pallet.isScanned && (
                      <ScannedOverlay>
                        <CheckCircle size={16} />
                      </ScannedOverlay>
                    )}
                    <PalletIdLarge>#{pallet.id}</PalletIdLarge>
                    <StatusBadge 
                      isScanned={pallet.isScanned}
                      isAssignedToCurrentOrder={pallet.isAssignedToCurrentOrder}
                      isAssignedToOtherOrder={pallet.isAssignedToOtherOrder}
                      style={{
                        background: pallet.isAssignedToCurrentOrder ? '#e8f5e9' : 
                                 pallet.isAssignedToOtherOrder ? '#ffebee' : '#f1f5f9',
                        color: pallet.isAssignedToCurrentOrder ? '#1b5e20' : 
                              pallet.isAssignedToOtherOrder ? '#d32f2f' : '#455a64'
                      }}
                    >
                      <CheckCircle size={12} />
                      {pallet.isScanned || pallet.isAssignedToCurrentOrder || pallet.isAssignedToOtherOrder
                        ? 'Processing' 
                        : getProcessStageDisplay(pallet.processStage)}
                    </StatusBadge>
                  </PalletCard>
                ))}
              </PalletGrid>
              
              <ScanSection>
                <p>Found a pallet? Click the button below to scan it:</p>
                <ScanButton onClick={handleStartScan}>
                  <QrCode size={20} />
                  Scan Pallet
                </ScanButton>
              </ScanSection>
            </>
          ) : (
            <p>No pallets found in this row. Please select a different row or check the storage assignments.</p>
          )}
        </div>
      </>
    );
  };
  
  // Render scan step
  const renderScanStep = () => (
    <>
      {renderStepIndicator()}
      
      <div style={{ textAlign: 'center' }}>
        <h3>Scan a Pallet</h3>
        <p>Position the QR code within the scanner frame to assign the pallet to this order.</p>
        <p>The pallet will be removed from storage and assigned to the current order.</p>
        
        <ScanButton 
          onClick={handleStartScan}
          style={{ margin: '20px auto' }}
        >
          <QrCode size={20} />
          Open Scanner
        </ScanButton>
      </div>
    </>
  );
  
  // Don't render if not open
  if (!isOpen) return null;
  
  // If scanner is active, render it full screen
  // Handle scanner close (re-enable SSE)
  const handleCloseScanner = () => {
    setShowScanner(false);
    
    // Re-enable SSE when scanner is closed
    setTimeout(() => {
      kleinpakSSEManager.disableScanningMode();
      console.log('RowLocationWorkflow: Re-enabled SSE after scanner closed');
    }, 500); 
  };
  
  if (showScanner) {
    return (
      <ModalOverlay>
        <ModalContent style={{ maxWidth: '95vw', width: '100%', maxHeight: '95vh', height: '100%' }}>
          <ModalHeader>
            <ModalTitle>Scan Pallet in Zone {zoneName}</ModalTitle>
            <CloseButton onClick={handleCloseScanner}>×</CloseButton>
          </ModalHeader>
          <QRScanner 
            onScan={handleScanResult}
            onManualInputRequest={() => {
              const palletId = prompt('Enter pallet ID:');
              if (palletId) {
                handleScanResult({ id: palletId });
              } else {
                handleCloseScanner();
              }
            }}
            onClose={handleCloseScanner}
          />
        </ModalContent>
      </ModalOverlay>
    );
  }
  
  return (
    <>
      {/* Celebration modal - shown when all pallets are scanned */}
      {showCompletionCelebration && (
        <CelebrationOverlay>
          <CelebrationContent>
            <TrophyIcon>
              <Trophy />
            </TrophyIcon>
            <CelebrationTitle>Mission Accomplished!</CelebrationTitle>
            <CelebrationMessage>
              Great job! You've successfully scanned all {pallets.length} pallets from the {zoneName} zone.
              The pallets have been marked as "Processing" and assigned to the order.
            </CelebrationMessage>
            <CompletionButton onClick={handleCompletionContinue}>
              Continue
            </CompletionButton>
          </CelebrationContent>
        </CelebrationOverlay>
      )}
      
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>
              {currentStep === STEPS.SHOW_ZONE && 'Navigate to Zone'}
              {currentStep === STEPS.SHOW_ROWS && 'Storage Rows'}
              {currentStep === STEPS.LIST_PALLETS && 'Pallets in Row'}
              {currentStep === STEPS.SCAN_PALLET && 'Scan Pallet'}
            </ModalTitle>
            <CloseButton onClick={handleClose}>×</CloseButton>
          </ModalHeader>
          
          <ModalBody>
            {currentStep === STEPS.SHOW_ZONE && renderZoneStep()}
            {currentStep === STEPS.SHOW_ROWS && renderRowsStep()}
            {currentStep === STEPS.LIST_PALLETS && renderPalletsStep()}
            {currentStep === STEPS.SCAN_PALLET && renderScanStep()}
          </ModalBody>
          
          <ModalFooter>
            {currentStep !== STEPS.SHOW_ZONE && (
              <SecondaryButton onClick={handleBack}>
                Back
              </SecondaryButton>
            )}
            
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            
            {currentStep === STEPS.SHOW_ZONE && (
              <PrimaryButton onClick={handleNext}>
                Next
              </PrimaryButton>
            )}
            
            {currentStep === STEPS.SHOW_ROWS && (
              <PrimaryButton onClick={handleNext}>
                Next
              </PrimaryButton>
            )}
            
            {currentStep === STEPS.LIST_PALLETS && (
              <PrimaryButton onClick={handleStartScan}>
                Scan Pallet
              </PrimaryButton>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default RowLocationWorkflow;

import React, { useState } from 'react';
import { Plus, Filter, ChevronDown, SortAsc, Calendar, AlertTriangle } from 'lucide-react';
import OrderCard from './OrderCard';
import ProcessingOrdersConsole from './ProcessingOrdersConsole';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const Title = styled.h3`
  ${({ theme }) => theme.typography.h3};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
  
  span {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 0.7em;
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : theme.colors.background.paper};
  color: ${({ active, theme }) => 
    active ? theme.colors.common.white : theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  font-size: 0.8rem;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.hover};
  }
  
  svg {
    width: 14px;
    height: 14px;
  }
`;

const SortButton = styled(ActionButton)`
  min-width: 110px;
  justify-content: space-between;
  position: relative;
`;

const SortMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.md};
  z-index: 10;
  overflow: hidden;
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
`;

const SortOption = styled.div`
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
  
  ${({ active, theme }) => active && `
    background-color: ${theme.colors.primary.lighter};
    font-weight: 500;
  `}
`;

const AddButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const EmptyState = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: ${({ theme }) => theme.colors.background.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  
  svg {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.primary.lighter};
  }
  
  p {
    margin: 0;
  }
`;

const OrderList = ({
  orders,
  showCompletedOrders,
  onToggleCompleted,
  onAddOrder,
  onUpdatePriority,
  onShipOrder,
  onEditOrder,
  onDeleteOrder
}) => {
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sortBy, setSortBy] = useState('priority');
  
  // Sort orders based on the selected criteria
  const sortedOrders = [...orders].sort((a, b) => {
    switch (sortBy) {
      case 'customer':
        return a.customer_name.localeCompare(b.customer_name);
      case 'priority':
        // Sort by priority (urgent first, then normal, then low)
        const priorityOrder = { urgent: 0, normal: 1, low: 2 };
        return priorityOrder[a.priority || 'normal'] - priorityOrder[b.priority || 'normal'];
      case 'dueDate':
        // Sort by due date (earliest first, null dates last)
        if (!a.due_date && !b.due_date) return 0;
        if (!a.due_date) return 1;
        if (!b.due_date) return -1;
        return new Date(a.due_date) - new Date(b.due_date);
      case 'progress':
        // Sort by completion percentage (highest first)
        return (b.colli_completion || 0) - (a.colli_completion || 0);
      default:
        return 0;
    }
  });

  // Active orders (not completed)
  const activeOrders = sortedOrders.filter(order => !order.is_completed || showCompletedOrders);
  const activeCount = sortedOrders.filter(order => !order.is_completed).length;
  const completedCount = sortedOrders.filter(order => order.is_completed).length;

  return (
    <Container>
      {/* Processing Orders Console - Shows active orders at the top */}
      <ProcessingOrdersConsole 
        orders={orders}
        onUpdatePriority={onUpdatePriority}
        onShipOrder={onShipOrder}
        onEditOrder={onEditOrder}
        onDeleteOrder={onDeleteOrder}
      />
      
      <Header>
        <HeaderLeft>
          <Title>
            Orders 
            <span>
              ({activeCount} active{showCompletedOrders ? `, ${completedCount} completed` : ''})
            </span>
          </Title>
        </HeaderLeft>
        
        <FilterSection>
          <SortButton onClick={() => setSortMenuOpen(!sortMenuOpen)}>
            <SortAsc size={14} />
            <span>
              {sortBy === 'customer' && 'Customer'}
              {sortBy === 'priority' && 'Priority'}
              {sortBy === 'dueDate' && 'Due date'}
              {sortBy === 'progress' && 'Progress'}
            </span>
            <ChevronDown size={14} />
            
            <SortMenu isOpen={sortMenuOpen}>
              <SortOption 
                active={sortBy === 'priority'} 
                onClick={(e) => { e.stopPropagation(); setSortBy('priority'); setSortMenuOpen(false); }}
              >
                Priority
              </SortOption>
              <SortOption 
                active={sortBy === 'customer'} 
                onClick={(e) => { e.stopPropagation(); setSortBy('customer'); setSortMenuOpen(false); }}
              >
                Customer
              </SortOption>
              <SortOption 
                active={sortBy === 'dueDate'} 
                onClick={(e) => { e.stopPropagation(); setSortBy('dueDate'); setSortMenuOpen(false); }}
              >
                Due date
              </SortOption>
              <SortOption 
                active={sortBy === 'progress'} 
                onClick={(e) => { e.stopPropagation(); setSortBy('progress'); setSortMenuOpen(false); }}
              >
                Progress
              </SortOption>
            </SortMenu>
          </SortButton>
          
          <ActionButton
            active={showCompletedOrders}
            onClick={onToggleCompleted}
          >
            <Filter size={14} />
            {showCompletedOrders ? 'Hide Completed' : 'Show Completed'}
          </ActionButton>
          
          <AddButton onClick={onAddOrder}>
            <Plus size={14} />
            Add Order
          </AddButton>
        </FilterSection>
      </Header>

      {activeOrders.length > 0 ? (
        activeOrders.map(order => (
          <OrderCard
            key={order.id}
            order={order}
            onUpdatePriority={onUpdatePriority}
            onShip={onShipOrder}
            onEdit={onEditOrder}
            onDelete={onDeleteOrder}
          />
        ))
      ) : (
        <EmptyState>
          <AlertTriangle size={32} />
          <p>No orders found. {!showCompletedOrders && 'Try showing completed orders or'} create a new order.</p>
        </EmptyState>
      )}
    </Container>
  );
};

export default OrderList;

import { DEFAULT_ZONES } from '@features/map/constants/zoneConfig.v2';
import { transformToActualRowIndex, transformToVisualRowIndex } from './zoneTransformUtils';

/**
 * Get pallets that are located in the specified scheduled rows
 * This enhanced version handles inverted zones correctly
 */
export function getEnhancedPalletsInScheduledRows(pallets, scheduledRows, debug = false) {
  if (!pallets || !scheduledRows || !Array.isArray(scheduledRows) || scheduledRows.length === 0) {
    if (debug) console.log('No valid pallets or scheduledRows provided');
    return [];
  }

  // Helper to get zone config
  const getZoneConfig = (zoneId) => {
    if (!zoneId) return null;
    
    // Try to find zone by ID first
    let zoneConfig = DEFAULT_ZONES.find(zone => 
      zone.id?.toLowerCase() === zoneId.toString().toLowerCase()
    );
    
    // If not found by ID, try matching zone number
    if (!zoneConfig) {
      const zoneMatch = zoneId.toString().match(/zone[-_]?(\d+(?:\.\d+)?)/i);
      if (zoneMatch) {
        zoneConfig = DEFAULT_ZONES.find(zone => {
          const configMatch = zone.id?.match(/zone[-_]?(\d+(?:\.\d+)?)/i);
          return configMatch && configMatch[1] === zoneMatch[1];
        });
      }
    }
    
    return zoneConfig;
  };

  // Helper to normalize zone ID
  const normalizeZoneId = (zoneId) => {
    if (!zoneId) return undefined;
    return String(zoneId)
      .trim()
      .toLowerCase()
      .replace(/^zone[-_]?/, '')
      .replace(/[^a-z0-9]/g, '');
  };

  // Helper to normalize row index
  const normalizeRowIndex = (rowIndex) => {
    if (rowIndex === undefined || rowIndex === null) return undefined;
    const num = Number(rowIndex);
    return isNaN(num) ? undefined : num;
  };

  // Helper to check if a pallet matches a row
  const palletMatchesRow = (pallet, row) => {
    if (!pallet || !row) return false;

    // Get zone config for the row
    const zoneConfig = getZoneConfig(row.zoneId);
    const isInvertedZone = zoneConfig?.rowViewConfig?.invertRowsAndColumns;

    // Normalize row data
    const rowZoneId = normalizeZoneId(row.zoneId);
    let rowIndex = normalizeRowIndex(row.rowIndex);

    // If this is an inverted zone, we need to transform the row index
    if (isInvertedZone) {
      rowIndex = transformToActualRowIndex(rowIndex, zoneConfig);
    }

    // Normalize pallet data
    const palletZoneId = normalizeZoneId(pallet.zoneId);
    let palletRowIndex = normalizeRowIndex(pallet.rowIndex);

    // If pallet is in an inverted zone, transform its row index
    if (isInvertedZone && palletZoneId === rowZoneId) {
      palletRowIndex = transformToActualRowIndex(palletRowIndex, zoneConfig);
    }

    // Check direct match
    if (palletZoneId === rowZoneId && palletRowIndex === rowIndex) {
      return true;
    }

    // Check position data
    if (pallet.position) {
      const positionZoneId = normalizeZoneId(pallet.position.zoneId);
      let positionRowIndex = normalizeRowIndex(pallet.position.rowIndex);
      
      // If position has no rowIndex, try row property
      if (positionRowIndex === undefined) {
        positionRowIndex = normalizeRowIndex(pallet.position.row);
      }

      // If position is in an inverted zone, transform its row index
      if (isInvertedZone && positionZoneId === rowZoneId) {
        positionRowIndex = transformToActualRowIndex(positionRowIndex, zoneConfig);
      }

      if (positionZoneId === rowZoneId && positionRowIndex === rowIndex) {
        return true;
      }
    }

    // Check storage zone string
    if (pallet.storageZone) {
      const match = String(pallet.storageZone).match(/^(?:zone-)?([^-]+)-row-(\d+)$/);
      if (match) {
        const [_, zoneIdPart, rowIndexPart] = match;
        const storageZoneId = normalizeZoneId(zoneIdPart);
        let storageRowIndex = normalizeRowIndex(rowIndexPart);

        // If storage zone is inverted, transform its row index
        if (isInvertedZone && storageZoneId === rowZoneId) {
          storageRowIndex = transformToActualRowIndex(storageRowIndex, zoneConfig);
        }

        if (storageZoneId === rowZoneId && storageRowIndex === rowIndex) {
          return true;
        }
      }
    }

    return false;
  };

  // Find pallets that match any of the scheduled rows
  const matchingPallets = pallets.filter(pallet => 
    scheduledRows.some(row => palletMatchesRow(pallet, row))
  );

  if (debug) {
    console.log('Enhanced pallet matching results:', {
      totalPallets: pallets.length,
      matchingPallets: matchingPallets.length,
      scheduledRows: scheduledRows.map(row => ({
        zoneId: row.zoneId,
        rowIndex: row.rowIndex,
        isInverted: getZoneConfig(row.zoneId)?.rowViewConfig?.invertRowsAndColumns
      }))
    });
  }

  return matchingPallets;
}

/**
 * Get the display row index for a given row, handling inverted zones
 */
export function getDisplayRowIndex(row) {
  if (!row) return 0;

  // Get zone config
  const zoneConfig = DEFAULT_ZONES.find(zone => 
    zone.id?.toLowerCase() === row.zoneId?.toString().toLowerCase()
  );

  // If this is an inverted zone, transform the row index for display
  if (zoneConfig?.rowViewConfig?.invertRowsAndColumns) {
    return transformToVisualRowIndex(row.rowIndex, zoneConfig);
  }

  return row.rowIndex;
}

/**
 * Get the actual row index for a given display index
 */
export function getActualRowIndex(displayIndex, zoneId) {
  if (displayIndex === undefined || !zoneId) return displayIndex;

  // Get zone config
  const zoneConfig = DEFAULT_ZONES.find(zone => 
    zone.id?.toLowerCase() === zoneId.toString().toLowerCase()
  );

  // If this is an inverted zone, transform the display index to actual
  if (zoneConfig?.rowViewConfig?.invertRowsAndColumns) {
    return transformToActualRowIndex(displayIndex, zoneConfig);
  }

  return displayIndex;
}

import React, { useState, useEffect, useContext } from 'react';
import { ArrowLeft, Check, AlertCircle, X, CornerDownRight, Grid, Map, List, ArrowRight, Filter, Compass } from 'lucide-react';
import api from '@lib/api/axios';
import { zoneUpdateService } from '@features/map/services/zoneUpdateService';
import storageAssignmentService, { parseStorageZone } from '@features/map/services/storageAssignmentService';
import { UserContext } from '@lib/contexts/UserContext';
import { calculateZoneCapacity, DEFAULT_ZONES } from '@features/map/constants/zoneConfig.v2';
import { transformToActualRowIndex } from '@lib/utils/zoneTransformUtils';
import { getZoneOrientation, storageToDisplayIndex, displayToStorageIndex, ORIENTATIONS } from '@features/map/constants/zoneOrientations';
import StorageMapGrid from '@features/shared/components/StorageMap';
import {
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalFooter,
  IconButton,
  CancelButton,
  PrimaryButton,
  PalletInfo,
  InfoItem,
  InfoLabel,
  InfoValue,
  ZoneList,
  ZoneCard,
  ZoneName,
  ZoneDetails,
  OccupancyBar,
  OccupancyFill,
  ZoneTag,
  LoadingIndicator,
  ErrorMessage,
  SuccessMessage,
  InfoMessage,
  SectionTitle,
  GridViewContainer,
  GridTitle,
  GridDescription,
  PositionGrid,
  PositionCell,
  Legend,
  LegendItem,
  LegendColor,
  ActiveRowContainer,
  ActiveRowHeader,
  ActiveRowDetails,
  ProgressBar,
  ViewToggle,
  ViewToggleButton,
  MapContainer,
  MapContent,
  Zone,
  ZoneHeader,
  ZoneLabel,
  ZoneFooter,
  ZoneCapacity,
  PalletGrid,
  PalletCell,
  OrientationIndicator,
  ZoneSegment
} from './styles';

// View mode options for zone selection
const VIEW_MODES = {
  LIST: 'list',
  MAP: 'map'
};

/**
 * Mobile-optimized storage assignment modal
 * A simplified version of StorageAssignmentModal designed for mobile use
 * Focused only on zone selection for improved user workflow
 * Provides both list and map view options for zone selection
 */
const MobileStorageAssignmentModal = ({ 
  isOpen, 
  onClose, 
  pallet, 
  onAssign, 
  locationId = null,
  pending = false
}) => {
  // Context and base state
  const { user } = useContext(UserContext);
  const currentLocationId = locationId || (user?.currentLocation?.id || 1);
  
  // UI state management
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [assignmentComplete, setAssignmentComplete] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODES.LIST);
  const [mapScale, setMapScale] = useState(0.85);
  const [showRowSelection, setShowRowSelection] = useState(false);
  const [zoneOccupancy, setZoneOccupancy] = useState({});
  const [palletsByZone, setPalletsByZone] = useState({});
  const [showAllZones, setShowAllZones] = useState(false); // New state for zone filtering toggle
  
  // Reset state when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      if (activeRow) {
        const activeZone = zones.find(z => z.id === activeRow.zoneId);
        if (activeZone) {
          setSelectedZone(activeZone);
          setSelectedRow(activeRow.rowIndex);
          setShowRowSelection(true);
          storageAssignmentService.getZoneOccupancy(activeZone.id, currentLocationId)
            .then(occupancy => setZoneOccupancy(occupancy || {}))
            .catch(error => {
              console.error('Failed to get zone occupancy:', error);
              setZoneOccupancy({});
            });
        }
      } else {
        setSelectedZone(null);
        setSelectedRow(null);
      }
      
      setErrorMessage('');
      setAssignmentComplete(false);
      setShowAllZones(false); // Reset to show only recommended zones
      
      const savedViewMode = localStorage.getItem('storageAssignmentViewMode');
      if (savedViewMode && Object.values(VIEW_MODES).includes(savedViewMode)) {
        setViewMode(savedViewMode);
      }
    }
  }, [isOpen, activeRow, zones, currentLocationId]);
  
  // Load zones when modal opens
  useEffect(() => {
    let isMounted = true;
    
    const loadZones = async () => {
      if (!isOpen || !pallet) return;
      
      setIsLoading(true);
      setErrorMessage('');
      
      try {
        const response = await api.get(`/api/pallets/${currentLocationId}`);
        const pallets = response.data.data;

        const newPalletsByZone = {};
        pallets.forEach(pallet => {
          if (pallet.storageZone) {
            const zoneMatch = pallet.storageZone.match(/^([\w-]+)-row-/);
            if (zoneMatch) {
              const zoneId = zoneMatch[1];
              if (!newPalletsByZone[zoneId]) {
                newPalletsByZone[zoneId] = [];
              }
              newPalletsByZone[zoneId].push(pallet);
            }
          }
        });

        setPalletsByZone(newPalletsByZone);

        const allZones = DEFAULT_ZONES.map(zone => {
          const zonePallets = newPalletsByZone[zone.id] || [];
          const capacity = calculateZoneCapacity(zone);
          const occupiedCount = zonePallets.length;
          const occupancyPercentage = Math.round((occupiedCount / capacity) * 100);

          return {
            ...zone,
            occupiedCount,
            occupancyPercentage,
            hasSpace: occupiedCount < capacity,
            isRecommended: storageAssignmentService.isZoneRecommendedForTomatoType(
              zone.id,
              pallet.tomatoType,
              pallet.tomatoOption,
              pallet.sortingGrade
            )
          };
        });

        if (!isMounted) return;
        
        if (allZones && allZones.length > 0) {
          setZones(allZones);
        } else {
          setErrorMessage('No storage zones available in this location.');
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error loading zones:', error);
          setErrorMessage(`Failed to load zones: ${error.message}`);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    loadZones();
    
    return () => {
      isMounted = false;
    };
  }, [isOpen, pallet, currentLocationId]);

  // Filter zones based on recommendations
  const filteredZones = showAllZones 
    ? zones 
    : zones.filter(zone => zone.isRecommended);
  
  // Toggle between list and map view
  const toggleViewMode = () => {
    const newMode = viewMode === VIEW_MODES.LIST ? VIEW_MODES.MAP : VIEW_MODES.LIST;
    setViewMode(newMode);
    try {
      localStorage.setItem('storageAssignmentViewMode', newMode);
    } catch (error) {
      console.warn('Failed to save view mode preference:', error);
    }
  };

  // Handle zone selection
  const handleZoneSelect = async (zone) => {
    setSelectedZone(zone);
    setSelectedRow(null);
    setShowRowSelection(true);
    
    try {
      const response = await api.get(`/api/pallets/${currentLocationId}`);
      const pallets = response.data.data;

      const zoneOccupancy = {};
      pallets.forEach(pallet => {
        if (pallet.storageZone?.startsWith(`${zone.id}-row-`)) {
          const rowMatch = pallet.storageZone.match(/-row-(\d+)$/);
          if (rowMatch) {
            const rowIndex = parseInt(rowMatch[1]);
            if (!zoneOccupancy[rowIndex]) zoneOccupancy[rowIndex] = [];
            zoneOccupancy[rowIndex].push(pallet);
          }
        }
      });

      setZoneOccupancy(zoneOccupancy);
    } catch (error) {
      console.error('Failed to get zone occupancy:', error);
      setZoneOccupancy({});
    }
  };

  // Handle row selection
  // Get orientation display text
  const getOrientationDisplay = (orientation) => {
    const orientationMap = {
      [ORIENTATIONS.EAST]: 'Facing East',
      [ORIENTATIONS.WEST]: 'Facing West',
      [ORIENTATIONS.NORTH]: 'Facing North',
      [ORIENTATIONS.SOUTH]: 'Facing South'
    };
    return orientationMap[orientation] || 'Unknown Orientation';
  };

  const handleRowSelect = (displayRowIndex) => {
    // Convert display index to storage index
    const storageRowIndex = displayToStorageIndex(
      displayRowIndex,
      selectedZone.id,
      selectedZone.dimensions.rows
    );
    setSelectedRow(storageRowIndex);
  };
  
  // Handle assignment confirmation
  const handleAssign = async () => {
    if (!selectedZone || selectedRow === null) return;
    
    setIsLoading(true);
    setErrorMessage('');
    
    try {
      // First apply any row/column inversion if configured
      let transformedRowIndex = selectedZone.rowViewConfig?.invertRowsAndColumns
        ? transformToActualRowIndex(selectedRow, selectedZone)
        : selectedRow;
      
      if (onAssign) {
        onAssign({
          zoneId: selectedZone.id,
          zoneName: selectedZone.name,
          rowIndex: transformedRowIndex,
          storageZone: `${selectedZone.id}-row-${transformedRowIndex}`,
          position: {
            row: transformedRowIndex,
            col: 0
          }
        });
      }
      
      setAssignmentComplete(true);
      setTimeout(() => {
        onClose();
      }, 1500);
    } catch (error) {
      console.error('Failed to assign pallet:', error);
      setErrorMessage(`Assignment failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  if (!isOpen) return null;
  
  const renderContent = () => {
    if (isLoading && !assignmentComplete) {
      return (
        <LoadingIndicator>
          <div className="spinner" />
        </LoadingIndicator>
      );
    }
    
    if (errorMessage) {
      return (
        <ErrorMessage>
          <AlertCircle />
          <div>{errorMessage}</div>
          {errorMessage.includes('No storage zones') && (
            <div style={{ marginTop: '8px', fontSize: '14px' }}>
              Please ensure that storage zones are configured for this location.
            </div>
          )}
        </ErrorMessage>
      );
    }
    
    if (assignmentComplete) {
      return (
        <SuccessMessage>
          <Check />
          <div>
            Pallet successfully assigned to
            <strong> {selectedZone.name}</strong> zone,{' '}
            <strong>Row {selectedRow + 1}</strong>
          </div>
        </SuccessMessage>
      );
    }
    
    return showRowSelection && selectedZone ? renderRowSelection() : renderZoneSelection();
  };
  
  const renderRowSelection = () => {
    const dimensions = selectedZone?.rowViewConfig?.invertRowsAndColumns
      ? selectedZone.rowViewConfig.dimensions
      : selectedZone?.dimensions;
    
    const rows = dimensions?.rows || 0;
    const cols = dimensions?.cols || 0;
    
    return (
      <>
        <IconButton 
          onClick={() => {
            setShowRowSelection(false);
            setSelectedRow(null);
          }}
          style={{ marginBottom: '16px' }}
        >
          <ArrowLeft size={20} />
          Back to Zones
        </IconButton>

          <div style={{ marginBottom: '16px' }}>
            <SectionTitle>
              <Grid size={18} />
              Select Row in {selectedZone.name}
            </SectionTitle>
            <OrientationIndicator>
              <Compass size={16} />
              <span className="orientation-text">
                {getOrientationDisplay(getZoneOrientation(selectedZone.id))}
              </span>
              <span className="orientation-hint">
                Rows numbered left-to-right when facing zone
              </span>
            </OrientationIndicator>
          </div>

        <InfoMessage>
          <ArrowRight size={16} />
          Select a row to store the pallet. Row numbers are shown as they appear when facing the zone.
          The progress bar shows how full each row is.
        </InfoMessage>

        <GridViewContainer>
          {Array.from({ length: rows }).map((_, rowIndex) => {
            const rowPallets = zoneOccupancy[rowIndex] || [];
            const rowOccupancy = rowPallets.length;
            const maxPositions = cols;
            
            const isSelected = selectedRow === rowIndex;
            const occupancyPercentage = (rowOccupancy / maxPositions) * 100;

            return (
              <div 
                key={rowIndex}
                onClick={() => handleRowSelect(rowIndex)}
                title={`Physical Row ${storageToDisplayIndex(rowIndex, selectedZone.id, rows) + 1}`}
                style={{
                  padding: '12px',
                  margin: '8px 0',
                  border: `2px solid ${isSelected ? '#2563eb' : '#e2e8f0'}`,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  backgroundColor: isSelected ? '#eff6ff' : 'white'
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '8px'
                }}>
                  <strong>Row {storageToDisplayIndex(rowIndex, selectedZone.id, rows) + 1}</strong>
                  <span>{rowOccupancy} / {maxPositions} positions filled</span>
                </div>
                <OccupancyBar>
                  <OccupancyFill percentage={occupancyPercentage} />
                </OccupancyBar>
                {rowPallets.length > 0 && (
                  <div style={{ 
                    marginTop: '8px',
                    fontSize: '12px',
                    color: '#64748b'
                  }}>
                    Contains: {Object.entries(rowPallets.reduce((acc, p) => {
                      const key = `${p.day || 'No Day'}, ${p.tomatoOption || 'No Option'}, ${p.sortingGrade || 'No Grade'}`;
                      acc[key] = (acc[key] || 0) + 1;
                      return acc;
                    }, {})).map(([info, count]) => 
                      count > 1 ? `${count}x (${info})` : info
                    ).join('; ')}
                  </div>
                )}
              </div>
            );
          })}
        </GridViewContainer>
      </>
    );
  };

  const renderZoneSelection = () => {
    return (
      <>
        <PalletInfo>
          <InfoItem>
            <InfoLabel>Pallet ID</InfoLabel>
            <InfoValue>{pallet?.id || 'N/A'}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Type</InfoLabel>
            <InfoValue>{pallet?.tomatoType || pallet?.type || 'N/A'}</InfoValue>
          </InfoItem>
          {pallet?.tomatoOption && (
            <InfoItem>
              <InfoLabel>Option</InfoLabel>
              <InfoValue>{pallet.tomatoOption}</InfoValue>
            </InfoItem>
          )}
          {pallet?.sortingGrade && (
            <InfoItem>
              <InfoLabel>Grade</InfoLabel>
              <InfoValue>{pallet.sortingGrade}</InfoValue>
            </InfoItem>
          )}
          <InfoItem>
            <InfoLabel>Status</InfoLabel>
            <InfoValue>{pallet?.status || 'N/A'}</InfoValue>
          </InfoItem>
        </PalletInfo>
        
        {activeRow && (
          <ActiveRowContainer>
            <ActiveRowHeader>Continue filling active row</ActiveRowHeader>
            <ActiveRowDetails>
              <div>
                Zone: {zones.find(z => z.id === activeRow.zoneId)?.name || activeRow.zoneId}
              </div>
              <div>Row: {activeRow.rowIndex + 1}</div>
              {activeRow.rowInfo && (
                <>
                  <div>
                    {activeRow.rowInfo.last_position_index + 1} of {activeRow.rowInfo.row_capacity} positions filled
                  </div>
                  <ProgressBar 
                    $percentage={Math.round((activeRow.rowInfo.last_position_index + 1) / activeRow.rowInfo.row_capacity * 100)}
                  >
                    <div className="fill" />
                  </ProgressBar>
                </>
              )}
            </ActiveRowDetails>
          </ActiveRowContainer>
        )}
        
        <ViewToggle>
          <ViewToggleButton 
            $active={viewMode === VIEW_MODES.LIST}
            onClick={() => setViewMode(VIEW_MODES.LIST)}
          >
            <List size={16} />
            List View
          </ViewToggleButton>
          <ViewToggleButton 
            $active={viewMode === VIEW_MODES.MAP}
            onClick={() => setViewMode(VIEW_MODES.MAP)}
          >
            <Map size={16} />
            Map View
          </ViewToggleButton>
        </ViewToggle>
        
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '16px' 
        }}>
          <SectionTitle>
            {viewMode === VIEW_MODES.LIST ? <List size={18} /> : <Map size={18} />}
            Select Storage Zone
          </SectionTitle>
          
          <ViewToggleButton
            $active={showAllZones}
            onClick={() => setShowAllZones(!showAllZones)}
            style={{ padding: '8px 12px' }}
          >
            <Filter size={16} />
            {showAllZones ? 'Show Recommended' : 'Show All'}
          </ViewToggleButton>
        </div>

        {!showAllZones && filteredZones.length === 0 ? (
          <InfoMessage style={{ backgroundColor: '#fff3cd', color: '#856404' }}>
            <AlertCircle size={16} />
            No recommended zones found. Try showing all zones.
          </InfoMessage>
        ) : activeRow ? (
          <InfoMessage>
            <ArrowRight size={16} />
            <strong>Tip:</strong> Continue filling the active row in {zones.find(z => z.id === activeRow.zoneId)?.name || 'zone'} to maintain organized storage.
          </InfoMessage>
        ) : (
          <InfoMessage>
            <ArrowRight size={16} />
            {showAllZones ? 
              'Showing all available zones. Recommended zones are highlighted.' :
              'Showing only recommended zones for this product type.'}
          </InfoMessage>
        )}
        
        {viewMode === VIEW_MODES.LIST ? (
          <ZoneList>
            {filteredZones.map(zone => {
              const capacity = calculateZoneCapacity(zone);
              const occupiedCount = zone.occupiedCount || 0;
              const occupancyPercentage = zone.occupancyPercentage || 0;
              const isActiveZone = activeRow && activeRow.zoneId === zone.id;
              
              return (
                <ZoneCard 
                  key={zone.id}
                  type={zone.type}
                  occupancy={occupancyPercentage}
                  $isSelected={selectedZone?.id === zone.id || isActiveZone}
                  $isRecommended={zone.isRecommended}
                  onClick={() => handleZoneSelect(zone)}
                >
                  <ZoneName>{zone.name}</ZoneName>
                  <ZoneDetails>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>{zone.dimensions.rows} rows × {zone.dimensions.cols} columns</span>
                      <span>{capacity - occupiedCount} positions available</span>
                    </div>
                    {palletsByZone[zone.id]?.length > 0 && (
                      <div style={{ 
                        fontSize: '12px', 
                        color: '#64748b',
                        marginTop: '4px' 
                      }}>
                        Contains: {Object.entries(palletsByZone[zone.id].reduce((acc, p) => {
                          const key = `${p.tomatoOption || 'No Option'} (${p.sortingGrade || 'No Grade'})`;
                          acc[key] = (acc[key] || 0) + 1;
                          return acc;
                        }, {})).map(([info, count]) => 
                          count > 1 ? `${count}x ${info}` : info
                        ).join('; ')}
                      </div>
                    )}
                    {isActiveZone && (
                      <div style={{ fontWeight: 600, color: '#079438', marginTop: '4px' }}>
                        Active row ✓
                      </div>
                    )}
                    {zone.isRecommended && !isActiveZone && (
                      <div style={{ fontWeight: 500, color: '#0284c7', marginTop: '4px' }}>
                        Recommended for this product type
                      </div>
                    )}
                  </ZoneDetails>
                  <OccupancyBar>
                    <OccupancyFill percentage={occupancyPercentage} />
                  </OccupancyBar>
                  <ZoneTag>{zone.section || zone.type || 'Zone'}</ZoneTag>
                </ZoneCard>
              );
            })}
          </ZoneList>
        ) : (
          <MapContainer>
            <MapContent style={{ transform: `scale(${mapScale})` }}>
              {DEFAULT_ZONES.map((zone) => {
                // Skip if not in filtered zones
                if (!showAllZones && !filteredZones.some(z => z.id === zone.id)) {
                  return null;
                }

                const loadedZone = zones.find(z => z.id === zone.id);
                if (!loadedZone) return null;
                
                const capacity = calculateZoneCapacity(zone);
                const occupiedCount = loadedZone.occupiedCount || 0;
                const occupancyPercentage = loadedZone.occupancyPercentage || 0;
                const isActiveZone = activeRow && activeRow.zoneId === zone.id;
                const isSelected = selectedZone?.id === zone.id;
                const zoneOccupancy = loadedZone.occupancy || {};
                
                return zone.customLayout ? (
                  <Zone
                    key={zone.id}
                    data-custom-layout="true"
                    style={{
                      left: `${zone.position.x * 16}px`,
                      top: `${zone.position.y * 16}px`,
                      width: `${zone.dimensions.cols * 16}px`,
                      height: `${zone.dimensions.rows * 16}px`,
                      opacity: showAllZones && !loadedZone.isRecommended ? 0.5 : 1
                    }}
                  >
                    {zone.segments.map((segment, index) => (
                      <ZoneSegment
                        key={`${zone.id}-segment-${index}`}
                        style={{
                          left: `${(segment.x - zone.position.x) * 16}px`,
                          top: `${(segment.y - zone.position.y) * 16}px`,
                          width: `${segment.width * 16}px`,
                          height: `${segment.height * 16}px`
                        }}
                        onClick={() => handleZoneSelect(loadedZone)}
                        $selected={isSelected || isActiveZone}
                        $recommended={loadedZone.isRecommended}
                      >
                        {index === 0 && (
                          <ZoneHeader>
                            <ZoneLabel>{zone.name}</ZoneLabel>
                          </ZoneHeader>
                        )}
                        
                        <StorageMapGrid 
                          dimensions={{ rows: segment.height, cols: segment.width }}
                          positions={zoneOccupancy}
                          style={{ height: '100%', width: '100%' }}
                          data-zone-id={zone.id}
                        />
                        
                        {index === zone.segments.length - 1 && (
                          <ZoneFooter>
                            <ZoneCapacity>
                              {occupiedCount} / {capacity}
                            </ZoneCapacity>
                            <OccupancyBar>
                              <OccupancyFill percentage={occupancyPercentage} />
                            </OccupancyBar>
                          </ZoneFooter>
                        )}
                      </ZoneSegment>
                    ))}
                  </Zone>
                ) : (
                  <Zone
                    key={zone.id}
                    type={zone.type}
                    occupancy={occupancyPercentage}
                    style={{
                      left: `${zone.position.x * 16}px`,
                      top: `${zone.position.y * 16}px`,
                      width: `${zone.dimensions.cols * 16}px`,
                      height: `${zone.dimensions.rows * 16}px`,
                      opacity: showAllZones && !loadedZone.isRecommended ? 0.5 : 1
                    }}
                    onClick={() => handleZoneSelect(loadedZone)}
                    $selected={isSelected || isActiveZone}
                    $recommended={loadedZone.isRecommended}
                  >
                    <ZoneHeader>
                      <ZoneLabel>{zone.name}</ZoneLabel>
                    </ZoneHeader>
                    
                    <StorageMapGrid 
                      dimensions={zone.dimensions}
                      positions={zoneOccupancy}
                      style={{ height: '100%', width: '100%' }}
                      data-zone-id={zone.id}
                    />
                    
                    <ZoneFooter>
                      <ZoneCapacity>
                        {occupiedCount} / {capacity}
                      </ZoneCapacity>
                      <OccupancyBar>
                        <OccupancyFill percentage={occupancyPercentage} />
                      </OccupancyBar>
                    </ZoneFooter>
                  </Zone>
                );
              })}
            </MapContent>
          </MapContainer>
        )}
      </>
    );
  };
  
  const getTitle = () => {
    if (assignmentComplete) return 'Assignment Complete';
    if (showRowSelection) return `Select Row in ${selectedZone?.name || 'Zone'}`;
    return 'Select Storage Zone';
  };
  
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>{getTitle()}</ModalTitle>
          <IconButton onClick={onClose}>
            <X size={20} />
          </IconButton>
        </ModalHeader>
        
        <ModalContent>
          {renderContent()}
        </ModalContent>
        
        {!isLoading && !assignmentComplete && !errorMessage && (
          <ModalFooter>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <PrimaryButton 
              onClick={handleAssign}
              disabled={!selectedZone || (showRowSelection && selectedRow === null)}
            >
              {showRowSelection ? 'Assign to Row' : 'Next'}
            </PrimaryButton>
          </ModalFooter>
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default MobileStorageAssignmentModal;

/**
 * Service for managing position assignments with safety-critical constraints
 */

import api from '@lib/api/axios';
import { ZONE_MAP } from '../constants/zoneConfig.v2';
import { createPosition, getPositionKey, isPositionInZone } from '../utils/positionManager';
import { executeWithRetry, enhanceError, ERROR_TYPES } from '../utils/storageApiErrorHandler';
import { createBoundedArray, COLLECTION_LIMITS } from '../utils/collectionManager';
import { validateLocationId } from '../utils/validationUtils';

const API_URL = '/api/storage';

// Maximum positions to return in search results
const MAX_SEARCH_RESULTS = 50;

/**
 * Validates zone existence and structure
 * @param {string} zoneId - Zone ID to validate
 * @returns {Object} Zone configuration
 * @throws {Error} If zone is invalid
 */
const validateZone = (zoneId) => {
  const zone = ZONE_MAP[zoneId];
  if (!zone) {
    throw new Error(`Invalid zone ID: ${zoneId}`);
  }
  if (!zone.dimensions || !zone.dimensions.rows || !zone.dimensions.cols) {
    throw new Error(`Invalid zone configuration for zone ${zoneId}`);
  }
  return zone;
};

/**
 * Gets the next available position in a zone
 * @param {string} zoneId - Zone ID to check
 * @param {number} locationId - Location ID
 * @param {Object} occupancy - Current zone occupancy
 * @returns {Object|null} Next available position or null if zone is full
 */
export const getNextAvailablePosition = (zoneId, locationId, occupancy) => {
  // Input validation
  const zone = validateZone(zoneId);
  validateLocationId(locationId);

  // Validate occupancy data
  if (!occupancy || typeof occupancy !== 'object') {
    throw new Error('Invalid occupancy data');
  }

  // Check zone capacity
  const positions = Object.keys(occupancy);
  if (positions.length >= zone.dimensions.rows * zone.dimensions.cols) {
    return null; // Zone is full
  }

  // Search for first available position
  for (let row = 0; row < zone.dimensions.rows; row++) {
    for (let col = 0; col < zone.dimensions.cols; col++) {
      const position = createPosition(row, col);
      const positionKey = getPositionKey(position);

      // Check all possible key formats for backward compatibility
      const legacyKey1 = `${row}_${col}`;
      const legacyKey2 = `r${row}c${col}`;

      if (!occupancy[positionKey] && !occupancy[legacyKey1] && !occupancy[legacyKey2]) {
        return position;
      }
    }
  }

  return null;
};

/**
 * Assigns a pallet to a specific position
 * @param {string} zoneId - Zone ID
 * @param {Object} position - Position coordinates
 * @param {Object} palletData - Pallet information
 * @param {number} locationId - Location ID
 * @param {Object} options - Assignment options
 * @returns {Promise<Object>} Assignment result
 */
export const assignPalletToPosition = async (
  zoneId,
  position,
  palletData,
  locationId,
  options = {}
) => {
  // Input validation
  const zone = validateZone(zoneId);
  const locationIdNum = validateLocationId(locationId);

  if (!palletData?.id) {
    throw new Error('Invalid pallet data: missing pallet ID');
  }

  // Validate position is within zone bounds
  if (!isPositionInZone(position, zone.dimensions)) {
    throw new Error(`Position (${position.row}, ${position.col}) is outside zone bounds`);
  }

  // Normalize position
  const normalizedPosition = createPosition(position.row, position.col);

  try {
    // Add request metadata
    const requestId = `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
    const notes = options.trackRow ? 'trackRow' : null;

    // Execute assignment with bounded retries
    const response = await executeWithRetry(
      () => api.post(`${API_URL}/assign-to-zone`, {
        palletId: palletData.id,
        zoneId,
        rowIndex: normalizedPosition.row,
        colIndex: normalizedPosition.col,
        locationId: locationIdNum,
        notes,
        requestId,
        updatePalletRecord: true,
        position: normalizedPosition
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          const errorType = error.category || ERROR_TYPES.UNKNOWN;
          // Don't retry certain error types
          if (errorType === ERROR_TYPES.POSITION_OCCUPIED) {
            throw error;
          }
          console.warn(`Retrying assignment (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    return response.data;
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      position: normalizedPosition,
      palletId: palletData.id,
      locationId: locationIdNum,
      operation: 'assignPalletToPosition'
    });
  }
};

/**
 * Finds suitable positions for a product combination
 * @param {string} palletType - Type of pallet
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option/variety
 * @param {number} locationId - Location ID
 * @returns {Promise<Array>} Array of suitable positions
 */
export const findSuitablePositions = async (
  palletType,
  tomatoType,
  tomatoOption,
  locationId
) => {
  // Input validation
  if (!palletType || !tomatoType) {
    throw new Error('Pallet type and tomato type are required');
  }
  const locationIdNum = validateLocationId(locationId);

  try {
    // Create bounded array for results
    const results = createBoundedArray(MAX_SEARCH_RESULTS);

    // Execute search with bounded retries
    const response = await executeWithRetry(
      () => api.get(`${API_URL}/suitable/location/${locationIdNum}`, {
        params: {
          palletType,
          tomatoType,
          tomatoOption,
          limit: MAX_SEARCH_RESULTS
        }
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying position search (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    // Validate and normalize results
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach(position => {
        if (results.isFull()) return;
        
        try {
          const normalizedPosition = createPosition(
            position.rowIndex || position.row,
            position.colIndex || position.col
          );
          results.add({
            ...position,
            ...normalizedPosition
          });
        } catch (error) {
          console.warn('Skipping invalid position:', error.message);
        }
      });
    }

    return results.getAll();
  } catch (error) {
    throw enhanceError(error, {
      palletType,
      tomatoType,
      tomatoOption,
      locationId: locationIdNum,
      operation: 'findSuitablePositions'
    });
  }
};

/**
 * Moves a pallet to a new position
 * @param {string} palletId - Pallet ID to move
 * @param {string} targetZoneId - Target zone ID
 * @param {Object} targetPosition - Target position
 * @param {number} locationId - Location ID
 * @returns {Promise<boolean>} Success status
 */
export const movePallet = async (palletId, targetZoneId, targetPosition, locationId) => {
  // Input validation
  const zone = validateZone(targetZoneId);
  const locationIdNum = validateLocationId(locationId);

  if (!palletId) {
    throw new Error('Pallet ID is required');
  }

  // Validate target position
  if (!isPositionInZone(targetPosition, zone.dimensions)) {
    throw new Error(`Target position is outside zone bounds`);
  }

  try {
    // Execute move with bounded retries
    await executeWithRetry(
      () => api.post(`${API_URL}/move`, {
        palletId,
        zoneId: targetZoneId,
        rowIndex: targetPosition.row,
        colIndex: targetPosition.col,
        locationId: locationIdNum
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying pallet move (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    return true;
  } catch (error) {
    throw enhanceError(error, {
      palletId,
      targetZoneId,
      targetPosition,
      locationId: locationIdNum,
      operation: 'movePallet'
    });
  }
};

export default {
  getNextAvailablePosition,
  assignPalletToPosition,
  findSuitablePositions,
  movePallet
};

import React from 'react';
import styled from 'styled-components';
import { Package, ClipboardList, Boxes, Map, Activity } from 'lucide-react';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const LocationSelect = styled.select`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  background-color: ${({ theme }) => theme.colors.background.paper};
  min-width: 200px;
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.light};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary.main}20`};
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const Tab = styled.button`
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.xl}`};
  border: none;
  background: none;
  ${({ theme }) => theme.typography.body1};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ $active, $disabled, theme }) => 
    $disabled ? theme.colors.text.disabled :
    $active ? theme.colors.primary.main : theme.colors.text.secondary};
  font-weight: ${({ $active }) => ($active ? '600' : '400')};
  border-bottom: 2px solid ${({ $active, $disabled, theme }) => 
    $disabled ? 'transparent' :
    $active ? theme.colors.primary.main : 'transparent'};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  transition: ${({ theme }) => theme.transitions.default};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

  &:hover {
    color: ${({ $disabled, theme }) => 
      $disabled ? theme.colors.text.disabled : theme.colors.primary.main};
  }
`;

const DashboardLayout = ({
  selectedLocation,
  locations,
  onLocationChange,
  activeTab,
  onTabChange,
  children
}) => {
  return (
    <Container>
      <Header>
        <LocationSelect 
          value={selectedLocation?.id || ''} 
          onChange={onLocationChange}
        >
          <option value="">Select Location</option>
          {locations.map(location => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </LocationSelect>
      </Header>

      <TabContainer>
        <Tab
          $active={activeTab === 'overview'}
          onClick={() => onTabChange('overview')}
        >
          <Package />
          Overview
        </Tab>
        <Tab
          $active={activeTab === 'orders'}
          onClick={() => onTabChange('orders')}
        >
          <ClipboardList />
          Orders
        </Tab>
        <Tab
          $active={activeTab === 'pallets'}
          onClick={() => onTabChange('pallets')}
        >
          <Boxes />
          Pallets
        </Tab>
        <Tab
          $active={activeTab === 'map'}
          onClick={() => onTabChange('map')}
        >
          <Map />
          Storage
        </Tab>
        <Tab
          $active={activeTab === 'analytics'}
          onClick={() => onTabChange('analytics')}
        >
          <Activity />
          Analytics
        </Tab>
      </TabContainer>

      {children}
    </Container>
  );
};

export default DashboardLayout;

import { useState, useCallback } from 'react';
import usePalletFiltering from './usePalletFiltering';
import useOrderProcessing from './useOrderProcessing';
import { validateZoneData } from '../utils/validationUtils';
import { findLastScannedPallet } from '../utils/palletUtils';

/**
 * Hook for managing order actions and workflows
 * @param {string} locationId - Location ID
 * @param {Function} showToast - Toast notification function
 * @param {Function} showProcessing - Show processing overlay function
 * @param {Function} hideProcessing - Hide processing overlay function
 */
const useOrderActions = (locationId, showToast, showProcessing, hideProcessing) => {
  // Zone workflow state
  const [zoneWorkflowState, setZoneWorkflowState] = useState({
    showZoneWorkflow: false,
    selectedZone: null,
    selectedZonePallets: [],
    selectedOrder: null
  });

  // Row navigation state
  const [rowNavigationState, setRowNavigationState] = useState({
    isRowBasedNavigation: false,
    selectedRow: null
  });

  // Mark ready modal state
  const [markReadyState, setMarkReadyState] = useState({
    showMarkReadyModal: false,
    orderToMarkReady: null,
    lastScannedPallet: null,
    otherOrdersWithSamePallet: []
  });

  // Import custom hooks
  const {
    getPalletsByZone,
    getZonePallets,
    getScheduledRowPallets,
    calculateTotalBoxes
  } = usePalletFiltering();

  const {
    prepareOrderReadyForShipping,
    handleMarkReadySubmission,
    findRelatedOrders
  } = useOrderProcessing(showToast, showProcessing, hideProcessing);

  /**
   * Handles zone selection and workflow initialization
   */
  const handleZoneClick = useCallback(async (zoneName, rows, order, options = {}) => {
    try {
      validateZoneData(zoneName, rows);
      
      if (!order || !order.pallets) {
        throw new Error("Missing order or pallets data");
      }

      // Determine navigation mode
      const hasScheduledRows = order.scheduled_rows?.length > 0;
      const isRowBased = hasScheduledRows ? true : (options.isRowBasedNavigation || false);

      // Get pallets for this zone
      const zonePallets = hasScheduledRows
        ? getScheduledRowPallets(order.pallets, order.scheduled_rows, zoneName)
        : getZonePallets(order.pallets, zoneName);

      // Update state
      setRowNavigationState(prev => ({
        ...prev,
        isRowBasedNavigation: isRowBased,
        selectedRow: options.selectedRow || null
      }));

      setZoneWorkflowState({
        showZoneWorkflow: true,
        selectedZone: zoneName,
        selectedZonePallets: zonePallets,
        selectedOrder: order
      });

    } catch (error) {
      console.error("Error handling zone click:", error);
      showToast({
        message: `Error: ${error.message}`,
        variant: 'error'
      });
    }
  }, [getScheduledRowPallets, getZonePallets, showToast]);

  /**
   * Prepares and shows the "Mark as Ready" modal
   */
  const handleOrderReadyForShipping = useCallback(async (orderId, orderData) => {
    try {
      const preparedOrder = await prepareOrderReadyForShipping(orderId, orderData);
      if (!preparedOrder) return false;

      const defaultPallet = findLastScannedPallet(preparedOrder);
      const operatorId = preparedOrder.operator_id || preparedOrder.operatorId;

      let otherOrders = [];
      if (operatorId && defaultPallet) {
        otherOrders = await findRelatedOrders(
          defaultPallet.id,
          orderId,
          operatorId
        );
      }

      setMarkReadyState({
        showMarkReadyModal: true,
        orderToMarkReady: preparedOrder,
        lastScannedPallet: defaultPallet,
        otherOrdersWithSamePallet: otherOrders
      });

      return true;
    } catch (error) {
      console.error('Error preparing order ready modal:', error);
      showToast({
        message: `Error: ${error.message}`,
        variant: 'error'
      });
      return false;
    }
  }, [prepareOrderReadyForShipping, findRelatedOrders, showToast]);

  /**
   * Handles form submission from the "Mark as Ready" modal
   */
  const handleMarkReadyConfirm = useCallback(async (formData) => {
    const { orderToMarkReady } = markReadyState;
    if (!orderToMarkReady?.id) {
      showToast({
        message: "Error: Order data is missing or invalid",
        variant: 'error'
      });
      return false;
    }

    const success = await handleMarkReadySubmission(orderToMarkReady.id, formData);
    if (success) {
      setMarkReadyState(prev => ({
        ...prev,
        showMarkReadyModal: false,
        orderToMarkReady: null
      }));
    }
    return success;
  }, [markReadyState, handleMarkReadySubmission, showToast]);

  /**
   * Handles successful scan completion in the zone workflow
   */
  const handleWorkflowScanComplete = useCallback(async (palletId) => {
    showToast({
      message: `Pallet #${palletId} successfully assigned to order`,
      variant: 'success'
    });

    setZoneWorkflowState(prev => ({
      ...prev,
      showZoneWorkflow: false
    }));

    return true;
  }, [showToast]);

  return {
    // Zone workflow state and handlers
    ...zoneWorkflowState,
    handleZoneClick,
    setShowZoneWorkflow: (show) => setZoneWorkflowState(prev => ({ ...prev, showZoneWorkflow: show })),
    setSelectedZone: (zone) => setZoneWorkflowState(prev => ({ ...prev, selectedZone: zone })),
    setSelectedZonePallets: (pallets) => setZoneWorkflowState(prev => ({ ...prev, selectedZonePallets: pallets })),
    setSelectedOrder: (order) => setZoneWorkflowState(prev => ({ ...prev, selectedOrder: order })),

    // Row navigation state
    ...rowNavigationState,
    setIsRowBasedNavigation: (isRow) => setRowNavigationState(prev => ({ ...prev, isRowBasedNavigation: isRow })),
    setSelectedRow: (row) => setRowNavigationState(prev => ({ ...prev, selectedRow: row })),

    // Mark ready modal state and handlers
    ...markReadyState,
    setShowMarkReadyModal: (show) => setMarkReadyState(prev => ({ ...prev, showMarkReadyModal: show })),
    handleOrderReadyForShipping,
    handleMarkReadyConfirm,
    handleWorkflowScanComplete,

    // Utility functions
    getPalletsByZone,
    calculateTotalBoxes
  };
};

export default useOrderActions;

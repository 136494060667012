import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useOutletContext, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  handleShipPallet,
  handleDeletePallet,
  handleAssignOrder,
  handleReturnPallet,
  handleSplitPallet,
  updatePalletStage
} from '@features/shared/services/palletService';
import { PROCESS_STAGES } from '@features/shared/constants/processStages';
import { useToast } from '@lib/contexts';
import { useProcessing } from '@lib/contexts';
import { api } from '@lib/api';
import KleinpakOrderAssignmentModal from '../KleinpakOrderAssignmentModal';
import MobileStorageAssignmentModal from '../MobileStorageAssignmentModal';

const Container = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background.paper};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
`;

const InfoSection = styled.div`
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  
  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ theme }) => theme.typography.body2};
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  font-weight: 500;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: auto;
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.secondary.dark};
          }
        `;
      case 'danger':
        return `
          background-color: ${theme.colors.error.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.error.dark};
          }
        `;
      case 'success':
        return `
          background-color: ${theme.colors.success.main};
          color: ${theme.colors.common.white};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.success.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          border: 1px solid ${theme.colors.border.main};
          color: ${theme.colors.text.primary};
          &:hover:not(:disabled) {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.success.light};
  color: ${({ theme }) => theme.colors.success.main};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-align: center;
  ${({ theme }) => theme.typography.body1};
  margin-bottom: 16px;
`;

const ErrorMessage = styled.div`
  background-color: ${({ theme }) => theme.colors.error.light};
  color: ${({ theme }) => theme.colors.error.main};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-align: center;
  ${({ theme }) => theme.typography.body1};
  margin-bottom: 16px;
`;

export const KleinpakPalletDetailsPage = () => {
  const { locationId, userId, activeConsole } = useOutletContext();
  const { showToast } = useToast();
  const { showProcessing, hideProcessing } = useProcessing();
  const navigate = useNavigate();
  const location = useLocation();
  const { palletId } = useParams();
  
  // Get pallet details from navigation state if available
  const palletDetailsFromState = location.state?.palletDetails;
  
  const [actionSuccess, setActionSuccess] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [palletDetails, setPalletDetails] = useState(
    palletDetailsFromState || { id: palletId, route: 'kleinpak' }
  );
  const [fetchError, setFetchError] = useState(null);
  const [isFetching, setIsFetching] = useState(!palletDetailsFromState);
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);

  // Fetch pallet details when component mounts
  useEffect(() => {
    // If we already have complete pallet details from the navigation state, skip fetching
    if (palletDetailsFromState && Object.keys(palletDetailsFromState).length > 1) {
      return;
    }
    
    const fetchPalletDetails = async () => {
      setIsFetching(true);
      setFetchError(null);
      
      try {
        const response = await api.get(`/api/pallets/${palletId}`);
        setPalletDetails({
          ...response.data,
          id: palletId, // Ensure ID is always set
          route: 'kleinpak' // Ensure route is kleinpak on this page
        });
      } catch (error) {
        console.error('Error fetching pallet details:', error);
        setFetchError(error.message || 'Failed to load pallet details');
        showToast({ 
          message: `Error loading pallet details: ${error.message || 'Unknown error'}`, 
          variant: 'error' 
        });
        
        // Still set minimal pallet details so the page can function
        setPalletDetails({ id: palletId, route: 'kleinpak' });
      } finally {
        setIsFetching(false);
      }
    };

    fetchPalletDetails();
  }, [palletId, showToast, palletDetailsFromState]);
  
  // Pre-fetch orders for the assignment modal when it's about to open
  useEffect(() => {
    const fetchOrders = async () => {
      if (!isOrderModalOpen || orders.length > 0) return;
      
      setLoadingOrders(true);
      try {
        // Fix: Use the correct API endpoint with /location/ in the path
        // Add isKleinpakOrder=true parameter to filter for Kleinpak orders
        const response = await api.get(`/api/orders/location/${locationId}?isKleinpakOrder=true`);
        
        // Add validation to ensure we have an array of orders
        const orderData = response.data;
        
        if (Array.isArray(orderData)) {
          console.log(`Fetched ${orderData.length} total Kleinpak orders`);
          setOrders(orderData);
        } else if (Array.isArray(orderData?.data)) {
          // Handle nested data structure if present
          console.log(`Fetched ${orderData.data.length} total Kleinpak orders`);
          setOrders(orderData.data);
        } else {
          console.error('Invalid order data format:', orderData);
          setOrders([]);
          showToast({
            message: 'Received invalid order data format from server',
            variant: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching Kleinpak orders:', error);
        setOrders([]);
        showToast({
          message: `Error loading Kleinpak orders: ${error.message || 'Unknown error'}`,
          variant: 'error'
        });
      } finally {
        setLoadingOrders(false);
      }
    };
    
    fetchOrders();
  }, [isOrderModalOpen, locationId, orders.length, showToast]);

  const handleAction = async (action, actionName) => {
    setIsProcessing(true);
    showProcessing({ message: `${actionName}ing pallet...`, showOverlay: true });
    try {
      // Handle split action specifically, passing the needed splitAmount
      if (actionName === 'Split') {
        await action(palletId, locationId, 0.5); // Pass splitAmount=0.5 explicitly
      } else {
        await action(palletId, locationId);
      }
      setActionSuccess(true);
      setActionMessage(`Successfully ${actionName.toLowerCase()}ed pallet`);
      showToast({ message: `Pallet ${actionName.toLowerCase()}ed successfully`, variant: 'success' });
    } catch (error) {
      console.error(`Error ${actionName.toLowerCase()}ing pallet:`, error);
      showToast({ message: `Error ${actionName.toLowerCase()}ing pallet: ${error.message}`, variant: 'error' });
    } finally {
      setIsProcessing(false);
      hideProcessing();
    }
  };

  const handleAssignToOrder = () => {
    // Clear existing orders to force a fresh fetch
    setOrders([]);
    
    // Open the order assignment modal
    setIsOrderModalOpen(true);
  };

  const handleOrderAssignment = async (orderId, processingData, palletDetails) => {
    setIsProcessing(true);
    showProcessing({ message: 'Assigning pallet to order...', showOverlay: true });
    try {
      // First, assign the pallet to the order
      await handleAssignOrder(palletId, orderId);
      
      // Then, associate the current operator with this order
      console.log(`Associating operator ${userId} with order ${orderId}`);
      await api.post(`/api/orders/${orderId}/operator`, { 
        operatorId: userId 
      });
      
      setActionSuccess(true);
      setActionMessage('Successfully assigned pallet to order');
      showToast({ message: 'Pallet assigned to order successfully', variant: 'success' });
    } catch (error) {
      console.error('Error assigning pallet to order:', error);
      showToast({ message: `Error assigning pallet to order: ${error.message}`, variant: 'error' });
      throw error; // Re-throw to let the modal handle the error
    } finally {
      setIsProcessing(false);
      hideProcessing();
    }
  };

  const handleReturnToScanner = () => {
    // Check if we have a referrer URL in the location state
    if (location.state?.referrer) {
      // Navigate to the referrer URL directly
      window.location.href = location.state.referrer;
      return;
    }
    
    // Fall back to previously stored URL if available
    const previousUrl = localStorage.getItem('previousScannerUrl');
    if (previousUrl) {
      window.location.href = previousUrl;
      return;
    }
    
    // Last resort fallback to console
    const consoleToReturn = activeConsole || localStorage.getItem('lastActiveConsole') || 'kleinpak';
    navigate(`/mobile/operator/${consoleToReturn}?tab=scan`);
  };

  // Storage zone assignment
  const [showStorageModal, setShowStorageModal] = useState(false);

  const handleAssignToStorage = () => {
    setShowStorageModal(true);
  };

  const handleStorageAssignment = async (result) => {
    if (result.error) {
      showToast({
        message: `Error assigning to storage: ${result.error}`,
        variant: 'error'
      });
      return;
    }

    try {
      // Update the pallet details to include the storage zone
      setPalletDetails(prev => ({
        ...prev,
        storageZone: result.zoneName || result.zoneId
      }));

      showToast({
        message: `Successfully assigned pallet to storage zone ${result.zoneName || result.zoneId}`,
        variant: 'success'
      });
    } catch (error) {
      console.error('Error handling storage assignment:', error);
      showToast({
        message: `Error updating pallet: ${error.message}`,
        variant: 'error'
      });
    }
  };

  // Display loading state while fetching pallet details
  if (isFetching) {
    return (
      <Container>
        <Header>
          <Title>Kleinpak Pallet Details</Title>
        </Header>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          Loading pallet details...
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Kleinpak Pallet Details</Title>
      </Header>

      {fetchError && (
        <ErrorMessage>
          Error loading pallet details: {fetchError}
        </ErrorMessage>
      )}

      <InfoSection>
        <InfoRow>
          <Label>ID</Label>
          <Value>{palletId}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Type</Label>
          <Value>{palletDetails.tomatoType || 'Round'}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Box Type</Label>
          <Value>{palletDetails.boxType || 'Standard'}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Weight</Label>
          <Value>{palletDetails.weight ? `${palletDetails.weight}kg` : '750kg'}</Value>
        </InfoRow>
        <InfoRow>
          <Label>Route</Label>
          <Value>Kleinpak Processing</Value>
        </InfoRow>
      </InfoSection>

      {actionSuccess && (
        <SuccessMessage>{actionMessage}</SuccessMessage>
      )}

      <ActionsContainer>
        {!actionSuccess ? (
          <>
            <Button
              variant="primary"
              onClick={async () => {
                setIsProcessing(true);
                showProcessing({ message: 'Processing pallet...', showOverlay: true });
                try {
                  await updatePalletStage(palletId, { 
                    processStage: PROCESS_STAGES.IN_PROCESSING,
                    sortingGrade: null // Add the missing sortingGrade parameter
                  });
                  setActionSuccess(true);
                  setActionMessage('Successfully processed pallet');
                  showToast({ message: 'Pallet processed successfully', variant: 'success' });
                } catch (error) {
                  console.error('Error processing pallet:', error);
                  showToast({ 
                    message: `Error processing pallet: ${error.message}`, 
                    variant: 'error' 
                  });
                } finally {
                  setIsProcessing(false);
                  hideProcessing();
                }
              }}
              disabled={isProcessing}
            >
              Process pallet
            </Button>
          </>
        ) : (
          <Button
            variant="success"
            onClick={handleReturnToScanner}
          >
            Return to Scanner
          </Button>
        )}
      </ActionsContainer>

      <KleinpakOrderAssignmentModal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        onAssign={handleOrderAssignment}
        palletDetails={palletDetails}
        orders={orders}
        loading={loadingOrders}
      />
      
      <MobileStorageAssignmentModal
        isOpen={showStorageModal}
        onClose={() => setShowStorageModal(false)}
        pallet={palletDetails}
        onAssign={handleStorageAssignment}
        locationId={locationId}
      />
    </Container>
  );
};

export default KleinpakPalletDetailsPage;

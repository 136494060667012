import { api } from '@lib/api';

export const fetchPallets = async (locationId, tomatoType = '') => {
  try {
    const response = await api.get(`/api/pallets/${locationId}`, {
      params: { tomatoType }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching pallets:', error);
    throw error;
  }
};

export const fetchOrders = async (locationId, includeCompleted = false) => {
  try {
    const response = await api.get(`/api/orders/location/${locationId}`, {
      params: { includeCompleted }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const fetchOverviewStats = async (locationId) => {
  try {
    const today = new Date().toISOString().split('T')[0];
    const [totalResponse, shippedResponse, ordersResponse] = await Promise.all([
      api.get(`/api/pallets/${locationId}/total-weight`),
      api.get(`/api/pallets/${locationId}/shipped`, {
        params: { date: today }
      }),
      api.get(`/api/orders/location/${locationId}`, {
        params: { includeCompleted: false }
      })
    ]);

    if (totalResponse.data.success && shippedResponse.data.success && ordersResponse.data.success) {
      // Get active orders count
      const activeOrders = ordersResponse.data.data?.length || 0;

      // Get total pallets (excluding shipped)
      const totalPallets = totalResponse.data.data.totalPallets || 0;

      return {
        totalKgWarehouse: totalResponse.data.data.totalInWarehouse || 0,
        totalKgAssigned: totalResponse.data.data.totalAssigned || 0,
        shippedToday: shippedResponse.data.data.count || 0,
        totalPallets,
        activeOrders,
        todayByType: totalResponse.data.data.todayByType || []
      };
    }
    throw new Error('Failed to fetch overview stats');
  } catch (error) {
    console.error('Error fetching overview stats:', error);
    throw error;
  }
};

export const addPallet = async (palletData) => {
  try {
    const response = await api.post('/api/pallets/create', palletData);
    return response.data;
  } catch (error) {
    console.error('Error adding pallet:', error);
    throw error;
  }
};

export const updatePallet = async (palletId, palletData) => {
  try {
    const response = await api.put(`/api/pallets/update/${palletId}`, palletData);
    return response.data;
  } catch (error) {
    console.error('Error updating pallet:', error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const response = await api.put(`/api/orders/update/${orderId}`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const updateOrderPriority = async (orderId, priority) => {
  try {
    const response = await api.put(`/api/orders/${orderId}/priority`, { priority });
    return response.data;
  } catch (error) {
    console.error('Error updating order priority:', error);
    throw error;
  }
};

export const updateOrderColli = async (orderId, completedColli) => {
  try {
    const response = await api.put(`/api/orders/${orderId}/colli`, { completedColli });
    return response.data;
  } catch (error) {
    console.error('Error updating order colli:', error);
    throw error;
  }
};

export const updateOrderDueDate = async (orderId, dueDate) => {
  try {
    const response = await api.put(`/api/orders/${orderId}/due-date`, { dueDate });
    return response.data;
  } catch (error) {
    console.error('Error updating order due date:', error);
    throw error;
  }
};

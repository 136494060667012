export const palletTypes = [
  'Pool',
  'Euro Chep',
  'Block Chep',
  'Block',
  'LPR',
  'Euro',
  'Single Use Block',
  'Single Use Euro',
  'Air Plane Pallet',
  'DPA'
];

import React from 'react';
import { OrderInfo } from '../styles';

const PalletDetails = ({ pallet }) => {
  console.log('PalletDetails received pallet:', pallet);
  
  if (!pallet) return null;

  // Handle batch case where we have multiple pallets
  if (pallet.isBatch === true) {
    if (!Array.isArray(pallet.pallets) || pallet.pallets.length === 0) {
      console.error('Invalid batch pallet data:', pallet);
      return (
        <OrderInfo style={{ marginBottom: '1rem' }}>
          <h4>Error: Invalid pallet data</h4>
        </OrderInfo>
      );
    }

    return (
      <OrderInfo style={{ marginBottom: '1rem' }}>
        <h4>Selected Pallets ({pallet.pallets.length})</h4>
        {pallet.pallets.map((p, index) => (
          <div key={p.id} style={{ marginBottom: index < pallet.pallets.length - 1 ? '1rem' : 0 }}>
            <p>Pallet {index + 1}:</p>
            <p>Type: {p.tomatoType}</p>
            <p>Box Type: {p.boxType}</p>
            <p>Weight: {p.weight}kg</p>
          </div>
        ))}
      </OrderInfo>
    );
  }

  // Handle single pallet case - pallet should be a direct pallet object
  if (!pallet.id || !pallet.tomatoType) {
    console.error('Invalid single pallet data:', pallet);
    return (
      <OrderInfo style={{ marginBottom: '1rem' }}>
        <h4>Error: Invalid pallet data</h4>
      </OrderInfo>
    );
  }

  return (
    <OrderInfo style={{ marginBottom: '1rem' }}>
      <h4>Selected Pallet</h4>
      <p>Type: {pallet.tomatoType}</p>
      <p>Box Type: {pallet.boxType}</p>
      <p>Weight: {pallet.weight}kg</p>
    </OrderInfo>
  );
};

export default PalletDetails;

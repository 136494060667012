import config from '../config/palletBoxConfig.json';

class PalletBoxConfigService {
  constructor() {
    this.config = config;
  }

  /**
   * Find the base configuration that applies to a pallet type
   * @param {string} palletType - Type of pallet
   * @returns {Object} The configuration to use for this pallet type
   */
  findConfigForPalletType(palletType) {
    // Check special configurations first
    if (this.config.specialConfigs[palletType]) {
      return this.config.specialConfigs[palletType];
    }

    // Check base configurations
    for (const [baseType, baseConfig] of Object.entries(this.config.baseConfigs)) {
      if (baseConfig.appliesTo.includes(palletType)) {
        return baseConfig;
      }
    }

    // Return default configuration if no specific config found
    return this.config.defaults['*'];
  }

  /**
   * Get handling rules for a specific pallet-box combination
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @returns {Object} Rules for the combination
   */
  getHandlingRules(palletType, boxType) {
    const palletConfig = this.findConfigForPalletType(palletType);
    
    // Get specific box config or fallback to pallet's default
    const boxConfig = palletConfig.boxes[boxType] || palletConfig.boxes['*'];

    return {
      maxLayers: boxConfig.maxLayers,
      boxesPerLayer: boxConfig.boxesPerLayer
    };
  }

  /**
   * Validate if a pallet-box combination is allowed
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @returns {boolean} Whether the combination is valid
   */
  isValidCombination(palletType, boxType) {
    try {
      this.getHandlingRules(palletType, boxType);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Calculate total box capacity for a pallet-box combination
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @returns {number} Total box capacity
   */
  calculateTotalCapacity(palletType, boxType) {
    const rules = this.getHandlingRules(palletType, boxType);
    return rules.maxLayers * rules.boxesPerLayer;
  }

  /**
   * Get maximum layers allowed for a pallet-box combination
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @returns {number} Maximum layers allowed
   */
  getMaxLayers(palletType, boxType) {
    return this.getHandlingRules(palletType, boxType).maxLayers;
  }

  /**
   * Get boxes per layer for a pallet-box combination
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @returns {number} Boxes per layer
   */
  getBoxesPerLayer(palletType, boxType) {
    return this.getHandlingRules(palletType, boxType).boxesPerLayer;
  }

  /**
   * Calculate total boxes for a specific configuration
   * @param {string} palletType - Type of pallet
   * @param {string} boxType - Type of box
   * @param {number} layers - Number of layers
   * @param {number} [additionalBoxes=0] - Additional boxes on top
   * @returns {number} Total number of boxes
   */
  calculateTotalBoxes(palletType, boxType, layers, additionalBoxes = 0) {
    const boxesPerLayer = this.getBoxesPerLayer(palletType, boxType);
    return (boxesPerLayer * layers) + additionalBoxes;
  }

  /**
   * Get all pallet types that use a specific base configuration
   * @param {string} baseType - The base configuration type ('euro' or 'pool')
   * @returns {string[]} Array of pallet types using this base configuration
   */
  getPalletTypesForBaseConfig(baseType) {
    return this.config.baseConfigs[baseType]?.appliesTo || [];
  }
}

// Export a singleton instance
export const palletBoxConfigService = new PalletBoxConfigService();

// Also export the class for testing purposes
export { PalletBoxConfigService };

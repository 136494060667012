import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
`;

const Corner = styled(motion.div)`
  position: absolute;
  width: 30px;
  height: 30px;
  border-color: ${({ theme }) => theme.colors.primary};
  opacity: 0.8;
  
  ${({ position }) => {
    switch (position) {
      case 'topLeft':
        return `
          top: 20%;
          left: 20%;
          border-left: 4px solid;
          border-top: 4px solid;
        `;
      case 'topRight':
        return `
          top: 20%;
          right: 20%;
          border-right: 4px solid;
          border-top: 4px solid;
        `;
      case 'bottomLeft':
        return `
          bottom: 20%;
          left: 20%;
          border-left: 4px solid;
          border-bottom: 4px solid;
        `;
      case 'bottomRight':
        return `
          bottom: 20%;
          right: 20%;
          border-right: 4px solid;
          border-bottom: 4px solid;
        `;
      default:
        return '';
    }
  }}
`;

const scanAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ScanLine = styled(motion.div)`
  position: absolute;
  left: 20%;
  right: 20%;
  height: 2px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    ${({ theme }) => theme.colors.primary}88 20%,
    ${({ theme }) => theme.colors.primary} 50%,
    ${({ theme }) => theme.colors.primary}88 80%,
    transparent 100%
  );
  box-shadow: 0 0 8px ${({ theme }) => theme.colors.primary};
  animation: ${scanAnimation} 2s linear infinite;
`;

const StatusContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const StatusText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.6);
  padding: 8px 16px;
  border-radius: 20px;
  backdrop-filter: blur(4px);
`;

const LoadingSpinner = styled(motion.div)`
  width: 24px;
  height: 24px;
  border: 3px solid ${({ theme }) => theme.colors.primary}33;
  border-top-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
`;

const GuideText = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  background: rgba(0, 0, 0, 0.6);
  padding: 8px;
  margin: 0 auto;
  width: fit-content;
  border-radius: 20px;
  backdrop-filter: blur(4px);
`;

export const ScannerOverlay = ({ status, isInitializing }) => {
  return (
    <OverlayContainer>
      <Corner
        position="topLeft"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.8, scale: 1 }}
        transition={{ duration: 0.3 }}
      />
      <Corner
        position="topRight"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.8, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      />
      <Corner
        position="bottomLeft"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.8, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      />
      <Corner
        position="bottomRight"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.8, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      />
      
      {!isInitializing && status === 'Ready to scan' && (
        <>
          <ScanLine />
          <GuideText>Position QR code within the frame</GuideText>
        </>
      )}
      
      <StatusContainer>
        {isInitializing && (
          <LoadingSpinner
            animate={{ rotate: 360 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        )}
        <StatusText>{status}</StatusText>
      </StatusContainer>
    </OverlayContainer>
  );
};

import React from 'react';
import styled from 'styled-components';

// Styled components for grid overlay
const GridContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

const GridLine = styled.div`
  position: absolute;
  background-color: rgba(200, 200, 200, 0.15);
  
  ${props => props.horizontal ? `
    left: 0;
    width: 100%;
    height: 1px;
    top: ${props.position}px;
  ` : `
    top: 0;
    height: 100%;
    width: 1px;
    left: ${props.position}px;
  `}
  
  &:nth-child(5n) {
    background-color: rgba(100, 100, 200, 0.2);
  }
`;

const GridLabel = styled.div`
  position: absolute;
  font-size: 8px;
  color: rgba(100, 100, 200, 0.6);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1px 2px;
  border-radius: 2px;
  z-index: 2;
  user-select: none;
  
  ${props => props.isX ? `
    top: 2px;
    left: ${props.position}px;
    transform: translateX(-50%);
  ` : `
    left: 2px;
    top: ${props.position}px;
    transform: translateY(-50%);
  `}
  
  ${props => (props.isX && props.value % 10 === 0) || (!props.isX && props.value % 10 === 0) ? `
    font-weight: bold;
    color: rgba(80, 80, 180, 0.8);
    background-color: rgba(255, 255, 255, 0.7);
  ` : ''}
`;

/**
 * GridOverlay Component
 * 
 * Renders a coordinate grid overlay that helps with positioning elements
 * 
 * @param {Object} props
 * @param {Object} props.gridSize - Size of the grid in cells (width, height)
 * @param {number} props.cellSize - Size of each cell in pixels
 * @param {boolean} props.showLabels - Whether to show coordinate labels
 * @param {boolean} props.showCells - Whether to show cell borders
 */
const GridOverlay = ({ 
  gridSize = { width: 45, height: 40 }, 
  cellSize = 16,
  showLabels = true,
  showCells = false,
}) => {
  // Generate vertical lines (columns)
  const verticalLines = [];
  for (let i = 0; i <= gridSize.width; i++) {
    verticalLines.push(
      <GridLine 
        key={`v-${i}`} 
        position={i * cellSize} 
        horizontal={false} 
      />
    );
    
    if (showLabels && i % 5 === 0) {
      verticalLines.push(
        <GridLabel 
          key={`v-label-${i}`} 
          position={i * cellSize} 
          isX={true}
          value={i}
        >
          {i}
        </GridLabel>
      );
    }
  }
  
  // Generate horizontal lines (rows)
  const horizontalLines = [];
  for (let i = 0; i <= gridSize.height; i++) {
    horizontalLines.push(
      <GridLine 
        key={`h-${i}`} 
        position={i * cellSize} 
        horizontal={true} 
      />
    );
    
    if (showLabels && i % 5 === 0) {
      horizontalLines.push(
        <GridLabel 
          key={`h-label-${i}`} 
          position={i * cellSize} 
          isX={false}
          value={i}
        >
          {i}
        </GridLabel>
      );
    }
  }
  
  return (
    <GridContainer>
      {/* Grid lines */}
      {verticalLines}
      {horizontalLines}
    </GridContainer>
  );
};

export default GridOverlay;

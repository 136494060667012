import React, { useState, useContext } from 'react';
import { PalletForm } from '@features/shared/components/PalletForm';
import { withStorageIntegration } from '@features/operator/components/FinishMenu/StorageIntegration';
import { useProcessing } from '@lib/contexts/ProcessingContext';
import { useToast } from '@lib/contexts/ToastContext';
import { UserContext } from '@lib/contexts/UserContext';
import { api } from '@lib/api';
import { Plus } from 'lucide-react';
import { 
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalContent,
  Title,
  CloseButton,
  CloseIcon,
  AddButton
} from './styles';

/**
 * AddPalletModal component provides a modal interface for adding new pallets
 * with storage zone integration.
 */
export const AddPalletModal = ({ 
  isOpen, 
  onClose, 
  locationId,
  onPalletAdded
}) => {
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  const { user } = useContext(UserContext);
  const [createdPalletData, setCreatedPalletData] = useState(null);

  if (!isOpen) return null;

  // Handle pallet creation
  const handleAddPallet = async (palletData) => {
    try {
      showProcessing({ 
        message: 'Creating pallet' + (palletData.quantity > 1 ? 's' : '') + '...', 
        showOverlay: true 
      });

      // API call to create pallet(s) with proper authentication
      const response = await api.post('/api/pallets/add', {
        ...palletData,
        locationId,
        userName: user?.username,
        user_id: user?.id
      });

      const createdPallet = response.data;
      setCreatedPalletData(createdPallet);

      // After successful creation, storage zone assignment will be handled by the HOC
      // The withStorageIntegration HOC will intercept the created pallet and show the zone selection UI
      
      showToast({ 
        message: `Successfully created ${palletData.quantity > 1 ? palletData.quantity + ' pallets' : 'a pallet'}`, 
        variant: 'success' 
      });

      if (onPalletAdded) {
        onPalletAdded(createdPallet);
      }

      // Don't close the modal yet - the storage integration will handle that
      // The withStorageIntegration HOC will show a zone selection modal/dialog

    } catch (error) {
      console.error('Error creating pallet:', error);
      showToast({ 
        message: `Error creating pallet: ${error.message}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
    }
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <Title>Add New Pallet</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <ModalContent>
          <PalletForm
            onPalletAdded={handleAddPallet}
            onCancel={onClose}
            locationId={locationId}
          />
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

// Create an enhanced version with storage zone integration
// This will automatically show the zone selection UI after pallet creation
export const StorageEnabledAddPalletModal = withStorageIntegration(AddPalletModal);

// Export both versions
export default StorageEnabledAddPalletModal;

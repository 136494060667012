import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScrollableMenu = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  -webkit-overflow-scrolling: touch;
  
  /* Hide scrollbar */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuButton = styled.button`
  flex: 0 0 auto;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  border: none;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : 'transparent'};
  color: ${({ active, theme }) => 
    active ? theme.colors.common.white : theme.colors.text.secondary};
  font-weight: 500;
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;
  white-space: nowrap;
  
  &:hover:not(:disabled) {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.hover};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

export const ContentArea = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const InfoCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 24px;
  margin: 20px;
  box-shadow: ${({ theme }) => theme.shadows.sm};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Message = styled.h2`
  ${({ theme }) => theme.typography.h4};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0 0 12px 0;
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 0 0 24px 0;
`;

export const ProductionForm = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin: 20px;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 500;
  gap: 8px;
`;

export const Input = styled.input`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: 16px;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.light};
  }
`;

export const Button = styled.button`
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-weight: 500;
  cursor: pointer;
  
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  div {
    margin-top: 16px;
  }
`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid ${({ theme }) => theme.colors.background.hover};
  border-top: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.error.light};
  color: ${({ theme }) => theme.colors.error.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-align: center;
`;

export const RetryButton = styled.button`
  margin-top: 16px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const DashboardLink = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.fast};
  
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lighter};
  }
`;

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { PlainFinishMenu } from '@features/operator/components/FinishMenu';
import { withStorageIntegration } from '@features/operator/components/FinishMenu/StorageIntegration';

// Create a FinishMenu that uses traditional storage integration
const StorageEnabledFinishMenu = withStorageIntegration(PlainFinishMenu);

export const FinishMenu = (props) => {
  const { locationId } = useOutletContext();
  
  return (
    <StorageEnabledFinishMenu
      {...props}
      locationId={locationId}
    />
  );
};

import React, { useState, useEffect } from 'react';
import { CheckCircle, AlertTriangle, ArrowRight, Tag, Layers } from 'lucide-react';
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  SaveButton,
  CancelButton,
  ErrorMessage,
  RadioGroup,
  RadioOption
} from '../styles';

const MarkOrderReadyModal = ({
  isOpen,
  onClose,
  onConfirm,
  order,
  lastScannedPallet,
  otherOrdersWithSamePallet = []
}) => {
  const [remainingLayers, setRemainingLayers] = useState('');
  const [remainingBoxes, setRemainingBoxes] = useState('');
  const [transferOption, setTransferOption] = useState('storage');
  const [targetOrderId, setTargetOrderId] = useState('');
  const [error, setError] = useState('');
  
  // State for mix orders and pallets
  const [isMixOrder, setIsMixOrder] = useState(false);
  const [selectedPalletId, setSelectedPalletId] = useState('');
  const [secondPalletId, setSecondPalletId] = useState('');
  const [secondRemainingLayers, setSecondRemainingLayers] = useState('');
  const [secondRemainingBoxes, setSecondRemainingBoxes] = useState('');

  // Reset form when a new order is selected
  useEffect(() => {
    if (isOpen) {
      setRemainingLayers('');
      setRemainingBoxes('');
      setTransferOption('storage');
      setTargetOrderId('');
      setError('');
      // Set last scanned pallet as default if available
      setSelectedPalletId(lastScannedPallet?.id || '');
      
      // Reset secondary pallet fields
      setSecondPalletId('');
      setSecondRemainingLayers('');
      setSecondRemainingBoxes('');
      
      // Check for "mix" in order title and set flag accordingly
      const orderTitle = order?.orderNumber || '';
      const hasMixInTitle = typeof orderTitle === 'string' && 
        orderTitle.toLowerCase().includes('mix');
      setIsMixOrder(hasMixInTitle);
    }
  }, [isOpen, order?.id, lastScannedPallet]);

  // Helper to get pallets from scheduled rows
  const getPalletsInScheduledRows = () => {
    if (!order || !order.pallets || !Array.isArray(order.pallets) || 
        !order.scheduled_rows || !Array.isArray(order.scheduled_rows) || 
        order.scheduled_rows.length === 0) {
      return [];
    }
    
    // Create a map of zoneId -> rowIndices for quick lookup
    const rowLookup = {};
    order.scheduled_rows.forEach(row => {
      if (!rowLookup[row.zoneId]) {
        rowLookup[row.zoneId] = [];
      }
      rowLookup[row.zoneId].push(row.rowIndex);
      // Also add string version for robust matching
      rowLookup[row.zoneId].push(String(row.rowIndex));
    });
    
    // Also create string-based lookup for different ID formats
    const stringZoneLookup = {};
    Object.keys(rowLookup).forEach(zoneId => {
      stringZoneLookup[String(zoneId)] = rowLookup[zoneId];
    });
    
    // Find pallets positioned in scheduled rows and assigned to this order
    return order.pallets.filter(pallet => {
      if (!pallet) return false;
      
      // Check if pallet is assigned to this order
      const palletOrderId = pallet.order_id || pallet.orderId;
      const isAssignedToThisOrder = palletOrderId && String(palletOrderId) === String(order.id);
      
      if (!isAssignedToThisOrder) return false;
      
      // Check if pallet has a valid processing state
      const hasValidProcessStage = pallet.processStage === 'Processing' || 
                                   pallet.processStage === 'Processed' ||
                                   pallet.isScanned === true;
      
      if (!hasValidProcessStage) return false;
      
      // Check multiple position formats to see if pallet is in a scheduled row
      let isInScheduledRow = false;
      
      // Check direct properties
      if (pallet.zoneId !== undefined && pallet.rowIndex !== undefined) {
        isInScheduledRow = (rowLookup[pallet.zoneId] && rowLookup[pallet.zoneId].includes(pallet.rowIndex)) ||
                           (stringZoneLookup[String(pallet.zoneId)] && 
                            stringZoneLookup[String(pallet.zoneId)].includes(pallet.rowIndex));
      }
      
      // Check position object
      if (!isInScheduledRow && pallet.position) {
        const zoneId = pallet.position.zoneId;
        const rowIndex = pallet.position.row !== undefined ? pallet.position.row : pallet.position.rowIndex;
        
        if (zoneId !== undefined && rowIndex !== undefined) {
          isInScheduledRow = (rowLookup[zoneId] && rowLookup[zoneId].includes(rowIndex)) ||
                             (stringZoneLookup[String(zoneId)] && 
                              stringZoneLookup[String(zoneId)].includes(rowIndex));
        }
      }
      
      // Check storage zone string format
      if (!isInScheduledRow && pallet.storageZone) {
        const match = String(pallet.storageZone).match(/^(zone-\d+)-row-(\d+)$/);
        if (match) {
          const zoneId = match[1];
          const rowIndex = parseInt(match[2], 10);
          
          isInScheduledRow = (rowLookup[zoneId] && rowLookup[zoneId].includes(rowIndex)) ||
                             (stringZoneLookup[String(zoneId)] && 
                              stringZoneLookup[String(zoneId)].includes(rowIndex));
        }
      }
      
      return isInScheduledRow;
    });
  };

  // Helper to get all pallets associated with this order
  const getOrderPallets = () => {
    if (!order || !order.pallets || !Array.isArray(order.pallets)) {
      console.log("Order or pallets array is missing:", order);
      return [];
    }
    
    // Get the current order ID as string for consistent comparison
    const currentOrderId = String(order.id);
    
    // Check if this order uses row-based scheduling
    const hasScheduledRows = order.scheduled_rows && 
                             Array.isArray(order.scheduled_rows) && 
                             order.scheduled_rows.length > 0;
    
    // If using row scheduling, ONLY use pallets from those rows
    if (hasScheduledRows) {
      const rowBasedPallets = getPalletsInScheduledRows();
      console.log(`MarkOrderReadyModal: Using ${rowBasedPallets.length} pallets from scheduled rows`);
      return rowBasedPallets;
    }
    
    // Otherwise, use allowed_pallet_ids if available
    let allowedPalletIds = [];
    if (order.allowed_pallet_ids) {
      if (Array.isArray(order.allowed_pallet_ids)) {
        allowedPalletIds = order.allowed_pallet_ids.map(id => String(id));
      } else if (typeof order.allowed_pallet_ids === 'string') {
        try {
          // Try to parse if it's a JSON string
          allowedPalletIds = JSON.parse(order.allowed_pallet_ids).map(id => String(id));
        } catch (e) {
          console.log("Error parsing allowed_pallet_ids:", e);
        }
      }
    }
    
    // Get strictly filtered pallets that are ACTUALLY assigned to this order
    const actuallyAssignedPallets = order.pallets.filter(pallet => {
      if (!pallet) return false;
      
      // Convert to string for consistent comparison
      const palletId = String(pallet.id);
      
      // Safely access order_id or orderId with fallback
      const palletOrderId = pallet.order_id || pallet.orderId;
      const palletOrderIdStr = palletOrderId ? String(palletOrderId) : '';
      
      // Check if this pallet is actually assigned to this order (MOST IMPORTANT CHECK)
      const isAssignedToThisOrder = palletOrderIdStr === currentOrderId;
      
      // Check process stage - we only want pallets that have been processed
      // Pallets can be in the scheduled_pallets array but not actually assigned/processed
      const hasValidProcessStage = pallet.processStage === 'Processing' || 
                                  pallet.processStage === 'Processed' ||
                                  pallet.isScanned === true;
      
      // Additional check: if we have allowed_pallet_ids, make sure this pallet is in that list
      const isInAllowedList = allowedPalletIds.length === 0 || allowedPalletIds.includes(palletId);
      
      // A pallet must be BOTH actually assigned to this order AND have a valid processing state
      return isAssignedToThisOrder && hasValidProcessStage && isInAllowedList;
    });
    
    // Add logging to show which pallets were filtered out and why
    const notAssignedCount = order.pallets.filter(p => {
      const palletOrderId = p?.order_id || p?.orderId;
      return !palletOrderId || String(palletOrderId) !== currentOrderId;
    }).length;
    
    const notProcessedCount = order.pallets.filter(p => {
      const palletOrderId = p?.order_id || p?.orderId;
      const isAssignedToThisOrder = palletOrderId && String(palletOrderId) === currentOrderId;
      const hasValidProcessStage = p?.processStage === 'Processing' || 
                                  p?.processStage === 'Processed' ||
                                  p?.isScanned === true;
      return isAssignedToThisOrder && !hasValidProcessStage;
    }).length;
    
    console.log(`Pallet filtering for order ${currentOrderId}:
      Total pallets: ${order.pallets.length}
      Not assigned to this order: ${notAssignedCount}
      Assigned but not processed: ${notProcessedCount}
      Actually assigned and processed: ${actuallyAssignedPallets.length}`);
    
    return actuallyAssignedPallets;
  };
  
  // Helper to generate the pallet details text with enhanced information
  const getPalletDetails = (pallet) => {
    if (!pallet) return 'No pallet information available';
    
    // Handle potential inconsistencies in field names (colli_count, box_count, boxCount)
    const boxCount = pallet.colli_count !== undefined ? 
                    pallet.colli_count : 
                    (pallet.box_count !== undefined ? 
                        pallet.box_count : 
                        (pallet.boxCount !== undefined ? 
                            pallet.boxCount : 0));
    
    // Include box type if available
    const boxType = pallet.boxType || order?.boxType || 'Unknown';
    
    // Create a more detailed description with pallet ID and box count
    return `Pallet #${pallet.id} - ${boxCount} boxes (${boxType})`;
  };

  const handleSubmit = () => {
    try {
      // Validate pallet selection
      if (!selectedPalletId) {
        setError('Please select a pallet from the dropdown');
        return;
      }
      
      // Additional validation for mix orders
      if (isMixOrder && !secondPalletId) {
        setError('Please select a secondary pallet for this mix order');
        return;
      }
      
      // Parse values for primary pallet, treating empty strings as 0
      const layersValue = remainingLayers === '' ? 0 : parseInt(remainingLayers, 10);
      const boxesValue = remainingBoxes === '' ? 0 : parseInt(remainingBoxes, 10);
      
      // Parse values for secondary pallet (only for mix orders)
      const secondLayersValue = secondRemainingLayers === '' ? 0 : parseInt(secondRemainingLayers, 10);
      const secondBoxesValue = secondRemainingBoxes === '' ? 0 : parseInt(secondRemainingBoxes, 10);
      
      // Validate inputs only when they have values
      if (remainingLayers !== '' && layersValue < 0) {
        setError('Remaining layers cannot be negative');
        return;
      }

      if (remainingBoxes !== '' && boxesValue < 0) {
        setError('Remaining boxes cannot be negative');
        return;
      }
      
      // Validate secondary pallet values
      if (isMixOrder) {
        if (secondRemainingLayers !== '' && secondLayersValue < 0) {
          setError('Secondary pallet remaining layers cannot be negative');
          return;
        }

        if (secondRemainingBoxes !== '' && secondBoxesValue < 0) {
          setError('Secondary pallet remaining boxes cannot be negative');
          return;
        }
      }

      // If user selected to transfer to another order, validate they selected an order
      if (transferOption === 'transfer' && !targetOrderId) {
        setError('Please select a target order');
        return;
      }

      // Prepare data for submission
      const data = {
        remainingLayers: layersValue,
        remainingBoxes: boxesValue,
        palletId: selectedPalletId,
        transferOption,
        targetOrderId: transferOption === 'transfer' ? targetOrderId : null,
        isMixOrder,
        // Include secondary pallet data if it's a mix order
        secondPalletId: isMixOrder ? secondPalletId : null,
        secondRemainingLayers: isMixOrder ? secondLayersValue : null,
        secondRemainingBoxes: isMixOrder ? secondBoxesValue : null
      };

      console.log("Submitting form data:", data);
      onConfirm(data);
    } catch (error) {
      console.error("Error in form submission:", error);
      setError(`Form submission error: ${error.message}`);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          Mark Order as Ready
          <ModalCloseButton onClick={onClose}>×</ModalCloseButton>
        </ModalHeader>
        <ModalBody>
          {/* Mix Order Checkbox */}
          <label style={{ 
            display: 'flex', 
            alignItems: 'center',
            padding: '12px',
            background: '#f5f5f5',
            borderRadius: '4px',
            marginBottom: '16px'
          }}>
            <input
              type="checkbox"
              checked={isMixOrder}
              onChange={(e) => setIsMixOrder(e.target.checked)}
              style={{ marginRight: '10px' }}
            />
            <span>
              <strong>This is a mix order</strong> 
              <span style={{ marginLeft: '5px', fontSize: '0.85rem', color: '#666' }}>
                (Remaining layers and boxes will be on 2 pallets)
              </span>
            </span>
          </label>

          <div style={{ marginBottom: '16px' }}>
            <p>
              You're about to mark Order #{order?.orderNumber || order?.id} as ready for shipping.
            </p>
            <p style={{ 
              fontSize: '0.9rem', 
              color: '#555', 
              background: '#f9f9f9', 
              padding: '8px 12px', 
              borderLeft: '3px solid #3498db',
              marginTop: '8px' 
            }}>
              <strong>Important:</strong> Select the pallet ID from the sticker you've kept. This will be used to track any remaining boxes.
            </p>
          </div>
          
          {/* First Pallet Section */}
          <div style={{ marginBottom: '20px' }}>
            <h4 style={{ 
              margin: '0 0 10px 0', 
              borderBottom: '1px solid #eee', 
              paddingBottom: '5px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px'
            }}>
              <Layers size={16} />
              {isMixOrder ? 'Primary Pallet' : 'Pallet Details'}
            </h4>
            
            <Label style={{ fontWeight: 'bold', marginBottom: '8px' }}>
              <Tag size={16} style={{ marginRight: '6px' }} />
              Select Pallet (from sticker):
            </Label>
            <select
              value={selectedPalletId}
              onChange={(e) => setSelectedPalletId(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginBottom: '8px'
              }}
            >
              <option value="">-- Select a pallet from your sticker --</option>
              {getOrderPallets().map(pallet => (
                <option key={pallet.id} value={pallet.id}>
                  Pallet #{pallet.id} {pallet.id === lastScannedPallet?.id ? '(Last Scanned)' : ''}
                </option>
              ))}
            </select>
            
            {selectedPalletId && (
              <div style={{ padding: '12px', background: '#f5f5f5', borderRadius: '4px', fontSize: '1rem' }}>
                {getPalletDetails(getOrderPallets().find(p => String(p.id) === String(selectedPalletId)))}
              </div>
            )}
            
            <div style={{ fontSize: '0.85rem', color: '#666', marginTop: '6px', fontStyle: 'italic' }}>
              Enter the pallet ID from the sticker you've kept from this order
            </div>
          </div>

          <Label>
            Remaining Layers:
            <Input
              type="number"
              min="0"
              value={remainingLayers}
              onChange={(e) => setRemainingLayers(e.target.value)}
              placeholder="Enter number of layers left (optional)"
            />
          </Label>

          <Label>
            Remaining Boxes:
            <Input
              type="number"
              min="0"
              value={remainingBoxes}
              onChange={(e) => setRemainingBoxes(e.target.value)}
              placeholder="Enter number of boxes left (optional)"
            />
          </Label>
          
          {/* Secondary Pallet Section - Only visible for mix orders */}
          {isMixOrder && (
            <div style={{ 
              marginTop: '24px',
              marginBottom: '16px', 
              padding: '15px', 
              background: '#f9f9f9', 
              borderRadius: '4px',
              border: '1px dashed #ccc'
            }}>
              <h4 style={{ 
                margin: '0 0 10px 0', 
                borderBottom: '1px solid #e5e5e5', 
                paddingBottom: '5px',
                display: 'flex',
                alignItems: 'center',
                gap: '6px'
              }}>
                <Layers size={16} />
                Secondary Pallet
              </h4>
              
              <Label style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                <Tag size={16} style={{ marginRight: '6px' }} />
                Select Second Pallet:
              </Label>
              <select
                value={secondPalletId}
                onChange={(e) => setSecondPalletId(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  marginBottom: '8px'
                }}
              >
                <option value="">-- Select a second pallet --</option>
                {getOrderPallets()
                  .filter(pallet => String(pallet.id) !== String(selectedPalletId)) // Filter out the first selected pallet
                  .map(pallet => (
                    <option key={pallet.id} value={pallet.id}>
                      Pallet #{pallet.id}
                    </option>
                  ))}
              </select>
              
              {secondPalletId && (
                <div style={{ padding: '12px', background: '#f0f0f0', borderRadius: '4px', fontSize: '0.9rem', marginBottom: '12px' }}>
                  {getPalletDetails(getOrderPallets().find(p => String(p.id) === String(secondPalletId)))}
                </div>
              )}
              
              <Label>
                Remaining Layers (Second Pallet):
                <Input
                  type="number"
                  min="0"
                  value={secondRemainingLayers}
                  onChange={(e) => setSecondRemainingLayers(e.target.value)}
                  placeholder="Enter number of layers left"
                />
              </Label>

              <Label>
                Remaining Boxes (Second Pallet):
                <Input
                  type="number"
                  min="0"
                  value={secondRemainingBoxes}
                  onChange={(e) => setSecondRemainingBoxes(e.target.value)}
                  placeholder="Enter number of boxes left"
                />
              </Label>
            </div>
          )}

          {selectedPalletId && (parseFloat(remainingLayers) > 0 || parseFloat(remainingBoxes) > 0) && (
            <div style={{ marginTop: '20px' }}>
              <Label style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                What would you like to do with the remaining boxes?
              </Label>
              
              <RadioGroup>
                <RadioOption 
                  selected={transferOption === 'storage'}
                  onClick={() => setTransferOption('storage')}
                >
                  <span>Return pallet to storage</span>
                </RadioOption>
                
                {otherOrdersWithSamePallet && otherOrdersWithSamePallet.length > 0 && (
                  <RadioOption 
                    selected={transferOption === 'transfer'}
                    onClick={() => setTransferOption('transfer')}
                  >
                    <span>Transfer to another order</span>
                  </RadioOption>
                )}
              </RadioGroup>

              {transferOption === 'transfer' && otherOrdersWithSamePallet && otherOrdersWithSamePallet.length > 0 && (
                <div style={{ marginTop: '12px', paddingLeft: '20px' }}>
                  <Label>
                    Select Target Order:
                    <select
                      value={targetOrderId}
                      onChange={(e) => setTargetOrderId(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        marginTop: '8px'
                      }}
                    >
                      <option value="">-- Select an order --</option>
                      {otherOrdersWithSamePallet.map(order => (
                        <option key={order.id} value={order.id}>
                          Order #{order.orderNumber || order.id} - {order.customer_name}
                        </option>
                      ))}
                    </select>
                  </Label>
                </div>
              )}
            </div>
          )}

          {error && (
            <ErrorMessage>
              <AlertTriangle size={16} />
              {error}
            </ErrorMessage>
          )}
        </ModalBody>
        <ModalFooter>
          <CancelButton onClick={onClose}>
            Cancel
          </CancelButton>
          <SaveButton onClick={handleSubmit}>
            <CheckCircle size={16} />
            Mark as Ready
          </SaveButton>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default MarkOrderReadyModal;

import React, { createContext, useState, useEffect } from 'react';
import { validateToken, clearAuthData, checkRedirectLoop } from '@features/auth/services/tokenService';
import { config } from '@config/env';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null;
  });
  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [tokenValidated, setTokenValidated] = useState(false);

  // Update localStorage when user changes
  useEffect(() => {
    if (user) {
      localStorage.setItem('userData', JSON.stringify(user));
    }
  }, [user]);

  // Validate token on initial load
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('userData');
    
    console.log('UserContext: Checking auth state', {
      isDevelopment: config.isDevelopment,
      hasToken: !!token,
      hasUserData: !!userData,
      tokenValidated
    });

    // Skip validation in development mode
    if (config.isDevelopment) {
      console.log('Development mode: skipping token validation');
      setTokenValidated(true);
      return;
    }
    
    // Only validate if both token and userData exist
    if (token && userData && !tokenValidated) {
      const validateUserToken = async () => {
        setIsValidatingToken(true);
        try {
          // Check if we're in a redirect loop first
          if (checkRedirectLoop('token')) {
            console.error('Detected token validation redirect loop. Clearing auth data.');
            clearAuthData();
            setUser(null);
            // Force redirect to login to break the loop
            window.location.href = '/login?loop=1';
            return;
          }

          // Validate token with server
          const { isValid, user: validatedUser } = await validateToken();
          
          if (isValid) {
            console.log('Token validation successful', {
              isValid,
              hasValidatedUser: !!validatedUser,
              currentPath: window.location.pathname
            });
            // User is already set from localStorage, so we don't need to do anything
          } else {
            console.warn('Token validation failed', {
              isValid,
              error: validatedUser?.error,
              currentPath: window.location.pathname
            });
            clearAuthData();
            setUser(null);
            
            // Redirect to login
            if (window.location.pathname !== '/login') {
              window.location.href = '/login?expired=1';
            }
          }
        } catch (error) {
          console.error('Error validating token:', error);
          // On any error, clear auth data
          clearAuthData();
          setUser(null);
        } finally {
          setIsValidatingToken(false);
          setTokenValidated(true);
        }
      };

      validateUserToken();
    } else {
      setTokenValidated(true);
    }
  }, [tokenValidated]);

  return (
    <UserContext.Provider value={{ user, setUser, isValidatingToken }}>
      {children}
    </UserContext.Provider>
  );
};

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Selection as BaseSelection } from '@features/operator/components/SelectionMenu';

export const Selection = (props) => {
  const { locationId } = useOutletContext();
  
  return (
    <BaseSelection
      {...props}
      locationId={locationId}
    />
  );
};

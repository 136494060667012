import { useState, useEffect } from 'react';

export const usePwaStatus = () => {
  const [isStandalone, setIsStandalone] = useState(false);
  
  useEffect(() => {
    // Check if running in standalone mode (PWA)
    const checkStandalone = () => {
      const isInStandaloneMode = 
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone || // iOS Safari
        document.referrer.includes('android-app://'); // Android TWA
      
      setIsStandalone(isInStandaloneMode);
    };
    
    checkStandalone();
    
    // Listen for changes in display mode
    const mediaQueryList = window.matchMedia('(display-mode: standalone)');
    const handleChange = (e) => {
      setIsStandalone(e.matches);
    };

    // Use the modern event listener if available, fall back to deprecated method
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handleChange);
    } else {
      // Fallback for older browsers
      mediaQueryList.addListener(handleChange);
    }
    
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', handleChange);
      } else {
        // Fallback for older browsers
        mediaQueryList.removeListener(handleChange);
      }
    };
  }, []);

  return { isStandalone };
};

export default usePwaStatus;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Calendar, Layers, AlertCircle, Truck, Edit, X, Clock } from 'lucide-react';

// Styled components for a more compact and informative processing order card
const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.md};
  
  /* Add a pulsing border for active orders */
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ theme, priority }) => {
      switch (priority) {
        case 'urgent':
          return theme.colors.error.main;
        case 'low':
          return theme.colors.gray[300];
        default:
          return theme.colors.primary.main;
      }
    }};
    animation: pulseBorder 2s infinite;
    
    @keyframes pulseBorder {
      0% { opacity: 1; }
      50% { opacity: 0.5; }
      100% { opacity: 1; }
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const Title = styled.h4`
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const ProcessingBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  color: ${({ theme }) => theme.colors.primary.dark};
  font-size: 0.75rem;
  font-weight: 500;
  animation: pulse 2s infinite;
  
  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
  }
  
  svg {
    width: 12px;
    height: 12px;
  }
`;

const ProgressContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.background.lighter};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const ProgressFill = styled.div`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ progress }) => {
    // Color based on progress percentage
    if (progress < 25) return '#f44336'; // red
    if (progress < 75) return '#ff9800'; // orange
    return '#4caf50'; // green
  }};
  transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  position: relative;
  
  /* Processing animation - moving gradient effect */
  background-image: ${({ isProcessing }) => isProcessing ? 
    'linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)' : 'none'};
  background-size: 20px 20px;
  animation: ${({ isProcessing }) => isProcessing ? 'progress-bar-stripes 1s linear infinite' : 'none'};
  
  @keyframes progress-bar-stripes {
    from { background-position: 20px 0; }
    to { background-position: 0 0; }
  }
`;

const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 4px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.typography.body2};
  
  svg {
    color: ${({ theme }) => theme.colors.text.secondary};
    flex-shrink: 0;
    width: 14px;
    height: 14px;
  }
  
  span {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  
  strong {
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: 500;
  }
`;

const ProcessTimeInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.background.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-size: 0.8rem;
  
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  
  span {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  
  strong {
    color: ${({ theme }) => theme.colors.text.primary};
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const ActionBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: none;
  background: none;
  color: ${({ theme, variant }) => {
    switch (variant) {
      case 'ship':
        return theme.colors.primary.main;
      case 'edit':
        return theme.colors.secondary.main;
      case 'delete':
        return theme.colors.error.main;
      default:
        return theme.colors.text.primary;
    }
  }};
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
  
  svg {
    width: 18px;
    height: 18px;
  }
`;

/**
 * ProcessingOrderCard Component
 * Displays detailed information about an order that is currently being processed
 */
const ProcessingOrderCard = ({ 
  order, 
  onUpdatePriority, 
  onShip, 
  onEdit, 
  onDelete 
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  
  // Calculate completion percentage
  const completionPercentage = order.colli_completion || 0;
  
  // Calculate processing time - if available in the order
  useEffect(() => {
    if (order.processing_start_time) {
      const interval = setInterval(() => {
        const startTime = new Date(order.processing_start_time).getTime();
        const now = new Date().getTime();
        setElapsedTime(Math.floor((now - startTime) / 1000)); // in seconds
      }, 1000);
      
      return () => clearInterval(interval);
    }
  }, [order.processing_start_time]);
  
  // Format elapsed time as hh:mm:ss
  const formatElapsedTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      remainingSeconds.toString().padStart(2, '0')
    ].join(':');
  };

  return (
    <Card priority={order.priority || "normal"}>
      <Header>
        <Title>{order.customer_name}</Title>
        <ProcessingBadge>
          <AlertCircle size={12} />
          Processing
        </ProcessingBadge>
      </Header>
      
      <ProgressContainer>
        <ProgressBar>
          <ProgressFill 
            progress={completionPercentage} 
            isProcessing={true} 
          />
        </ProgressBar>
        <ProgressText>
          <span>
            {order.completed_colli || 0}/{order.output_colli || 0} Boxes
          </span>
          <span>{Math.round(completionPercentage)}% Complete</span>
        </ProgressText>
      </ProgressContainer>
      
      {elapsedTime > 0 && (
        <ProcessTimeInfo>
          <Clock size={14} />
          <span>Processing time:</span>
          <strong>{formatElapsedTime(elapsedTime)}</strong>
        </ProcessTimeInfo>
      )}
      
      <InfoGrid>
        <InfoItem>
          <Calendar size={14} />
          <span>Due:</span>
          <strong>{order.due_date || 'Not set'}</strong>
        </InfoItem>
        
        <InfoItem>
          <Layers size={14} />
          <span>Pallets:</span>
          <strong>{order.assigned_pallets || 0}/{order.total_pallets || 0}</strong>
        </InfoItem>
      </InfoGrid>
      
      <Actions>
        <ActionBtn 
          variant="ship"
          onClick={() => onShip && onShip(order.id)}
          title="Ship Order"
        >
          <Truck size={16} />
        </ActionBtn>
        
        <ActionBtn 
          variant="edit"
          onClick={() => onEdit && onEdit(order)}
          title="Edit Order"
        >
          <Edit size={16} />
        </ActionBtn>
        
        <ActionBtn 
          variant="delete"
          onClick={() => onDelete && onDelete(order.id)}
          title="Delete Order"
        >
          <X size={16} />
        </ActionBtn>
      </Actions>
    </Card>
  );
};

export default ProcessingOrderCard;

/**
 * Shared validation utilities for storage operations
 */

/**
 * Ensures location ID is a valid number
 * @param {any} locationId - Location ID to validate
 * @returns {number} Validated location ID
 * @throws {Error} If ID is invalid
 */
export const validateLocationId = (locationId) => {
  if (!locationId) {
    throw new Error('Location ID is required');
  }
  
  const numericId = parseInt(locationId, 10);
  if (isNaN(numericId) || numericId <= 0) {
    throw new Error(`Invalid location ID: ${locationId}`);
  }
  
  return numericId;
};

/**
 * Extract zone and row information from a storage zone identifier
 * 
 * This function is necessary because:
 * 1. The UI needs to display zone and row separately (with row numbers shown as 1-based)
 * 2. Storage assignment operations need zone and row as separate values for calculations
 * 3. Historical data may use different formats, requiring consistent parsing
 * 
 * @param {string} storageZone - The storage zone string (e.g., "zone-1-row-3")
 * @returns {Object} An object with zoneId and rowIndex properties
 */
export const parseStorageZone = (storageZone) => {
  if (!storageZone) return { zoneId: null, rowIndex: null };
  
  // Match the standardized pattern {zoneId}-row-{rowIndex}
  const match = storageZone.match(/^(.+)-row-(\d+)$/);
  
  if (match) {
    return {
      zoneId: match[1],
      rowIndex: parseInt(match[2], 10)
    };
  }
  
  // Handle legacy format by returning original string as zoneId with null rowIndex
  return {
    zoneId: storageZone,
    rowIndex: null
  };
};

/**
 * Check if a storage zone has row-level assignment
 * @param {string} storageZone - The storage zone to check
 * @returns {boolean} Whether this is a row-level assignment
 */
export const isRowLevelAssignment = (storageZone) => {
  if (!storageZone) return false;
  return storageZone.includes('-row-');
};

/**
 * Get the zone ID from a storage zone, always using standardized format
 * @param {string} storageZone - The storage zone
 * @returns {string} The zone ID
 */
export const getZoneIdFromStorageZone = (storageZone) => {
  if (!storageZone) return null;
  
  const { zoneId } = parseStorageZone(storageZone);
  return zoneId;
};

export default {
  validateLocationId,
  parseStorageZone,
  isRowLevelAssignment,
  getZoneIdFromStorageZone
};

import { useState, useEffect } from 'react';
import { api } from '@lib/api';

export const useLocationManagement = (user) => {
  const [locations, setLocations] = useState([]);

  const loadUserLocations = async () => {
    try {
      if (!user?.id) {
        return [];
      }

      const response = await api.get(`/api/user-locations/user/${user.id}`);
      if (response.data.success && response.data.data) {
        setLocations(response.data.data);
        return response.data.data;
      }
      return [];
    } catch (error) {
      console.error('Error loading user locations:', error);
      return [];
    }
  };

  useEffect(() => {
    if (user) {
      loadUserLocations();
    }
  }, [user]);

  return {
    locations,
    setLocations,
    loadUserLocations
  };
};

import { useCallback } from 'react';
import {
  groupPalletsByZone,
  filterPalletsByZone,
  filterPalletsByScheduledRows
} from '../utils/palletUtils';

/**
 * Hook for pallet filtering operations
 * @returns {Object} Pallet filtering functions
 */
const usePalletFiltering = () => {
  /**
   * Groups pallets by their assigned zones
   */
  const getPalletsByZone = useCallback((pallets) => {
    return groupPalletsByZone(pallets);
  }, []);

  /**
   * Filters pallets for a specific zone
   */
  const getZonePallets = useCallback((pallets, zoneName) => {
    return filterPalletsByZone(pallets, zoneName);
  }, []);

  /**
   * Gets pallets for scheduled rows in a zone
   */
  const getScheduledRowPallets = useCallback((allPallets, scheduledRows, zoneName) => {
    return filterPalletsByScheduledRows(allPallets, scheduledRows, zoneName);
  }, []);

  /**
   * Calculates total boxes for a set of pallets
   */
  const calculateTotalBoxes = useCallback((pallets) => {
    if (!Array.isArray(pallets)) return 0;
    return pallets.reduce((total, pallet) => {
      return total + (pallet.box_count || 0);
    }, 0);
  }, []);

  return {
    getPalletsByZone,
    getZonePallets,
    getScheduledRowPallets,
    calculateTotalBoxes
  };
};

export default usePalletFiltering;

import React from 'react';
import {
  SelectContainer,
  SelectLabel,
  StyledSelect,
  SelectArrow,
  LocationOption,
  NoLocationsMessage
} from './styles';

export const LocationSelector = ({ locations, selectedLocation, onLocationChange }) => {
  if (!locations || locations.length === 0) {
    return (
      <NoLocationsMessage>
        No locations available
      </NoLocationsMessage>
    );
  }

  return (
    <SelectContainer>
      <SelectLabel htmlFor="location-select">Location</SelectLabel>
      <StyledSelect
        id="location-select"
        value={selectedLocation?.id || ''}
        onChange={onLocationChange}
        aria-label="Select location"
      >
        <LocationOption value="" disabled>
          Select a location
        </LocationOption>
        {locations.map((location) => (
          <LocationOption key={location.id} value={location.id}>
            {location.name}
          </LocationOption>
        ))}
      </StyledSelect>
      <SelectArrow />
    </SelectContainer>
  );
};

export default LocationSelector;

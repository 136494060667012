import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: ${theme.colors.primary.main};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0;
  cursor: pointer;
  transition: color 0.2s ease;
  align-self: flex-start;

  &:hover {
    color: ${theme.colors.primary.dark};
    text-decoration: underline;
  }
`;

export const PalletInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.md};
  box-shadow: ${theme.shadows.xs};

  h3 {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
    color: ${theme.colors.text.primary};
  }

  span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    color: ${theme.colors.text.secondary};
    margin-left: 1rem;
  }
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background-color: ${theme.colors.background.default};
  border-radius: ${theme.borderRadius.md};
  border-left: 4px solid ${props => props.color || theme.colors.primary.main};
  box-shadow: ${theme.shadows.xs};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${theme.colors.background.hover};
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.sm};
  }
`;

export const EventTime = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${theme.colors.text.secondary};
  font-size: 0.875rem;
`;

export const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const EventType = styled.div`
  font-weight: 600;
  color: ${props => props.color || theme.colors.text.primary};
`;

export const EventDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  font-size: 0.875rem;
  color: ${theme.colors.text.secondary};

  span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

export const UserInfo = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const StateChanges = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.sm};
  font-size: 0.875rem;
`;

export const StateChange = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  span {
    font-weight: 500;
    color: ${theme.colors.text.primary};
  }
`;

export const ChangeValue = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${theme.colors.text.secondary};
  
  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const NoEvents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  
  p {
    width: 100%;
    text-align: center;
    color: ${theme.colors.text.secondary};
    font-style: italic;
    margin: 2rem 0;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.lg};
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.background.paper},
    ${({ theme }) => theme.colors.background.default}
  );
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

export const MiddleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

export const BottomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

export const Button = styled.button`
  background: ${props => 
    props.selected 
      ? `linear-gradient(135deg, ${props.theme.colors.primary.main}, ${props.theme.colors.primary.dark})`
      : props.theme.colors.background.paper};
  color: ${props => 
    props.selected 
      ? props.theme.colors.text.inverse 
      : props.theme.colors.text.primary};
  border: 1px solid ${props => 
    props.selected 
      ? 'transparent'
      : props.theme.colors.border.light};
  padding: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  font-weight: ${props => props.selected ? 600 : 400};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90px;
  box-shadow: ${props => 
    props.selected 
      ? props.theme.shadows.md
      : props.theme.shadows.sm};
  
  &:hover {
    background: ${props => 
      props.selected 
        ? `linear-gradient(135deg, ${props.theme.colors.primary.dark}, ${props.theme.colors.primary.darker})`
        : props.theme.colors.background.hover};
    border-color: ${props => 
      props.selected 
        ? 'transparent'
        : props.theme.colors.primary.lighter};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  // Style for the Round/Plum toggle button
  ${ButtonContainer} & {
    height: 48px;
    width: 200px;
    background: ${props => props.theme.colors.primary.main};
    color: ${props => props.theme.colors.text.inverse};
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    font-weight: 600;
    letter-spacing: 0.5px;
    border: none;
    box-shadow: ${({ theme }) => theme.shadows.sm};

    &:hover {
      background: ${({ theme }) => theme.colors.primary.dark};
      box-shadow: ${({ theme }) => theme.shadows.md};
    }

    &:active {
      background: ${({ theme }) => theme.colors.primary.darker};
      box-shadow: ${({ theme }) => theme.shadows.sm};
    }
  }
`;

export const Divider = styled.hr`
  margin: ${({ theme }) => theme.spacing.lg} 0;
  border: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.border.light}00,
    ${({ theme }) => theme.colors.border.light},
    ${({ theme }) => theme.colors.border.light}00
  );
`;

export const Typography = styled.h2`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.md} 0;
  font-size: ${({ theme }) => theme.typography.h3.fontSize};
  font-weight: ${({ theme }) => theme.typography.h3.fontWeight};
  color: ${({ theme }) => theme.colors.text.primary};
  letter-spacing: ${({ theme }) => theme.typography.h3.letterSpacing};
  line-height: 1.4;
`;

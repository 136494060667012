import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 20;
  gap: 20px;
`;

const ControlButton = styled(motion.button)`
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  backdrop-filter: blur(4px);
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }

  ${({ active }) => active && `
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    
    &:hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.primary};
    }
  `}
`;

const buttonVariants = {
  tap: { scale: 0.95 },
  hover: { scale: 1.05 }
};

// SVG Icons
const FlashIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 2v11h3v9l7-12h-4l4-8z"/>
  </svg>
);

const SwitchCameraIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 4h-3.17L15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 11.5V13H9v2.5L5.5 12 9 8.5V11h6V8.5l3.5 3.5-3.5 3.5z"/>
  </svg>
);

const HistoryIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"/>
  </svg>
);

const KeyboardIcon = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z"/>
  </svg>
);

export const CameraControls = ({
  flashEnabled,
  onToggleFlash,
  onSwitchCamera,
  onShowHistory,
  onManualInput,
  isFlashAvailable = true,
  isSwitchAvailable = true
}) => {
  return (
    <ControlsContainer>
      <ControlButton
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        onClick={onToggleFlash}
        disabled={!isFlashAvailable}
        active={flashEnabled}
        aria-label={flashEnabled ? 'Turn flash off' : 'Turn flash on'}
        title={flashEnabled ? 'Turn flash off' : 'Turn flash on'}
      >
        <FlashIcon />
      </ControlButton>

      <ControlButton
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        onClick={onSwitchCamera}
        disabled={!isSwitchAvailable}
        aria-label="Switch camera"
        title="Switch camera"
      >
        <SwitchCameraIcon />
      </ControlButton>

      <ControlButton
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        onClick={onShowHistory}
        aria-label="Show scan history"
        title="Show scan history"
      >
        <HistoryIcon />
      </ControlButton>

      <ControlButton
        variants={buttonVariants}
        whileTap="tap"
        whileHover="hover"
        onClick={onManualInput}
        aria-label="Enter code manually"
        title="Enter code manually"
      >
        <KeyboardIcon />
      </ControlButton>
    </ControlsContainer>
  );
};

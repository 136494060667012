import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableRow = styled.tr`
  &.selected {
    background-color: ${({ theme }) => theme.colors.primary.alpha10};
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;

    /* Allow checkbox interaction even when disabled */
    .checkbox-cell {
      pointer-events: auto;
    }
  }

  /* Ensure proper spacing and alignment */
  td {
    vertical-align: middle;
  }

  /* Hover effect */
  &:hover:not(.disabled) {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }

  /* Focus outline for keyboard navigation */
  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.primary.main};
    outline-offset: -2px;
  }
`;

const TableRow = ({
  children,
  selected,
  disabled,
  index,
  onKeyDown,
  ...props
}) => {
  return (
    <StyledTableRow
      className={`${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={onKeyDown}
      data-row-index={index}
      {...props}
    >
      {children}
    </StyledTableRow>
  );
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  onKeyDown: PropTypes.func,
};

TableRow.defaultProps = {
  selected: false,
  disabled: false,
};

export default TableRow;

// Also export the styled component for flexibility
export { StyledTableRow };

import { useState, useCallback, useRef } from 'react';
import { zoneUpdateService } from '@features/map/services/zoneUpdateService';
import storageStateManager from '@lib/services/storage/StorageStateManager';
import storageAssignmentService from '@features/map/services/storageAssignmentService';

/**
 * React hook for managing pallet assignments to storage positions
 * Handles assignment operations with proper updates and fallbacks
 * 
 * @param {function} onAssignmentSuccess - Optional callback for successful assignments
 * @param {function} onAssignmentError - Optional callback for failed assignments
 * @return {Object} Assignment functions and state
 */
const useStorageAssignment = (onAssignmentSuccess, onAssignmentError) => {
  const [isAssigning, setIsAssigning] = useState(false);
  const [lastAssignment, setLastAssignment] = useState(null);
  const [assignmentError, setAssignmentError] = useState(null);
  
  // Use a ref to keep track of assignments in progress
  const pendingAssignmentsRef = useRef(new Map());
  
  /**
   * Assign a pallet to a specific position in a zone
   * @param {string} zoneId - The zone ID
   * @param {number} rowIndex - The row index
   * @param {number} colIndex - The column index
   * @param {Object} palletData - The pallet data
   * @param {number} locationId - Optional location ID
   * @param {boolean} trackRow - Whether to track the row completion
   * @returns {Promise<Object>} Assignment result
   */
  const assignPalletToPosition = useCallback(async (
    zoneId, 
    rowIndex, 
    colIndex, 
    palletData, 
    locationId = null,
    trackRow = false
  ) => {
    if (!zoneId || !palletData || rowIndex === undefined || colIndex === undefined) {
      const error = new Error('Missing required parameters for assignment');
      setAssignmentError(error.message);
      
      if (onAssignmentError) onAssignmentError(error);
      
      return Promise.reject(error);
    }
    
    // Generate a unique ID for this assignment operation
    const assignmentId = `assign_${Date.now()}_${Math.random().toString(36).substring(2, 8)}`;
    
    try {
      setIsAssigning(true);
      setAssignmentError(null);
      
      // Track this assignment as pending
      pendingAssignmentsRef.current.set(assignmentId, {
        zoneId,
        rowIndex,
        colIndex,
        palletData,
        timestamp: Date.now()
      });
      
      // Attempt the assignment with the service
      console.log(`Assigning pallet ${palletData.id} to zone ${zoneId} at position ${rowIndex},${colIndex}`);
      
      // First attempt to use the assignment service
      const result = await storageAssignmentService.assignPalletToPosition(
        zoneId,
        rowIndex,
        colIndex,
        palletData,
        locationId,
        trackRow
      );
      
      // If we've gotten here, the assignment was successful through the API
      
      // Store the successful assignment
      const assignmentData = {
        id: assignmentId,
        zoneId,
        palletId: palletData.id,
        rowIndex,
        colIndex,
        timestamp: Date.now(),
        source: 'api',
        success: true
      };
      
      setLastAssignment(assignmentData);
      
      // If row is being tracked, also create a row tracking entry
      if (trackRow) {
        try {
          await storageAssignmentService.trackRowCompletion(
            palletData.palletType || 'standard',
            palletData.type,
            palletData.option || '',
            zoneId,
            rowIndex,
            colIndex,
            typeof result.zone?.dimensions?.cols === 'number' ? result.zone.dimensions.cols : 6,
            locationId
          );
        } catch (rowError) {
          console.error('Error tracking row completion:', rowError);
          // Continue even if row tracking fails
        }
      }
      
      // Force update via multiple methods to ensure UI consistency
      
      // 1. Directly emit zone assignment event to ensure immediate UI updates
      zoneUpdateService.eventEmitter.emit(
        zoneUpdateService.ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, 
        {
          palletId: palletData.id,
          zoneId,
          position: { row: rowIndex, col: colIndex },
          success: true
        }
      );
      
      // 2. Refresh zone occupancy data to ensure storage state is updated
      try {
        const updatedOccupancy = await zoneUpdateService.getZoneOccupancy(zoneId, locationId);
        console.log('Refreshed zone occupancy data:', updatedOccupancy);
        
        // 3. Refresh all zones data to ensure everything is in sync
        await zoneUpdateService.fetchZoneData(locationId);
      } catch (refreshError) {
        console.error('Failed to refresh zone data after assignment:', refreshError);
        // Continue even if refresh fails - the assignment itself succeeded
      }
      
      // Clean up pending assignment
      pendingAssignmentsRef.current.delete(assignmentId);
      
      // Call success callback if provided
      if (onAssignmentSuccess) {
        onAssignmentSuccess(assignmentData);
      }
      
      return result;
    } catch (error) {
      console.error('Error assigning pallet to position:', error);
      
      // Clean up pending assignment
      pendingAssignmentsRef.current.delete(assignmentId);
      
      // Create error record
      const errorData = {
        error: error.message || 'Unknown error during assignment',
        code: error.code || 'ASSIGNMENT_ERROR',
        zoneId,
        palletId: palletData.id,
        position: { row: rowIndex, col: colIndex },
        timestamp: Date.now()
      };
      
      setAssignmentError(errorData);
      
      // Call error callback if provided
      if (onAssignmentError) {
        onAssignmentError(error, errorData);
      }
      
      // Emit error event
      zoneUpdateService.eventEmitter.emit(
        zoneUpdateService.ZONE_UPDATE_EVENTS.SERVICE_ERROR,
        {
          operation: 'assignPalletToPosition',
          error,
          zoneId,
          palletId: palletData.id,
          position: { row: rowIndex, col: colIndex }
        }
      );
      
      throw error;
    } finally {
      setIsAssigning(false);
    }
  }, [onAssignmentSuccess, onAssignmentError]);
  
  /**
   * Get suitable zones for a tomato type
   * @param {string} tomatoType - Tomato type to check
   * @param {number} locationId - Optional location ID
   * @returns {Promise<Array>} Array of suitable zones
   */
  const getSuitableZones = useCallback(async (tomatoType, locationId = null) => {
    try {
      return await storageAssignmentService.getSuitableZones(tomatoType, locationId);
    } catch (error) {
      console.error('Error getting suitable zones:', error);
      setAssignmentError(error.message || 'Error getting suitable zones');
      throw error;
    }
  }, []);
  
  /**
   * Assign a pallet to a zone without specifying a position
   * @param {string} zoneId - The zone ID
   * @param {Object} palletData - The pallet data
   * @param {number} locationId - Optional location ID
   * @returns {Promise<Object>} Assignment result
   */
  const assignPalletToZone = useCallback(async (
    zoneId,
    palletData,
    locationId = null
  ) => {
    if (!zoneId || !palletData) {
      const error = new Error('Missing required parameters for zone assignment');
      setAssignmentError(error.message);
      
      if (onAssignmentError) onAssignmentError(error);
      
      return Promise.reject(error);
    }
    
    // Generate a unique ID for this assignment operation
    const assignmentId = `assign_zone_${Date.now()}_${Math.random().toString(36).substring(2, 8)}`;
    
    try {
      setIsAssigning(true);
      setAssignmentError(null);
      
      // Track this assignment as pending
      pendingAssignmentsRef.current.set(assignmentId, {
        zoneId,
        palletData,
        timestamp: Date.now()
      });
      
      // Attempt the assignment with the service
      console.log(`Assigning pallet ${palletData.id} to zone ${zoneId} without position specification`);
      
      // Use the zone-only assignment method
      const result = await storageAssignmentService.assignPalletToZone(
        zoneId,
        palletData,
        locationId
      );
      
      // If we've gotten here, the assignment was successful through the API
      
      // Store the successful assignment
      const assignmentData = {
        id: assignmentId,
        zoneId,
        palletId: palletData.id,
        timestamp: Date.now(),
        source: 'api',
        success: true
      };
      
      setLastAssignment(assignmentData);
      
      // Force update via multiple methods to ensure UI consistency
      
      // 1. Directly emit zone assignment event to ensure immediate UI updates
      zoneUpdateService.eventEmitter.emit(
        zoneUpdateService.ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, 
        {
          palletId: palletData.id,
          zoneId,
          success: true
        }
      );
      
      // 2. Refresh zone occupancy data to ensure storage state is updated
      try {
        const updatedOccupancy = await zoneUpdateService.getZoneOccupancy(zoneId, locationId);
        console.log('Refreshed zone occupancy data:', updatedOccupancy);
        
        // 3. Refresh all zones data to ensure everything is in sync
        await zoneUpdateService.fetchZoneData(locationId);
      } catch (refreshError) {
        console.error('Failed to refresh zone data after assignment:', refreshError);
        // Continue even if refresh fails - the assignment itself succeeded
      }
      
      // Clean up pending assignment
      pendingAssignmentsRef.current.delete(assignmentId);
      
      // Call success callback if provided
      if (onAssignmentSuccess) {
        onAssignmentSuccess(assignmentData);
      }
      
      return result;
    } catch (error) {
      console.error('Error assigning pallet to zone:', error);
      
      // Clean up pending assignment
      pendingAssignmentsRef.current.delete(assignmentId);
      
      // Create error record
      const errorData = {
        error: error.message || 'Unknown error during zone assignment',
        code: error.code || 'ZONE_ASSIGNMENT_ERROR',
        zoneId,
        palletId: palletData.id,
        timestamp: Date.now()
      };
      
      setAssignmentError(errorData);
      
      // Call error callback if provided
      if (onAssignmentError) {
        onAssignmentError(error, errorData);
      }
      
      // Emit error event
      zoneUpdateService.eventEmitter.emit(
        zoneUpdateService.ZONE_UPDATE_EVENTS.SERVICE_ERROR,
        {
          operation: 'assignPalletToZone',
          error,
          zoneId,
          palletId: palletData.id
        }
      );
      
      throw error;
    } finally {
      setIsAssigning(false);
    }
  }, [onAssignmentSuccess, onAssignmentError]);

  /**
   * Find the next position in an active row
   * @param {string} palletType - Pallet type
   * @param {string} tomatoType - Tomato type
   * @param {string} option - Option
   * @param {number} locationId - Optional location ID
   * @returns {Promise<Object>} Next row position
   */
  const findNextRowPosition = useCallback(async (palletType, tomatoType, option, locationId = null) => {
    try {
      return await storageAssignmentService.findNextRowPosition(
        palletType, 
        tomatoType, 
        option, 
        locationId
      );
    } catch (error) {
      console.error('Error finding next row position:', error);
      setAssignmentError(error.message || 'Error finding next row position');
      throw error;
    }
  }, []);
  
  /**
   * Clear any assignment errors
   */
  const clearErrors = useCallback(() => {
    setAssignmentError(null);
  }, []);
  
  return {
    isAssigning,
    lastAssignment,
    assignmentError, 
    assignPalletToPosition,
    assignPalletToZone, // Add the new zone-only assignment function
    getSuitableZones,
    findNextRowPosition,
    clearErrors
  };
};

export default useStorageAssignment;

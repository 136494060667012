import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { config } from '@config/env';
import GlobalStyle from './styles/GlobalStyle';
import { theme } from './styles/theme';
import { ToastProvider, LoadingProvider, ProcessingProvider, UserProvider, PermissionProvider } from '@lib/contexts';
import { DateSettingsProvider } from '@lib/contexts/DateSettingsContext';
import { LoginDesktop } from './features/auth/components/LoginDesktop';
import { LoginMobile } from './features/auth/components/LoginMobile';
import PasswordChange from './features/auth/components/PasswordChange';
import MainDashboard from './features/dashboard/components/MainDashboard';
import { InventoryDashboard } from './features/dashboard/components/InventoryDashboard';
import AdminDashboard from './features/dashboard/components/AdminDashboard';
import AdminRefreshPage from './features/dashboard/components/AdminRefreshPage';
import ProtectedDesktopRoute from './features/dashboard/components/ProtectedDesktopRoute';
import { MobileOperatorRoutes } from './features/operator/routes/mobile.routes';
import { initAnalyticsTracking } from '@features/analytics/utils/analyticsUtils';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { usePermission } from '@lib/contexts';
import { PrinterTester } from './features/printer';

// Analytics tracking wrapper component that is aware of the current route
const AnalyticsProvider = ({ children }) => {
  const { user } = useUserSession();
  const location = useLocation();
  
  React.useEffect(() => {
    // Check if we're on a login-related route
    const isLoginRoute = location.pathname.includes('/login');
    
    // Only initialize analytics tracking when user is logged in AND not on login route
    if (user?.id && !isLoginRoute) {
      const locationId = localStorage.getItem('locationId');
      if (locationId) {
        console.log('Initializing analytics tracking');
        const cleanup = initAnalyticsTracking(user, locationId);
        
        // Clean up on unmount
        return cleanup;
      }
    }
  }, [user, location.pathname]);
  
  return children;
};

// Admin route protection component
const AdminRoute = ({ component: Component }) => {
  const { isAdmin } = usePermission();
  const { user } = useUserSession();

  console.log('AdminRoute debug:', { 
    isAuthenticated: validateAuthData(), 
    isAdmin: isAdmin(),
    user: user
  });

  if (!validateAuthData()) {
    return <Navigate to="/login" replace />;
  }

  if (!isAdmin()) {
    console.log('User is not admin, redirecting to dashboard');
    return <Navigate to="/dashboard" replace />;
  }

  return <Component />;
};

// Validate stored authentication data
const validateAuthData = () => {
  // In development mode, always return true if bypass is enabled
  if (config.bypassAuth) {
    return true;
  }

  const token = localStorage.getItem('token');
  const userData = localStorage.getItem('userData');
  
  // If no token, definitely not authenticated
  if (!token) {
    return false;
  }
  
  // If token exists but no userData, clear token and return false
  if (!userData) {
    // Use the clearAuthData helper to make sure all auth data is cleaned up
    import('@features/auth/services/tokenService').then(({ clearAuthData }) => {
      clearAuthData();
    });
    return false;
  }
  
  // Validate user data
  try {
    const parsedUser = JSON.parse(userData);
    if (!parsedUser || !parsedUser.id || !parsedUser.username) {
      // Invalid user data, clear everything
      import('@features/auth/services/tokenService').then(({ clearAuthData }) => {
        clearAuthData();
      });
      return false;
    }
    return true;
  } catch (e) {
    // JSON parsing error, clear everything
    import('@features/auth/services/tokenService').then(({ clearAuthData }) => {
      clearAuthData();
    });
    return false;
  }
};

// Main Routes component that has access to Router context
const AppRoutes = () => {
  const location = useLocation();
  const { user } = useUserSession();
  
  // When AppRoutes mounts/updates, clean up any stale session data and auth data if needed
  React.useEffect(() => {
    // Clear all redirect counters from previous implementation
    sessionStorage.removeItem('loginRedirectCount');
    sessionStorage.removeItem('appRedirectCount');
    
    // If we're on the login page, prevent any auto-redirects
    // This ensures a user can always access the login page
    const isLoginPage = location.pathname.includes('/login');
    if (isLoginPage) {
      console.log('On login page, ensuring clean session');
      
      // Check if there's corrupted data in localStorage and clean it
      const token = localStorage.getItem('token');
      const userData = localStorage.getItem('userData');
      
      if (token && !userData) {
        console.warn('Found token without userData, cleaning up');
        localStorage.removeItem('token');
      }
      
      if (userData) {
        try {
          // Test if userData is valid JSON
          const parsed = JSON.parse(userData);
          if (!parsed || !parsed.id || !parsed.username) {
            console.warn('Found invalid userData, cleaning up');
            localStorage.removeItem('userData');
            localStorage.removeItem('token');
          }
        } catch (e) {
          console.warn('Found corrupted userData, cleaning up');
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
        }
      }
    }
  }, [location.pathname]);
  
  const isAuthenticated = () => {
    return validateAuthData();
  };

  const getLastLocation = () => {
    return localStorage.getItem('lastLocation') || '/dashboard';
  };

  // Check if user needs to change password (first login)
  const needsPasswordChange = () => {
    // Skip password change in development mode
    if (config.skipPasswordChange) {
      return false;
    }

    // Get user from context or localStorage
    if (user?.first_login) {
      return true;
    }
    // Also check localStorage in case context is not yet loaded
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      return !!parsedUser.first_login;
    }
    return false;
  };

  return (
    <AnalyticsProvider>
      <Routes>
        {/* Login Routes */}
        <Route 
          path="/login" 
          element={isMobile ? <LoginMobile /> : <LoginDesktop />} 
        />
        
        {/* Password Change Route */}
        <Route
          path="/change-password"
          element={
            isAuthenticated() ? <PasswordChange /> : <Navigate to="/login" replace />
          }
        />

        {/* Dashboard Routes */}
        <Route path="/dashboard" element={
          isAuthenticated() ? (
            needsPasswordChange() ? (
              <Navigate to="/change-password" replace />
            ) : isMobile ? (
              <Navigate to="/mobile/operator" replace />
            ) : (
              <ProtectedDesktopRoute component={MainDashboard} />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        } />
        
        <Route path="/dashboard/inventory" element={
          isAuthenticated() ? (
            needsPasswordChange() ? (
              <Navigate to="/change-password" replace />
            ) : isMobile ? (
              <Navigate to="/mobile/operator" replace />
            ) : (
              <ProtectedDesktopRoute component={InventoryDashboard} />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        } />

        {/* Mobile Operator Routes */}
        <Route
          path="/mobile/operator/*"
          element={
            isAuthenticated() ? (
              needsPasswordChange() ? (
                <Navigate to="/change-password" replace />
              ) : isMobile ? (
                <MobileOperatorRoutes />
              ) : (
                <Navigate to="/dashboard" replace />
              )
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        {/* Logifour Printer Config Route - Only accessible via direct URL */}
        <Route 
          path="/printer-config" 
          element={
            isAuthenticated() ? (
              isMobile ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <ProtectedDesktopRoute component={PrinterTester} />
              )
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

      {/* Admin Routes */}
      <Route path="/nimda" element={<AdminRoute component={AdminDashboard} />} />
      
      {/* Admin Refresh Page - accessible to all authenticated users */}
      <Route 
        path="/admin-refresh" 
        element={
          isAuthenticated() ? (
            <AdminRefreshPage />
          ) : (
            <Navigate to="/login" replace />
          )
        } 
      />
      
      {/* For easier discovery during development */}
      <Route path="/admin" element={<Navigate to="/admin-refresh" replace />} />

        {/* Root Route */}
        <Route
          path="/"
          element={
            isAuthenticated() ? (
              <Navigate to={getLastLocation()} replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </AnalyticsProvider>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastProvider>
        <LoadingProvider>
          <ProcessingProvider>
            <UserProvider>
              <PermissionProvider>
                <DateSettingsProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </DateSettingsProvider>
              </PermissionProvider>
            </UserProvider>
          </ProcessingProvider>
        </LoadingProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;

/**
 * Service for managing row-based operations with safety-critical constraints
 */

import api from '@lib/api/axios';
import { ZONE_MAP } from '../constants/zoneConfig.v2';
import { createPosition } from '../utils/positionManager';
import { executeWithRetry, enhanceError } from '../utils/storageApiErrorHandler';
import { createBoundedArray, COLLECTION_LIMITS } from '../utils/collectionManager';
import { validateLocationId } from '../utils/validationUtils';

const API_URL = '/api/storage';

// Constants for row operations
const ROW_LIMITS = {
  MAX_ACTIVE_ROWS: 10,      // Maximum number of active rows per product type
  MAX_ROW_CAPACITY: 100,    // Maximum capacity for any single row
  MIN_ROW_CAPACITY: 1       // Minimum capacity for any single row
};

/**
 * Validates row capacity value
 * @param {number} capacity - Row capacity to validate
 * @throws {Error} If capacity is invalid
 */
const validateRowCapacity = (capacity) => {
  if (!Number.isInteger(capacity)) {
    throw new Error('Row capacity must be an integer');
  }
  if (capacity < ROW_LIMITS.MIN_ROW_CAPACITY || capacity > ROW_LIMITS.MAX_ROW_CAPACITY) {
    throw new Error(`Row capacity must be between ${ROW_LIMITS.MIN_ROW_CAPACITY} and ${ROW_LIMITS.MAX_ROW_CAPACITY}`);
  }
};

/**
 * Finds the next position in an active row
 * @param {string} palletType - Type of pallet
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option/variety
 * @param {number} locationId - Location ID
 * @returns {Promise<Object|null>} Next position recommendation
 */
export const findNextRowPosition = async (
  palletType,
  tomatoType,
  tomatoOption,
  locationId
) => {
  // Input validation
  if (!palletType || !tomatoType) {
    throw new Error('Pallet type and tomato type are required');
  }
  const locationIdNum = validateLocationId(locationId);

  try {
    // Execute search with bounded retries
    const response = await executeWithRetry(
      () => api.get(`${API_URL}/next-row-position/location/${locationIdNum}`, {
        params: {
          palletType,
          tomatoType,
          tomatoOption
        }
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying next row position search (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    if (!response.data) {
      return null;
    }

    // Validate and normalize position data
    const { zoneId, rowIndex, colIndex, isActiveRow, rowInfo } = response.data;

    // Validate zone exists
    if (!ZONE_MAP[zoneId]) {
      throw new Error(`Invalid zone ID in response: ${zoneId}`);
    }

    // Create standardized position
    const position = createPosition(rowIndex, colIndex);

    return {
      zoneId,
      ...position,
      isActiveRow,
      rowInfo
    };
  } catch (error) {
    throw enhanceError(error, {
      palletType,
      tomatoType,
      tomatoOption,
      locationId: locationIdNum,
      operation: 'findNextRowPosition'
    });
  }
};

/**
 * Gets active rows for a product combination
 * @param {string} palletType - Type of pallet
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option/variety
 * @param {number} locationId - Location ID
 * @returns {Promise<Array>} Array of active row data
 */
export const getActiveRows = async (
  palletType,
  tomatoType,
  tomatoOption,
  locationId
) => {
  // Input validation
  if (!palletType || !tomatoType) {
    throw new Error('Pallet type and tomato type are required');
  }
  const locationIdNum = validateLocationId(locationId);

  try {
    // Create bounded array for results
    const results = createBoundedArray(ROW_LIMITS.MAX_ACTIVE_ROWS);

    // Execute query with bounded retries
    const response = await executeWithRetry(
      () => api.get(`${API_URL}/active-rows/location/${locationIdNum}`, {
        params: {
          palletType,
          tomatoType,
          tomatoOption
        }
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying active rows fetch (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    // Validate and normalize results
    if (response.data && Array.isArray(response.data)) {
      response.data.forEach(row => {
        if (results.isFull()) return;

        try {
          // Validate zone exists
          if (!ZONE_MAP[row.zoneId]) {
            throw new Error(`Invalid zone ID: ${row.zoneId}`);
          }

          // Validate row data
          if (typeof row.rowIndex !== 'number' || row.rowIndex < 0) {
            throw new Error(`Invalid row index: ${row.rowIndex}`);
          }

          results.add(row);
        } catch (error) {
          console.warn('Skipping invalid row data:', error.message);
        }
      });
    }

    return results.getAll();
  } catch (error) {
    throw enhanceError(error, {
      palletType,
      tomatoType,
      tomatoOption,
      locationId: locationIdNum,
      operation: 'getActiveRows'
    });
  }
};

/**
 * Tracks row completion progress
 * @param {Object} params - Row tracking parameters
 * @returns {Promise<Object|null>} Row tracking data
 */
export const trackRowCompletion = async ({
  palletType,
  tomatoType,
  tomatoOption,
  zoneId,
  rowIndex,
  colIndex,
  rowCapacity,
  locationId
}) => {
  // Input validation
  if (!palletType || !tomatoType || !zoneId) {
    throw new Error('Missing required parameters');
  }
  
  const locationIdNum = validateLocationId(locationId);
  validateRowCapacity(rowCapacity);

  // Validate zone exists
  if (!ZONE_MAP[zoneId]) {
    throw new Error(`Invalid zone ID: ${zoneId}`);
  }

  // Ensure valid indices
  const position = createPosition(
    rowIndex || 0,
    colIndex || 0
  );

  try {
    // Execute tracking update with bounded retries
    const response = await executeWithRetry(
      () => api.post(`${API_URL}/row-tracking`, {
        palletType,
        tomatoType,
        tomatoOption,
        zoneId,
        rowIndex: position.row,
        colIndex: position.col,
        rowCapacity,
        locationId: locationIdNum
      }),
      {
        timeout: 5000,
        onRetry: (error, attempt) => {
          console.warn(`Retrying row tracking update (attempt ${attempt + 1}):`, error.message);
        }
      }
    );

    return response.data;
  } catch (error) {
    throw enhanceError(error, {
      palletType,
      tomatoType,
      tomatoOption,
      zoneId,
      position,
      rowCapacity,
      locationId: locationIdNum,
      operation: 'trackRowCompletion'
    });
  }
};

export default {
  findNextRowPosition,
  getActiveRows,
  trackRowCompletion,
  ROW_LIMITS
};

import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
} from '../shared/ConsoleNavigation/styles';
import styled from 'styled-components';

// Export the shared styles
export {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
};

// Additional styles specific to LogisticsConsole
export const Message = styled.h2`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  text-align: center;
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight};
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

export const InfoCard = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  margin: ${({ theme }) => theme.spacing.lg} auto;
  max-width: 600px;
  box-shadow: ${({ theme }) => theme.shadows.md};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.main}40;
  }
`;

import React, { useMemo } from 'react';
import { 
  formatTimestamp, 
  getEventTypeLabel, 
  getEventTypeColor,
  groupEventsByDate 
} from '../../utils';
import { 
  Container, 
  DateHeader, 
  TimelineList, 
  TimelineItem, 
  EventTime, 
  EventContent, 
  EventType, 
  EventDetails, 
  UserInfo, 
  PalletInfo, 
  NoEvents 
} from './styles';
import { User, Package, Clock, ArrowRight } from 'lucide-react';

export const EventsTimeline = ({ events, onEventSelect, onPalletSelect }) => {
  // Group events by date
  const groupedEvents = useMemo(() => {
    return groupEventsByDate(events);
  }, [events]);

  // Sort dates in descending order (newest first)
  const sortedDates = useMemo(() => {
    return Object.keys(groupedEvents).sort((a, b) => new Date(b) - new Date(a));
  }, [groupedEvents]);

  if (!events || events.length === 0) {
    return (
      <NoEvents>
        <p>No events found. Try adjusting your filters.</p>
      </NoEvents>
    );
  }

  return (
    <Container>
      {sortedDates.map(date => (
        <div key={date}>
          <DateHeader>
            {new Date(date).toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </DateHeader>
          
          <TimelineList>
            {groupedEvents[date].map(event => (
              <TimelineItem 
                key={event.id}
                onClick={() => onEventSelect(event)}
                color={getEventTypeColor(event.event_type)}
              >
                <EventTime>
                  <Clock size={14} />
                  {formatTimestamp(event.timestamp).split(',')[1]}
                </EventTime>
                
                <EventContent>
                  <EventType color={getEventTypeColor(event.event_type)}>
                    {getEventTypeLabel(event.event_type)}
                  </EventType>
                  
                  <EventDetails>
                    <PalletInfo onClick={(e) => {
                      e.stopPropagation();
                      onPalletSelect(event.pallet_id);
                    }}>
                      <Package size={14} />
                      Pallet: {event.pallet_number || event.pallet_id}
                      {event.tomato_type && ` (${event.tomato_type})`}
                    </PalletInfo>
                    
                    <UserInfo>
                      <User size={14} />
                      {event.user_name || `User ${event.user_id}`}
                    </UserInfo>
                    
                    {event.location_name && (
                      <span>
                        <ArrowRight size={14} />
                        Location: {event.location_name}
                      </span>
                    )}
                    
                    {event.order_number && (
                      <span>
                        <ArrowRight size={14} />
                        Order: {event.order_number}
                      </span>
                    )}
                  </EventDetails>
                </EventContent>
              </TimelineItem>
            ))}
          </TimelineList>
        </div>
      ))}
    </Container>
  );
};

/**
 * API configuration for different environments
 */

const environments = {
  development: {
    baseURL: '', // Empty string for relative URLs, will use proxy
    sseEndpoint: '/api/sse',
    wsBaseURL: 'ws://localhost:5000' // WebSocket URL for development
  },
  staging: {
    baseURL: 'https://staging.eyesu.ltd',
    sseEndpoint: '/api/sse',
    wsBaseURL: 'wss://staging.eyesu.ltd'
  },
  production: {
    baseURL: 'https://eyesu.ltd',
    sseEndpoint: '/api/sse',
    wsBaseURL: 'wss://eyesu.ltd'
  },
};

// Get current environment from NODE_ENV, default to development
const currentEnv = process.env.NODE_ENV || 'development';

// Export environment-specific configuration
export const apiConfig = environments[currentEnv] || environments.development;

// Export helper function to get full API URL
export const getApiUrl = (path) => {
  const base = apiConfig.baseURL;
  // In development (empty baseURL), just return the path
  if (!base) return path.startsWith('/') ? path : `/${path}`;
  // In production/staging, prepend the baseURL
  return `${base}${path.startsWith('/') ? path : `/${path}`}`;
};

// Export helper function to get full SSE URL
export const getSseUrl = (locationId) => {
  if (!locationId) {
    throw new Error('locationId is required for SSE URL');
  }
  return getApiUrl(`${apiConfig.sseEndpoint}/location/${locationId}`);
};

// Export helper function to get WebSocket URL
export const getWsUrl = (path) => {
  const base = apiConfig.wsBaseURL;
  return `${base}${path.startsWith('/') ? path : `/${path}`}`;
};

/**
 * Utility functions for handling zone coordinate transformations
 * These functions help manage the transition between visual (inverted) and actual row coordinates
 */

/**
 * Transforms a row index from inverted space to actual space
 * @param {number} invertedRowIndex - The row index in inverted coordinates
 * @param {Object} zoneConfig - The zone configuration object
 * @returns {number} The row index in actual coordinates
 */
export function transformToActualRowIndex(invertedRowIndex, zoneConfig) {
  // Early return for non-inverted zones
  if (!zoneConfig?.rowViewConfig?.invertRowsAndColumns) return invertedRowIndex;
  
  const visualDimensions = zoneConfig.dimensions;
  const actualDimensions = zoneConfig.rowViewConfig.dimensions;
  
  // Get zone orientation
  const { getZoneOrientation, ORIENTATIONS } = require('@features/map/constants/zoneOrientations');
  const orientation = zoneConfig.id ? getZoneOrientation(zoneConfig.id) : null;
  
  // For inverted zones, we need to transform the row index based on orientation
  let actualRow;
  
  if (orientation === ORIENTATIONS.EAST || orientation === ORIENTATIONS.WEST) {
    // For east/west orientations, the transformation is different
    actualRow = Math.floor(invertedRowIndex / visualDimensions.cols);
  } else {
    // For north/south orientations
    actualRow = invertedRowIndex % actualDimensions.rows;
  }
  
  // Add debug logging
  console.log('transformToActualRowIndex:', {
    invertedRowIndex,
    actualRow,
    zoneName: zoneConfig.name,
    orientation,
    visualDimensions,
    actualDimensions
  });
  
  return actualRow;
}

/**
 * Transforms a row index from actual space to inverted space with orientation awareness
 * @param {number} actualRowIndex - The row index in actual coordinates
 * @param {Object} zoneConfig - The zone configuration object
 * @returns {number} The row index in inverted coordinates
 */
export function transformToVisualRowIndex(actualRowIndex, zoneConfig) {
  // Input validation
  if (actualRowIndex === undefined || actualRowIndex === null) {
    console.error('transformToVisualRowIndex: Invalid actualRowIndex provided');
    return 0;
  }
  
  if (!zoneConfig) {
    console.error('transformToVisualRowIndex: No zoneConfig provided');
    return actualRowIndex;
  }
  
  // Early return for non-inverted zones
  if (!zoneConfig.rowViewConfig?.invertRowsAndColumns) {
    return actualRowIndex;
  }
  
  const visualDimensions = zoneConfig.dimensions;
  const actualDimensions = zoneConfig.rowViewConfig.dimensions;
  
  // Get zone orientation
  const { getZoneOrientation, ORIENTATIONS } = require('@features/map/constants/zoneOrientations');
  const orientation = zoneConfig.id ? getZoneOrientation(zoneConfig.id) : null;
  
  let visualRow;
  
  if (orientation === ORIENTATIONS.EAST || orientation === ORIENTATIONS.WEST) {
    // For east/west orientations
    visualRow = actualRowIndex * visualDimensions.cols;
  } else {
    // For north/south orientations
    visualRow = actualRowIndex;
  }
  
  // Add debug logging
  console.log('transformToVisualRowIndex:', {
    actualRowIndex,
    visualRow,
    zoneName: zoneConfig.name,
    orientation,
    visualDimensions,
    actualDimensions
  });
  
  // Ensure result is within bounds
  return Math.max(0, visualRow);
}

/**
 * Determines if row order should be reversed based on zone configuration
 * @param {Object} zoneConfig - The zone configuration object
 * @returns {boolean} True if rows should be displayed in reverse order
 */
export function shouldReverseRowOrder(zoneConfig) {
  // Input validation
  if (!zoneConfig) {
    console.error('shouldReverseRowOrder: No zoneConfig provided');
    return false;
  }
  
  // Get zone orientation
  const { getZoneOrientation, ORIENTATIONS } = require('@features/map/constants/zoneOrientations');
  const orientation = zoneConfig.id ? getZoneOrientation(zoneConfig.id) : null;
  
  // INVERTED LOGIC: East-facing zones need row reversal (instead of West)
  return orientation === ORIENTATIONS.EAST;
}

/**
 * Gets the correct dimensions to use based on the context
 * @param {Object} zoneConfig - The zone configuration object
 * @param {boolean} useActual - Whether to return actual dimensions instead of visual
 * @returns {Object} The appropriate dimensions object { rows, cols }
 */
export function getZoneDimensions(zoneConfig, useActual = false) {
  if (!zoneConfig) return { rows: 0, cols: 0 };
  
  // If this is an inverted zone and we want actual dimensions, use rowViewConfig
  if (useActual && zoneConfig.rowViewConfig?.invertRowsAndColumns) {
    return zoneConfig.rowViewConfig.dimensions;
  }
  
  // Otherwise use the visual dimensions
  return zoneConfig.dimensions;
}

/**
 * Determines if rows should be rendered horizontally based on zone configuration
 * @param {Object} zoneConfig - The zone configuration object
 * @param {boolean} useActual - Whether to use actual dimensions for calculation
 * @returns {boolean} True if rows should be rendered horizontally
 */
export function shouldRenderRowsHorizontally(zoneConfig, useActual = false) {
  if (!zoneConfig) return false;
  
  // If zone has forceVerticalRows flag, always render vertically
  if (zoneConfig.forceVerticalRows) return false;
  
  // Get the appropriate dimensions
  const dimensions = getZoneDimensions(zoneConfig, useActual);
  
  // Compare dimensions to determine orientation
  return dimensions.cols > dimensions.rows;
}

/**
 * Calculates the maximum number of rows for a zone
 * @param {Object} zoneConfig - The zone configuration object
 * @param {boolean} useActual - Whether to use actual dimensions
 * @returns {number} The maximum number of rows
 */
export function getMaxRows(zoneConfig, useActual = false) {
  if (!zoneConfig) return 0;
  
  const dimensions = getZoneDimensions(zoneConfig, useActual);
  const horizontal = shouldRenderRowsHorizontally(zoneConfig, useActual);
  
  return horizontal ? dimensions.cols : dimensions.rows;
}

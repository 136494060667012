/**
 * Sorting grades for tomato quality classification
 */
export const SORTING_GRADES = {
  LIGHT: 'LIGHT',
  MIDDLE: 'MIDDLE',
  RED: 'RED'
};

/**
 * Process stages for pallet logistics flow
 */
export const PROCESS_STAGES = {
  UNPROCESSED: 'unprocessed',
  IN_PROCESSING: 'in_processing',
  PROCESSED: 'processed',
  READY_FOR_SHIPPING: 'ready_for_shipping',
  RETURNED: 'returned'
};

/**
 * Get the display name for a process stage or sorting grade
 * @param {string} value - The process stage or sorting grade key
 * @param {string} type - The type ('process' or 'sorting')
 * @returns {string} - The formatted display name
 */
export const getDisplayName = (value, type = 'process') => {
  if (!value) return 'Unknown';
  
  if (type === 'sorting') {
    // For sorting grades, convert to title case
    return value.charAt(0).toUpperCase() + 
      value.slice(1).toLowerCase();
  }
  
  // For process stages, keep existing behavior
  return value.charAt(0).toUpperCase() + 
    value.slice(1).replace(/_/g, ' ');
};

/**
 * Get the badge status for a process stage or sorting grade
 * @param {string} value - The process stage or sorting grade
 * @param {string} type - The type ('process' or 'sorting')
 * @returns {string} - The badge status
 */
export const getBadgeStatus = (value, type = 'process') => {
  if (type === 'sorting') {
    // Handle Sorting grades
    switch (value) {
      case SORTING_GRADES.LIGHT: return 'info';
      case SORTING_GRADES.MIDDLE: return 'warning';
      case SORTING_GRADES.RED: return 'error';
      default: return 'default';
    }
  } else {
    // Handle Process stages
    switch (value) {
      case PROCESS_STAGES.UNPROCESSED: return 'warning';
      case PROCESS_STAGES.IN_PROCESSING: return 'info';
      case PROCESS_STAGES.PROCESSED: return 'success';
      case PROCESS_STAGES.READY_FOR_SHIPPING: return 'success';
      case PROCESS_STAGES.RETURNED: return 'error';
      default: return 'default';
    }
  }
};

import React from 'react';
import { usePermission } from '@lib/contexts';
import RefreshAdminAuthButton from '../RefreshAdminAuthButton';
import { Container, Header, Title, Card } from './components/StyledComponents';
import UserManagement from './components/UserManagement';
import { ClientAccessSection, UserRestrictionsSection } from './components/UserDetails';
import CreateUserModal from './modals/CreateUserModal';
import useAdminDashboard from './hooks/useAdminDashboard';

const AdminDashboard = () => {
  const { isAdmin } = usePermission();
  const {
    // States
    loading,
    users,
    companies,
    locations,
    selectedUser,
    userRestrictions,
    isCreateUserModalOpen,
    newUserData,
    
    // User related functions
    handleSelectUser,
    handleCreateUser,
    handleToggleRestriction,
    handleUpdateClientAccess,
    handleDeleteUser,
    setCreateUserModalOpen,
    setNewUserData,
  } = useAdminDashboard();
  
  // Render functions for user details sections
  const renderClientAccessSection = () => (
    <ClientAccessSection 
      user={selectedUser}
      onUpdateClientAccess={handleUpdateClientAccess}
    />
  );
  
  const renderUserRestrictions = () => (
    <UserRestrictionsSection 
      user={selectedUser} 
      userRestrictions={userRestrictions} 
      onToggleRestriction={handleToggleRestriction} 
    />
  );
  
  // If user doesn't have admin access, show not authorized
  if (!isAdmin()) {
    return (
      <Container>
        <Card>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            <div style={{ fontSize: '3rem', marginBottom: '1rem' }}>🔒</div>
            <Title>Access Denied</Title>
            <p>Your access to the Admin Dashboard has been restricted. Please contact another administrator.</p>
          </div>
        </Card>
      </Container>
    );
  }
  
  return (
    <Container>
      <Header>
        <Title>Admin Dashboard</Title>
        <RefreshAdminAuthButton />
      </Header>
      
      {loading ? (
        <Card>
          <div style={{ textAlign: 'center', padding: '2rem' }}>
            Loading...
          </div>
        </Card>
      ) : (
        <UserManagement 
          users={users}
          selectedUser={selectedUser}
          onSelectUser={handleSelectUser}
          onCreateUser={() => setCreateUserModalOpen(true)}
          onDeleteUser={handleDeleteUser}
          renderClientAccessSection={renderClientAccessSection}
          renderUserRestrictions={renderUserRestrictions}
        />
      )}
      
      {/* Modals */}
      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        userData={newUserData}
        onUserDataChange={setNewUserData}
        onCreateUser={handleCreateUser}
        locations={locations}
      />
    </Container>
  );
};

export default AdminDashboard;

/**
 * Position Utilities
 * 
 * This file re-exports the position-related functions from our standardized
 * position model, maintaining backward compatibility for existing code while
 * leveraging the new standardized models.
 */

import {
  createPosition as createPositionModel,
  normalizePosition as normalizePositionModel,
  addLegacyProperties as addLegacyPropertiesModel,
  getPositionKey as getPositionKeyModel,
  parsePositionKey as parsePositionKeyModel,
  getGlobalPositionKey as getGlobalPositionKeyModel,
  parseGlobalPositionKey as parseGlobalPositionKeyModel,
  isValidPosition as isValidPositionModel,
  POSITION_KEY_FORMATS
} from '../models/position';

/**
 * Create a standardized position object
 * @param {number} row - The row index
 * @param {number} col - The column index
 * @returns {Object} A standardized position object
 */
export function createPosition(row, col) {
  return createPositionModel(row, col);
}

/**
 * Add legacy position properties for backward compatibility
 * @param {Object} position - Standardized position object
 * @returns {Object} Position with legacy properties
 */
export function addLegacyProperties(position) {
  return addLegacyPropertiesModel(position);
}

/**
 * Normalize a position object to ensure it has the standard properties
 * @param {Object} position - The position object to normalize
 * @returns {Object} A standardized position object
 */
export function normalizePosition(position) {
  return normalizePositionModel(position);
}

/**
 * Generate a standardized position key for a position
 * @param {Object} position - The position object
 * @param {string} [format] - Optional key format from POSITION_KEY_FORMATS
 * @returns {string} A standardized position key
 */
export function getPositionKey(position, format) {
  return getPositionKeyModel(position, format);
}

/**
 * Parse a position key into a position object
 * @param {string} key - The position key to parse
 * @returns {Object|null} A position object or null if the key is invalid
 */
export function parsePositionKey(key) {
  return parsePositionKeyModel(key);
}

/**
 * Get a global position key that includes the zone
 * @param {string} zoneId - The zone identifier
 * @param {Object} position - The position object
 * @returns {string} A global position key
 */
export function getGlobalPositionKey(zoneId, position) {
  return getGlobalPositionKeyModel(zoneId, position);
}

/**
 * Parse a global position key
 * @param {string} key - The global position key
 * @returns {Object|null} A position with zone or null if invalid
 */
export function parseGlobalPositionKey(key) {
  return parseGlobalPositionKeyModel(key);
}

/**
 * Validate a position object
 * @param {Object} position - The position object to validate
 * @returns {boolean} Whether the position is valid
 */
export function isValidPosition(position) {
  return isValidPositionModel(position);
}

// Export the position key formats enumeration for consumers that need it
export { POSITION_KEY_FORMATS };

// Export a default object with all functions for convenience
export default {
  createPosition,
  normalizePosition,
  addLegacyProperties,
  getPositionKey,
  parsePositionKey,
  getGlobalPositionKey,
  parseGlobalPositionKey,
  isValidPosition,
  POSITION_KEY_FORMATS
};

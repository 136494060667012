import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background: ${({ theme }) => theme.colors.background.paper};
  max-height: ${props => props.maxHeight || 'none'};
  overflow-y: ${props => props.maxHeight ? 'auto' : 'visible'};
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;

  /* Add shadow to header when scrolled */
  &.is-scrolled thead {
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }

  /* Sticky header */
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${({ theme }) => theme.colors.background.paper};
    transition: box-shadow 0.2s;
  }

  /* Ensure consistent row heights */
  tr {
    height: 3.5rem;
  }

  /* Row hover effect */
  tbody tr:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }

  /* Selected row state */
  tbody tr.selected {
    background-color: ${({ theme }) => theme.colors.primary.alpha10};
  }

  /* Disabled row state */
  tbody tr.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const Table = React.forwardRef(({ children, ...props }, ref) => (
  <StyledTable ref={ref} {...props}>
    {children}
  </StyledTable>
));

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

Table.displayName = 'Table';

export default Table;

import { api } from '@lib/api';
import { analyticsService } from '@features/analytics/services';

export const usePalletHandlers = ({
  selectedLocation,
  user,
  loadPallets,
  onAsyncAction
}) => {
  const handleAddPallet = (palletData) => {
    return onAsyncAction(
      async () => {
        try {
          // Process the add pallet request
          let response = await api.post('/api/pallets/add', {
            ...palletData,
            locationId: selectedLocation.id,
            userId: user.id,
            quantity: 1 // Force single pallet creation
          });
          
          // Validate and process the response
          if (!response) {
            throw new Error('Empty response received from server');
          }
          
          // Handle various response formats
          if (typeof response.data === 'string') {
            // Convert 'ok' or similar to a proper object
            if (response.data === 'ok' || response.data.toLowerCase() === 'ok') {
              response.data = { 
                success: true, 
                ...palletData,
                locationId: selectedLocation.id
              };
            } else {
              // Try to parse if it's a JSON string
              try {
                response.data = JSON.parse(response.data);
              } catch (parseError) {
                console.error('Error parsing add pallet response:', parseError);
                // Fall back to basic success object
                response.data = { 
                  success: true, 
                  ...palletData,
                  locationId: selectedLocation.id
                };
              }
            }
          }
          
          // If we still don't have a valid object, create one
          if (!response.data || typeof response.data !== 'object') {
            response.data = { 
              success: true, 
              ...palletData,
              locationId: selectedLocation.id
            };
          }
          
          // Ensure response.data has an id property
          if (!response.data.id) {
            console.warn('Response data missing ID, generating temporary ID for UI purposes');
            response.data.id = `temp-${Date.now()}`;
          }
          
          // Log pallet creation event
          await analyticsService.logPalletEvent({
            pallet_id: response.data.id,
            event_type: 'pallet_created',
            user_id: user.id,
            location_id: selectedLocation.id,
            new_state: response.data
          });
          
          // Ensure UI updates by reloading pallets data
          await loadPallets(selectedLocation.id);
          
          return response.data; // Return created pallet for potential storage assignment
        } catch (error) {
          console.error('Error adding pallet:', error);
          
          // Provide specific, helpful error message
          if (error.message && error.message.includes('JSON')) {
            throw new Error(`Error adding pallet: Invalid response format. (${error.message})`);
          }
          
          throw new Error(`Error adding pallet: ${error.message}`);
        }
      },
      'Pallet added successfully'
    );
  };

  const handleDeletePallet = (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      return onAsyncAction(
        async () => {
          try {
            // Get current pallet state before deletion for logging
            const currentState = await api.get(`/api/pallets/${palletId}`);
            
            if (!currentState || !currentState.data) {
              throw new Error('Could not retrieve current pallet data');
            }
            
            // Store pallet details for error recovery and logging
            const palletDetails = currentState.data;
            
            // Process the deletion request
            let response = await api.delete(`/api/pallets/remove/${palletId}`);
            
            // Handle various response formats
            if (response) {
              // Handle string responses (common cause of "unexpected token" errors)
              if (typeof response.data === 'string') {
                // Convert 'ok' or similar to a proper object
                if (response.data === 'ok' || response.data.toLowerCase() === 'ok') {
                  response.data = { 
                    success: true, 
                    id: palletId,
                    deleted: true
                  };
                } else {
                  // Try to parse if it's a JSON string
                  try {
                    response.data = JSON.parse(response.data);
                  } catch (parseError) {
                    console.error('Error parsing delete pallet response:', parseError);
                    // Fall back to basic success object
                    response.data = { 
                      success: true, 
                      id: palletId,
                      deleted: true
                    };
                  }
                }
              }
              
              // If we still don't have a valid object, create one
              if (!response.data || typeof response.data !== 'object') {
                response.data = { 
                  success: true, 
                  id: palletId,
                  deleted: true
                };
              }
            } else {
              // For empty responses (common with DELETE operations)
              response = { 
                data: { 
                  success: true, 
                  id: palletId,
                  deleted: true
                } 
              };
            }
            
            // Log pallet deletion event
            await analyticsService.logPalletEvent({
              pallet_id: palletId,
              event_type: 'pallet_deleted',
              user_id: user.id,
              location_id: selectedLocation.id,
              previous_state: palletDetails
            });

            // Ensure UI updates by reloading pallets data
            await loadPallets(selectedLocation.id);
          } catch (error) {
            console.error('Error deleting pallet:', error);
            
            // Provide specific, helpful error message
            if (error.message && error.message.includes('JSON')) {
              throw new Error(`Error deleting pallet: Invalid response format. (${error.message})`);
            }
            
            throw new Error(`Error deleting pallet: ${error.message}`);
          }
        },
        'Pallet deleted successfully'
      );
    }
  };

  const handlePalletUpdate = async (updatedPalletData, selectedPallet) => {
    return onAsyncAction(
      async () => {
        const currentUser = user?.username || 'Unknown User';
        const editEntry = {
          editDate: new Date().toISOString(),
          editedBy: currentUser
        };
        
        const updateData = {
          ...updatedPalletData,
          locationId: selectedLocation.id,
          editHistory: [
            ...(selectedPallet.editHistory || []),
            editEntry
          ]
        };
        
        let response;
        try {
          response = await api.put(`/api/pallets/update/${selectedPallet.id}`, updateData);
          
          // Check if response.data is string 'ok' or similar (common source of JSON parse errors)
          if (response && typeof response.data === 'string') {
            // Handle the case where the server returns a string like 'ok'
            // Convert to a proper object format to prevent JSON parsing errors
            if (response.data === 'ok' || response.data.toLowerCase() === 'ok') {
              response.data = { 
                success: true, 
                id: selectedPallet.id,
                ...updateData 
              };
            } else {
              // Try to parse if it's a JSON string
              try {
                response.data = JSON.parse(response.data);
              } catch (parseError) {
                console.error('Error parsing response data:', parseError);
                // If parsing fails, create a valid response object
                response.data = { 
                  success: true, 
                  id: selectedPallet.id,
                  ...updateData
                };
              }
            }
          }
          
          // Ensure response.data is an object
          if (!response.data || typeof response.data !== 'object') {
            response.data = { 
              success: true, 
              id: selectedPallet.id,
              ...updateData
            };
          }
          
          // Log pallet update event
          await analyticsService.logPalletEvent({
            pallet_id: selectedPallet.id,
            event_type: 'pallet_updated',
            user_id: user.id,
            location_id: selectedLocation.id,
            previous_state: selectedPallet,
            new_state: response.data
          });
        } catch (error) {
          console.error('Error updating pallet:', error);
          
          // Check if it's a JSON parsing error
          if (error.message && error.message.includes('JSON')) {
            throw new Error(`Failed to update pallet: ${error.message}. This may be due to an invalid response format.`);
          }
          
          throw new Error(`Failed to update pallet: ${error.message}`);
        }

        await loadPallets(selectedLocation.id);
      },
      'Pallet updated successfully'
    );
  };

  const handleShipPallet = (palletId) => {
    if (window.confirm('Are you sure you want to mark this pallet as shipped?')) {
      return onAsyncAction(
        async () => {
          try {
            // Get current pallet state before shipping
            const currentState = await api.get(`/api/pallets/${palletId}`);
            if (!currentState || !currentState.data) {
              throw new Error('Could not retrieve current pallet data');
            }
            
            // Process the ship request
            let response = await api.put(`/api/pallets/mark-shipped/${palletId}`);
            
            // Validate and process the response
            if (response) {
              // Handle string responses (common cause of "unexpected token" errors)
              if (typeof response.data === 'string') {
                // Convert 'ok' or similar to a proper object
                if (response.data === 'ok' || response.data.toLowerCase() === 'ok') {
                  response.data = { 
                    success: true, 
                    id: palletId,
                    status: 'shipped',
                    orderId: currentState.data.orderId
                  };
                } else {
                  // Try to parse if it's a JSON string
                  try {
                    response.data = JSON.parse(response.data);
                  } catch (parseError) {
                    console.error('Error parsing ship pallet response:', parseError);
                    // Fall back to basic success object
                    response.data = { 
                      success: true, 
                      id: palletId,
                      status: 'shipped',
                      orderId: currentState.data.orderId
                    };
                  }
                }
              }
              
              // If we still don't have a valid object, create one
              if (!response.data || typeof response.data !== 'object') {
                response.data = { 
                  success: true, 
                  id: palletId,
                  status: 'shipped',
                  orderId: currentState.data.orderId,
                  shippedDate: new Date().toISOString(),
                  shippedBy: user?.username || 'Unknown User'
                };
              }
            } else {
              throw new Error('Empty response received from server');
            }
            
            // Log pallet shipping event
            await analyticsService.logPalletEvent({
              pallet_id: palletId,
              event_type: 'pallet_shipped',
              user_id: user.id,
              location_id: selectedLocation.id,
              previous_state: currentState.data,
              new_state: response.data,
              order_id: currentState.data.orderId
            });

            // Ensure UI updates by reloading pallets data
            await loadPallets(selectedLocation.id);
          } catch (error) {
            console.error('Error shipping pallet:', error);
            
            // Provide specific error message
            if (error.message && error.message.includes('JSON')) {
              throw new Error(`Error shipping pallet: Invalid response format. (${error.message})`);
            }
            
            throw new Error(`Error shipping pallet: ${error.message}`);
          }
        },
        'Pallet shipped successfully'
      );
    }
  };

  const handleSplitPallet = async (pallet) => {
    const splitColliCount = prompt(`Enter number of colli to split from pallet (max ${pallet.colliCount})`);
    if (!splitColliCount) return;
  
    const keepOrder = window.confirm('Keep order assignment for the split pallet?');
  
    return onAsyncAction(
      async () => {
        const response = await api.post(`/api/pallets/${pallet.id}/split`, {
          splitColliCount: parseInt(splitColliCount),
          keepOrderAssignment: keepOrder
        });
        
        // Log pallet split event
        await analyticsService.logPalletEvent({
          pallet_id: pallet.id,
          event_type: 'pallet_split',
          user_id: user.id,
          location_id: selectedLocation.id,
          previous_state: pallet,
          new_state: response.data.originalPallet,
          notes: `Split ${splitColliCount} colli into new pallet ${response.data.newPallet.id}`
        });

        // Log creation event for the new split pallet
        await analyticsService.logPalletEvent({
          pallet_id: response.data.newPallet.id,
          event_type: 'pallet_created',
          user_id: user.id,
          location_id: selectedLocation.id,
          new_state: response.data.newPallet,
          notes: `Created from splitting pallet ${pallet.id}`
        });

        await loadPallets(selectedLocation.id);
      },
      'Pallet split successfully'
    );
  };
  
  const handleReturnPallet = async (pallet) => {
    if (window.confirm('Return this pallet from production to warehouse?')) {
      return onAsyncAction(
        async () => {
          try {
            // First try to get current pallet state to ensure we have valid data for logging
            const currentState = await api.get(`/api/pallets/${pallet.id}`);
            
            // Make the return request
            let response = await api.post(`/api/pallets/${pallet.id}/return`);
            
            // Validate and process the response
            if (response) {
              // Handle string responses (common cause of "unexpected token" errors)
              if (typeof response.data === 'string') {
                // Convert 'ok' or similar to a proper object
                if (response.data === 'ok' || response.data.toLowerCase() === 'ok') {
                  response.data = { 
                    success: true, 
                    id: pallet.id,
                    status: 'warehouse',
                    orderId: null
                  };
                } else {
                  // Try to parse if it's a JSON string
                  try {
                    response.data = JSON.parse(response.data);
                  } catch (parseError) {
                    console.error('Error parsing return pallet response:', parseError);
                    // Fall back to basic success object
                    response.data = { 
                      success: true, 
                      id: pallet.id,
                      status: 'warehouse'
                    };
                  }
                }
              }
              
              // If we still don't have a valid object, create one
              if (!response.data || typeof response.data !== 'object') {
                response.data = { 
                  success: true, 
                  id: pallet.id,
                  status: 'warehouse'
                };
              }
            } else {
              throw new Error('Empty response received from server');
            }
            
            // Log pallet return event
            await analyticsService.logPalletEvent({
              pallet_id: pallet.id,
              event_type: 'pallet_returned',
              user_id: user.id,
              location_id: selectedLocation.id,
              previous_state: currentState?.data || pallet,
              new_state: response.data
            });

            // Ensure UI updates by reloading pallets data
            await loadPallets(selectedLocation.id);
          } catch (error) {
            console.error('Error returning pallet to warehouse:', error);
            
            // Provide specific error message
            if (error.message && error.message.includes('JSON')) {
              throw new Error(`Error returning pallet to warehouse: Invalid response format. (${error.message})`);
            }
            
            throw new Error(`Error returning pallet to warehouse: ${error.message}`);
          }
        },
        'Pallet returned to warehouse successfully'
      );
    }
  };

  return {
    handleAddPallet,
    handleDeletePallet,
    handlePalletUpdate,
    handleShipPallet,
    handleSplitPallet,
    handleReturnPallet
  };
};

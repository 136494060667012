import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@lib/contexts';
import { useUserSession } from '../../hooks';
import { Container, Title, Description, Input, Button, ErrorMessage, Form } from './styles';
import api from '@lib/api/axios';

const PasswordChange = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { user, setUser } = useUserSession();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Check if user is logged in and first_login is true
  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    // If the user has already changed their password (first_login is false),
    // redirect to dashboard
    if (user && !user.first_login) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const validateForm = () => {
    if (!currentPassword) {
      setError('Please enter your current password');
      return false;
    }
    
    if (!newPassword) {
      setError('Please enter a new password');
      return false;
    }
    
    if (newPassword.length < 6) {
      setError('Password must be at least 6 characters long');
      return false;
    }
    
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsLoading(true);
    setError('');

    try {
      const response = await api.post('/api/auth/change-password', {
        userId: user.id,
        currentPassword,
        newPassword,
        confirmPassword
      });

      // Update user in context with first_login = false
      if (response.data && response.data.data) {
        const updatedUser = response.data.data.user;
        const token = response.data.data.token;

        // Update localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(updatedUser));

        // Update user context
        setUser(updatedUser);
        
        showToast('Password updated successfully', 'success');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setError(error.response?.data?.message || 'An error occurred while changing your password');
    } finally {
      setIsLoading(false);
    }
  };

  // If user is not loaded yet, show loading
  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Title>Change Your Password</Title>
      <Description>
        For security reasons, you need to change your password on first login.
        Please choose a strong password that you'll remember.
      </Description>

      <Form onSubmit={handleSubmit}>
        <Input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          disabled={isLoading}
        />
        
        <Input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={isLoading}
        />
        
        <Input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          disabled={isLoading}
        />
        
        {error && <ErrorMessage>{error}</ErrorMessage>}
        
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Updating Password...' : 'Update Password'}
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordChange;

import { useState, useContext, useEffect } from 'react';
import { UserContext } from '@lib/contexts';
import { loginUser } from '../services';
import { clearAuthData, resetRedirectCounter } from '../services/tokenService';

export const useLogin = (redirectPath = '/dashboard') => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(UserContext);

  // Check URL params for error messages
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const loopDetected = params.get('loop');
    const expiredToken = params.get('expired');

    // Clean up URL params after reading them
    if (loopDetected || expiredToken) {
      const cleanUrl = window.location.pathname;
      window.history.replaceState({}, '', cleanUrl);
    }

    // Show appropriate error message
    if (loopDetected) {
      setError('Session error detected. Please try logging in again.');
    } else if (expiredToken) {
      setError('Your session has expired. Please login again.');
    }

    // Always clean any old auth data when on login page
    clearAuthData();
    
    // Reset any redirection counters
    resetRedirectCounter('token');
    resetRedirectCounter('login');
    resetRedirectCounter('app');
  }, []);

  const handleLogin = async () => {
    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }

    try {
      setError('');
      setIsLoading(true);
      
      // Clear any existing auth data before login
      clearAuthData();
      
      const response = await loginUser(username, password);
      setUser(response.user);
      
      // Reset redirection counters
      resetRedirectCounter('token');
      resetRedirectCounter('login');
      resetRedirectCounter('app');
      
      window.location.href = redirectPath;
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'An error occurred during login');
      setPassword('');
      clearAuthData();
    } finally {
      setIsLoading(false);
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    error,
    isLoading,
    handleLogin
  };
};

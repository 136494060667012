/**
 * Zone recommendations mapping based on tomato type, option and sorting grade
 */
export const ZONE_RECOMMENDATIONS = {
  // Plum tomatoes
  'plum': {
    'b': {
      'light': ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      'middle': ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      'red': ['zone-1', 'zone-2', 'zone-3', 'zone-4']
    },
    'a_fijn': {
      'light': ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      'middle': ['zone-1', 'zone-2', 'zone-3', 'zone-4']
    },
    '6 pack': {
      'light': ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      'middle': ['zone-1', 'zone-2', 'zone-3', 'zone-4'],
      'red': ['zone-1', 'zone-2', 'zone-3', 'zone-4']
    },
    '5 pack': {
      'light': ['zone-12', 'zone-13-old', 'zone-13-2', 'zone-13-1'],
      'middle': ['zone-12', 'zone-13-old', 'zone-13-2', 'zone-13-1'],
      'red': ['zone-12', 'zone-13-old', 'zone-13-2', 'zone-13-1']
    }
  },
  // Round tomatoes
  'round': {
    'a_grof': {
      'red': ['k1', 'k2'],
      'middle': ['zone-5'],
      'light': ['zone-10', 'zone-11']
    },
    'a_grof+': {  // A grof + variant
      'light': ['zone-6', 'zone-8', 'zone-9'],
      'middle': ['zone-6', 'zone-8', 'zone-9'],
      'red': ['zone-6', 'zone-8', 'zone-9']
    },
    'b': ['zone-7', 'zone-7-1', 'zone-7-2', 'zone-7-3'],
    'a_fijn': {
      'light': ['zone-6', 'zone-8', 'zone-9'],
      'middle': ['zone-6', 'zone-8', 'zone-9', 'k1', 'k2']
    }
  }
};

/**
 * Normalize tomato option string
 * @param {string} option - Raw tomato option string
 * @returns {string} Normalized option string
 */
const normalizeOption = (option) => {
  if (!option) return '';
  
  // Convert to lowercase and normalize spaces
  option = option.toLowerCase().trim();
  
  // Handle special cases
  if (option.includes('a grof +')) return 'a_grof+';
  if (option === 'a grof') return 'a_grof';
  if (option === 'a fijn') return 'a_fijn';
  if (option === '6 pack' || option === '6pack' || option === '6 Pack') return '6 pack';
  if (option === '5 pack' || option === '5pack' || option === '5 Pack') return '5 pack';
  if (option === 'b') return 'b';
  
  // Remove remaining spaces and normalize
  option = option.replace(/\s+/g, '');
  
  return option;
};

/**
 * Normalize sorting grade string
 * @param {string} grade - Raw sorting grade string
 * @returns {string} Normalized grade string
 */
const normalizeGrade = (grade) => {
  if (!grade) return '';
  
  // Convert to lowercase and normalize spaces
  grade = grade.toLowerCase().trim();
  
  // Handle special cases for packs
  if (grade === '6pack' || grade === '6 pack') return '6_pack';
  if (grade === '5pack' || grade === '5 pack') return '5_pack';
  
  // Handle uppercase sorting grades from SORTING_GRADES constant
  switch (grade) {
    case 'LIGHT':
    case 'light':
      return 'light';
    case 'MIDDLE':
    case 'middle':
      return 'middle';
    case 'RED':
    case 'red':
      return 'red';
    default:
      // Remove remaining spaces and normalize
      return grade.replace(/\s+/g, '');
  }
};

/**
 * Get recommended zones based on tomato type, option and sorting grade
 * @param {string} tomatoType - Type of tomato (plum, round)
 * @param {string} tomatoOption - Tomato option (B, A_fijn, A_grof, A_grof+)
 * @param {string} sortingGrade - Sorting grade (6_pack, 5_pack, red, middle, light, etc.)
 * @returns {string[]} Array of recommended zone IDs
 */
export const getRecommendedZones = (tomatoType, tomatoOption, sortingGrade) => {
  if (!tomatoType || !tomatoOption) return [];

  // Debug input values
  console.log('Zone Recommendations - Input:', {
    tomatoType,
    tomatoOption,
    sortingGrade
  });

  // Normalize inputs
  const normalizedType = tomatoType.toLowerCase();
  const normalizedOption = normalizeOption(tomatoOption);
  const normalizedGrade = normalizeGrade(sortingGrade);

  // Debug normalized values
  console.log('Zone Recommendations - Normalized:', {
    normalizedType,
    normalizedOption,
    normalizedGrade
  });

  // Get recommendations for the tomato type
  const typeRecommendations = ZONE_RECOMMENDATIONS[normalizedType];
  if (!typeRecommendations) {
    console.log('Zone Recommendations - No type recommendations found for:', normalizedType);
    return [];
  }

  // Get recommendations for the option
  const optionRecommendations = typeRecommendations[normalizedOption];
  if (!optionRecommendations) {
    console.log('Zone Recommendations - No option recommendations found for:', normalizedOption);
    return [];
  }

  // Debug recommendations found
  console.log('Zone Recommendations - Found:', {
    typeRecommendations: Object.keys(typeRecommendations),
    optionRecommendations,
    isArray: Array.isArray(optionRecommendations)
  });

  // If optionRecommendations is an array, it means no sorting grade distinction
  if (Array.isArray(optionRecommendations)) {
    return optionRecommendations;
  }

  // If we have sorting grade-specific recommendations, use those
  const result = normalizedGrade && optionRecommendations[normalizedGrade] 
    ? optionRecommendations[normalizedGrade] 
    : [];

  // Debug final result
  console.log('Zone Recommendations - Result:', {
    normalizedGrade,
    availableGrades: Object.keys(optionRecommendations),
    result
  });

  return result;
};

export default {
  ZONE_RECOMMENDATIONS,
  getRecommendedZones
};

/**
 * Position management utility enforcing safety-critical constraints
 */

// Strict bounds for position values
const POSITION_BOUNDS = {
  MAX_ROW: 1000, // Maximum allowed row index
  MAX_COL: 1000, // Maximum allowed column index
  MIN_ROW: 0,
  MIN_COL: 0
};

/**
 * Validates position coordinates against defined bounds
 * @param {number} row - Row index to validate
 * @param {number} col - Column index to validate
 * @throws {Error} If position is out of bounds
 */
const validatePosition = (row, col) => {
  // Input type validation
  if (!Number.isInteger(row) || !Number.isInteger(col)) {
    throw new Error('Position coordinates must be integers');
  }

  // Bounds checking
  if (row < POSITION_BOUNDS.MIN_ROW || row > POSITION_BOUNDS.MAX_ROW) {
    throw new Error(`Row index ${row} is out of bounds [${POSITION_BOUNDS.MIN_ROW}, ${POSITION_BOUNDS.MAX_ROW}]`);
  }
  if (col < POSITION_BOUNDS.MIN_COL || col > POSITION_BOUNDS.MAX_COL) {
    throw new Error(`Column index ${col} is out of bounds [${POSITION_BOUNDS.MIN_COL}, ${POSITION_BOUNDS.MAX_COL}]`);
  }
};

/**
 * Creates a standardized position object with bounds checking
 * @param {number} row - Row index
 * @param {number} col - Column index
 * @returns {Object} Normalized position object
 */
export const createPosition = (row, col) => {
  validatePosition(row, col);
  return {
    row,
    col,
    rowIndex: row, // For backward compatibility
    colIndex: col  // For backward compatibility
  };
};

/**
 * Generates a unique position key with validated coordinates
 * @param {Object} position - Position object with row and col
 * @returns {string} Unique position identifier
 */
export const getPositionKey = (position) => {
  if (!position || typeof position !== 'object') {
    throw new Error('Invalid position object');
  }

  const row = position.row ?? position.rowIndex;
  const col = position.col ?? position.colIndex;

  validatePosition(row, col);
  return `${row}_${col}`;
};

/**
 * Checks if a position is within zone dimensions
 * @param {Object} position - Position to check
 * @param {Object} zoneDimensions - Zone dimensions {rows: number, cols: number}
 * @returns {boolean} Whether position is within zone
 */
export const isPositionInZone = (position, zoneDimensions) => {
  if (!position || !zoneDimensions) {
    return false;
  }

  const row = position.row ?? position.rowIndex;
  const col = position.col ?? position.colIndex;

  try {
    validatePosition(row, col);
    return row < zoneDimensions.rows && col < zoneDimensions.cols;
  } catch {
    return false;
  }
};

/**
 * Converts legacy position formats to standardized format
 * @param {Object} position - Position in any supported format
 * @returns {Object} Standardized position object
 */
export const normalizePosition = (position) => {
  if (!position || typeof position !== 'object') {
    throw new Error('Invalid position object');
  }

  const row = position.row ?? position.rowIndex;
  const col = position.col ?? position.colIndex;

  return createPosition(row, col);
};

export default {
  createPosition,
  getPositionKey,
  isPositionInZone,
  normalizePosition,
  POSITION_BOUNDS
};

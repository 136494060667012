import React, { useState } from 'react';
import { 
  Card, Title, Button, Table, 
} from './StyledComponents';

const UserManagement = ({ 
  users, 
  selectedUser, 
  onSelectUser, 
  onCreateUser,
  onDeleteUser,
  renderClientAccessSection,
  renderUserRestrictions
}) => {
  const [confirmDelete, setConfirmDelete] = useState(null);
  // Ensure users is an array before rendering
  const safeUsers = Array.isArray(users) ? users : [];
  
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '40%', marginRight: '1rem' }}>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            <Title>Users</Title>
            <Button variant="primary" onClick={onCreateUser} style={{ background: '#4CAF50', color: 'white' }}>Add User</Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Role</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {safeUsers.map(user => (
                <tr key={user.id} style={{ background: selectedUser?.id === user.id ? '#f0f9ff' : 'transparent' }}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>
                    <Button onClick={() => onSelectUser(user)}>
                      Manage Permissions
                    </Button>
                  </td>
                  <td>
                    {confirmDelete === user.id ? (
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <Button 
                          onClick={() => {
                            onDeleteUser(user.id);
                            setConfirmDelete(null);
                          }}
                          style={{ background: '#ff4d4f', color: 'white', fontSize: '0.8rem' }}
                        >
                          Confirm
                        </Button>
                        <Button 
                          onClick={() => setConfirmDelete(null)}
                          style={{ fontSize: '0.8rem' }}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <Button 
                        onClick={() => setConfirmDelete(user.id)}
                        style={{ background: '#ff7875', color: 'white' }}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </div>
      
      <div style={{ width: '60%' }}>
        {selectedUser ? (
          <>
            {renderClientAccessSection && renderClientAccessSection()}
            {renderUserRestrictions && renderUserRestrictions()}
          </>
        ) : (
          <Card>
            <p>Select a user to manage their permissions</p>
          </Card>
        )}
      </div>
    </div>
  );
};

export default UserManagement;

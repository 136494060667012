import { PROCESS_STAGES } from '@features/shared/constants/processStages';

export const processStages = Object.values(PROCESS_STAGES);

export const dateRangeOptions = {
  all: 'All Time',
  today: 'Today',
  week: 'This Week',
  month: 'This Month',
  custom: 'Custom Range'
};

export const weightRangeOptions = {
  all: 'All Weights',
  under500: 'Under 500kg',
  '500to1000': '500kg - 1000kg',
  over1000: 'Over 1000kg'
};

export const boxCountOptions = {
  all: 'All Box Counts',
  with: 'With Box Count',
  without: 'Without Box Count'
};

export const palletStatuses = {
  all: 'All Pallets',
  active: 'Active',
  processing: 'In Processing',
  shipped: 'Shipped'
};

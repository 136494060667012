import localforage from 'localforage';

// Initialize localforage instance for scan history
const scanHistoryStore = localforage.createInstance({
  name: 'scanHistory'
});

// Maximum number of scans to keep in history
const MAX_HISTORY_ITEMS = 100;

export const scanHistoryService = {
  async addScan(scanData) {
    try {
      // Get current history
      const history = await this.getHistory() || [];
      
      // Add new scan with timestamp
      const newScan = {
        ...scanData,
        timestamp: new Date().toISOString(),
        id: `scan_${Date.now()}`
      };
      
      // Add to front of array and limit size
      const updatedHistory = [newScan, ...history].slice(0, MAX_HISTORY_ITEMS);
      
      // Save updated history
      await scanHistoryStore.setItem('history', updatedHistory);
      
      return newScan;
    } catch (error) {
      console.error('Error adding scan to history:', error);
      throw error;
    }
  },

  async getHistory() {
    try {
      return await scanHistoryStore.getItem('history') || [];
    } catch (error) {
      console.error('Error getting scan history:', error);
      throw error;
    }
  },

  async clearHistory() {
    try {
      await scanHistoryStore.clear();
    } catch (error) {
      console.error('Error clearing scan history:', error);
      throw error;
    }
  },

  async deleteScan(scanId) {
    try {
      const history = await this.getHistory();
      const updatedHistory = history.filter(scan => scan.id !== scanId);
      await scanHistoryStore.setItem('history', updatedHistory);
    } catch (error) {
      console.error('Error deleting scan:', error);
      throw error;
    }
  },

  async searchHistory(query) {
    try {
      const history = await this.getHistory();
      return history.filter(scan => 
        Object.values(scan).some(value => 
          String(value).toLowerCase().includes(query.toLowerCase())
        )
      );
    } catch (error) {
      console.error('Error searching scan history:', error);
      throw error;
    }
  },

  async getRecentScans(limit = 10) {
    try {
      const history = await this.getHistory();
      return history.slice(0, limit);
    } catch (error) {
      console.error('Error getting recent scans:', error);
      throw error;
    }
  },

  async getScanStats() {
    try {
      const history = await this.getHistory();
      return {
        totalScans: history.length,
        lastScanTime: history[0]?.timestamp || null,
        scansByDay: history.reduce((acc, scan) => {
          const date = new Date(scan.timestamp).toLocaleDateString();
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {})
      };
    } catch (error) {
      console.error('Error getting scan stats:', error);
      throw error;
    }
  }
};

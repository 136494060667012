import React from 'react';
import { 
  formatTimestamp, 
  getEventTypeLabel, 
  getEventTypeColor,
  extractStateChanges,
  formatFieldName
} from '../../utils';
import { 
  Container, 
  Header, 
  CloseButton, 
  Title, 
  Content, 
  Section, 
  SectionTitle, 
  DetailRow, 
  Label, 
  Value, 
  StateChanges, 
  StateChange, 
  ChangeValue, 
  Notes, 
  ActionButton 
} from './styles';
import { X, User, Package, Clock, Calendar, MapPin, ShoppingCart, ArrowRight, MessageSquare } from 'lucide-react';

export const EventDetails = ({ event, onClose, onIntervene }) => {
  if (!event) return null;

  const stateChanges = extractStateChanges(event);
  
  return (
    <Container>
      <Header color={getEventTypeColor(event.event_type)}>
        <Title>{getEventTypeLabel(event.event_type)}</Title>
        <CloseButton onClick={onClose}>
          <X size={18} />
        </CloseButton>
      </Header>
      
      <Content>
        <Section>
          <SectionTitle>Event Information</SectionTitle>
          
          <DetailRow>
            <Label>
              <Clock size={14} />
              Time
            </Label>
            <Value>{formatTimestamp(event.timestamp)}</Value>
          </DetailRow>
          
          <DetailRow>
            <Label>
              <Calendar size={14} />
              Date
            </Label>
            <Value>
              {new Date(event.timestamp).toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </Value>
          </DetailRow>
          
          <DetailRow>
            <Label>
              <User size={14} />
              User
            </Label>
            <Value>{event.user_name || `User ${event.user_id}`}</Value>
          </DetailRow>
        </Section>
        
        <Section>
          <SectionTitle>Pallet Information</SectionTitle>
          
          <DetailRow>
            <Label>
              <Package size={14} />
              Pallet
            </Label>
            <Value>
              {event.pallet_number || `#${event.pallet_id}`}
              {event.tomato_type && ` (${event.tomato_type})`}
            </Value>
          </DetailRow>
          
          {event.box_type && (
            <DetailRow>
              <Label>Box Type</Label>
              <Value>{event.box_type}</Value>
            </DetailRow>
          )}
          
          {event.pallet_type && (
            <DetailRow>
              <Label>Pallet Type</Label>
              <Value>{event.pallet_type}</Value>
            </DetailRow>
          )}
          
          {event.location_name && (
            <DetailRow>
              <Label>
                <MapPin size={14} />
                Location
              </Label>
              <Value>{event.location_name}</Value>
            </DetailRow>
          )}
          
          {event.order_number && (
            <DetailRow>
              <Label>
                <ShoppingCart size={14} />
                Order
              </Label>
              <Value>{event.order_number}</Value>
            </DetailRow>
          )}
        </Section>
        
        {stateChanges.length > 0 && (
          <Section>
            <SectionTitle>State Changes</SectionTitle>
            
            <StateChanges>
              {stateChanges.map((change, index) => (
                <StateChange key={index}>
                  <Label>{formatFieldName(change.field)}</Label>
                  <ChangeValue>
                    {change.oldValue !== null ? String(change.oldValue) : 'null'}
                    <ArrowRight size={14} />
                    {change.newValue !== null ? String(change.newValue) : 'null'}
                  </ChangeValue>
                </StateChange>
              ))}
            </StateChanges>
          </Section>
        )}
        
        {event.notes && (
          <Section>
            <SectionTitle>Notes</SectionTitle>
            <Notes>{event.notes}</Notes>
          </Section>
        )}
        
        <ActionButton onClick={onIntervene}>
          <MessageSquare size={16} />
          Add Intervention
        </ActionButton>
      </Content>
    </Container>
  );
};

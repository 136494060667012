import React from 'react';
import { 
  Container, 
  FilterGroup, 
  Label, 
  Select, 
  DateInput,
  FilterButton,
  ClearButton
} from './styles';
import { Filter, X } from 'lucide-react';
import { getEventTypeLabel } from '../../utils';

export const AnalyticsFilters = ({ filters, onFilterChange, onClearFilters, eventTypes = [] }) => {
  const handleChange = (field, value) => {
    onFilterChange({
      ...filters,
      [field]: value
    });
  };

  const handleDateChange = (field) => (e) => {
    const value = e.target.value;
    onFilterChange({
      ...filters,
      [field]: value ? new Date(value).toISOString() : null
    });
  };

  const clearFilters = () => {
    if (onClearFilters) {
      onClearFilters();
    } else {
      onFilterChange({
        eventType: '',
        startDate: null,
        endDate: null,
        userId: '',
        palletId: ''
      });
    }
  };

  // Convert ISO dates to YYYY-MM-DD for date inputs
  const formatDateForInput = (isoDate) => {
    if (!isoDate) return '';
    return new Date(isoDate).toISOString().split('T')[0];
  };

  return (
    <Container>
      <FilterGroup>
        <Label>Event Type</Label>
        <Select
          value={filters.eventType || ''}
          onChange={(e) => handleChange('eventType', e.target.value)}
        >
          <option value="">All Events</option>
          {eventTypes.map(type => (
            <option key={type} value={type}>
              {getEventTypeLabel(type)}
            </option>
          ))}
        </Select>
      </FilterGroup>

      <FilterGroup>
        <Label>Start Date</Label>
        <DateInput
          type="date"
          value={formatDateForInput(filters.startDate)}
          onChange={handleDateChange('startDate')}
          max={formatDateForInput(filters.endDate)}
        />
      </FilterGroup>

      <FilterGroup>
        <Label>End Date</Label>
        <DateInput
          type="date"
          value={formatDateForInput(filters.endDate)}
          onChange={handleDateChange('endDate')}
          min={formatDateForInput(filters.startDate)}
        />
      </FilterGroup>

      <FilterGroup>
        <Label>User ID</Label>
        <Select
          value={filters.userId || ''}
          onChange={(e) => handleChange('userId', e.target.value)}
        >
          <option value="">All Users</option>
          {/* User options would be populated from props */}
        </Select>
      </FilterGroup>

      <FilterButton>
        <Filter size={14} />
        Filters
      </FilterButton>

      <ClearButton onClick={clearFilters}>
        <X size={14} />
        Clear
      </ClearButton>
    </Container>
  );
};

export * from './analyticsUtils';

/**
 * Format a timestamp into a readable date and time
 * @param {string} timestamp - ISO timestamp string
 * @returns {string} Formatted date and time
 */
export const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'N/A';
  
  try {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return 'Invalid date';
  }
};

/**
 * Format a field name into a human-readable label
 * @param {string} fieldName The field name to format
 * @returns {string} The formatted field name
 */
export const formatFieldName = (fieldName) => {
  if (!fieldName) return '';
  
  // Convert snake_case or camelCase to space-separated words
  const words = fieldName
    .replace(/([A-Z])/g, ' $1') // Split on capital letters
    .replace(/_/g, ' ')         // Replace underscores with spaces
    .toLowerCase()
    .trim();
  
  // Capitalize first letter of each word
  return words
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Get the color associated with an event type
 * @param {string} eventType The type of event
 * @returns {string} The color code for the event type
 */
export const getEventTypeColor = (eventType) => {
  const colors = {
    // Original mappings
    PALLET_ADDED: '#4CAF50',      // Green
    PALLET_REMOVED: '#F44336',    // Red
    PALLET_UPDATED: '#2196F3',    // Blue
    PALLET_SHIPPED: '#9C27B0',    // Purple
    PALLET_RETURNED: '#FF9800',   // Orange
    ORDER_CREATED: '#00BCD4',     // Cyan
    ORDER_UPDATED: '#3F51B5',     // Indigo
    ORDER_COMPLETED: '#8BC34A',   // Light Green
    ORDER_DELETED: '#E91E63',     // Pink
    USER_LOGIN: '#607D8B',        // Blue Grey
    USER_LOGOUT: '#795548',       // Brown
    SYSTEM_EVENT: '#9E9E9E',      // Grey
    
    // SSE-based event mappings - use same colors as their counterparts
    pallet_add: '#4CAF50',        // Green
    pallet_create: '#4CAF50',     // Green
    pallet_update: '#2196F3',     // Blue
    pallet_edit: '#2196F3',       // Blue
    pallet_delete: '#F44336',     // Red
    pallet_remove: '#F44336',     // Red
    pallet_ship: '#9C27B0',       // Purple
    pallet_return: '#FF9800',     // Orange
    
    order_add: '#00BCD4',         // Cyan
    order_create: '#00BCD4',      // Cyan
    order_update: '#3F51B5',      // Indigo
    order_edit: '#3F51B5',        // Indigo
    order_delete: '#E91E63',      // Pink
    order_remove: '#E91E63',      // Pink
    order_complete: '#8BC34A',    // Light Green
    
    pallet_order_assignment: '#673AB7', // Deep Purple
    
    user_preferences_update: '#607D8B', // Blue Grey
    user_preferences_add: '#607D8B',    // Blue Grey
    user_preferences_delete: '#607D8B', // Blue Grey
    
    printer_update: '#795548',    // Brown
    printer_add: '#795548',       // Brown
    printer_delete: '#795548',    // Brown
    
    location_settings_update: '#009688', // Teal
    location_settings_add: '#009688',    // Teal
    location_settings_delete: '#009688', // Teal
    
    sse_pallet_update: '#2196F3',  // Blue
    sse_order_update: '#3F51B5',   // Indigo
    sse_assignment_update: '#673AB7', // Deep Purple
    
    DEFAULT: '#757575'            // Dark Grey
  };
  
  return colors[eventType] || colors.DEFAULT;
};

/**
 * Get a human-readable label for an event type
 * @param {string} eventType The type of event
 * @returns {string} The human-readable label
 */
export const getEventTypeLabel = (eventType) => {
  const labels = {
    // Original mappings
    PALLET_ADDED: 'Pallet Added',
    PALLET_REMOVED: 'Pallet Removed',
    PALLET_UPDATED: 'Pallet Updated',
    PALLET_SHIPPED: 'Pallet Shipped',
    PALLET_RETURNED: 'Pallet Returned',
    ORDER_CREATED: 'Order Created',
    ORDER_UPDATED: 'Order Updated',
    ORDER_COMPLETED: 'Order Completed',
    ORDER_DELETED: 'Order Deleted',
    USER_LOGIN: 'User Login',
    USER_LOGOUT: 'User Logout',
    SYSTEM_EVENT: 'System Event',
    
    // SSE-based event mappings
    pallet_add: 'Pallet Added',
    pallet_update: 'Pallet Updated',
    pallet_delete: 'Pallet Removed',
    pallet_ship: 'Pallet Shipped',
    pallet_return: 'Pallet Returned',
    pallet_create: 'Pallet Created',
    pallet_edit: 'Pallet Edited',
    pallet_remove: 'Pallet Removed',
    
    order_add: 'Order Added',
    order_update: 'Order Updated',
    order_delete: 'Order Deleted',
    order_create: 'Order Created',
    order_edit: 'Order Edited',
    order_remove: 'Order Removed',
    order_complete: 'Order Completed',
    
    pallet_order_assignment: 'Pallet Assigned to Order',
    
    user_preferences_update: 'User Preferences Updated',
    user_preferences_add: 'User Preferences Added',
    user_preferences_delete: 'User Preferences Deleted',
    
    printer_update: 'Printer Config Updated',
    printer_add: 'Printer Config Added',
    printer_delete: 'Printer Config Deleted',
    
    location_settings_update: 'Location Settings Updated',
    location_settings_add: 'Location Settings Added',
    location_settings_delete: 'Location Settings Deleted',
    
    sse_pallet_update: 'Pallet Updated',
    sse_order_update: 'Order Updated',
    sse_assignment_update: 'Assignment Updated'
  };
  
  return labels[eventType] || 'Unknown Event';
};

/**
 * Extract state changes from an event
 * @param {Object} event The event object
 * @returns {Array} Array of state changes
 */
export const extractStateChanges = (event) => {
  if (!event) return [];
  
  let previous = {};
  let current = {};
  
  // Handle different formats of event data
  if (event.data && (event.data.previous || event.data.current)) {
    // Original format
    previous = event.data.previous || {};
    current = event.data.current || {};
  } else if (event.previous_state || event.new_state) {
    // Direct database format
    try {
      previous = event.previous_state ? JSON.parse(event.previous_state) : {};
      current = event.new_state ? JSON.parse(event.new_state) : {};
    } catch (error) {
      console.error('Error parsing state JSON:', error);
      return [];
    }
  } else {
    // No state change data available
    return [];
  }
  
  const changes = [];
  
  // Get all unique keys from both states
  const allKeys = [...new Set([...Object.keys(previous), ...Object.keys(current)])];
  
  allKeys.forEach(key => {
    const prevValue = previous[key];
    const currValue = current[key];
    
    if (prevValue !== currValue) {
      changes.push({
        field: key,
        oldValue: prevValue,
        newValue: currValue
      });
    }
  });
  
  return changes;
};

/**
 * Group events by date
 * @param {Array} events Array of events
 * @returns {Object} Events grouped by date
 */
export const groupEventsByDate = (events) => {
  return events.reduce((acc, event) => {
    const date = new Date(event.timestamp).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {});
};

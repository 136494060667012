/**
 * Zone orientation configurations
 * Defines the physical orientation of each storage zone
 * This determines how row numbers are displayed (left-to-right when facing the zone)
 */

export const ORIENTATIONS = {
  EAST: 'east',   // Default orientation, rows numbered left-to-right
  SOUTH: 'south', // Facing south, rows numbered left-to-right
  WEST: 'west',   // Facing west, rows need to be reversed
  NORTH: 'north'  // Facing north, rows numbered left-to-right
};

export const ZONE_ORIENTATIONS = {
  // East-facing zones (standard left-to-right)
  [ORIENTATIONS.EAST]: [
    'zone-1',
    'zone-2',
    'zone-3',
    'zone-7',
    'zone-10',
    'zone-11'
  ],
  
  // South-facing zones
  [ORIENTATIONS.SOUTH]: [
    'zone-4',
    'zone-5',
    'zone-6',
    'oversorteer'
  ],
  
  // West-facing zones (needs row reversal)
  [ORIENTATIONS.WEST]: [
    'bakker',
    'zone-12',
    'zone-13-old',
    'zone-13-1',
    'zone-13-2',
    'k1',
    'k2'
  ],
  
  // North-facing zones
  [ORIENTATIONS.NORTH]: [
    'zone-8',
    'zone-9'
  ]
};

/**
 * Get the orientation for a specific zone
 * @param {string} zoneId - The zone identifier
 * @returns {string} The zone's orientation (from ORIENTATIONS enum)
 */
export const getZoneOrientation = (zoneId) => {
  for (const [orientation, zones] of Object.entries(ZONE_ORIENTATIONS)) {
    if (zones.includes(zoneId)) {
      return orientation;
    }
  }
  return ORIENTATIONS.EAST; // Default to east if not specified
};

/**
 * Transform a row index based on zone orientation
 * @param {number} rowIndex - The original row index
 * @param {string} zoneId - The zone identifier
 * @param {number} totalRows - Total number of rows in the zone
 * @returns {number} The transformed row index
 */
export const transformRowIndex = (rowIndex, zoneId, totalRows) => {
  const orientation = getZoneOrientation(zoneId);
  
  switch (orientation) {
    case ORIENTATIONS.EAST:
      // INVERTED LOGIC: For east-facing zones, reverse the row order
      return totalRows - 1 - rowIndex;
      
    case ORIENTATIONS.WEST:
    case ORIENTATIONS.SOUTH:
    case ORIENTATIONS.NORTH:
    default:
      // For other orientations, maintain left-to-right ordering
      return rowIndex;
  }
};

/**
 * Transform a display index to storage index
 * @param {number} displayIndex - The display row index (what user sees)
 * @param {string} zoneId - The zone identifier
 * @param {number} totalRows - Total number of rows in the zone
 * @returns {number} The actual storage index
 */
export const displayToStorageIndex = (displayIndex, zoneId, totalRows) => {
  const orientation = getZoneOrientation(zoneId);
  
  switch (orientation) {
    case ORIENTATIONS.EAST:
      // INVERTED LOGIC: For east-facing zones, reverse the row order
      return totalRows - 1 - displayIndex;
      
    case ORIENTATIONS.WEST:
    case ORIENTATIONS.SOUTH:
    case ORIENTATIONS.NORTH:
    default:
      // For other orientations, maintain left-to-right ordering
      return displayIndex;
  }
};

/**
 * Transform a storage index to display index
 * @param {number} storageIndex - The actual storage row index
 * @param {string} zoneId - The zone identifier
 * @param {number} totalRows - Total number of rows in the zone
 * @returns {number} The display index (what user should see)
 */
export const storageToDisplayIndex = (storageIndex, zoneId, totalRows) => {
  const orientation = getZoneOrientation(zoneId);
  
  switch (orientation) {
    case ORIENTATIONS.EAST:
      // INVERTED LOGIC: For east-facing zones, reverse the row order
      return totalRows - 1 - storageIndex;
      
    case ORIENTATIONS.WEST:
    case ORIENTATIONS.SOUTH:
    case ORIENTATIONS.NORTH:
    default:
      // For other orientations, maintain left-to-right ordering
      return storageIndex;
  }
};

export default {
  ORIENTATIONS,
  ZONE_ORIENTATIONS,
  getZoneOrientation,
  transformRowIndex,
  displayToStorageIndex,
  storageToDisplayIndex
};

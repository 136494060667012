import styled, { keyframes } from 'styled-components';

// Animation keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounceIn = keyframes`
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.05); opacity: 0.9; }
  70% { transform: scale(0.9); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Modal components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 16px;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
`;

const ModalTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  
  &:hover {
    background: #f5f5f5;
  }
`;

const ModalBody = styled.div`
  padding: 20px;
  flex: 1;
  overflow-y: auto;
`;

const ModalFooter = styled.div`
  padding: 16px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(StyledButton)`
  background: #0066cc;
  color: white;
  
  &:hover:not(:disabled) {
    background: #0055aa;
  }
`;

const SecondaryButton = styled(StyledButton)`
  background: #f0f0f0;
  color: #333;
  
  &:hover:not(:disabled) {
    background: #e0e0e0;
  }
`;

// Zone view components
const ZoneInstructionCard = styled.div`
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  text-align: center;
`;

const ZoneHighlight = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #fee2e2;
  border-radius: 6px;
  color: #b91c1c;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 12px 0;
  border: 1px solid rgba(220, 38, 38, 0.3);
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.1);
  
  /* Responsive font size */
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 6px 12px;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 280px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
`;

const ZoneInstructions = styled.div`
  margin-top: 12px;
  font-size: 0.9rem;
  color: #555;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
`;

const StepDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ active }) => active ? '#0066cc' : '#ddd'};
`;

// Row visualization components
const RowsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 12px;
  margin: 20px 0;
`;

const RowCard = styled.div`
  background: ${props => props.isActive ? '#e8f5e9' : '#f5f9ff'};
  border: 2px solid ${props => props.isActive ? '#4caf50' : '#0066cc'};
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const RowIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${props => props.isActive ? '#e8f5e9' : '#f0f7ff'};
  border-radius: 50%;
  margin-bottom: 8px;
  color: ${props => props.isActive ? '#2e7d32' : '#0066cc'};
`;

const RowName = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 4px;
`;

const RowDetails = styled.div`
  font-size: 0.85rem;
  color: #666;
`;

// Orientation indicator for zone direction
const OrientationIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
  padding: 8px 12px;
  background-color: #f8fafc;
  border-radius: 6px;
  color: #64748b;
  font-size: 0.875rem;

  svg {
    color: #0369a1;
  }

  .orientation-text {
    font-weight: 500;
    color: #0369a1;
  }

  .orientation-hint {
    margin-left: auto;
    font-style: italic;
    color: #94a3b8;
    font-size: 0.75rem;
  }
`;

// Enhanced map components for row visualization
const EnhancedMapContainer = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
  
  /* Make this taller than the regular map */
  height: 350px;
  
  @media (max-width: 768px) {
    height: 300px;
  }
`;

const RowLegend = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
  font-size: 0.85rem;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const LegendColor = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background: ${props => props.color};
`;

// Components for pallet list and scanning
const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
  margin: 20px 0;
`;

const PalletCard = styled.div`
  background: ${props => {
    if (props.isAssignedToCurrentOrder) return '#e8f5e9'; // Green background for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#ffebee'; // Red background for pallets assigned to other orders
    return '#f5f9ff'; // Default blue background
  }};
  border: 2px solid ${props => {
    if (props.isAssignedToCurrentOrder) return '#4caf50'; // Green border for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#ef5350'; // Red border for pallets assigned to other orders
    return '#0066cc'; // Default blue border
  }};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  
  color: ${props => {
    if (props.isAssignedToCurrentOrder) return '#1b5e20'; // Green text for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#d32f2f'; // Red text for pallets assigned to other orders
    return 'inherit'; // Default text color
  }};
`;

const ScannedOverlay = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ef5350;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${bounceIn} 0.5s ease-out;
`;

const PalletIdLarge = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 8px;
  word-break: break-all;
`;

const StatusBadge = styled.div`
  background: ${props => props.isScanned ? '#ffcdd2' : '#e8f5e9'};
  color: ${props => props.isScanned ? '#d32f2f' : '#2e7d32'};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.3s ease;
`;

// Scanner section
const ScanSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScanButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 16px;
  
  &:hover {
    background: #0055aa;
  }
`;

// Celebration overlay
const CelebrationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 118, 210, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CelebrationContent = styled.div`
  background: white;
  border-radius: 16px;
  padding: 32px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  animation: ${bounceIn} 0.6s ease-out;
`;

const CelebrationTitle = styled.h2`
  margin: 0 0 16px;
  color: #2e7d32;
  font-size: 1.8rem;
`;

const CelebrationMessage = styled.p`
  margin: 0 0 24px;
  color: #455a64;
  font-size: 1.1rem;
  line-height: 1.5;
`;

const TrophyIcon = styled.div`
  color: #ffc107;
  margin-bottom: 20px;
  animation: ${spin} 2s ease-out;
  svg {
    height: 64px;
    width: 64px;
  }
`;

const CompletionButton = styled(PrimaryButton)`
  background: #2e7d32;
  padding: 12px 24px;
  font-size: 1.1rem;
  
  &:hover:not(:disabled) {
    background: #1b5e20;
  }
`;

// Define steps for the workflow - with enhanced row visualization step
const STEPS = {
  SHOW_ZONE: 'SHOW_ZONE',         // Step 1: Show storage map with zone
  SHOW_ROWS: 'SHOW_ROWS',         // Step 2: Show zoomed map with highlighted rows
  LIST_PALLETS: 'LIST_PALLETS',    // Step 3: Show pallets in those rows
  SCAN_PALLET: 'SCAN_PALLET'       // Step 4: Scan pallet confirmation
};

export {
  // Animation keyframes
  fadeIn,
  bounceIn,
  spin,
  
  // Modal components
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  
  // Zone view components
  ZoneInstructionCard,
  ZoneHighlight,
  MapContainer,
  ZoneInstructions,
  StepIndicator,
  StepDot,
  
  // Row visualization components
  RowsGrid,
  RowCard,
  RowIcon,
  RowName,
  RowDetails,
  OrientationIndicator,
  
  // Enhanced map components
  EnhancedMapContainer,
  RowLegend,
  LegendItem,
  LegendColor,
  
  // Pallet components
  PalletGrid,
  PalletCard,
  ScannedOverlay,
  PalletIdLarge,
  StatusBadge,
  
  // Scanner components
  ScanSection,
  ScanButton,
  
  // Celebration components
  CelebrationOverlay,
  CelebrationContent,
  CelebrationTitle,
  CelebrationMessage,
  TrophyIcon,
  CompletionButton,
  
  // Step constants
  STEPS
};

import { api } from '@lib/api';
import { 
  validateOrderData, 
  MAX_ORDERS,
  normalizeAllowedPalletIds,
  getPalletOrderId,
  getPalletBoxCount 
} from './validationUtils';

/**
 * Calculates total boxes based on layers and individual boxes
 * @param {string} boxType - Type of box
 * @param {number} remainingLayers - Number of remaining layers
 * @param {number} remainingBoxes - Number of remaining individual boxes
 * @returns {number} Total remaining boxes
 */
export const calculateTotalRemainingBoxes = (boxType, remainingLayers, remainingBoxes) => {
  const boxesPerLayerMap = {
    'Standard': 8,
    'Small': 12,
    'Large': 6,
    'Default': 8
  };
  
  const boxesPerLayer = boxesPerLayerMap[boxType] || boxesPerLayerMap['Default'];
  return (remainingLayers * boxesPerLayer) + parseInt(remainingBoxes || 0);
};

/**
 * Gets location ID from operator's orders
 * @param {string} operatorId - Operator ID
 * @returns {Promise<string|null>} Location ID or null
 */
export const getOrderLocationId = async (operatorId) => {
  try {
    if (!operatorId) {
      console.log("Missing operatorId for getOrderLocationId");
      return null;
    }

    const response = await api.get(`/api/orders/by-operator/${operatorId}?limit=1`);
    
    if (response.data && Array.isArray(response.data) && response.data.length > 0) {
      const locationId = response.data[0].location_id;
      console.log(`Found location ID ${locationId} from operator's orders`);
      return locationId;
    }
    
    console.log("Could not find location ID from operator orders");
    return null;
  } catch (error) {
    console.error("Error getting location ID:", error);
    return null;
  }
};

/**
 * Finds other orders that contain a specific pallet
 * @param {string} palletId - Pallet ID to search for
 * @param {string} currentOrderId - Current order ID to exclude
 * @param {string} operatorId - Operator ID
 * @returns {Promise<Array>} Array of orders containing the pallet
 */
export const findOtherOrdersWithSamePallet = async (palletId, currentOrderId, operatorId) => {
  if (!palletId || !operatorId) {
    console.log("Missing required parameters for findOtherOrdersWithSamePallet");
    return [];
  }
  
  try {
    const locationId = await getOrderLocationId(operatorId);
    if (!locationId) {
      console.log("Could not determine location ID for orders");
      return [];
    }
    
    const response = await api.get(`/api/orders/location/${locationId}`);
    
    if (!response.data || !Array.isArray(response.data)) {
      console.log("Invalid response data format:", response.data);
      return [];
    }
    
    // Apply upper bound to prevent excessive processing
    const boundedOrders = response.data.slice(0, MAX_ORDERS);
    
    // Filter orders containing this pallet
    const otherOrders = boundedOrders.filter(order => {
      if (String(order.id) === String(currentOrderId)) return false;
      
      let allowedPalletIds = [];
      if (Array.isArray(order.allowed_pallet_ids)) {
        allowedPalletIds = order.allowed_pallet_ids;
      } else if (typeof order.allowed_pallet_ids === 'string') {
        try {
          allowedPalletIds = JSON.parse(order.allowed_pallet_ids);
        } catch (e) {
          console.log(`Error parsing allowed_pallet_ids for order ${order.id}:`, e);
          return false;
        }
      }
      
      const stringPalletId = String(palletId);
      return allowedPalletIds.some(id => String(id) === stringPalletId);
    });
    
    console.log(`Found ${otherOrders.length} other orders with pallet ${palletId}`);
    return otherOrders;
  } catch (error) {
    console.error('Error finding other orders with same pallet:', error);
    return [];
  }
};

/**
 * Prepares order data for shipping
 * @param {string} orderId - Order ID
 * @param {Object} orderData - Order data
 * @returns {Promise<Object|null>} Prepared order data or null
 */
export const prepareOrderForShipping = async (orderId, orderData) => {
  try {
    validateOrderData(orderData);
    
    // Fetch fresh data if needed
    let fullOrderData = orderData;
    if (!orderData.pallets || !Array.isArray(orderData.pallets) || orderData.pallets.length === 0) {
      const response = await api.get(`/api/orders/${orderId}`);
      if (response && response.data) {
        fullOrderData = response.data;
      }
    }
    
    // Filter to strictly assigned pallets
    if (fullOrderData.pallets && Array.isArray(fullOrderData.pallets)) {
      const currentOrderId = String(fullOrderData.id);
      const allowedPalletIds = normalizeAllowedPalletIds(fullOrderData.allowed_pallet_ids);
      
      fullOrderData.pallets = fullOrderData.pallets.filter(pallet => {
        if (!pallet) return false;
        
        const palletId = String(pallet.id);
        const palletOrderId = getPalletOrderId(pallet);
        return palletOrderId === currentOrderId && 
               (allowedPalletIds.length === 0 || allowedPalletIds.includes(palletId));
      });
      
      // Normalize box count fields
      fullOrderData.pallets.forEach(pallet => {
        const boxCount = getPalletBoxCount(pallet);
        pallet.colli_count = boxCount;
        pallet.box_count = boxCount;
        pallet.boxCount = boxCount;
      });
    }
    
    return fullOrderData;
  } catch (error) {
    console.error('Error preparing order for shipping:', error);
    return null;
  }
};

import React from 'react';
import styled from 'styled-components';
import useStorageAssignment from '../hooks/useStorageAssignment';
import StorageAssignmentModal from './StorageAssignmentModal';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const Title = styled.h4`
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 600;
`;

const Button = styled.button`
  background: ${({ theme, $primary }) => 
    $primary ? theme.colors.primary.main : theme.colors.background.default
  };
  color: ${({ theme, $primary }) => 
    $primary ? theme.colors.text.inverse : theme.colors.text.secondary
  };
  border: 1px solid ${({ theme, $primary }) => 
    $primary ? theme.colors.primary.main : theme.colors.border.main
  };
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  font-weight: 500;
  font-size: 0.9rem;
  
  &:hover {
    background: ${({ theme, $primary }) => 
      $primary ? theme.colors.primary[600] : theme.colors.background.hover
    };
    color: ${({ theme, $primary }) => 
      $primary ? theme.colors.text.inverse : theme.colors.text.primary
    };
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const InfoText = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.9rem;
  margin: ${({ theme }) => theme.spacing.sm} 0;
`;

const SuccessMessage = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.success.alpha};
  border: 1px solid ${({ theme }) => theme.colors.success.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.success.dark};
  margin: ${({ theme }) => theme.spacing.md} 0;
`;

/**
 * StorageIntegration component - integrates with FinishMenu for pallet assignment
 * 
 * @param {Object} props
 * @param {Object} props.palletData - Data for the pallet to assign
 * @param {Function} props.onAssignmentComplete - Callback when assignment is complete
 * @param {boolean} props.autoAssign - Whether to auto-assign pallets
 */
const StorageIntegration = ({ palletData, onAssignmentComplete, autoAssign = false }) => {
  const {
    isModalOpen,
    currentPallet,
    assignedPosition,
    openStorageAssignment,
    closeStorageAssignment,
    handleAssignmentComplete,
    autoAssignPallet,
  } = useStorageAssignment();

  // Check if pallet has sufficient data for assignment
  const canAssign = palletData?.id && palletData?.type;

  // Handle manual assignment button click
  const handleManualAssignment = () => {
    openStorageAssignment(palletData);
  };

  // Handle auto-assignment button click
    const handleAutoAssignment = async () => {
      try {
        // Simply assign the pallet to an appropriate zone without specifying position
        const result = await autoAssignPallet(palletData);
        if (result && onAssignmentComplete) {
          onAssignmentComplete(result);
        }
      } catch (error) {
        console.error('Auto-assignment failed:', error);
      }
    };

  // Handle modal assignment completion
  const handleModalAssignment = (assignmentData) => {
    handleAssignmentComplete(assignmentData);
    if (onAssignmentComplete) {
      onAssignmentComplete(assignmentData);
    }
  };

  return (
    <Container>
      <Title>Storage Assignment</Title>
      
      {assignedPosition ? (
        <SuccessMessage>
          Pallet successfully assigned to {assignedPosition.zoneId} at position {assignedPosition.rowIndex + 1},{assignedPosition.colIndex + 1}
        </SuccessMessage>
      ) : (
        <>
          <InfoText>
            Assign this pallet to a storage location or set auto-assignment preferences.
          </InfoText>
          
          <ButtonGroup>
            <Button 
              onClick={handleManualAssignment}
              disabled={!canAssign}
              $primary
            >
              Manual Assignment
            </Button>
            <Button 
              onClick={handleAutoAssignment}
              disabled={!canAssign}
            >
              Auto-Assign
            </Button>
          </ButtonGroup>
        </>
      )}
      
      {/* Storage assignment modal */}
      <StorageAssignmentModal
        isOpen={isModalOpen}
        onClose={closeStorageAssignment}
        palletData={currentPallet}
        onAssign={handleModalAssignment}
      />
    </Container>
  );
};

export default StorageIntegration;

import React from 'react';

export const withAsyncBoundary = (WrappedComponent) => {
  return class AsyncBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    componentDidCatch(error, errorInfo) {
      console.error('AsyncBoundary caught an error:', error, errorInfo);
    }

    onAsyncAction = async (action, successMessage) => {
      try {
        this.setState({ error: null });
        
        // Execute the action
        const result = await action();
        
        // Determine if the action was successful
        // Even if result is undefined/null, we consider it a success
        // since no exception was thrown
        return true;
      } catch (error) {
        console.log('AsyncBoundary caught action error:', error);
        
        // Check if this is a JSON parsing error but the operation actually succeeded
        // This happens when the server sends back a non-JSON response for a successful operation
        if (error.name === 'SyntaxError' && error.message.includes('JSON')) {
          console.log('JSON parsing error but operation likely succeeded:', error.message);
          // Don't set error state for JSON parsing errors, as these often happen
          // when the server successfully processes the request but returns a non-JSON response
          return true;
        }
        
        // Check if this is an "ok" string or similar indicating success
        if (error.responseText && 
            (error.responseText === 'ok' || 
             error.responseText.toLowerCase() === 'ok' || 
             error.responseText.includes('success'))) {
          console.log('Request likely succeeded with non-JSON response:', error.responseText);
          return true;
        }
        
        // Check if error is from a successful response with unexpected format
        if (error.status === 200 || error.statusCode === 200) {
          console.log('Status 200 but error in processing response:', error);
          return true;
        }
        
        // For actual errors, set the error state
        this.setState({ error });
        return false;
      }
    };

    render() {
      if (this.state.error) {
        return (
          <div role="alert" style={{ padding: '20px', color: 'red' }}>
            <h2>Something went wrong</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error.toString()}
            </details>
            <button
              onClick={() => this.setState({ error: null })}
              style={{ marginTop: '10px' }}
            >
              Try again
            </button>
          </div>
        );
      }

      return <WrappedComponent {...this.props} onAsyncAction={this.onAsyncAction} />;
    }
  };
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
`;

export const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body2};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  margin-top: ${({ theme }) => theme.spacing.md};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary.main}20`};
  }
`;

export const RangeInput = styled(Input)`
  width: 45%;
  margin-right: ${({ theme }) => theme.spacing.sm};
`;

export const RangeLabel = styled.label`
  display: block;
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.main};
  margin: ${({ theme }) => `${theme.spacing.xl} 0`};
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.sm};
    cursor: pointer;
    ${({ theme }) => theme.typography.body2};
  }

  input[type="radio"] {
    cursor: pointer;
  }
`;

export const DateInput = styled(Input)`
  max-width: 200px;
`;

import React from 'react';
import { 
  Card, Title, CheckboxContainer, Checkbox, CheckboxLabel, Label 
} from './StyledComponents';

// Client Access Section
export const ClientAccessSection = ({ user, onUpdateClientAccess }) => {
  if (!user) return null;
  
  // Access flags are now directly on the user object
  const desktopAccess = user.desktop_access || false;
  const mobileAccess = user.mobile_access || false;
  
  const handleToggleAccess = (accessType, value) => {
    if (onUpdateClientAccess) {
      onUpdateClientAccess(user.id, accessType, value);
    }
  };
  
  return (
    <Card>
      <Title>Client Access for {user.username}</Title>
      <div>
        <CheckboxContainer>
          <Checkbox 
            type="checkbox" 
            id="desktop_access" 
            checked={desktopAccess}
            onChange={(e) => handleToggleAccess('desktop_access', e.target.checked)}
          />
          <CheckboxLabel htmlFor="desktop_access">
            Desktop Access
          </CheckboxLabel>
        </CheckboxContainer>
        
        <CheckboxContainer>
          <Checkbox 
            type="checkbox" 
            id="mobile_access" 
            checked={mobileAccess}
            onChange={(e) => handleToggleAccess('mobile_access', e.target.checked)}
          />
          <CheckboxLabel htmlFor="mobile_access">
            Mobile Access
          </CheckboxLabel>
        </CheckboxContainer>
      </div>
    </Card>
  );
};

// User Restrictions Section - now inverted to show what the user CAN use
export const UserRestrictionsSection = ({ user, userRestrictions, onToggleRestriction }) => {
  if (!user) return null;
  
  // Define the available features by category in a hierarchical structure
  const availableFeatures = [
    {
      category: "Mobile Client Consoles",
      items: [
        { key: "mobile.sorting_console", label: "Sorting Console" },
        { key: "mobile.kleinpak_console", label: "Kleinpak Console" },
        { key: "mobile.logistics_console", label: "Logistics Console" },
        { key: "mobile.storage_map", label: "Storage Map" }
      ]
    },
    {
      category: "Sorting Pallet Actions",
      items: [
        { key: "mobile.sorting_console.pallet_modal.assign_order", label: "Assign to Order" },
        { key: "mobile.sorting_console.pallet_modal.print_label", label: "Print Label" },
        { key: "mobile.sorting_console.pallet_modal.move", label: "Move Pallet" }
      ]
    }
  ];
  
  // Check if a feature is accessible (NOT restricted)
  const canAccessFeature = (key) => {
    return !(Array.isArray(userRestrictions) && 
      userRestrictions.some(r => r.restriction_key === key));
  };
  
  return (
    <Card>
      <Title>Mobile Features for {user.username}</Title>
      <div style={{ marginBottom: '1rem' }}>
        <p>Check items you want to <strong>allow</strong> this user to access.</p>
      </div>
      
      {availableFeatures.map(category => (
        <div key={category.category} style={{ marginBottom: '1.5rem' }}>
          <Label>{category.category}</Label>
          {category.items.map(item => (
            <CheckboxContainer key={item.key}>
              <Checkbox 
                type="checkbox" 
                id={`feature-${item.key}`} 
                checked={canAccessFeature(item.key)} 
                onChange={() => onToggleRestriction(item.key)}
              />
              <CheckboxLabel htmlFor={`feature-${item.key}`}>
                {item.label}
              </CheckboxLabel>
            </CheckboxContainer>
          ))}
        </div>
      ))}
    </Card>
  );
};

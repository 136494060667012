import styled from 'styled-components';

export const NavigationContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.paper};
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
  position: sticky;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

export const NavItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${props => 
    props.active 
      ? props.theme.colors.background.hover
      : 'transparent'
  };
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${props => 
    props.active 
      ? props.theme.colors.primary.main
      : props.theme.colors.text.secondary
  };
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${props => props.active ? 600 : 400};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  min-width: 80px;
  
  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.primary.main};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.main};
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -${({ theme }) => theme.spacing.sm};
    left: 50%;
    transform: translateX(-50%);
    width: ${props => props.active ? '30px' : '0'};
    height: 2px;
    background: ${({ theme }) => theme.colors.primary.main};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    transition: ${({ theme }) => theme.transitions.default};
  }
`;

export const IconWrapper = styled.div`
  font-size: 1.25rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${props => 
    props.active 
      ? props.theme.colors.primary.main + '10'
      : 'transparent'
  };
  transition: ${({ theme }) => theme.transitions.default};
  
  ${NavItem}:hover & {
    background: ${({ theme }) => theme.colors.primary.main + '10'};
  }
`;

export const Label = styled.span`
  font-weight: ${props => props.active ? 600 : 400};
  transition: ${({ theme }) => theme.transitions.default};
  font-size: 0.75rem;
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 2px;
  background: ${({ theme }) => theme.colors.primary.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

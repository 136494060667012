import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonBase = styled.div`
  background: ${({ theme }) => `linear-gradient(90deg, 
    ${theme.colors.background.darker} 25%, 
    ${theme.colors.background.default} 50%, 
    ${theme.colors.background.darker} 75%)`};
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  border-radius: ${({ theme, radius }) => radius ? theme.borderRadius[radius] : theme.borderRadius.md};
  height: ${({ height }) => height || '16px'};
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '0'};
  opacity: 0.7;
`;

const TomatoSkeletonBase = styled(SkeletonBase)`
  background: ${({ theme, variant }) => {
    if (variant === 'primary') {
      return `linear-gradient(90deg, 
        ${theme.colors.primary[100]} 25%, 
        ${theme.colors.primary[200]} 50%, 
        ${theme.colors.primary[100]} 75%)`;
    }
    if (variant === 'secondary') {
      return `linear-gradient(90deg, 
        ${theme.colors.secondary[100]} 25%, 
        ${theme.colors.secondary[200]} 50%, 
        ${theme.colors.secondary[100]} 75%)`;
    }
    return `linear-gradient(90deg, 
      ${theme.colors.background.darker} 25%, 
      ${theme.colors.background.default} 50%, 
      ${theme.colors.background.darker} 75%)`;
  }};
  background-size: 200% 100%;
`;

const CircleSkeletonBase = styled(SkeletonBase)`
  border-radius: 50%;
  height: ${({ size }) => size || '48px'};
  width: ${({ size }) => size || '48px'};
`;

const TextRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, gap }) => gap ? theme.spacing[gap] : theme.spacing.sm};
  width: ${({ width }) => width || '100%'};
`;

const CardSkeletonContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || 'repeat(4, 1fr)'};
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  align-items: center;
`;

/**
 * Basic skeleton loader with customizable properties
 */
export const Skeleton = ({ 
  width, 
  height, 
  radius, 
  margin, 
  variant,
  ...props 
}) => (
  <TomatoSkeletonBase 
    width={width} 
    height={height} 
    radius={radius} 
    margin={margin} 
    variant={variant}
    {...props} 
  />
);

/**
 * Circle skeleton for avatars, icons, etc.
 */
export const CircleSkeleton = ({ size, ...props }) => (
  <CircleSkeletonBase size={size} {...props} />
);

/**
 * Text rows skeleton for paragraphs, lists, etc.
 */
export const TextRowsSkeleton = ({ 
  rows = 3, 
  width = '100%', 
  rowHeight = '16px',
  lastRowWidth = '70%',
  gap,
  ...props 
}) => (
  <TextRowsContainer width={width} gap={gap} {...props}>
    {Array.from({ length: rows }).map((_, index) => (
      <Skeleton 
        key={index} 
        width={index === rows - 1 && lastRowWidth ? lastRowWidth : '100%'} 
        height={rowHeight} 
      />
    ))}
  </TextRowsContainer>
);

/**
 * Card skeleton for content cards
 */
export const CardSkeleton = ({ 
  width, 
  height,
  headerHeight = '24px',
  contentRows = 3,
  footerHeight = '40px',
  ...props 
}) => (
  <CardSkeletonContainer width={width} height={height} {...props}>
    <Skeleton height={headerHeight} width="60%" variant="primary" />
    <TextRowsSkeleton rows={contentRows} />
    <Skeleton height={footerHeight} variant="secondary" />
  </CardSkeletonContainer>
);

/**
 * Table row skeleton for tables
 */
export const TableRowSkeleton = ({ 
  columns = 'repeat(4, 1fr)', 
  rows = 5,
  cellHeight = '20px',
  ...props 
}) => (
  <>
    {Array.from({ length: rows }).map((_, index) => (
      <TableRowContainer key={index} columns={columns} {...props}>
        {Array.from({ length: columns.split(',').length }).map((_, cellIndex) => (
          <Skeleton 
            key={cellIndex} 
            height={cellHeight} 
            width={cellIndex === 0 ? '70%' : '100%'} 
            variant={index % 2 === 0 ? 'primary' : undefined}
          />
        ))}
      </TableRowContainer>
    ))}
  </>
);

export default Skeleton;

import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { usePermission } from '@lib/contexts';
import NotAuthorized from '@features/shared/components/NotAuthorized';

/**
 * Validates the authentication data from localStorage
 * @returns {boolean} True if authentication data is valid, false otherwise
 */
const validateAuthData = () => {
  const token = localStorage.getItem('token');
  const userData = localStorage.getItem('userData');
  
  // If no token, definitely not authenticated
  if (!token) {
    return false;
  }
  
  // If token exists but no userData, clear token and return false
  if (!userData) {
    localStorage.removeItem('token');
    return false;
  }
  
  // Validate user data
  try {
    const parsedUser = JSON.parse(userData);
    if (!parsedUser || !parsedUser.id || !parsedUser.username) {
      // Invalid user data, clear everything
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      return false;
    }
    return true;
  } catch (e) {
    // JSON parsing error, clear everything
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    return false;
  }
};

/**
 * A protected route component for mobile consoles
 * Following our new permission model: Access is allowed by default unless explicitly restricted
 * 
 * @param {Object} props
 * @param {string} props.consoleName - The name of the console (kleinpak, sorting, logistics)
 * @param {React.ComponentType} props.component - The console component to render if authorized
 * @returns {React.ReactNode}
 */
const ProtectedMobileConsole = ({ consoleName, component: Component }) => {
  const { hasConsoleAccess, hasClientAccess, loading, hasPermission } = usePermission();
  const location = useLocation();
  const navigate = useNavigate();
  
  // Check for valid authentication
  useEffect(() => {
    if (!validateAuthData()) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  // Don't render anything while permissions are loading
  if (loading) {
    return <div>Loading permissions...</div>;
  }
  
  // Double-check authentication before proceeding
  if (!validateAuthData()) {
    return <Navigate to="/login" replace />;
  }

  // Check if user has access to mobile client
  if (!hasClientAccess('mobile')) {
    return (
      <NotAuthorized 
        message="You do not have access to the mobile client. Access is restricted by an administrator."
      />
    );
  }

  // Check if user has access to this specific console
  if (!hasConsoleAccess(consoleName)) {
    // If no access to this console, check if they have access to any other console and redirect
    const consoles = ['sorting', 'kleinpak', 'logistics'];
    
    // Find a console they have access to
    const availableConsole = consoles.find(c => hasConsoleAccess(c));
    
    if (availableConsole) {
      // Redirect to a console they have access to
      return <Navigate to={`/mobile/operator/${availableConsole}`} replace />;
    }
    
    // If all consoles are restricted, show not authorized
    return (
      <NotAuthorized 
        message={`You do not have access to the ${consoleName} console. Access is restricted by an administrator.`}
      />
    );
  }

  // User has permission to view this console (not restricted)
  return <Component />;
};

export default ProtectedMobileConsole;

import { EventEmitter } from 'events';
import api from '@lib/api/axios';
import { normalizePosition, createPosition, addLegacyProperties } from '@lib/utils/positionUtils';
import apiErrorHandler, { executeWithCircuitBreaker, ERROR_CATEGORIES } from '@lib/utils/apiErrorHandler';

// Export event types for subscribers
export const ZONE_UPDATE_EVENTS = {
  PALLET_ADDED: 'PALLET_ADDED',
  PALLET_REMOVED: 'PALLET_REMOVED',
  PALLET_MOVED: 'PALLET_MOVED',
  ZONE_CAPACITY_WARNING: 'ZONE_CAPACITY_WARNING',
  ZONE_ASSIGNMENT: 'ZONE_ASSIGNMENT',
  ZONES_UPDATED: 'ZONES_UPDATED',
  SERVICE_ERROR: 'SERVICE_ERROR'     // New event type for error notifications
};

// Cache settings for zone data
const ZONE_CACHE_SETTINGS = {
  enabled: true,
  defaultTTL: 60 * 1000, // 1 minute cache for zone data
  bypassQueryParams: ['timestamp', 'refresh']
};

// Retry settings for zone operations
const ZONE_RETRY_SETTINGS = {
  maxRetries: 2,
  retryStatusCodes: [408, 429, 500, 502, 503, 504],
  // Include POST methods in retries for zone assignments since they're important operations
  retryMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE', 'PATCH', 'POST'],
  retryDelay: attempt => Math.min(200 * Math.pow(2, attempt), 2000) // Exponential backoff with 2s max
};

class ZoneUpdateService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.locationId = null;
    this.zoneData = {};
  }

  initialize(locationId) {
    this.locationId = locationId;
    console.log('ZoneUpdateService initialized with locationId:', locationId);
    
    // Fetch initial zone data when initialized
    if (locationId) {
      try {
        this.fetchZoneData(locationId);
      } catch (error) {
        console.error('Error initializing zone data:', error);
        // Fallback to simulated data if fetch fails
        this.zoneData = this.getSimulatedZoneData();
        // Still emit an update event, but with simulated data
        this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONES_UPDATED, this.zoneData, true);
      }
    }
  }

  cleanup() {
    this.eventEmitter.removeAllListeners();
    this.locationId = null;
    this.zoneData = {};
  }

  subscribe(event, callback) {
    this.eventEmitter.on(event, callback);
    return () => this.eventEmitter.off(event, callback);
  }

  /**
   * Fetch all zone data for a location, including pallet counts
   * Enhanced with circuit breaker pattern and caching
   */
  async fetchZoneData(locationId = this.locationId) {
    if (!locationId) {
      throw new Error('LocationId is required to fetch zone data');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(locationId);
    const url = `/api/storage/zones?locationId=${locationIdNum}`;

    try {
      // Use the circuit breaker pattern for fetching zone data
      const response = await executeWithCircuitBreaker(
        () => api.get(url),
        {
          url,
          method: 'GET',
          cacheSettings: ZONE_CACHE_SETTINGS,
          retrySettings: ZONE_RETRY_SETTINGS,
          onError: (error) => {
            // Emit error event for subscribers to handle
            this.eventEmitter.emit(ZONE_UPDATE_EVENTS.SERVICE_ERROR, {
              operation: 'fetchZoneData',
              error,
              locationId: locationIdNum
            });
          }
        }
      );

      this.zoneData = response.data || {};
      
      // Emit event to notify subscribers that zone data has been updated
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONES_UPDATED, this.zoneData);
      return this.zoneData;
    } catch (error) {
      console.error('Error fetching zone data:', error);
      
      // Detailed logging for monitoring and debugging
      console.warn('Zone data fetch failed, using simulated data:', {
        locationId: locationIdNum,
        error: error.message,
        category: error.category,
        userMessage: error.userMessage
      });
      
      // If API call fails, fallback to simulated data for graceful degradation
      this.zoneData = this.getSimulatedZoneData();
      
      // Still emit an update event, but with simulated data
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONES_UPDATED, this.zoneData, true);
      
      return this.zoneData;
    }
  }

  /**
   * Get pallet count for a specific zone
   */
  async getZonePalletCount(zoneId, locationId = this.locationId) {
    if (!locationId) {
      throw new Error('LocationId is required to get zone pallet count');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(locationId);

    try {
      const response = await api.get(`/api/storage/zones/${zoneId}/pallets?locationId=${locationIdNum}`);
      return response.data.count || 0;
    } catch (error) {
      console.error(`Error fetching pallet count for zone ${zoneId}:`, error);
      // Return simulated data if API call fails
      return this.zoneData[zoneId]?.palletCount || 0;
    }
  }

  /**
   * Get all pallets in a zone
   */
  async getZonePallets(zoneId, locationId = this.locationId) {
    if (!locationId) {
      throw new Error('LocationId is required to get zone pallets');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(locationId);

    try {
      const response = await api.get(`/api/storage/zones/${zoneId}/pallets?locationId=${locationIdNum}&details=true`);
      return response.data.pallets || [];
    } catch (error) {
      console.error(`Error fetching pallets for zone ${zoneId}:`, error);
      return [];
    }
  }
  
  /**
   * Get detailed occupancy data for a specific zone
   * @param {string} zoneId - ID of the zone
   * @param {number} locationId - ID of the location
   * @returns {Promise<Object>} Position-based occupancy data
   */
  async getZoneOccupancy(zoneId, locationId = this.locationId) {
    if (!locationId) {
      throw new Error('LocationId is required to get zone occupancy');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(locationId);

    try {
      // Use the same service method that MapView uses to ensure consistency
      const storageAssignmentService = await import('./storageAssignmentService').then(m => m.default);
      return await storageAssignmentService.getZoneOccupancy(zoneId, locationIdNum);
    } catch (error) {
      console.error(`Error fetching occupancy for zone ${zoneId}:`, error);
      // Return empty object if API call fails
      return {};
    }
  }

  /**
   * Ensure an ID is converted to a number
   * @param {any} id - The ID to convert
   * @returns {number} The numeric ID
   */
  ensureNumericId(id) {
    // If it's an object with an id property, extract the id
    if (id && typeof id === 'object' && id.id) {
      id = id.id;
    }
    
    // Convert to number
    const numericId = parseInt(id, 10);
    if (isNaN(numericId)) {
      console.error(`Invalid ID value: ${id}. Using default 1.`);
      return 1; // Default fallback to prevent NaN errors
    }
    return numericId;
  }

  /**
   * NEW: Get detailed information about rows in a zone, including pallets stored in each row
   * @param {string} zoneId - ID of the zone
   * @param {number} locationId - ID of the location
   * @returns {Promise<Array>} Array of rows with detailed pallet information
   */
  async getRowsWithPalletInfo(zoneId, locationId = this.locationId) {
    try {
      // Get all pallets in the zone
      const pallets = await this.getZonePallets(zoneId, locationId);
      
      // Group pallets by row
      const rowMap = {};
      
      pallets.forEach(pallet => {
        // Extract row information
        let rowIndex = -1;
        
        // Handle different position structures
        if (pallet.position && pallet.position.row !== undefined) {
          rowIndex = pallet.position.row;
        } else if (pallet.rowIndex !== undefined) {
          rowIndex = pallet.rowIndex;
        }
        
        // Skip pallets that don't have row information
        if (rowIndex === -1) return;
        
        // Create row key
        const rowId = `${zoneId}-row-${rowIndex}`;
        
        // Initialize row if it doesn't exist
        if (!rowMap[rowId]) {
          rowMap[rowId] = {
            rowId,
            zoneId,
            rowIndex,
            pallets: [],
            // Default values will be updated based on pallets
            tomatoType: '',
            tomatoOption: '',
            sortingGrade: '',
            palletCount: 0,
            storageDate: null
          };
        }
        
        // Add pallet to the row
        rowMap[rowId].pallets.push(pallet);
        
        // Update row information based on pallet data
        if (pallet.tomatoType && !rowMap[rowId].tomatoType) {
          rowMap[rowId].tomatoType = pallet.tomatoType;
        }
        
        if (pallet.tomatoOption && !rowMap[rowId].tomatoOption) {
          rowMap[rowId].tomatoOption = pallet.tomatoOption;
        }
        
        if (pallet.sortingGrade && !rowMap[rowId].sortingGrade) {
          rowMap[rowId].sortingGrade = pallet.sortingGrade;
        }
        
        // Track earliest storage date
        const palletDate = pallet.createdAt || pallet.updatedAt || pallet.storedAt;
        if (palletDate) {
          const date = new Date(palletDate);
          if (!rowMap[rowId].storageDate || date < rowMap[rowId].storageDate) {
            rowMap[rowId].storageDate = date;
          }
        }
      });
      
      // Process rows and calculate summary information
      const rows = Object.values(rowMap).map(row => {
        // Count pallets in this row
        row.palletCount = row.pallets.length;
        
        // Format storage date
        if (row.storageDate) {
          // Create human-readable date string (e.g., "Today", "Yesterday", "Monday")
          const today = new Date();
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          
          if (row.storageDate.toDateString() === today.toDateString()) {
            row.storageDateText = 'Today';
          } else if (row.storageDate.toDateString() === yesterday.toDateString()) {
            row.storageDateText = 'Yesterday';
          } else {
            // Get day of week
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            row.storageDateText = days[row.storageDate.getDay()];
          }
        } else {
          row.storageDateText = 'Unknown';
        }
        
        return row;
      });
      
      return rows;
    } catch (error) {
      console.error(`Error fetching row information for zone ${zoneId}:`, error);
      return [];
    }
  }
  
  /**
   * NEW: Get all rows with pallet information for all zones in a location
   * @param {number} locationId - ID of the location
   * @returns {Promise<Object>} Map of zoneId -> rows with pallet information
   */
  async getAllZoneRowsWithPalletInfo(locationId = this.locationId) {
    if (!locationId) {
      throw new Error('LocationId is required to get all zone rows with pallet info');
    }
    
    try {
      // Get all zone data
      const zoneData = await this.fetchZoneData(locationId);
      
      // Process each zone to get row information
      const zonePromises = Object.keys(zoneData).map(async zoneId => {
        const rows = await this.getRowsWithPalletInfo(zoneId, locationId);
        return { zoneId, rows };
      });
      
      // Wait for all zones to process
      const results = await Promise.all(zonePromises);
      
      // Convert to zone -> rows map
      const zoneRowsMap = {};
      results.forEach(({ zoneId, rows }) => {
        zoneRowsMap[zoneId] = rows;
      });
      
      return zoneRowsMap;
    } catch (error) {
      console.error('Error fetching all zone rows with pallet info:', error);
      return {};
    }
  }

  /**
   * Assign a pallet to a storage zone with row information
   * Enhanced with circuit breaker pattern and improved error handling
   * @param {number} palletId - ID of the pallet to assign
   * @param {string} zoneId - ID of the zone to assign to
   * @param {number} rowIndex - Optional row index within the zone
   * @returns {Promise<Object>} Assignment result
   */
  async assignPalletToZone(palletId, zoneId, rowIndex = 0) {
    if (!this.locationId) {
      throw new Error('ZoneUpdateService not initialized with locationId');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(this.locationId);
    
    // Create enhanced storage zone format
    const storageZone = `${zoneId}-row-${rowIndex}`;
    
    // Create request payload
    const payload = {
      palletId,
      storageZone,
      locationId: locationIdNum,
      // Add timestamp to prevent cached requests
      timestamp: Date.now(),
      // Add unique request ID to help track and prevent duplicates
      requestId: `${Date.now()}-${Math.random().toString(36).substring(2, 8)}`
    };
    
    console.log('ZoneUpdateService: Assigning pallet to zone with row', { 
      palletId, 
      storageZone,
      payload 
    });
    
    const url = '/api/storage/assign-pallet';
    
    try {
      // Use circuit breaker pattern for the critical assign operation
      const response = await executeWithCircuitBreaker(
        () => api.post(url, payload),
        {
          url,
          method: 'POST',
          payload,
          // Assignment operations are critical, so we use special retry settings
          retrySettings: {
            ...ZONE_RETRY_SETTINGS,
            maxRetries: 3, // Extra retries for assignments (important operations)
          },
          onError: (error, attempt) => {
            // Emit error event with structured data
            this.eventEmitter.emit(ZONE_UPDATE_EVENTS.SERVICE_ERROR, {
              operation: 'assignPalletToZone',
              attempt,
              error,
              palletId,
              zoneId
            });
            
            // Detailed error log for monitoring
            console.warn(`Pallet assignment attempt ${attempt + 1} failed:`, {
              palletId, 
              zoneId,
              error: error.message,
              category: error.category
            });
          }
        }
      );

      // Log response for debugging
      console.log('Zone assignment response:', response.data);
      
      // Update local zone data
      await this.fetchZoneData();

      // Emit events to update the UI with the enhanced storage zone format
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_MOVED, {
        palletId,
        toZoneId: zoneId,
        fromZoneId: response.data.previousZoneId,
        storageZone,
        rowIndex
      });
      
      // Also emit a specific zone assignment event to ensure UI components update
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, {
        palletId,
        zoneId,
        storageZone,
        rowIndex,
        success: true
      });

      return response.data;
    } catch (error) {
      console.error('Error assigning pallet to zone:', error);
      
      // Enhance error with structured data
      const enhancedError = {
        ...error,
        palletId,
        zoneId,
        operation: 'assignPalletToZone'
      };
      
      // Emit failure event so UI can update appropriately
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, {
        palletId,
        zoneId,
        success: false,
        error: enhancedError
      });
      
      throw enhancedError;
    }
  }

  /**
   * Remove a pallet from a zone
   * Enhanced with circuit breaker pattern and improved error handling
   */
  async removePalletFromZone(palletId, zoneId) {
    if (!this.locationId) {
      throw new Error('ZoneUpdateService not initialized with locationId');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(this.locationId);
    const url = `/api/storage/zones/${zoneId}/pallets/${palletId}?locationId=${locationIdNum}&timestamp=${Date.now()}`;

    try {
      // Use circuit breaker for removal operation
      const response = await executeWithCircuitBreaker(
        () => api.delete(url),
        {
          url,
          method: 'DELETE',
          retrySettings: ZONE_RETRY_SETTINGS,
          onError: (error, attempt) => {
            // Emit error event
            this.eventEmitter.emit(ZONE_UPDATE_EVENTS.SERVICE_ERROR, {
              operation: 'removePalletFromZone',
              attempt,
              error,
              palletId,
              zoneId
            });
          }
        }
      );
      
      // Update local zone data
      await this.fetchZoneData();
      
      // Emit events to update the UI
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_REMOVED, {
        palletId,
        zoneId,
        success: true
      });

      return response.data;
    } catch (error) {
      console.error('Error removing pallet from zone:', error);
      
      // Emit failure event
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_REMOVED, {
        palletId,
        zoneId,
        success: false,
        error: {
          ...error,
          operation: 'removePalletFromZone'
        }
      });
      
      throw error;
    }
  }

  /**
   * Move a pallet from one zone to another
   * Enhanced with circuit breaker pattern and improved error handling
   */
  async movePallet(palletId, fromZoneId, toZoneId) {
    if (!this.locationId) {
      throw new Error('ZoneUpdateService not initialized with locationId');
    }

    // Ensure locationId is a number
    const locationIdNum = this.ensureNumericId(this.locationId);
    
    // Create request payload with timestamp to prevent caching
    const payload = {
      palletId,
      fromZoneId,
      toZoneId,
      locationId: locationIdNum,
      timestamp: Date.now(),
      requestId: `${Date.now()}-${Math.random().toString(36).substring(2, 8)}`
    };
    
    const url = '/api/storage/move';

    try {
      // Use circuit breaker for move operation
      const response = await executeWithCircuitBreaker(
        () => api.put(url, payload),
        {
          url,
          method: 'PUT',
          payload,
          retrySettings: ZONE_RETRY_SETTINGS,
          onError: (error, attempt) => {
            // Emit error event
            this.eventEmitter.emit(ZONE_UPDATE_EVENTS.SERVICE_ERROR, {
              operation: 'movePallet',
              attempt,
              error,
              palletId,
              fromZoneId,
              toZoneId
            });
          }
        }
      );
      
      // Update local zone data
      await this.fetchZoneData();
      
      // Emit events to update the UI
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_MOVED, {
        palletId,
        fromZoneId,
        toZoneId,
        success: true
      });

      return response.data;
    } catch (error) {
      console.error('Error moving pallet between zones:', error);
      
      // Emit failure event
      this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_MOVED, {
        palletId,
        fromZoneId,
        toZoneId,
        success: false,
        error: {
          ...error,
          operation: 'movePallet'
        }
      });
      
      throw error;
    }
  }

  // Fallback method to generate simulated zone data for testing
  getSimulatedZoneData() {
    const baseZones = {
      'zone-1': { palletCount: 30, capacity: 50, rows: 5 },
      'zone-2': { palletCount: 35, capacity: 40, rows: 4 },
      'zone-3': { palletCount: 20, capacity: 45, rows: 5 },
      'zone-4': { palletCount: 15, capacity: 35, rows: 4 },
      'zone-5': { palletCount: 80, capacity: 100, rows: 10 },
      'zone-6': { palletCount: 25, capacity: 30, rows: 3 },
      'zone-7': { palletCount: 30, capacity: 45, rows: 5 },
      'zone-8': { palletCount: 25, capacity: 40, rows: 4 },
      'zone-9': { palletCount: 20, capacity: 35, rows: 4 },
      'zone-10': { palletCount: 30, capacity: 35, rows: 4 },
      'zone-11': { palletCount: 35, capacity: 40, rows: 4 },
      'zone-12': { palletCount: 25, capacity: 40, rows: 4 },
      'zone-13': { palletCount: 20, capacity: 35, rows: 4 }
    };

    // Add row-level data to each zone
    const zoneData = {};
    Object.entries(baseZones).forEach(([zoneId, data]) => {
      zoneData[zoneId] = {
        ...data,
        rows: Array.from({ length: data.rows }, (_, i) => ({
          id: `${zoneId}-row-${i}`,
          index: i,
          capacity: Math.floor(data.capacity / data.rows),
          palletCount: Math.floor(data.palletCount / data.rows)
        }))
      };
    });

    return zoneData;
  }

  /**
   * Get row-level zone data
   * @param {string} zoneId - ID of the zone
   * @returns {Array} Array of row data for the zone
   */
  getZoneRows(zoneId) {
    const zoneData = this.zoneData[zoneId];
    if (!zoneData?.rows) {
      // If no row data exists, create default row structure
      const defaultRows = 5;
      return Array.from({ length: defaultRows }, (_, i) => ({
        id: `${zoneId}-row-${i}`,
        index: i,
        capacity: Math.floor((zoneData?.capacity || 50) / defaultRows),
        palletCount: Math.floor((zoneData?.palletCount || 0) / defaultRows)
      }));
    }
    return zoneData.rows;
  }

  /**
   * Get all zones with row-level data
   * @returns {Object} Map of zone IDs to zone data including rows
   */
  getAllZonesWithRows() {
    const zones = {};
    Object.keys(this.zoneData).forEach(zoneId => {
      zones[zoneId] = {
        ...this.zoneData[zoneId],
        rows: this.getZoneRows(zoneId)
      };
    });
    return zones;
  }

  // For compatibility with previous implementation
  emitPalletAdded(zoneId, palletData) {
    this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_ADDED, { zoneId, palletData });
  }

  emitPalletRemoved(zoneId, palletId) {
    this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_REMOVED, { zoneId, palletId });
  }

  emitPalletMoved(fromZoneId, toZoneId, palletId) {
    this.eventEmitter.emit(ZONE_UPDATE_EVENTS.PALLET_MOVED, { fromZoneId, toZoneId, palletId });
  }

  emitCapacityWarning(zoneId, message) {
    this.eventEmitter.emit(ZONE_UPDATE_EVENTS.ZONE_CAPACITY_WARNING, { zoneId, message });
  }
}

// Export a singleton instance
export const zoneUpdateService = new ZoneUpdateService();

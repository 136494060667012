import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { X } from 'lucide-react';
import { Modal } from '@lib/components/Modal';
import { Badge } from '../MainDashboard/styles';
import { getBadgeStatus, getDisplayName } from '@features/shared/constants/processStages';

const ModalContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  max-width: 900px;
  width: 100%;
`;

const InfoSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const QRSection = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.common.white};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const Label = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-transform: uppercase;
`;

const Value = styled.div`
  ${({ theme }) => theme.typography.body1};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.md};
  right: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
    background: ${({ theme }) => theme.colors.background.hover};
  }
`;

const QRPlaceholder = styled.div`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
`;

const PalletInfoModal = ({ pallet, onClose, isOpen }) => {

  if (!pallet) return null;

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalContent>
        <InfoSection>
          <InfoGroup>
            <Label>Type/Option</Label>
            <Value>
              {pallet.tomatoType}
              {pallet.tomatoOption && ` - ${pallet.tomatoOption}`}
            </Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Box/Pallet</Label>
            <Value>
              {pallet.boxType}
              {pallet.palletType && ` - ${pallet.palletType}`}
            </Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Process Stage</Label>
            <Value>
              <Badge status={getBadgeStatus(pallet.processStage, 'process')}>
                {getDisplayName(pallet.processStage, 'process')}
              </Badge>
            </Value>
          </InfoGroup>

          {pallet.sortingGrade && (
            <InfoGroup>
              <Label>Sorting Grade</Label>
              <Value>
                <Badge status={getBadgeStatus(pallet.sortingGrade, 'sorting')}>
                  {getDisplayName(pallet.sortingGrade, 'sorting')}
                </Badge>
              </Value>
            </InfoGroup>
          )}

          <InfoGroup>
            <Label>Boxes</Label>
            <Value>
              {pallet.colli_count ? `${pallet.colli_count} boxes` : '-'}
            </Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Weight</Label>
            <Value>
              {pallet.weight 
                ? `${parseFloat(pallet.weight).toFixed(2)}kg`
                : '-'}
            </Value>
          </InfoGroup>

          <InfoGroup>
            <Label>Status</Label>
            <Value>
              <Badge status={pallet.status}>
                {pallet.status?.charAt(0).toUpperCase() + pallet.status?.slice(1)}
              </Badge>
            </Value>
          </InfoGroup>

          {pallet.orderCustomer && (
            <InfoGroup>
              <Label>Order</Label>
              <Value>
                <Badge status={pallet.status === 'shipped' ? 'shipped' : 'assigned'}>
                  {pallet.orderCustomer}
                </Badge>
                {pallet.order_is_completed && (
                  <Badge status="completed" style={{ marginLeft: '8px' }}>
                    Completed
                  </Badge>
                )}
              </Value>
            </InfoGroup>
          )}

          <InfoGroup>
            <Label>Added</Label>
            <Value>
              {format(new Date(pallet.addedDate), 'MMM d, yyyy')}
              <div style={{ 
                fontSize: '0.875rem', 
                color: ({ theme }) => theme.colors.text.secondary 
              }}>
                Added by: {pallet.userName || 'Unknown'}
              </div>
            </Value>
          </InfoGroup>

          {pallet.lastEditor && pallet.lastEditor !== pallet.userName && (
            <InfoGroup>
              <Label>Last Edited</Label>
              <Value>Edited by: {pallet.lastEditor}</Value>
            </InfoGroup>
          )}

          {pallet.status === 'shipped' && (
            <InfoGroup>
              <Label>Shipping Details</Label>
              <Value>
                Shipped: {format(new Date(pallet.shipped_date), 'MMM d, yyyy')}
                <div style={{ 
                  fontSize: '0.875rem', 
                  color: ({ theme }) => theme.colors.text.secondary 
                }}>
                  By: {pallet.shipped_by}
                </div>
              </Value>
            </InfoGroup>
          )}
        </InfoSection>

        <QRSection>
          <QRPlaceholder>
            QR Code
            <br />
            Coming Soon
          </QRPlaceholder>
        </QRSection>

        <CloseButton onClick={onClose}>
          <X size={20} />
        </CloseButton>
      </ModalContent>
    </Modal>
  );
};

export default PalletInfoModal;

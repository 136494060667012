import { useCallback } from 'react';
import { api } from '@lib/api';
import {
  calculateTotalRemainingBoxes,
  findOtherOrdersWithSamePallet,
  prepareOrderForShipping,
  getOrderLocationId
} from '../utils/orderUtils';
import { validateOrderData } from '../utils/validationUtils';

/**
 * Hook for order processing operations
 * @param {Function} showToast - Toast notification function
 * @param {Function} showProcessing - Show processing overlay function
 * @param {Function} hideProcessing - Hide processing overlay function
 * @returns {Object} Order processing functions
 */
const useOrderProcessing = (showToast, showProcessing, hideProcessing) => {
  /**
   * Prepares an order to be marked as ready for shipping
   */
  const prepareOrderReadyForShipping = useCallback(async (orderId, orderData) => {
    if (!orderId) {
      showToast({ 
        message: "Error: Missing order ID", 
        variant: 'error' 
      });
      return null;
    }

    try {
      validateOrderData(orderData);
      
      const preparedOrder = await prepareOrderForShipping(orderId, orderData);
      if (!preparedOrder) {
        throw new Error("Failed to prepare order data");
      }

      return preparedOrder;
    } catch (error) {
      showToast({ 
        message: `Error preparing order: ${error.message}`, 
        variant: 'error' 
      });
      return null;
    }
  }, [showToast]);

  /**
   * Handles the submission of marking an order ready for shipping
   */
  const handleMarkReadySubmission = useCallback(async (orderId, formData) => {
    if (!orderId || !formData) {
      showToast({ 
        message: "Error: Missing required data", 
        variant: 'error' 
      });
      return false;
    }

    showProcessing({ message: 'Marking order ready for shipping...', showOverlay: true });

    try {
      // Validate pallet selection
      if (!formData.palletId) {
        throw new Error("You must select a pallet from your sticker");
      }

      // Calculate total remaining boxes
      const remainingLayers = Number(formData.remainingLayers) || 0;
      const remainingIndividualBoxes = Number(formData.remainingBoxes) || 0;
      const totalRemainingBoxes = calculateTotalRemainingBoxes(
        formData.boxType || 'Default',
        remainingLayers,
        remainingIndividualBoxes
      );

      const requestData = {
        remainingLayers,
        remainingBoxes: totalRemainingBoxes,
        individualRemainingBoxes: remainingIndividualBoxes,
        palletId: formData.palletId,
        transferToOrderId: formData.targetOrderId,
        returnToStorage: formData.transferOption === 'storage'
      };

      const response = await api.post(`/api/orders/ready-for-shipping/${orderId}`, requestData);
      
      showToast({ 
        message: `Order ${orderId} marked as ready for shipping`, 
        variant: 'success' 
      });

      return true;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showToast({ 
        message: `Error: ${errorMessage}`, 
        variant: 'error' 
      });
      return false;
    } finally {
      hideProcessing();
    }
  }, [showToast, showProcessing, hideProcessing]);

  /**
   * Finds orders that share pallets with the current order
   */
  const findRelatedOrders = useCallback(async (palletId, currentOrderId, operatorId) => {
    if (!palletId || !operatorId) {
      console.log("Missing required parameters for finding related orders");
      return [];
    }

    try {
      return await findOtherOrdersWithSamePallet(palletId, currentOrderId, operatorId);
    } catch (error) {
      console.error("Error finding related orders:", error);
      return [];
    }
  }, []);

  /**
   * Gets the location ID for an operator
   */
  const getOperatorLocation = useCallback(async (operatorId) => {
    if (!operatorId) {
      console.log("Missing operator ID");
      return null;
    }

    try {
      return await getOrderLocationId(operatorId);
    } catch (error) {
      console.error("Error getting operator location:", error);
      return null;
    }
  }, []);

  return {
    prepareOrderReadyForShipping,
    handleMarkReadySubmission,
    findRelatedOrders,
    getOperatorLocation
  };
};

export default useOrderProcessing;

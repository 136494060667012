/**
 * ConnectionStateManager.js
 * 
 * Manages SSE connection state and provides hooks for components to respond
 * to connection changes. This centralized manager helps ensure UI components
 * can react appropriately to connection issues.
 */

class ConnectionStateManager {
  constructor() {
    this.clients = new Map(); // Map client ID to client instance
    this.stateListeners = new Set(); // Set of state change listeners
    this.connectionState = 'UNKNOWN'; // Current aggregated connection state
    this.lastStateChangeTime = Date.now();
    this.stateTransitions = []; // Track state change history
    this.maxTransitionHistory = 20;
    this.reconnectInProgress = false;
    
    // Monitor for network connectivity changes
    this.setupNetworkMonitoring();
    
    console.log('ConnectionStateManager initialized', {
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Register an SSE client with the manager
   * @param {string} clientId - The client ID
   * @param {object} client - The SSE client instance
   */
  registerClient(clientId, client) {
    if (!clientId || !client) return;
    
    this.clients.set(clientId, client);
    
    // Start monitoring this client's state
    client.setStateChangeHandler(this.handleClientStateChange.bind(this, clientId));
    
    console.log('SSE client registered with connection manager:', {
      clientId,
      totalClients: this.clients.size,
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Unregister an SSE client from the manager
   * @param {string} clientId - The client ID
   */
  unregisterClient(clientId) {
    if (!clientId || !this.clients.has(clientId)) return;
    
    this.clients.delete(clientId);
    
    console.log('SSE client unregistered from connection manager:', {
      clientId,
      totalClients: this.clients.size,
      timestamp: new Date().toISOString()
    });
    
    // Recalculate aggregated state after client removal
    this.recalculateState();
  }
  
  /**
   * Set up monitoring for network connectivity changes
   */
  setupNetworkMonitoring() {
    // Listen for online/offline events
    window.addEventListener('online', this.handleNetworkChange.bind(this, true));
    window.addEventListener('offline', this.handleNetworkChange.bind(this, false));
  }
  
  /**
   * Handle network connectivity changes
   * @param {boolean} isOnline - Whether the network is online
   */
  handleNetworkChange(isOnline) {
    console.log('Network status changed:', {
      isOnline,
      timestamp: new Date().toISOString()
    });
    
    if (!isOnline) {
      // Network is offline, update state to offline
      this.updateAggregatedState('OFFLINE');
    } else {
      // Network is back online, attempt to reconnect all clients
      this.attemptReconnectAll();
    }
  }
  
  /**
   * Handle client state change
   * @param {string} clientId - The client ID
   * @param {object} stateData - The state change data
   */
  handleClientStateChange(clientId, stateData) {
    console.log('SSE client state changed:', {
      clientId,
      state: stateData.connectionState,
      timestamp: new Date().toISOString()
    });
    
    // Recalculate aggregated state after client state change
    this.recalculateState();
  }
  
  /**
   * Recalculate the aggregated connection state based on all clients
   */
  recalculateState() {
    if (this.clients.size === 0) {
      this.updateAggregatedState('UNKNOWN');
      return;
    }
    
    // Get all client states
    const clientStates = Array.from(this.clients.values())
      .map(client => client.connectionState);
    
    // Calculate aggregated state using priority order
    if (clientStates.includes('ERROR') || clientStates.includes('AUTH_ERROR')) {
      this.updateAggregatedState('ERROR');
    } else if (clientStates.includes('OFFLINE')) {
      this.updateAggregatedState('OFFLINE');
    } else if (clientStates.includes('STALE')) {
      this.updateAggregatedState('STALE');
    } else if (clientStates.includes('CONNECTING')) {
      this.updateAggregatedState('CONNECTING');
    } else if (clientStates.every(state => state === 'CONNECTED')) {
      this.updateAggregatedState('CONNECTED');
    } else if (clientStates.includes('DISCONNECTED')) {
      this.updateAggregatedState('DISCONNECTED');
    } else {
      this.updateAggregatedState('UNKNOWN');
    }
  }
  
  /**
   * Update the aggregated connection state
   * @param {string} state - The new state
   */
  updateAggregatedState(state) {
    if (this.connectionState === state) return;
    
    const previousState = this.connectionState;
    this.connectionState = state;
    this.lastStateChangeTime = Date.now();
    
    // Track state transition
    this.stateTransitions.push({
      from: previousState,
      to: state,
      timestamp: new Date().toISOString()
    });
    
    // Limit transition history size
    if (this.stateTransitions.length > this.maxTransitionHistory) {
      this.stateTransitions = this.stateTransitions.slice(-this.maxTransitionHistory);
    }
    
    console.log('Aggregated connection state changed:', {
      from: previousState,
      to: state,
      timestamp: new Date().toISOString()
    });
    
    // Notify listeners
    this.notifyStateChangeListeners(previousState, state);
  }
  
  /**
   * Notify state change listeners
   * @param {string} previousState - The previous state
   * @param {string} currentState - The current state
   */
  notifyStateChangeListeners(previousState, currentState) {
    this.stateListeners.forEach(listener => {
      try {
        listener({
          previousState,
          currentState,
          timestamp: new Date().toISOString()
        });
      } catch (error) {
        console.error('Error notifying state change listener:', {
          error: error.message,
          stack: error.stack,
          timestamp: new Date().toISOString()
        });
      }
    });
  }
  
  /**
   * Add a state change listener
   * @param {function} listener - The state change listener
   * @returns {function} A function to remove the listener
   */
  addStateChangeListener(listener) {
    if (!listener || typeof listener !== 'function') return () => {};
    
    this.stateListeners.add(listener);
    
    console.log('State change listener added:', {
      totalListeners: this.stateListeners.size,
      timestamp: new Date().toISOString()
    });
    
    // Return a function to remove the listener
    return () => this.removeStateChangeListener(listener);
  }
  
  /**
   * Remove a state change listener
   * @param {function} listener - The state change listener to remove
   */
  removeStateChangeListener(listener) {
    if (!listener) return;
    
    this.stateListeners.delete(listener);
    
    console.log('State change listener removed:', {
      totalListeners: this.stateListeners.size,
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Attempt to reconnect all clients
   */
  attemptReconnectAll() {
    // Prevent multiple reconnect attempts
    if (this.reconnectInProgress) return;
    
    this.reconnectInProgress = true;
    
    console.log('Attempting to reconnect all SSE clients:', {
      clientCount: this.clients.size,
      timestamp: new Date().toISOString()
    });
    
    // Reconnect each client with a slight delay between attempts
    let delay = 0;
    this.clients.forEach((client, clientId) => {
      setTimeout(() => {
        try {
          if (client.isConnected()) {
            console.log('Skipping reconnect for already connected client:', {
              clientId,
              timestamp: new Date().toISOString()
            });
            return;
          }
          
          console.log('Reconnecting client:', {
            clientId,
            timestamp: new Date().toISOString()
          });
          
          client.connect();
        } catch (error) {
          console.error('Error reconnecting client:', {
            clientId,
            error: error.message,
            stack: error.stack,
            timestamp: new Date().toISOString()
          });
        }
      }, delay);
      
      // Add delay between client reconnects
      delay += 1000;
    });
    
    // Reset reconnect flag after all attempts
    setTimeout(() => {
      this.reconnectInProgress = false;
    }, delay + 1000);
  }
  
  /**
   * Get the current aggregated connection state
   * @returns {string} The current state
   */
  getConnectionState() {
    return this.connectionState;
  }
  
  /**
   * Get diagnostic information about the connection state
   * @returns {object} Diagnostic information
   */
  getDiagnosticInfo() {
    return {
      connectionState: this.connectionState,
      clientCount: this.clients.size,
      clientStates: Array.from(this.clients.entries()).map(([id, client]) => ({
        clientId: id,
        state: client.connectionState
      })),
      lastStateChangeTime: this.lastStateChangeTime,
      stateTransitions: this.stateTransitions,
      timestamp: new Date().toISOString()
    };
  }
  
  /**
   * Check if the connection is healthy
   * @returns {boolean} True if the connection is healthy
   */
  isConnectionHealthy() {
    return this.connectionState === 'CONNECTED';
  }
}

// Create singleton instance
const connectionStateManager = new ConnectionStateManager();

export default connectionStateManager;

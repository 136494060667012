
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '@lib/contexts/UserContext';
import { useDateSettings } from '@lib/contexts/DateSettingsContext';
import axios from '@api/axios';
import { format } from 'date-fns';
import { SORTING_GRADES, PROCESS_STAGES } from '@features/shared/constants/processStages';
import { withStorageIntegration } from './StorageIntegration';
import RowSelectionModal from '@lib/components/RowSelectionModal';
import MobileStorageAssignmentModal from '../MobileStorageAssignmentModal';
import {
  Container,
  ButtonContainer,
  MenuButton,
  TopGrid,
  MiddleGrid,
  BottomGrid,
  Button,
  Divider,
  Typography,
  Overlay,
  FeedContainer,
  CloseButton,
  FeedItem,
  FeedItemInfo,
  DeleteButton
} from './styles';
import {
  getMaxLayers,
  calculateTotalBoxes,
  addPalletToLocation,
  deletePallet
} from '@features/operator/services/operatorService';

export const FinishMenu = ({
  selectedPallets,
  onFinishPallet,
  locationId,
  tomatoType,
  filterRanges,
  recentlyFinished,
  onRemovePallet
}) => {
  const { user } = useContext(UserContext);
  const { dateSettings } = useDateSettings();
  const [showFeed, setShowFeed] = useState(false);
  const [feedPallets, setFeedPallets] = useState([]);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('Finished');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showStorageModal, setShowStorageModal] = useState(false);
  const [selectedPalletForReassign, setSelectedPalletForReassign] = useState(null);
  
  // New state for storage row selection
  const [pendingPalletData, setPendingPalletData] = useState(null);
  const [showStorageRowSelection, setShowStorageRowSelection] = useState(false);

  useEffect(() => {
    if (showFeed) {
      fetchTodaysPallets();
    }
  }, [showFeed]);

  const getPositionInfo = (position) => {
    const positions = getPositionsForTomatoType(tomatoType);
    return positions.find(pos => pos.label === position);
  };

  const renderPositionButton = (position) => {
    const isSelected = !!selectedPallets[position.label];
    const isRecentlyFinished = recentlyFinished?.[position.label];

    return (
      <Button
        key={position.label}
        onClick={() => handleFinishPallet(position.label)}
        disabled={!isSelected || isSubmitting}
        selected={isSelected}
        className={isRecentlyFinished ? 'recently-finished' : ''}
      >
        {position.label}
      </Button>
    );
  };

  const fetchTodaysPallets = async () => {
    try {
      setError(null);
      const response = await axios.get(`/api/pallets/${locationId}`);
      const today = new Date().toISOString().split('T')[0];
      const todaysPallets = response.data
        .filter(pallet => pallet.addedDate.startsWith(today))
        .sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate))
        .map(pallet => ({
          ...pallet,
          storageZone: pallet.storageZone || 'Not assigned'
        }));
      setFeedPallets(todaysPallets);
    } catch (error) {
      setError('Failed to fetch today\'s pallets');
    }
  };

  const handleReassignPallet = (pallet) => {
    setSelectedPalletForReassign(pallet);
    setShowStorageModal(true);
  };

  const handleStorageAssignment = async (result) => {
    if (!result || !selectedPalletForReassign) {
      setShowStorageModal(false);
      setSelectedPalletForReassign(null);
      return;
    }

    try {
      await axios.put(`/api/pallets/${selectedPalletForReassign.id}/storage`, {
        zoneId: result.zoneId,
        zoneName: result.zoneName,
        rowIndex: result.position.row,
        colIndex: result.position.col,
        storageZone: `${result.zoneId}-row-${result.position.row}`
      });

      fetchTodaysPallets(); // Refresh the feed
      setShowStorageModal(false);
      setSelectedPalletForReassign(null);
    } catch (error) {
      console.error('Failed to reassign pallet:', error);
      setError('Failed to reassign pallet');
    }
  };

  const handleFinishPallet = async (positionLabel) => {
    if (isSubmitting || !selectedPallets[positionLabel]) {
      console.log('FinishMenu: Skipping finish - already submitting or no pallet selected', {
        isSubmitting,
        hasPallet: !!selectedPallets[positionLabel]
      });
      setIsSubmitting(false);
      return;
    }

    if (dateSettings.mode === 'manual') {
      const proceed = window.confirm(
        `Warning: You are using a manually set date (${dateSettings.manualDate}) instead of the current day. Do you want to proceed?`
      );
      if (!proceed) {
        return;
      }
    }

    console.log('FinishMenu: Starting handleFinishPallet', { positionLabel });
    setIsSubmitting(true);
    try {
      const palletData = selectedPallets[positionLabel];
      console.log('FinishMenu: Selected pallet data', palletData);
      const positionInfo = getPositionInfo(positionLabel);
      if (!positionInfo) {
        throw new Error('Invalid position selected');
      }

      // Debug logging for sorting grade and position info
      console.log('FinishMenu: Position info and sorting grade:', {
        positionInfo,
        positionLabel,
        sortingGrade: positionInfo.sortingGrade,
        processStage: PROCESS_STAGES.UNPROCESSED
      });

      const sortingGrade = positionInfo.sortingGrade;
      // All finish menu pallets should default to unprocessed
      const processStage = PROCESS_STAGES.UNPROCESSED;

      // Get max layers for this combination
      const maxLayers = getMaxLayers(palletData.palletType, palletData.boxType);

      // Base pallet data
      let data = {
        ...palletData,
        addedDate: dateSettings.mode === 'manual'
          ? `${dateSettings.manualDate}T${new Date().toISOString().split('T')[1]}`
          : new Date().toISOString(),
        manualDate: dateSettings.mode === 'manual' ? dateSettings.manualDate : null,
        isManualDate: dateSettings.mode === 'manual',
        locationId,
        position: positionLabel,
        processStage,
        sortingGrade: positionInfo.sortingGrade, // Use the explicit sorting grade from positionInfo
        isUnfinished: mode === 'Unfinished',
        tomatoType: tomatoType,
        tomatoOption: positionInfo.tomatoOption,
        quantity: 1,
        order_id: '',
        userName: user?.username || 'Unknown User',
        user_id: user?.id
      };

      // Add filter ranges if available
      if (filterRanges?.[processStage]) {
        data.filterRangeMin = filterRanges[processStage].min;
        data.filterRangeMax = filterRanges[processStage].max;
      }

      // Handle unfinished pallets
      if (mode === 'Unfinished') {
        const layers = await promptForLayers(maxLayers);
        if (layers === null) {
          setIsSubmitting(false);
          return;
        }

        const additionalBoxes = await promptForAdditionalBoxes();
        if (additionalBoxes === null) {
          setIsSubmitting(false);
          return;
        }

        const parsedLayers = parseInt(layers);
        const parsedAdditionalBoxes = parseInt(additionalBoxes);

        const totalBoxes = calculateTotalBoxes(
          palletData.palletType,
          palletData.boxType,
          parsedLayers,
          parsedAdditionalBoxes
        );
        data = {
          ...data,
          layers: parsedLayers,
          additionalBoxes: parsedAdditionalBoxes,
          colliCount: totalBoxes,
          weight: (totalBoxes * 6).toString()
        };
      } else {
        // Finished pallets use max layers
        const totalBoxes = calculateTotalBoxes(
          palletData.palletType,
          palletData.boxType,
          maxLayers
        );
        data = {
          ...data,
          layers: maxLayers,
          additionalBoxes: 0,
          colliCount: totalBoxes,
          weight: (totalBoxes * 6).toString()
        };
      }

      // Pass the prepared data to the storage integration
      // The storage integration will handle creating the pallet after storage assignment
      onFinishPallet(positionLabel, true, {
        ...data,
        palletType: selectedPallets[positionLabel].palletType,
        boxType: selectedPallets[positionLabel].boxType,
        tomatoType: tomatoType,
        tomatoOption: positionInfo.tomatoOption,
        processStage: processStage,
        sortingGrade: positionInfo.sortingGrade,
        manualDate: dateSettings.mode === 'manual' ? dateSettings.manualDate : null
      });
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error preparing pallet:', error);
      onFinishPallet(positionLabel, false);
      setIsSubmitting(false);
    }
  };


  const handleDeletePallet = async (palletId) => {
    if (window.confirm('Are you sure you want to delete this pallet?')) {
      try {
        // Find the pallet before deleting to capture its data
        const palletToDelete = feedPallets.find(p => p.id === palletId);
        
        // Delete the pallet
        await deletePallet(palletId);
        
        // Update the local feed display
        fetchTodaysPallets();
        
        // If we found the pallet in our feed, and it's not unfinished
        // Notify parent components of removal (for weekly tally updates)
        if (palletToDelete && !palletToDelete.isUnfinished) {
          // This will trigger the withStorageIntegration HOC's onRemove handler
          // which can then propagate the event for weekly tally updates
          if (onRemovePallet) {
            onRemovePallet(palletToDelete);
          }
        }
      } catch (error) {
        console.error('Error deleting pallet:', error);
        setError('Failed to delete pallet');
      }
    }
  };

  // Track pallet position for weekly tally updates
  const trackPalletPositionForTally = async (palletId, position, sortingGrade, tomatoType) => {
    try {
      // Log that we're tracking this pallet for weekly tally updates
      console.log('FinishMenu: Tracking pallet position for weekly tally:', {
        palletId,
        position,
        sortingGrade,
        tomatoType
      });
      
      // You could add additional storage tracking here if needed
      // This function creates a hook for future expansion
      return true;
    } catch (error) {
      console.error('Error tracking pallet position for tally:', error);
      return false;
    }
  };

  const renderFeedOverlay = () => (
    showFeed && (
      <Overlay onClick={() => setShowFeed(false)}>
        <FeedContainer onClick={e => e.stopPropagation()}>
          <CloseButton onClick={() => setShowFeed(false)}>&times;</CloseButton>
          <h3>Today's Pallets</h3>
          {error ? (
            <div>{error}</div>
          ) : feedPallets.length > 0 ? (
            feedPallets.map((pallet) => (
              <FeedItem key={pallet.id}>
                <FeedItemInfo>
                  <div>Position: {pallet.position}</div>
                  <div>Type: {pallet.tomatoType} - {pallet.tomatoOption}</div>
                  <div>Process: {pallet.processStage}</div>
                  <div>Storage Zone: {pallet.storageZone}</div>
                  <div>Added: {format(new Date(pallet.addedDate), 'HH:mm:ss')}</div>
                </FeedItemInfo>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <DeleteButton onClick={() => handleDeletePallet(pallet.id)}>
                    Delete
                  </DeleteButton>
                  <Button 
                    onClick={() => handleReassignPallet(pallet)}
                    style={{
                      backgroundColor: '#2563eb',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    Reassign
                  </Button>
                </div>
              </FeedItem>
            ))
          ) : (
            <p>No pallets added today.</p>
          )}
        </FeedContainer>
      </Overlay>
    )
  );

  const renderButtons = () => {
    const positions = getPositionsForTomatoType(tomatoType);
    
    return (
      <>
        <TopGrid>
          {positions.slice(0, 6).map(renderPositionButton)}
        </TopGrid>
        <MiddleGrid>
          {positions.slice(6, 8).map(renderPositionButton)}
        </MiddleGrid>
        <Divider />
        <Typography>Automaat</Typography>
        <BottomGrid>
          {positions.slice(8).map(renderPositionButton)}
        </BottomGrid>
      </>
    );
  };

  return (
    <Container>
      <ButtonContainer>
        <MenuButton onClick={() => setShowFeed(true)}>
          Show Feed
        </MenuButton>
        <MenuButton 
          active={mode === 'Unfinished'}
          onClick={() => setMode(prev => prev === 'Finished' ? 'Unfinished' : 'Finished')}
        >
          {mode}
        </MenuButton>
      </ButtonContainer>
      {renderButtons()}
      {renderFeedOverlay()}
      
      <MobileStorageAssignmentModal
        isOpen={showStorageModal}
        onClose={() => {
          setShowStorageModal(false);
          setSelectedPalletForReassign(null);
        }}
        pallet={selectedPalletForReassign}
        onAssign={handleStorageAssignment}
        locationId={locationId}
      />
    </Container>
  );
};

// Helper functions would go here or in a separate utils file
const promptForLayers = async (maxLayers) => {
  const layers = prompt(`How many full layers of tomato boxes? (Max: ${maxLayers})`);
  if (!layers || isNaN(layers) || parseInt(layers) > maxLayers) {
    alert("Invalid number of layers");
    return null;
  }
  return layers;
};

const promptForAdditionalBoxes = async () => {
  const boxes = prompt('How many additional boxes?');
  if (!boxes || isNaN(boxes)) {
    alert("Invalid number of additional boxes");
    return null;
  }
  return boxes;
};

const getPositionsForTomatoType = (tomatoType) => {
  return tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'A grof +' },
        { label: 'Middle A grof +', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'A grof +' },
        { label: 'Red A grof +', sortingGrade: SORTING_GRADES.RED, tomatoOption: 'A grof +' },
        { label: 'Light B', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'B' },
        { label: 'Middle B', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'B' },
        { label: 'Red B', sortingGrade: SORTING_GRADES.RED, tomatoOption: 'B' },
        { label: 'A fijn Light', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'A fijn' },
        { label: 'A fijn Middle', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'A fijn' },
        { label: 'A grof Red', sortingGrade: SORTING_GRADES.RED, tomatoOption: 'A grof' },
        { label: 'A grof Middle', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'A grof' },
        { label: 'A grof Light', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'A grof' },
      ]
    : [
        { label: 'Light 6 Pack', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: '6 Pack' },
        { label: 'Middle 6 Pack', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: '6 Pack' },
        { label: 'Red 6 Pack', sortingGrade: SORTING_GRADES.RED, tomatoOption: '6 Pack' },
        { label: 'Light B', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'B' },
        { label: 'Middle B', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'B' },
        { label: 'Red B', sortingGrade: SORTING_GRADES.RED, tomatoOption: 'B' },
        { label: 'A fijn Light', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: 'A fijn' },
        { label: 'A fijn Middle', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: 'A fijn' },
        { label: 'Red 5 Pack', sortingGrade: SORTING_GRADES.RED, tomatoOption: '5 Pack' },
        { label: 'Middle 5 Pack', sortingGrade: SORTING_GRADES.MIDDLE, tomatoOption: '5 Pack' },
        { label: 'Light 5 Pack', sortingGrade: SORTING_GRADES.LIGHT, tomatoOption: '5 Pack' },
      ];
};

// Export the plain version for other components that don't need storage integration
export const PlainFinishMenu = FinishMenu;

// Export an enhanced version of FinishMenu with traditional storage integration
export default withStorageIntegration(FinishMenu);

export const orderStatuses = {
  all: 'All Orders',
  unassigned: 'Unassigned',
  assigned: 'Assigned',
  processing: 'In Processing',
  completed: 'Completed',
  shipped: 'Shipped'
};

export const colliConfigurations = {
  '30x6': 56,  // 46 colli per pallet
  '10x650': 72, // 72 colli per pallet
  '12x500': 75  // 75 colli per pallet
};

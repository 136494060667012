import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContainer, HeaderContainer, ContentContainer } from './styles';
import { DEFAULT_PRINTER_STATUS, PRINTER_TYPES, LOCATION, HARDCODED_CONFIG } from './constants';
import PrinterSection from './PrinterSection';
import PrinterLogs from './PrinterLogs';
import * as printerService from './printerService';

const PrinterTester = () => {
  const navigate = useNavigate();
  const [awsConfig, setAwsConfig] = useState(HARDCODED_CONFIG);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortingPrinterStatus, setSortingPrinterStatus] = useState(DEFAULT_PRINTER_STATUS);
  const [trapPrinterStatus, setTrapPrinterStatus] = useState(DEFAULT_PRINTER_STATUS);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    loadInitialData();
    const logInterval = setInterval(loadLogs, 5000); // Update logs more frequently
    return () => clearInterval(logInterval);
  }, [navigate]);

  const loadInitialData = async () => {
    try {
      setIsLoading(true);
      
      // Add initial system log
      const initialLog = {
        timestamp: new Date().toISOString(),
        action: 'SYSTEM_INIT',
        status: 'INFO',
        location: LOCATION.NAME,
        details: 'Printer testing interface initialized'
      };
      
      // Load configuration and logs in parallel
      const [configResponse, logsResponse] = await Promise.all([
        printerService.fetchAwsConfig(),
        printerService.fetchPrinterLogs()
      ]);
      
      // Set default config values
      setAwsConfig(configResponse || HARDCODED_CONFIG);
      
      // Combine logs with initial system log
      setLogs([initialLog, ...(logsResponse || [])]);
      
      // Set raw command and protocol defaults
      setSortingPrinterStatus(prev => ({
        ...prev,
        useRawCommand: true,
        protocol: configResponse?.protocols_7 ? 
          JSON.parse(configResponse.protocols_7)[0] : 
          prev.protocol
      }));
      
      setTrapPrinterStatus(prev => ({
        ...prev,
        useRawCommand: true,
        protocol: configResponse?.protocols_7 ? 
          JSON.parse(configResponse.protocols_7)[0] : 
          prev.protocol
      }));
    } catch (error) {
      console.error('Error loading initial data:', error);
      // Still set the hardcoded values as fallback
      setAwsConfig(HARDCODED_CONFIG);
    } finally {
      setIsLoading(false);
    }
  };

  const loadLogs = async () => {
    try {
      const logsResponse = await printerService.fetchPrinterLogs();
      if (Array.isArray(logsResponse)) {
        setLogs(logsResponse);
      }
    } catch (error) {
      console.error('Error loading logs:', error);
    }
  };

  const handleTestConnection = async (printerType, statusSetter, status) => {
    try {
      statusSetter(prev => ({ ...prev, isLoading: true, testResult: null }));
      const result = await printerService.testPrinterConnection(printerType, status.protocol);
      statusSetter(prev => ({
        ...prev,
        isLoading: false,
        testResult: result
      }));
      await loadLogs();
    } catch (error) {
      statusSetter(prev => ({
        ...prev,
        isLoading: false,
        testResult: {
          success: false,
          message: 'Connection test failed'
        }
      }));
      await loadLogs();
    }
  };

  const handlePrintTest = async (printerType, statusSetter, status) => {
    try {
      statusSetter(prev => ({ ...prev, isLoading: true, testResult: null }));
      const result = await printerService.printTestLabel(printerType, status);
      statusSetter(prev => ({
        ...prev,
        isLoading: false,
        testResult: result
      }));
      await loadLogs();
    } catch (error) {
      statusSetter(prev => ({
        ...prev,
        isLoading: false,
        testResult: {
          success: false,
          message: 'Print test failed'
        }
      }));
      await loadLogs();
    }
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <h1>{LOCATION.NAME} Printer Configuration</h1>
        <p>
          This page allows testing of printers with hardcoded configuration values. 
          Use Raw Commands mode to send EPL or ZPL commands directly to the printer.
        </p>
      </HeaderContainer>

      <ContentContainer>
        <PrinterSection
          printerType={PRINTER_TYPES.SORTING}
          status={sortingPrinterStatus}
          onStatusChange={setSortingPrinterStatus}
          awsConfig={awsConfig}
          onTestConnection={() => handleTestConnection(
            PRINTER_TYPES.SORTING,
            setSortingPrinterStatus,
            sortingPrinterStatus
          )}
          onPrintTest={() => handlePrintTest(
            PRINTER_TYPES.SORTING,
            setSortingPrinterStatus,
            sortingPrinterStatus
          )}
          isLoading={isLoading}
        />

        <PrinterSection
          printerType={PRINTER_TYPES.TRAP}
          status={trapPrinterStatus}
          onStatusChange={setTrapPrinterStatus}
          awsConfig={awsConfig}
          onTestConnection={() => handleTestConnection(
            PRINTER_TYPES.TRAP,
            setTrapPrinterStatus,
            trapPrinterStatus
          )}
          onPrintTest={() => handlePrintTest(
            PRINTER_TYPES.TRAP,
            setTrapPrinterStatus,
            trapPrinterStatus
          )}
          isLoading={isLoading}
        />

        <PrinterLogs
          logs={logs}
          onClearLogs={() => setLogs([])}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default PrinterTester;

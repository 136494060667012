import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
} from '../shared/ConsoleNavigation/styles';
import styled from 'styled-components';

// Export the shared styles
export {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
};

// Additional styles specific to SortingConsole
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.modal};
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;

  @keyframes fadeIn {
    to { opacity: 1; }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background.paper};
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  margin: ${({ theme }) => theme.spacing.xl} auto;
  padding: ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.xl};
  overflow-y: auto;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.3s ease forwards;

  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[400]};
    border-radius: ${({ theme }) => theme.borderRadius.full};
  }
`;

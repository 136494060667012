/**
 * Validation utilities for orders and pallets
 */

// Constants for validation
export const MAX_PALLETS = 1000;
export const MAX_ORDERS = 100;
export const MAX_BOXES = 10000;

/**
 * Validates order data structure
 * @param {Object} order - Order object to validate
 * @throws {Error} If validation fails
 */
export const validateOrderData = (order) => {
  if (!order) throw new Error("Invalid order: Order is required");
  if (!order.id) throw new Error("Invalid order: Missing order ID");
  if (!Array.isArray(order.pallets)) throw new Error("Invalid order: Pallets must be an array");
  if (order.pallets.length > MAX_PALLETS) {
    throw new Error(`Invalid order: Number of pallets exceeds maximum (${MAX_PALLETS})`);
  }
  return true;
};

/**
 * Validates pallet data structure
 * @param {Object} pallet - Pallet object to validate
 * @throws {Error} If validation fails
 */
export const validatePalletData = (pallet) => {
  if (!pallet) throw new Error("Invalid pallet: Pallet is required");
  if (!pallet.id) throw new Error("Invalid pallet: Missing pallet ID");
  return true;
};

/**
 * Validates zone data
 * @param {string} zoneName - Zone name to validate
 * @param {Array} rows - Rows array to validate
 * @throws {Error} If validation fails
 */
export const validateZoneData = (zoneName, rows) => {
  if (!zoneName) throw new Error("Invalid zone: Zone name is required");
  if (!Array.isArray(rows)) throw new Error("Invalid zone: Rows must be an array");
  return true;
};

/**
 * Safely extracts order ID from a pallet
 * @param {Object} pallet - Pallet object
 * @returns {string|null} Order ID or null if not found
 */
export const getPalletOrderId = (pallet) => {
  if (!pallet) return null;
  return String(pallet.order_id || pallet.orderId || '');
};

/**
 * Safely extracts box count from a pallet
 * @param {Object} pallet - Pallet object
 * @returns {number} Box count, defaults to 0 if not found
 */
export const getPalletBoxCount = (pallet) => {
  if (!pallet) return 0;
  return pallet.colli_count ?? pallet.box_count ?? pallet.boxCount ?? 0;
};

/**
 * Validates and normalizes allowed pallet IDs
 * @param {Array|string} allowedPalletIds - Array or JSON string of allowed pallet IDs
 * @returns {Array} Normalized array of pallet IDs
 */
export const normalizeAllowedPalletIds = (allowedPalletIds) => {
  if (!allowedPalletIds) return [];
  
  if (Array.isArray(allowedPalletIds)) {
    return allowedPalletIds.map(id => String(id));
  }
  
  if (typeof allowedPalletIds === 'string') {
    try {
      return JSON.parse(allowedPalletIds).map(id => String(id));
    } catch (e) {
      console.error("Error parsing allowed_pallet_ids:", e);
      return [];
    }
  }
  
  return [];
};

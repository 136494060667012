import { useState } from 'react';

export const useOrderAssignment = ({ onAssign, onClose, palletDetails }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [extraBoxes, setExtraBoxes] = useState('');
  const [isAssigning, setIsAssigning] = useState(false);

  const handleAssign = async () => {
    if (!selectedOrder || !palletDetails || isAssigning) return;
    
    setIsAssigning(true);
    const extraBoxCount = extraBoxes === '' ? null : parseInt(extraBoxes);
    
    try {
      console.log('Starting assignment with:', {
        orderId: selectedOrder.id,
        extraBoxCount,
        palletDetails
      });
      
      // Pass palletDetails to the assignment function
      await onAssign(selectedOrder.id, extraBoxCount, palletDetails);
      onClose();
    } catch (error) {
      console.error('Error in handleAssign:', error);
      // Don't close the modal if there's an error
    } finally {
      setIsAssigning(false);
    }
  };

  const resetForm = () => {
    setSelectedOrder(null);
    setExtraBoxes('');
  };

  return {
    selectedOrder,
    setSelectedOrder,
    extraBoxes,
    setExtraBoxes,
    handleAssign,
    resetForm,
    isAssigning
  };
};

export default useOrderAssignment;

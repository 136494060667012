import React from 'react';
import { 
  Card, Title, Button, FormGroup, Label, Input, Select, 
  CheckboxContainer, Checkbox, CheckboxLabel, ModalOverlay 
} from '../components/StyledComponents';

const CreateUserModal = ({ 
  isOpen, 
  onClose, 
  userData, 
  onUserDataChange, 
  onCreateUser,
  locations 
}) => {
  // Initialize states - always declare hooks at the top level
  const [selectedLocations, setSelectedLocations] = React.useState([]);
  const [mobileFeatures, setMobileFeatures] = React.useState({
    'mobile.sorting_console': true,
    'mobile.kleinpak_console': true,
    'mobile.logistics_console': true,
    'mobile.storage_map': true, 
    'mobile.sorting_console.pallet_modal.assign_order': true,
    'mobile.sorting_console.pallet_modal.print_label': true,
    'mobile.sorting_console.pallet_modal.move': true
  });
  
  // Handle location selection changes
  const handleLocationChange = (locationId, isChecked) => {
    if (isChecked) {
      setSelectedLocations(prev => [...prev, locationId]);
    } else {
      setSelectedLocations(prev => prev.filter(id => id !== locationId));
    }
  };
  
  // Handle mobile feature selection changes
  const handleFeatureChange = (featureKey, isChecked) => {
    setMobileFeatures(prev => ({
      ...prev,
      [featureKey]: isChecked
    }));
  };
  
  // Reset selections when modal closes - always declare hooks at the top level
  React.useEffect(() => {
    if (!isOpen) {
      setSelectedLocations([]);
      setMobileFeatures({
        'mobile.sorting_console': true,
        'mobile.kleinpak_console': true,
        'mobile.logistics_console': true,
        'mobile.storage_map': true,
        'mobile.sorting_console.pallet_modal.assign_order': true,
        'mobile.sorting_console.pallet_modal.print_label': true,
        'mobile.sorting_console.pallet_modal.move': true
      });
    }
  }, [isOpen]);
  
  // Early return pattern after all hooks are called
  if (!isOpen) return null;
  
  return (
    <ModalOverlay>
      <Card style={{ width: '500px', maxWidth: '90%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <Title>Create New User</Title>
          <Button onClick={onClose} style={{ background: 'none', border: 'none', fontSize: '1.5rem' }}>×</Button>
        </div>
        
        <FormGroup>
          <Label htmlFor="username">Username</Label>
          <Input 
            id="username" 
            type="text" 
            value={userData.username || ''} 
            onChange={e => onUserDataChange({...userData, username: e.target.value})}
          />
          <div style={{ fontSize: '0.75rem', fontStyle: 'italic', marginTop: '0.25rem', color: '#666' }}>
            Initial password will be the same as username. User will be prompted to change it on first login.
          </div>
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="role">Role</Label>
          <Select 
            id="role" 
            value={userData.role || 'user'} 
            onChange={e => onUserDataChange({...userData, role: e.target.value})}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
            <option value="operator">Operator</option>
            <option value="manager">Manager</option>
          </Select>
        </FormGroup>
        
        <FormGroup>
          <Label>Client Access</Label>
          <CheckboxContainer>
            <Checkbox 
              type="checkbox" 
              id="desktop_access" 
              checked={userData.desktop_access || false} 
              onChange={e => onUserDataChange({...userData, desktop_access: e.target.checked})}
            />
            <CheckboxLabel htmlFor="desktop_access">
              Desktop Access
            </CheckboxLabel>
          </CheckboxContainer>
          
          <CheckboxContainer>
            <Checkbox 
              type="checkbox" 
              id="mobile_access" 
              checked={userData.mobile_access || false} 
              onChange={e => onUserDataChange({...userData, mobile_access: e.target.checked})}
            />
            <CheckboxLabel htmlFor="mobile_access">
              Mobile Access
            </CheckboxLabel>
          </CheckboxContainer>
        </FormGroup>
        
        {userData.mobile_access && (
          <FormGroup>
            <Label>Mobile Features</Label>
            <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
              Select features this user can access on mobile:
            </div>
            
            <Label style={{ fontSize: '0.9rem', marginTop: '0.5rem' }}>Mobile Client Consoles</Label>
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="sorting_console" 
                checked={mobileFeatures['mobile.sorting_console']} 
                onChange={e => handleFeatureChange('mobile.sorting_console', e.target.checked)}
              />
              <CheckboxLabel htmlFor="sorting_console">
                Sorting Console
              </CheckboxLabel>
            </CheckboxContainer>
            
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="kleinpak_console" 
                checked={mobileFeatures['mobile.kleinpak_console']} 
                onChange={e => handleFeatureChange('mobile.kleinpak_console', e.target.checked)}
              />
              <CheckboxLabel htmlFor="kleinpak_console">
                Kleinpak Console
              </CheckboxLabel>
            </CheckboxContainer>
            
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="logistics_console" 
                checked={mobileFeatures['mobile.logistics_console']} 
                onChange={e => handleFeatureChange('mobile.logistics_console', e.target.checked)}
              />
              <CheckboxLabel htmlFor="logistics_console">
                Logistics Console
              </CheckboxLabel>
            </CheckboxContainer>
            
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="storage_map" 
                checked={mobileFeatures['mobile.storage_map']} 
                onChange={e => handleFeatureChange('mobile.storage_map', e.target.checked)}
              />
              <CheckboxLabel htmlFor="storage_map">
                Storage Map
              </CheckboxLabel>
            </CheckboxContainer>
            
            <Label style={{ fontSize: '0.9rem', marginTop: '0.5rem' }}>Sorting Pallet Actions</Label>
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="assign_order" 
                checked={mobileFeatures['mobile.sorting_console.pallet_modal.assign_order']} 
                onChange={e => handleFeatureChange('mobile.sorting_console.pallet_modal.assign_order', e.target.checked)}
              />
              <CheckboxLabel htmlFor="assign_order">
                Assign to Order
              </CheckboxLabel>
            </CheckboxContainer>
            
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="print_label" 
                checked={mobileFeatures['mobile.sorting_console.pallet_modal.print_label']} 
                onChange={e => handleFeatureChange('mobile.sorting_console.pallet_modal.print_label', e.target.checked)}
              />
              <CheckboxLabel htmlFor="print_label">
                Print Label
              </CheckboxLabel>
            </CheckboxContainer>
            
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                id="move_pallet" 
                checked={mobileFeatures['mobile.sorting_console.pallet_modal.move']} 
                onChange={e => handleFeatureChange('mobile.sorting_console.pallet_modal.move', e.target.checked)}
              />
              <CheckboxLabel htmlFor="move_pallet">
                Move Pallet
              </CheckboxLabel>
            </CheckboxContainer>
          </FormGroup>
        )}
        
        {/* Set company ID to 1 automatically */}
        <input type="hidden" value="1" name="companyId" />
        
        <FormGroup>
          <Label>Location Access</Label>
          <CheckboxContainer>
            <Checkbox 
              type="checkbox" 
              id="accessAllLocations" 
              checked={userData.accessAllLocations || false} 
              onChange={e => onUserDataChange({...userData, accessAllLocations: e.target.checked})}
            />
            <CheckboxLabel htmlFor="accessAllLocations">
              Access All Locations
            </CheckboxLabel>
          </CheckboxContainer>
          
          {!userData.accessAllLocations && (
            <div style={{ marginTop: '0.5rem', border: '1px solid #eee', padding: '0.5rem', borderRadius: '0.25rem' }}>
              <div style={{ marginBottom: '0.5rem', fontSize: '0.875rem', fontStyle: 'italic' }}>
                Select specific locations this user can access:
              </div>
              {Array.isArray(locations) && locations.map(location => (
                <CheckboxContainer key={location.id}>
                  <Checkbox 
                    type="checkbox" 
                    id={`location-${location.id}`} 
                    checked={selectedLocations.includes(location.id)}
                    onChange={e => handleLocationChange(location.id, e.target.checked)}
                  />
                  <CheckboxLabel htmlFor={`location-${location.id}`}>
                    {location.name}
                  </CheckboxLabel>
                </CheckboxContainer>
              ))}
            </div>
          )}
        </FormGroup>
        
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button 
            onClick={onClose} 
            style={{ marginRight: '0.5rem' }}
          >
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={() => {
              // Find features the user shouldn't have access to (invert the selection)
              const restrictedFeatures = Object.entries(mobileFeatures)
                .filter(([_, isEnabled]) => !isEnabled)
                .map(([key]) => key);
              
              // Update userData with company ID 1, selected locations, and feature restrictions
              const updatedUserData = {
                ...userData,
                companyId: 1, // Always set companyId to 1
                selectedLocationIds: userData.accessAllLocations ? [] : selectedLocations,
                restrictedFeatures: restrictedFeatures
              };
              onCreateUser(updatedUserData);
            }}
            style={{ background: '#4CAF50', color: 'white' }}
          >
            Create User
          </Button>
        </div>
      </Card>
    </ModalOverlay>
  );
};

export default CreateUserModal;

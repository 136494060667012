import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Header = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.border.light};
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

export const StatCard = styled.div`
  padding: 1.5rem;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${theme.shadows.md};
  }
`;

export const StatValue = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${theme.colors.primary.main};
  }
`;

export const StatLabel = styled.div`
  font-size: 0.875rem;
  color: ${theme.colors.text.secondary};
`;

export const ChartContainer = styled.div`
  padding: 1.5rem;
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};
`;

export const ChartTitle = styled.h3`
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: ${theme.colors.text.primary};
`;

export const ChartDescription = styled.p`
  margin: 0 0 1.5rem 0;
  font-size: 0.875rem;
  color: ${theme.colors.text.secondary};
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 3rem;
  text-align: center;
  color: ${theme.colors.text.secondary};
  background-color: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  box-shadow: ${theme.shadows.sm};

  svg {
    color: ${theme.colors.text.disabled};
  }

  p {
    margin: 0;
    font-size: 1rem;
  }
`;

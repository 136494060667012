import React from 'react';
import { Plus, FileSpreadsheet } from 'lucide-react';
import styled from 'styled-components';
import DashboardFilters from '../../DashboardFilters';
import DashboardTables from '../../DashboardTables';
import { BatchActionsToolBar } from '@components/BatchActionsToolBar';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  ${({ theme }) => theme.typography.h3};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-right: ${({ theme }) => theme.spacing.lg};
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  ${({ theme }) => theme.typography.body2};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }

  svg {
    width: ${({ theme }) => theme.spacing.lg};
    height: ${({ theme }) => theme.spacing.lg};
  }
`;

const ResultCount = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ theme }) => theme.typography.body2};
  margin: ${({ theme }) => theme.spacing.md} 0;
`;

const PalletList = ({
  pallets,
  filters,
  onFilterChange,
  selectedPallets,
  selectAllChecked,
  onSelectAll,
  onSelectPallet,
  onSort,
  onShipPallet,
  onEditPallet,
  onDeletePallet,
  onReturnPallet,
  onSplitPallet,
  onAssignOrder,
  onRowKeyDown,
  onAddPallet,
  onExportExcel,
  batchOperationLoading,
  batchOperationError,
  onBatchShip,
  onBatchDelete,
  onBatchUpdate,
  onShowInfo,
  onChangeStorageLocation,
}) => {
  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Title>Pallets</Title>
          <ActionButton onClick={onAddPallet}>
            <Plus size={16} />
            Add Pallet
          </ActionButton>
        </HeaderLeft>
        <ActionButton variant="export" onClick={onExportExcel}>
          <FileSpreadsheet size={16} />
          Export Excel
        </ActionButton>
      </Header>

      <DashboardFilters filters={filters} onChange={onFilterChange} />
            
      <ResultCount>
        {filters.palletStatus === 'shipped' 
          ? `Showing ${pallets.length} shipped pallets`
          : `Showing ${pallets.length} active pallets in inventory`
        }
      </ResultCount>

      <BatchActionsToolBar
        selectedCount={selectedPallets.size}  
        onShip={onBatchShip}
        onDelete={onBatchDelete}
        onUpdate={onBatchUpdate}
        onAssign={() => onAssignOrder(Array.from(selectedPallets))}
        isLoading={batchOperationLoading}
        error={batchOperationError}
      />
  
      <DashboardTables
        pallets={pallets}
        selectedPallets={selectedPallets}
        selectAllChecked={selectAllChecked}
        onSelectAll={(checked) => {
          // Ensure we only select non-shipped pallets
          const palletsToSelect = pallets.filter(p => p.status !== 'shipped');
          onSelectAll(checked, palletsToSelect.map(p => p.id));
        }}
        onSelectPallet={onSelectPallet}
        onSort={onSort}
        onShip={onShipPallet}
        onEdit={onEditPallet}
        onDelete={onDeletePallet}
        onReturn={onReturnPallet}
        onSplit={onSplitPallet}
        onAssignOrder={onAssignOrder}
        onRowKeyDown={onRowKeyDown}
        onShowInfo={onShowInfo}
        onChangeStorageLocation={onChangeStorageLocation}
      />
    </Container>
  );
};

export default PalletList;

import { useEffect, useCallback } from 'react';
import usePersistentState from './usePersistentState';

/**
 * Hook to save and restore scroll position for a specific component
 * 
 * @param {string} id - Unique identifier for this component's scroll position
 * @param {boolean} enabled - Whether scroll position should be tracked
 * @param {object} options - Additional options
 * @returns {object} scroll position methods
 */
const useScrollPosition = (id, enabled = true, options = {}) => {
  const storageKey = `scroll_position_${id}`;
  const [savedPosition, setSavedPosition] = usePersistentState(storageKey, 0, true);
  const { debounceMs = 100, scrollElement = null } = options;
  
  // Save current scroll position
  const saveScrollPosition = useCallback(() => {
    if (!enabled) return;

    // Get position from either the provided element or window
    const currentPosition = scrollElement 
      ? scrollElement.scrollTop 
      : (window.scrollY || document.documentElement.scrollTop);
      
    setSavedPosition(currentPosition);
  }, [enabled, setSavedPosition, scrollElement]);
  
  // Restore saved scroll position
  const restoreScrollPosition = useCallback(() => {
    if (!enabled || typeof savedPosition !== 'number') return;
    
    // Use setTimeout to ensure DOM has completed rendering before scrolling
    setTimeout(() => {
      if (scrollElement) {
        scrollElement.scrollTop = savedPosition;
      } else {
        window.scrollTo(0, savedPosition);
      }
    }, 0);
  }, [enabled, savedPosition, scrollElement]);
  
  // Set up scroll event listener with debounce
  useEffect(() => {
    if (!enabled) return;
    
    let debounceTimer = null;
    
    const handleScroll = () => {
      if (debounceTimer) clearTimeout(debounceTimer);
      debounceTimer = setTimeout(saveScrollPosition, debounceMs);
    };
    
    // Attach listener to either the element or window
    const target = scrollElement || window;
    target.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      if (debounceTimer) clearTimeout(debounceTimer);
      target.removeEventListener('scroll', handleScroll);
    };
  }, [enabled, saveScrollPosition, debounceMs, scrollElement]);
  
  // Restore position on mount
  useEffect(() => {
    if (enabled) restoreScrollPosition();
  }, [enabled, restoreScrollPosition]);
  
  return {
    saveScrollPosition,
    restoreScrollPosition,
    savedPosition,
    setSavedPosition
  };
};

export default useScrollPosition;

import { useState, useEffect } from 'react';

/**
 * Custom hook to persist state in localStorage/sessionStorage across page refreshes
 * 
 * @param {string} key - Unique identifier for this state in storage
 * @param {any} defaultValue - Default value if nothing exists in storage
 * @param {boolean} useSession - Whether to use sessionStorage (true) or localStorage (false)
 * @returns {[any, Function]} State value and setter function
 */
const usePersistentState = (key, defaultValue, useSession = false) => {
  // Use the appropriate storage mechanism
  const storage = useSession ? sessionStorage : localStorage;
  
  // Initial state setup - try to get from storage, fallback to default
  const [state, setState] = useState(() => {
    try {
      const storedValue = storage.getItem(key);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Error retrieving '${key}' from storage:`, error);
      return defaultValue;
    }
  });
  
  // Sync state to storage whenever it changes
  useEffect(() => {
    try {
      if (state !== undefined) {
        storage.setItem(key, JSON.stringify(state));
      } else {
        storage.removeItem(key);
      }
    } catch (error) {
      console.error(`Error storing '${key}' to storage:`, error);
    }
  }, [key, state, storage]);
  
  return [state, setState];
};

export default usePersistentState;

import { api } from '@lib/api';
import { PROCESS_STAGES } from '@features/shared/constants/processStages';

export const updatePalletStage = async (palletId, { processStage, sortingGrade }) => {
  try {
    const response = await api.patch(`/api/pallets/${palletId}/stage`, {
      processStage,
      sortingGrade
    });
    return response.data;
  } catch (error) {
    console.error('Error updating pallet stage:', error);
    throw error;
  }
};

export const assignPalletToOrder = async (palletId, orderId, processingData = null) => {
  try {
    const isKleinpak = !!processingData;
    
    // For all orders, we need to set the process stage to IN_PROCESSING
    const payload = {
      orderId,
      // Always set to IN_PROCESSING for all orders when first assigned
      processStage: PROCESS_STAGES.IN_PROCESSING,
    };
    
    // Include processing data for Kleinpak orders
    if (isKleinpak && processingData) {
      payload.processingLine = processingData.processingLine;
      payload.tomatoType = processingData.tomatoType;
      payload.assignedAt = processingData.assignedAt || new Date().toISOString();
    }
    
    const response = await api.patch(`/api/pallets/${palletId}/assign`, payload);
    return response.data;
  } catch (error) {
    console.error('Error assigning pallet to order:', error);
    throw error;
  }
};

// New function to mark an order as complete and set all its pallets to PROCESSED
export const markOrderComplete = async (orderId) => {
  try {
    // Get all pallets for this order with IN_PROCESSING status
    const pallets = await getProcessedPalletsForOrder(orderId);
    
    if (Array.isArray(pallets)) {
      // Update each pallet's status to PROCESSED
      const updatePromises = pallets.map(pallet => 
        updatePalletStage(pallet.id, { processStage: PROCESS_STAGES.PROCESSED })
      );
      
      await Promise.all(updatePromises);
    }
    
    // Update order status to completed
    const response = await api.patch(`/api/orders/${orderId}/complete`);
    return response.data;
  } catch (error) {
    console.error('Error marking order complete:', error);
    throw error;
  }
};

export const finishKleinpakProcessing = async (palletId) => {
  try {
    const response = await api.patch(`/api/pallets/${palletId}/stage`, {
      processStage: PROCESS_STAGES.PROCESSED
    });
    return response.data;
  } catch (error) {
    console.error('Error finishing kleinpak processing:', error);
    throw error;
  }
};

export const markPalletReadyForShipping = async (palletId) => {
  try {
    const response = await api.patch(`/api/pallets/${palletId}/stage`, {
      processStage: PROCESS_STAGES.READY_FOR_SHIPPING
    });
    return response.data;
  } catch (error) {
    console.error('Error marking pallet ready for shipping:', error);
    throw error;
  }
};

// Get processed pallets for an order
export const getProcessedPalletsForOrder = async (orderId) => {
  try {
    const response = await api.get(`/api/orders/${orderId}/pallets?processStage=IN_PROCESSING`);
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error getting processed pallets for order:', error);
    return [];
  }
};

// Get the processing time metrics for an order
export const getOrderProcessingMetrics = async (orderId) => {
  try {
    const response = await api.get(`/api/orders/${orderId}/processing-metrics`);
    return response.data;
  } catch (error) {
    console.error('Error getting order processing metrics:', error);
    throw error;
  }
};

// Load saved selections from localStorage
export const loadSavedSelections = (userId) => {
  try {
    const saved = localStorage.getItem(`palletSelections_${userId}`);
    return saved ? JSON.parse(saved) : {};
  } catch (error) {
    console.error('Error loading saved selections:', error);
    return {};
  }
};

// Save selections to localStorage
export const saveSelections = (userId, selections) => {
  try {
    localStorage.setItem(`palletSelections_${userId}`, JSON.stringify(selections));
  } catch (error) {
    console.error('Error saving selections:', error);
  }
};

// Load recently finished pallets from localStorage
export const loadRecentlyFinished = () => {
  try {
    const saved = localStorage.getItem('recentlyFinishedPallets');
    return saved ? JSON.parse(saved) : {};
  } catch (error) {
    console.error('Error loading recently finished pallets:', error);
    return {};
  }
};

// Save recently finished pallets to localStorage
export const saveRecentlyFinished = (recentlyFinished) => {
  try {
    localStorage.setItem('recentlyFinishedPallets', JSON.stringify(recentlyFinished));
  } catch (error) {
    console.error('Error saving recently finished pallets:', error);
  }
};

// Handle shipping a pallet - fixed API endpoint
export const handleShipPallet = async (palletId, locationId) => {
  try {
    // Use the correct endpoint for shipping a pallet
    // Based on server route: PUT /api/pallets/mark-shipped/:id
    const response = await api.put(`/api/pallets/mark-shipped/${palletId}`, { 
      locationId 
    });
    
    return response.data;
  } catch (error) {
    console.error('Error shipping pallet:', error);
    throw error;
  }
};

// Handle deleting a pallet
export const handleDeletePallet = async (palletId, locationId) => {
  try {
    await api.delete(`/api/pallets/${palletId}`, { data: { locationId } });
  } catch (error) {
    console.error('Error deleting pallet:', error);
    throw error;
  }
};

// Handle assigning a pallet to an order
export const handleAssignOrder = async (palletId, orderId) => {
  try {
    // Instead of using the api.post directly, use the assignPalletToOrder function 
    // which properly sets the process stage to IN_PROCESSING
    return await assignPalletToOrder(palletId, orderId, null);
  } catch (error) {
    console.error('Error assigning order:', error);
    throw error;
  }
};

// Handle returning a pallet
export const handleReturnPallet = async (palletId, locationId) => {
  try {
    const response = await api.post(`/api/pallets/${palletId}/return`, { locationId });
    return response.data;
  } catch (error) {
    console.error('Error returning pallet:', error);
    throw error;
  }
};

// Handle splitting a pallet - added splitAmount parameter to fix 500 error
export const handleSplitPallet = async (palletId, locationId, splitAmount = 0.5) => {
  try {
    const response = await api.post(`/api/pallets/${palletId}/split`, { 
      locationId,
      splitAmount // Add splitAmount parameter to fix "Invalid split amount" error
    });
    return response.data;
  } catch (error) {
    console.error('Error splitting pallet:', error);
    throw error;
  }
};

// Calculate tomato tally by type for given pallets
export const calculateTomatoTally = (pallets) => {
  if (!Array.isArray(pallets)) return {};
  
  return pallets.reduce((tally, pallet) => {
    const type = pallet.tomatoType || 'Unknown';
    tally[type] = (tally[type] || 0) + 1;
    return tally;
  }, {});
};

// Calculate progress percentage based on 2:1 ratio
export const calculateProgressPercentage = (processedPallets, totalOutputPallets) => {
  // Using 2:1 ratio (2 input pallets = 1 output pallet)
  const totalRequiredInputPallets = totalOutputPallets * 2;
  return Math.min((processedPallets / totalRequiredInputPallets) * 100, 100);
};

// Unified function to handle pallet actions with consistent error handling
export const handlePalletAction = async (actionType, palletId, locationId, additionalParams = {}) => {
  try {
    let result;
    
    switch(actionType) {
      case 'ship':
        result = await handleShipPallet(palletId, locationId);
        break;
      case 'assign':
        result = await handleAssignOrder(palletId, additionalParams.orderId);
        break;
      case 'return':
        result = await handleReturnPallet(palletId, locationId);
        break;
      case 'split':
        result = await handleSplitPallet(palletId, locationId, additionalParams.splitAmount);
        break;
      default:
        throw new Error(`Unknown action type: ${actionType}`);
    }
    
    return result;
  } catch (error) {
    console.error(`Error performing ${actionType} action:`, error);
    throw error;
  }
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '@styles/theme';
import { format } from 'date-fns';
import { 
  fetchHistoricalTallyCards, 
  fetchTallyCardById, 
  getDailySnapshots 
} from '../../services/weeklyTallyService';

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const Tab = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: ${theme.borderRadius.md};
  background: ${props => props.active ? theme.colors.primary.main : theme.colors.background.secondary};
  color: ${props => props.active ? 'white' : theme.colors.text.primary};
  cursor: pointer;
  
  &:hover {
    background: ${props => props.active ? theme.colors.primary.dark : theme.colors.background.hover};
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  padding: 2rem;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h2 {
    margin: 0;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: ${theme.colors.text.secondary};

  &:hover {
    color: ${theme.colors.text.primary};
  }
`;

const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Card = styled.div`
  padding: 1rem;
  border: 1px solid ${theme.colors.border.default};
  border-radius: ${theme.borderRadius.md};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${theme.colors.background.hover};
  }

  &.selected {
    border-color: ${theme.colors.primary.main};
    background: ${theme.colors.primary.light};
  }
`;

const CardDetails = styled.div`
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid ${theme.colors.border.default};
`;

const TallyGrid = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(6, 1fr) 100px;
  gap: 1px;
  background: ${theme.colors.border.default};
  border: 1px solid ${theme.colors.border.default};
  border-radius: ${theme.borderRadius.md};
  overflow: hidden;
`;

const TallyCell = styled.div`
  padding: 0.75rem;
  background: ${theme.colors.background.paper};
  text-align: center;

  &.header {
    background: ${theme.colors.background.secondary};
    font-weight: 600;
  }

  &.section-header {
    background: ${theme.colors.background.hover};
    font-weight: 600;
    text-align: left;
  }

  &.row-type {
    background: ${theme.colors.background.hover};
    text-align: left;
  }

  &.total {
    background: ${theme.colors.background.secondary};
    font-weight: 600;
  }
`;

const DAYS_OF_WEEK = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];

const HistoryModal = ({ isOpen, onClose, locationId }) => {
  const [viewMode, setViewMode] = useState('weekly'); // 'weekly' or 'daily'
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snapshots, setSnapshots] = useState([]);

  useEffect(() => {
    if (isOpen && locationId) {
      if (viewMode === 'weekly') {
        loadHistoricalCards();
      } else {
        loadDailySnapshots();
      }
    }
  }, [isOpen, locationId, viewMode]);

  const loadDailySnapshots = async () => {
    try {
      setLoading(true);
      setError(null);
      // Get snapshots for the last 7 days
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      
      const data = await getDailySnapshots(
        locationId,
        format(startDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
        selectedCard?.tomato_type || 'Round'
      );
      setSnapshots(data);
    } catch (err) {
      setError('Failed to load daily snapshots');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const loadHistoricalCards = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await fetchHistoricalTallyCards(locationId);
      setCards(data);
    } catch (err) {
      setError('Failed to load historical cards');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = async (card) => {
    try {
      setLoading(true);
      setError(null);
      const cardDetails = await fetchTallyCardById(card.id);
      setSelectedCard(cardDetails);
    } catch (err) {
      setError('Failed to load card details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderTallyGrid = (tallyData) => {
    if (!tallyData?.sections) return null;

    return (
      <TallyGrid>
        <TallyCell className="header"></TallyCell>
        {DAYS_OF_WEEK.map(day => (
          <TallyCell key={day} className="header">{day}</TallyCell>
        ))}
        <TallyCell className="header">Total</TallyCell>

        {Object.entries(tallyData.sections).map(([section, data]) => (
          <React.Fragment key={section}>
            <TallyCell className="section-header" style={{ gridColumn: '1 / -1' }}>
              {section}
            </TallyCell>
            {['licht', 'midden', 'rood'].map(rowType => (
              <React.Fragment key={`${section}-${rowType}`}>
                <TallyCell className="row-type">{rowType}</TallyCell>
                {data[rowType].map((count, dayIndex) => (
                  <TallyCell key={dayIndex}>{count}</TallyCell>
                ))}
                <TallyCell className="total">
                  {data[rowType].reduce((sum, count) => sum + count, 0)}
                </TallyCell>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </TallyGrid>
    );
  };

  if (!isOpen) return null;

  return (
    <Modal onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <h2>Historical Tally Data</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>

        <TabContainer>
          <Tab 
            active={viewMode === 'weekly'} 
            onClick={() => setViewMode('weekly')}
          >
            Weekly History
          </Tab>
          <Tab 
            active={viewMode === 'daily'} 
            onClick={() => setViewMode('daily')}
          >
            Daily Snapshots
          </Tab>
        </TabContainer>

        {loading && <div>Loading...</div>}
        {error && <div style={{ color: 'red' }}>{error}</div>}

        {!loading && !error && viewMode === 'weekly' && (
          <>
            <CardList>
              {cards.map(card => (
                <Card
                  key={card.id}
                  className={selectedCard?.id === card.id ? 'selected' : ''}
                  onClick={() => handleCardClick(card)}
                >
                  <div>Week of {new Date(card.week_start_date).toLocaleDateString()}</div>
                  <div>{card.tomato_type}</div>
                </Card>
              ))}
            </CardList>

            {selectedCard && (
              <CardDetails>
                <h3>Week of {new Date(selectedCard.week_start_date).toLocaleDateString()}</h3>
                {renderTallyGrid(selectedCard.tally_data)}
              </CardDetails>
            )}
          </>
        )}

        {!loading && !error && viewMode === 'daily' && (
          <>
            <CardList>
              {snapshots.map(snapshot => (
                <Card
                  key={snapshot.id}
                  className={selectedCard?.id === snapshot.id ? 'selected' : ''}
                  onClick={() => setSelectedCard(snapshot)}
                >
                  <div>{format(new Date(snapshot.snapshot_date), 'dd MMM yyyy')}</div>
                  <div>{snapshot.tomato_type}</div>
                </Card>
              ))}
            </CardList>
            
            {selectedCard && (
              <CardDetails>
                <h3>Snapshot from {format(new Date(selectedCard.snapshot_date), 'dd MMM yyyy')}</h3>
                {renderTallyGrid(JSON.parse(selectedCard.tally_data))}
              </CardDetails>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default HistoryModal;

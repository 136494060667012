import { api } from '@lib/api';

const BatchActionsManager = (selectedPallets, onAsyncAction, loadPallets) => {
  const handleBatchShip = async () => {
    try {
      await onAsyncAction(async () => {
        const response = await api.post('/api/pallets/batch/ship', {
          palletIds: Array.from(selectedPallets)
        });
        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to ship pallets');
        }
        // Refresh data and reset selection
        await loadPallets();
      });
      return true;
    } catch (error) {
      return error.message || 'Failed to ship pallets';
    }
  };

  const handleBatchDelete = async () => {
    const confirm = window.confirm(
      `Are you sure you want to delete the ${selectedPallets.size} selected pallets? This cannot be undone.`
    );

    if (confirm) {
    try {
      await onAsyncAction(async () => {
        const response = await api.post('/api/pallets/batch/delete', {
          palletIds: Array.from(selectedPallets)
        });
        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to delete pallets');
        }
        // Refresh data and reset selection
        await loadPallets();
      });
      return true;
    } catch (error) {
      return error.message || 'Failed to delete pallets';
    }
    }
    return false;
  };

  const handleBatchUpdate = async (updateData) => {
    try {
      await onAsyncAction(async () => {
        // Create a new object with only the fields we want to update
        const cleanUpdateData = {
          palletType: updateData.palletType,
          boxType: updateData.boxType,
          processStage: updateData.processStage,
          tomatoType: updateData.tomatoType,
          tomatoOption: updateData.tomatoOption,
          locationId: updateData.locationId,
          isUnfinished: updateData.isUnfinished,
          layers: updateData.layers,
          additionalBoxes: updateData.additionalBoxes,
          colliCount: updateData.colliCount,
          weight: updateData.weight,
          order_id: updateData.order_id
        };

        // Filter out undefined values
        Object.keys(cleanUpdateData).forEach(key => 
          cleanUpdateData[key] === undefined && delete cleanUpdateData[key]
        );

        const response = await api.post('/api/pallets/batch/update', {
          palletIds: Array.from(selectedPallets),
          updateData: cleanUpdateData
        });
        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to update pallets');
        }
        // Refresh data and reset selection
        await loadPallets();
      });
      return true;
    } catch (error) {
      return error.message || 'Failed to update pallets';
    }
  };

  const handleBatchAssign = async (orderId) => {
    try {
      await onAsyncAction(async () => {
        const response = await api.post('/api/pallets/batch/assign', {
          palletIds: Array.from(selectedPallets),
          orderId
        });
        if (!response.data?.ok) {
          throw new Error(response.data?.message || 'Failed to assign pallets to order');
        }
        // Refresh data and reset selection
        await loadPallets();
      });
      return true;
    } catch (error) {
      return error.message || 'Failed to assign pallets to order';
    }
  };

  return {
    handleBatchShip,
    handleBatchDelete,
    handleBatchUpdate,
    handleBatchAssign
  };
};

export default BatchActionsManager;

import { useState, useCallback, useEffect } from 'react';
import { api } from '@lib/api';

export const useKleinpakOrderAssignment = ({ 
  onAssign, 
  onClose, 
  palletDetails
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [processingData, setProcessingData] = useState({
    progressPercentage: 0,
    processedPallets: 0,
    tomatoTally: {}
  });
  const [isAssigning, setIsAssigning] = useState(false);
  
  // Set up SSE connection and fetch initial data
  useEffect(() => {
    if (!selectedOrder || !selectedOrder.id) {
      setProcessingData({
        progressPercentage: 0,
        processedPallets: 0,
        tomatoTally: {}
      });
      return;
    }

    fetchOrderProgress();

    // Set up SSE connection for real-time updates
    // We need the location ID for the SSE connection
    // Temporarily use localStorage to get location ID if available
    const locationId = localStorage.getItem('currentLocationId') || '1'; // Default to 1 if not set
    const eventSource = new EventSource(`/api/sse/location/${locationId}`);
    
    // Listen for connection established
    eventSource.addEventListener('connected', (event) => {
      console.log('KleinpakOrderAssignment SSE connection established:', JSON.parse(event.data));
    });
    
    // Listen for error events
    eventSource.addEventListener('error', (event) => {
      console.error('KleinpakOrderAssignment SSE connection error:', event);
    });
    
    // Listen specifically for 'update' events
    eventSource.addEventListener('update', (event) => {
      const data = JSON.parse(event.data);
      console.log('KleinpakOrderAssignment received SSE update event:', data);
      
      // Refresh data when our order is updated
      if (data.type === 'ORDER_UPDATE' && data.data && data.data.orders) {
        // Check if any of the orders in the update match our selected order
        const orderUpdated = data.data.orders.some(order => 
          order.id && selectedOrder && order.id.toString() === selectedOrder.id.toString()
        );
        
        if (orderUpdated) {
          console.log('Matched order update in KleinpakOrderAssignment, refreshing for order:', selectedOrder.id);
          fetchOrderProgress();
        }
      }
      
      // Also refresh when pallets are assigned to our order
      if (data.type === 'ASSIGNMENT_UPDATE' && data.data && data.data.orderId && selectedOrder) {
        // Make sure to compare as strings
        const eventOrderId = String(data.data.orderId);
        const currentOrderId = String(selectedOrder.id);
        
        if (eventOrderId === currentOrderId) {
          console.log('Matched assignment update in KleinpakOrderAssignment for order:', selectedOrder.id);
          fetchOrderProgress();
        }
      }
    });

    // Clean up SSE connection
    return () => {
      eventSource.close();
    };
  }, [selectedOrder]);

  // Fetch initial data when order is selected
  useEffect(() => {
    if (!selectedOrder?.id) {
      setProcessingData({
        progressPercentage: 0,
        processedPallets: 0,
        tomatoTally: {}
      });
      return;
    }
    
    fetchOrderProgress();
  }, [selectedOrder?.id]);

  const validateProcessingData = useCallback(() => {
    // No validation needed for our simplified model,
    // but we keep the function for compatibility
    return null;
  }, []);

  const fetchOrderProgress = async () => {
    if (!selectedOrder?.id) return;
    
    try {
      // Fetch all pallets assigned to this order
      const response = await api.get(`/api/orders/${selectedOrder.id}/pallets`);
      
      // Ensure we have an array to work with - handle both formats
      const processedPalletsData = Array.isArray(response.data.data) 
        ? response.data.data 
        : (Array.isArray(response.data) ? response.data : []);
      const processedPallets = processedPalletsData.length || 0;
      
      // Get processing count
      const processingCount = processedPalletsData.filter(
        pallet => pallet.processStage === 'processing'
      ).length;

      // Calculate progress based on output pallets only with 0.75 ratio
      const progressPercentage = Math.min(
        (processedPallets / selectedOrder.total_pallets) * 100 * 0.75, 
        100
      );
      
      // Create tally of tomato types
      const tomatoTally = {};
      if (Array.isArray(processedPalletsData)) {
        processedPalletsData.forEach(pallet => {
          const type = pallet.tomatoType || 'Unknown';
          tomatoTally[type] = (tomatoTally[type] || 0) + 1;
        });
      }
      
      setProcessingData({
        progressPercentage,
        processedPallets,
        processingPallets: processingCount,
        tomatoTally
      });
    } catch (error) {
      console.error('Error fetching order progress:', error);
      setProcessingData({
        progressPercentage: 0,
        processedPallets: 0,
        tomatoTally: {}
      });
    }
  };


  const handleAssign = async () => {
    // Check if we have everything we need
    if (!selectedOrder || !selectedOrder.id) {
      console.error('No order selected');
      return;
    }
    
    if (!palletDetails || !palletDetails.id) {
      console.error('Invalid pallet details');
      return;
    }
    
    if (isAssigning) {
      return; // Prevent multiple submissions
    }
    
    setIsAssigning(true);
    
    try {
      console.log('Starting Kleinpak order assignment with:', {
        orderId: selectedOrder.id,
        palletId: palletDetails.id
      });
      
      // Call the onAssign callback if provided
      if (typeof onAssign === 'function') {
        // We pass the processing data without weights since we're no longer using those
      await onAssign(selectedOrder.id, {
        processingLine: selectedOrder.processingLine,
        tomatoType: palletDetails.tomatoType,
        assignedAt: new Date().toISOString(),
        processStage: "processing"  // Updated to consistent lowercase value
      }, palletDetails);
      }
      
      // Refresh the progress data after successful assignment
      await fetchOrderProgress();
      
      // Close the modal on success
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      console.error('Error in handleAssign:', error);
    } finally {
      setIsAssigning(false);
    }
  };

  const resetForm = useCallback(() => {
    setSelectedOrder(null);
    setProcessingData({
      progressPercentage: 0,
      processedPallets: 0,
      tomatoTally: {}
    });
  }, []);

  return {
    selectedOrder,
    setSelectedOrder,
    processingData,
    handleAssign,
    resetForm,
    isAssigning,
    validateProcessingData
  };
};

export default useKleinpakOrderAssignment;

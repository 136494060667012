/**
 * StorageStateManager.js
 * 
 * Centralized manager for storage state across component lifecycles.
 * This ensures consistent state even when components unmount and remount,
 * and provides fallback mechanisms when real-time updates aren't working.
 */

import { zoneUpdateService, ZONE_UPDATE_EVENTS } from '@features/map/services/zoneUpdateService';
import connectionStateManager from '@lib/services/sse/ConnectionStateManager';

class StorageStateManager {
  constructor() {
    this.zoneOccupancy = {};  // Map of zoneId -> position occupancy data
    this.lastUpdates = {};    // Map of zoneId -> timestamp of last update
    this.listeners = new Map(); // Map of zoneId -> Set of callbacks
    this.isInitialized = false;
    this.refreshInterval = null;
    this.refreshIntervalMs = 30000; // 30 seconds
    this.connectionState = 'UNKNOWN';
    
    console.log('StorageStateManager initialized:', {
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Initialize the state manager and set up event listeners
   */
  initialize() {
    if (this.isInitialized) return;
    
    // Set up connection state monitoring
    connectionStateManager.addStateChangeListener(this.handleConnectionStateChange.bind(this));
    this.connectionState = connectionStateManager.getConnectionState();
    
    // Subscribe to zone update events
    this.setupEventSubscriptions();
    
    // Set up periodic refresh if no real-time updates
    this.startRefreshInterval();
    
    this.isInitialized = true;
    
    console.log('StorageStateManager fully initialized:', {
      connectionState: this.connectionState,
      timestamp: new Date().toISOString()
    });
    
    return this;
  }
  
  /**
   * Handle connection state changes
   * @param {Object} stateChange - The state change data
   */
  handleConnectionStateChange(stateChange) {
    const prevState = this.connectionState;
    this.connectionState = stateChange.currentState;
    
    console.log('Storage connection state changed:', {
      from: prevState,
      to: this.connectionState,
      timestamp: new Date().toISOString()
    });
    
    // If connection was lost and restored, refresh all zones
    if (prevState !== 'CONNECTED' && this.connectionState === 'CONNECTED') {
      console.log('Connection restored, refreshing all zones');
      this.refreshAllZones();
    }
    
    // If connection lost, start more aggressive polling
    if (prevState === 'CONNECTED' && this.connectionState !== 'CONNECTED') {
      console.log('Connection lost, switching to more frequent polling');
      this.startRefreshInterval(true);
    }
  }
  
  /**
   * Start periodic refresh interval
   * @param {boolean} aggressive - Whether to use more aggressive polling
   */
  startRefreshInterval(aggressive = false) {
    // Clear any existing interval
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    
    // Set appropriate interval based on connection state and aggressive flag
    const interval = aggressive ? 15000 : this.refreshIntervalMs;
    
    // Start new interval
    this.refreshInterval = setInterval(() => {
      // Only aggressively poll if not connected
      if (this.connectionState !== 'CONNECTED' || aggressive) {
        this.refreshAllZones();
      }
    }, interval);
    
    console.log('Refresh interval started:', {
      intervalMs: interval,
      aggressive,
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Set up SSE event subscriptions
   */
  setupEventSubscriptions() {
    // Update zone data when events occur
    const handleZoneUpdate = (event) => {
      if (!event) return;
      
      const affectedZoneId = event.zoneId || event.toZoneId || event.fromZoneId || event.storageZone;
      
      if (affectedZoneId) {
        console.log(`StorageStateManager: Event affecting zone ${affectedZoneId}`, {
          eventType: event.type || 'unknown',
          timestamp: new Date().toISOString()
        });
        
        // Refresh the affected zone
        this.refreshZone(affectedZoneId);
      }
    };
    
    // Subscribe to all relevant update events
    zoneUpdateService.subscribe(ZONE_UPDATE_EVENTS.PALLET_ADDED, handleZoneUpdate);
    zoneUpdateService.subscribe(ZONE_UPDATE_EVENTS.PALLET_REMOVED, handleZoneUpdate);
    zoneUpdateService.subscribe(ZONE_UPDATE_EVENTS.PALLET_MOVED, handleZoneUpdate);
    zoneUpdateService.subscribe(ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, handleZoneUpdate);
    
    // Subscribe to error events to handle retries
    zoneUpdateService.subscribe(ZONE_UPDATE_EVENTS.SERVICE_ERROR, (error) => {
      console.warn('StorageStateManager: Service error detected, scheduling refresh', error);
      // On service error, queue a refresh after a short delay
      setTimeout(() => this.refreshAllZones(), 3000);
    });
  }
  
  /**
   * Refresh all tracked zones
   */
  async refreshAllZones() {
    console.log('StorageStateManager: Refreshing all zones', {
      zoneCount: Object.keys(this.zoneOccupancy).length,
      timestamp: new Date().toISOString()
    });
    
    const promises = Object.keys(this.zoneOccupancy).map(zoneId => this.refreshZone(zoneId));
    await Promise.allSettled(promises);
  }
  
  /**
   * Force refresh all zones ignoring cache
   */
  async forceRefreshAllZones() {
    console.log('StorageStateManager: Force refreshing all zones', {
      zoneCount: Object.keys(this.zoneOccupancy).length,
      timestamp: new Date().toISOString()
    });
    
    const promises = Object.keys(this.zoneOccupancy).map(zoneId => this.refreshZone(zoneId, true));
    await Promise.allSettled(promises);
  }
  
  /**
   * Refresh a specific zone
   * @param {string} zoneId - The zone ID to refresh
   * @param {boolean} forceRefresh - Whether to force bypass cache
   */
  async refreshZone(zoneId, forceRefresh = false) {
    if (!zoneId) return;
    
    try {
      console.log(`StorageStateManager: ${forceRefresh ? 'Force refreshing' : 'Refreshing'} zone ${zoneId}`);
      
      // Specify force refresh option if needed
      const options = forceRefresh ? { forceRefresh: true, timestamp: Date.now() } : {};
      const zoneOccupancy = await zoneUpdateService.getZoneOccupancy(zoneId, undefined, options);
      
      if (zoneOccupancy && typeof zoneOccupancy === 'object') {
        // Create a new object reference to ensure React detects changes
        this.zoneOccupancy[zoneId] = { ...zoneOccupancy };
        this.lastUpdates[zoneId] = Date.now();
        
        // Notify listeners for this zone
        this.notifyZoneListeners(zoneId, this.zoneOccupancy[zoneId]);
        
        const positions = Object.keys(zoneOccupancy).length;
        console.log(`StorageStateManager: Zone ${zoneId} refreshed`, {
          positions,
          timestamp: new Date().toISOString()
        });
        
        // If we got empty data but we expected positions, schedule another refresh
        if (positions === 0 && !forceRefresh) {
          console.warn(`StorageStateManager: Zone ${zoneId} returned 0 positions, scheduling force refresh`);
          setTimeout(() => this.refreshZone(zoneId, true), 2000);
        }
      }
    } catch (error) {
      console.error(`StorageStateManager: Error refreshing zone ${zoneId}:`, error);
    }
  }
  
  /**
   * Notify listeners for a specific zone
   * @param {string} zoneId - The zone ID
   * @param {Object} zoneData - The zone occupancy data
   */
  notifyZoneListeners(zoneId, zoneData) {
    if (!this.listeners.has(zoneId)) return;
    
    const listeners = this.listeners.get(zoneId);
    
    listeners.forEach(callback => {
      try {
        callback(zoneData);
      } catch (error) {
        console.error(`StorageStateManager: Error in zone listener for ${zoneId}:`, error);
      }
    });
  }
  
  /**
   * Register a component to receive zone updates
   * @param {string} zoneId - The zone ID to track
   * @param {string} componentId - A unique ID for the component
   * @param {function} callback - The callback to call with updated data
   * @param {boolean} forceRefresh - Whether to force refresh data
   * @returns {function} A function to unregister the listener
   */
  registerZoneListener(zoneId, componentId, callback, forceRefresh = false) {
    if (!zoneId || !componentId || !callback) return () => {};
    
    // Create listener key
    const listenerKey = `${componentId}:${zoneId}`;
    
    // Initialize listeners for this zone if needed
    if (!this.listeners.has(zoneId)) {
      this.listeners.set(zoneId, new Map());
    }
    
    // Register the callback
    this.listeners.get(zoneId).set(listenerKey, callback);
    
    console.log(`StorageStateManager: Registered listener for zone ${zoneId}`, {
      componentId,
      forceRefresh,
      timestamp: new Date().toISOString()
    });
    
    // Check if we have recent cached data to immediately return
    if (this.zoneOccupancy[zoneId] && !forceRefresh && this.isZoneDataFresh(zoneId)) {
      // Call the callback with the cached data
      setTimeout(() => {
        callback(this.zoneOccupancy[zoneId]);
      }, 0);
    } else {
      // No cached data or force refresh requested, fetch it
      this.refreshZone(zoneId, forceRefresh);
    }
    
    // Return a function to unregister the listener
    return () => {
      this.unregisterZoneListener(zoneId, componentId);
    };
  }
  
  /**
   * Unregister a component from receiving zone updates
   * @param {string} zoneId - The zone ID
   * @param {string} componentId - The component ID
   */
  unregisterZoneListener(zoneId, componentId) {
    if (!zoneId || !componentId) return;
    
    // Create listener key
    const listenerKey = `${componentId}:${zoneId}`;
    
    // Remove the listener
    if (this.listeners.has(zoneId)) {
      this.listeners.get(zoneId).delete(listenerKey);
      
      // Clean up empty listener maps
      if (this.listeners.get(zoneId).size === 0) {
        this.listeners.delete(zoneId);
      }
      
      console.log(`StorageStateManager: Unregistered listener for zone ${zoneId}`, {
        componentId,
        timestamp: new Date().toISOString()
      });
    }
  }
  
  /**
   * Get the current occupancy data for a zone
   * @param {string} zoneId - The zone ID
   * @returns {Object} The zone occupancy data, or empty object if not available
   */
  getZoneOccupancy(zoneId) {
    return this.zoneOccupancy[zoneId] || {};
  }
  
  /**
   * Get the last update timestamp for a zone
   * @param {string} zoneId - The zone ID
   * @returns {number} The timestamp in milliseconds, or 0 if not available
   */
  getLastUpdateTimestamp(zoneId) {
    return this.lastUpdates[zoneId] || 0;
  }
  
  /**
   * Check if a zone has been updated recently
   * @param {string} zoneId - The zone ID
   * @param {number} maxAgeMs - Maximum age in milliseconds
   * @returns {boolean} True if zone data is fresh, false otherwise
   */
  isZoneDataFresh(zoneId, maxAgeMs = 60000) {
    const lastUpdate = this.getLastUpdateTimestamp(zoneId);
    if (!lastUpdate) return false;
    
    const age = Date.now() - lastUpdate;
    return age <= maxAgeMs;
  }
  
  /**
   * Get the current connection state
   * @returns {string} The connection state
   */
  getConnectionState() {
    return this.connectionState;
  }
  
  /**
   * Cleanup resources
   */
  destroy() {
    // Clear refresh interval
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
    
    // Clear listeners
    this.listeners.clear();
    
    // Clear cached data
    this.zoneOccupancy = {};
    this.lastUpdates = {};
    
    this.isInitialized = false;
    
    console.log('StorageStateManager destroyed:', {
      timestamp: new Date().toISOString()
    });
  }
}

// Create and initialize singleton instance
const storageStateManager = new StorageStateManager().initialize();

export default storageStateManager;

import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const SelectLabel = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.inverse};
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  padding-right: ${({ theme }) => theme.spacing.xl};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  appearance: none;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.main};
  }
  
  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darker};
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const SelectArrow = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.md};
  transform: translateY(-50%);
  pointer-events: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  &::before {
    content: '';
    display: block;
    width: 0.6em;
    height: 0.6em;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: rotate(45deg);
  }
`;

export const LocationOption = styled.option`
  padding: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
`;

export const NoLocationsMessage = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme }) => theme.colors.text.inverse};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  text-align: center;
`;


import { api } from '@lib/api';
import { palletBoxConfigService } from '../../../services/palletBoxConfigService';

export const fetchLeaderboard = async () => {
  try {
    const response = await api.get('/api/users/leaderboard');
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch leaderboard data');
  }
};

export const fetchMapData = async (locationId) => {
  try {
    const response = await api.get(`/api/map/${locationId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch map data');
  }
};

export const getColorForValue = (value, filterRanges) => {
  if (value === null) return 'white';
  
  const { light, middle, red } = filterRanges;

  if (value >= light.min) return `rgb(144, 238, 144)`;
  if (value >= middle.min) return `rgb(255, 165, 0)`;
  if (value >= red.min) return `rgb(255, 0, 0)`;

  const gradientPercent = (value - red.min) / (red.max - red.min);
  const r = Math.floor(255 * gradientPercent);
  return `rgb(${r}, 0, 0)`;
};

export const fetchStatistics = async (locationId, timeFrame) => {
  try {
    const startDate = getStartDate(timeFrame);
    const response = await api.get(`/api/pallets/stats/${locationId}`, {
      params: { startDate: startDate.toISOString() }
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch statistics');
  }
};

export const fetchFilterRanges = async (locationId) => {
  try {
    const response = await api.get(`/api/settings/${locationId}`);
    return response.data.filterRanges;
  } catch (error) {
    throw new Error('Failed to fetch filter ranges');
  }
};

const getStartDate = (timeFrame) => {
  const now = new Date();
  switch (timeFrame) {
    case 'week':
      return new Date(now.setDate(now.getDate() - 7));
    case 'month':
      return new Date(now.setMonth(now.getMonth() - 1));
    default: // today
      return new Date(now.setHours(0, 0, 0, 0));
  }
};

export const calculateTotalBoxes = (palletType, boxType, layers, additionalBoxes = 0) => {
  return palletBoxConfigService.calculateTotalBoxes(palletType, boxType, layers, additionalBoxes);
};

export const getMaxLayers = (palletType, boxType) => {
  return palletBoxConfigService.getMaxLayers(palletType, boxType);
};

export const addPalletToLocation = async (palletData) => {
  try {
    console.log('OperatorService: addPalletToLocation called with', {
      ...palletData,
      fullData: JSON.stringify(palletData, null, 2)
    });
    
    // Extract storage-related data, but preserve sorting grade and process stage
    const { zoneId, zoneName, position, rowIndex, colIndex, sortingGrade, processStage, ...otherData } = palletData;
    
    // Validate position is present
    if (!position) {
      console.error('OperatorService: No position provided for pallet');
      throw new Error('Position is required for pallet creation');
    }

    // Create basic pallet data with sorting grade, process stage, and storage zone
    const basicPalletData = {
      ...otherData,
      sortingGrade,
      processStage,
      position: String(position).trim(), // Ensure position is a trimmed string
      storageZone: zoneId ? `${zoneId}-row-${rowIndex || 0}` : null // Set proper storage zone format immediately
    };

    console.log('OperatorService: Created basic pallet data', {
      position,
      sortingGrade,
      processStage,
      basicPalletData: JSON.stringify(basicPalletData, null, 2)
    });
    
    // Create a transaction ID to track this operation
    const transactionId = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
    
    // Create the initial pallet without storage assignment
    const createPayload = {
      ...basicPalletData,
      position,
      transactionId
    };

    // Debug log the payload
    console.log('OperatorService: Creating pallet with payload:', {
      sortingGrade: createPayload.sortingGrade,
      processStage: createPayload.processStage,
      fullPayload: createPayload
    });

    // Create the pallet first
    const response = await api.post('/api/pallets/add', createPayload);
    
    console.log('OperatorService: API response from pallet creation', response);
    
    // Get the created pallet data
    let createdPallet = response.data;
    if (Array.isArray(response.data?.data)) {
      createdPallet = response.data.data[0];
    } else if (response.data?.data) {
      createdPallet = response.data.data;
    }

    // If we have zone information, update the created pallet with zone info
    if (zoneId) {
      createdPallet = {
        ...createdPallet,
        zoneId,
        zoneName,
        storageZone: `${zoneId}-row-${rowIndex || 0}`,
        rowIndex,
        colIndex,
        sortingGrade: basicPalletData.sortingGrade,
        processStage: basicPalletData.processStage,
        tomatoType: basicPalletData.tomatoType,
        tomatoOption: basicPalletData.tomatoOption
      };
    }
    
    // Save the pattern for future suggestions
    if (basicPalletData.user_id) {
      try {
        console.log('OperatorService: Saving assignment pattern', {
          palletId: createdPallet.id,
          position,
          zoneId,
          rowIndex: rowIndex || 0,
          colIndex: colIndex || 0,
          userId: basicPalletData.user_id,
          sortingGrade,
          processStage
        });

        const { default: palletAssignmentHistoryService } = await import('@features/map/services/palletAssignmentHistoryService');
        const savedPattern = await palletAssignmentHistoryService.saveAssignmentPattern(
          {
            ...createdPallet,
            position, // Ensure position is included
            sortingGrade,
            processStage
          },
          zoneId,
          rowIndex || 0,
          colIndex || 0,
          basicPalletData.user_id
        );

        console.log('OperatorService: Assignment pattern saved', {
          pattern: savedPattern,
          palletId: createdPallet.id,
          position
        });
      } catch (historyError) {
        console.warn('Failed to save assignment pattern:', {
          error: historyError,
          palletId: createdPallet.id,
          position,
          zoneId,
          userId: basicPalletData.user_id
        });
        // Non-critical error, don't throw
      }
    } else {
      console.log('OperatorService: Skipping pattern save - no user ID', {
        palletId: createdPallet.id,
        position,
        userId: basicPalletData.user_id
      });
    }
    
    console.log('OperatorService: Returning final pallet data', createdPallet);
    return createdPallet;
  } catch (error) {
    console.error('OperatorService: Error in addPalletToLocation', error);
    
    // Attempt cleanup if we have a transaction ID
    if (error.transactionId) {
      try {
        await api.post('/api/pallets/rollback', { transactionId: error.transactionId });
      } catch (cleanupError) {
        console.error('Failed to cleanup failed transaction:', cleanupError);
      }
    }
    
    throw new Error(`Failed to add pallet: ${error.message}`);
  }
};

export const deletePallet = async (palletId) => {
  try {
    await api.delete(`/api/pallets/remove/${palletId}`);
  } catch (error) {
    throw new Error(`Failed to delete pallet: ${error.message}`);
  }
};

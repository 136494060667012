import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getPalletPositionColor } from '../constants/colors/zoneColors';
import { calculateZoneCapacity } from '../constants/zoneConfig.v2';
import { zoneUpdateService, ZONE_UPDATE_EVENTS } from '../services/zoneUpdateService';
import connectionStateManager from '@lib/services/sse/ConnectionStateManager';
import storageStateManager from '@lib/services/storage/StorageStateManager';

const GridContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  overflow: hidden;
  max-width: 100%;
`;

const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const GridTitle = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 600;
`;

const BackButton = styled.button`
  background: ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.8rem;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  margin: 0 auto;
  max-width: 100%;
  overflow: auto;
`;

const GridRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const PalletPosition = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ theme, $isEmpty, $tomatoType, $ripeness }) => {
    if ($isEmpty) {
      return theme.colors.background.paper;
    } else {
      // Use bold red for occupied positions to enhance visibility
      return '#e63946'; // Red color for occupied positions
    }
  }};
  border: 2px solid ${({ theme, $isSelected }) => 
    $isSelected 
      ? theme.colors.primary.main
      : theme.colors.border.light
  };
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme, $isEmpty }) => 
    $isEmpty 
      ? 'none'
      : theme.shadows.xs
  };
  
  &:hover {
    transform: ${({ $isEmpty }) => $isEmpty ? 'none' : 'scale(1.05)'};
    box-shadow: ${({ theme, $isEmpty }) => 
      $isEmpty 
        ? theme.shadows.xs
        : theme.shadows.sm
    };
    z-index: 1;
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

const PalletId = styled.span`
  font-size: 0.7rem;
  font-weight: 600;
  color: ${({ theme, $isEmpty }) => 
    $isEmpty 
      ? theme.colors.text.disabled
      : theme.colors.text.inverse
  };
`;

const PalletTooltip = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacing.sm};
  box-shadow: ${({ theme }) => theme.shadows.md};
  z-index: 2;
  min-width: 150px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  pointer-events: none;
  
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.border.light} transparent transparent transparent;
  }
`;

const TooltipContent = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text.primary};
  
  p {
    margin: 0.2rem 0;
  }
  
  .label {
    font-weight: 600;
    margin-right: 0.3rem;
  }
`;

const ZoneInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ZoneData = styled.div`
  flex: 1;
  min-width: 200px;
`;

const DataRow = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  
  .label {
    font-weight: 600;
    margin-right: ${({ theme }) => theme.spacing.sm};
    min-width: 120px;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  
  .value {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const StatusBlock = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.background.accent};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  .title {
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  
  .content {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const PalletCount = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme, $isEmpty }) => 
    $isEmpty 
      ? theme.colors.background.accent
      : theme.colors.primary.alpha
  };
  padding: 2px 5px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: 0.7rem;
  color: ${({ theme, $isEmpty }) => 
    $isEmpty 
      ? theme.colors.text.secondary
      : theme.colors.primary.main
  };
`;

const ConnectionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  font-size: 0.8rem;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  background: ${({ theme, $status }) => {
    switch ($status) {
      case 'CONNECTED':
        return theme.colors.success.alpha;
      case 'CONNECTING':
        return theme.colors.warning.alpha;
      case 'DISCONNECTED':
      case 'ERROR':
      case 'AUTH_ERROR':
      case 'STALE':
        return theme.colors.error.alpha;
      default:
        return theme.colors.background.accent;
    }
  }};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme, $status }) => {
    switch ($status) {
      case 'CONNECTED':
        return theme.colors.success.dark;
      case 'CONNECTING':
        return theme.colors.warning.dark;
      case 'DISCONNECTED':
      case 'ERROR':
      case 'AUTH_ERROR':
      case 'STALE':
        return theme.colors.error.dark;
      default:
        return theme.colors.text.secondary;
    }
  }};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

const RefreshButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  margin-left: auto;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.alpha};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StatusIndicator = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'CONNECTED':
        return theme.colors.success.main;
      case 'CONNECTING':
        return theme.colors.warning.main;
      case 'DISCONNECTED':
      case 'ERROR':
      case 'AUTH_ERROR':
      case 'STALE':
        return theme.colors.error.main;
      default:
        return theme.colors.gray[400];
    }
  }};
`;

// Main component
const ZoneGridView = ({ zone, onBack, palletData = {}, onPositionSelect, selectedPosition: propSelectedPosition }) => {
  const [selectedPositionState, setSelectedPositionState] = useState(null);
  const [hoveredPosition, setHoveredPosition] = useState(null);
  const [occupancy, setOccupancy] = useState(palletData);
  const [connectionState, setConnectionState] = useState('UNKNOWN');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(Date.now());
  
  // Ref to track if component is mounted
  const isMounted = useRef(true);
  // Ref to store polling interval
  const pollIntervalRef = useRef(null);
  // Store the last connection state to detect changes
  const lastConnectionStateRef = useRef(connectionState);
  
  // Use passed in selected position if provided (for controlled component)
  const selectedPosition = propSelectedPosition ? 
    `${propSelectedPosition.rowIndex}-${propSelectedPosition.colIndex}` : 
    selectedPositionState;
  
  const capacity = calculateZoneCapacity(zone);
  const occupiedPositions = Object.keys(occupancy).length;
  const emptyPositions = capacity - occupiedPositions;
  
  // Function to fetch and update zone occupancy data
  const fetchOccupancy = async (force = false) => {
    if (!zone?.id || (!force && isRefreshing)) return;
    
    setIsRefreshing(true);
    
    try {
      console.log(`ZoneGridView: ${force ? 'Force refreshing' : 'Fetching'} occupancy data for zone ${zone.id}`);
      
      // Use StorageStateManager to refresh the zone data with force refresh option if requested
      await storageStateManager.refreshZone(zone.id, force);
      
      // No need to update state here as our StorageStateManager listener will handle it
    } catch (error) {
      console.error(`Error fetching occupancy for zone ${zone.id}:`, error);
      
      // If we encounter an error, try to manually update state to prevent UI from being stuck
      if (isMounted.current) {
        setIsRefreshing(false);
      }
    }
  };

  // Replace old logic with StorageStateManager-based implementation
  useEffect(() => {
    // Generate a unique component ID for this instance
    const componentId = `ZoneGridView_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
    isMounted.current = true;
    
    // Only register if we have a valid zone
    if (!zone?.id) return;
    
    console.log(`ZoneGridView: Registering with StorageStateManager for zone ${zone.id}`);
    
    // Keep track of last manual refresh
    const handleManualRefresh = () => {
      if (isMounted.current) {
        setLastRefreshTime(Date.now());
      }
    };
    
    // Determine if we should force refresh on mount
    // Force refresh in ~20% of cases or if previous data is empty or stale
    const shouldForceRefresh = () => {
      const previousData = storageStateManager.getZoneOccupancy(zone.id);
      const lastUpdateTime = storageStateManager.getLastUpdateTimestamp(zone.id);
      const isStale = Date.now() - lastUpdateTime > 60000; // 1 minute
      const isEmpty = Object.keys(previousData).length === 0;
      const randomRefresh = Math.random() < 0.2; // 20% chance
      
      return isEmpty || isStale || randomRefresh;
    };
    
    // Register with the storage state manager to receive zone updates
    const unregisterListener = storageStateManager.registerZoneListener(
      zone.id,
      componentId,
      (zoneOccupancy) => {
        if (!isMounted.current) return;
        
        console.log(`ZoneGridView: Received zone update from StorageStateManager for zone ${zone.id}`);
        
        // Update state with new zone data
        setOccupancy(zoneOccupancy);
        setLastRefreshTime(storageStateManager.getLastUpdateTimestamp(zone.id));
        setIsRefreshing(false);
        
        // Handle manual refresh callback
        handleManualRefresh();
      },
      shouldForceRefresh() // Pass force refresh parameter
    );
    
    // Use the connection state from the state manager
    setConnectionState(storageStateManager.getConnectionState());
    
    // Add connection state listener
    const unsubscribeConnection = connectionStateManager.addStateChangeListener((state) => {
      if (!isMounted.current) return;
      
      setConnectionState(state.currentState);
      lastConnectionStateRef.current = state.currentState;
    });
    
    // If we have initial data, populate the state
    const initialData = storageStateManager.getZoneOccupancy(zone.id);
    if (Object.keys(initialData).length > 0) {
      setOccupancy(initialData);
      setLastRefreshTime(storageStateManager.getLastUpdateTimestamp(zone.id));
    } else if (Object.keys(palletData).length > 0) {
      // If we have pallet data passed as props, use that
      setOccupancy(palletData);
    }
    
    // Cleanup on unmount
    return () => {
      isMounted.current = false;
      unregisterListener();
      unsubscribeConnection();
    };
  }, [zone?.id, palletData]);
  
  const handlePositionClick = (rowIndex, colIndex) => {
    const position = `${rowIndex}-${colIndex}`;
    
    // If this is being used as a controlled component
    if (propSelectedPosition && onPositionSelect) {
      onPositionSelect(rowIndex, colIndex);
    } else {
      // Uncontrolled component behavior
      setSelectedPositionState(position === selectedPosition ? null : position);
    }
  };

  const handlePositionMouseEnter = (rowIndex, colIndex) => {
    setHoveredPosition(`${rowIndex}-${colIndex}`);
  };

  const handlePositionMouseLeave = () => {
    setHoveredPosition(null);
  };
  
  // Helper function to determine if a position is occupied
  const isPositionOccupied = (rowIndex, colIndex) => {
    const positionKey = `${rowIndex}-${colIndex}`;
    return occupancy.hasOwnProperty(positionKey);
  };
  
  // Helper function to get pallet data for a position
  const getPalletDataForPosition = (rowIndex, colIndex) => {
    const positionKey = `${rowIndex}-${colIndex}`;
    return occupancy[positionKey] || null;
  };

  return (
    <GridContainer>
      <GridHeader>
        <GridTitle>
          {zone.name} Grid View
          <PalletCount $isEmpty={occupiedPositions === 0}>
            {occupiedPositions} / {capacity} pallets
          </PalletCount>
        </GridTitle>
        <BackButton onClick={onBack}>
          <span>←</span> Back to Map
        </BackButton>
      </GridHeader>
      
      <ZoneInfo>
        <ZoneData>
          <DataRow>
            <span className="label">Zone ID:</span>
            <span className="value">{zone.id}</span>
          </DataRow>
          <DataRow>
            <span className="label">Dimensions:</span>
            <span className="value">{zone.dimensions.rows} rows × {zone.dimensions.cols} columns</span>
          </DataRow>
          <DataRow>
            <span className="label">Capacity:</span>
            <span className="value">{capacity} pallet positions</span>
          </DataRow>
          <DataRow>
            <span className="label">Section:</span>
            <span className="value">{zone.section.charAt(0).toUpperCase() + zone.section.slice(1)}</span>
          </DataRow>
          
          {/* Add last updated and connection status info */}
          <DataRow>
            <span className="label">Last Updated:</span>
            <span className="value">
              {new Date(lastRefreshTime).toLocaleTimeString()}
              <RefreshButton 
                onClick={() => fetchOccupancy(true)} 
                disabled={isRefreshing}
              >
                {isRefreshing ? "Refreshing..." : "Force Refresh"}
              </RefreshButton>
            </span>
          </DataRow>
        </ZoneData>
      </ZoneInfo>
      
      {/* Connection status indicator */}
      <ConnectionStatus $status={connectionState}>
        <StatusIndicator $status={connectionState} />
        <span>
          {connectionState === 'CONNECTED' 
            ? 'Real-time updates active' 
            : connectionState === 'CONNECTING'
              ? 'Connecting to real-time updates...'
              : 'Real-time updates unavailable - using manual refresh'}
        </span>
      </ConnectionStatus>
      
      <GridWrapper>
        {Array.from({ length: zone.dimensions.rows }).map((_, rowIndex) => (
          <GridRow key={`row-${rowIndex}`}>
              {Array.from({ length: zone.dimensions.cols }).map((_, colIndex) => {
              const positionKey = `${rowIndex}-${colIndex}`;
              const isOccupied = isPositionOccupied(rowIndex, colIndex);
              const palletInfo = getPalletDataForPosition(rowIndex, colIndex);
              const isSelected = selectedPosition === positionKey;
              const isHovered = hoveredPosition === positionKey;
              
              return (
                <PalletPosition
                  key={`position-${rowIndex}-${colIndex}`}
                  $isEmpty={!isOccupied}
                  $isSelected={isSelected}
                  $tomatoType={palletData?.type}
                  $ripeness={palletData?.ripeness}
                  onClick={() => handlePositionClick(rowIndex, colIndex)}
                  onMouseEnter={() => handlePositionMouseEnter(rowIndex, colIndex)}
                  onMouseLeave={handlePositionMouseLeave}
                  tabIndex={0}
                  role="button"
                  aria-label={isOccupied 
                    ? `Pallet position ${rowIndex + 1}-${colIndex + 1}, occupied by pallet ${palletData?.id}` 
                    : `Empty pallet position ${rowIndex + 1}-${colIndex + 1}`
                  }
                >
                  <PalletId $isEmpty={!isOccupied}>
                    {isOccupied ? palletInfo.id.substring(0, 4) : `${rowIndex+1},${colIndex+1}`}
                  </PalletId>
                  
                  <PalletTooltip visible={isHovered}>
                    <TooltipContent>
                      <p>
                        <span className="label">Position:</span>
                        {rowIndex + 1},{colIndex + 1}
                      </p>
                      {isOccupied ? (
                        <>
                          <p>
                          <span className="label">Pallet ID:</span>
                          {palletInfo.id}
                        </p>
                        <p>
                          <span className="label">Type:</span>
                          {palletInfo.type}
                        </p>
                        <p>
                          <span className="label">Status:</span>
                          {palletInfo.status}
                        </p>
                        {palletInfo.customer && (
                            <p>
                          <span className="label">Customer:</span>
                          {palletInfo.customer}
                            </p>
                          )}
                        </>
                      ) : (
                        <p>Empty Position</p>
                      )}
                    </TooltipContent>
                  </PalletTooltip>
                </PalletPosition>
              );
            })}
          </GridRow>
        ))}
      </GridWrapper>
    </GridContainer>
  );
};

export default ZoneGridView;

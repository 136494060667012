import React, { useState } from 'react';
import { 
  Container, 
  Header, 
  Title, 
  CloseButton, 
  Content, 
  Form, 
  TextArea, 
  ButtonGroup, 
  CancelButton, 
  SubmitButton 
} from './styles';
import { X, MessageSquare, Check, X as XIcon } from 'lucide-react';

export const InterventionForm = ({ eventId, onSubmit, onCancel }) => {
  const [notes, setNotes] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!notes.trim()) return;
    
    setIsSubmitting(true);
    
    try {
      await onSubmit(notes);
    } catch (error) {
      console.error('Error submitting intervention:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <Header>
        <Title>
          <MessageSquare size={18} />
          Add Intervention
        </Title>
        <CloseButton onClick={onCancel}>
          <X size={18} />
        </CloseButton>
      </Header>
      
      <Content>
        <Form onSubmit={handleSubmit}>
          <TextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter your notes or intervention details here..."
            rows={8}
            required
            disabled={isSubmitting}
          />
          
          <ButtonGroup>
            <CancelButton 
              type="button" 
              onClick={onCancel}
              disabled={isSubmitting}
            >
              <XIcon size={16} />
              Cancel
            </CancelButton>
            
            <SubmitButton 
              type="submit"
              disabled={!notes.trim() || isSubmitting}
            >
              <Check size={16} />
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </ButtonGroup>
        </Form>
      </Content>
    </Container>
  );
};

import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { usePermission } from '@lib/contexts';
import NotAuthorized from '@features/shared/components/NotAuthorized';

/**
 * Validates the authentication data from localStorage
 * @returns {boolean} True if authentication data is valid, false otherwise
 */
const validateAuthData = () => {
  const token = localStorage.getItem('token');
  const userData = localStorage.getItem('userData');
  
  // If no token, definitely not authenticated
  if (!token) {
    return false;
  }
  
  // If token exists but no userData, clear token and return false
  if (!userData) {
    localStorage.removeItem('token');
    return false;
  }
  
  // Validate user data
  try {
    const parsedUser = JSON.parse(userData);
    if (!parsedUser || !parsedUser.id || !parsedUser.username) {
      // Invalid user data, clear everything
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      return false;
    }
    return true;
  } catch (e) {
    // JSON parsing error, clear everything
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    return false;
  }
};

/**
 * A protected route component for desktop client
 * Following our new permission model: Access is allowed by default unless explicitly restricted
 * 
 * @param {Object} props
 * @param {string} props.permission - Optional specific permission needed for this route (legacy support)
 * @param {string} props.restriction - Optional specific restriction that would block access to this route
 * @param {React.ComponentType} props.component - The component to render if authorized
 * @returns {React.ReactNode}
 */
const ProtectedDesktopRoute = ({ 
  permission = null, 
  restriction = null, 
  component: Component, 
  ...rest 
}) => {
  const { hasPermission, hasRestriction, hasClientAccess, loading } = usePermission();
  const navigate = useNavigate();
  
  // Check for valid authentication
  useEffect(() => {
    if (!validateAuthData()) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  // Don't render anything while permissions are loading
  if (loading) {
    return <div>Loading permissions...</div>;
  }
  
  // Double-check authentication before proceeding
  if (!validateAuthData()) {
    return <Navigate to="/login" replace />;
  }

  // Check if user has access to desktop client
  if (!hasClientAccess('desktop')) {
    return (
      <NotAuthorized 
        message="You do not have access to the desktop client. Access is restricted by an administrator."
      />
    );
  }
  
  // If a specific restriction is specified, check it
  if (restriction && hasRestriction(restriction)) {
    return (
      <NotAuthorized 
        message={`You do not have access to this feature. Access is restricted by an administrator.`}
      />
    );
  }

  // If a specific permission is required (legacy support), check it
  if (permission && !hasPermission(permission)) {
    return (
      <NotAuthorized 
        message={`You do not have the required permission to access this feature. Please contact your administrator.`}
      />
    );
  }

  // User has permission to view this component (not restricted)
  return <Component {...rest} />;
};

export default ProtectedDesktopRoute;

import styled, { keyframes, css } from 'styled-components';

// Animation keyframes
const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
`;

// Compact zone card
export const CompactZoneCard = styled.div`
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 0;
  margin-bottom: 12px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  ${props => props.isCompleted && css`
    animation: ${fadeOut} 0.5s ease forwards;
  `}
`;

export const CompactZoneHeader = styled.div`
  background: ${props => props.isCompleted ? '#e8f5e9' : '#f1f5f9'};
  padding: 8px 12px;
  border-bottom: 1px solid #e2e8f0;
  border-radius: 4px 4px 0 0;
`;

export const RowsContainer = styled.div`
  padding: 8px;
`;

export const RowCard = styled.div`
  background: ${props => props.isCompleted ? '#e8f5e9' : '#f8fafc'};
  border: 1px solid ${props => props.isCompleted ? '#c8e6c9' : '#e2e8f0'};
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    background: ${props => props.isCompleted ? '#d7f0db' : '#f1f5f9'};
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

export const RowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowPalletList = styled.div`
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed ${props => props.isCompleted ? '#c8e6c9' : '#e2e8f0'};
`;

export const PalletItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.9em;
  background: ${props => props.isProcessed ? '#e8f5e9' : '#fee2e2'};
  color: ${props => props.isProcessed ? '#166534' : '#991b1b'};
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: #64748b;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  
  &:hover {
    color: #475569;
  }
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const RowName = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  color: ${props => props.isCompleted ? '#2e7d32' : '#334155'};
  
  svg {
    opacity: 0.7;
  }
`;

export const RowStatus = styled.div`
  font-size: 0.8rem;
  color: ${props => props.isCompleted ? '#2e7d32' : '#64748b'};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const CompletedZoneMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8f5e9;
  color: #2e7d32;
  padding: 8px;
  border-radius: 4px;
  margin: 8px;
  font-weight: 500;
  font-size: 0.8rem;
  border: 1px solid #c8e6c9;
  
  svg {
    margin-right: 8px;
  }
`;

export const ZoneSummary = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ZoneStats = styled.div`
  font-size: 0.9rem;
  color: #495057;
  
  span {
    font-weight: 600;
    color: #0284c7;
  }
`;

export const ShowMoreButton = styled.div`
  padding: 4px 8px;
  font-size: 0.7rem;
  color: #64748b;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
`;

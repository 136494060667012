import axios from 'axios';
import { LOCATION, HARDCODED_CONFIG } from './constants';

const getAuthHeaders = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
});

// Helper to add logs immediately for a more responsive UI
const addLocalLog = (action, status, printerType, protocol, details, labelType, commandLanguage) => {
  const log = {
    timestamp: new Date().toISOString(),
    action,
    status,
    location: LOCATION.NAME,
    printerType,
    protocol,
    details,
    labelType,
    commandLanguage
  };
  
  // Store in localStorage temporarily to ensure logs persist between API calls
  const existingLogs = JSON.parse(localStorage.getItem('printerLogs') || '[]');
  existingLogs.push(log);
  localStorage.setItem('printerLogs', JSON.stringify(existingLogs.slice(-50))); // Keep last 50 logs
  
  return log;
};

export const fetchAwsConfig = async () => {
  try {
    const response = await axios.get(
      `/api/printer-config/location/${LOCATION.ID}/aws-config`,
      getAuthHeaders()
    );
    
    // If we have real data, use it
    if (response.data && Object.keys(response.data).length > 0) {
      return response.data;
    }
    
    // Otherwise fall back to hardcoded values
    return HARDCODED_CONFIG;
  } catch (error) {
    console.error('Error fetching AWS config:', error);
    // Return hardcoded values on error
    return HARDCODED_CONFIG;
  }
};

export const fetchPrinterLogs = async () => {
  try {
    const response = await axios.get('/api/printer-config/logs', getAuthHeaders());
    const apiLogs = Array.isArray(response.data) ? response.data : [];
    
    // Combine with locally stored logs
    const localLogs = JSON.parse(localStorage.getItem('printerLogs') || '[]');
    
    // Return combined and sorted logs
    const allLogs = [...apiLogs, ...localLogs]
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    
    // De-duplicate based on timestamp and details
    const uniqueLogs = [];
    const seen = new Set();
    
    allLogs.forEach(log => {
      const key = `${log.timestamp}-${log.details}`;
      if (!seen.has(key)) {
        seen.add(key);
        uniqueLogs.push(log);
      }
    });
    
    return uniqueLogs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  } catch (error) {
    console.error('Error fetching printer logs:', error);
    // Fall back to locally stored logs
    return JSON.parse(localStorage.getItem('printerLogs') || '[]');
  }
};

export const testPrinterConnection = async (printerType, protocol) => {
  try {
    // Log the attempt
    addLocalLog(
      'CONNECTION_TEST_ATTEMPT', 
      'INFO', 
      printerType, 
      protocol, 
      `Testing ${printerType} connection with ${protocol} protocol`
    );
    
    const response = await axios.post(
      `/api/printer-config/location/${LOCATION.ID}/test-connection`,
      { protocol, printerType },
      getAuthHeaders()
    );
    
    // Log the success
    addLocalLog(
      'CONNECTION_TEST', 
      'SUCCESS', 
      printerType, 
      protocol, 
      response.data.message || 'Connection test successful'
    );
    
    return {
      success: true,
      message: response.data.message || 'Connection test successful'
    };
  } catch (error) {
    console.error(`Error testing ${printerType} connection:`, error);
    
    // Log the failure
    const errorMessage = error.response?.data?.message || 'Connection test failed';
    addLocalLog(
      'CONNECTION_TEST', 
      'FAILURE', 
      printerType, 
      protocol, 
      errorMessage
    );
    
    return {
      success: false,
      message: errorMessage
    };
  }
};

export const printTestLabel = async (printerType, {
  protocol,
  labelType,
  testText,
  commandLanguage,
  rawCommand,
  useRawCommand
}) => {
  try {
    // Log the attempt
    const sendContent = useRawCommand 
      ? `Raw ${commandLanguage} command: ${rawCommand.substring(0, 50)}...` 
      : `${labelType} label with text: ${testText}`;
      
    addLocalLog(
      'PRINT_TEST_ATTEMPT', 
      'INFO', 
      printerType, 
      protocol, 
      `Sending ${sendContent}`,
      labelType,
      commandLanguage
    );
    
    const response = await axios.post(
      `/api/printer-config/location/${LOCATION.ID}/print-test`,
      {
        protocol,
        labelType,
        text: testText,
        printerType,
        commandLanguage,
        rawCommand: useRawCommand ? rawCommand : undefined
      },
      getAuthHeaders()
    );
    
    // Log the success
    addLocalLog(
      'PRINT_TEST', 
      'SUCCESS', 
      printerType, 
      protocol, 
      response.data.message || 'Print test successful',
      labelType,
      commandLanguage
    );
    
    return {
      success: true,
      message: response.data.message || 'Print test successful'
    };
  } catch (error) {
    console.error(`Error printing test label on ${printerType}:`, error);
    
    // Log the failure
    const errorMessage = error.response?.data?.message || 'Printing test failed';
    addLocalLog(
      'PRINT_TEST', 
      'FAILURE', 
      printerType, 
      protocol, 
      errorMessage,
      labelType,
      commandLanguage
    );
    
    return {
      success: false,
      message: errorMessage
    };
  }
};

// Clear local logs - useful for testing
export const clearLocalLogs = () => {
  localStorage.removeItem('printerLogs');
};

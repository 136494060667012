import React from 'react';
import styled from 'styled-components';
import { useUserSession } from '@features/auth/hooks';
import { useToast } from '@lib/contexts';
import { api } from '@lib/api';

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin: 20px;
  &:hover {
    background-color: #0069d9;
  }
`;

const RefreshAdminAuthButton = () => {
  const { user, setUser } = useUserSession();
  const { showToast } = useToast();

  const refreshUserData = async () => {
    try {
      // The axios interceptor will automatically add the token to the request
      // Check if user is logged in
      if (!user || !user.id) {
        showToast({
          message: 'User information not found. Please log in again.',
          variant: 'error'
        });
        return;
      }
      
      console.log('Refreshing user data for logged-in user');
      
      // Get the current user data from the server using the /me endpoint
      const response = await api.get('/api/users/me');
      
      const userData = response.data;
      console.log('Refreshed user data:', userData);
      
      // Update the user data in the context and localStorage
      // Ensure admin flag is preserved with correct type
      const adminValue = userData.admin;
      console.log('Admin value from API:', adminValue, 'Type:', typeof adminValue);
      
      // Normalize the admin flag value (could be 1, true, or '1')
      const normalizedAdmin = adminValue === 1 || adminValue === true || adminValue === '1';
      
      const updatedUser = {
        ...user,
        admin: normalizedAdmin
      };
      
      console.log('Updated user with admin status:', updatedUser);
      
      setUser(updatedUser);
      localStorage.setItem('userData', JSON.stringify(updatedUser));
      
      showToast({
        message: 'User data refreshed successfully. Admin status: ' + 
                 (userData.admin ? 'Enabled' : 'Disabled'),
        variant: 'success'
      });
      
      // Force reload the page to ensure all contexts are updated
      setTimeout(() => {
        window.location.reload();
      }, 1500); // Give the toast time to be seen
    } catch (error) {
      console.error('Error refreshing user data:', error);
      showToast({
        message: 'Error refreshing user data',
        variant: 'error'
      });
    }
  };

  return (
    <Button onClick={refreshUserData}>
      Refresh Admin Permissions
    </Button>
  );
};

export default RefreshAdminAuthButton;

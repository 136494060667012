import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ZoneHighlightMap from '@features/operator/components/KleinpakDashboard/components/ZoneHighlightMap';
import RowGroupedPallets from '@features/operator/components/KleinpakDashboard/components/RowGroupedPallets';
import TabButton from '@lib/components/ui/button/TabButton';
import { Maximize2, Minimize2, Map, List } from 'lucide-react';

const DualViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  padding: 8px 12px;
  background: #f8fafc;
`;

const ContentContainer = styled.div`
  display: flex;
  height: ${props => props.fullHeight ? '500px' : '400px'};
  transition: height 0.3s ease;
`;

const Panel = styled.div`
  flex: ${props => props.expanded ? '1' : `0 0 ${props.width}`};
  overflow: hidden;
  transition: flex 0.3s ease;
  position: relative;
  height: 100%;
  
  ${props => !props.isVisible && `
    display: none;
  `}
  
  ${props => props.hasBorder && `
    border-left: 1px solid #e2e8f0;
  `}
`;

const PanelContent = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: ${props => props.isPadded ? '16px' : '0'};
`;

const ViewControlButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: #f1f5f9;
  }
`;

/**
 * ScheduleStorageRowsDualView Component
 * 
 * This component provides a dual view with both list and map visualization
 * of scheduled storage rows for orders.
 */
const ScheduleStorageRowsDualView = ({ 
  order, 
  scheduledRows = [],
  onRowSelect,
  onZoneClick,
  mapHeight = '400px',
  selectedZone = null,
  selectedRow = null,
}) => {
  // View state: 'dual', 'list', or 'map'
  const [viewMode, setViewMode] = useState('dual');
  
  // State for interaction between views
  const [hoveredZone, setHoveredZone] = useState(null);
  const [activeZone, setActiveZone] = useState(selectedZone);
  const [rowsInActiveZone, setRowsInActiveZone] = useState([]);
  
  // Keep track of panels that are expanded
  const [expandedPanel, setExpandedPanel] = useState(null);
  
  // Update rows in active zone when it changes
  useEffect(() => {
    if (activeZone) {
      const filteredRows = scheduledRows.filter(row => 
        row.zoneName === activeZone || row.zoneId === activeZone
      );
      setRowsInActiveZone(filteredRows);
    } else {
      setRowsInActiveZone([]);
    }
  }, [activeZone, scheduledRows]);
  
  // Handle zone hover from list view
  const handleZoneHover = (zoneName) => {
    setHoveredZone(zoneName);
  };
  
  // Handle zone click from list view
  const handleZoneClick = (zoneName, rows, orderObj) => {
    setActiveZone(zoneName);
    if (onZoneClick) {
      onZoneClick(zoneName, rows, orderObj);
    }
  };
  
  // Handle row click from list or map view
  const handleRowClick = (zoneName, row, orderObj) => {
    if (onRowSelect) {
      onRowSelect(row);
    }
  };
  
  // Handle expand/collapse of panels
  const togglePanelExpansion = (panel) => {
    if (expandedPanel === panel) {
      setExpandedPanel(null);
      setViewMode('dual');
    } else {
      setExpandedPanel(panel);
      setViewMode(panel);
    }
  };
  
  // Determine which panels to show based on view mode
  const showListPanel = viewMode === 'dual' || viewMode === 'list';
  const showMapPanel = viewMode === 'dual' || viewMode === 'map';
  
  return (
    <DualViewContainer>
      <TabsContainer>
        <TabButton 
          active={viewMode === 'dual'} 
          onClick={() => setViewMode('dual')}
          style={{ marginRight: '8px' }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Map size={16} /> + <List size={16} /> Dual View
          </span>
        </TabButton>
        <TabButton 
          active={viewMode === 'list'}
          onClick={() => setViewMode('list')}
          style={{ marginRight: '8px' }}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <List size={16} /> List View
          </span>
        </TabButton>
        <TabButton 
          active={viewMode === 'map'}
          onClick={() => setViewMode('map')}
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <Map size={16} /> Map View
          </span>
        </TabButton>
      </TabsContainer>
      
      <ContentContainer fullHeight={activeZone !== null}>
        {/* List Panel */}
        <Panel 
          width="50%" 
          isVisible={showListPanel}
          expanded={expandedPanel === 'list'}
        >
          <PanelContent isPadded={true}>
            <ViewControlButton 
              onClick={() => togglePanelExpansion('list')}
              title={expandedPanel === 'list' ? "Restore dual view" : "Expand list view"}
            >
              {expandedPanel === 'list' ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </ViewControlButton>
            
            <RowGroupedPallets
              order={{
                ...order,
                scheduled_rows: scheduledRows
              }}
              onZoneClick={handleZoneClick}
              onRowClick={handleRowClick}
            />
          </PanelContent>
        </Panel>
        
        {/* Map Panel */}
        <Panel 
          width="50%" 
          isVisible={showMapPanel}
          hasBorder={showListPanel}
          expanded={expandedPanel === 'map'}
        >
          <PanelContent>
            <ViewControlButton 
              onClick={() => togglePanelExpansion('map')}
              title={expandedPanel === 'map' ? "Restore dual view" : "Expand map view"}
            >
              {expandedPanel === 'map' ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </ViewControlButton>
            
            <ZoneHighlightMap
              highlightedZone={hoveredZone || activeZone}
              focusMode={activeZone ? 'zone' : 'all'}
              highlightedRows={rowsInActiveZone}
              useRowMode={!!activeZone}
              interactive={true}
              customHeight={mapHeight}
            />
          </PanelContent>
        </Panel>
      </ContentContainer>
    </DualViewContainer>
  );
};

export default ScheduleStorageRowsDualView;

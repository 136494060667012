// Zone-specific colors based on the provided legend
const ZONE_SPECIFIC_COLORS = {
  // Customer zones
  'new-green': 'rgb(99, 93, 255)',       // NewGreen
  'bakker': 'rgb(217, 22, 168)',         // Bakker
  'van-gelder-1': 'rgb(252, 148, 50)',   // Van Gelder 1
  'van-gelder-2': 'rgb(0, 194, 168)',    // Van Gelder 2
  'van-gelder-3': 'rgb(109, 177, 255)',  // Van Gelder 3
  
  // Numbered zones
  'zone-1': 'rgb(186, 35, 246)',         // Zone 1
  'zone-2': 'rgb(232, 19, 19)',          // Zone 2
  'zone-3': 'rgb(204, 78, 0)',           // Zone 3
  'zone-4': 'rgb(252, 206, 20)',         // Zone 4
  'zone-5': 'rgb(130, 117, 91)',         // Zone 5
  'zone-6': 'rgb(0, 138, 14)',           // Zone 6
  'zone-7': 'rgb(0, 133, 115)',          // Zone 7
  'zone-7-1': 'rgb(184, 245, 237)',      // Zone 7.1
  'zone-8': 'rgb(16, 113, 229)',         // Zone 8
  'zone-9': 'rgb(147, 145, 255)',        // Zone 9
  'zone-10': 'rgb(255, 128, 223)',       // Zone 10
  'zone-11': 'rgb(254, 112, 112)',       // Zone 11
  'zone-12': 'rgb(255, 227, 66)',        // Zone 12
  'zone-13': 'rgb(203, 194, 163)',       // Zone 13
  'zone-13-1': 'rgb(84, 196, 94)',       // Zone 13.1
  
  // Special zones
  'k1': 'rgb(244, 217, 255)',            // K1
  'k2': 'rgb(222, 222, 255)',            // K2
  'oversorteer': 'rgb(224, 143, 255)',   // Oversorteer
};

export default ZONE_SPECIFIC_COLORS;

/**
 * Analytics Utilities
 * 
 * This module provides utilities for tracking user actions throughout the application.
 * It integrates with the analytics service to log events related to pallets, orders,
 * and other important user interactions.
 */

import { analyticsService } from '../services/analyticsService';

/**
 * Track a generic event
 * @param {string} eventType - The type of event
 * @param {object} eventData - Data related to the event
 * @param {object} user - Current user information
 * @param {string|number} locationId - The location ID
 */
export const trackEvent = async (eventType, eventData, user, locationId) => {
  try {
    await analyticsService.logPalletEvent({
      event_type: eventType,
      user_id: user?.id,
      location_id: locationId,
      ...eventData,
      timestamp: new Date().toISOString()
    });
    console.log(`Analytics tracked: ${eventType}`, eventData);
  } catch (error) {
    console.error(`Error tracking analytics event ${eventType}:`, error);
    // Don't throw - analytics failures shouldn't break the main application flow
  }
};

/**
 * Track pallet-related events
 */
export const trackPalletEvent = async (actionType, palletData, user, locationId) => {
  const eventMap = {
    'add': 'pallet_created',
    'update': 'pallet_updated', 
    'delete': 'pallet_deleted',
    'ship': 'pallet_shipped',
    'return': 'pallet_returned',
    'assign': 'pallet_assigned',
    'split': 'pallet_split',
    'print': 'pallet_label_printed'
  };
  
  const eventType = eventMap[actionType] || `pallet_${actionType}`;
  
  await trackEvent(eventType, {
    pallet_id: palletData.id,
    pallet_data: palletData
  }, user, locationId);
};

/**
 * Track order-related events
 */
export const trackOrderEvent = async (actionType, orderData, user, locationId) => {
  const eventMap = {
    'add': 'order_created',
    'update': 'order_updated',
    'delete': 'order_deleted',
    'ship': 'order_shipped',
    'complete': 'order_completed',
    'priority': 'order_priority_changed'
  };
  
  const eventType = eventMap[actionType] || `order_${actionType}`;
  
  await trackEvent(eventType, {
    order_id: orderData.id,
    order_data: orderData
  }, user, locationId);
};

/**
 * Track storage-related events
 */
export const trackStorageEvent = async (actionType, zoneData, palletData, user, locationId) => {
  const eventMap = {
    'assign': 'storage_assigned',
    'remove': 'storage_removed',
    'move': 'storage_moved'
  };
  
  const eventType = eventMap[actionType] || `storage_${actionType}`;
  
  await trackEvent(eventType, {
    zone_id: zoneData.id,
    zone_data: zoneData,
    pallet_id: palletData.id,
    pallet_data: palletData
  }, user, locationId);
};

/**
 * Initialize analytics tracking for SSE events
 * This should be called when the application starts
 * @param {object} user - Current user information
 * @param {string|number} locationId - The location ID
 */
export const initAnalyticsTracking = (user, locationId) => {
  if (!user || !locationId) {
    console.warn('Cannot initialize analytics tracking without user and locationId');
    return;
  }
  
  console.log('Initializing analytics tracking for SSE events');
  
  // Listen for SSE events through the global window object
  window.addEventListener('sse-event', (event) => {
    const { type, data } = event.detail;
    
    // Track different event types
    switch (type) {
      case 'PALLET_UPDATE':
        trackPalletEvent(data.action, data.pallets[0], user, locationId);
        break;
      case 'ORDER_UPDATE':
        trackOrderEvent(data.action, data.orders[0], user, locationId);
        break;
      case 'ASSIGNMENT_UPDATE':
        trackEvent('pallet_order_assignment', {
          pallet_ids: data.palletIds,
          order_id: data.orderId
        }, user, locationId);
        break;
      case 'STORAGE_UPDATE':
        trackStorageEvent(data.action, data.zone, data.pallet, user, locationId);
        break;
      default:
        // Generic tracking for other event types
        trackEvent(`sse_${type.toLowerCase()}`, data, user, locationId);
    }
  });
  
  return () => {
    // Cleanup function
    window.removeEventListener('sse-event', () => {});
  };
};

// Exports
export default {
  trackEvent,
  trackPalletEvent,
  trackOrderEvent,
  trackStorageEvent,
  initAnalyticsTracking
};

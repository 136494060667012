import { api } from '@api';
import { config } from '@config/env';

// Utility to check if a token is valid with the server
export const validateToken = async () => {
  // In development mode, always return valid
  if (config.isDevelopment) {
    console.log('Development mode: skipping token validation');
    const userData = localStorage.getItem('userData');
    return { 
      isValid: true, 
      user: userData ? JSON.parse(userData) : null 
    };
  }

  try {
    const response = await api.get('/api/auth/validate');
    return { isValid: true, user: response.data.data.user };
  } catch (error) {
    console.warn('Token validation failed:', error.message || 'Unknown error');
    return { isValid: false, error: error.message };
  }
};

// Clear all auth data from localStorage
export const clearAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  // Remove any other auth-related data
  localStorage.removeItem('refreshToken');
};

// Check if there's a redirection loop happening
export const checkRedirectLoop = (redirectType) => {
  const key = `${redirectType}RedirectCount`;
  const count = parseInt(sessionStorage.getItem(key) || '0', 10);
  
  // Increment the counter
  sessionStorage.setItem(key, String(count + 1));
  
  // Return true if we've redirected too many times (possible loop)
  return count >= 3; // Three redirects is enough to detect a loop
};

// Reset redirect counter
export const resetRedirectCounter = (redirectType) => {
  sessionStorage.removeItem(`${redirectType}RedirectCount`);
};

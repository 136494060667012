/**
 * EventTranslator.js
 * 
 * Translates server-side SSE events to client-side event types
 * This bridges the gap between the server's STORAGE_POSITION_UPDATE events
 * and the client's domain-specific events (PALLET_ADDED, PALLET_MOVED, etc.)
 */

import { ZONE_UPDATE_EVENTS } from '@features/map/services/zoneUpdateService';

class EventTranslator {
  /**
   * Translate a server event to a client event
   * @param {string} eventType - The server event type
   * @param {Object} eventData - The event data
   * @returns {Object|null} Translated event or null if no translation
   */
  translate(eventType, eventData) {
    try {
      if (!eventType || !eventData) return null;
      
      // Handle storage position updates
      if (eventType === 'STORAGE_POSITION_UPDATE') {
        return this.translateStoragePositionEvent(eventData);
      }
      
      // Handle pallet updates
      if (eventType === 'PALLET_UPDATE') {
        return this.translatePalletUpdateEvent(eventData);
      }
      
      // Handle order updates that might affect pallets
      if (eventType === 'ORDER_UPDATE') {
        return this.translateOrderUpdateEvent(eventData);
      }
      
      // Handle assignment updates
      if (eventType === 'ASSIGNMENT_UPDATE') {
        return this.translateAssignmentUpdateEvent(eventData);
      }
      
      // If no translation rules match, pass through the original event
      return {
        type: eventType,
        data: eventData
      };
    } catch (error) {
      console.error('Error translating event:', {
        error: error.message,
        stack: error.stack,
        eventType,
        eventData: JSON.stringify(eventData).substring(0, 100) + '...',
        timestamp: new Date().toISOString()
      });
      
      // Return null on translation error
      return null;
    }
  }
  
  /**
   * Translate STORAGE_POSITION_UPDATE events to client-specific event types
   * @param {Object} eventData - The event data
   * @returns {Object|null} Translated event or null if no translation
   */
  translateStoragePositionEvent(eventData) {
    if (!eventData || !eventData.action) return null;
    
    // Map server action to client event type
    switch (eventData.action) {
      case 'assign':
      case 'assign_to_zone':
        const zoneId = eventData.position?.zoneId;
        const storageZone = zoneId ? `${zoneId}-row-0` : null; // Create proper storage zone format
        
        return {
          type: ZONE_UPDATE_EVENTS.PALLET_ADDED,
          data: {
            palletId: eventData.position?.palletId,
            zoneId: zoneId,
            position: {
              row: eventData.position?.rowIndex,
              col: eventData.position?.colIndex
            },
            fromZoneId: eventData.position?.previousZoneId || null,
            storageZone: storageZone, // Use the properly formatted storage zone
            // Ensure the event data includes all necessary fields
            timestamp: eventData.timestamp || new Date().toISOString()
          }
        };
        
      case 'move':
        const toZoneId = eventData.position?.zoneId;
        const toStorageZone = toZoneId ? `${toZoneId}-row-0` : null; // Properly format destination storage zone
        
        return {
          type: ZONE_UPDATE_EVENTS.PALLET_MOVED,
          data: {
            palletId: eventData.position?.palletId,
            fromZoneId: eventData.position?.previousZoneId,
            toZoneId: toZoneId,
            position: {
              row: eventData.position?.rowIndex, 
              col: eventData.position?.colIndex
            },
            storageZone: toStorageZone,
            timestamp: eventData.timestamp || new Date().toISOString()
          }
        };
        
      case 'remove':
        return {
          type: ZONE_UPDATE_EVENTS.PALLET_REMOVED,
          data: {
            palletId: eventData.position?.palletId,
            zoneId: eventData.position?.zoneId,
            reason: eventData.position?.reason,
            timestamp: eventData.timestamp || new Date().toISOString()
          }
        };
        
      case 'update':
        const updateZoneId = eventData.position?.zoneId;
        const updateStorageZone = updateZoneId ? `${updateZoneId}-row-0` : null; // Properly format storage zone
        
        return {
          type: ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT,
          data: {
            palletId: eventData.position?.palletId,
            zoneId: updateZoneId,
            position: {
              row: eventData.position?.rowIndex,
              col: eventData.position?.colIndex
            },
            storageZone: updateStorageZone,
            success: true,
            timestamp: eventData.timestamp || new Date().toISOString()
          }
        };
        
      default:
        console.warn('Unknown storage position action:', {
          action: eventData.action,
          data: eventData,
          timestamp: new Date().toISOString()
        });
        return null;
    }
  }

  /**
   * Translate PALLET_UPDATE events to client-specific event types
   * @param {Object} eventData - The event data
   * @returns {Object|null} Translated event or null if no translation
   */
  translatePalletUpdateEvent(eventData) {
    if (!eventData || !eventData.action) return null;
    
    // Determine the appropriate zone update event type based on the action
    let eventType = null;
    
    switch (eventData.action) {
      case 'process_stage_update':
      case 'update':
      case 'edit':
        eventType = 'PALLET_UPDATED';
        break;
      case 'assign_to_order':
        eventType = 'PALLET_ASSIGNED_TO_ORDER';
        break;
      case 'ship':
      case 'mark_shipped':
        eventType = 'PALLET_SHIPPED';
        break;
      case 'create':
      case 'add':
        eventType = 'PALLET_CREATED';
        break;
      case 'delete':
      case 'remove':
        eventType = 'PALLET_DELETED';
        break;
      default:
        console.warn('Unknown pallet update action:', {
          action: eventData.action,
          data: eventData,
          timestamp: new Date().toISOString()
        });
        return null;
    }
    
    // Extract and enhance the pallets data
    const pallets = (eventData.pallets || []).map(pallet => {
      // Ensure storage zone information is preserved
      const enhancedPallet = {
        ...pallet,
        // Preserve storage zone if it exists in any format
        storageZone: pallet.storageZone || pallet.storage_zone || null,
        // Preserve zone ID and name if they exist
        zoneId: pallet.zoneId || pallet.zone_id || null,
        zoneName: pallet.zoneName || pallet.zone_name || null
      };

      // Log storage zone preservation for debugging
      console.debug('Preserving storage zone in pallet update:', {
        palletId: pallet.id,
        storageZone: enhancedPallet.storageZone,
        zoneId: enhancedPallet.zoneId,
        zoneName: enhancedPallet.zoneName,
        timestamp: new Date().toISOString()
      });

      return enhancedPallet;
    });
    
    return {
      type: eventType,
      data: {
        pallets: pallets,
        action: eventData.action,
        timestamp: eventData.timestamp || new Date().toISOString()
      }
    };
  }

  /**
   * Translate ORDER_UPDATE events to client-specific event types
   * @param {Object} eventData - The event data
   * @returns {Object|null} Translated event or null if no translation
   */
  translateOrderUpdateEvent(eventData) {
    if (!eventData || !eventData.action) return null;
    
    // If this order update affects pallets, translate it to a corresponding pallet event
    if (eventData.action === 'complete' || eventData.action === 'ship') {
      // If pallets were involved in the order update, emit a pallet event
      if (eventData.palletIds && eventData.palletIds.length > 0) {
        return {
          type: 'PALLETS_STATUS_CHANGED',
          data: {
            palletIds: eventData.palletIds,
            orderId: eventData.orderId || (eventData.orders && eventData.orders[0]?.id),
            action: eventData.action,
            timestamp: eventData.timestamp || new Date().toISOString()
          }
        };
      }
    }
    
    // Default order update passthrough
    return {
      type: 'ORDER_UPDATED',
      data: eventData
    };
  }

  /**
   * Translate ASSIGNMENT_UPDATE events to client-specific event types
   * @param {Object} eventData - The event data
   * @returns {Object|null} Translated event or null if no translation
   */
  translateAssignmentUpdateEvent(eventData) {
    if (!eventData) return null;
    
    return {
      type: 'PALLET_ASSIGNED_TO_ORDER',
      data: {
        palletIds: eventData.palletIds || [],
        orderId: eventData.orderId,
        timestamp: eventData.timestamp || new Date().toISOString()
      }
    };
  }
}

// Export singleton instance
const eventTranslator = new EventTranslator();
export default eventTranslator;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 2rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
`;

const Icon = styled.div`
  font-size: 4rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.warning};
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.textDark};
`;

const Message = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.textMedium};
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const NotAuthorized = ({ message, onGoBack }) => {
  return (
    <Container>
      <Icon>🔒</Icon>
      <Title>Access Denied</Title>
      <Message>
        {message || "You don't have permission to access this page. Please contact your administrator if you believe this is an error."}
      </Message>
      <Button onClick={onGoBack || (() => window.history.back())}>
        Go Back
      </Button>
    </Container>
  );
};

export default NotAuthorized;

import React, { useState, useEffect, useMemo } from 'react';
import { Map } from 'lucide-react';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useDashboard, DashboardProvider } from '../../contexts/DashboardContext';
import { useProcessing } from '@lib/contexts';
import { useToast } from '@lib/contexts';
import { UpdateProvider } from '@lib/contexts';
import { useLocationManagement } from '../../hooks/useLocationManagement';
import { useDashboardNavigation } from '../../hooks/useDashboardNavigation';
import { withAsyncBoundary } from '@lib/components/AsyncBoundary';
import { PalletForm } from '@lib/components/PalletForm'; 
import { AddOrder } from '@lib/components/AddOrder';
import OrderAssignmentModal from '../OrderAssignmentModal';
import BatchActionsManager from '../../utils/BatchActionsManager';
import { useDashboardFilters } from '../../hooks/useDashboardFilters';
import { usePalletHandlers } from '../../hooks/usePalletHandlers';
import { useOrderHandlers } from '../../hooks/useOrderHandlers';
import { handleExportExcel, handleRowKeyDown } from '../../utils/DashboardUtilities';
import { zoneUpdateService } from '@features/map/services/zoneUpdateService';
import storageAssignmentService from '@features/map/services/storageAssignmentService';
import RowSelectionModal from '@lib/components/RowSelectionModal';
import { theme } from '@styles/theme';
import { isDateFromToday } from '@lib/utils/dateUtils';
import {  
  fetchPallets,
  fetchOrders,
  fetchOverviewStats,
  updateOrderPriority,
  updateOrderColli,
  updateOrderDueDate
} from '../../services/dashboardService';

import DashboardLayout from '../DashboardLayout';
import DashboardStats from '../DashboardStats';
import WeeklyPalletTally from '../WeeklyPalletTally';
import { OrderList, PalletList } from './components';
import { MapView } from '@features/map';
import { StorageAssignmentModal } from '@features/map/components';
import { AddPallet, StorageEnabledAddPallet } from '@lib/components';
import PalletInfoModal from '../PalletInfoModal';
import { AnalyticsDashboard } from '@features/analytics';
import AdminDashboard from '../AdminDashboard';

const WrappedMainDashboard = ({ onAsyncAction }) => (
  <DashboardProvider>
    <UpdateProvider>
      <MainDashboard onAsyncAction={onAsyncAction} />
    </UpdateProvider>
  </DashboardProvider>
);

const MainDashboard = withAsyncBoundary(({ onAsyncAction }) => {
  const { user } = useUserSession();
  const {
    isLoading,
    setIsLoading,
    selectedLocation,
    setSelectedLocation,
    filters,
    setFilters,
    pallets,
    setPallets,
    orders,
    setOrders,
    overviewStats,
    setOverviewStats
  } = useDashboard();
  
  const { activeTab, setActiveTab } = useDashboardNavigation();

  // Info modal state
  const [selectedPalletInfo, setSelectedPalletInfo] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const handleShowInfo = (pallet) => {
    setSelectedPalletInfo(pallet);
    setInfoModalOpen(true);
  };

  const handleCloseInfo = () => {
    setInfoModalOpen(false);
    setSelectedPalletInfo(null);
  };

  // Reset filters when switching to pallets tab, with palletStatus defaulting to 'active'
  useEffect(() => {
    if (activeTab === 'pallets') {
      setFilters({
        tomatoType: '',
        boxType: '',
        palletType: '',
        orderStatus: '',
        palletStatus: 'active', // Default to active pallets only
        dateRange: '',
        weightRange: '',
        boxStatus: ''
      });
    }
  }, [activeTab, setFilters]);
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  
  const [showAddPallet, setShowAddPallet] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTomatoType, setSelectedTomatoType] = useState('');
  const [showAddOrder, setShowAddOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showCompletedOrders, setShowCompletedOrders] = useState(false);
  const [batchOperationLoading, setBatchOperationLoading] = useState(false);
  const [batchOperationError, setBatchOperationError] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedPalletForAssignment, setSelectedPalletForAssignment] = useState(null);
  const [showStorageAssignModal, setShowStorageAssignModal] = useState(false);
  const [palletsToAssignStorage, setPalletsToAssignStorage] = useState(null);
  const [showRowSelectionModal, setShowRowSelectionModal] = useState(false);
  const [palletForLocationChange, setPalletForLocationChange] = useState(null);

  // Reset modal state when tab changes
  useEffect(() => {
    setShowAddOrder(false);
    setSelectedOrder(null);
  }, [activeTab]);

  // Reset modal state when location changes
  useEffect(() => {
    setShowAddOrder(false);
    setSelectedOrder(null);
  }, [selectedLocation]);

  const {
    filteredAndSortedPallets,
    handleSelectPallet,
    handleSelectAll,
    requestSort,
    selectedPallets,
    selectAllChecked,
    resetSelection
  } = useDashboardFilters({ pallets, filters, isLoading });

  const loadPallets = async (locationId, tomatoTypeFilter = selectedTomatoType) => {
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const palletData = await fetchPallets(locationId, tomatoTypeFilter);
        setPallets(palletData || []);
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadOrders = async (locationId) => {
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const orderData = await fetchOrders(locationId, showCompletedOrders);
        setOrders(orderData || []);
      });
    } catch (error) {
      console.error('Error loading orders:', error);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadOverviewStats = async () => {
    if (!selectedLocation?.id) {
      setOverviewStats({
        totalKgWarehouse: 0,
        totalKgAssigned: 0,
        shippedToday: 0,
        totalPallets: 0,
        activeOrders: 0,
        todayByType: []
      });
      return;
    }
    
    setIsLoading(true);
    try {
      await onAsyncAction(async () => {
        const stats = await fetchOverviewStats(selectedLocation.id);
        setOverviewStats({
          totalKgWarehouse: stats?.totalKgWarehouse || 0,
          totalKgAssigned: stats?.totalKgAssigned || 0,
          shippedToday: stats?.shippedToday || 0,
          totalPallets: stats?.totalPallets || 0,
          activeOrders: stats?.activeOrders || 0,
          todayByType: stats?.todayByType || []
        });
      });
    } catch (error) {
      console.error('Error loading overview stats:', error);
      setOverviewStats({
        totalKgWarehouse: 0,
        totalKgAssigned: 0,
        shippedToday: 0,
        totalPallets: 0,
        activeOrders: 0,
        todayByType: []
      });
    } finally {
      setIsLoading(false);
    }
  };

const {
  handleAddPallet: baseHandleAddPallet,
  handleDeletePallet: baseHandleDeletePallet,
  handlePalletUpdate: baseHandlePalletUpdate,
  handleShipPallet,
  handleSplitPallet,
  handleReturnPallet
} = usePalletHandlers({
  selectedLocation,
  user,
  loadPallets,
  onAsyncAction
});

// Wrap the delete pallet function to ensure it updates the weekly tally
const handleDeletePallet = (palletId) => {
  return baseHandleDeletePallet(palletId)
    .then(async (result) => {
      // After successful deletion, ensure weekly tally is updated
      // This will supplement the SSE events already being emitted
      // which the WeeklyPalletTally component listens to
      console.log('Pallet successfully deleted, triggering tally update');
      return result;
    })
    .catch(error => {
      console.error('Error during pallet deletion:', error);
      throw error;
    });
};

  const {
    handleShipOrder: baseHandleShipOrder,
    handleAddOrder: baseHandleAddOrder,
    handleDeleteOrder: baseHandleDeleteOrder,
    handleAssignOrderSubmit: baseHandleAssignOrderSubmit
  } = useOrderHandlers({
    selectedLocation,
    onAsyncAction,
    loadOrders,
    loadPallets,
    orders,
    pallets,
    setShowAddOrder
  });

  const { locations, setLocations, loadUserLocations } = useLocationManagement(user);

  useEffect(() => {
    if (locations.length > 0 && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations, selectedLocation, setSelectedLocation]);

  // Initialize zoneUpdateService with the selected location ID
  useEffect(() => {
    if (selectedLocation?.id) {
      console.log('Initializing zoneUpdateService from MainDashboard with locationId:', selectedLocation.id);
      zoneUpdateService.initialize(selectedLocation.id);
    }
    
    return () => {
      zoneUpdateService.cleanup();
    };
  }, [selectedLocation]);

  // Listen for pallet list updates
  useEffect(() => {
    const handlePalletListUpdate = (event) => {
      if (selectedLocation?.id) {
        console.log('Received pallet-list-update event:', event.detail);
        loadPallets(selectedLocation.id);
      }
    };

    window.addEventListener('pallet-list-update', handlePalletListUpdate);
    return () => {
      window.removeEventListener('pallet-list-update', handlePalletListUpdate);
    };
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocation) {
      loadPallets(selectedLocation.id);
      loadOrders(selectedLocation.id);
      loadOverviewStats();
      // Reset modal states when data is reloaded
      setShowAddOrder(false);
      setSelectedOrder(null);
    }
  }, [selectedLocation, showCompletedOrders]);

  const handleAddOrder = async (orderData) => {
    const isEdit = !!orderData.id;
    showProcessing({ 
      message: isEdit ? 'Updating order...' : 'Creating new order...', 
      showOverlay: true 
    });
    try {
      await baseHandleAddOrder(orderData, isEdit);
    } finally {
      hideProcessing();
    }
  };

  const handleDeleteOrder = async (orderId) => {
    showProcessing({ 
      message: 'Deleting order...', 
      showOverlay: true 
    });
    try {
      await baseHandleDeleteOrder(orderId);
    } finally {
      hideProcessing();
    }
  };

  const handleShipOrder = async (orderId) => {
    showProcessing({ 
      message: 'Shipping order...', 
      showOverlay: true 
    });
    try {
      await baseHandleShipOrder(orderId);
    } finally {
      hideProcessing();
    }
  };

  const handleAssignOrder = (palletId) => {
    console.log('handleAssignOrder called with:', palletId);
    
    const activeOrders = orders.filter(order => !order.is_completed);
    if (activeOrders.length === 0) {
      showToast({
        message: 'No active orders available for assignment. Create a new order first.',
        variant: 'warning'
      });
      return;
    }

    let palletDetails;
    // If it's a batch operation (from BatchActionsToolbar)
    if (Array.isArray(palletId)) {
      console.log('Batch assignment with IDs:', palletId);
      const selectedPallets = palletId.map(id => pallets.find(p => p.id === id));
      console.log('Found pallet details:', selectedPallets);
      
      if (selectedPallets.some(p => !p)) {
        showToast({
          message: 'Some pallets could not be found',
          variant: 'error'
        });
        return;
      }

      palletDetails = {
        id: palletId, // Keep array of IDs for batch operations
        isBatch: true,
        pallets: selectedPallets // Keep full pallet objects for display
      };
    } else {
      // For single pallet assignment
      console.log('Single pallet assignment with ID:', palletId);
      const palletData = pallets.find(p => p.id === palletId);
      console.log('Found pallet data:', palletData);
      
      if (!palletData) {
        showToast({
          message: 'Could not find pallet',
          variant: 'error'
        });
        return;
      }

      // For single pallet, just pass the pallet data directly
      palletDetails = palletData;
    }

    setSelectedPalletForAssignment(palletDetails);
    setShowAssignModal(true);
  };

  const handleAssignOrderSubmit = async (orderId, extraBoxCount, palletData) => {
    showProcessing({ 
      message: 'Assigning pallet to order...', 
      showOverlay: true 
    });
    try {
      await baseHandleAssignOrderSubmit(orderId, extraBoxCount, palletData);
      setShowAssignModal(false);
      setSelectedPalletForAssignment(null);
    } catch (error) {
      showToast({
        message: `Error assigning pallet: ${error.message}`,
        variant: 'error'
      });
    } finally {
      hideProcessing();
    }
  };

  const handleLocationChange = (e) => {
    const selectedId = e.target.value;
    const location = locations.find(loc => loc.id === parseInt(selectedId));
    setSelectedLocation(location);
    if (location) {
      loadPallets(location.id);
    }
  };

  const handleAddPallet = async (palletData) => {
    showProcessing({ 
      message: 'Adding new pallet...', 
      showOverlay: true 
    });
    try {
      const result = await baseHandleAddPallet(palletData);
      setShowAddPallet(false);
      
      // Show storage assignment modal after adding pallet(s)
      if (Array.isArray(result)) {
        setPalletsToAssignStorage(result);
      } else if (result) {
        setPalletsToAssignStorage([result]);
      }
      setShowStorageAssignModal(true);
    } finally {
      hideProcessing();
    }
  };

  const handlePalletUpdate = async (updatedPalletData) => {
    showProcessing({ 
      message: 'Updating pallet...', 
      showOverlay: true 
    });
    try {
      const result = await baseHandlePalletUpdate(updatedPalletData, selectedPallet);
      setShowAddPallet(false);
      setIsEditMode(false);
      setSelectedPallet(null);
      
      // Show storage assignment modal after updating pallet(s)
      if (Array.isArray(result)) {
        setPalletsToAssignStorage(result);
      } else if (result) {
        setPalletsToAssignStorage([result]);
      }
      setShowStorageAssignModal(true);
    } finally {
      hideProcessing();
    }
  };

  const handleEditClick = (pallet) => {
    setSelectedPallet(pallet);
    setIsEditMode(true);
    setShowAddPallet(true);
  };

  const handleChangeStorageLocation = (palletId) => {
    const pallet = pallets.find(p => p.id === palletId);
    if (!pallet) {
      showToast({
        message: 'Could not find pallet',
        variant: 'error'
      });
      return;
    }
    
    // Set the pallet for location change
    setPalletForLocationChange(pallet);
    setShowRowSelectionModal(true);
  };

  const handleUpdateOrderPriority = async (orderId, priority) => {
    showProcessing({
      message: 'Updating order priority...',
      showOverlay: true
    });
    try {
      await updateOrderPriority(orderId, priority);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const handleUpdateOrderDueDate = async (orderId, dueDate) => {
    showProcessing({
      message: 'Updating due date...',
      showOverlay: true
    });
    try {
      await updateOrderDueDate(orderId, dueDate);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const handleUpdateOrderColli = async (orderId, completedColli) => {
    showProcessing({
      message: 'Updating colli count...',
      showOverlay: true
    });
    try {
      await updateOrderColli(orderId, completedColli);
      await loadOrders(selectedLocation.id);
    } finally {
      hideProcessing();
    }
  };

  const batchActions = BatchActionsManager(
    selectedPallets,
    onAsyncAction,
    () => {
      loadPallets(selectedLocation.id);
      resetSelection();
    }
  );

  const handleBatchOperation = async (operation, message, ...args) => {
    // Only use batch operations when multiple pallets are selected
    if (selectedPallets.size <= 1) {
      return;
    }

    setBatchOperationLoading(true);
    setBatchOperationError(null);
    showProcessing({ 
      message, 
      showOverlay: true 
    });
    
    try {
      const result = await operation(...args);
      if (result !== true) {
        setBatchOperationError(result);
      }
    } finally {
      hideProcessing();
      setBatchOperationLoading(false);
    }
  };

  // Modified to handle single pallet operations directly
  const handleBatchShip = () => {
    if (selectedPallets.size === 1) {
      const palletId = Array.from(selectedPallets)[0];
      return handleShipPallet(palletId);
    }
    return handleBatchOperation(batchActions.handleBatchShip, 'Shipping selected pallets...');
  };

  const handleBatchDelete = () => {
    if (selectedPallets.size === 1) {
      const palletId = Array.from(selectedPallets)[0];
      return handleDeletePallet(palletId);
    }
    return handleBatchOperation(batchActions.handleBatchDelete, 'Deleting selected pallets...');
  };

  const handleBatchUpdate = (updateData) => {
    if (selectedPallets.size === 1) {
      const palletId = Array.from(selectedPallets)[0];
      const pallet = pallets.find(p => p.id === palletId);
      return handlePalletUpdate(updateData, pallet);
    }
    return handleBatchOperation(batchActions.handleBatchUpdate, 'Updating selected pallets...', updateData);
  };

  const filteredOrders = useMemo(() => {
    if (!Array.isArray(orders)) return [];
    return orders;
  }, [orders]);

  const renderOverview = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    const activeOrdersCount = Array.isArray(orders) 
      ? orders.filter(o => !o?.is_completed).length 
      : 0;
      
    // Filter out shipped pallets for the warehouse stock counter
    const activePalletsCount = Array.isArray(pallets) 
      ? pallets.filter(p => p?.status !== 'shipped').length 
      : 0;

    const todayByType = Array.isArray(pallets)
      ? Object.entries(
          pallets.reduce((acc, pallet) => {
            // Use our safe date utility to check if the pallet was added today
            if (pallet?.addedDate && isDateFromToday(pallet.addedDate)) {
              acc[pallet.tomatoType] = (acc[pallet.tomatoType] || 0) + 1;
            }
            return acc;
          }, {})
        ).map(([type, count]) => ({ type, count }))
      : [];

    return (
      <DashboardStats overviewStats={{
        totalPallets: activePalletsCount, // Only count active pallets
        activeOrders: activeOrdersCount,
        totalKgWarehouse: overviewStats?.totalKgWarehouse || 0,
        totalKgAssigned: overviewStats?.totalKgAssigned || 0,
        shippedToday: overviewStats?.shippedToday || 0,
        todayByType
      }} />
    );
  };

  return (
    <DashboardLayout
      selectedLocation={selectedLocation}
      locations={locations}
      onLocationChange={handleLocationChange}
      activeTab={activeTab}
      onTabChange={setActiveTab}
    >
      {activeTab === 'overview' && (
        <>
          {renderOverview()}
          <WeeklyPalletTally />
        </>
      )}
      {activeTab === 'orders' && (
        <OrderList
          orders={filteredOrders}
          showCompletedOrders={showCompletedOrders}
          onToggleCompleted={() => setShowCompletedOrders(!showCompletedOrders)}
          onAddOrder={() => setShowAddOrder(true)}
          onUpdatePriority={handleUpdateOrderPriority}
          onShipOrder={handleShipOrder}
          onEditOrder={(order) => {
            setSelectedOrder(order);
            setShowAddOrder(true);
          }}
          onDeleteOrder={handleDeleteOrder}
        />
      )}
      {activeTab === 'pallets' && (
        <PalletList
          pallets={filteredAndSortedPallets}
          filters={filters}
          onFilterChange={setFilters}
          selectedPallets={selectedPallets}
          selectAllChecked={selectAllChecked}
          onSelectAll={handleSelectAll}
          onSelectPallet={handleSelectPallet}
          onSort={requestSort}
          onShipPallet={handleShipPallet}
          onEditPallet={handleEditClick}
          onDeletePallet={handleDeletePallet}
          onReturnPallet={handleReturnPallet}
          onSplitPallet={handleSplitPallet}
          onAssignOrder={handleAssignOrder}
          onRowKeyDown={(e, palletId) => handleRowKeyDown(e, palletId, handleSelectPallet)}
          onAddPallet={() => setShowAddPallet(true)}
          onExportExcel={() => handleExportExcel(filters, selectedLocation?.id, onAsyncAction)}
          batchOperationLoading={batchOperationLoading}
          batchOperationError={batchOperationError}
          onBatchShip={handleBatchShip}
          onBatchDelete={handleBatchDelete}
          onBatchUpdate={handleBatchUpdate}
          onShowInfo={handleShowInfo}
          onChangeStorageLocation={handleChangeStorageLocation}
        />
      )}
      {activeTab === 'map' && (
        <MapView />
      )}

      {activeTab === 'analytics' && (
        <AnalyticsDashboard locationId={selectedLocation?.id} />
      )}

      <StorageEnabledAddPallet
        isOpen={showAddPallet}
        onClose={() => {
          setShowAddPallet(false);
          setIsEditMode(false);
          setSelectedPallet(null);
        }}
        onPalletAdded={isEditMode ? handlePalletUpdate : handleAddPallet}
        locationId={selectedLocation?.id}
        tomatoType={selectedTomatoType}
        initialData={selectedPallet}
        isEditMode={isEditMode}
      />

      <AddOrder
        isOpen={showAddOrder}
        onClose={() => {
          setShowAddOrder(false);
          setSelectedOrder(null);
        }}
        onOrderAdded={handleAddOrder}
        locationId={selectedLocation?.id}
        initialData={selectedOrder}
      />

      <OrderAssignmentModal
        isOpen={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        orders={orders.filter(o => !o.is_completed)}
        onAssign={handleAssignOrderSubmit}
        palletDetails={selectedPalletForAssignment}
      />

      <PalletInfoModal
        pallet={selectedPalletInfo}
        isOpen={infoModalOpen}
        onClose={handleCloseInfo}
      />

      <StorageAssignmentModal
        isOpen={showStorageAssignModal}
        onClose={() => {
          setShowStorageAssignModal(false);
          setPalletsToAssignStorage(null);
        }}
        pallets={palletsToAssignStorage}
        onAssign={async (result) => {
          showProcessing({
            message: 'Assigning pallets to storage zone...',
            showOverlay: true
          });

          try {
            // If there was an error in the StorageAssignmentModal, we just need to handle UI updates
            if (result.error) {
              showToast({
                message: `Error assigning pallets to storage: ${result.error}`,
                variant: 'error'
              });
              return;
            }

            const { zoneId, pallets } = result;
            
            showToast({
              message: 'Successfully assigned pallets to storage zone',
              variant: 'success'
            });
            
            setShowStorageAssignModal(false);
            setPalletsToAssignStorage(null);
            
            // Refresh pallets to show updated assignments
            if (selectedLocation?.id) {
              await loadPallets(selectedLocation.id);
            }
          } catch (error) {
            showToast({
              message: `Error processing storage assignment: ${error.message}`,
              variant: 'error'
            });
          } finally {
            hideProcessing();
          }
        }}
        locationId={selectedLocation?.id}
      />

      <RowSelectionModal
        isOpen={showRowSelectionModal}
        onClose={() => {
          setShowRowSelectionModal(false);
          setPalletForLocationChange(null);
        }}
        locationId={selectedLocation?.id}
        onRowsSelected={async (selectedRows) => {
          if (!palletForLocationChange || selectedRows.length === 0) return;
          
          // Get the first selected row (only one should be selected due to singleSelectionMode)
          const selectedRow = selectedRows[0];
          
          showProcessing({
            message: 'Updating pallet storage location...',
            showOverlay: true
          });
          
          try {
            // Update the pallet's storage metadata with row information
            await storageAssignmentService.updatePalletStorageMetadata(
              palletForLocationChange.id,
              {
                zoneId: selectedRow.zoneId,
                rowIndex: selectedRow.rowIndex,
                isRowAssigned: true,
                locationId: selectedLocation?.id
              }
            );
            
            showToast({
              message: 'Pallet storage location updated successfully',
              variant: 'success'
            });
            
            // Refresh the pallets data to show the updated location
            await loadPallets(selectedLocation?.id);
          } catch (error) {
            showToast({
              message: `Error updating pallet location: ${error.message}`,
              variant: 'error'
            });
          } finally {
            hideProcessing();
            setShowRowSelectionModal(false);
            setPalletForLocationChange(null);
          }
        }}
        context="storage"
        singleSelectionMode={true}
      />
    </DashboardLayout>
  );
});

export default WrappedMainDashboard;

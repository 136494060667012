import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserSession } from '@features/auth/hooks';
import { api } from '@lib/api';
import { config } from '@config/env';

// Create the Permission context
export const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const { user } = useUserSession();
  const [permissions, setPermissions] = useState({});
  const [userRestrictions, setUserRestrictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Load permissions from user JWT token
  useEffect(() => {
    // In development mode, use dev permissions
    if (config.isDevelopment) {
      console.log('Development mode: using dev permissions');
      setPermissions(config.devPermissions);
      setUserRestrictions([]);
      setLoading(false);
      return;
    }

    if (user) {
      // If the user has permissions in their token, use those
      if (user.permissions) {
        setPermissions(user.permissions);
        // Always fetch restrictions even if we have permissions from token
        fetchUserRestrictions(user.id);
      } else {
        // Otherwise fetch permissions from the API
        // Skip this fetch if we already have the admin flag in the user object
        if (user.admin === true || user.admin === 1 || user.admin === "1") {
          console.log('User has admin flag, setting default admin permissions');
          // Set default permissions for admin users
          setPermissions({
            desktop_access: true,
            mobile_access: true,
            admin_tab: true,
            kleinpak_console: true,
            logistics_console: true,
            sorting_console: true,
            kleinpak_access: true,
            logistics_access: true,
            sorting_access: true,
            manage_users: true,
            manage_permissions: true,
            view_analytics: true,
            manage_orders: true,
            manage_pallets: true,
            view_storage_map: true,
            manage_storage_assignments: true
          });
          // Even admin users may have restrictions
          fetchUserRestrictions(user.id);
        } else {
          // For regular users, fetch both permissions and restrictions
          fetchUserPermissions(user.id);
          fetchUserRestrictions(user.id);
        }
      }
    } else {
      setPermissions({});
      setUserRestrictions([]);
      setLoading(false);
    }
  }, [user]);

  const fetchUserPermissions = async (userId) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await api.get(`/api/permissions/users/${userId}/permissions`);
      
      // Convert array of permission objects to a permission map
      const permMap = {};
      if (Array.isArray(response.data)) {
        response.data.forEach(perm => {
          permMap[perm.name] = true;
        });
      }
      
      setPermissions(permMap);
    } catch (err) {
      console.error('Error fetching user permissions:', err);
      setError(err.message || 'Failed to load permissions');
      setPermissions({});
    } finally {
      if (!user.permissions) {
        setLoading(false);
      }
    }
  };
  
  // New function to fetch user restrictions
  const fetchUserRestrictions = async (userId) => {
    try {
      setLoading(true);
      
      const response = await api.get(`/api/permissions/users/${userId}/restrictions`);
      
      let restrictionsData = [];
      
      // Handle nested data structure
      if (response.data && response.data.data) {
        restrictionsData = Array.isArray(response.data.data) ? response.data.data : [];
      } else {
        restrictionsData = Array.isArray(response.data) ? response.data : [];
      }
      
      console.log('Fetched user restrictions:', restrictionsData);
      
      // Store the raw restrictions array
      setUserRestrictions(restrictionsData);
      
      // Update permissions object to include restrictions with proper prefix
      setPermissions(prev => {
        const updatedPermissions = { ...prev };
        
        // Add each restriction to the permissions object with the restrict_ prefix
        restrictionsData.forEach(restriction => {
          if (restriction.restriction_key) {
            // Map specific restriction keys to the format expected by the application
            const mappedKey = mapRestrictionKeyToPrefixFormat(restriction.restriction_key);
            updatedPermissions[mappedKey] = true;
          }
        });
        
        console.log('Updated permissions with restrictions:', updatedPermissions);
        return updatedPermissions;
      });
    } catch (err) {
      console.error('Error fetching user restrictions:', err);
    } finally {
      setLoading(false);
    }
  };
  
  // Helper function to map restriction keys from database format to application format
  const mapRestrictionKeyToPrefixFormat = (key) => {
    // Map from database format (e.g., "mobile.sorting_console") to application format (e.g., "restrict_sorting_console")
    
    // Extract the console name from keys like "mobile.sorting_console"
    const consoleMatch = key.match(/mobile\.(.*?)_console/);
    if (consoleMatch && consoleMatch[1]) {
      return `restrict_${consoleMatch[1]}_console`;
    }
    
    // Handle storage map case
    if (key === "mobile.storage_map") {
      return "restrict_storage_console";
    }
    
    // For other restriction types, use a direct prefix
    if (!key.startsWith('restrict_')) {
      return `restrict_${key}`;
    }
    
    return key;
  };

  // Check if user has a specific permission
  const hasPermission = (permissionName) => {
    // In development mode, grant all permissions
    if (config.isDevelopment) {
      return true;
    }

    // If loading, assume they don't have permission yet
    if (loading) return false;
    
    // Check if the user has this specific permission
    return !!permissions[permissionName];
  };
  
  // Check if user has a specific restriction
  const hasRestriction = (restrictionName) => {
    // If loading, assume no restrictions
    if (loading) return false;
    
    // Ensure we're checking for a restriction (prefix with restrict_ if needed)
    const fullRestrictionName = restrictionName.startsWith('restrict_') 
      ? restrictionName 
      : `restrict_${restrictionName}`;
    
    // Check if the user has this specific restriction in the permissions object
    const restrictionInPermissions = !!permissions[fullRestrictionName];
    
    // For debugging
    console.log(`Checking restriction ${restrictionName}:`, {
      originalRestrictionName: restrictionName,
      fullRestrictionName,
      restrictionInPermissions,
      hasProperty: fullRestrictionName in permissions,
      propertyValue: permissions[fullRestrictionName],
      permissionsKeys: Object.keys(permissions),
    });
    
    return restrictionInPermissions;
  };

  // Check if user has access to a specific client type using permissions object
  const hasClientAccess = (clientType) => {
    console.log('hasClientAccess check:', { 
      clientType,
      user,
      isAdminResult: isAdmin(),
      hasPermissionsObject: !!user?.permissions,
      permissionsDesktopAccess: user?.permissions?.desktop_access
    });
    
    // Admin users have access to everything
    if (isAdmin()) {
      console.log('User is admin, granting access to', clientType);
      return true;
    }
    
    if (clientType === 'desktop') {
      // Check permissions object for desktop access
      if (user?.permissions) {
        const permissionValue = user.permissions.desktop_access;
        console.log('Using permissions object for desktop access:', permissionValue);
        return Boolean(permissionValue);
      }
      
      console.log('No desktop_access permission found');
      return false;
    } else if (clientType === 'mobile') {
      // For mobile, check permission first
      if (user?.permissions && 'mobile_access' in user.permissions) {
        return Boolean(user.permissions.mobile_access);
      }
      
      // Fall back to restriction check
      return !hasRestriction('mobile_access'); 
    }
    
    return true; // Default allow for unknown client types
  };

  // Check if user has access to a specific console in mobile client - default allow unless restricted
  const hasConsoleAccess = (consoleName) => {
    // Admin users have access to everything
    if (hasPermission('admin_tab')) return true;
    
    // Build the console-specific restriction key
    const restrictionKey = `restrict_${consoleName}_console`;
    
    // Log the check for debugging
    console.log(`Checking console access for ${consoleName}:`, {
      restrictionKey,
      hasRestriction: !!permissions[restrictionKey]
    });
    
    // Return true if the user doesn't have this restriction
    return !permissions[restrictionKey];
  };

  // Check if user has access to admin tab - default allow unless restricted
  const hasAdminAccess = () => {
    // Allow by default unless explicitly restricted
    return !hasRestriction('admin_tab');
  };
  
  // Check if user is an admin (based on admin flag in user data)
  const isAdmin = () => {
    // In development mode, always return true
    if (config.isDevelopment) {
      return true;
    }

    // Check admin flag, handling different possible formats (boolean, number, string)
    const adminValue = user?.admin;
    
    // If admin is 1, true, or "1", consider it true
    const result = adminValue === 1 || adminValue === true || adminValue === "1";
    
    console.log('isAdmin check:', { 
      user: user,
      adminValue: adminValue,
      adminType: typeof adminValue,
      adminValueAsNumber: Number(adminValue),
      adminValueAsBoolean: Boolean(adminValue),
      result: result
    });
    
    return result;
  };
  
  // Module-specific access checks using permissions system
  const hasSortingAccess = () => {
    // If user is admin, they have access to everything
    if (isAdmin()) return true;
    
    // Check permissions object
    if (user?.permissions) {
      return Boolean(user.permissions.sorting_access);
    }
    
    // Default to denied if no permission is found
    return false;
  };
  
  const hasLogisticsAccess = () => {
    // If user is admin, they have access to everything
    if (isAdmin()) return true;
    
    // Check permissions object
    if (user?.permissions) {
      return Boolean(user.permissions.logistics_access);
    }
    
    // Default to denied if no permission is found
    return false;
  };
  
  const hasKleinpakAccess = () => {
    // If user is admin, they have access to everything
    if (isAdmin()) return true;
    
    // Check permissions object
    if (user?.permissions) {
      return Boolean(user.permissions.kleinpak_access);
    }
    
    // Default to denied if no permission is found
    return false;
  };
  
  // Check if user has access to a specific module
  const hasModuleAccess = (moduleName) => {
    switch(moduleName) {
      case 'sorting':
        return hasSortingAccess();
      case 'logistics':
        return hasLogisticsAccess();
      case 'kleinpak':
        return hasKleinpakAccess();
      default:
        // For unknown modules, fall back to checking if user has any module restrictions
        return !hasAnyModuleAccess() || isAdmin();
    }
  };
  
  // Helper to check if any module access flags are set in permissions
  const hasAnyModuleAccess = () => {
    if (!user || !user.permissions) return false;
    
    // Check if any module access permissions are set
    return Boolean(
      user.permissions.sorting_access || 
      user.permissions.logistics_access || 
      user.permissions.kleinpak_access
    );
  };

  // Values to expose through the context
  const value = {
    permissions,
    userRestrictions,
    loading,
    error,
    hasPermission,
    hasRestriction,
    hasClientAccess,
    hasConsoleAccess,
    hasAdminAccess,
    isAdmin,
    hasSortingAccess,
    hasLogisticsAccess,
    hasKleinpakAccess,
    hasModuleAccess,
    hasAnyModuleAccess,
    refreshPermissions: () => {
      if (user) {
        fetchUserPermissions(user.id);
        fetchUserRestrictions(user.id);
      }
    }
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

// Custom hook for using the permission context
export const usePermission = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermission must be used within a PermissionProvider');
  }
  return context;
};

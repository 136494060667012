export const theme = {
  colors: {
    // Common colors with opacity variations
    common: {
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent',
      overlay: 'rgba(0, 0, 0, 0.5)',
      backdrop: 'rgba(0, 0, 0, 0.75)'
    },
    // Primary palette - Enhanced tomato-inspired reds
    primary: {
      50: '#FFF3F3',    // Lightest tint for subtle backgrounds
      100: '#FFE6E6',   // Very light tint for hover states
      200: '#FFCDD2',   // Light tint for active states
      300: '#FF9A9A',   // Soft tomato for secondary elements
      400: '#FF6B6B',   // Bright tomato for interactive elements
      500: '#E53935',   // Main tomato red - primary brand color
      600: '#D32F2F',   // Deeper tomato for emphasis
      700: '#C62828',   // Rich tomato for strong emphasis
      800: '#B71C1C',   // Deep tomato for intense emphasis
      900: '#961717',   // Darkest tomato for maximum contrast
      alpha10: 'rgba(229, 57, 53, 0.1)',  // For subtle overlays
      alpha20: 'rgba(229, 57, 53, 0.2)',  // For hover effects
      alpha50: 'rgba(229, 57, 53, 0.5)',   // For stronger overlays
      
      // Named keys for compatibility with component usage
      main: '#E53935',       // Same as 500
      light: '#FF6B6B',      // Same as 400
      dark: '#C62828',       // Same as 700
      lighter: '#FF9A9A',    // Same as 300
      darker: '#B71C1C',     // Same as 800
      contrastText: '#FFFFFF' // White text for contrast on tomato backgrounds
    },
    // Secondary palette - Enhanced fresh greens
    secondary: {
      50: '#F3FBF3',    // Lightest green tint
      100: '#E8F6E8',   // Very light green
      200: '#C8E6C9',   // Light green
      300: '#A5D6A7',   // Soft green
      400: '#66BB6A',   // Medium green
      500: '#43A047',   // Main green - fresh leaf
      600: '#388E3C',   // Deep green
      700: '#2E7D32',   // Rich green
      800: '#1B5E20',   // Dark green
      900: '#0A3D0A',   // Darkest green
      alpha10: 'rgba(67, 160, 71, 0.1)',  // For subtle overlays
      alpha20: 'rgba(67, 160, 71, 0.2)',  // For hover effects
      alpha50: 'rgba(67, 160, 71, 0.5)',   // For stronger overlays
      
      // Named keys for compatibility with component usage
      main: '#43A047',       // Same as 500
      light: '#66BB6A',      // Same as 400
      dark: '#2E7D32',       // Same as 700
      lighter: '#A5D6A7',    // Same as 300
      darker: '#1B5E20',     // Same as 800
      contrastText: '#000000' // Black text for contrast on green backgrounds
    },
    // Status colors - Enhanced for better accessibility
    success: {
      light: '#4CAF50',   // Light success
      main: '#2E7D32',    // Main success - Deeper green for better contrast
      dark: '#1B5E20',    // Dark success
      alpha: 'rgba(46, 125, 50, 0.1)'  // For success backgrounds
    },
    warning: {
      light: '#FF9800',   // Light warning
      main: '#F57C00',    // Main warning - Warmer orange
      dark: '#E65100',    // Dark warning
      alpha: 'rgba(245, 124, 0, 0.1)'  // For warning backgrounds
    },
    error: {
      light: '#EF5350',   // Light error
      main: '#D32F2F',    // Main error - Consistent red
      dark: '#C62828',    // Dark error
      alpha: 'rgba(211, 47, 47, 0.1)'  // For error backgrounds
    },
    info: {
      light: '#42A5F5',   // Light info
      main: '#1976D2',    // Main info - Clearer blue
      dark: '#1565C0',    // Dark info
      alpha: 'rgba(25, 118, 210, 0.1)'  // For info backgrounds
    },
    // Neutral grays - Enhanced warmer undertones
    gray: {
      50: '#FAFAFA',    // Lightest gray for subtle backgrounds
      100: '#F8F9FA',   // Very light gray
      200: '#F1F3F5',   // Light gray for hover states
      300: '#E9ECEF',   // Soft gray for borders
      400: '#DEE2E6',   // Medium gray
      500: '#ADB5BD',   // Main gray
      600: '#868E96',   // Deep gray
      700: '#495057',   // Rich gray for text
      800: '#343A40',   // Dark gray
      900: '#212529',   // Darkest gray
      alpha10: 'rgba(173, 181, 189, 0.1)',  // For subtle overlays
      alpha20: 'rgba(173, 181, 189, 0.2)',  // For hover effects
      alpha50: 'rgba(173, 181, 189, 0.5)',   // For stronger overlays
      
      // Named keys for compatibility with component usage
      main: '#ADB5BD',       // Same as 500
      light: '#DEE2E6',      // Same as 400
      dark: '#495057',       // Same as 700
      lighter: '#E9ECEF',    // Same as 300
      darker: '#343A40',     // Same as 800
      contrastText: '#000000' // Black text for contrast on gray backgrounds
    },
    // Background variations - Enhanced subtle warmth
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
      card: '#FFFFFF',
      hover: '#F8F9FA',
      active: '#F3F3F3',
      selected: '#FFF3F3',  // Subtle tomato tint for selected items
      accent: '#FFF5F5',   // Very light tomato tint
      highlight: '#FFEBEE', // Stronger tomato tint for highlights
      darker: '#E0E0E0',
      darkest: '#BDBDBD',
      overlay: 'rgba(0, 0, 0, 0.05)',  // For subtle layering
      disabled: 'rgba(0, 0, 0, 0.12)'  // For disabled elements
    },
    // Text colors - Enhanced contrast for accessibility
    text: {
      primary: '#212529',      // Main text - passes AA contrast
      secondary: '#495057',    // Secondary text - passes AA contrast
      tertiary: '#6C757D',    // Less important text - passes AA contrast
      disabled: '#ADB5BD',    // Disabled text - passes AA contrast
      accent: '#E53935',      // Tomato red for emphasis - passes AA contrast
      inverse: '#FFFFFF',     // White text - for dark backgrounds
      hint: 'rgba(0, 0, 0, 0.6)',  // Hint text - passes AA contrast
      link: '#D32F2F',        // Link text - passes AA contrast
      code: '#B71C1C'         // Code text - passes AA contrast
    },
    // Border colors - Enhanced system
    border: {
      light: '#E0E0E0',
      main: '#BDBDBD',
      dark: '#9E9E9E',
      focus: 'rgba(229, 57, 53, 0.25)',  // Focus ring color
      hover: 'rgba(0, 0, 0, 0.1)',       // Hover border color
      active: '#E53935',                 // Active border color
      error: '#D32F2F',                  // Error border color
      success: '#2E7D32'                 // Success border color
    },
  },
  typography: {
    // Font families
    fontFamily: {
      primary: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
      monospace: "'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace"
    },
    // Type scale using a 1.25 ratio (major third)
    h1: {
      fontSize: '3rem',          // 48px
      fontWeight: 700,
      lineHeight: 1.1,          // Tighter for headlines
      letterSpacing: '-0.02em',
      marginBottom: '1.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    h2: {
      fontSize: '2.25rem',      // 36px
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: '-0.015em',
      marginBottom: '1.25rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    h3: {
      fontSize: '1.75rem',      // 28px
      fontWeight: 600,
      lineHeight: 1.3,
      letterSpacing: '-0.01em',
      marginBottom: '1rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    h4: {
      fontSize: '1.5rem',       // 24px
      fontWeight: 600,
      lineHeight: 1.35,
      letterSpacing: '-0.005em',
      marginBottom: '0.75rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    h5: {
      fontSize: '1.25rem',      // 20px
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: '0em',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    h6: {
      fontSize: '1.125rem',     // 18px
      fontWeight: 600,
      lineHeight: 1.45,
      letterSpacing: '0.005em',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    // Body text variations
    body1: {
      fontSize: '1rem',         // 16px - Base size
      fontWeight: 400,
      lineHeight: 1.5,          // Comfortable reading
      letterSpacing: '0.01em',
      marginBottom: '1rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    body2: {
      fontSize: '0.875rem',     // 14px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.01em',
      marginBottom: '0.75rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    // Additional text styles
    subtitle1: {
      fontSize: '1.125rem',     // 18px
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.005em',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    subtitle2: {
      fontSize: '0.875rem',     // 14px
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0.005em',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    caption: {
      fontSize: '0.75rem',      // 12px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.02em',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    overline: {
      fontSize: '0.75rem',      // 12px
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      marginBottom: '0.5rem',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    button: {
      fontSize: '0.875rem',     // 14px
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: '0.02em',
      textTransform: 'none',
      fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', sans-serif",
    },
    code: {
      fontSize: '0.875rem',     // 14px
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0em',
      fontFamily: "'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono', 'Droid Sans Mono', 'Source Code Pro', monospace",
    },
    // Helper styles
    strong: {
      fontWeight: 600,
    },
    small: {
      fontSize: '0.875em',      // Relative to parent
    },
  },
  spacing: {
    xs: '0.25rem',    // 4px
    sm: '0.5rem',     // 8px
    md: '1rem',       // 16px
    lg: '1.5rem',     // 24px
    xl: '2rem',       // 32px
    xxl: '3rem',      // 48px
  },
  // Enhanced elevation system with realistic shadows
  elevation: {
    0: 'none',
    1: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',                                                                // Subtle elevation
    2: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',                             // Card elevation
    3: '0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.08)',                       // Dropdown elevation
    4: '0 6px 8px -2px rgba(0, 0, 0, 0.05), 0 4px 6px -1px rgba(0, 0, 0, 0.08)',                       // Modal elevation
    5: '0 8px 16px -4px rgba(0, 0, 0, 0.05), 0 4px 8px -2px rgba(0, 0, 0, 0.08)',                      // High elevation
    6: '0 12px 24px -6px rgba(0, 0, 0, 0.05), 0 8px 12px -3px rgba(0, 0, 0, 0.08)',                    // Highest elevation
  },
  // Enhanced shadow system for different use cases
  shadows: {
    // Basic shadows
    none: 'none',
    xs: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    sm: '0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
    md: '0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.08)',
    lg: '0 12px 16px -4px rgba(0, 0, 0, 0.05), 0 4px 8px -2px rgba(0, 0, 0, 0.08)',
    xl: '0 20px 24px -6px rgba(0, 0, 0, 0.05), 0 8px 12px -3px rgba(0, 0, 0, 0.08)',
    // Interactive shadows
    hover: '0 6px 12px -2px rgba(0, 0, 0, 0.05), 0 3px 6px -1px rgba(0, 0, 0, 0.08)',
    active: '0 2px 4px -1px rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.08)',
    focus: '0 0 0 3px rgba(229, 57, 53, 0.25)',  // Tomato-themed focus ring
    // Special purpose shadows
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    outline: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    selected: '0 0 0 2px rgba(229, 57, 53, 0.2)',  // Tomato-themed selection
    // Colored shadows for status
    success: '0 4px 6px -1px rgba(46, 125, 50, 0.1), 0 2px 4px -1px rgba(46, 125, 50, 0.06)',
    error: '0 4px 6px -1px rgba(211, 47, 47, 0.1), 0 2px 4px -1px rgba(211, 47, 47, 0.06)',
    warning: '0 4px 6px -1px rgba(245, 124, 0, 0.1), 0 2px 4px -1px rgba(245, 124, 0, 0.06)',
  },
  transitions: {
    default: 'all 0.2s ease-in-out',
    fast: 'all 0.1s ease-in-out',
    slow: 'all 0.3s ease-in-out',
    shadow: 'box-shadow 0.15s ease-in-out',
    transform: 'transform 0.15s ease-in-out',
    color: 'color 0.15s ease-in-out',
    background: 'background-color 0.15s ease-in-out',
    border: 'border-color 0.15s ease-in-out',
    opacity: 'opacity 0.15s ease-in-out',
  },
  // Enhanced border radius system
  borderRadius: {
    none: '0',
    xs: '0.125rem',      // 2px
    sm: '0.25rem',       // 4px
    md: '0.375rem',      // 6px
    lg: '0.5rem',        // 8px
    xl: '0.75rem',       // 12px
    '2xl': '1rem',       // 16px
    '3xl': '1.5rem',     // 24px
    full: '9999px',
    // Special purpose border radii
    button: '0.375rem',  // 6px - Consistent button radius
    card: '0.5rem',      // 8px - Card corners
    modal: '0.75rem',    // 12px - Modal corners
    pill: '9999px',      // Pill shape
    circle: '50%',       // Perfect circle
    // Directional border radii
    top: '0.5rem 0.5rem 0 0',
    right: '0 0.5rem 0.5rem 0',
    bottom: '0 0 0.5rem 0.5rem',
    left: '0.5rem 0 0 0.5rem',
    // Individual corner border radii
    'top-left': '0.5rem 0 0 0',
    'top-right': '0 0.5rem 0 0',
    'bottom-right': '0 0 0.5rem 0',
    'bottom-left': '0 0 0 0.5rem',
  },
  zIndex: {
    toast: 1300,
    loading: 1200,
    modal: 1100,
    popover: 1000,
    tooltip: 900,
    header: 800,
    drawer: 700,
  },
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from '@lib/api/axios';
import { Alert, AlertDescription } from '@lib/components/ui';
import { Check, AlertTriangle, Loader, Printer } from 'lucide-react';
import { UserContext } from '@lib/contexts';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const Title = styled.h3`
  ${({ theme }) => theme.typography.h3};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Select = styled.select`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary.main}20`};
  }
`;

const Button = styled.button`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body2};
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
  ${({ theme }) => theme.typography.body2};
  color: ${({ status, theme }) => 
    status === 'active' 
      ? theme.colors.success 
      : status === 'maintenance'
      ? theme.colors.warning
      : theme.colors.error};
`;

export const PrinterSettings = ({ locationId }) => {
  const [printers, setPrinters] = useState([]);
  const [selectedPrinterId, setSelectedPrinterId] = useState('');
  const [loading, setLoading] = useState(false);
  const [testing, setTesting] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [printerStatus, setPrinterStatus] = useState(null);

  useEffect(() => {
    if (locationId) {
      fetchPrinters();
    }
  }, [locationId]);

  const fetchPrinters = async () => {
    try {
      const response = await axios.get(`/api/user-printer-preferences/location/${locationId}/printers`);
      setPrinters(response.data.data);
      
      // Set selected printer if one is already chosen
      const selectedPrinter = response.data.data.find(p => p.isSelected);
      if (selectedPrinter) {
        setSelectedPrinterId(selectedPrinter.id);
      }
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.response?.data?.message || 'Failed to fetch printers'
      });
    }
  };

  const handlePrinterChange = async (e) => {
    const printerId = e.target.value;
    setSelectedPrinterId(printerId);
    setPrinterStatus(null);

    if (!printerId) {
      // Clear printer preference
      try {
        await axios.delete(`/api/user-printer-preferences/location/${locationId}/preference`);
        setFeedback({
          type: 'success',
          message: 'Printer preference cleared'
        });
      } catch (error) {
        setFeedback({
          type: 'error',
          message: error.response?.data?.message || 'Failed to clear printer preference'
        });
      }
      return;
    }

    // Set new printer preference
    setLoading(true);
    try {
      await axios.post(`/api/user-printer-preferences/location/${locationId}/preference`, {
        printerId
      });
      
      setFeedback({
        type: 'success',
        message: 'Printer preference updated successfully'
      });
      
      // Test printer connection
      await testConnection(printerId);
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.response?.data?.message || 'Failed to set default printer'
      });
    } finally {
      setLoading(false);
    }
  };

  const testConnection = async (printerId) => {
    if (!printerId) return;

    setTesting(true);
    try {
      const response = await axios.post(`/api/printer-config/printers/${printerId}/test-connection`);
      const status = response.data.data;
      setPrinterStatus(status);
      
      if (status.status !== 'active') {
        setFeedback({
          type: 'error',
          message: `Printer issue: ${status.message}`
        });
      }
    } catch (error) {
      setFeedback({
        type: 'error',
        message: error.response?.data?.message || 'Failed to test printer connection'
      });
    } finally {
      setTesting(false);
    }
  };

  return (
    <Container>
      {feedback && (
        <Alert className={`mb-4 ${feedback.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
          {feedback.type === 'error' ? (
            <AlertTriangle className="h-4 w-4" />
          ) : (
            <Check className="h-4 w-4" />
          )}
          <AlertDescription>{feedback.message}</AlertDescription>
        </Alert>
      )}

      <Section>
        <Title>Select Printer</Title>
        <Select 
          value={selectedPrinterId} 
          onChange={handlePrinterChange}
        >
          <option value="">No Printer Selected</option>
          {printers.map(printer => (
            <option key={printer.id} value={printer.id}>
              {printer.name} {printer.status !== 'active' ? `(${printer.status})` : ''}
            </option>
          ))}
        </Select>

        {selectedPrinterId && (
          <>
            {printerStatus && (
              <StatusIndicator status={printerStatus.status}>
                <Printer size={16} />
                {printerStatus.status === 'active' ? 'Printer Ready' : printerStatus.message}
              </StatusIndicator>
            )}

            {loading && (
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '0.5rem',
                marginTop: '0.5rem' 
              }}>
                <Loader className="animate-spin" size={16} />
                Updating printer preference...
              </div>
            )}

            {selectedPrinterId && printers.length > 0 && (
              <div style={{ marginTop: '1rem' }}>
                {(() => {
                  const printer = printers.find(p => p.id === selectedPrinterId);
                  if (!printer) return null;

                  return (
                    <>
                      <p><strong>Connection:</strong> {printer.protocol} (Port {printer.port})</p>
                      <p><strong>Security:</strong> {printer.ssl_enabled ? 'SSL/TLS Enabled' : 'Standard'}</p>
                      <p><strong>Status:</strong> {printer.status.charAt(0).toUpperCase() + printer.status.slice(1)}</p>
                    </>
                  );
                })()}
              </div>
            )}
          </>
        )}
      </Section>
    </Container>
  );
};

export default PrinterSettings;

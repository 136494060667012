import React, { useContext } from 'react';
import { UserContext } from '@lib/contexts/UserContext';
import { DEFAULT_ZONES } from '@features/map/constants/zoneConfig.v2';
import MobileStorageAssignmentModal from '../MobileStorageAssignmentModal';
import { useStorageAssignment } from './hooks/useStorageAssignment';
import { useZoneManagement } from './hooks/useZoneManagement';
import { usePalletPatterns } from './hooks/usePalletPatterns';
import {
  QuickAssignDialog,
  QuickAssignContent,
  QuickAssignTitle,
  QuickAssignInfo,
  QuickAssignButtons,
  InfoMessage,
  ColumnVisualizer,
  ColumnLabel,
  Legend,
  LegendItem,
  LegendBox,
  CancelButton,
  ConfirmButton,
  PositionCell
} from './styles/StorageIntegrationStyles';
import { Map as MapIcon, Check, X, Columns, ArrowRight, AlertCircle } from 'lucide-react';

/**
 * Higher-order component to add column-based storage zone assignment capabilities
 * @param {React.ComponentType} FinishMenuComponent - Base component to enhance
 * @returns {React.ComponentType} Enhanced component with storage integration
 */
export const withStorageIntegration = (FinishMenuComponent) => {
  if (!FinishMenuComponent) {
    throw new Error('FinishMenuComponent is required for withStorageIntegration');
  }

  return (props) => {
    // Development mode warnings for missing required props
    if (process.env.NODE_ENV === 'development') {
      if (!props.locationId) {
        console.warn('StorageIntegration: locationId prop is missing, storage features will be disabled');
      }
      if (typeof props.onFinishPallet !== 'function') {
        console.warn('StorageIntegration: onFinishPallet prop is not a function, storage features will be disabled');
      }
    }

    // Provide safe defaults for missing or invalid props
    const safeProps = {
      ...props,
      locationId: props.locationId || null,
      onFinishPallet: typeof props.onFinishPallet === 'function' 
        ? props.onFinishPallet 
        : () => {
            console.warn('StorageIntegration: Attempted to finish pallet but onFinishPallet is not available');
            return false;
          }
    };

    // Return base component if required features are not available
    if (!safeProps.locationId) {
      return <FinishMenuComponent {...safeProps} />;
    }

    const { user } = useContext(UserContext);

    // Return base component if user context is not available
    if (!user?.id) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('StorageIntegration: User context not available, storage features will be disabled');
      }
      return <FinishMenuComponent {...safeProps} />;
    }
    
    // Initialize hooks with validated props
    const {
      newlyCreatedPallet,
      showAssignmentConfirm,
      showStorageModal,
      assignmentError,
      setNewlyCreatedPallet,
      setShowAssignmentConfirm,
      setShowStorageModal,
      setAssignmentError,
      handleStorageAssignment
    } = useStorageAssignment({
      locationId: props.locationId,
      onFinishPallet: props.onFinishPallet
    });

    const {
      zonesAvailable,
      zoneOccupancy,
      findNextAvailablePosition,
      verifyPositionAvailability
    } = useZoneManagement({
      locationId: props.locationId
    });

    const {
      columnAssignData,
      showColumnAssign,
      previousStorageData,
      showPreviousStorageDialog,
      setColumnAssignData,
      setShowColumnAssign,
      setPreviousStorageData,
      setShowPreviousStorageDialog,
      findAndApplyPattern,
      savePattern
    } = usePalletPatterns({
      locationId: props.locationId,
      userId: user?.id
    });

    /**
     * Handle pallet finish with storage assignment
     * @param {string} positionLabel - Position identifier
     * @param {boolean} success - Whether pallet creation succeeded
     * @param {Object} palletData - Pallet data
     */
    const handleFinishPalletWithStorage = async (positionLabel, success, palletData) => {
      // Validate inputs
      if (!positionLabel || typeof positionLabel !== 'string') {
        console.error('Invalid position label:', positionLabel);
        return props.onFinishPallet(positionLabel, false);
      }

      const normalizedPosition = String(positionLabel).trim();
      if (!normalizedPosition) {
        console.error('Empty position label after normalization');
        return props.onFinishPallet(positionLabel, false);
      }

      if (!success || !zonesAvailable || !user?.id) {
        return props.onFinishPallet(positionLabel, success);
      }

      // Prepare pallet data with normalized position
      const palletWithPosition = {
        ...palletData,
        position: normalizedPosition,
        sortingGrade: palletData?.sortingGrade,
        processStage: palletData?.processStage,
        tomatoType: palletData?.tomatoType // Ensure tomato type is included for weekly tally
      };
      
      console.log('StorageIntegration: Pallet data with position', {
        position: normalizedPosition,
        sortingGrade: palletData?.sortingGrade,
        processStage: palletData?.processStage,
        tomatoType: palletData?.tomatoType
      });
      
      setNewlyCreatedPallet(palletWithPosition);

      // Find matching pattern or previous storage
      const patternResult = await findAndApplyPattern(palletWithPosition);
      
      if (patternResult) {
        if (patternResult.type === 'pattern') {
          const pattern = patternResult.data;
          const zone = DEFAULT_ZONES.find(z => z.id === pattern.zoneId);
          
          if (!zone) {
            setShowStorageModal(true);
            return;
          }

          const nextPosition = await findNextAvailablePosition(
            pattern.zoneId,
            pattern.col || pattern.colIndex || 0,
            zone.dimensions.rows
          );

          if (nextPosition.found) {
            setColumnAssignData({
              pattern,
              nextPosition,
              zone,
              occupancy: zoneOccupancy
            });
            setShowColumnAssign(true);
          } else {
            setShowStorageModal(true);
          }
        } else if (patternResult.type === 'previous') {
          const previousStorage = patternResult.data;
          const zone = DEFAULT_ZONES.find(z => z.id === previousStorage.zoneId);
          
          setPreviousStorageData({
            ...previousStorage,
            zone,
            zoneName: zone?.name || previousStorage.zoneId
          });
          setShowPreviousStorageDialog(true);
        }
      } else {
        setShowStorageModal(true);
      }
    };

      /**
       * Handle column-based assignment confirmation
       */
      const handleColumnAssignConfirm = async () => {
        if (!columnAssignData || !newlyCreatedPallet) {
          setShowColumnAssign(false);
          return;
        }

        const { pattern, nextPosition, zone } = columnAssignData;

        try {
          // Verify position is still available
          const isAvailable = await verifyPositionAvailability(
            pattern.zoneId,
            nextPosition.row,
            nextPosition.col
          );

          if (!isAvailable) {
            throw new Error('Position is no longer available');
          }

          // Create storage assignment data
          const assignmentData = {
            zoneId: pattern.zoneId,
            zoneName: zone?.name || pattern.zoneId,
            position: nextPosition,
            storageZone: `${pattern.zoneId}-row-${nextPosition.row}`,
            // Preserve tomato type and option data for weekly tally
            tomatoType: newlyCreatedPallet.tomatoType,
            tomatoOption: newlyCreatedPallet.tomatoOption,
            sortingGrade: newlyCreatedPallet.sortingGrade
          };

          console.log('StorageIntegration: Preserving tomato data for assignment:', {
            tomatoType: newlyCreatedPallet.tomatoType,
            tomatoOption: newlyCreatedPallet.tomatoOption,
            sortingGrade: newlyCreatedPallet.sortingGrade
          });

          // Handle the assignment
          await handleStorageAssignment(assignmentData);

          // Save the pattern on success
          await savePattern(
            newlyCreatedPallet,
            pattern.zoneId,
            nextPosition.row,
            nextPosition.col
          );

        } catch (error) {
          console.error('Column assignment error:', error);
          setAssignmentError(error.message);
          setTimeout(() => setShowStorageModal(true), 800);
        } finally {
          setShowColumnAssign(false);
          setColumnAssignData(null);
        }
      };

    /**
     * Handle using previous storage location
     */
    const handleUsePreviousStorage = () => {
      if (!previousStorageData || !newlyCreatedPallet) {
        console.warn('Missing data for previous storage assignment');
        return;
      }

      handleStorageAssignment({
        zoneId: previousStorageData.zoneId,
        zoneName: previousStorageData.zoneName,
        position: {
          row: previousStorageData.rowIndex,
          col: 0
        },
        storageZone: `${previousStorageData.zoneId}-row-${previousStorageData.rowIndex || 0}`
      });

      setShowPreviousStorageDialog(false);
      setPreviousStorageData(null);
    };

    /**
     * Render the column-based assignment dialog
     */
    const renderColumnAssignDialog = () => {
      if (!showColumnAssign || !columnAssignData) return null;

      const { pattern, nextPosition, zone, occupancy } = columnAssignData;
      const columnIndex = pattern.col ?? pattern.column ?? 0;
      const maxRows = zone?.dimensions?.rows || 5;

      return (
        <QuickAssignDialog>
          <QuickAssignContent>
            <QuickAssignTitle>
              <Columns size={20} />
              Suggested Storage Location
            </QuickAssignTitle>
            
            <QuickAssignInfo>
              <p><strong>Zone:</strong> {zone?.name || pattern.zoneId}</p>
              <p><strong>Column:</strong> {columnIndex + 1}</p>
            </QuickAssignInfo>

            {nextPosition.isAlternative ? (
              <InfoMessage $warning>
                <AlertCircle size={16} />
                Column {columnIndex + 1} is full. Alternative position found.
              </InfoMessage>
            ) : (
              <InfoMessage>
                <ArrowRight size={16} />
                Next available position in same column
              </InfoMessage>
            )}

            <ColumnVisualizer>
              {Array.from({ length: maxRows }).map((_, row) => (
                <PositionCell
                  key={row}
                  $occupied={occupancy[`${row}-${columnIndex}`]}
                  $suggested={nextPosition.row === row}
                  $lastUsed={pattern.row === row}
                >
                  {row + 1}
                </PositionCell>
              ))}
              <ColumnLabel>Column {columnIndex + 1}</ColumnLabel>
            </ColumnVisualizer>

            <Legend>
              <LegendItem>
                <LegendBox $color="#cbd5e1" />
                <span>Occupied</span>
              </LegendItem>
              <LegendItem>
                <LegendBox $color="#3b82f6" />
                <span>Suggested</span>
              </LegendItem>
            </Legend>

            {assignmentError && (
              <InfoMessage $warning>
                <AlertCircle size={16} />
                {assignmentError}
              </InfoMessage>
            )}

            <QuickAssignButtons>
              <CancelButton onClick={() => {
                setShowColumnAssign(false);
                setShowStorageModal(true);
              }}>
                <MapIcon size={16} />
                Show All Zones
              </CancelButton>
              <ConfirmButton onClick={handleColumnAssignConfirm}>
                <Check size={16} />
                Use Suggestion
              </ConfirmButton>
            </QuickAssignButtons>
          </QuickAssignContent>
        </QuickAssignDialog>
      );
    };

    /**
     * Render the previous storage dialog
     */
    const renderPreviousStorageDialog = () => {
      if (!showPreviousStorageDialog || !previousStorageData) return null;

      return (
        <QuickAssignDialog>
          <QuickAssignContent>
            <QuickAssignTitle>
              <ArrowRight size={20} />
              Previous Storage Location Found
            </QuickAssignTitle>
            
            <QuickAssignInfo>
              <p><strong>Zone:</strong> {previousStorageData.zoneName}</p>
              <p><strong>Row:</strong> {previousStorageData.rowIndex + 1}</p>
            </QuickAssignInfo>

            <QuickAssignButtons>
              <CancelButton onClick={() => {
                setShowPreviousStorageDialog(false);
                setShowStorageModal(true);
              }}>
                <X size={16} />
                Choose Different
              </CancelButton>
              <ConfirmButton onClick={handleUsePreviousStorage}>
                <Check size={16} />
                Use Same Location
              </ConfirmButton>
            </QuickAssignButtons>
          </QuickAssignContent>
        </QuickAssignDialog>
      );
    };

    return (
      <>
        <FinishMenuComponent
          {...props}
          onFinishPallet={handleFinishPalletWithStorage}
        />
        
        {renderColumnAssignDialog()}
        {renderPreviousStorageDialog()}
        
        <MobileStorageAssignmentModal
          isOpen={showStorageModal}
          onClose={() => setShowStorageModal(false)}
          pallet={newlyCreatedPallet}
          pending={false}
          onAssign={handleStorageAssignment}
          locationId={props.locationId}
        />
      </>
    );
  };
};

import styled from 'styled-components';
import { theme } from '@styles/theme';

export const TallyCard = styled.div`
  background: ${theme.colors.background.paper};
  border-radius: ${theme.borderRadius.lg};
  padding: 1.5rem;
  margin-top: 1.5rem;
  box-shadow: ${theme.shadows.sm};
`;

export const TallyControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const TallyToggle = styled.div`
  display: flex;
  gap: 0.5rem;

  button {
    padding: 0.5rem 1rem;
    border: 1px solid ${theme.colors.border.default};
    border-radius: ${theme.borderRadius.md};
    background: transparent;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      background: ${theme.colors.primary.main};
      color: white;
      border-color: ${theme.colors.primary.main};
    }

    &:hover:not(.active) {
      background: ${theme.colors.background.hover};
    }
  }
`;

export const TallyButton = styled.button`
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: 1px solid ${theme.colors.border.default};
  border-radius: ${theme.borderRadius.md};
  background: ${theme.colors.background.paper};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${theme.colors.background.hover};
  }
`;

export const TallyGrid = styled.div`
  border: 1px solid ${theme.colors.border.default};
  border-radius: ${theme.borderRadius.md};
  overflow: hidden;
`;

export const TallyHeader = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(6, 1fr) 100px;
  background: ${theme.colors.background.secondary};
  border-bottom: 1px solid ${theme.colors.border.default};

  > div {
    padding: 0.75rem;
    text-align: center;
    font-weight: 600;
    border-right: 1px solid ${theme.colors.border.default};

    &:last-child {
      border-right: none;
    }
  }
`;

export const TallySection = styled.div`
  .section-header {
    padding: 0.75rem;
    background: ${theme.colors.background.hover};
    font-weight: 600;
    border-bottom: 1px solid ${theme.colors.border.default};
  }
`;

export const TallyRow = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(6, 1fr) 100px;
  border-bottom: 1px solid ${theme.colors.border.default};

  &:last-child {
    border-bottom: none;
  }

  .row-type {
    padding: 0.75rem;
    background: ${theme.colors.background.hover};
    border-right: 1px solid ${theme.colors.border.default};
  }
`;

export const TallyCell = styled.div`
  padding: 0.75rem;
  text-align: center;
  border-right: 1px solid ${theme.colors.border.default};

  &:last-child {
    border-right: none;
  }

  &.total {
    background: ${theme.colors.background.hover};
    font-weight: 600;
  }
`;

export const TallyTotal = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(6, 1fr) 100px;
  background: ${theme.colors.background.secondary};
  border-top: 2px solid ${theme.colors.border.default};
  font-weight: 600;

  > div {
    padding: 0.75rem;
    text-align: center;
    border-right: 1px solid ${theme.colors.border.default};

    &:last-child {
      border-right: none;
    }
  }
`;

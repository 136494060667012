/**
 * Position Model
 * 
 * Standardized representation of a position within a storage zone
 * with validation, normalization and utility functions.
 */

/**
 * @typedef {Object} Position
 * @property {number} row - The row index (0-based)
 * @property {number} col - The column index (0-based)
 */

/**
 * @typedef {Object} PositionWithZone
 * @property {number} row - The row index (0-based)
 * @property {number} col - The column index (0-based)
 * @property {string} zoneId - The zone identifier
 */

/**
 * @typedef {Object} LegacyPosition
 * @property {number} rowIndex - The row index (0-based) - Legacy property
 * @property {number} colIndex - The column index (0-based) - Legacy property
 * @property {number} [column] - Alias for colIndex in some legacy contexts
 */

// Position key format constants
export const POSITION_KEY_FORMATS = {
  STANDARD: 'STANDARD', // row-col
  UNDERSCORE: 'UNDERSCORE', // row_col
  ROWCOL: 'ROWCOL' // rXcY
};

// Default position key format
export const DEFAULT_POSITION_KEY_FORMAT = POSITION_KEY_FORMATS.STANDARD;

/**
 * Create a standardized position object
 * @param {number} row - The row index
 * @param {number} col - The column index
 * @returns {Position} A standardized position object
 */
export function createPosition(row, col) {
  return {
    row: ensureNonNegativeInteger(row),
    col: ensureNonNegativeInteger(col)
  };
}

/**
 * Create a position with zone information
 * @param {number} row - The row index
 * @param {number} col - The column index
 * @param {string} zoneId - The zone identifier
 * @returns {PositionWithZone} A position object with zone information
 */
export function createPositionWithZone(row, col, zoneId) {
  return {
    ...createPosition(row, col),
    zoneId: String(zoneId)
  };
}

/**
 * Add legacy position properties for backward compatibility
 * @param {Position} position - Standardized position object
 * @returns {Position & LegacyPosition} Position with legacy properties
 */
export function addLegacyProperties(position) {
  if (!position) return null;
  
  return {
    ...position,
    rowIndex: position.row,
    colIndex: position.col,
    column: position.col // Some legacy code uses 'column' instead of 'col'
  };
}

/**
 * Normalize a position object to ensure it has the standard properties
 * @param {Object} position - The position object to normalize
 * @returns {Position} A standardized position object
 */
export function normalizePosition(position) {
  if (!position) return createPosition(0, 0);
  
  // Extract row and column values, handling all possible property names
  const row = getPositionValue(position, ['row', 'rowIndex', 'r']);
  const col = getPositionValue(position, ['col', 'colIndex', 'column', 'c']);
  
  return createPosition(row, col);
}

/**
 * Extract a value from a position object trying multiple property names
 * @param {Object} position - The position object
 * @param {string[]} propertyNames - Array of property names to try
 * @returns {number} The value found or 0 if not found
 */
function getPositionValue(position, propertyNames) {
  for (const prop of propertyNames) {
    if (position[prop] !== undefined) {
      const value = position[prop];
      return ensureNonNegativeInteger(value);
    }
  }
  return 0;
}

/**
 * Generate a standardized position key for a position
 * @param {Position|Object} position - The position object
 * @param {string} [format] - The format to use (from POSITION_KEY_FORMATS)
 * @returns {string} A standardized position key
 */
export function getPositionKey(position, format = DEFAULT_POSITION_KEY_FORMAT) {
  const { row, col } = normalizePosition(position);
  
  switch (format) {
    case POSITION_KEY_FORMATS.UNDERSCORE:
      return `${row}_${col}`;
    case POSITION_KEY_FORMATS.ROWCOL:
      return `r${row}c${col}`;
    case POSITION_KEY_FORMATS.STANDARD:
    default:
      return `${row}-${col}`;
  }
}

/**
 * Parse a position key into a position object
 * @param {string} key - The position key to parse
 * @returns {Position|null} A position object or null if the key is invalid
 */
export function parsePositionKey(key) {
  if (!key || typeof key !== 'string') return null;
  
  // Try standard format (row-col)
  let match = key.match(/^(\d+)-(\d+)$/);
  if (match) {
    return createPosition(parseInt(match[1], 10), parseInt(match[2], 10));
  }
  
  // Try underscore format (row_col)
  match = key.match(/^(\d+)_(\d+)$/);
  if (match) {
    return createPosition(parseInt(match[1], 10), parseInt(match[2], 10));
  }
  
  // Try rowcol format (rXcY)
  match = key.match(/^r(\d+)c(\d+)$/);
  if (match) {
    return createPosition(parseInt(match[1], 10), parseInt(match[2], 10));
  }
  
  return null;
}

/**
 * Validate a position object
 * @param {Object} position - The position object to validate
 * @returns {boolean} Whether the position is valid
 */
export function isValidPosition(position) {
  if (!position || typeof position !== 'object') return false;
  
  // Check if it has either the standard or legacy properties
  const hasStandardProps = 
    typeof position.row === 'number' && 
    typeof position.col === 'number';
    
  const hasLegacyProps = 
    typeof position.rowIndex === 'number' && 
    (typeof position.colIndex === 'number' || typeof position.column === 'number');
    
  return hasStandardProps || hasLegacyProps;
}

/**
 * Ensure a value is a non-negative integer
 * @param {any} value - The value to check
 * @returns {number} A non-negative integer
 */
function ensureNonNegativeInteger(value) {
  const num = parseInt(value, 10);
  return isNaN(num) ? 0 : Math.max(0, num);
}

/**
 * Get a global position key that includes the zone
 * @param {string} zoneId - The zone identifier
 * @param {Position|Object} position - The position object
 * @returns {string} A global position key
 */
export function getGlobalPositionKey(zoneId, position) {
  const { row, col } = normalizePosition(position);
  return `${zoneId}-${row}-${col}`;
}

/**
 * Parse a global position key
 * @param {string} key - The global position key
 * @returns {PositionWithZone|null} A position with zone or null if invalid
 */
export function parseGlobalPositionKey(key) {
  if (!key || typeof key !== 'string') return null;
  
  const parts = key.split('-');
  if (parts.length < 3) return null;
  
  const row = parseInt(parts[parts.length - 2], 10);
  const col = parseInt(parts[parts.length - 1], 10);
  
  if (isNaN(row) || isNaN(col)) return null;
  
  // Zone ID could contain hyphens, so we need to rejoin all parts except the last two
  const zoneId = parts.slice(0, parts.length - 2).join('-');
  
  return createPositionWithZone(row, col, zoneId);
}

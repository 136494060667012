import React, { useState, useEffect, useCallback } from 'react';
import kleinpakSSEManager from '../../../../lib/services/sse/KleinpakSSEManager';
import styled, { keyframes } from 'styled-components';
import { MapPin, CheckCircle, QrCode, ArrowRight, AlertCircle, ThumbsUp, Award, Trophy } from 'lucide-react';
import { api } from '@lib/api';
import QRScanner from '@features/shared/components/QRScanner';
import storageAssignmentService from '@features/map/services/storageAssignmentService';
import usePersistentState from '@lib/hooks/usePersistentState';
import ZoneHighlightMap from './components/ZoneHighlightMap';

// Modal components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 16px;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
`;

const ModalTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  
  &:hover {
    background: #f5f5f5;
  }
`;

const ModalBody = styled.div`
  padding: 20px;
  flex: 1;
  overflow-y: auto;
`;

const ModalFooter = styled.div`
  padding: 16px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(Button)`
  background: #0066cc;
  color: white;
  
  &:hover:not(:disabled) {
    background: #0055aa;
  }
`;

const SecondaryButton = styled(Button)`
  background: #f0f0f0;
  color: #333;
  
  &:hover:not(:disabled) {
    background: #e0e0e0;
  }
`;

// Zone view components
const ZoneInstructionCard = styled.div`
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  text-align: center;
`;

const ZoneHighlight = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #fee2e2;
  border-radius: 6px;
  color: #b91c1c;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 12px 0;
  border: 1px solid rgba(220, 38, 38, 0.3);
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.1);
  
  /* Responsive font size */
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 6px 12px;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 280px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
`;

const ZoneInstructions = styled.div`
  margin-top: 12px;
  font-size: 0.9rem;
  color: #555;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
`;

const StepDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ active }) => active ? '#0066cc' : '#ddd'};
`;

// Pallet list components
const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
  margin: 20px 0;
`;

// Animation keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounceIn = keyframes`
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.05); opacity: 0.9; }
  70% { transform: scale(0.9); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const PalletCard = styled.div`
  background: ${props => {
    if (props.isAssignedToCurrentOrder) return '#e8f5e9'; // Green background for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#ffebee'; // Red background for pallets assigned to other orders
    return '#f5f9ff'; // Default blue background
  }};
  border: 2px solid ${props => {
    if (props.isAssignedToCurrentOrder) return '#4caf50'; // Green border for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#ef5350'; // Red border for pallets assigned to other orders
    return '#0066cc'; // Default blue border
  }};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  
  color: ${props => {
    if (props.isAssignedToCurrentOrder) return '#1b5e20'; // Green text for pallets assigned to this order
    if (props.isAssignedToOtherOrder) return '#d32f2f'; // Red text for pallets assigned to other orders
    return 'inherit'; // Default text color
  }};
`;

const ScannedOverlay = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ef5350;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${bounceIn} 0.5s ease-out;
`;

const PalletIdLarge = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 8px;
  word-break: break-all;
`;

const StatusBadge = styled.div`
  background: ${props => props.isScanned ? '#ffcdd2' : '#e8f5e9'};
  color: ${props => props.isScanned ? '#d32f2f' : '#2e7d32'};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.3s ease;
`;

// Celebration overlay
const CelebrationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 118, 210, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const CelebrationContent = styled.div`
  background: white;
  border-radius: 16px;
  padding: 32px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  animation: ${bounceIn} 0.6s ease-out;
`;

const CelebrationTitle = styled.h2`
  margin: 0 0 16px;
  color: #2e7d32;
  font-size: 1.8rem;
`;

const CelebrationMessage = styled.p`
  margin: 0 0 24px;
  color: #455a64;
  font-size: 1.1rem;
  line-height: 1.5;
`;

const TrophyIcon = styled.div`
  color: #ffc107;
  margin-bottom: 20px;
  animation: ${spin} 2s ease-out;
  svg {
    height: 64px;
    width: 64px;
  }
`;

const CompletionButton = styled(PrimaryButton)`
  background: #2e7d32;
  padding: 12px 24px;
  font-size: 1.1rem;
  
  &:hover:not(:disabled) {
    background: #1b5e20;
  }
`;

const ScanSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScanButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #0066cc;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 16px;
  
  &:hover {
    background: #0055aa;
  }
`;

// Define steps for the workflow
const STEPS = {
  SHOW_ZONE: 'SHOW_ZONE',        // Step 1: Show storage map with zone
  LIST_PALLETS: 'LIST_PALLETS',   // Step 2: Show pallets in that zone
  SCAN_PALLET: 'SCAN_PALLET'      // Step 3: Scan pallet confirmation
};

// Helper function to get a formatted display name for process stages
const getProcessStageDisplay = (processStage) => {
  if (!processStage) return 'Ready';
  
  // Convert camelCase or snake_case to Title Case with spaces
  return processStage
    .replace(/_/g, ' ')  // Replace underscores with spaces
    .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
    .replace(/^\w/, c => c.toUpperCase())  // Capitalize first letter
    .trim();  // Remove any extra spaces
};

const PalletLocationWorkflow = ({ 
  isOpen, 
  onClose, 
  zoneName, 
  pallets, 
  order,
  locationId,
  onScanComplete
}) => {
  // Persist current state across refreshes with a unique key based on order and zone
  const persistenceKey = `workflow_state_${locationId}_${order?.id}_${zoneName}`;
  
  // Persisted state
  const [currentStep, setCurrentStep] = usePersistentState(
    `${persistenceKey}_step`, 
    STEPS.SHOW_ZONE
  );
  const [scannedPalletIds, setScannedPalletIds] = usePersistentState(
    `${persistenceKey}_scanned_pallets`, 
    []
  );
  const [showCompletionCelebration, setShowCompletionCelebration] = usePersistentState(
    `${persistenceKey}_completion`, 
    false
  );
  
  // Track assignment status of all pallets
  const [palletAssignments, setPalletAssignments] = useState({});
  
  // Local state
  const [showScanner, setShowScanner] = useState(false);
  const [recentlyScannedId, setRecentlyScannedId] = useState(null);
  
  // Reset step when modal is opened with a new zone/order
  useEffect(() => {
    if (isOpen && !currentStep) {
      setCurrentStep(STEPS.SHOW_ZONE);
      setShowScanner(false);
    }
  }, [isOpen, currentStep, setCurrentStep]);
  
  // Check if all pallets are scanned
  const allPalletsScanned = usePersistentState(
    `${persistenceKey}_all_scanned`,
    false
  );
  
  // Handler for pallet assignment updates
  const handlePalletAssignmentUpdate = useCallback((data) => {
    if (!data || (!data.palletIds && !data.palletId)) return;
    
    const palletIds = data.palletIds || [data.palletId];
    const orderId = String(data.orderId);
    
    console.log(`PalletLocationWorkflow received assignment update for ${palletIds.length} pallets to order ${orderId}`);
    
    // Update our record of pallet assignments
    setPalletAssignments(prev => {
      const updated = { ...prev };
      palletIds.forEach(palletId => {
        updated[palletId] = {
          orderId,
          processStage: 'Processing' 
        };
      });
      return updated;
    });
  }, []);
  
  // Handler for pallet updates
  const handlePalletUpdate = useCallback((data) => {
    if (!data || !data.pallets || !Array.isArray(data.pallets)) return;
    
    console.log(`PalletLocationWorkflow received updates for ${data.pallets.length} pallets`);
    
    // Update our records with the latest pallet information
    setPalletAssignments(prev => {
      const updated = { ...prev };
      
      data.pallets.forEach(pallet => {
        if (!pallet.id) return;
        
        const palletId = String(pallet.id);
        const orderId = pallet.orderId || pallet.order_id;
        const processStage = pallet.processStage || 'Unprocessed';
        
        updated[palletId] = {
          ...updated[palletId],
          orderId: orderId ? String(orderId) : null,
          processStage
        };
      });
      
      return updated;
    });
  }, []);
  
  // Subscribe to SSE updates
  useEffect(() => {
    if (!isOpen || !locationId) return;
    
    // Initialize the SSE connection
    kleinpakSSEManager.initialize(locationId);
    
    // Subscribe to assignment updates
    const assignmentSubscriptionId = kleinpakSSEManager.subscribe('assignmentUpdate', handlePalletAssignmentUpdate);
    
    // Subscribe to pallet updates
    const palletSubscriptionId = kleinpakSSEManager.subscribe('palletUpdate', handlePalletUpdate);
    
    // Initialize palletAssignments from existing pallets data
    setPalletAssignments(prev => {
      const initialAssignments = { ...prev };
      
      if (pallets && Array.isArray(pallets)) {
        pallets.forEach(pallet => {
          if (!pallet.id) return;
          
          initialAssignments[pallet.id] = {
            orderId: pallet.order_id || pallet.orderId || null,
            processStage: pallet.processStage || 'Unprocessed'
          };
        });
      }
      
      return initialAssignments;
    });
    
    // Clean up subscriptions
    return () => {
      kleinpakSSEManager.unsubscribe('assignmentUpdate', assignmentSubscriptionId);
      kleinpakSSEManager.unsubscribe('palletUpdate', palletSubscriptionId);
    };
  }, [isOpen, locationId, pallets, handlePalletAssignmentUpdate, handlePalletUpdate]);
  
  // Determine scan status and assignment status for each pallet
  const palletsWithStatus = pallets.map(pallet => {
    const palletId = String(pallet.id);
    const isScanned = scannedPalletIds.includes(palletId);
    const assignment = palletAssignments[palletId] || {};
    
    // Check if assigned to current order
    const isAssignedToCurrentOrder = assignment.orderId === String(order?.id) && 
                                   assignment.processStage === 'Processing';
    
    // Check if assigned to a different order
    const isAssignedToOtherOrder = assignment.orderId && 
                                 assignment.orderId !== String(order?.id) && 
                                 assignment.processStage === 'Processing';
    
    return {
      ...pallet,
      isScanned,
      isAssignedToCurrentOrder,
      isAssignedToOtherOrder,
      processStage: assignment.processStage || pallet.processStage || 'Unprocessed'
    };
  });
  
  // Update the all pallets scanned status
  useEffect(() => {
    if (pallets.length > 0 && scannedPalletIds.length >= pallets.length) {
      setShowCompletionCelebration(true);
    }
  }, [pallets.length, scannedPalletIds.length, setShowCompletionCelebration]);
  
  const handleNext = () => {
    if (currentStep === STEPS.SHOW_ZONE) {
      setCurrentStep(STEPS.LIST_PALLETS);
    } else if (currentStep === STEPS.LIST_PALLETS) {
      setCurrentStep(STEPS.SCAN_PALLET);
    }
  };
  
  const handleBack = () => {
    if (currentStep === STEPS.LIST_PALLETS) {
      setCurrentStep(STEPS.SHOW_ZONE);
    } else if (currentStep === STEPS.SCAN_PALLET) {
      setCurrentStep(STEPS.LIST_PALLETS);
    }
  };
  
  const handleStartScan = () => {
    // Completely disable SSE before activating scanner to free up resources
    kleinpakSSEManager.enableScanningMode();
    console.log('PalletLocationWorkflow: Disabled SSE for scanning mode');
    setShowScanner(true);
  };
  
  const handleScanResult = async (scanData) => {
    setShowScanner(false);
    
    // Re-enable SSE connection as we're done with the scanner
    setTimeout(() => {
      kleinpakSSEManager.disableScanningMode();
      console.log('PalletLocationWorkflow: Re-enabled SSE after successful scan');
    }, 500); // Small delay to ensure UI updates first
    
    try {
      if (!scanData || !scanData.id) {
        throw new Error('Invalid scan data');
      }
      
      // Check if this pallet is already scanned
      if (scannedPalletIds.includes(String(scanData.id))) {
        alert(`Pallet #${scanData.id} has already been scanned!`);
        return;
      }
      
      // Check if this pallet is allowed for this order
      const isPalletAllowed = order.allowed_pallet_ids 
        ? (order.allowed_pallet_ids.includes(scanData.id) || 
           order.allowed_pallet_ids.includes(String(scanData.id)))
        : true;
      
      if (!isPalletAllowed) {
        throw new Error(`Pallet #${scanData.id} is not allowed for this order`);
      }
      
      // Check if this pallet is from the correct zone - enhanced checking
      const isPalletInZone = pallets.some(p => {
        // Convert both IDs to strings for reliable comparison
        return String(p.id) === String(scanData.id) || p.id === scanData.id;
      });
      
      if (!isPalletInZone) {
        throw new Error(`Pallet #${scanData.id} is not located in zone ${zoneName}`);
      }
      
      // All checks passed, proceed with assigning the pallet and updating its status
      // 1. Assign pallet to order
      await api.post(`/api/pallets/${scanData.id}/assign`, {
        orderId: order.id,
        locationId,
        processStage: 'in_processing'
      });
      
      // 2. Update pallet process stage
      await api.patch(`/api/pallets/${scanData.id}`, {
        processStage: 'in_processing',
        locationId
      });
      
      // 3. Remove from storage position if it's stored
      await storageAssignmentService.removePalletFromStorage(
        scanData.id, 
        'Assigned to order and processing'
      );
      
      // 4. Use kleinpakSSEManager to process the assignment (this will emit the legacy event too)
      kleinpakSSEManager.processPalletAssignment({
        palletId: scanData.id,
        orderId: order.id,
        processStage: 'Processing',
        zone: zoneName
      });
      
      console.log(`Pallet #${scanData.id} assigned to order #${order.id} in zone ${zoneName}`);
      
      // 5. Add to scanned pallets list
      setScannedPalletIds(prev => [...prev, String(scanData.id)]);
      setRecentlyScannedId(String(scanData.id));
      
      // 6. Call the parent component callback
      onScanComplete(scanData.id);
      
      // 7. Check if all pallets have been scanned
      const updatedScannedCount = scannedPalletIds.length + 1;
      if (updatedScannedCount >= pallets.length) {
        setTimeout(() => {
          setShowCompletionCelebration(true);
        }, 1000);
      }
    } catch (error) {
      console.error('Scan error:', error);
      alert(error.message || 'Error processing scan');
      // Keep the modal open to allow another attempt
    }
  };
  
  // Reset when closed
  const handleClose = () => {
    onClose();
  };
  
  // Handle completion
  const handleCompletionContinue = () => {
    setShowCompletionCelebration(false);
    onClose();
  };
  
  const renderStepIndicator = () => (
    <StepIndicator>
      <StepDot active={currentStep === STEPS.SHOW_ZONE} />
      <StepDot active={currentStep === STEPS.LIST_PALLETS} />
      <StepDot active={currentStep === STEPS.SCAN_PALLET} />
    </StepIndicator>
  );
  
// Helper function to normalize zone names for precise matching
const normalizeZoneName = (name) => {
  if (!name) return '';
  
  // If it's already in the correct format, return as is
  if (/^Zone \d+(\.\d+)?$/i.test(name)) {
    // Make sure "Zone" is capitalized and there's exactly one space after it
    return name.replace(/zone\s+/i, 'Zone ');
  }
  
  // Try to extract zone number from different formats (zone-1, zone1, zone_1)
  const match = name.toLowerCase().match(/zone[\s\-_]?(\d+(?:\.\d+)?)/);
  if (match) {
    // Ensure we return a consistently formatted string: "Zone X"
    // We use exactly one space between "Zone" and the number
    return `Zone ${match[1]}`;
  }
  
  // For non-standard zone names, return as is
  return name;
};

const renderZoneStep = () => (
  <>
    {renderStepIndicator()}
    
    <ZoneInstructionCard>
      <MapPin size={24} color="#dc2626" style={{ marginBottom: '8px' }} />
      <h3>Navigate to Zone</h3>
      
      <ZoneHighlight style={{ background: '#fee2e2', color: '#b91c1c' }}>
        <MapPin size={20} />
        {zoneName}
      </ZoneHighlight>
      
      <div style={{ width: '100%', marginTop: '15px', marginBottom: '15px' }}>
        <ZoneHighlightMap 
          highlightedZone={normalizeZoneName(zoneName)}
          interactive={false}
          customHeight="45vh"
          mobileHeight="35vh"
        />
      </div>
      
      <ZoneInstructions>
        Go to the highlighted zone in the warehouse. Look for the <strong style={{ color: '#dc2626' }}>red area</strong> on the map. Click 'Next' when you've arrived.
      </ZoneInstructions>
    </ZoneInstructionCard>
  </>
);
  
  const renderPalletsStep = () => (
    <>
      {renderStepIndicator()}
      
      <div>
        <h3>Pallets in Zone {zoneName}</h3>
        <p>Find and scan one of these pallets to assign it to the order:</p>
        
        <PalletGrid>
          {palletsWithStatus.map(pallet => (
            <PalletCard 
              key={pallet.id}
              isScanned={pallet.isScanned}
              isAssignedToCurrentOrder={pallet.isAssignedToCurrentOrder}
              isAssignedToOtherOrder={pallet.isAssignedToOtherOrder}
              style={pallet.id === recentlyScannedId ? { transform: 'scale(1.05)', boxShadow: '0 0 15px rgba(239, 83, 80, 0.5)' } : {}}
            >
              {pallet.isScanned && (
                <ScannedOverlay>
                  <CheckCircle size={16} />
                </ScannedOverlay>
              )}
              <PalletIdLarge>#{pallet.id}</PalletIdLarge>
<StatusBadge 
  isScanned={pallet.isScanned}
  isAssignedToCurrentOrder={pallet.isAssignedToCurrentOrder}
  isAssignedToOtherOrder={pallet.isAssignedToOtherOrder}
  style={{
    background: pallet.isAssignedToCurrentOrder ? '#e8f5e9' : 
               pallet.isAssignedToOtherOrder ? '#ffebee' : '#f1f5f9',
    color: pallet.isAssignedToCurrentOrder ? '#1b5e20' : 
           pallet.isAssignedToOtherOrder ? '#d32f2f' : '#455a64'
  }}
>
                <CheckCircle size={12} />
                {pallet.isScanned || pallet.isAssignedToCurrentOrder || pallet.isAssignedToOtherOrder
                  ? 'Processing' 
                  : getProcessStageDisplay(pallet.processStage)}
              </StatusBadge>
            </PalletCard>
          ))}
        </PalletGrid>
        
        <ScanSection>
          <p>Found a pallet? Click the button below to scan it:</p>
          <ScanButton onClick={handleStartScan}>
            <QrCode size={20} />
            Scan Pallet
          </ScanButton>
        </ScanSection>
        
          {/* Progress display removed as it's redundant */}
      </div>
    </>
  );
  
  const renderScanStep = () => (
    <>
      {renderStepIndicator()}
      
      <div style={{ textAlign: 'center' }}>
        <h3>Scan a Pallet</h3>
        <p>Position the QR code within the scanner frame to assign the pallet to this order.</p>
        <p>The pallet will be removed from storage and assigned to the current order.</p>
        
        <ScanButton 
          onClick={handleStartScan}
          style={{ margin: '20px auto' }}
        >
          <QrCode size={20} />
          Open Scanner
        </ScanButton>
      </div>
    </>
  );
  
  // Don't render if not open
  if (!isOpen) return null;
  
  // If scanner is active, render it full screen
  // Handle scanner close (re-enable SSE)
  const handleCloseScanner = () => {
    setShowScanner(false);
    
    // Re-enable SSE when scanner is closed
    setTimeout(() => {
      kleinpakSSEManager.disableScanningMode();
      console.log('PalletLocationWorkflow: Re-enabled SSE after scanner closed');
    }, 500); 
  };
  
  if (showScanner) {
    return (
      <ModalOverlay>
        <ModalContent style={{ maxWidth: '95vw', width: '100%', maxHeight: '95vh', height: '100%' }}>
          <ModalHeader>
            <ModalTitle>Scan Pallet in Zone {zoneName}</ModalTitle>
            <CloseButton onClick={handleCloseScanner}>×</CloseButton>
          </ModalHeader>
          <QRScanner 
            onScan={handleScanResult}
            onManualInputRequest={() => {
              const palletId = prompt('Enter pallet ID:');
              if (palletId) {
                handleScanResult({ id: palletId });
              } else {
                handleCloseScanner();
              }
            }}
            onClose={handleCloseScanner}
          />
        </ModalContent>
      </ModalOverlay>
    );
  }
  
  return (
    <>
      {/* Celebration modal - shown when all pallets are scanned */}
      {showCompletionCelebration && (
        <CelebrationOverlay>
          <CelebrationContent>
            <TrophyIcon>
              <Trophy />
            </TrophyIcon>
            <CelebrationTitle>Mission Accomplished!</CelebrationTitle>
            <CelebrationMessage>
              Great job! You've successfully scanned all {pallets.length} pallets from the {zoneName} zone.
              The pallets have been marked as "Processing" and assigned to the order.
            </CelebrationMessage>
            <CompletionButton onClick={handleCompletionContinue}>
              Continue
            </CompletionButton>
          </CelebrationContent>
        </CelebrationOverlay>
      )}
      
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>
              {currentStep === STEPS.SHOW_ZONE && 'Navigate to Zone'}
              {currentStep === STEPS.LIST_PALLETS && 'Pallets in Zone'}
              {currentStep === STEPS.SCAN_PALLET && 'Scan Pallet'}
            </ModalTitle>
            <CloseButton onClick={handleClose}>×</CloseButton>
          </ModalHeader>
          
          <ModalBody>
            {currentStep === STEPS.SHOW_ZONE && renderZoneStep()}
            {currentStep === STEPS.LIST_PALLETS && renderPalletsStep()}
            {currentStep === STEPS.SCAN_PALLET && renderScanStep()}
          </ModalBody>
          
          <ModalFooter>
            {currentStep !== STEPS.SHOW_ZONE && (
              <SecondaryButton onClick={handleBack}>
                Back
              </SecondaryButton>
            )}
            
            <SecondaryButton onClick={handleClose}>
              Cancel
            </SecondaryButton>
            
            {currentStep === STEPS.SHOW_ZONE && (
              <PrimaryButton onClick={handleNext}>
                Next
              </PrimaryButton>
            )}
            
            {currentStep === STEPS.LIST_PALLETS && (
              <PrimaryButton onClick={handleStartScan}>
                Scan Pallet
              </PrimaryButton>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

export default PalletLocationWorkflow;

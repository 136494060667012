import React from 'react';
import { useLocation } from 'react-router-dom';
import { Package, Truck, Archive, Map } from 'lucide-react';
import { usePermission } from '@lib/contexts';
import {
  NavigationContainer,
  NavItem,
  IconWrapper,
  Label
} from './styles';

const consoles = [
  { 
    id: 'sorting', 
    label: 'Sorting', 
    icon: (active) => <Package size={18} color={active ? '#E53935' : '#495057'} />, 
    path: '/mobile/operator/sorting',
    permissionName: 'sorting_console'
  },
  { 
    id: 'logistics', 
    label: 'Logistics', 
    icon: (active) => <Truck size={18} color={active ? '#E53935' : '#495057'} />, 
    path: '/mobile/operator/logistics',
    permissionName: 'logistics_console'
  },
  { 
    id: 'kleinpak', 
    label: 'Kleinpak', 
    icon: (active) => <Archive size={18} color={active ? '#E53935' : '#495057'} />, 
    path: '/mobile/operator/kleinpak',
    permissionName: 'kleinpak_console'
  },
  { 
    id: 'storage', 
    label: 'Storage', 
    icon: (active) => <Map size={18} color={active ? '#E53935' : '#495057'} />, 
    path: '/mobile/operator/storage',
    permissionName: 'storage_console'
  }
];

export const ConsoleNavigation = ({ activeConsole, onConsoleChange }) => {
  const location = useLocation();
  const { hasConsoleAccess, hasRestriction, permissions } = usePermission();
  const currentPath = location.pathname;

  // Filter consoles based on user permissions
  const availableConsoles = consoles.filter(consoleItem => {
    // Use the hasConsoleAccess function from PermissionContext which now handles
    // the proper mapping between restriction key formats
    const hasAccess = hasConsoleAccess(consoleItem.id);
    
    // Add more verbose logging to help debug restriction issues
    console.log(`Console ${consoleItem.id} access check:`, {
      consoleId: consoleItem.id,
      permissionName: consoleItem.permissionName,
      restrictionKey: `restrict_${consoleItem.id}_console`,
      permissions: { ...permissions }, // Create a copy to avoid circular reference 
      hasAccess,
      activeConsole
    });
    
    return hasAccess;
  });
  
  // If no consoles are available (all restricted), default to showing them all
  // This is a fallback to prevent users from getting stuck with no navigation
  const displayConsoles = availableConsoles.length > 0 ? availableConsoles : consoles;

  return (
    <NavigationContainer>
      {displayConsoles.map(({ id, label, icon }) => (
        <NavItem
          key={id}
          active={activeConsole === id}
          onClick={() => onConsoleChange(id)}
          aria-label={`Switch to ${label} console`}
          aria-pressed={activeConsole === id}
          data-testid={`console-nav-${id}`}
        >
          <IconWrapper active={activeConsole === id}>
            {icon(activeConsole === id)}
          </IconWrapper>
          <Label active={activeConsole === id}>
            {label}
          </Label>
        </NavItem>
      ))}
    </NavigationContainer>
  );
};

export default ConsoleNavigation;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DEFAULT_ZONES } from '../constants/zoneConfig.v2';
import ZoneGridView from './ZoneGridView';
import GridOverlay from './GridOverlay';
import ZoneItem from './ZoneItem';
import ZoneExportModal from './ZoneExportModal';
import storageAssignmentService from '../services/storageAssignmentService';
import { zoneUpdateService, ZONE_UPDATE_EVENTS } from '../services/zoneUpdateService';
import { Box, RefreshCw, Grid, Save, Move } from 'lucide-react';
import { useToast } from '@lib/contexts';
import { useDashboard } from '@features/dashboard/contexts/DashboardContext';
import {
  MapWrapper, 
  MapHeader, 
  Title,
  Controls,
  Button,
  LocationWarning,
  MapContainer,
  WarehouseLayout
} from './MapView.styles';

// Main component
const MapView = () => {
  const [selectedZone, setSelectedZone] = useState(null);
  const [zoneOccupancy, setZoneOccupancy] = useState({});
  const [showDetailView, setShowDetailView] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showGrid, setShowGrid] = useState(true); // Show grid by default
  const [editMode, setEditMode] = useState(false); // Edit mode for dragging zones
  const [currentZones, setCurrentZones] = useState(DEFAULT_ZONES);
  const [draggedZone, setDraggedZone] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [showExportModal, setShowExportModal] = useState(false);
  
  const warehouseLayoutRef = useRef(null);
  const { showToast } = useToast();
  const { selectedLocation } = useDashboard();
  
  // Load initial storage state
  useEffect(() => {
    const loadStorageData = async () => {
      // Reset error state
      setError(null);
      
      // Check if location is selected
      if (!selectedLocation) {
        setZoneOccupancy({});
        setError('No location selected. Please select a location to view storage data.');
        return;
      }
      
      try {
        setLoading(true);
        
        // Get all zones with occupancy data from the storage service - now with await and passing locationId
        const zonesWithOccupancy = await storageAssignmentService.getAllZonesWithOccupancy(selectedLocation);
        
        // If we get a valid array response, convert to a map for easier lookup
        if (Array.isArray(zonesWithOccupancy)) {
          const occupancyMap = zonesWithOccupancy.reduce((acc, zone) => {
            acc[zone.id] = {
              occupancy: zone.occupancy,
              occupiedCount: zone.occupiedCount,
              occupancyPercentage: zone.occupancyPercentage
            };
            return acc;
          }, {});
          
          setZoneOccupancy(occupancyMap);
        } else {
          // Handle non-array response
          console.error('Invalid response from getAllZonesWithOccupancy:', zonesWithOccupancy);
          setError('Failed to load storage data. Please try again later.');
          setZoneOccupancy({});
        }
      } catch (err) {
        console.error('Error loading storage data:', err);
        setError('Failed to load storage data. Please check your connection and try again.');
        setZoneOccupancy({});
      } finally {
        setLoading(false);
      }
    };
    
    loadStorageData();
  }, [refreshTrigger, selectedLocation]); 
  
  // Initialize current zones
  useEffect(() => {
    setCurrentZones(DEFAULT_ZONES);
  }, []);
  
  // Subscribe to zone update events
  useEffect(() => {
    if (!selectedLocation) return;
    
    // Initialize the zone update service with the current location
    zoneUpdateService.initialize(selectedLocation);
    
    // Function to refresh data when zone updates occur
    const handleZoneUpdate = () => {
      console.log('MapView: Zone update detected, refreshing data');
      setRefreshTrigger(prev => prev + 1);
    };
    
    // Subscribe to all relevant update events
    const palletAddedUnsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.PALLET_ADDED, 
      handleZoneUpdate
    );
    
    const palletRemovedUnsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.PALLET_REMOVED, 
      handleZoneUpdate
    );
    
    const palletMovedUnsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.PALLET_MOVED, 
      handleZoneUpdate
    );
    
    const zoneAssignmentUnsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, 
      handleZoneUpdate
    );
    
    const zonesUpdatedUnsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.ZONES_UPDATED, 
      handleZoneUpdate
    );
    
    // Cleanup function to unsubscribe from all events when component unmounts
    return () => {
      palletAddedUnsubscribe();
      palletRemovedUnsubscribe();
      palletMovedUnsubscribe();
      zoneAssignmentUnsubscribe();
      zonesUpdatedUnsubscribe();
      zoneUpdateService.cleanup();
    };
  }, [selectedLocation]);
  
  // Handle zone selection
  const handleZoneClick = (zone, event) => {
    if (editMode) {
      // In edit mode, don't select - handle in drag events
      return;
    }
    
    if (selectedZone && selectedZone.id === zone.id) {
      // If clicking the already selected zone, show detail view
      setShowDetailView(true);
    } else {
      // Otherwise, select the zone
      setSelectedZone(zone);
    }
  };
  
  // Handle back button from detail view
  const handleBack = () => {
    setShowDetailView(false);
  };
  
  // Refresh the storage data
  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
    showToast({ 
      message: 'Storage data refreshed',
      variant: 'success'
    });
  };
  
  // Toggle edit mode
  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (selectedZone) {
      setSelectedZone(null);
    }
  };
  
  // Handle drag start
  const handleDragStart = (e, zone) => {
    if (!editMode) return;
    
    const zoneRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - zoneRect.left;
    const offsetY = e.clientY - zoneRect.top;
    
    setDraggedZone(zone);
    setDragOffset({ x: offsetX, y: offsetY });
    
    // Add a ghost image to avoid the default drag image
    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    e.dataTransfer.setDragImage(img, 0, 0);
  };
  
  // Handle drag over
  const handleDragOver = (e) => {
    if (!editMode || !draggedZone) return;
    e.preventDefault();
    
    if (warehouseLayoutRef.current) {
      const layoutRect = warehouseLayoutRef.current.getBoundingClientRect();
      
      // Calculate new position in grid units
      const x = Math.max(0, Math.floor((e.clientX - layoutRect.left - dragOffset.x) / 16));
      const y = Math.max(0, Math.floor((e.clientY - layoutRect.top - dragOffset.y) / 16));
      
      // Update the current zones
      setCurrentZones(prevZones => 
        prevZones.map(z => {
          if (z.id === draggedZone.id) {
            return {
              ...z,
              position: { x, y }
            };
          }
          return z;
        })
      );
    }
  };
  
  // Handle drag end
  const handleDragEnd = () => {
    if (!editMode) return;
    setDraggedZone(null);
  };
  
  // Export all zone positions
  const exportPositions = () => {
    setShowExportModal(true);
  };
  
  // Render the detail view (grid view of selected zone)
  if (showDetailView && selectedZone) {
    const zoneData = zoneOccupancy[selectedZone.id] || { occupancy: {} };
    
    return (
      <MapWrapper>
        <MapHeader>
          <Title>
            <Box size={24} />
            Storage - Zone Detail View
          </Title>
          <Controls>
            <Button onClick={handleRefresh}>
              <RefreshCw size={16} />
              Refresh
            </Button>
          </Controls>
        </MapHeader>
        
        <ZoneGridView 
          zone={selectedZone}
          onBack={handleBack}
          palletData={zoneData.occupancy}
        />
      </MapWrapper>
    );
  }
  
  // Render the main map view (bird's eye view)
  return (
    <MapWrapper id="storage-map-view">
      <MapHeader>
        <Title>
          <Box size={24} />
          Storage
        </Title>
        <Controls>
          <Button 
            onClick={() => setShowGrid(!showGrid)}
            style={{ 
              backgroundColor: showGrid ? 'rgba(100, 100, 230, 0.1)' : undefined,
              borderColor: showGrid ? 'rgba(100, 100, 230, 0.5)' : undefined
            }}
          >
            <Grid size={16} />
            {showGrid ? 'Hide Grid' : 'Show Grid'}
          </Button>
          <Button 
            onClick={toggleEditMode} 
            style={{ 
              backgroundColor: editMode ? 'rgba(230, 100, 100, 0.1)' : undefined,
              borderColor: editMode ? 'rgba(230, 100, 100, 0.5)' : undefined
            }}
          >
            <Move size={16} />
            {editMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}
          </Button>
          {editMode && (
            <Button onClick={exportPositions}>
              <Save size={16} />
              Export Positions
            </Button>
          )}
          <Button onClick={handleRefresh}>
            <RefreshCw size={16} />
            Refresh
          </Button>
        </Controls>
      </MapHeader>
      
      {error && (
        <LocationWarning>
          {error}
        </LocationWarning>
      )}
      
      {/* Legend removed as per request */}
      
      <MapContainer>
        <WarehouseLayout 
          ref={warehouseLayoutRef}
          onDragOver={handleDragOver}
        >
          {showGrid && (
            <GridOverlay 
              gridSize={{ width: 40, height: 40 }} 
              cellSize={16} 
              showCells={false}
            />
          )}
          {currentZones.map(zone => {
            const zoneData = zoneOccupancy[zone.id] || {
              occupiedCount: 0,
              occupancyPercentage: 0
            };
            
            const isSelected = selectedZone?.id === zone.id;
            const isDragged = draggedZone?.id === zone.id;
            
            return (
              <ZoneItem 
                key={zone.id}
                zone={zone}
                zoneData={zoneData}
                isSelected={isSelected}
                editMode={editMode}
                isDragged={isDragged}
                showGrid={showGrid}
                onClick={(e) => handleZoneClick(zone, e)}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              />
            );
          })}
        </WarehouseLayout>
      </MapContainer>
      
      {showExportModal && (
        <ZoneExportModal
          zones={currentZones}
          onClose={() => setShowExportModal(false)}
        />
      )}
    </MapWrapper>
  );
};

export default MapView;

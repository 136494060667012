import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem;
  height: 100vh;
  background-color: #ffffff;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 1rem;
    height: 100%;
    min-height: 100vh;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #666;
  text-align: center;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Input = styled.input`
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    padding: 10px 14px;
    font-size: 16px; /* Prevents iOS zoom on focus */
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1rem;
  width: 100%;
  
  &:hover:not(:disabled) {
    background-color: #0069d9;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    padding: 14px 16px; /* Slightly larger touch target for mobile */
    margin-top: 1.5rem;
  }
`;

export const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-top: 0.75rem;
  }
`;

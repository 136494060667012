/**
 * Checks if a response is likely a success despite being treated as an error
 * This handles cases where the server returns non-JSON success responses
 */
const isProbablySuccess = (error) => {
  // Check for JSON parsing errors with syntax error
  if (error.name === 'SyntaxError' && error.message.includes('JSON')) {
    return true;
  }
  
  // Check for string responses that indicate success
  if (typeof error.response?.data === 'string') {
    const data = error.response.data.toLowerCase();
    if (data === 'ok' || data.includes('success') || data === 'true') {
      return true;
    }
  }
  
  // Check for 200-range status codes that might have parsing issues
  if (error.response?.status >= 200 && error.response?.status < 300) {
    return true;
  }
  
  return false;
};

export const handleApiError = (error) => {
  // Handle the special case where the error is actually a successful operation
  if (isProbablySuccess(error)) {
    console.log('Error handler detected probable success:', error);
    return {
      success: true,
      message: 'Operation completed successfully',
      _originalError: error
    };
  }
  
  if (error.response) {
    const message = error.response.data?.message || 'An error occurred';
    const status = error.response.status;
    
    switch (status) {
      case 401:
        const currentPath = window.location.pathname;
        if (currentPath !== '/login') {
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
          window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
        }
        return new Error('Session expired. Please login again.');
      case 403:
        return new Error('You do not have permission to perform this action');
      case 404:
        return new Error('The requested resource was not found');
      case 422:
        return new Error(message || 'Invalid data provided');
      case 429:
        return new Error('Too many requests. Please try again later');
      default:
        return new Error(message);
    }
  } else if (error.request) {
    return new Error('No response received from server');
  } else {
    return new Error(error.message || 'An unexpected error occurred');
  }
};

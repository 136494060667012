import styled from 'styled-components';

export const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  
  h1 {
    margin-bottom: 10px;
    color: #333;
  }
  
  p {
    color: #666;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionHeader = styled.h2`
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PrinterSection = styled.div`
  background: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const PrinterDetails = styled.div`
  margin: 15px 0;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

export const ConfigItem = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
`;

export const FormGroup = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Courier New', monospace;
  resize: vertical;
  min-height: 150px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  flex: 1;
  
  &:hover {
    background-color: #0052a3;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const ClearButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  
  &:hover {
    background-color: #d32f2f;
  }
`;

export const TestResult = styled.div`
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: ${props => props.success ? '#e8f5e9' : '#ffebee'};
  color: ${props => props.success ? '#388e3c' : '#d32f2f'};
  border: 1px solid ${props => props.success ? '#c8e6c9' : '#ffcdd2'};
`;

export const LogSection = styled.div`
  grid-column: 1 / span 2;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const LogFilterContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
  flex-wrap: wrap;
`;

export const FilterButton = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: ${props => {
    if (props.active) {
      if (props.success) return '#e8f5e9';
      if (props.failure) return '#ffebee';
      if (props.info) return '#e3f2fd';
      return '#e0e0e0';
    }
    return '#f5f5f5';
  }};
  color: ${props => {
    if (props.success) return '#388e3c';
    if (props.failure) return '#d32f2f';
    if (props.info) return '#1976d2';
    return '#333';
  }};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  
  &:hover {
    background-color: ${props => {
      if (props.success) return '#c8e6c9';
      if (props.failure) return '#ffcdd2';
      if (props.info) return '#bbdefb';
      return '#e0e0e0';
    }};
  }
`;

export const LogContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Courier New', monospace;
  font-size: 12px;
`;

export const LogEntry = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  border-left: 3px solid ${props => {
    if (props.status === 'SUCCESS') return '#4caf50';
    if (props.status === 'FAILURE') return '#f44336';
    if (props.status === 'INFO') return '#2196f3';
    return '#9e9e9e';
  }};
  background-color: ${props => {
    if (props.status === 'SUCCESS') return '#f1f8e9';
    if (props.status === 'FAILURE') return '#fce4ec';
    if (props.status === 'INFO') return '#e3f2fd';
    return '#f5f5f5';
  }};
`;

export const LogTimestamp = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
`;

export const LogAction = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const LogDetails = styled.div`
  margin-left: 10px;
  
  div {
    margin-bottom: 3px;
  }
`;

export const LogCodeBlock = styled.pre`
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-family: 'Courier New', monospace;
  font-size: 11px;
  white-space: pre-wrap;
  overflow-x: auto;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
`;

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Settings as BaseSettings } from '@features/operator/components/Settings';

export const Settings = ({ onSettingsUpdate, ...props }) => {
  const { locationId } = useOutletContext();
  
  return (
    <BaseSettings
      {...props}
      locationId={locationId}
      onSettingsUpdate={onSettingsUpdate}
    />
  );
};

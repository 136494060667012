import axios from 'axios';
import { handleApiError } from '@lib/utils/errorHandler';
import { config } from '@config/env';
const instance = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

instance.interceptors.request.use(
  reqConfig => {
    console.log('API Request:', {
      url: reqConfig.url,
      isDevelopment: config.isDevelopment,
      method: reqConfig.method
    });

    // In development mode, skip auth for certain endpoints
    const isAuthEndpoint = reqConfig.url.includes('/api/auth/login') || 
                          reqConfig.url.includes('/api/auth/register') ||
                          reqConfig.url.includes('/api/auth/validate');
    
    const isUserLocationsEndpoint = reqConfig.url.includes('/api/user-locations');

    if (config.isDevelopment && (isAuthEndpoint || isUserLocationsEndpoint)) {
      console.log('Development mode: skipping auth header for auth/user-locations endpoint');
      return reqConfig;
    }

    const token = localStorage.getItem('token');
    if (token) {
      reqConfig.headers.Authorization = `Bearer ${token}`;
    }
    return reqConfig;
  },
  error => Promise.reject(error)
);

const toCamelCase = (str) => str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const transformKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(transformKeys);
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = toCamelCase(key);
      acc[camelKey] = transformKeys(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

instance.interceptors.response.use(
  response => {
    // Check for non-JSON string responses that indicate success
    if (typeof response.data === 'string') {
      if (response.data === 'ok' || 
          response.data.toLowerCase() === 'ok' || 
          response.data.includes('success')) {
        console.log('Converting string response to success object:', response.data);
        // Convert string response to a standard success object
        response.data = { 
          success: true, 
          message: 'Operation completed successfully',
          originalText: response.data
        };
      } else {
        try {
          // Try to parse the string as JSON if it looks like JSON
          if ((response.data.startsWith('{') && response.data.endsWith('}')) ||
              (response.data.startsWith('[') && response.data.endsWith(']'))) {
            const parsedData = JSON.parse(response.data);
            response.data = parsedData;
            console.log('Successfully parsed string response as JSON');
          }
        } catch (e) {
          console.log('Failed to parse string response as JSON, keeping as is:', e);
          // Keep the string as is if parsing fails
        }
      }
    }
    
    // Transform response data for pallet-related endpoints only
    if (response.config.url.includes('/api/pallets')) {
      if (response.data?.data) {
        response.data.data = transformKeys(response.data.data);
      } else if (response.data && typeof response.data === 'object') {
        response.data = transformKeys(response.data);
      }
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;
    
    // Check if this is a JSON parsing error from a successful response
    if (error.name === 'SyntaxError' && 
        error.message.includes('JSON') && 
        error.response?.status >= 200 && 
        error.response?.status < 300) {
      
      console.log('JSON parsing error in successful response, creating success object');
      
      // Create a success response
      const response = {
        ...error.response,
        data: {
          success: true,
          message: 'Operation completed successfully',
          originalText: error.response.data
        }
      };
      
      return response;
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      // Skip token refresh for auth endpoints and in development mode
      const isAuthEndpoint = originalRequest.url.includes('/api/auth/login') || 
                            originalRequest.url.includes('/api/auth/register') ||
                            originalRequest.url.includes('/api/auth/validate');
      
      if (config.isDevelopment) {
        console.log('Development mode: skipping token refresh');
        return Promise.reject(error);
      }

      if (isAuthEndpoint) {
        console.log('Auth endpoint: skipping token refresh');
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return instance(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await instance.post('/api/auth/refresh-token', {
          refreshToken
        });

        const { token } = response.data;
        localStorage.setItem('token', token);
        
        originalRequest.headers.Authorization = `Bearer ${token}`;
        processQueue(null, token);
        
        return instance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        
        // Use dynamic import to avoid circular dependencies
        import('@features/auth/services/tokenService').then(({ clearAuthData, checkRedirectLoop }) => {
          // Check if we're in a redirect loop before redirecting
          if (checkRedirectLoop('api')) {
            console.error('Detected API redirect loop - clearing auth data without redirect');
            clearAuthData();
          } else {
            clearAuthData();
            window.location.href = '/login?expired=1';
          }
        }).catch(() => {
          // Fallback if import fails
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('userData');
          window.location.href = '/login?expired=1';
        });
        
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(handleApiError(error));
  }
);

export default instance;

/**
 * Error handling utility for storage operations with bounded retries
 */

// Strict bounds for retry attempts and timeouts
export const RETRY_LIMITS = {
  MAX_ATTEMPTS: 3,
  MIN_DELAY: 100,    // Minimum delay between retries in ms
  MAX_DELAY: 2000,   // Maximum delay between retries in ms
  TIMEOUT: 10000     // Maximum total time for operation in ms
};

// Error categories for consistent handling
export const ERROR_TYPES = {
  VALIDATION: 'VALIDATION',
  POSITION_OCCUPIED: 'POSITION_OCCUPIED',
  NETWORK: 'NETWORK',
  TIMEOUT: 'TIMEOUT',
  MISSING_PARAMETERS: 'MISSING_PARAMETERS',
  UNKNOWN: 'UNKNOWN'
};

/**
 * Categorizes an error for consistent handling
 * @param {Error} error - Error to categorize
 * @returns {string} Error category from ERROR_TYPES
 */
export const categorizeError = (error) => {
  if (!error) return ERROR_TYPES.UNKNOWN;

  // Check for position occupied errors
  if (
    error.code === 'POSITION_OCCUPIED' ||
    error.message?.includes('already occupied') ||
    error.response?.data?.message?.includes('already occupied')
  ) {
    return ERROR_TYPES.POSITION_OCCUPIED;
  }

  // Check for missing parameters
  if (
    error.message?.includes('parameter is required') ||
    error.message?.includes('zoneId parameter') ||
    error.message?.includes('locationId parameter')
  ) {
    return ERROR_TYPES.MISSING_PARAMETERS;
  }

  // Check for validation errors
  if (
    error.message?.includes('validation') ||
    error.response?.status === 400
  ) {
    return ERROR_TYPES.VALIDATION;
  }

  // Check for network errors
  if (
    error.code === 'ECONNABORTED' ||
    error.message?.includes('network') ||
    !error.response
  ) {
    return ERROR_TYPES.NETWORK;
  }

  // Check for timeout errors
  if (
    error.code === 'ETIMEDOUT' ||
    error.message?.includes('timeout')
  ) {
    return ERROR_TYPES.TIMEOUT;
  }

  return ERROR_TYPES.UNKNOWN;
};

/**
 * Calculates delay for next retry attempt with exponential backoff
 * @param {number} attempt - Current attempt number (0-based)
 * @returns {number} Delay in milliseconds
 */
export const calculateBackoffDelay = (attempt) => {
  const delay = Math.min(
    RETRY_LIMITS.MIN_DELAY * Math.pow(2, attempt),
    RETRY_LIMITS.MAX_DELAY
  );
  return delay;
};

/**
 * Executes an operation with bounded retries and timeout
 * @param {Function} operation - Async operation to execute
 * @param {Object} options - Options for retry behavior
 * @returns {Promise<any>} Result of the operation
 */
export const executeWithRetry = async (operation, options = {}) => {
  const {
    maxAttempts = RETRY_LIMITS.MAX_ATTEMPTS,
    timeout = RETRY_LIMITS.TIMEOUT,
    onRetry = () => {}
  } = options;

  let lastError = null;
  const startTime = Date.now();

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      // Check if we've exceeded total timeout
      if (Date.now() - startTime > timeout) {
        throw new Error(`Operation timed out after ${timeout}ms`);
      }

      // Execute the operation
      return await operation();
    } catch (error) {
      lastError = error;
      const errorType = categorizeError(error);

      // Don't retry certain error types
      if (
        errorType === ERROR_TYPES.VALIDATION ||
        errorType === ERROR_TYPES.POSITION_OCCUPIED
      ) {
        throw error;
      }

      // Check if we should attempt retry
      if (attempt < maxAttempts - 1) {
        const delay = calculateBackoffDelay(attempt);
        await onRetry(error, attempt, delay);
        await new Promise(resolve => setTimeout(resolve, delay));
        continue;
      }

      // No more retries, throw the error
      throw error;
    }
  }

  // This shouldn't be reached due to the throw in the catch block
  throw lastError || new Error('Operation failed after all retry attempts');
};

/**
 * Creates a standardized error object with additional context
 * @param {Error} error - Original error
 * @param {Object} context - Additional context about the operation
 * @returns {Error} Enhanced error object
 */
export const enhanceError = (error, context = {}) => {
  const enhanced = new Error(error.message);
  enhanced.originalError = error;
  enhanced.category = categorizeError(error);
  enhanced.context = context;
  enhanced.timestamp = Date.now();
  return enhanced;
};

export default {
  executeWithRetry,
  enhanceError,
  categorizeError,
  ERROR_TYPES,
  RETRY_LIMITS
};

/**
 * Main storage assignment service orchestrating zone, position, and row operations
 * with safety-critical constraints
 */

import { getZonesForTomatoType } from '../constants/zoneConfig.v2';
import { getRecommendedZones } from '../constants/zoneRecommendations';
import { createBoundedMap, paginateResults } from '../utils/collectionManager';
import { createPosition, getPositionKey } from '../utils/positionManager';
import { enhanceError } from '../utils/storageApiErrorHandler';
import { validateLocationId, parseStorageZone, isRowLevelAssignment, getZoneIdFromStorageZone } from '../utils/validationUtils';
import zoneOccupancyService from './zoneOccupancyService';
import positionAssignmentService from './positionAssignmentService';
import rowTrackingService from './rowTrackingService';

// Cache for zone recommendations
const zoneRecommendationCache = createBoundedMap();

/**
 * Gets all zones suitable for a tomato type with occupancy information
 * @param {string} tomatoType - Type of tomato
 * @param {number} locationId - Location ID
 * @returns {Promise<Array>} Array of zone objects with occupancy data
 */
export const getSuitableZones = async (tomatoType, locationId) => {
  if (!tomatoType) {
    throw new Error('Tomato type is required');
  }

  const zones = getZonesForTomatoType(tomatoType);

  try {
    // Add occupancy information to each zone
    const zonesWithOccupancy = await Promise.all(zones.map(async zone => {
      try {
        const occupancy = await zoneOccupancyService.getZoneOccupancy(
          zone.id,
          locationId
        );

        const occupiedCount = Object.keys(occupancy).length;
        const occupancyPercentage = Math.round((occupiedCount / zone.capacity) * 100);

        return {
          ...zone,
          occupancy,
          occupiedCount,
          occupancyPercentage,
          hasSpace: occupiedCount < zone.capacity
        };
      } catch (error) {
        console.warn(`Failed to get occupancy for zone ${zone.id}:`, error.message);
        // Return zone with empty occupancy data
        return {
          ...zone,
          occupancy: {},
          occupiedCount: 0,
          occupancyPercentage: 0,
          hasSpace: true,
          error: true
        };
      }
    }));

    return zonesWithOccupancy;
  } catch (error) {
    throw enhanceError(error, {
      tomatoType,
      locationId,
      operation: 'getSuitableZones'
    });
  }
};

/**
 * Gets the next available position in a zone
 * @param {string} zoneId - Zone ID to check
 * @param {number} locationId - Location ID
 * @returns {Promise<Object|null>} Next available position or null if zone is full
 */
export const getNextAvailablePosition = async (zoneId, locationId) => {
  try {
    const occupancy = await zoneOccupancyService.getZoneOccupancy(
      zoneId,
      locationId
    );

    return positionAssignmentService.getNextAvailablePosition(
      zoneId,
      locationId,
      occupancy
    );
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      locationId,
      operation: 'getNextAvailablePosition'
    });
  }
};

/**
 * Assigns a pallet to a specific position
 * @param {string} zoneId - Zone ID
 * @param {Object} position - Position coordinates
 * @param {Object} palletData - Pallet information
 * @param {number} locationId - Location ID
 * @param {Object} options - Assignment options
 * @returns {Promise<Object>} Assignment result
 */
export const assignPalletToPosition = async (
  zoneId,
  position,
  palletData,
  locationId,
  options = {}
) => {
  try {
    const result = await positionAssignmentService.assignPalletToPosition(
      zoneId,
      position,
      palletData,
      locationId,
      options
    );

    if (options.trackRow) {
      await rowTrackingService.trackRowCompletion({
        palletType: palletData.type,
        tomatoType: palletData.tomatoType,
        tomatoOption: palletData.tomatoOption,
        zoneId,
        rowIndex: position.row,
        colIndex: position.col,
        rowCapacity: options.rowCapacity,
        locationId
      });
    }

    return result;
  } catch (error) {
    throw enhanceError(error, {
      zoneId,
      position,
      palletId: palletData?.id,
      locationId,
      operation: 'assignPalletToPosition'
    });
  }
};

/**
 * Finds next position in an active row
 * @param {string} palletType - Type of pallet
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option/variety
 * @param {number} locationId - Location ID
 * @returns {Promise<Object|null>} Next position recommendation
 */
export const findNextRowPosition = async (
  palletType,
  tomatoType,
  tomatoOption,
  locationId
) => {
  return rowTrackingService.findNextRowPosition(
    palletType,
    tomatoType,
    tomatoOption,
    locationId
  );
};

/**
 * Gets active rows for a product combination
 * @param {string} palletType - Type of pallet
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option/variety
 * @param {number} locationId - Location ID
 * @returns {Promise<Array>} Array of active row data
 */
export const getActiveRows = async (
  palletType,
  tomatoType,
  tomatoOption,
  locationId
) => {
  return rowTrackingService.getActiveRows(
    palletType,
    tomatoType,
    tomatoOption,
    locationId
  );
};

/**
 * Removes a pallet from storage
 * @param {string} palletId - Pallet ID to remove
 * @param {string} zoneId - Zone ID
 * @param {Object} position - Position data
 * @param {number} locationId - Location ID
 * @returns {Promise<boolean>} Success status
 */
export const removePalletFromStorage = async (
  palletId,
  zoneId,
  position,
  locationId
) => {
  try {
    await zoneOccupancyService.removeFromZoneOccupancy(
      zoneId,
      locationId,
      position
    );
    return true;
  } catch (error) {
    throw enhanceError(error, {
      palletId,
      zoneId,
      position,
      locationId,
      operation: 'removePalletFromStorage'
    });
  }
};

/**
 * Moves a pallet to a new position
 * @param {string} palletId - Pallet ID to move
 * @param {string} targetZoneId - Target zone ID
 * @param {Object} targetPosition - Target position
 * @param {number} locationId - Location ID
 * @returns {Promise<boolean>} Success status
 */
export const movePallet = async (
  palletId,
  targetZoneId,
  targetPosition,
  locationId
) => {
  return positionAssignmentService.movePallet(
    palletId,
    targetZoneId,
    targetPosition,
    locationId
  );
};

/**
 * Gets all zones with their current occupancy
 * @param {number} locationId - Location ID
 * @param {Object} options - Query options
 * @returns {Promise<Array>} Array of zone objects with occupancy data
 */
export const getAllZonesWithOccupancy = async (locationId, options = {}) => {
  try {
    const zones = getZonesForTomatoType('*'); // Get all zones
    const zonesWithOccupancy = await Promise.all(
      zones.map(async zone => {
        try {
          const occupancy = await zoneOccupancyService.getZoneOccupancy(
            zone.id,
            locationId,
            options
          );

          const occupiedCount = Object.keys(occupancy).length;
          const occupancyPercentage = Math.round((occupiedCount / zone.capacity) * 100);

          return {
            ...zone,
            occupancy,
            occupiedCount,
            occupancyPercentage,
            hasSpace: occupiedCount < zone.capacity
          };
        } catch (error) {
          console.warn(`Failed to get occupancy for zone ${zone.id}:`, error.message);
          return {
            ...zone,
            occupancy: {},
            occupiedCount: 0,
            occupancyPercentage: 0,
            hasSpace: true,
            error: true
          };
        }
      })
    );

    // Apply pagination if requested
    if (options.page && options.pageSize) {
      return paginateResults(zonesWithOccupancy, options.page, options.pageSize);
    }

    return zonesWithOccupancy;
  } catch (error) {
    throw enhanceError(error, {
      locationId,
      operation: 'getAllZonesWithOccupancy'
    });
  }
};

/**
 * Checks if a zone is recommended for a product combination
 * @param {string} zoneId - Zone ID to check
 * @param {string} tomatoType - Type of tomato
 * @param {string} tomatoOption - Tomato option
 * @param {string} sortingGrade - Sorting grade
 * @returns {boolean} Whether zone is recommended
 */
export const isZoneRecommendedForTomatoType = (zoneId, tomatoType, tomatoOption, sortingGrade) => {
  if (!zoneId || !tomatoType) return false;

  // Debug input values
  console.log('Storage Assignment - Checking recommendation for:', {
    zoneId,
    tomatoType,
    tomatoOption,
    sortingGrade
  });

  // Handle A grof + case
  const adjustedOption = tomatoOption?.includes('+') ? `${tomatoOption.trim()}` : tomatoOption;
  
  const cacheKey = `${zoneId}_${tomatoType}_${adjustedOption || ''}_${sortingGrade || ''}`;
  
  // Check cache first
  if (zoneRecommendationCache.has(cacheKey)) {
    const cachedResult = zoneRecommendationCache.get(cacheKey);
    console.log('Storage Assignment - Using cached result:', {
      cacheKey,
      cachedResult
    });
    return cachedResult;
  }

  // Get recommended zones based on product combination
  const recommendedZones = getRecommendedZones(tomatoType, adjustedOption, sortingGrade);
  const isRecommended = recommendedZones.includes(zoneId);

  // Debug recommendation result
  console.log('Storage Assignment - Recommendation result:', {
    adjustedOption,
    recommendedZones,
    isRecommended
  });

  // Cache result
  zoneRecommendationCache.set(cacheKey, isRecommended);

  return isRecommended;
};

// Export storage zone utilities
export {
  parseStorageZone,
  isRowLevelAssignment,
  getZoneIdFromStorageZone
};

export default {
  getSuitableZones,
  getNextAvailablePosition,
  assignPalletToPosition,
  findNextRowPosition,
  getActiveRows,
  removePalletFromStorage,
  movePallet,
  getAllZonesWithOccupancy,
  isZoneRecommendedForTomatoType,
  parseStorageZone,
  isRowLevelAssignment,
  getZoneIdFromStorageZone
};

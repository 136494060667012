import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const ScrollableMenu = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  -webkit-overflow-scrolling: touch;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  
  /* Hide scrollbar for cleaner look */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuButton = styled.button`
  flex: 0 0 auto;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  border: none;
  background-color: ${({ active, theme }) => 
    active ? theme.colors.primary.main : 'transparent'};
  color: ${({ active, theme }) => 
    active ? theme.colors.common.white : theme.colors.text.secondary};
  font-weight: 500;
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;
  white-space: nowrap;
  
  &:hover:not(:disabled) {
    background-color: ${({ active, theme }) => 
      active ? theme.colors.primary.dark : theme.colors.background.hover};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  &:last-child {
    margin-right: 0;
  }
`;

export const ContentArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  background: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacing.md};
  animation: ${fadeIn} 0.3s ease-out;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[400]};
    border-radius: ${({ theme }) => theme.borderRadius.full};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${({ theme }) => theme.colors.background.default};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.text.primary};
  
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  border: 3px solid ${({ theme }) => theme.colors.background.hover};
  border-top: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.error + '10'};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border-left: 4px solid ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  margin: ${({ theme }) => theme.spacing.md} 0;
  min-height: 150px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const RetryButton = styled.button`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
  
  &:active {
    transform: translateY(0);
    background: ${({ theme }) => theme.colors.primary.darker};
  }
`;

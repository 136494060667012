import { SSEManager } from './SSEManager';

/**
 * KleinpakSSEManager
 * 
 * A specialized SSE manager for the Kleinpak Dashboard that handles real-time updates
 * for orders, pallets, and their assignments.
 */
class KleinpakSSEManager {
  constructor() {
    this.sseManager = null;
    this.locationId = null;
    this.subscribers = {
      orderUpdate: new Map(),
      palletUpdate: new Map(),
      assignmentUpdate: new Map(),
      allEvents: new Map()
    };
    this.isConnected = false;
    this.connectionAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 2000; // Start with 2 seconds
    this.subscriptionId = 0;
    this.scanningMode = false; // Global flag to disable SSE during scanning
  }

  /**
   * Initializes the SSE connection for the specified location
   * @param {number} locationId - The location ID to connect to
   */
  initialize(locationId) {
    if (this.isConnected && this.locationId === locationId) {
      console.log('KleinpakSSEManager: Already connected to this location');
      return;
    }

    this.locationId = locationId;
    this.connectionAttempts = 0;
    
    if (!this.scanningMode) {
      this.connect();
    } else {
      console.log('KleinpakSSEManager: Initialize skipped, scanning mode active');
    }
  }
  
  /**
   * Enable scanning mode - completely turns off SSE during scanning operations
   * This is more aggressive than pause() and should be called earlier in the flow
   */
  enableScanningMode() {
    console.log('KleinpakSSEManager: ENTERING SCANNING MODE - all SSE operations disabled');
    this.scanningMode = true;
    
    // If connected, disconnect immediately
    if (this.isConnected && this.sseManager) {
      this.sseManager.disconnect();
      this.isConnected = false;
    }
  }
  
  /**
   * Disable scanning mode and resume normal operation
   */
  disableScanningMode() {
    console.log('KleinpakSSEManager: EXITING SCANNING MODE - resuming normal operation');
    this.scanningMode = false;
    
    // If we have a location ID, reconnect
    if (this.locationId) {
      // Add small delay for stability
      setTimeout(() => this.connect(), 500);
    }
  }

  // Flag to prevent multiple initializations at once
  isInitializing = false;
  
  // Throttle connection attempts
  lastConnectionAttempt = 0;
  connectionThrottleMs = 2000; // Minimum ms between connection attempts
  
  /**
   * Establishes an SSE connection to the server
   */
  connect() {
    if (!this.locationId) {
      console.error('KleinpakSSEManager: No location ID specified');
      return;
    }
    
    // Don't connect if in scanning mode
    if (this.scanningMode) {
      console.log('KleinpakSSEManager: Connect skipped, scanning mode active');
      return;
    }
    
    // Prevent connection spamming
    const now = Date.now();
    if (now - this.lastConnectionAttempt < this.connectionThrottleMs) {
      console.log(`KleinpakSSEManager: Connection attempt throttled (wait ${this.connectionThrottleMs}ms)`);
      return;
    }
    
    // Prevent multiple initializations
    if (this.isInitializing) {
      console.log('KleinpakSSEManager: Already initializing a connection');
      return;
    }
    
    this.lastConnectionAttempt = now;
    this.isInitializing = true;

    // Import SSEManager dynamically to avoid circular dependencies
    import('./SSEManager').then((module) => {
      // Important: use module.default to get the actual SSEManager
      const SSEManager = module.default;
      this.sseManager = SSEManager;
      
      if (!this.sseManager || typeof this.sseManager.connect !== 'function') {
        console.error('KleinpakSSEManager: Invalid SSEManager import, connect function missing');
        this.isInitializing = false;
        return;
      }
      
      // Connect to the SSE endpoint for this location
      this.sseManager.connect(this.locationId, {
        onConnect: () => {
          console.log(`KleinpakSSEManager: Connected to location ${this.locationId}`);
          this.isConnected = true;
          this.connectionAttempts = 0;
          this.reconnectDelay = 2000; // Reset reconnect delay
          this.isInitializing = false;
        },
        onMessage: (event) => this.handleEvent(event),
        onError: (error) => {
          console.error(`KleinpakSSEManager: Connection error: ${error.message}`);
          this.isConnected = false;
          this.isInitializing = false;
          this.attemptReconnect();
        },
        onDisconnect: () => {
          console.log(`KleinpakSSEManager: Disconnected from location ${this.locationId}`);
          this.isConnected = false;
          this.isInitializing = false;
          this.attemptReconnect();
        }
      });
    }).catch(error => {
      console.error('KleinpakSSEManager: Failed to import SSEManager', error);
      this.isInitializing = false;
    });
  }

  /**
   * Attempts to reconnect to the SSE endpoint with exponential backoff
   */
  attemptReconnect() {
    // Don't attempt reconnect if in scanning mode
    if (this.scanningMode) {
      console.log('KleinpakSSEManager: Reconnect skipped, scanning mode active');
      return;
    }
    
    if (this.connectionAttempts >= this.maxReconnectAttempts) {
      console.error(`KleinpakSSEManager: Max reconnection attempts (${this.maxReconnectAttempts}) reached. Giving up.`);
      return;
    }

    this.connectionAttempts++;
    const delay = Math.min(this.reconnectDelay * Math.pow(1.5, this.connectionAttempts - 1), 30000); // Cap at 30 seconds

    console.log(`KleinpakSSEManager: Attempting to reconnect in ${delay / 1000} seconds (attempt ${this.connectionAttempts}/${this.maxReconnectAttempts})`);
    
    setTimeout(() => {
      this.connect();
    }, delay);
  }

  /**
   * Handles incoming SSE events
   * @param {Object} event - The SSE event
   */
  handleEvent(event) {
    if (!event || !event.type) return;

    console.log(`KleinpakSSEManager: Received ${event.type} event`, event);

    // Process the event based on its type
    switch (event.type) {
      case 'ORDER_UPDATE':
        this.notifySubscribers('orderUpdate', event.data);
        this.notifySubscribers('allEvents', event);
        break;
        
      case 'PALLET_UPDATE':
        this.notifySubscribers('palletUpdate', event.data);
        this.notifySubscribers('allEvents', event);
        break;
        
      case 'ASSIGNMENT_UPDATE':
        this.notifySubscribers('assignmentUpdate', event.data);
        this.notifySubscribers('allEvents', event);
        break;
        
      default:
        // Notify general subscribers for all event types
        this.notifySubscribers('allEvents', event);
        break;
    }
  }

  /**
   * Notifies all subscribers of a specific event type
   * @param {string} eventType - The type of event
   * @param {Object} data - The event data
   */
  notifySubscribers(eventType, data) {
    if (!this.subscribers[eventType]) return;
    
    this.subscribers[eventType].forEach((callback) => {
      try {
        callback(data);
      } catch (error) {
        console.error(`KleinpakSSEManager: Error in ${eventType} subscriber callback:`, error);
      }
    });
  }

  /**
   * Subscribes to a specific event type
   * @param {string} eventType - The type of event to subscribe to
   * @param {Function} callback - The callback function to invoke when events occur
   * @returns {number} A subscription ID that can be used to unsubscribe
   */
  subscribe(eventType, callback) {
    if (!this.subscribers[eventType]) {
      console.error(`KleinpakSSEManager: Invalid event type: ${eventType}`);
      return -1;
    }

    const id = this.subscriptionId++;
    this.subscribers[eventType].set(id, callback);
    
    console.log(`KleinpakSSEManager: Subscribed to ${eventType} events with ID ${id}`);
    return id;
  }

  /**
   * Unsubscribes from a specific event type using the subscription ID
   * @param {string} eventType - The type of event
   * @param {number} subscriptionId - The ID returned from subscribe()
   * @returns {boolean} Whether the unsubscription was successful
   */
  unsubscribe(eventType, subscriptionId) {
    if (!this.subscribers[eventType]) {
      console.error(`KleinpakSSEManager: Invalid event type: ${eventType}`);
      return false;
    }

    const result = this.subscribers[eventType].delete(subscriptionId);
    
    if (result) {
      console.log(`KleinpakSSEManager: Unsubscribed from ${eventType} events with ID ${subscriptionId}`);
    } else {
      console.warn(`KleinpakSSEManager: No subscription found for ${eventType} with ID ${subscriptionId}`);
    }
    
    return result;
  }

  /**
   * Disconnects from the SSE endpoint
   */
  disconnect() {
    if (this.sseManager) {
      this.sseManager.disconnect();
      this.isConnected = false;
      this.locationId = null;
      console.log('KleinpakSSEManager: Disconnected');
    }
  }

  /**
   * Temporarily pauses the SSE connection without losing subscription state
   * This is useful when we need to free up resources for intensive operations like camera scanning
   */
  pause() {
    if (this.sseManager && this.isConnected) {
      console.log('KleinpakSSEManager: Pausing SSE connection to free resources for scanner');
      this.sseManager.disconnect();
      this.isConnected = false;
      // Note: We're keeping locationId and subscriptions intact
      // This is what makes it different from disconnect()
    }
  }

  /**
   * Resumes a paused SSE connection
   */
  resume() {
    if (!this.isConnected && this.locationId) {
      console.log('KleinpakSSEManager: Resuming SSE connection after scanner operation');
      this.connect();
    }
  }

  /**
   * Clears all subscriptions
   */
  clearSubscriptions() {
    Object.keys(this.subscribers).forEach(eventType => {
      this.subscribers[eventType].clear();
    });
    console.log('KleinpakSSEManager: All subscriptions cleared');
  }

  /**
   * Processes a pallet assignment and emits a custom event for backward compatibility
   * @param {Object} assignmentData - The assignment data from the SSE event
   */
  processPalletAssignment(assignmentData) {
    if (!assignmentData || (!assignmentData.palletIds && !assignmentData.palletId)) {
      return;
    }

    const palletIds = assignmentData.palletIds || [assignmentData.palletId];
    const orderId = assignmentData.orderId;
    
    // For backward compatibility, emit a custom DOM event
    // This will be gradually phased out as components are updated to use the SSE manager directly
    palletIds.forEach(palletId => {
      const event = new CustomEvent('palletAssigned', {
        detail: {
          palletId,
          orderId,
          processStage: 'Processing', // Assume Processing state for assigned pallets
          // Use zone data if available, otherwise null
          zone: assignmentData.zone || null
        }
      });
      
      window.dispatchEvent(event);
      console.log(`KleinpakSSEManager: Emitted legacy palletAssigned event for pallet #${palletId} to order #${orderId}`);
    });
  }
}

// Export a singleton instance
const kleinpakSSEManager = new KleinpakSSEManager();
export default kleinpakSSEManager;

/**
 * Utility functions for handling dates consistently throughout the application
 */

/**
 * Safely formats a date string to YYYY-MM-DD format
 * @param {string|Date} date - The date to format
 * @returns {string|null} The formatted date string or null if invalid
 */
export const formatDateToYYYYMMDD = (date) => {
  try {
    if (!date) return null;
    
    // If it's already a Date object
    if (date instanceof Date) {
      return date.toISOString().split('T')[0];
    }
    
    // Try parsing the string date
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return null;
    }
    
    return parsedDate.toISOString().split('T')[0];
  } catch (error) {
    console.warn('Error formatting date:', error);
    return null;
  }
};

/**
 * Checks if a date string is from today
 * @param {string|Date} date - The date to check
 * @returns {boolean} True if the date is from today
 */
export const isDateFromToday = (date) => {
  try {
    if (!date) return false;
    
    const formattedDate = formatDateToYYYYMMDD(date);
    if (!formattedDate) return false;
    
    const today = formatDateToYYYYMMDD(new Date());
    return formattedDate === today;
  } catch (error) {
    console.warn('Error checking if date is from today:', error);
    return false;
  }
};

/**
 * Safely creates a Date object from various formats
 * @param {string|Date} date - The date to parse
 * @returns {Date|null} The parsed Date object or null if invalid
 */
export const parseDate = (date, defaultValue = null) => {
  try {
    if (!date) return null;
    
    // If it's already a Date object
    if (date instanceof Date) {
      return isNaN(date.getTime()) ? null : date;
    }
    
    // Try parsing the string date
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? defaultValue : parsedDate;
  } catch (error) {
    console.warn('Error parsing date:', error);
    return null;
  }
};

/**
 * Gets the day index (0-6) from a date, handling invalid dates
 * @param {string|Date} date - The date to get the day index from
 * @param {number} defaultValue - Value to return if date is invalid
 * @returns {number} The day index (0 = Monday, 6 = Sunday) or defaultValue if invalid
 */
export const getDayIndex = (date, defaultValue = -1) => {
  try {
    const parsedDate = parseDate(date);
    if (!parsedDate) return defaultValue;
    
    // Convert Sunday (0) to 6, and other days to 0-5
    const day = parsedDate.getDay();
    return day === 0 ? 6 : day - 1;
  } catch (error) {
    console.warn('Error getting day index:', error);
    return defaultValue;
  }
};

/**
 * Gets the start of the week (Monday) for a given date
 * @param {string|Date} date - The date to get the week start from
 * @returns {Date|null} The start of the week or null if invalid
 */
export const getWeekStart = (date = new Date()) => {
  try {
    const parsedDate = parseDate(date);
    if (!parsedDate) return null;
    
    const dayOfWeek = parsedDate.getDay();
    const diff = parsedDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const monday = new Date(parsedDate);
    monday.setDate(diff);
    monday.setHours(0, 0, 0, 0);
    
    return monday;
  } catch (error) {
    console.warn('Error getting week start:', error);
    return null;
  }
};

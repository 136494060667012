export const tomatoOptions = {
  'Round': {
    'A grof +': {},
    'A grof': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Round': {
    'A': {},
    'B': {},
  },
  'Plum': {
    '6-Pack': {},
    '5-Pack': {},
    'A fijn': {},
    'B': {}
  },
  'Cherry Plum': {
    'A': {},
    'B': {},
  }
};

import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  ${({ theme }) => theme.typography.caption};
  background-color: ${({ variant, theme }) => {
    switch (variant) {
      // Inventory states
      case 'fresh':
        return theme.colors.success.alpha;
      case 'ripening':
        return theme.colors.warning.alpha;
      case 'near_expiry':
        return theme.colors.error.alpha;
      // Temperature zones
      case 'cold':
        return theme.colors.primary[100];
      case 'ambient':
        return theme.colors.primary[50];
      // Order urgency
      case 'urgent':
        return theme.colors.error.alpha;
      case 'normal':
        return theme.colors.primary.alpha10;
      case 'low':
        return theme.colors.gray.alpha10;
      default:
        return theme.colors.gray.alpha10;
    }
  }};
  color: ${({ variant, theme }) => {
    switch (variant) {
      case 'fresh':
        return theme.colors.success.main;
      case 'ripening':
        return theme.colors.warning.main;
      case 'near_expiry':
        return theme.colors.error.main;
      case 'cold':
        return theme.colors.primary[700];
      case 'ambient':
        return theme.colors.primary[500];
      case 'urgent':
        return theme.colors.error.main;
      case 'normal':
        return theme.colors.primary.main;
      case 'low':
        return theme.colors.text.secondary;
      default:
        return theme.colors.text.secondary;
    }
  }};

  /* Pulse animation for urgent items */
  ${({ variant, theme }) => variant === 'urgent' && `
    animation: ${pulse} 2s infinite;
  `}

  /* Icon styling */
  .icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: currentColor;
    opacity: 0.8;
  }

  /* Progress bar for ripening state */
  ${({ variant, progress, theme }) => variant === 'ripening' && progress && `
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: ${progress}%;
      background-color: ${theme.colors.warning.main};
      opacity: 0.2;
      transition: width 0.3s ease;
    }
  `}

  /* Temperature indicator */
  ${({ variant, temperature, theme }) => 
    (variant === 'cold' || variant === 'ambient') && temperature && `
    &::before {
      content: '${temperature}°C';
      font-size: 0.65em;
      padding: ${theme.spacing.xs};
      background-color: ${theme.colors.background.paper};
      border-radius: ${theme.borderRadius.sm};
      margin-right: ${theme.spacing.xs};
    }
  `}
`;

const StatusIndicator = ({
  variant,
  label,
  showIcon = true,
  progress,
  temperature,
  className,
}) => {
  // Format the label to be more readable
  const formattedLabel = label || variant?.replace(/_/g, ' ');

  return (
    <Container
      variant={variant}
      progress={progress}
      temperature={temperature}
      className={className}
    >
      {showIcon && <div className="icon" />}
      {formattedLabel}
    </Container>
  );
};

// Predefined status groups for type checking and documentation
StatusIndicator.variants = {
  inventory: ['fresh', 'ripening', 'near_expiry'],
  temperature: ['cold', 'ambient'],
  urgency: ['urgent', 'normal', 'low'],
};

export default StatusIndicator;

import React from 'react';
import { ChartBar, Package, Truck } from 'lucide-react';
import {
  StatsCard,
  StatsGrid,
  StatBox,
  StatIcon,
  StatTitle,
  StatValue,
  StatSubtitle,
  BreakdownList
} from './styles';

const DashboardStats = ({ overviewStats }) => {
  return (
    <StatsCard>
      <StatsGrid>
        <StatBox>
          <StatIcon><ChartBar size={32} /></StatIcon>
          <StatTitle>Warehouse Stock</StatTitle>
          <StatValue>{overviewStats?.totalPallets || 0}</StatValue>
          <StatSubtitle>Total Pallets</StatSubtitle>
          <StatSubtitle>
            {(overviewStats?.totalKgWarehouse || 0).toLocaleString()} kg Total Weight
          </StatSubtitle>
        </StatBox>
        
        <StatBox>
          <StatIcon><Package size={32} /></StatIcon>
          <StatTitle>Order Assignment</StatTitle>
          <StatValue>{overviewStats?.activeOrders || 0}</StatValue>
          <StatSubtitle>Active Orders</StatSubtitle>
          <StatSubtitle>
            {(overviewStats?.totalKgAssigned || 0).toLocaleString()} kg Assigned
          </StatSubtitle>
        </StatBox>
        
        <StatBox>
          <StatIcon><Truck size={32} /></StatIcon>
          <StatTitle>Today's Activity</StatTitle>
          <StatValue>{overviewStats?.shippedToday || 0}</StatValue>
          <StatSubtitle>Pallets Shipped</StatSubtitle>
          <BreakdownList>
            {overviewStats.todayByType?.map(({ type, count }) => (
              <div key={type} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{type}:</span>
                <span>{count} added</span>
              </div>
            ))}
          </BreakdownList>
        </StatBox>
      </StatsGrid>
    </StatsCard>
  );
};

export default DashboardStats;

import React from 'react';
import {
  ExportModal,
  ExportContent,
  ExportHeader,
  ExportTitle,
  CloseButton,
  CodeBlock
} from './MapView.styles';

const ZoneExportModal = ({ zones, onClose }) => {
  return (
    <ExportModal onClick={onClose}>
      <ExportContent onClick={(e) => e.stopPropagation()}>
        <ExportHeader>
          <ExportTitle>Zone Positions</ExportTitle>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ExportHeader>
        <p>Copy these position values to update your configuration:</p>
        <CodeBlock>
          {zones.map(zone => 
            `${zone.name}: (${zone.position.x}, ${zone.position.y})${zone.dimensions ? ` - ${zone.dimensions.rows}×${zone.dimensions.cols}` : ''}`
          ).join('\n')}
        </CodeBlock>
        <p>Or use this JSON format:</p>
        <CodeBlock>
          {JSON.stringify(zones.reduce((acc, zone) => {
            acc[zone.id] = {
              position: zone.position,
              dimensions: zone.dimensions
            };
            return acc;
          }, {}), null, 2)}
        </CodeBlock>
      </ExportContent>
    </ExportModal>
  );
};

export default ZoneExportModal;

import { useState, useEffect } from 'react';
import { zoneUpdateService } from '@features/map/services/zoneUpdateService';
import storageAssignmentService from '@features/map/services/storageAssignmentService';
import zoneOccupancyService from '@features/map/services/zoneOccupancyService';

// Constants for bounded iteration
const MAX_ROWS = 20;
const MAX_COLUMNS = 20;
const MAX_RETRIES = 3;

/**
 * Custom hook for managing zone-related operations
 * @param {Object} props - Hook configuration
 * @param {string} props.locationId - Current location ID
 * @returns {Object} Zone management state and utilities
 */
export const useZoneManagement = ({ locationId }) => {
  const [zonesAvailable, setZonesAvailable] = useState(false);
  const [zoneOccupancy, setZoneOccupancy] = useState({});

  // Log warning in development if locationId is missing
  useEffect(() => {
    if (!locationId && process.env.NODE_ENV === 'development') {
      console.warn('useZoneManagement: locationId is missing, zone features will be disabled');
    }
  }, [locationId]);

  // Main zone initialization effect
  useEffect(() => {
    if (!locationId) return;
    let isSubscribed = true;
    let retryCount = 0;
    
    const initializeZones = async () => {
      try {
        zoneUpdateService.initialize(locationId);
        const data = await zoneUpdateService.fetchZoneData(locationId);
        const zonesWithRows = zoneUpdateService.getAllZonesWithRows();
        
        if (isSubscribed) {
          setZoneOccupancy(zonesWithRows || {});
          setZonesAvailable(Object.keys(zonesWithRows || {}).length > 0);
        }
      } catch (error) {
        console.error('Zone initialization error:', error);
        
        // Retry with exponential backoff
        if (retryCount < MAX_RETRIES) {
          retryCount++;
          setTimeout(initializeZones, Math.min(1000 * Math.pow(2, retryCount), 5000));
          return;
        }
        
        // Fall back to simulated data after max retries
        if (isSubscribed) {
          const simulatedData = zoneUpdateService.getSimulatedZoneData();
          setZoneOccupancy(simulatedData || {});
          setZonesAvailable(Object.keys(simulatedData || {}).length > 0);
        }
      }
    };

    initializeZones();
    
    return () => {
      isSubscribed = false;
      zoneUpdateService.cleanup();
    };
  }, [locationId]);

  /**
   * Find next available position in a column with bounded iteration
   * @param {string} zoneId - Zone identifier
   * @param {number} column - Column index
   * @param {number} maxRows - Maximum rows to check
   * @returns {Promise<Object>} Available position or error
   */
  const findNextAvailablePosition = async (zoneId, column, maxRows) => {
    if (!zoneId || typeof column !== 'number' || column < 0) {
      throw new Error('Invalid parameters for findNextAvailablePosition');
    }

    const boundedMaxRows = Math.min(maxRows || MAX_ROWS, MAX_ROWS);
    let iterations = 0;
    
    try {
      const occupancyData = await zoneOccupancyService.getZoneOccupancy(
        zoneId, 
        locationId,
        { forceRefresh: true }
      );

      // Search in requested column first
      for (let row = 0; row < boundedMaxRows && iterations < MAX_ROWS; row++) {
        iterations++;
        const position = { row, col: column };
        const isOccupied = checkPositionOccupancy(position, occupancyData);
        
        if (!isOccupied) {
          const isAvailable = await verifyPositionAvailability(zoneId, row, column);
          if (isAvailable) {
            return { ...position, found: true };
          }
        }
      }

      // Search other columns if requested column is full
      for (let col = 0; col < MAX_COLUMNS && iterations < MAX_ROWS * MAX_COLUMNS; col++) {
        if (col === column) continue;
        
        for (let row = 0; row < boundedMaxRows; row++) {
          iterations++;
          const position = { row, col };
          const isOccupied = checkPositionOccupancy(position, occupancyData);
          
          if (!isOccupied) {
            const isAvailable = await verifyPositionAvailability(zoneId, row, col);
            if (isAvailable) {
              return { ...position, found: true, isAlternative: true };
            }
          }
        }
      }

      return { found: false };
    } catch (error) {
      console.error('Error finding available position:', error);
      return { found: false, error: error.message };
    }
  };

  /**
   * Check if a position is occupied in occupancy data
   * @param {Object} position - Position to check
   * @param {Object} occupancyData - Zone occupancy data
   * @returns {boolean} Whether position is occupied
   */
  const checkPositionOccupancy = (position, occupancyData) => {
    if (!position || !occupancyData) return true;

    const positionKey = `${position.row}-${position.col}`;
    const legacyKey1 = `${position.row}_${position.col}`;
    const legacyKey2 = `r${position.row}c${position.col}`;

    return !!(
      occupancyData[positionKey] ||
      occupancyData[legacyKey1] ||
      occupancyData[legacyKey2]
    );
  };

  /**
   * Verify if a position is available with retries
   * @param {string} zoneId - Zone identifier
   * @param {number} row - Row index
   * @param {number} col - Column index
   * @returns {Promise<boolean>} Whether position is available
   */
  const verifyPositionAvailability = async (zoneId, row, col) => {
    if (!zoneId || typeof row !== 'number' || typeof col !== 'number' || !locationId) {
      return false;
    }

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        // Get the current occupancy data for the zone
        const occupancyData = await zoneOccupancyService.getZoneOccupancy(
          zoneId, 
          locationId
        );

        // Check if the position is occupied
        const position = { row, col };
        const isOccupied = checkPositionOccupancy(position, occupancyData);
        
        // If not occupied, the position is available
        return !isOccupied;
      } catch (error) {
        console.warn(`Position check attempt ${attempt + 1} failed:`, error.message);
        if (attempt === MAX_RETRIES - 1) return false;
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, attempt)));
      }
    }
    return false;
  };

  return {
    zonesAvailable,
    zoneOccupancy,
    findNextAvailablePosition,
    verifyPositionAvailability
  };
};

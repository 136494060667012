import React, { useState, useCallback } from 'react';
import QrScanner from 'react-qr-scanner';
import styled from 'styled-components';
import { useToast } from '@lib/contexts';
import { ScannerOverlay } from './ScannerOverlay';
import { CameraControls } from './CameraControls';
import { ScanHistory } from './ScanHistory';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import { scanHistoryService } from '../../services/scanHistoryService';

const ScannerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: relative;
  
  section {
    width: 100% !important;
    height: 100% !important;
    
    video {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 12px;
    }
  }
`;

const PermissionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
  padding: 20px;
  text-align: center;
  z-index: 30;
`;

const PermissionButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 16px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ManualInputButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.primary}10;
  }
`;

  const QRScanner = ({ onScan, onManualInputRequest, onClose, onMount, onUnmount }) => {
    const { showToast } = useToast();
    const [scannedCodes, setScannedCodes] = useState(new Set());
    const [scanStatus, setScanStatus] = useState('Initializing camera...');
    const [showHistory, setShowHistory] = useState(false);
    const [hasPermission, setHasPermission] = useState(null);
    const [isInitializing, setIsInitializing] = useState(true);
    const { successVibration, errorVibration } = useHapticFeedback();
    const [flashEnabled, setFlashEnabled] = useState(false);
    const [cameraStream, setCameraStream] = useState(null);

    // Handle mount and unmount callbacks
    React.useEffect(() => {
      // Call onMount when component mounts
      if (onMount && typeof onMount === 'function') {
        console.log('QRScanner: Calling onMount callback');
        onMount();
      }
      
      // Call onUnmount when component unmounts
      return () => {
        console.log('QRScanner: Cleaning up camera resources on unmount');
        if (cameraStream) {
          cameraStream.getTracks().forEach(track => {
            console.log(`QRScanner: Stopping track: ${track.kind}`);
            track.stop();
          });
        }
        
        if (onUnmount && typeof onUnmount === 'function') {
          console.log('QRScanner: Calling onUnmount callback');
          onUnmount();
        }
      };
    }, [cameraStream, onMount, onUnmount]);
    
    // Clean up and call the onClose callback when needed
    const handleClose = useCallback(() => {
      console.log('QRScanner: Handling close');
      
      // Clean up camera resources
      if (cameraStream) {
        cameraStream.getTracks().forEach(track => {
          console.log(`QRScanner: Stopping track on handleClose: ${track.kind}`);
          track.stop();
        });
        setCameraStream(null);
      }
      
      // Call the parent's onClose callback if provided
      if (onClose && typeof onClose === 'function') {
        console.log('QRScanner: Calling parent onClose callback');
        onClose();
      }
    }, [cameraStream, onClose]);
    
    // Add a keydown event listener to handle Escape key
    React.useEffect(() => {
      const handleEscKey = (event) => {
        if (event.key === 'Escape') {
          console.log('QRScanner: Escape key pressed');
          handleClose();
        }
      };
      
      window.addEventListener('keydown', handleEscKey);
      
      return () => {
        window.removeEventListener('keydown', handleEscKey);
      };
    }, [handleClose]);

  const handleError = useCallback((error) => {
    console.error('Scanner error:', error);
    if (error.name === 'NotAllowedError') {
      setHasPermission(false);
      showToast({
        message: 'Camera access denied. Please check your permissions.',
        variant: 'error'
      });
    } else if (error.name === 'NotFoundError') {
      showToast({
        message: 'No camera found on your device.',
        variant: 'error'
      });
    } else {
      showToast({
        message: 'Failed to initialize camera. Please try again.',
        variant: 'error'
      });
    }
    setScanStatus('Camera error');
    setIsInitializing(false);
  }, [showToast]);

  const handleScan = useCallback(async (data) => {
    if (!data) return;

    try {
      const decodedText = data.text;

      if (scannedCodes.has(decodedText)) {
        errorVibration();
        showToast({
          message: 'This code has already been scanned',
          variant: 'warning'
        });
        return;
      }

      let palletData;
      try {
        palletData = JSON.parse(decodedText);
      } catch (error) {
        throw new Error('Invalid QR code format');
      }
      
      setScannedCodes(prev => new Set([...prev, decodedText]));
      await scanHistoryService.addScan(palletData);
      
      successVibration();
      showToast({
        message: 'Scan successful',
        variant: 'success'
      });
      
      onScan(palletData);
      
    } catch (error) {
      errorVibration();
      showToast({
        message: error.message || 'Failed to process QR code',
        variant: 'error'
      });
    }
  }, [onScan, scannedCodes, showToast, successVibration, errorVibration]);

  const handleManualInput = () => {
    if (onManualInputRequest && typeof onManualInputRequest === 'function') {
      onManualInputRequest();
    } else {
      showToast({
        message: 'Manual input not available',
        variant: 'info'
      });
    }
  };

  const handleToggleFlash = () => {
    setFlashEnabled(prev => !prev);
    // Flash functionality would be implemented here
    // This is a placeholder since we're not actually controlling the flash
    showToast({
      message: flashEnabled ? 'Flash turned off' : 'Flash turned on',
      variant: 'info'
    });
  };

  const handleSwitchCamera = () => {
    // Camera switching functionality would be implemented here
    // This is a placeholder since we're not actually switching cameras
    showToast({
      message: 'Camera switching not available',
      variant: 'info'
    });
  };

  const requestCameraPermission = useCallback(async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Camera access is not supported by this browser');
      }

      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: 'environment' }
      });
      
      // Store the stream for later cleanup
      setCameraStream(stream);
      
      // Only check permission, don't stop tracks yet
      setHasPermission(true);
      setScanStatus('Ready to scan');
      setIsInitializing(false);
      
      console.log('QRScanner: Camera permission granted, stream active');
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  }, [handleError]);

  if (hasPermission === null) {
    return (
      <ScannerContainer>
        <PermissionContainer>
          <h3>Camera Access Required</h3>
          <p>We need access to your camera to scan QR codes.</p>
          <PermissionButton onClick={requestCameraPermission}>
            Allow Camera Access
          </PermissionButton>
          <ManualInputButton onClick={handleManualInput}>
            Enter Pallet ID Manually
          </ManualInputButton>
        </PermissionContainer>
      </ScannerContainer>
    );
  }

  if (hasPermission === false) {
    return (
      <ScannerContainer>
        <PermissionContainer>
          <h3>Camera Access Denied</h3>
          <p>Please enable camera access in your browser settings to use the scanner.</p>
          <PermissionButton onClick={requestCameraPermission}>
            Try Again
          </PermissionButton>
          <ManualInputButton onClick={handleManualInput}>
            Enter Pallet ID Manually
          </ManualInputButton>
        </PermissionContainer>
      </ScannerContainer>
    );
  }

  return (
    <ScannerContainer>
      <VideoContainer>
        <QrScanner
          onError={handleError}
          onScan={handleScan}
          constraints={{
            video: {
              facingMode: { exact: "environment" },
              width: { ideal: 1920 },
              height: { ideal: 1080 }
            }
          }}
          style={{ width: '100%', height: '100%' }}
        />
        <ScannerOverlay status={scanStatus} isInitializing={isInitializing} />
        <CameraControls
          onShowHistory={() => setShowHistory(true)}
          onManualInput={handleManualInput}
          onToggleFlash={handleToggleFlash}
          onSwitchCamera={handleSwitchCamera}
          onClose={handleClose}
          flashEnabled={flashEnabled}
        />
        {showHistory && <ScanHistory onClose={() => setShowHistory(false)} />}
      </VideoContainer>
    </ScannerContainer>
  );
};

export default QRScanner;

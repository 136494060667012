/**
 * Z-index management system for the application
 * 
 * This file defines all z-index values used throughout the application
 * to ensure consistent layering and prevent z-index conflicts.
 * 
 * Usage:
 * import { zIndex } from '@styles/constants/zIndex';
 * 
 * // In styled component
 * z-index: ${zIndex.map.zone.base};
 */

export const zIndex = {
  // Base layers
  base: 1,
  
  // Map component specific layers
  map: {
    container: 10,
    zone: {
      base: 20,
      hover: 30,
      selected: 40,
      dragging: 50
    },
    tooltip: 60,
    details: 70
  },
  
  // Global UI layers
  modal: 1000,
  toast: 1100,
  dropdown: 900,
  header: 800,
  drawer: 700,
  popover: 950
};

/**
 * Helper function to get zone z-index based on state
 * @param {number} baseZ - Base z-index value
 * @param {Object} state - Zone state object
 * @returns {Object} - Z-index values for different states
 */
export const getZoneZIndex = (baseZ = zIndex.map.zone.base, state = {}) => ({
  default: baseZ,
  hover: baseZ + 10,
  selected: baseZ + 20,
  dragging: baseZ + 30,
  selectedAndHover: baseZ + 40
});

export default zIndex;

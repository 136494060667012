import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Filter, Search, Map, List, Check, X, AlertTriangle, AlertCircle, Layers, Grid, Loader, Layout } from 'lucide-react';
import ScheduleStorageRowsDualView from '../ScheduleStorageRowsDualView';
import { api } from '@lib/api';
import zoneConfig from '@features/map/constants/zoneConfig.v2';
// Import service directly instead of dynamically
import { zoneUpdateService } from '@features/map/services/zoneUpdateService';
import storageAssignmentService from '@features/map/services/storageAssignmentService';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 18px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ModalBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const ModalFooter = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  margin-bottom: 16px;
`;

const Tab = styled.button`
  padding: 12px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.main : theme.colors.text.secondary};
  border-bottom: 2px solid ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.main : 'transparent'};
  font-weight: ${({ isActive }) => isActive ? '600' : '400'};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 16px 10px 40px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.lighter};
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ZoneGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const ZoneCard = styled.div`
  border: 1px solid ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.main : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  transition: all 0.2s ease;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ZoneHeader = styled.div`
  background-color: ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.lighter : theme.colors.background.hover};
  padding: 12px 16px;
  border-bottom: 1px solid ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.light : theme.colors.border.main};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ZoneTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.dark : theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ZoneInfo = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 13px;
  font-weight: 500;
`;

const RowsContainer = styled.div`
  padding: ${({ show }) => show ? '12px' : '0'};
  max-height: ${({ show }) => show ? '300px' : '0'};
  overflow-y: ${({ show }) => show ? 'auto' : 'hidden'};
  transition: all 0.3s ease;
`;

const RowItem = styled.div`
  padding: 12px;
  border: 1px solid ${({ isSelected, theme }) => 
    isSelected ? theme.colors.primary.main : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-bottom: 12px;
  cursor: pointer;
  background-color: ${({ isSelected, tomatoType, theme }) => {
    if (isSelected) return theme.colors.primary.lighter;
    
    // Subtle background based on tomato type
    switch (tomatoType?.toLowerCase()) {
      case 'round':
        return '#f8f9fa'; // Very light gray
      case 'plum':
        return '#fff8e1'; // Very light amber
      case 'cherry':
        return '#f1f8e9'; // Very light green
      default:
        return theme.colors.background.paper;
    }
  }};
  transition: all 0.2s ease;
  flex-direction: column;
  
  &:hover {
    background-color: ${({ isSelected, theme }) => 
      isSelected ? theme.colors.primary.lighter : theme.colors.background.hover};
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const RowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const RowTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${({ isSelected, theme }) => 
    isSelected ? theme.colors.primary.dark : theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RowTomatoInfo = styled.div`
  font-weight: 500;
  margin-top: 2px;
  color: #334155;
`;

const RowDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4px;
`;

const RowDetailItem = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const RowTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 8px;
`;

const Tag = styled.div`
  background-color: ${({ type, theme }) => {
    switch (type) {
      case 'date':
        return '#e0f2fe'; // Light blue
      case 'grade':
        return '#f0fdf4'; // Light green
      default:
        return theme.colors.background.hover;
    }
  }};
  color: ${({ type, theme }) => {
    switch (type) {
      case 'date':
        return '#0c4a6e'; // Dark blue
      case 'grade':
        return '#14532d'; // Dark green
      default:
        return theme.colors.text.secondary;
    }
  }};
  border: 1px solid ${({ type, theme }) => {
    switch (type) {
      case 'date':
        return '#bae6fd'; // Light blue border
      case 'grade':
        return '#bbf7d0'; // Light green border
      default:
        return theme.colors.border.main;
    }
  }};
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RowSelectionStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 13px;
  font-weight: 500;
`;

const RowCount = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.dark};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: white;
          border: none;
          
          &:hover {
            background-color: ${theme.colors.primary.dark};
          }
          
          &:disabled {
            background-color: ${theme.colors.gray[300]};
            cursor: not-allowed;
          }
        `;
      case 'secondary':
        return `
          background-color: transparent;
          color: ${theme.colors.text.primary};
          border: 1px solid ${theme.colors.border.main};
          
          &:hover {
            background-color: ${theme.colors.background.hover};
          }
        `;
      default:
        return '';
    }
  }}
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  h4 {
    margin: 16px 0 8px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  p {
    margin: 0;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const ErrorState = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.error.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.error.main};
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectedCount = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectedSection = styled.div`
  margin-top: 20px;
`;

const SelectedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const SelectedTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

const SelectedRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const SelectedRowZone = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const SelectedRowDetails = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.error.lighter};
    color: ${({ theme }) => theme.colors.error.main};
  }
`;

// Main Component
const RowSelectionModal = ({ 
  isOpen, 
  onClose, 
  locationId, 
  initiallySelectedRowIds = [],
  onRowsSelected,
  context = 'order', // New prop: 'order' or 'storage'
  singleSelectionMode = false // New prop: limit to single selection
}) => {
  const [activeTab, setActiveTab] = useState('dual');
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedZoneId, setExpandedZoneId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredZones, setFilteredZones] = useState([]);
  
  // Extract zones from zoneConfig
  const zones = Object.values(zoneConfig.DEFAULT_ZONES).filter(zone => 
    // Only include zones with rows (filter out special/merged zones if needed)
    !zone.mergedGroupId && zone.dimensions && zone.dimensions.rows > 0
  );

  // List of zone IDs that need row/column inversion
  const invertedZones = ['zone-3', 'zone-4', 'zone-5', 'zone-6', 'zone-8', 'zone-9'];
  
  // Helper function to get actual rows count (with inversion if needed)
  const getActualRowsCount = (zone) => {
    // For inverted zones, use the col count as the row count
    if (invertedZones.includes(zone.id)) {
      return zone.dimensions.cols;
    }
    return zone.dimensions.rows;
  };
  
  // Helper function to get actual cols count (with inversion if needed)
  const getActualColsCount = (zone) => {
    // For inverted zones, use the row count as the col count
    if (invertedZones.includes(zone.id)) {
      return zone.dimensions.rows;
    }
    return zone.dimensions.cols;
  };
  
  // State for storing row information from the zoneUpdateService
  const [rowsWithPalletInfo, setRowsWithPalletInfo] = useState({});
  const [loadingRowInfo, setLoadingRowInfo] = useState(false);
  const [zoneOccupancyMap, setZoneOccupancyMap] = useState({});

  // Initialize the component and fetch enhanced row information
  useEffect(() => {
    if (isOpen) {
      // Initialize filtered zones
      setFilteredZones(zones);
      
      // Fetch row information with pallet details
      const fetchRowInfo = async () => {
        setLoadingRowInfo(true);
        try {
          console.log('RowSelectionModal: Initializing zoneUpdateService with locationId:', locationId);
          
          // Use the directly imported service instead of dynamic import
          zoneUpdateService.initialize(locationId);
          
          console.log('RowSelectionModal: Fetching row data using getAllZoneRowsWithPalletInfo...');
          
          // Get enhanced row information for all zones
          const allRowInfo = await zoneUpdateService.getAllZoneRowsWithPalletInfo(locationId);
          
          console.log('RowSelectionModal: Got detailed row information:', {
            zoneCount: Object.keys(allRowInfo).length,
            hasData: Object.keys(allRowInfo).length > 0,
            firstZoneId: Object.keys(allRowInfo)[0],
            sampleData: Object.values(allRowInfo)[0]?.[0] || 'No data'
          });
          
          // Only update state if we actually got data back
          if (Object.keys(allRowInfo).length > 0) {
            setRowsWithPalletInfo(allRowInfo);
          } else {
            console.warn('RowSelectionModal: No row data returned from service');
          }
        } catch (err) {
          console.error('RowSelectionModal: Error fetching row information:', err);
          
          // Log more details about the error
          if (err.message) console.error('Error message:', err.message);
          if (err.stack) console.error('Stack trace:', err.stack);
          
          // Don't set error state - we can still function without this enhanced info
        } finally {
          setLoadingRowInfo(false);
        }
      };
      
      // Execute the fetch function
      fetchRowInfo();
      
      // Initialize with pre-selected rows if any
      if (initiallySelectedRowIds && initiallySelectedRowIds.length > 0) {
        setLoading(true);
        try {
          // Find all rows with matching ids
          const preselectedRows = [];
          
          zones.forEach(zone => {
            const rowCount = zone.dimensions.rows;
            
            for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
              // Create a unique id for the row
              const rowId = `${zone.id}-row-${rowIndex}`;
              
              if (initiallySelectedRowIds.includes(rowId)) {
                preselectedRows.push({
                  rowId,
                  zoneId: zone.id,
                  zoneName: zone.name,
                  rowIndex,
                  capacity: zone.dimensions.cols // Number of positions in the row
                });
              }
            }
          });
          
          setSelectedRows(preselectedRows);
        } catch (err) {
          console.error('Error initializing selected rows:', err);
          setError('Failed to initialize selected rows.');
        } finally {
          setLoading(false);
        }
      }
    }
    
    // Cleanup function
    return () => {
      // Reset state when component closes
      setRowsWithPalletInfo({});
    };
  }, [isOpen, initiallySelectedRowIds, locationId]);
  
  // Filter zones based on search query
  useEffect(() => {
    const filterZones = () => {
      if (!searchQuery.trim()) {
        setFilteredZones(zones);
        return;
      }
      
      const query = searchQuery.toLowerCase();
      const filtered = zones.filter(zone => 
        zone.id?.toLowerCase().includes(query) ||
        zone.name?.toLowerCase().includes(query)
      );
      
      setFilteredZones(filtered);
    };

    filterZones();
  }, [searchQuery, zones, setFilteredZones]); // Add setFilteredZones to dependencies
  
  // Toggle zone expansion and fetch occupancy data
  const handleZoneClick = async (zoneId) => {
    if (expandedZoneId !== zoneId) {
      try {
        const occupancyData = await storageAssignmentService.getZoneOccupancy(zoneId, locationId);
        setZoneOccupancyMap(prev => ({
          ...prev,
          [zoneId]: occupancyData || {}
        }));
      } catch (error) {
        console.error('Failed to fetch zone occupancy:', error);
      }
    }
    setExpandedZoneId(expandedZoneId === zoneId ? null : zoneId);
  };
  
  // Toggle row selection
  const handleRowSelect = (row) => {
    const { zoneId, rowIndex } = row;
    const rowId = `${zoneId}-row-${rowIndex}`;
    
    setSelectedRows(prevSelectedRows => {
      // Check if this row is already selected
      const isSelected = prevSelectedRows.some(r => r.rowId === rowId);
      
      if (isSelected) {
        // Remove from selection
        return prevSelectedRows.filter(r => r.rowId !== rowId);
      } else {
        // Add to selection
        const zone = zones.find(z => z.id === zoneId);
        const newRow = {
          rowId,
          zoneId,
          zoneName: zone?.name || zoneId,
          rowIndex,
          capacity: zone?.dimensions?.cols || 0
        };
        
        // For single selection mode, replace all selections with the new one
        if (singleSelectionMode) {
          return [newRow];
        }
        
        // For multi-selection mode, add to existing selections
        return [...prevSelectedRows, newRow];
      }
    });
  };
  
  // Handle selecting all rows in a zone
  const handleSelectAllZoneRows = (zone) => {
    // Get all rows from this zone
    const zoneRows = [];
    const rowCount = getActualRowsCount(zone);
    const positionCount = getActualColsCount(zone);
    
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      zoneRows.push({
        rowId: `${zone.id}-row-${rowIndex}`,
        zoneId: zone.id,
        zoneName: zone.name,
        rowIndex,
        capacity: positionCount
      });
    }
    
    // Check if all rows are already selected
    const allSelected = zoneRows.every(row => 
      selectedRows.some(r => r.rowId === row.rowId)
    );
    
    if (allSelected) {
      // Deselect all rows from this zone
      setSelectedRows(prevSelectedRows => 
        prevSelectedRows.filter(r => r.zoneId !== zone.id)
      );
    } else {
      // Select all rows from this zone (avoiding duplicates)
      setSelectedRows(prevSelectedRows => {
        // Filter out existing rows from this zone
        const filteredRows = prevSelectedRows.filter(r => r.zoneId !== zone.id);
        
        // Add all rows from this zone
        return [...filteredRows, ...zoneRows];
      });
    }
  };
  
  // Remove a selected row
  const handleRemoveSelectedRow = (rowId) => {
    setSelectedRows(prevSelectedRows => 
      prevSelectedRows.filter(r => r.rowId !== rowId)
    );
  };
  
  // Save selected rows
  const handleSave = () => {
    onRowsSelected(selectedRows);
    onClose();
  };
  
  if (!isOpen) return null;
  
  return (
    <ModalOverlay>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>
            {context === 'storage' ? 'Select Storage Row for Pallet' : 'Schedule Storage Rows for Order'}
          </ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={24} />
          </CloseButton>
        </ModalHeader>
        
        <ModalBody>
          <Tabs>
            <Tab 
              isActive={activeTab === 'zones'} 
              onClick={() => setActiveTab('zones')}
            >
              <Map size={16} />
              Zone Rows
            </Tab>
            <Tab 
              isActive={activeTab === 'dual'} 
              onClick={() => setActiveTab('dual')}
            >
              <Layout size={16} />
              Dual View
            </Tab>
            <Tab 
              isActive={activeTab === 'selected'} 
              onClick={() => setActiveTab('selected')}
            >
              <Check size={16} />
              Selected Rows
              <span>({selectedRows.length})</span>
            </Tab>
          </Tabs>
          
          {activeTab === 'dual' && (
            <>
              {selectedRows.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                  <SelectedCount>
                    <Check size={16} />
                    {selectedRows.length} rows selected
                  </SelectedCount>
                </div>
              )}
              
              <ScheduleStorageRowsDualView
                order={{ scheduled_rows: selectedRows }}
                scheduledRows={selectedRows}
                onRowSelect={(row) => {
                  handleRowSelect({
                    zoneId: row.zoneId,
                    rowIndex: row.rowIndex
                  });
                }}
                onZoneClick={(zoneName) => {
                  // Find the zone and expand it
                  const zone = zones.find(z => z.name === zoneName || z.id === zoneName);
                  if (zone) {
                    setExpandedZoneId(zone.id);
                    setActiveTab('zones'); // Switch to zones tab to see the expanded zone
                  }
                }}
                mapHeight="450px"
              />
            </>
          )}
          
          {activeTab === 'zones' && (
            <>
              <SearchContainer>
                <SearchIcon>
                  <Search size={16} />
                </SearchIcon>
                <SearchInput 
                  placeholder="Search zones by name or ID..."
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
              </SearchContainer>
              
              {error && (
                <ErrorState>
                  <AlertTriangle size={18} />
                  {error}
                </ErrorState>
              )}
              
              {loading ? (
                <EmptyState>
                  <div className="loading-spinner"></div>
                  <h4>Loading zones...</h4>
                </EmptyState>
              ) : (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    {selectedRows.length > 0 && (
                      <SelectedCount>
                        <Check size={16} />
                        {selectedRows.length} rows selected
                      </SelectedCount>
                    )}
                  </div>
                  
              {loadingRowInfo && (
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  padding: '8px',
                  background: '#f0f9ff',
                  borderRadius: '4px',
                  marginBottom: '16px',
                  color: '#0369a1'
                }}>
                  <Loader size={16} className="animate-spin" style={{ marginRight: '8px' }} />
                  <span>Loading enhanced row information...</span>
                </div>
              )}
              
              {filteredZones.length === 0 ? (
                <EmptyState>
                  <AlertCircle size={48} />
                  <h4>No zones found</h4>
                  <p>
                    {searchQuery 
                      ? 'Try adjusting your search query.' 
                      : 'No storage zones available in this location.'}
                  </p>
                </EmptyState>
              ) : (
                    <ZoneGrid>
                      {filteredZones.map(zone => {
                        // Count how many rows in this zone are selected
                        const selectedRowsInZone = selectedRows.filter(r => r.zoneId === zone.id).length;
                        // Total rows in this zone
                        const totalRows = zone.dimensions.rows;
                        // Is this zone fully selected?
                        const isFullySelected = selectedRowsInZone === totalRows;
                        // Is this zone partially selected?
                        const isPartiallySelected = selectedRowsInZone > 0 && selectedRowsInZone < totalRows;
                        
                        return (
                          <ZoneCard key={zone.id} isActive={expandedZoneId === zone.id}>
                            <ZoneHeader 
                              isActive={expandedZoneId === zone.id}
                              onClick={() => handleZoneClick(zone.id)}
                            >
                              <ZoneTitle isActive={expandedZoneId === zone.id}>
                                <Map size={16} />
                                {zone.name}
                              </ZoneTitle>
                              <ZoneInfo>
                                {getActualRowsCount(zone)} rows
                                {selectedRowsInZone > 0 && ` (${selectedRowsInZone} selected)`}
                                <Button 
                                  style={{ 
                                    marginLeft: '8px', 
                                    padding: '2px 6px',
                                    backgroundColor: isFullySelected ? '#e0f7fa' : 'transparent'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSelectAllZoneRows(zone);
                                  }}
                                >
                                  {isFullySelected ? 'Deselect All' : 'Select All'}
                                </Button>
                              </ZoneInfo>
                            </ZoneHeader>
                            
                            <RowsContainer show={expandedZoneId === zone.id}>
                              {Array.from({ length: getActualRowsCount(zone) }).map((_, rowIndex) => {
                                const rowId = `${zone.id}-row-${rowIndex}`;
                                const isSelected = selectedRows.some(r => r.rowId === rowId);
                                const positionCount = getActualColsCount(zone);
                                
                                // Get enhanced row information if available
                                const rowInfo = rowsWithPalletInfo[zone.id]?.find(r => r.rowIndex === rowIndex);
                                const hasPallets = rowInfo && rowInfo.palletCount > 0;
                                
                                // Get occupancy data for this specific row
                                const zoneOccupancy = zoneOccupancyMap[zone.id] || {};
                                const rowOccupancy = Object.entries(zoneOccupancy).filter(([key]) => {
                                  const position = key.split('-');
                                  return parseInt(position[0]) === rowIndex;
                                });
                                
                                // Calculate occupancy percentage
                                const occupancyPercentage = (rowOccupancy.length / positionCount) * 100;
                                
                                return (
                                  <RowItem 
                                    key={rowId}
                                    isSelected={isSelected}
                                    tomatoType={rowInfo?.tomatoType}
                                    onClick={() => handleRowSelect({
                                      zoneId: zone.id,
                                      rowIndex
                                    })}
                                  >
                                    <RowHeader>
                                      <div>
                                        <RowTitle isSelected={isSelected}>
                                          <Grid size={16} />
                                          Row {rowIndex + 1}
                                        </RowTitle>
                                        
                                        {hasPallets && rowInfo.tomatoType && (
                                          <RowTomatoInfo>
                                            {rowInfo.tomatoType}
                                            {rowInfo.tomatoOption && ` - ${rowInfo.tomatoOption}`}
                                          </RowTomatoInfo>
                                        )}
                                      </div>
                                      
                                      {isSelected ? (
                                        <RowSelectionStatus>
                                          <Check size={14} />
                                          Selected
                                        </RowSelectionStatus>
                                      ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                          <RowCount>
                                            {rowOccupancy.length} / {positionCount} positions
                                          </RowCount>
                                          {hasPallets && (
                                            <RowCount style={{ backgroundColor: '#e0f2fe', color: '#0369a1' }}>
                                              {rowInfo.palletCount} {rowInfo.palletCount === 1 ? 'pallet' : 'pallets'}
                                            </RowCount>
                                          )}
                                        </div>
                                      )}
                                    </RowHeader>
                                    
                                    <div>
                                      {/* Occupancy bar */}
                                      <div style={{ 
                                        width: '100%', 
                                        height: '6px', 
                                        backgroundColor: '#e2e8f0',
                                        borderRadius: '3px',
                                        marginTop: '8px',
                                        marginBottom: '8px'
                                      }}>
                                        <div style={{
                                          width: `${occupancyPercentage}%`,
                                          height: '100%',
                                          backgroundColor: occupancyPercentage >= 90 ? '#ef4444' : '#3b82f6',
                                          borderRadius: '3px',
                                          transition: 'width 0.3s ease'
                                        }} />
                                      </div>
                                      
                                      {hasPallets && (
                                        <RowTags>
                                          {rowInfo.storageDateText && (
                                            <Tag type="date">
                                              Stored {rowInfo.storageDateText}
                                            </Tag>
                                          )}
                                          
                                          {rowInfo.sortingGrade && (
                                            <Tag type="grade">
                                              Grade: {rowInfo.sortingGrade}
                                            </Tag>
                                          )}
                                        </RowTags>
                                      )}
                                    </div>
                                  </RowItem>
                                );
                              })}
                            </RowsContainer>
                          </ZoneCard>
                        );
                      })}
                    </ZoneGrid>
                  )}
                </>
              )}
            </>
          )}
          
          {activeTab === 'selected' && (
            <>
              {selectedRows.length === 0 ? (
                <EmptyState>
                  <AlertCircle size={48} />
                  <h4>No rows selected</h4>
                  <p>
                    Go to the Zone Rows tab to select storage rows to schedule for this order.
                  </p>
                </EmptyState>
              ) : (
                <SelectedSection>
                  <SelectedHeader>
                    <SelectedTitle>
                      <Layers size={18} />
                      Selected Storage Rows
                    </SelectedTitle>
                    <Button 
                      variant="secondary"
                      onClick={() => setSelectedRows([])}
                    >
                      Clear All
                    </Button>
                  </SelectedHeader>
                  
                  {/* Group by zone for better organization */}
                  {Object.entries(
                    selectedRows.reduce((acc, row) => {
                      if (!acc[row.zoneName]) {
                        acc[row.zoneName] = [];
                      }
                      acc[row.zoneName].push(row);
                      return acc;
                    }, {})
                  ).map(([zoneName, zoneRows]) => (
                    <div key={zoneName} style={{ marginBottom: '16px' }}>
                      <div style={{ 
                        fontSize: '15px', 
                        fontWeight: '600', 
                        marginBottom: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px'
                      }}>
                        <Map size={16} />
                        {zoneName}
                        <span style={{ 
                          fontSize: '13px', 
                          fontWeight: 'normal',
                          color: '#666'
                        }}>
                          ({zoneRows.length} rows)
                        </span>
                      </div>
                      
                      {zoneRows
                        .sort((a, b) => a.rowIndex - b.rowIndex)
                        .map(row => {
                          // Get enhanced row information if available
                          const rowInfo = rowsWithPalletInfo[row.zoneId]?.find(r => r.rowIndex === row.rowIndex);
                          const hasPallets = rowInfo && rowInfo.palletCount > 0;
                          
                          return (
                            <SelectedRow 
                              key={row.rowId}
                              style={{
                                backgroundColor: rowInfo?.tomatoType ? 
                                  rowInfo.tomatoType.toLowerCase() === 'round' ? '#f8f9fa' :
                                  rowInfo.tomatoType.toLowerCase() === 'plum' ? '#fff8e1' :
                                  rowInfo.tomatoType.toLowerCase() === 'cherry' ? '#f1f8e9' :
                                  undefined : undefined
                              }}
                            >
                              <SelectedRowInfo>
                                <SelectedRowZone>
                                  Row {row.rowIndex + 1}
                                  {hasPallets && rowInfo.tomatoType && (
                                    <span style={{ marginLeft: '8px', fontWeight: 'normal' }}>
                                      • {rowInfo.tomatoType}
                                      {rowInfo.tomatoOption && ` - ${rowInfo.tomatoOption}`}
                                    </span>
                                  )}
                                </SelectedRowZone>
                                <SelectedRowDetails>
                                  {hasPallets ? (
                                    <>
                                      {rowInfo.palletCount} {rowInfo.palletCount === 1 ? 'pallet' : 'pallets'} stored
                                      {rowInfo.storageDateText && ` • Stored ${rowInfo.storageDateText}`}
                                      {rowInfo.sortingGrade && ` • Grade: ${rowInfo.sortingGrade}`}
                                    </>
                                  ) : (
                                    `Capacity: ${row.capacity} positions`
                                  )}
                                </SelectedRowDetails>
                              </SelectedRowInfo>
                              
                              <RemoveButton
                                onClick={() => handleRemoveSelectedRow(row.rowId)}
                                title="Remove row"
                              >
                                <X size={18} />
                              </RemoveButton>
                            </SelectedRow>
                          );
                        })}
                    </div>
                  ))}
                </SelectedSection>
              )}
            </>
          )}
        </ModalBody>
        
        <ModalFooter>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading}
          >
            <Check size={16} />
            {context === 'storage' 
              ? `Assign to ${selectedRows.length} Row${selectedRows.length !== 1 ? 's' : ''}` 
              : `Schedule ${selectedRows.length} Row${selectedRows.length !== 1 ? 's' : ''}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default RowSelectionModal;

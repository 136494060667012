// TODO: In the future, this will be replaced with a dynamic location selection system
// For now, we're focusing on Logifour's printers to avoid array mapping issues
export const LOCATION = {
  ID: '7',
  NAME: 'Logifour',
  PRINTERS: {
    SORTING: 'Sorting Printer',
    TRAP: 'Trap Printer'
  }
};

// Hardcoded printer configuration values
export const HARDCODED_CONFIG = {
  ip_7: '84.35.132.210',
  sortingPort_7: '1515',
  trapPort_7: '1516',
  protocols_7: '["LPR", "LPD"]'
};

// Supported printer command languages
export const PRINTER_LANGUAGES = {
  EPL: 'EPL', // Eltron Programming Language
  ZPL: 'ZPL'  // Zebra Programming Language
  // Future: Add more printer languages as needed
};

// Available label types for test printing
export const LABEL_TYPES = {
  QR: 'QR',
  TEXT: 'TEXT',
  BARCODE: 'BARCODE'
  // Future: Add more label types as needed
};

// Supported printer protocols
export const PROTOCOLS = {
  LPR: 'LPR', // Line Printer Remote Protocol
  LPD: 'LPD'  // Line Printer Daemon Protocol
  // Future: Add more protocols as needed (e.g., IPP, IPPS)
};

// Printer types - currently hardcoded for Logifour
// TODO: In the future, this will be fetched from the backend based on location
export const PRINTER_TYPES = {
  SORTING: LOCATION.PRINTERS.SORTING,
  TRAP: LOCATION.PRINTERS.TRAP
};

// Sample printer commands for different languages and label types
export const SAMPLE_COMMANDS = {
  [PRINTER_LANGUAGES.EPL]: {
    [LABEL_TYPES.QR]: 'N\nq812\nQ609\nS2\nD8\nA50,50,0,3,1,1,N,"EYESU"\nB50,150,0,1,2,2,100,B,"Test"\nP1\n',
    [LABEL_TYPES.TEXT]: 'N\nq812\nQ609\nS2\nD8\nA50,50,0,3,1,1,N,"Test Label"\nP1\n',
    [LABEL_TYPES.BARCODE]: 'N\nq812\nQ609\nS2\nD8\nB50,50,0,1,2,2,100,B,"12345"\nP1\n'
  },
  [PRINTER_LANGUAGES.ZPL]: {
    [LABEL_TYPES.QR]: '^XA\n^FO50,50^ADN,36,20^FDTest^FS\n^FO50,100^BQN,2,4^FDQA,Test^FS\n^XZ',
    [LABEL_TYPES.TEXT]: '^XA\n^FO50,50^ADN,36,20^FDTest Label^FS\n^XZ',
    [LABEL_TYPES.BARCODE]: '^XA\n^FO50,50^BC^FD12345^FS\n^XZ'
  }
  // Future: Add more printer languages and their corresponding commands
};

// Default state for printer status
export const DEFAULT_PRINTER_STATUS = {
  status: 'unknown',
  testResult: null,
  isLoading: false,
  protocol: PROTOCOLS.LPR,
  labelType: LABEL_TYPES.QR,
  testText: 'Test Label',
  commandLanguage: PRINTER_LANGUAGES.EPL,
  rawCommand: SAMPLE_COMMANDS[PRINTER_LANGUAGES.EPL][LABEL_TYPES.QR],
  useRawCommand: true // Now default to raw command mode
};

// Future: Add more configuration options and defaults as needed

import React from 'react';
import { Thermometer } from 'lucide-react';
import { createPosition, getPositionKey } from '@lib/utils/positionUtils';
import { getZoneOrientation, storageToDisplayIndex } from '@features/map/constants/zoneOrientations';
import { ZONE_MAP } from '@features/map/constants/zoneConfig.v2';
import {
  StorageZoneContainer,
  StorageMetadata,
  ZoneName,
  MetadataOccupancy,
  StorageGridContainer,
  ZoneType,
  PalletGrid,
  PalletCell,
  CellContent,
  OccupancyBar,
  OccupancyFill,
  SpecialIndicator
} from './styles';

/**
 * StorageMapGrid Component
 * 
 * Pure grid visualization with no metadata
 * Renders only the grid cells without zone name, occupancy info, etc.
 */
const StorageMapGrid = ({
  // Zone data
  dimensions = { rows: 1, cols: 1 },
  positions = {}, // An object with keys like "0-0" for occupied positions
  
  // Display options - all metadata display options are false by default
  isInteractive = false, // Whether the zone is clickable
  isSelected = false, // Whether the zone is selected
  showMetadata = false, // Whether to show the metadata (zone name & occupancy)
  showOccupancyBar = false, // Whether to show the occupancy bar
  showCellLabels = false, // Whether to show position labels in cells
  showZoneType = false, // Whether to show the zone type badge
  isRefrigerated = false, // Whether to show refrigerated icon
  
  // Interaction handlers
  onClick, // Click handler for the entire zone
  onCellClick, // Click handler for individual cells
  
  // Custom rendering (optional)
  renderCellContent, // Function to render custom cell content
  
  // Additional props
  style, // Additional styles for the container
  gridStyle, // Additional styles for just the grid
  
  // Props that are supported but ignored unless display options are enabled
  zoneName,
  zoneType,
  occupiedCount = 0,
  occupancyPercentage,
  
  ...props // Any other props
}) => {
  // Calculate occupancy statistics if not provided
  const totalCells = dimensions.rows * dimensions.cols;
  const calculatedOccupiedCount = occupiedCount || (positions ? Object.keys(positions).length : 0);
  const calculatedOccupancyPercentage = occupancyPercentage || 
    Math.round((calculatedOccupiedCount / totalCells) * 100) || 0;
  
  // Generate cells for the grid
  const renderCells = () => {
    const cells = [];
    
    // Use the provided dimensions for rendering the grid
    // This ensures we use visual dimensions for map view and actual dimensions for row view
    const { rows, cols } = dimensions;

    // Check if this zone has a custom layout
    const zoneId = props['data-zone-id'];
    const zoneConfig = zoneId && ZONE_MAP[zoneId];
    const hasCustomLayout = zoneConfig?.customLayout;

    if (hasCustomLayout && zoneConfig.gridLayout) {
      // For custom layout zones, render cells based on gridLayout
      Object.values(zoneConfig.gridLayout).forEach((segment, segmentIndex) => {
        const segmentRows = segment.height;
        const segmentCols = segment.width;
        const offsetX = segment.x - zoneConfig.position.x;
        const offsetY = segment.y - zoneConfig.position.y;

        // Calculate logical row offset for this segment
        let logicalRowOffset = 0;
        if (segmentIndex > 0) {
          // Sum up heights of previous segments to get logical row offset
          logicalRowOffset = Object.values(zoneConfig.gridLayout)
            .slice(0, segmentIndex)
            .reduce((total, prevSegment) => total + prevSegment.height, 0);
        }

        for (let rowIndex = 0; rowIndex < segmentRows; rowIndex++) {
          for (let colIndex = 0; colIndex < segmentCols; colIndex++) {
            // Use logical row index for display purposes
            const logicalRow = logicalRowOffset + rowIndex;
            // Create cell with logical row index but actual column position
            cells.push(createCell(logicalRow, colIndex));
          }
        }
      });
    } else {
      // For regular zones, render normal grid
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        for (let colIndex = 0; colIndex < cols; colIndex++) {
          cells.push(createCell(rowIndex, colIndex));
        }
      }
    }

    return cells;
  };

  // Helper function to create a single cell
  const createCell = (rowIndex, colIndex) => {
    const zoneId = props['data-zone-id'];
    const zoneConfig = zoneId && ZONE_MAP[zoneId];
    
    // Calculate display row index based on zone configuration
    let displayRowIndex = rowIndex;
    if (zoneConfig) {
      // Handle inverted dimensions
      if (zoneConfig.rowViewConfig?.invertRowsAndColumns) {
        const { rows } = zoneConfig.rowViewConfig.dimensions;
        displayRowIndex = rowIndex % rows;
      }
      // Transform based on orientation
      displayRowIndex = storageToDisplayIndex(displayRowIndex, zoneId, dimensions.rows);
    }
    
    // Use standardized position key format with original rowIndex for storage
    const position = createPosition(rowIndex, colIndex);
    const positionKey = getPositionKey(position);
    
    // Check for occupation using the standardized key
    // Also check legacy formats for backward compatibility
    const legacyKey1 = `${rowIndex}_${colIndex}`;
    const legacyKey2 = `r${rowIndex}c${colIndex}`;
    
    const isOccupied = Boolean(
      positions[positionKey] || 
      positions[legacyKey1] || 
      positions[legacyKey2]
    );
    const isSelectable = Boolean(onCellClick) && !isOccupied;
    
    return (
      <PalletCell
        key={positionKey}
        $occupied={isOccupied}
        $isSelectable={isSelectable}
        onClick={isSelectable ? () => onCellClick(rowIndex, colIndex) : undefined}
        aria-label={`Position ${displayRowIndex + 1},${colIndex + 1} ${isOccupied ? 'occupied' : 'available'}`}
        data-row={displayRowIndex + 1}
        data-col={colIndex + 1}
      >
        {/* Always create cell content, but only show text when showCellLabels is true */}
        <CellContent $occupied={isOccupied}>
          {showCellLabels && (
              renderCellContent ? 
                renderCellContent(displayRowIndex, colIndex, isOccupied) : 
                `${displayRowIndex + 1},${colIndex + 1}`
          )}
        </CellContent>
      </PalletCell>
    );
  };
  
  return (
    <StorageZoneContainer style={style} {...props}>
      {/* Zone metadata - outside the grid */}
      {showMetadata && (
        <StorageMetadata>
          <ZoneName>
            {zoneName || 'Zone'}
            {showZoneType && zoneType && (
              <ZoneType>{zoneType}</ZoneType>
            )}
          </ZoneName>
          
          <MetadataOccupancy>
            {calculatedOccupiedCount}/{totalCells} positions filled
          </MetadataOccupancy>
        </StorageMetadata>
      )}
      
      {/* Grid container - only contains the actual grid */}
      <StorageGridContainer
        $selected={isSelected}
        $isInteractive={isInteractive}
        onClick={isInteractive && onClick ? onClick : undefined}
        style={gridStyle}
        role={isInteractive ? "button" : undefined}
        aria-pressed={isInteractive ? isSelected : undefined}
      >
        {/* Refrigerated indicator */}
        {isRefrigerated && (
          <SpecialIndicator>
            <Thermometer size={12} />
          </SpecialIndicator>
        )}
        
        {/* Pallet grid - focused on just showing the positions */}
        <PalletGrid 
          $rows={dimensions.rows} 
          $cols={dimensions.cols}
        >
          {renderCells()}
        </PalletGrid>
      </StorageGridContainer>
      
      {/* Occupancy bar - outside the grid */}
      {showOccupancyBar && (
        <OccupancyBar>
          <OccupancyFill $percentage={calculatedOccupancyPercentage} />
        </OccupancyBar>
      )}
    </StorageZoneContainer>
  );
};

export default StorageMapGrid;

import { useState, useEffect, useCallback, useRef } from 'react';
import { api } from '@lib/api';
import usePersistentState from '@lib/hooks/usePersistentState';
import kleinpakSSEManager from '../../../../../lib/services/sse/KleinpakSSEManager';

// Utility functions for request throttling
const REQUEST_CACHE_TIME = 2000; // 2 seconds cache time
const requestCache = new Map();

// Check if a request is cached and still valid
const isRequestCached = (key) => {
  if (!requestCache.has(key)) return false;
  const { timestamp, promise } = requestCache.get(key);
  return Date.now() - timestamp < REQUEST_CACHE_TIME && promise;
};

// Cache a request with its promise result
const cacheRequest = (key, promise) => {
  requestCache.set(key, {
    timestamp: Date.now(),
    promise
  });
  return promise;
};

// Get a cached request or run the request function if not cached
const getCachedRequest = (key, requestFn) => {
  if (isRequestCached(key)) {
    console.log(`Using cached request for: ${key}`);
    return requestCache.get(key).promise;
  }
  console.log(`Making fresh request for: ${key}`);
  return cacheRequest(key, requestFn());
};

// Custom hook to handle all order data fetching and processing
const useOrderData = (locationId, userId) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  // Use persistent state for activeOrderId to maintain across refreshes
  const [activeOrderId, setActiveOrderId] = usePersistentState(
    `kleinpak_active_order_${locationId}_${userId}`, 
    null
  );
  // Track scanned pallets to maintain state across refreshes
  const [scannedPallets, setScannedPallets] = usePersistentState(
    `kleinpak_scanned_pallets_${locationId}_${userId}`, 
    []
  );

  // Fetch pallets for a specific order - now with caching
  const fetchOrderPallets = async (orderId) => {
    const cacheKey = `pallets_${locationId}_${orderId}`;
    
    try {
      // Use cached version if available
      return await getCachedRequest(cacheKey, async () => {
        console.log(`Fetching pallets specifically for order ${orderId}`);
        const palletResponse = await api.get('/api/pallets', {
          params: {
            orderId: orderId,
            locationId
          }
        });
        
        // Keep the scroll position intact - return consistently formatted data
        let palletsData = Array.isArray(palletResponse.data.data) 
          ? palletResponse.data.data 
          : (Array.isArray(palletResponse.data) ? palletResponse.data : []);
        
        // Filter out shipped pallets and ensure they're actually assigned to this order
        palletsData = palletsData.filter(pallet => {
          // Exclude shipped pallets
          if (pallet.status === 'shipped') {
            return false;
          }
          
          // If order_id is present, it should match this order
          if (pallet.order_id || pallet.orderId) {
            const palletOrderId = pallet.order_id || pallet.orderId;
            return String(palletOrderId) === String(orderId);
          }
          
          // If no order_id, only include it if the API sent it as part of this order's pallets
          return true;
        });
        
        // Ensure all returned pallets have the order_id set explicitly
        // This helps with proper order-based filtering in components
        return palletsData.map(pallet => ({
          ...pallet,
          order_id: orderId
        }));
      });
    } catch (error) {
      console.error(`Error fetching pallets for order ${orderId}:`, error);
      return [];
    }
  };

  // Add a pallet to the scanned pallets list
  const addScannedPallet = (palletId) => {
    setScannedPallets(prev => {
      // Avoid duplicates by checking if it's already in the list
      if (!prev.includes(palletId)) {
        return [...prev, palletId];
      }
      return prev;
    });
  };

  // Clear scanned pallets for a specific order
  const clearScannedPalletsForOrder = (orderId) => {
    setScannedPallets(prev => prev.filter(id => {
      // Remove all scanned pallets related to this order
      const palletBelongsToOrder = orders.find(order => 
        order.id === orderId && 
        order.pallets && 
        order.pallets.some(p => String(p.id) === String(id))
      );
      return !palletBelongsToOrder;
    }));
  };

  // Calculate progress for an order
  const calculateOrderProgress = (order, palletsData) => {
    // Calculate progress consistently, accounting for allowed pallets
    const totalRequiredPallets = order.total_pallets || 0;
    
    // For orders with allowed_pallet_ids, only count those specific pallets
    let processedPallets = 0;
    if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
      // Convert all IDs to strings for consistent comparison
      const allowedIds = order.allowed_pallet_ids.map(id => String(id));
      // Count only pallets that are allowed for this order
      processedPallets = palletsData.filter(pallet => 
        allowedIds.includes(String(pallet.id))
      ).length;
    } else {
      // For normal orders, count all assigned pallets
      processedPallets = palletsData.length || 0;
    }
    
    // Calculate percentage, capping at 100%
    const progressPercentage = totalRequiredPallets > 0 
      ? Math.min((processedPallets / totalRequiredPallets) * 100, 100)
      : 0;
    
    // Mark pallets as scanned if they're in our scanned list
    const updatedPalletsData = palletsData.map(pallet => ({
      ...pallet,
      isScanned: scannedPallets.includes(String(pallet.id))
    }));
    
    return {
      ...order,
      pallets: updatedPalletsData,
      processedPallets,
      progressPercentage
    };
  };
  
  // Track the last fetch time to prevent excessive API calls
  const lastFetchTimeRef = useRef(0);
  const fetchThrottleMs = 3000; // 3 seconds minimum between fetches

  // Fetch orders being processed by this operator - now with caching and throttling
  const fetchOrders = async () => {
    if (!locationId) {
      setError('Location ID is missing');
      setLoading(false);
      return;
    }
    
    // Implement throttling - don't fetch if we just did
    const now = Date.now();
    if (now - lastFetchTimeRef.current < fetchThrottleMs) {
      console.log(`Throttling fetchOrders - last fetch was ${(now - lastFetchTimeRef.current) / 1000}s ago`);
      return;
    }
    
    // Update the last fetch time
    lastFetchTimeRef.current = now;
    
    // Show loading on initial load, but just refreshing indicator on updates
    if (loading) {
      setLoading(true);
    } else {
      setIsRefreshing(true);
    }
    setError(null);
    
    try {
      // Use cache key based on operator and location
      const cacheKey = `orders_${locationId}_${userId}`;
      
      // Get kleinpak orders with pallets assigned to them for this operator
      console.log(`Fetching Kleinpak orders for operator ${userId} in location ${locationId}`);
      
      // Use the cached request mechanism
      const response = await getCachedRequest(cacheKey, async () => {
        // Use direct query string format to match the working implementation in KleinpakOrderAssignmentModal
        const url = `/api/orders/location/${locationId}?isKleinpakOrder=true&excludeReadyForShipping=true&operatorId=${userId}`;
        console.log(`Making API call to: ${url}`);
        return api.get(url);
      });
      
      // Ensure we have an array to work with - fix for "n.data.map is not a function" error
      // Handle both direct array format and nested structure with response.data.data
      const ordersData = Array.isArray(response.data.data) 
        ? response.data.data 
        : (Array.isArray(response.data) ? response.data : []);
      
      // Fetch pallet details for each order
      const ordersWithPallets = await Promise.all(
        ordersData.map(async (order) => {
          try {
            // ALWAYS use the specific per-order pallet fetching for accurate pallet data
            // This combines the general refresh with the specific per-order refresh
            const palletsData = await fetchOrderPallets(order.id);
            return calculateOrderProgress(order, palletsData);
          } catch (error) {
            console.error(`Error fetching pallets for order ${order.id}:`, error);
            return {
              ...order,
              pallets: [],
              processedPallets: 0,
              progressPercentage: 0,
              tomatoTally: {},
              error: 'Failed to load pallets'
            };
          }
        })
      );
      
      // Show all orders regardless of whether they have pallets
      // This is intentional - we want to show orders assigned to operators
      // even if they don't have any pallets yet
      console.log('Orders after fetching pallets:', ordersWithPallets);
      
      // Use all orders assigned to this operator
      const ordersToShow = ordersWithPallets;
      
      console.log(`Found ${ordersToShow.length} orders for this operator`);
      setOrders(ordersToShow);
      
      // If there's one order in progress, set it as active
      if (ordersToShow.length === 1) {
        setActiveOrderId(ordersToShow[0].id);
      } else if (ordersToShow.length > 0 && !activeOrderId) {
        // If multiple orders and none active yet, select the first one
        setActiveOrderId(ordersToShow[0].id);
      } else if (activeOrderId && !ordersToShow.some(o => o.id === activeOrderId)) {
        // If current active order isn't in the list anymore, select the first available
        setActiveOrderId(ordersToShow.length > 0 ? ordersToShow[0].id : null);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(error.message || 'Failed to load orders');
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  // Handler for updating a single order in the orders list
  const updateOrderInList = useCallback((updatedOrder) => {
    setOrders(prevOrders => {
      // Find the order to update
      const orderIndex = prevOrders.findIndex(o => String(o.id) === String(updatedOrder.id));
      if (orderIndex === -1) return prevOrders; // Order not found
      
      // Create a copy of the orders array
      const newOrders = [...prevOrders];
      
      // Preserve existing pallets if not provided in the update
      if (!updatedOrder.pallets && newOrders[orderIndex].pallets) {
        updatedOrder.pallets = newOrders[orderIndex].pallets;
      }
      
      // Calculate the progress with updated data
      const updatedOrderWithProgress = calculateOrderProgress(
        updatedOrder, 
        updatedOrder.pallets || []
      );
      
      // Replace the old order with the updated one
      newOrders[orderIndex] = updatedOrderWithProgress;
      
      return newOrders;
    });
  }, []);
  
  // Handle real-time order updates
  const handleOrderUpdate = useCallback((data) => {
    if (!data || !data.orders || !Array.isArray(data.orders)) return;
    
    // Filter for orders relevant to this user
    const relevantOrders = data.orders.filter(order => 
      order.operator_id === userId || order.operatorId === userId
    );
    
    if (relevantOrders.length === 0) return;
    
    console.log(`Received real-time updates for ${relevantOrders.length} orders`);
    
    // Process each order update
    relevantOrders.forEach(async (updatedOrder) => {
      const action = data.action;
      
      if (action === 'add') {
        // If it's a new order, fetch pallets and add it to the list
        const palletsData = await fetchOrderPallets(updatedOrder.id);
        const newOrderWithPallets = calculateOrderProgress(updatedOrder, palletsData);
        
        setOrders(prevOrders => {
          // Check if order already exists
          if (prevOrders.some(o => String(o.id) === String(updatedOrder.id))) {
            return prevOrders;
          }
          return [...prevOrders, newOrderWithPallets];
        });
      } else if (action === 'update') {
        // Update the order in our list
        updateOrderInList(updatedOrder);
      } else if (action === 'delete') {
        // Remove the order from our list
        setOrders(prevOrders => 
          prevOrders.filter(o => String(o.id) !== String(updatedOrder.id))
        );
        
        // If the active order was deleted, select another one
        if (String(activeOrderId) === String(updatedOrder.id)) {
          setActiveOrderId(prevState => {
            const remainingOrders = orders.filter(o => String(o.id) !== String(updatedOrder.id));
            return remainingOrders.length > 0 ? remainingOrders[0].id : null;
          });
        }
      }
    });
  }, [userId, fetchOrderPallets, updateOrderInList, activeOrderId, orders]);
  
  // Handle real-time pallet updates
  const handlePalletUpdate = useCallback((data) => {
    if (!data || !data.pallets || !Array.isArray(data.pallets)) return;
    
    console.log(`Received real-time updates for ${data.pallets.length} pallets`);
    
    // Group pallets by order ID for more efficient updates
    const palletsByOrder = {};
    
    data.pallets.forEach(pallet => {
      const orderId = pallet.orderId || pallet.order_id;
      if (!orderId) return;
      
      if (!palletsByOrder[orderId]) {
        palletsByOrder[orderId] = [];
      }
      palletsByOrder[orderId].push(pallet);
    });
    
    // Process pallets for each affected order
    Object.entries(palletsByOrder).forEach(([orderId, orderPallets]) => {
      // Find the order in our current state
      setOrders(prevOrders => {
        const orderIndex = prevOrders.findIndex(o => String(o.id) === String(orderId));
        if (orderIndex === -1) return prevOrders; // Order not in our list
        
        const newOrders = [...prevOrders];
        const currentOrder = {...newOrders[orderIndex]};
        const currentPallets = Array.isArray(currentOrder.pallets) ? [...currentOrder.pallets] : [];
        
        // Process each pallet update
        orderPallets.forEach(updatedPallet => {
          const palletIndex = currentPallets.findIndex(p => String(p.id) === String(updatedPallet.id));
          
          if (palletIndex === -1) {
            // Pallet not found - add it
            currentPallets.push(updatedPallet);
          } else {
            // Update existing pallet
            currentPallets[palletIndex] = {
              ...currentPallets[palletIndex],
              ...updatedPallet,
              isScanned: currentPallets[palletIndex].isScanned || 
                         scannedPallets.includes(String(updatedPallet.id))
            };
          }
        });
        
        // Update the order with new pallets and recalculate progress
        newOrders[orderIndex] = calculateOrderProgress(currentOrder, currentPallets);
        
        return newOrders;
      });
    });
  }, [scannedPallets]);
  
  // Processing queue for assignment updates to prevent flooding
  const assignmentQueue = useRef(new Map());
  const assignmentProcessingRef = useRef(false);
  
  // Process the next assignment in queue
  const processAssignmentQueue = useCallback(() => {
    if (assignmentProcessingRef.current) return;
    if (assignmentQueue.current.size === 0) return;
    
    assignmentProcessingRef.current = true;
    
    // Get the first item from the queue
    const [queueId, { orderId, palletIds }] = Array.from(assignmentQueue.current.entries())[0];
    assignmentQueue.current.delete(queueId);
    
    console.log(`Processing assignment update for order ${orderId} (${palletIds.length} pallets)`);
    
    // Find the affected order
    setOrders(prevOrders => {
      const orderIndex = prevOrders.findIndex(o => String(o.id) === orderId);
      if (orderIndex === -1) {
        // Order not found, finish processing
        assignmentProcessingRef.current = false;
        // Process next item if available
        setTimeout(processAssignmentQueue, 0);
        return prevOrders;
      }
      
      // Fetch updated pallet data for this order
      fetchOrderPallets(orderId).then(palletsData => {
        // Update the order with new information
        setOrders(currentOrders => {
          const orderToUpdateIndex = currentOrders.findIndex(o => String(o.id) === orderId);
          if (orderToUpdateIndex === -1) {
            // Order not found in current state, finish processing
            assignmentProcessingRef.current = false;
            // Process next item if available
            setTimeout(processAssignmentQueue, 0);
            return currentOrders;
          }
          
          const updatedOrders = [...currentOrders];
          const orderToUpdate = {...updatedOrders[orderToUpdateIndex]};
          
          // Update the order with new pallets and recalculate progress
          updatedOrders[orderToUpdateIndex] = calculateOrderProgress(orderToUpdate, palletsData);
          
          // Finish processing and continue with queue
          assignmentProcessingRef.current = false;
          // Process next item if available
          setTimeout(processAssignmentQueue, 0);
          
          return updatedOrders;
        });
      }).catch(() => {
        // Error handling - ensure we continue processing queue
        assignmentProcessingRef.current = false;
        setTimeout(processAssignmentQueue, 0);
      });
      
      return prevOrders;
    });
  }, [fetchOrderPallets]);
  
  // Handle real-time assignment updates - now with queue to prevent flooding
  const handleAssignmentUpdate = useCallback((data) => {
    if (!data || (!data.palletIds && !data.palletId) || !data.orderId) return;
    
    const palletIds = data.palletIds || [data.palletId];
    const orderId = String(data.orderId);
    
    console.log(`Received assignment update: ${palletIds.length} pallets assigned to order ${orderId}`);
    
    // Add these to our scanned list for visual feedback
    palletIds.forEach(palletId => {
      addScannedPallet(palletId);
    });
    
    // Use a unique queue ID based on order and timestamp
    const queueId = `assignment_${orderId}_${Date.now()}`;
    
    // Add this update to our queue
    assignmentQueue.current.set(queueId, {
      orderId,
      palletIds,
      timestamp: Date.now()
    });
    
    // Start processing the queue if not already running
    if (!assignmentProcessingRef.current) {
      // Add small delay to collect possibly multiple updates
      setTimeout(processAssignmentQueue, 200);
    }
  }, [addScannedPallet, processAssignmentQueue]);
  
  // Initialize SSE connection and fetch initial data
  useEffect(() => {
    // Initial data loading
    fetchOrders();
    
    if (!locationId) return;
    
    // Initialize the SSE connection
    kleinpakSSEManager.initialize(locationId);
    
    // Subscribe to order updates
    const orderSubscriptionId = kleinpakSSEManager.subscribe('orderUpdate', handleOrderUpdate);
    
    // Subscribe to pallet updates
    const palletSubscriptionId = kleinpakSSEManager.subscribe('palletUpdate', handlePalletUpdate);
    
    // Subscribe to assignment updates
    const assignmentSubscriptionId = kleinpakSSEManager.subscribe('assignmentUpdate', handleAssignmentUpdate);
    
    // Legacy event listener for backward compatibility during transition
    const handleLegacyPalletAssigned = () => {
      console.log('Legacy pallet assignment event detected');
      // This will be handled by the SSE subscription, but keep for compatibility
      // No need to call fetchOrders() as our SSE subscriptions will handle it
    };
    
    window.addEventListener('palletAssigned', handleLegacyPalletAssigned);
    
    // Clean up subscriptions and event listeners
    return () => {
      kleinpakSSEManager.unsubscribe('orderUpdate', orderSubscriptionId);
      kleinpakSSEManager.unsubscribe('palletUpdate', palletSubscriptionId);
      kleinpakSSEManager.unsubscribe('assignmentUpdate', assignmentSubscriptionId);
      window.removeEventListener('palletAssigned', handleLegacyPalletAssigned);
    };
  }, [locationId, userId, handleOrderUpdate, handlePalletUpdate, handleAssignmentUpdate]);

  return {
    orders,
    setOrders,
    loading,
    error,
    isRefreshing,
    activeOrderId,
    setActiveOrderId,
    fetchOrders,
    fetchOrderPallets,
    scannedPallets,
    addScannedPallet,
    clearScannedPalletsForOrder
  };
};

export default useOrderData;

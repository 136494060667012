import React from 'react';
import styled from 'styled-components';
import { Wifi, WifiOff } from 'lucide-react';

const StatusContainer = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacing.lg};
  right: ${({ theme }) => theme.spacing.lg};
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  background-color: ${({ theme }) => theme.colors.background.paper};
  box-shadow: ${({ theme }) => theme.shadows.md};
  transition: ${({ theme }) => theme.transitions.fast};
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.shadows.lg};
  }
`;

const StatusDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'CONNECTED':
        return theme.colors.success.main;
      case 'CONNECTING':
        return theme.colors.warning.main;
      case 'ERROR':
      case 'DISCONNECTED':
        return theme.colors.error.main;
      default:
        return theme.colors.gray[400];
    }
  }};
`;

const StatusText = styled.span`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-left: ${({ theme }) => theme.spacing.xs};
`;

const TooltipContent = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: ${({ theme }) => theme.transitions.fast};

  ${StatusContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const ConnectionStatus = ({ status, error }) => {
  const getStatusText = () => {
    switch (status) {
      case 'CONNECTED':
        return 'Connected';
      case 'CONNECTING':
        return 'Connecting...';
      case 'DISCONNECTED':
        return 'Disconnected';
      case 'ERROR':
        return 'Connection Error';
      default:
        return 'Unknown';
    }
  };

  const getTooltipText = () => {
    switch (status) {
      case 'CONNECTED':
        return 'You are connected and receiving real-time updates';
      case 'CONNECTING':
        return 'Attempting to establish connection...';
      case 'DISCONNECTED':
        return 'Connection lost. Will attempt to reconnect automatically.';
      case 'ERROR':
        return error || 'Failed to connect. Please refresh the page.';
      default:
        return 'Connection status unknown';
    }
  };

  return (
    <StatusContainer>
      {status === 'CONNECTED' ? (
        <Wifi size={16} />
      ) : (
        <WifiOff size={16} />
      )}
      <StatusDot status={status} />
      <StatusText>{getStatusText()}</StatusText>
      <TooltipContent>
        {getTooltipText()}
      </TooltipContent>
    </StatusContainer>
  );
};

export default ConnectionStatus;

import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUserSession } from '@features/auth/hooks/useUserSession';
import { useLocationManagement } from '@features/dashboard/hooks/useLocationManagement';
import { usePermission } from '@lib/contexts';
import { LocationSelector } from '../LocationSelector';
import { ConsoleNavigation } from '../ConsoleNavigation';
import { mobileOperatorPaths } from '../../routes/mobile.routes';
import {
  Container,
  Header,
  HeaderTitle,
  ConsoleArea,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
} from './styles';

export const MobileOperatorDashboard = () => {
  const { user } = useUserSession();
  const { locations, loadUserLocations, loading: locationsLoading, error: locationsError } = useLocationManagement(user);
  const { hasConsoleAccess } = usePermission();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Calculate available consoles
  const availableConsoles = [
    hasConsoleAccess('sorting') && 'sorting',
    hasConsoleAccess('logistics') && 'logistics',
    hasConsoleAccess('kleinpak') && 'kleinpak',
    hasConsoleAccess('storage') && 'storage'
  ].filter(Boolean); // Filter out falsy values

  // Determine active console from current path
  const pathParts = location.pathname.split('/');
  const activeConsole = pathParts.includes('scan') ? 
    (localStorage.getItem('lastActiveConsole') || 'sorting') : 
    (pathParts.pop() || 'sorting');
  
  // Store the active console in localStorage when it changes
  useEffect(() => {
    if (activeConsole && !location.pathname.includes('scan')) {
      localStorage.setItem('lastActiveConsole', activeConsole);
    }
  }, [activeConsole, location.pathname]);

  useEffect(() => {
    if (locations.length > 0 && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations, selectedLocation]);

  const handleLocationChange = (e) => {
    const locationId = parseInt(e.target.value);
    const location = locations.find(loc => loc.id === locationId);
    setSelectedLocation(location);
    
    // Save the selected location to localStorage for persistence
    if (location) {
      localStorage.setItem('lastSelectedLocation', JSON.stringify(location));
    }
  };

  const handleConsoleChange = (console) => {
    const path = mobileOperatorPaths[console];
    if (path) {
      navigate(path);
    }
  };

  const handleRetry = () => {
    loadUserLocations();
  };

  if (locationsLoading) {
    return (
      <Container>
        {/* We can't know available consoles during loading, so we don't show the header */}
        <LoadingContainer>
          <LoadingSpinner />
          <div>Loading locations...</div>
        </LoadingContainer>
      </Container>
    );
  }

  if (locationsError) {
    return (
      <Container>
        {/* Error state should be clean and focused on the error */}
        <ConsoleArea>
          <ErrorContainer>
            <div>Error loading locations: {locationsError}</div>
            <RetryButton onClick={handleRetry}>
              Retry
            </RetryButton>
          </ErrorContainer>
        </ConsoleArea>
      </Container>
    );
  }

  return (
    <Container>
      {/* Only show the Operator Console header when user has access to multiple consoles */}
      {availableConsoles.length > 1 && (
        <Header>
          <HeaderTitle>Operator Console</HeaderTitle>
          {locations.length > 1 && (
            <LocationSelector
              locations={locations}
              selectedLocation={selectedLocation}
              onLocationChange={handleLocationChange}
            />
          )}
        </Header>
      )}
      
      <ConsoleArea>
        {selectedLocation ? (
          <Outlet context={{ locationId: selectedLocation.id, userId: user.id, activeConsole }} />
        ) : (
          <ErrorContainer>
            <div>No location selected. Please select a location to continue.</div>
          </ErrorContainer>
        )}
      </ConsoleArea>

      {availableConsoles.length > 1 && (
        <ConsoleNavigation
          activeConsole={activeConsole}
          onConsoleChange={handleConsoleChange}
        />
      )}
    </Container>
  );
};

export default MobileOperatorDashboard;

export const boxTypes = [
  'Sales',
  'Nexture',
  'Van Gelder Low',
  'Van Gelder High',
  'Black Crate Small',
  'Green Crate Large',
  'Black Box',
  'Royal Red',
  'Bella Tondo',
  'Green Crate Small',
  'Novy'
];

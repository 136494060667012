import React from 'react';
import { Thermometer } from 'lucide-react';
import { Zone, ZoneInner, ZoneHeader, ZoneName, ZoneFooter, OccupancyBar, OccupancyFill, OccupancyText, CoordinateDisplay } from './MapView.styles';

const ZoneItem = ({
  zone,
  zoneData,
  isSelected,
  editMode,
  isDragged,
  showGrid,
  onClick,
  onDragStart,
  onDragEnd
}) => {
  // Calculate zone size based on true dimensions
  const getZoneSize = (zone) => {
    const { rows, cols } = zone.dimensions;
    const baseSize = 20; // Base size unit in pixels
    
    // Calculate size based on actual dimensions without minimums
    const widthInPx = cols * baseSize;
    const heightInPx = rows * baseSize;
    
    return {
      width: `${widthInPx}px`,
      height: `${heightInPx}px`,
      widthVal: widthInPx,
      heightVal: heightInPx
    };
  };

  const zoneSize = getZoneSize(zone);
  
  // Determine display mode based on actual zone size
  const getDisplayMode = (width, height) => {
    if (width >= 120 && height >= 80) {
      return 'full'; // Show all metadata
    } else if (width >= 80 && height >= 60) {
      return 'medium'; // Show reduced metadata
    } else {
      return 'minimal'; // Show minimal or no text
    }
  };
  
  const displayMode = getDisplayMode(zoneSize.widthVal, zoneSize.heightVal);

  // Calculate occupancy data for the zone
  const calculateOccupancy = () => {
    // Get occupied positions count
    const occupiedPositions = zoneData?.occupancy ? Object.keys(zoneData.occupancy).length : 0;
    
    // Calculate total capacity from dimensions
    const capacity = zone.dimensions.rows * zone.dimensions.cols;
    
    // Calculate percentage occupancy (avoid division by zero)
    const percentage = capacity > 0 ? Math.round((occupiedPositions / capacity) * 100) : 0;
    
    return {
      occupied: occupiedPositions,
      capacity,
      percentage
    };
  };

  const occupancy = calculateOccupancy();

  // If this is a non-storage area, render it differently
  if (zone.isNonStorage) {
    return (
      <Zone
        key={zone.id}
        type="machine"
        occupancy={0}
        $selected={false}
        $editMode={editMode}
        zoneId={zone.id}
        draggable={editMode}
        onDragStart={(e) => onDragStart(e, zone)}
        onDragEnd={onDragEnd}
        style={{
          width: zoneSize.width,
          height: zoneSize.height,
          left: `${zone.position.x * 20}px`,
          top: `${zone.position.y * 20}px`,
          background: '#eeeeee',
          cursor: editMode ? 'move' : 'default',
          opacity: 0.7,
          zIndex: isDragged ? 10 : 'auto',
        }}
      >
        <ZoneInner>
          <div>Non-storage area</div>
          {showGrid && (
            <CoordinateDisplay>
              ({zone.position.x}, {zone.position.y})
            </CoordinateDisplay>
          )}
        </ZoneInner>
      </Zone>
    );
  }
  
  // For regular storage zones, adapt display based on zone size
  return (
    <Zone
      key={zone.id}
      type={zone.type}
      occupancy={occupancy.percentage} // Use occupancy percentage for styling
      $selected={isSelected}
      $editMode={editMode}
      $displayMode={displayMode} // Pass display mode to Zone component for styling
      zoneId={zone.id}
      onClick={onClick}
      draggable={editMode}
      onDragStart={(e) => onDragStart(e, zone)}
      onDragEnd={onDragEnd}
      tabIndex={0}
      role="button"
      aria-pressed={isSelected}
      aria-label={`${zone.name} zone, ${occupancy.occupied} of ${occupancy.capacity} positions occupied`}
      style={{
        width: zoneSize.width,
        height: zoneSize.height,
        left: `${zone.position.x * 20}px`,
        top: `${zone.position.y * 20}px`,
        zIndex: isDragged ? 10 : 'auto',
      }}
      title={`${zone.name} - ${occupancy.occupied}/${occupancy.capacity} positions filled (${occupancy.percentage}%)`} // Add tooltip for small zones
    >
      <ZoneInner>
        {/* Adaptive header based on display mode */}
        {displayMode !== 'minimal' && (
          <ZoneHeader style={displayMode === 'medium' ? { margin: '1px 0' } : {}}>
            <ZoneName 
              style={{
                fontSize: displayMode === 'full' ? '0.9rem' : '0.7rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: `${zoneSize.widthVal - 10}px`,
              }}
            >
              {zone.name}
            </ZoneName>
            {zone.isRefrigerated && displayMode === 'full' && (
              <Thermometer size={displayMode === 'full' ? 16 : 12} color="#0284c7" />
            )}
          </ZoneHeader>
        )}
        
        {/* Always show occupancy bar since it's visual and scales well */}
        <OccupancyBar 
          style={{
            marginTop: displayMode === 'full' ? undefined : '1px',
            height: displayMode === 'minimal' ? '3px' : '4px'
          }}
        >
          <OccupancyFill percentage={occupancy.percentage} />
        </OccupancyBar>
        
        {/* Only show occupancy text for full and medium modes */}
        {displayMode !== 'minimal' && (
          <ZoneFooter>
            <OccupancyText style={{ fontSize: displayMode === 'full' ? '0.7rem' : '0.6rem' }}>
              {displayMode === 'full' 
                ? `${occupancy.occupied}/${occupancy.capacity}`
                : `${occupancy.percentage}%`}
            </OccupancyText>
          </ZoneFooter>
        )}
        
        {showGrid && (
          <CoordinateDisplay style={{ fontSize: displayMode === 'full' ? '8px' : '6px' }}>
            ({zone.position.x}, {zone.position.y})
          </CoordinateDisplay>
        )}
      </ZoneInner>
    </Zone>
  );
};

export default ZoneItem;

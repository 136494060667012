import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast } from '@lib/components/ui/feedback';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = useCallback(({ message, variant = 'info', duration = 3000 }) => {
    // If message is null, just clear the toast
    if (!message) {
      setToast(null);
      return;
    }

    // Ensure we have valid values
    setToast({
      message: String(message), // Convert to string to handle non-string messages
      variant: variant || 'info',
      duration: duration || 3000
    });
  }, []);

  const hideToast = useCallback(() => {
    setToast(null);
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Toast
          message={toast.message}
          variant={toast.variant}
          duration={toast.duration}
          onClose={hideToast}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

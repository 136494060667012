/**
 * SSECoordinator.js
 * 
 * Central manager for coordinating SSE events across multiple clients.
 * Handles deduplication of events and ensures subscribers get notified
 * in a consistent manner.
 */

class SSECoordinator {
  constructor() {
    this.clients = new Map(); // Map of clientId -> client instance
    this.processedEvents = new Map(); // Map of eventId -> timestamp
    this.maxProcessedEvents = 1000; // Cap the size of processedEvents to prevent memory leaks
    this.maxEventAge = 60 * 60 * 1000; // One hour in milliseconds
    this.cleanupInterval = null;
    
    // Start cleanup interval to prevent memory leaks
    this.startCleanupInterval();
    
    console.log('SSECoordinator initialized:', {
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Start the cleanup interval to remove old events
   */
  startCleanupInterval() {
    // Clear any existing interval
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
    }
    
    // Set up interval to clean up old events
    this.cleanupInterval = setInterval(() => {
      this.cleanupOldEvents();
    }, 10 * 60 * 1000); // 10 minutes
  }
  
  /**
   * Clean up old events to prevent memory leaks
   */
  cleanupOldEvents() {
    const now = Date.now();
    let deletedCount = 0;
    
    // Remove events older than maxEventAge
    this.processedEvents.forEach((timestamp, eventId) => {
      if (now - timestamp > this.maxEventAge) {
        this.processedEvents.delete(eventId);
        deletedCount++;
      }
    });
    
    // If we still have too many events, remove the oldest ones
    if (this.processedEvents.size > this.maxProcessedEvents) {
      // Convert to array so we can sort by timestamp
      const events = [...this.processedEvents.entries()];
      
      // Sort by timestamp, oldest first
      events.sort((a, b) => a[1] - b[1]);
      
      // Delete the oldest events until we're under the limit
      const eventsToDelete = events.slice(0, events.length - this.maxProcessedEvents);
      
      eventsToDelete.forEach(([eventId]) => {
        this.processedEvents.delete(eventId);
        deletedCount++;
      });
    }
    
    if (deletedCount > 0) {
      console.log('Cleaned up old SSE events:', {
        deletedCount,
        remainingEvents: this.processedEvents.size,
        timestamp: new Date().toISOString()
      });
    }
  }
  
  /**
   * Register a client with the coordinator
   * @param {string} clientId - The client ID
   * @param {Object} client - The client instance
   */
  registerClient(clientId, client) {
    if (!clientId || !client) return;
    
    this.clients.set(clientId, client);
    
    console.log('SSE client registered with coordinator:', {
      clientId,
      totalClients: this.clients.size,
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Unregister a client from the coordinator
   * @param {string} clientId - The client ID
   */
  unregisterClient(clientId) {
    if (!clientId) return;
    
    this.clients.delete(clientId);
    
    console.log('SSE client unregistered from coordinator:', {
      clientId,
      totalClients: this.clients.size,
      timestamp: new Date().toISOString()
    });
  }
  
  /**
   * Process an event
   * @param {string} clientId - The client ID
   * @param {string} eventType - The event type
   * @param {Object} eventData - The event data
   * @param {string} eventId - The event ID
   * @returns {boolean} True if the event was processed, false if it was a duplicate
   */
  processEvent(clientId, eventType, eventData, eventId) {
    // In case eventId is not provided, generate one from the event data
    const effectiveEventId = eventId || this.generateEventId(eventType, eventData);
    
    // Check if we've already processed this event
    if (this.processedEvents.has(effectiveEventId)) {
      console.log('Duplicate event detected, skipping:', {
        clientId,
        eventType,
        eventId: effectiveEventId,
        timestamp: new Date().toISOString()
      });
      return false;
    }
    
    // Store that we've processed this event
    this.processedEvents.set(effectiveEventId, Date.now());
    
    console.log('Event processed:', {
      clientId,
      eventType,
      eventId: effectiveEventId,
      timestamp: new Date().toISOString()
    });
    
    // If the processedEvents map gets too large, trigger a cleanup
    if (this.processedEvents.size > this.maxProcessedEvents) {
      this.cleanupOldEvents();
    }
    
    return true;
  }
  
  /**
   * Get the client that processed an event
   * @param {string} eventId - The event ID
   * @returns {Object|null} The client instance, or null if not found
   */
  getEventClient(eventId) {
    // For now, just check if the event has been processed
    return this.processedEvents.has(eventId);
  }
  
  /**
   * Generate an event ID from the event data
   * @param {string} eventType - The event type
   * @param {Object} eventData - The event data
   * @returns {string} The generated event ID
   */
  generateEventId(eventType, eventData) {
    // Try to extract an ID from the event data
    const palletId = eventData.palletId || eventData.pallet?.id;
    const zoneId = eventData.zoneId || eventData.zone?.id || eventData.toZoneId || eventData.storageZone;
    const rowCol = eventData.position ? `${eventData.position.row}-${eventData.position.col}` : '';
    const sequence = eventData.sequence || eventData.timestamp || Date.now();
    
    // Combine into a string that uniquely identifies this event
    let idComponents = [];
    
    if (eventType) idComponents.push(eventType);
    if (palletId) idComponents.push(`pallet-${palletId}`);
    if (zoneId) idComponents.push(`zone-${zoneId}`);
    if (rowCol) idComponents.push(`pos-${rowCol}`);
    if (sequence) idComponents.push(`seq-${sequence}`);
    
    // Generate a hash string
    return `${idComponents.join('-')}-${Math.random().toString(36).substring(2, 7)}`;
  }
  
  /**
   * Check if a client is registered
   * @param {string} clientId - The client ID
   * @returns {boolean} True if the client is registered
   */
  hasClient(clientId) {
    return this.clients.has(clientId);
  }
  
  /**
   * Get the count of registered clients
   * @returns {number} The count of registered clients
   */
  getClientCount() {
    return this.clients.size;
  }
  
  /**
   * Get the count of processed events
   * @returns {number} The count of processed events
   */
  getProcessedEventCount() {
    return this.processedEvents.size;
  }
  
  /**
   * Destroy the coordinator and clean up resources
   */
  destroy() {
    // Clear the cleanup interval
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
      this.cleanupInterval = null;
    }
    
    // Clear data
    this.clients.clear();
    this.processedEvents.clear();
    
    console.log('SSECoordinator destroyed:', {
      timestamp: new Date().toISOString()
    });
  }
}

// Export a singleton instance
const sseCoordinator = new SSECoordinator();
export default sseCoordinator;

import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext, useLocation, useNavigate } from 'react-router-dom';
import { useProcessing } from '@lib/contexts';
import { useToast } from '@lib/contexts';
import { Plus } from 'lucide-react';
import { usePwaStatus } from '@lib/hooks/usePwaStatus';
import ScanTab from '@features/shared/components/ScanTab';
import { DownloadPage } from '@features/shared/components/DownloadPage';
import {
  handleShipPallet,
  handleDeletePallet,
  handleAssignOrder,
  handleReturnPallet,
  handleSplitPallet
} from '@features/shared/services/palletService';
import MobileOrderAssignmentModal from '../../MobileOrderAssignmentModal';
import { api } from '@lib/api';
import { StorageEnabledAddPalletModal } from './components';
import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton,
  Message,
  Subtitle,
  InfoCard,
  AddButton
} from './styles';

export const LogisticsConsole = () => {
  const { locationId, userId } = useOutletContext();
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { isStandalone } = usePwaStatus();
  const [activeMenu, setActiveMenu] = useState('overview');
  const menuRef = useRef(null);
  
  // Check for tab query parameter to set active tab
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam && ['overview', 'scan', 'download'].includes(tabParam)) {
      setActiveMenu(tabParam);
    }
  }, [location.search]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for order assignment modal
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isAddPalletModalOpen, setIsAddPalletModalOpen] = useState(false);
  const [palletIdsToAssign, setPalletIdsToAssign] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersError, setOrdersError] = useState(null);

  // Handle scroll animation for menu
  const handleScroll = () => {
    if (!menuRef.current) return;

    const menuButtons = menuRef.current.children;
    const containerWidth = menuRef.current.offsetWidth;
    const scrollPosition = menuRef.current.scrollLeft;

    Array.from(menuButtons).forEach(button => {
      const buttonPosition = button.offsetLeft - scrollPosition;
      const buttonWidth = button.offsetWidth;

      button.style.opacity = 
        buttonPosition < 0 || buttonPosition + buttonWidth > containerWidth
          ? "0.5"
          : "1";
    });
  };

  // Fetch orders for the order assignment modal
  const fetchOrders = async () => {
    if (!locationId) {
      setOrdersError('Location ID is missing');
      return;
    }

    setOrdersLoading(true);
    setOrdersError(null);

    try {
      const response = await api.get(`/api/orders/active?locationId=${locationId}`);
      if (Array.isArray(response.data)) {
        setOrders(response.data);
      } else {
        console.warn('API response is not an array:', response.data);
        setOrders([]);
        setOrdersError('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrdersError(error.message || 'Failed to load orders');
      setOrders([]);
    } finally {
      setOrdersLoading(false);
    }
  };

  // Pallet action handlers for QR scanner
  const handleShipPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Shipping pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleShipPallet(id, locationId)));
      showToast({ message: 'Pallets shipped successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error shipping pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleDeletePallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Deleting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleDeletePallet(id, locationId)));
      showToast({ message: 'Pallets deleted successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error deleting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleAssignPalletsToOrder = async (palletIds) => {
    // Store the pallet IDs to assign
    setPalletIdsToAssign(palletIds);
    
    // Fetch orders before opening the modal
    showProcessing({ message: 'Loading orders...', showOverlay: true });
    try {
      await fetchOrders();
      
      // Check if we have orders before opening the modal
      if (orders.length === 0 && !ordersError) {
        showToast({ 
          message: 'No active orders available for assignment. Create a new order first.', 
          variant: 'warning' 
        });
        return;
      }
      
      // Open the order assignment modal
      setIsOrderModalOpen(true);
    } catch (error) {
      showToast({ 
        message: `Error loading orders: ${error.message}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
    }
  };

  const handleOrderAssignment = async (orderId, extraBoxCount) => {
    showProcessing({ message: 'Assigning pallets to order...', showOverlay: true });
    try {
      // Use the stored pallet IDs to assign to the selected order
      await Promise.all(palletIdsToAssign.map(id => handleAssignOrder(id, orderId)));
      showToast({ message: 'Pallets assigned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error assigning pallets: ${error.message}`, variant: 'error' });
      throw error; // Re-throw to let the modal handle the error
    } finally {
      hideProcessing();
    }
  };

  const handleReturnPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Returning pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleReturnPallet(id, locationId)));
      showToast({ message: 'Pallets returned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error returning pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleSplitPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Splitting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleSplitPallet(id, locationId)));
      showToast({ message: 'Pallets split successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error splitting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleRetry = () => {
    setError(null);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'overview':
        return (
          <>
            <InfoCard>
              <Message>Logistics Console</Message>
              <Subtitle>Manage logistics operations and shipment tracking</Subtitle>
              <AddButton 
                onClick={() => setIsAddPalletModalOpen(true)}
              >
                <Plus size={16} />
                Add Pallet
              </AddButton>
            </InfoCard>
          </>
        );
      case 'scan':
        return (
          <ScanTab
            onShipPallet={handleShipPallets}
            onDeletePallet={handleDeletePallets}
            onAssignOrder={handleAssignPalletsToOrder}
            onReturnPallet={handleReturnPallets}
            onSplitPallet={handleSplitPallets}
            locationId={locationId}
            consoleType="logistics"
          />
        );
      case 'download':
        return <DownloadPage />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container>
        <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
          {['overview', 'scan'].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <LoadingContainer>
          <LoadingSpinner />
          <div>Loading console data...</div>
        </LoadingContainer>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
          {['overview', 'scan'].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <ContentArea>
          <ErrorContainer>
            <div>Error: {error}</div>
            <RetryButton onClick={handleRetry}>
              Retry
            </RetryButton>
          </ErrorContainer>
        </ContentArea>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
        <MenuButton
          active={activeMenu === 'overview'}
          onClick={() => setActiveMenu('overview')}
        >
          Overview
        </MenuButton>
        <MenuButton
          active={activeMenu === 'scan'}
          onClick={() => setActiveMenu('scan')}
        >
          Scan
        </MenuButton>
        {!isStandalone && (
          <MenuButton
            active={activeMenu === 'download'}
            onClick={() => setActiveMenu('download')}
          >
            Download
          </MenuButton>
        )}
      </ScrollableMenu>
      
      <ContentArea>
        {renderContent()}
      </ContentArea>

      {/* Order Assignment Modal */}
      <MobileOrderAssignmentModal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        onAssign={handleOrderAssignment}
        locationId={locationId}
        preloadedOrders={orders}
        ordersLoading={ordersLoading}
        ordersError={ordersError}
      />
      
      {/* Add Pallet Modal with Storage Zone Integration */}
      <StorageEnabledAddPalletModal
        isOpen={isAddPalletModalOpen}
        onClose={() => setIsAddPalletModalOpen(false)}
        locationId={locationId}
        onPalletAdded={() => {
          // The modal will be closed by the storage integration after zone assignment
          // We can add a refresh function here if needed
        }}
      />
    </Container>
  );
};

export default LogisticsConsole;

/**
 * SSEClient.js
 * Manages Server-Sent Events connection and event handling
 * Refactored to follow safety-first coding practices with bounded iteration and clear error boundaries
 */

import SSETokenManager from './modules/SSETokenManager';
import SSEHealthMonitor from './modules/SSEHealthMonitor';
import SSEEventHandler from './modules/SSEEventHandler';
import SSEConnectionManager from './modules/SSEConnectionManager';
import sseCoordinator from './SSECoordinator';

class SSEClient {
  /**
   * Creates a new SSE client instance
   * @param {string} locationId - The location ID for the connection
   * @param {Object} options - Configuration options
   */
  constructor(locationId, options = {}) {
    // Validate required parameters
    if (!locationId) {
      throw new Error('locationId is required and cannot be undefined, null, or empty');
    }

    // Core properties
    this.locationId = locationId;
    this.options = options;
    this.clientId = `sse_client_${locationId}_${Date.now()}`;
    this.currentState = 'DISCONNECTED';

    // Initialize managers
    this.tokenManager = new SSETokenManager();
    this.healthMonitor = new SSEHealthMonitor({
      heartbeatInterval: options.heartbeatInterval || 30000,
      healthCheckTimer: options.healthCheckTimer || 15000,
      maxHeartbeatMisses: options.maxHeartbeatMisses || 2
    });
    this.eventHandler = new SSEEventHandler(this.clientId);
    this.connectionManager = new SSEConnectionManager({
      baseURL: this.options.baseURL || (process.env.NODE_ENV === 'production' ? 'https://eyesu.ltd' : ''),
      maxRetries: options.maxRetries || 8,
      retryDelay: options.retryDelay || 1000,
      maxRetryDelay: options.maxRetryDelay || 60000,
      jitterFactor: options.jitterFactor || 0.3
    });

    // Set up connection manager
    this.connectionManager.setup(this.locationId, this.clientId);
    this.connectionManager.setStateChangeHandler(this._handleStateChange.bind(this));

    console.log('SSE client created:', {
      locationId: this.locationId,
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
  }

  /**
   * Handles connection state changes
   * @param {Object} state - The new connection state
   * @private
   */
  _handleStateChange(state) {
    try {
      const prevState = this.currentState;
      this.currentState = state.connectionState;

      console.log('SSE connection state transition:', {
        from: prevState,
        to: this.currentState,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });

      // Handle state-specific actions
      switch (this.currentState) {
        case 'CONNECTED':
          // Start health monitoring only if transitioning from a non-connected state
          if (prevState !== 'CONNECTED') {
            console.log('Starting health monitoring:', {
              clientId: this.clientId,
              timestamp: new Date().toISOString()
            });
            this.healthMonitor.startMonitoring(() => {
              console.warn('Connection detected as stale, initiating reconnect:', {
                clientId: this.clientId,
                timestamp: new Date().toISOString()
              });
              this._handleStaleConnection();
            });
          }
          break;

        case 'DISCONNECTED':
        case 'ERROR':
          // Stop health monitoring
          if (this.healthMonitor.isActive()) {
            console.log('Stopping health monitoring:', {
              clientId: this.clientId,
              reason: this.currentState,
              timestamp: new Date().toISOString()
            });
            this.healthMonitor.stopMonitoring();
          }
          break;

        case 'CONNECTING':
          // Stop health monitoring during reconnection
          if (this.healthMonitor.isActive()) {
            console.log('Pausing health monitoring during reconnection:', {
              clientId: this.clientId,
              timestamp: new Date().toISOString()
            });
            this.healthMonitor.stopMonitoring();
          }
          break;
      }

      // Propagate state change to external handler if set
      if (this.stateChangeHandler) {
        this.stateChangeHandler(state);
      }
    } catch (error) {
      console.error('Error handling state change:', {
        error: error.message,
        stack: error.stack,
        state,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Handles stale connection detection
   * @private
   */
  _handleStaleConnection() {
    try {
      this.connectionManager.close();
      this.connect().catch(error => {
        console.error('Failed to reconnect after stale connection:', {
          error: error.message,
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
      });
    } catch (error) {
      console.error('Error handling stale connection:', {
        error: error.message,
        stack: error.stack,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Creates event handlers for the SSE connection
   * @returns {Object} Event handlers object
   * @private
   */
  _createEventHandlers() {
    return {
      update: (event) => this.eventHandler.processEvent(event),
      connected: (event) => {
        console.log('Received connected event:', {
          clientId: this.clientId,
          timestamp: new Date().toISOString()
        });
        this.eventHandler.processEvent(event);
      },
      heartbeat: (event) => {
        this.healthMonitor.recordHeartbeat();
        this.eventHandler.processEvent(event);
      },
      error: async (event) => {
        try {
          const errorData = JSON.parse(event.data);
          
          if (this._isAuthError(errorData)) {
            await this._handleAuthError();
          } else {
            console.error('Server sent error event:', {
              error: errorData,
              clientId: this.clientId,
              timestamp: new Date().toISOString()
            });
            setTimeout(() => this.connect(), 5000);
          }
        } catch (error) {
          console.error('Error handling server error event:', {
            error: error.message,
            stack: error.stack,
            clientId: this.clientId,
            timestamp: new Date().toISOString()
          });
        }
      }
    };
  }

  /**
   * Checks if an error is authentication-related
   * @param {Object} errorData - The error data
   * @returns {boolean} True if auth error
   * @private
   */
  _isAuthError(errorData) {
    return errorData.error === 'authentication_error' ||
      (errorData.message && errorData.message.includes('jwt expired'));
  }

  /**
   * Handles authentication errors
   * @returns {Promise<void>}
   * @private
   */
  async _handleAuthError() {
    console.error('Authentication error from SSE server:', {
      clientId: this.clientId,
      timestamp: new Date().toISOString()
    });
    
    this.connectionManager.close();
    
    const newToken = await this.tokenManager.refreshToken();
    if (newToken) {
      console.log('Auth token refreshed successfully, reconnecting:', {
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      setTimeout(() => this.connect(), 1000);
    } else {
      console.error('Token refresh failed, redirecting to login:', {
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      window.location.href = '/login?expired=1';
    }
  }

  /**
   * Sets the state change handler
   * @param {function} handler - The state change handler
   */
  setStateChangeHandler(handler) {
    this.stateChangeHandler = handler;
    // Notify handler of current state immediately
    if (handler && this.currentState) {
      handler({ connectionState: this.currentState });
    }
  }

  /**
   * Subscribes to an event type
   * @param {string} eventType - The event type to subscribe to
   * @param {function} handler - The event handler
   * @returns {function} Unsubscribe function
   */
  subscribe(eventType, handler) {
    return this.eventHandler.subscribe(eventType, handler);
  }

  /**
   * Unsubscribes from an event type
   * @param {string} eventType - The event type to unsubscribe from
   * @param {function} handler - The event handler to remove
   */
  unsubscribe(eventType, handler) {
    this.eventHandler.unsubscribe(eventType, handler);
  }

  /**
   * Connects to the SSE endpoint
   * @returns {Promise<void>}
   */
  async connect() {
    // Skip connection on login page
    if (window.location.pathname.includes('/login')) {
      console.log('Skipping SSE connection on login page:', {
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      return;
    }

    try {
      // Get and validate token
      const token = this.tokenManager.getAuthToken();
      if (!token) {
        throw new Error('No authentication token available');
      }

      if (this.tokenManager.isTokenExpired(token)) {
        const newToken = await this.tokenManager.refreshToken();
        if (!newToken) {
          throw new Error('Failed to refresh expired token');
        }
      }

      // Create connection with current state
      const connected = await this.connectionManager.connect({
        token: this.tokenManager.getAuthToken(),
        lastEventId: this.eventHandler.getLastEventId(),
        lastSequence: this.eventHandler.getLastSequence(),
        handlers: this._createEventHandlers()
      });

      if (!connected) {
        throw new Error('Failed to establish connection');
      }

      // Register with coordinator
      sseCoordinator.registerClient(this.clientId, this);
    } catch (error) {
      console.error('Failed to establish SSE connection:', {
        error: error.message,
        stack: error.stack,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
      
      if (error.message.includes('token')) {
        window.location.href = '/login?expired=1';
      }
    }
  }

  /**
   * Closes the SSE connection
   */
  close() {
    try {
      console.log('Closing SSE client:', {
        clientId: this.clientId,
        currentState: this.currentState,
        timestamp: new Date().toISOString()
      });

      sseCoordinator.unregisterClient(this.clientId);
      this.healthMonitor.stopMonitoring();
      this.eventHandler.clearHandlers();
      this.connectionManager.close();
      this.currentState = 'DISCONNECTED';
    } catch (error) {
      console.error('Error closing SSE client:', {
        error: error.message,
        stack: error.stack,
        clientId: this.clientId,
        timestamp: new Date().toISOString()
      });
    }
  }

  /**
   * Checks if the client is connected
   * @returns {boolean} True if connected
   */
  isConnected() {
    return this.currentState === 'CONNECTED' && this.connectionManager.isConnected();
  }
}

export default SSEClient;

import React from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';
import { Modal } from '@lib/components/Modal';
import QRCode from '../QRCode';

const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  max-width: 90vw;
  width: 400px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.h2};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg} 0;
`;

const PalletInfo = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
  padding-top: ${({ theme }) => theme.spacing.lg};
  border-top: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${({ theme }) => theme.typography.body2};
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ theme }) => theme.typography.body2};
  font-weight: 500;
`;

const QRCodeModal = ({ isOpen, onClose, pallet }) => {
  if (!pallet) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Header>
          <Title>Pallet QR Code</Title>
          <CloseButton onClick={onClose}>
            <X />
          </CloseButton>
        </Header>
        <QRCodeWrapper>
          <QRCode data={pallet.qrCode} size={300} />
        </QRCodeWrapper>
        <PalletInfo>
          <InfoRow>
            <Label>ID:</Label>
            <Value>{pallet.id}</Value>
          </InfoRow>
          <InfoRow>
            <Label>Type:</Label>
            <Value>{pallet.palletType}</Value>
          </InfoRow>
          <InfoRow>
            <Label>Box Type:</Label>
            <Value>{pallet.boxType}</Value>
          </InfoRow>
          <InfoRow>
            <Label>Tomato Type:</Label>
            <Value>{pallet.tomatoType}</Value>
          </InfoRow>
          <InfoRow>
            <Label>Option:</Label>
            <Value>{pallet.tomatoOption}</Value>
          </InfoRow>
          <InfoRow>
            <Label>Weight:</Label>
            <Value>{pallet.weight ? `${parseFloat(pallet.weight).toFixed(2)}kg` : '-'}</Value>
          </InfoRow>
        </PalletInfo>
      </ModalContent>
    </Modal>
  );
};

export default QRCodeModal;

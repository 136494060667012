import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.default};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const SubTitle = styled.p`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.secondary};
  margin: 4px 0 0 0;
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.error.light};
  color: ${({ theme }) => theme.colors.error.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: 16px;
  
  svg {
    color: ${({ theme }) => theme.colors.error.main};
  }
`;

export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const OrderSection = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
  
  ${({ active, theme }) => active && `
    & > div {
      border-color: ${theme.colors.primary.main};
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  `}
`;

export const OrderCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const OrderTitle = styled.h3`
  ${({ theme }) => theme.typography.subtitle1};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: 0;
  font-weight: 600;
`;

export const OrderMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  
  div {
    display: flex;
    justify-content: space-between;
    ${({ theme }) => theme.typography.body2};
    
    span {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
    
    strong {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const OrderStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  ${({ theme }) => theme.typography.caption};
  font-weight: 500;
  
  ${({ status, theme }) => {
    switch (status) {
      case 'processing':
        return `
          background-color: ${theme.colors.info.light};
          color: ${theme.colors.info.main};
        `;
      case 'ready':
        return `
          background-color: ${theme.colors.success.light};
          color: ${theme.colors.success.main};
        `;
      case 'problem':
        return `
          background-color: ${theme.colors.error.light};
          color: ${theme.colors.error.main};
        `;
      default:
        return `
          background-color: ${theme.colors.background.default};
          color: ${theme.colors.text.secondary};
          border: 1px solid ${theme.colors.border.main};
        `;
    }
  }}
  
  svg {
    width: 14px;
    height: 14px;
  }
`;

// Progress tracking components
export const ProgressContainer = styled.div`
  margin: 16px 0;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

export const ProgressFill = styled.div`
  height: 100%;
  background-color: ${({ progress }) => {
    if (progress < 25) return '#f44336'; // red
    if (progress < 75) return '#ff9800'; // orange
    return '#4caf50'; // green
  }};
  width: ${({ progress }) => `${progress}%`};
  transition: width 0.3s ease-out;
`;

export const ProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #757575;
  margin-top: 4px;
`;

export const TomatoTallyContainer = styled.div`
  margin: 8px 0;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
`;

export const TomatoTallyTitle = styled.h4`
  font-size: 14px;
  margin: 0 0 4px 0;
`;

export const TomatoTallyItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 2px 0;
`;

export const PalletsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  flex: 1;
  overflow-y: auto;
  max-height: 300px;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.default};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.border.main};
    border-radius: 2px;
  }
`;

export const PalletItem = styled.div`
  padding: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
`;

export const PalletName = styled.div`
  ${({ theme }) => theme.typography.subtitle2};
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 600;
  margin-bottom: 8px;
`;

export const PalletInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PalletDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  ${({ theme }) => theme.typography.body2};
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  
  div {
    span {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
    
    strong {
      display: block;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

export const PalletActions = styled.div`
  display: flex;
  gap: 8px;
  
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 12px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ${({ theme }) => theme.typography.button};
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: white;
          
          &:hover {
            background-color: ${theme.colors.primary.dark};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.colors.secondary.main};
          color: white;
          
          &:hover {
            background-color: ${theme.colors.secondary.dark};
          }
        `;
      case 'success':
        return `
          background-color: ${theme.colors.success.main};
          color: white;
          
          &:hover {
            background-color: ${theme.colors.success.dark};
          }
        `;
      case 'danger':
        return `
          background-color: ${theme.colors.error.main};
          color: white;
          
          &:hover {
            background-color: ${theme.colors.error.dark};
          }
        `;
      default:
        return `
          background-color: ${theme.colors.background.paper};
          color: ${theme.colors.text.primary};
          border: 1px solid ${theme.colors.border.main};
          
          &:hover {
            background-color: ${theme.colors.background.hover};
          }
        `;
    }
  }}
  
  svg {
    flex-shrink: 0;
  }
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  svg {
    color: ${({ theme }) => theme.colors.text.disabled};
    margin-bottom: 12px;
  }
  
  p {
    margin: 4px 0;
  }
  
  .loading-spinner {
    width: 32px;
    height: 32px;
    border: 3px solid ${({ theme }) => theme.colors.background.hover};
    border-top: 3px solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

// Modal styles
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 16px;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  width: 100%;
  max-width: 480px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.shadows.lg};
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  ${({ theme }) => theme.typography.h6};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ModalCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const ModalBody = styled.div`
  padding: 16px;
  flex: 1;
  
  p {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.text.secondary};
    ${({ theme }) => theme.typography.body2};
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 16px;
  
  &:not(:first-child) {
    margin-top: 16px;
  }
  
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 500;
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-top: 8px;
  ${({ theme }) => theme.typography.body1};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.light};
  }
`;

export const SaveButton = styled.button`
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const CancelButton = styled.button`
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.text.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

// Zone-grouped pallets components
export const ZoneGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  flex: 1;
  overflow-y: auto;
  max-height: 300px;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background.default};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.border.main};
    border-radius: 2px;
  }
`;

export const ZoneCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #f9f9f9;
  }
`;

export const ZoneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ZoneName = styled.div`
  font-weight: 600;
  color: #0066cc;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PalletCount = styled.div`
  background: rgba(0, 102, 204, 0.1);
  color: #0066cc;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const PalletIdList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const PalletIdItem = styled.div`
  background: #f0f0f0;
  color: #666;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const EmptyPalletsMessage = styled.div`
  padding: 20px; 
  text-align: center; 
  color: #6c757d;
  background: #f8f9fa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Export an empty component for TypeScript since it's not needed in our implementation
export const UpdateBoxCountModal = styled.div``;

// Radio group components for MarkOrderReadyModal
export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
`;

export const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ selected, theme }) => 
    selected ? theme.colors.primary.main : theme.colors.border.main};
  background-color: ${({ selected, theme }) => 
    selected ? `${theme.colors.primary.main}10` : theme.colors.background.paper};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ selected, theme }) => 
      selected ? `${theme.colors.primary.main}15` : theme.colors.background.hover};
  }
  
  &:before {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid ${({ selected, theme }) => 
      selected ? theme.colors.primary.main : theme.colors.border.dark};
    background-color: ${({ selected, theme }) => 
      selected ? theme.colors.primary.main : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    position: relative;
  }
  
  ${({ selected, theme }) => selected && `
    &:before {
      box-shadow: 0 0 0 2px white inset;
    }
  `}
  
  span {
    flex: 1;
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: ${({ selected }) => selected ? '500' : 'normal'};
  }
`;

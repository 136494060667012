import React, { useMemo } from 'react';
import { 
  Container, 
  Header, 
  Title, 
  Content, 
  UserCard, 
  UserInfo, 
  UserName, 
  UserStats, 
  StatItem, 
  ActivityList, 
  ActivityItem, 
  EventType, 
  TimeRange,
  NoData
} from './styles';
import { Users, Activity, Package, Clock } from 'lucide-react';
import { formatTimestamp, getEventTypeLabel, getEventTypeColor } from '../../utils';

export const UserActivity = ({ activity }) => {
  const userSummaries = useMemo(() => {
    if (!Array.isArray(activity)) return [];

    // Group by user
    const userGroups = activity.reduce((acc, item) => {
      const userId = item.user_id;
      if (!acc[userId]) {
        acc[userId] = {
          userId,
          userName: item.user_name,
          totalEvents: 0,
          totalPallets: 0,
          firstEvent: null,
          lastEvent: null,
          eventTypes: {}
        };
      }

      acc[userId].totalEvents += item.event_count;
      acc[userId].totalPallets += item.pallet_count;
      
      // Track first and last events
      const eventTime = new Date(item.first_event);
      if (!acc[userId].firstEvent || eventTime < new Date(acc[userId].firstEvent)) {
        acc[userId].firstEvent = item.first_event;
      }
      if (!acc[userId].lastEvent || eventTime > new Date(acc[userId].lastEvent)) {
        acc[userId].lastEvent = item.last_event;
      }

      // Track event types
      if (!acc[userId].eventTypes[item.event_type]) {
        acc[userId].eventTypes[item.event_type] = 0;
      }
      acc[userId].eventTypes[item.event_type] += item.event_count;

      return acc;
    }, {});

    // Convert to array and sort by total events
    return Object.values(userGroups).sort((a, b) => b.totalEvents - a.totalEvents);
  }, [activity]);

  if (!activity || activity.length === 0) {
    return (
      <NoData>
        <Users size={48} />
        <p>No user activity data available for the selected period.</p>
      </NoData>
    );
  }

  return (
    <Container>
      <Header>
        <Title>
          <Users size={24} />
          User Activity
        </Title>
      </Header>

      <Content>
        {userSummaries.map(user => (
          <UserCard key={user.userId}>
            <UserInfo>
              <UserName>{user.userName || `User ${user.userId}`}</UserName>
              <UserStats>
                <StatItem>
                  <Activity size={16} />
                  {user.totalEvents.toLocaleString()} events
                </StatItem>
                <StatItem>
                  <Package size={16} />
                  {user.totalPallets.toLocaleString()} pallets
                </StatItem>
                <StatItem>
                  <Clock size={16} />
                  {formatTimestamp(user.lastEvent).split(',')[0]}
                </StatItem>
              </UserStats>
            </UserInfo>

            <ActivityList>
              {Object.entries(user.eventTypes)
                .sort(([, a], [, b]) => b - a) // Sort by count
                .map(([type, count]) => {
                  const percentage = (count / user.totalEvents) * 100;
                  return (
                    <ActivityItem key={type}>
                      <EventType color={getEventTypeColor(type)}>
                        {getEventTypeLabel(type)}
                        <span>{count.toLocaleString()}</span>
                      </EventType>
                      <div className="progress-bar">
                        <div 
                          className="progress" 
                          style={{ 
                            width: `${percentage}%`,
                            backgroundColor: getEventTypeColor(type)
                          }} 
                        />
                      </div>
                    </ActivityItem>
                  );
                })}
            </ActivityList>

            <TimeRange>
              Active from {formatTimestamp(user.firstEvent)} to {formatTimestamp(user.lastEvent)}
            </TimeRange>
          </UserCard>
        ))}
      </Content>
    </Container>
  );
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { api } from '@lib/api';
import QRScanner from '../QRScanner';
import RouteSelectionModal from '@features/operator/components/RouteSelectionModal';

const ScanContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.default};
`;

const ScanHeader = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  h2 {
    ${({ theme }) => theme.typography.h4};
    color: ${({ theme }) => theme.colors.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
  }
  
  p {
    ${({ theme }) => theme.typography.body1};
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const Button = styled.button`
  width: 100%;
  max-width: 300px;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: none;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.common.white};
  ${({ theme }) => theme.typography.button};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  
  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const FormSection = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const FormHeader = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  
  h3 {
    ${({ theme }) => theme.typography.h5};
    color: ${({ theme }) => theme.colors.text.primary};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  ${({ theme }) => theme.typography.body1};
  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.text.primary};
  transition: ${({ theme }) => theme.transitions.default};
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.light};
  }
  
  &::placeholder {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

const OrDivider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.md} 0;
  
  &:before, &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  }
  
  span {
    padding: 0 ${({ theme }) => theme.spacing.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    ${({ theme }) => theme.typography.body2};
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
  ${({ theme }) => theme.typography.body2};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.sm};
`;

const BackButton = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary.main};
  border: none;
  padding: ${({ theme }) => theme.spacing.sm} 0;
  ${({ theme }) => theme.typography.body2};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.md};
  
  &:hover {
    text-decoration: underline;
  }
`;

const ScanTab = ({
  onShipPallet,
  onDeletePallet,
  onAssignOrder,
  onReturnPallet,
  onSplitPallet,
  onPutIntoProduction,
  locationId,
  consoleType
}) => {
  const navigate = useNavigate();
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [scannedPalletData, setScannedPalletData] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [manualPalletId, setManualPalletId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showManualEntry, setShowManualEntry] = useState(false);

  const handleScan = (palletData) => {
    setScannedPalletData(palletData);
    
    // For Kleinpak console, navigate directly to the Kleinpak pallet details page
    // Get current URL to use as referrer
    const currentUrl = window.location.href;
    
    if (consoleType === 'kleinpak') {
      // Pass complete pallet data and current URL via navigation state
      navigate(`/mobile/operator/kleinpak-pallet/${palletData.id}`, { 
        state: { 
          palletDetails: palletData,
          referrer: currentUrl 
        }
      });
    } 
    // For Sorting and Logistics consoles, show the route selection modal
    else if (consoleType === 'sorting' || consoleType === 'logistics') {
      setIsRouteModalOpen(true);
    }
    // For any other console type, use the default behavior
    else {
      navigate(`/mobile/operator/scan/${palletData.id}`, { 
        state: { 
          palletDetails: palletData,
          referrer: currentUrl 
        }
      });
    }
  };

  const handleUpdatePallet = async (updates) => {
    if (!scannedPalletData) return;
    
    try {
      // Update local state with the new route while preserving all other data
      setScannedPalletData(prevData => ({
        ...prevData,
        ...updates
      }));
      
      // Send update to server
      await api.patch(`/api/pallets/${scannedPalletData.id}`, {
        ...updates,
        locationId
      });
      
      return {
        ...scannedPalletData,
        ...updates
      };
    } catch (error) {
      console.error('Error updating pallet:', error);
      throw error;
    }
  };

  const handleRouteSelection = (routeId) => {
    if (!scannedPalletData) return;
    
    // Get current URL to use as referrer
    const currentUrl = window.location.href;
    
    // Update the scannedPalletData with the selected route
    const updatedPalletData = {
      ...scannedPalletData,
      route: routeId
    };
    
    if (routeId === 'kleinpak') {
      // Navigate to the Kleinpak pallet details page with complete pallet data
      navigate(`/mobile/operator/kleinpak-pallet/${scannedPalletData.id}`, {
        state: { 
          palletDetails: updatedPalletData,
          referrer: currentUrl
        }
      });
    } else {
      // Navigate to the Standard pallet details page with complete pallet data
      navigate(`/mobile/operator/standard-pallet/${scannedPalletData.id}`, {
        state: { 
          palletDetails: updatedPalletData,
          referrer: currentUrl
        }
      });
    }
  };

  const handleStartScanning = () => {
    setIsScanning(true);
    setError(null);
  };

  const handleManualEntry = async (e) => {
    e.preventDefault();
    
    if (!manualPalletId.trim()) {
      setError('Please enter a valid pallet ID');
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Fetch the pallet data by ID using the scan endpoint
      const response = await api.get(`/api/pallets/scan/${manualPalletId.trim()}`);
      
      if (response.data && response.data.data) {
        // Process the pallet data
        const palletData = response.data.data;
        
        // Add to scan history for consistency with QR scanning
        try {
          const scanHistoryService = (await import('../../services/scanHistoryService')).scanHistoryService;
          await scanHistoryService.addScan(palletData);
        } catch (historyError) {
          console.error('Error adding to scan history:', historyError);
          // Continue even if history tracking fails
        }
        
        // Use the same handler as for scanned pallets
        handleScan(palletData);
      } else {
        setError('Pallet not found');
      }
    } catch (error) {
      console.error('Error fetching pallet by ID:', error);
      setError(error.response?.data?.message || 'Pallet not found. Please check the ID and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualInputRequest = () => {
    setIsScanning(false);
    setShowManualEntry(true);
  };

  const handleBackToScanOptions = () => {
    setShowManualEntry(false);
    setError(null);
    setManualPalletId('');
  };

  return (
    <ScanContainer>
      {isScanning ? (
        <QRScanner
          onScan={handleScan}
          onManualInputRequest={handleManualInputRequest}
        />
      ) : showManualEntry ? (
        <>
          <FormSection>
            <FormHeader>
              <h3>Enter Pallet ID Manually</h3>
            </FormHeader>
            <Form onSubmit={handleManualEntry}>
              <Input
                type="text"
                placeholder="Enter pallet ID"
                value={manualPalletId}
                onChange={(e) => setManualPalletId(e.target.value)}
                disabled={isLoading}
                autoFocus
              />
              <Button type="submit" disabled={isLoading || !manualPalletId.trim()}>
                {isLoading ? 'Loading...' : 'Submit'}
              </Button>
            </Form>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <BackButton onClick={handleBackToScanOptions}>
              ← Back to scan options
            </BackButton>
          </FormSection>
        </>
      ) : (
        <>
          <ScanHeader>
            <h2>Scan Pallet</h2>
            <p>Click the button below to start scanning a pallet QR code.</p>
          </ScanHeader>
          <Button onClick={handleStartScanning} disabled={isLoading}>
            Start Scanning
          </Button>
          
          <OrDivider>
            <span>OR</span>
          </OrDivider>
          
          <FormSection>
            <FormHeader>
              <h3>Enter Pallet ID Manually</h3>
            </FormHeader>
            <Form onSubmit={handleManualEntry}>
              <Input
                type="text"
                placeholder="Enter pallet ID"
                value={manualPalletId}
                onChange={(e) => setManualPalletId(e.target.value)}
                disabled={isLoading}
              />
              <Button type="submit" disabled={isLoading || !manualPalletId.trim()}>
                {isLoading ? 'Loading...' : 'Submit'}
              </Button>
            </Form>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </FormSection>
        </>
      )}

      <RouteSelectionModal
        isOpen={isRouteModalOpen}
        onClose={() => setIsRouteModalOpen(false)}
        onSelectRoute={handleRouteSelection}
        onUpdatePallet={handleUpdatePallet}
      />
    </ScanContainer>
  );
};

export default ScanTab;

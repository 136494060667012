import React from 'react';
import styled from 'styled-components';
import { Activity, AlertCircle, AlertTriangle, Cpu } from 'lucide-react';
import ProcessingOrderCard from './ProcessingOrderCard';

// Styled components for the console
const ConsoleContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.darker};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.md};
  padding: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.dark};
`;

const ConsoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const ConsoleTitle = styled.h3`
  ${({ theme }) => theme.typography.h4};
  color: ${({ theme }) => theme.colors.common.white};
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  svg {
    color: ${({ theme }) => theme.colors.primary.main};
    animation: pulse 2s infinite;
    
    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

const ConsoleStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme, active }) => 
    active ? theme.colors.primary.alpha10 : theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme, active }) => 
    active ? theme.colors.primary.main : theme.colors.text.secondary};
  
  span {
    font-weight: 500;
  }
`;

const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const EmptyConsole = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: ${({ theme }) => theme.colors.background.darker};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px dashed ${({ theme }) => theme.colors.border.light};
  
  svg {
    margin-right: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

/**
 * ProcessingOrdersConsole Component
 * 
 * A dedicated console that displays orders currently being processed.
 * This provides a real-time view of active orders at the top of the Orders tab.
 */
const ProcessingOrdersConsole = ({ 
  orders, 
  onUpdatePriority,
  onShipOrder,
  onEditOrder,
  onDeleteOrder 
}) => {
  // Filter out orders that are currently being processed
  const processingOrders = orders.filter(order => 
    order.is_processing === 1 || order.status === 'processing'
  );
  
  return (
    <ConsoleContainer>
      <ConsoleHeader>
        <ConsoleTitle>
          <Cpu size={20} />
          Active Processing Console
        </ConsoleTitle>
        
        <ConsoleStatus active={processingOrders.length > 0}>
          {processingOrders.length > 0 ? (
            <>
              <Activity size={16} />
              <span>{processingOrders.length} order{processingOrders.length !== 1 ? 's' : ''} active</span>
            </>
          ) : (
            <>
              <AlertCircle size={16} />
              <span>No active orders</span>
            </>
          )}
        </ConsoleStatus>
      </ConsoleHeader>
      
      {processingOrders.length > 0 ? (
        <OrdersGrid>
          {processingOrders.map(order => (
            <ProcessingOrderCard 
              key={order.id} 
              order={order}
              onUpdatePriority={onUpdatePriority}
              onShip={onShipOrder}
              onEdit={onEditOrder}
              onDelete={onDeleteOrder}
            />
          ))}
        </OrdersGrid>
      ) : (
        <EmptyConsole>
          <AlertTriangle size={20} />
          No orders are currently being processed
        </EmptyConsole>
      )}
    </ConsoleContainer>
  );
};

export default ProcessingOrdersConsole;

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import storageAssignmentService from '../services/storageAssignmentService';
import { zoneUpdateService } from '../services/zoneUpdateService';
import { UserContext } from '@lib/contexts/UserContext';
import useStorageAssignment from '@lib/hooks/useStorageAssignment';
// ZoneGridView is no longer needed for position selection
import { calculateZoneCapacity, ZONE_MAP } from '../constants/zoneConfig.v2';
import { getZoneGradient } from '../constants/colors/zoneColors';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: 50%;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

const ModalContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ModalFooter = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding-top: ${({ theme }) => theme.spacing.md};
  border-top: 1px solid ${({ theme }) => theme.colors.border.light};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Button = styled.button`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  color: ${({ theme }) => theme.colors.text.secondary};
  
  &:hover {
    background: ${({ theme }) => theme.colors.background.hover};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ConfirmButton = styled(Button)`
  background: ${({ theme }) => theme.colors.primary.main};
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary[600]};
  }
  
  &:disabled {
    background: ${({ theme }) => theme.colors.gray[300]};
    border-color: ${({ theme }) => theme.colors.gray[300]};
    color: ${({ theme }) => theme.colors.gray[500]};
    cursor: not-allowed;
  }
`;

const PalletInfo = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.accent};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  
  &:last-child {
    margin-bottom: 0;
  }
  
  .label {
    font-weight: 600;
    min-width: 120px;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
  
  .value {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const NoZonesMessage = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.warning.alpha};
  border: 1px solid ${({ theme }) => theme.colors.warning.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.warning.dark};
  text-align: center;
  margin: ${({ theme }) => theme.spacing.lg} 0;
`;

const LoadingMessage = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.accent};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
  margin: ${({ theme }) => theme.spacing.lg} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

const LoadingSpinner = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.gray[300]};
  border-top: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ZoneSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const ZoneSelectionTitle = styled.h4`
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const ZoneGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
`;

const ZoneCard = styled.div`
  background: ${({ theme, type, occupancy }) => getZoneGradient(theme, type, occupancy)};
  border: 2px solid ${({ theme, $isSelected }) => 
    $isSelected 
      ? theme.colors.primary.main
      : theme.colors.border.light
  };
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
  
  &:focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.shadows.focus};
  }
`;

const ZoneName = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const ZoneDetails = styled.div`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const OccupancyBar = styled.div`
  height: 6px;
  width: 100%;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  margin-top: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;
`;

const OccupancyFill = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background: ${({ theme, percentage }) => {
    if (percentage > 90) return theme.colors.error.main;
    if (percentage > 70) return theme.colors.warning.main;
    return theme.colors.success.main;
  }};
  transition: width 0.3s ease;
`;

const ZoneTypeTag = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xs};
  right: ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.pill};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  font-size: 0.7rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.secondary};
  opacity: 0.8;
`;

const PreferredZoneMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.info.alpha};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme }) => theme.colors.info.dark};
  font-size: 0.9rem;
`;

const ActiveRowMessage = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.success.alpha};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme }) => theme.colors.success.dark};
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  
  .row-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .row-completion {
      font-weight: 600;
    }
  }
  
  .progress-bar {
    height: 8px;
    width: 100%;
    background: ${({ theme }) => theme.colors.background.paper};
    border-radius: ${({ theme }) => theme.borderRadius.pill};
    overflow: hidden;
    margin-top: ${({ theme }) => theme.spacing.xs};
    
    .progress-fill {
      height: 100%;
      background: ${({ theme }) => theme.colors.success.main};
      transition: width 0.3s ease;
    }
  }
`;

// StorageAssignmentModal component
const StorageAssignmentModal = ({ isOpen, onClose, palletData, onAssign, locationId = null }) => {
  // Get user context for location ID if not provided
  const { user } = useContext(UserContext);
  const currentLocationId = locationId || (user?.currentLocation?.id || 1);
  const [suitableZones, setSuitableZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [assignmentComplete, setAssignmentComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // Use the storage assignment hook for the new zone-only assignment
  const { 
    assignPalletToZone,
    isAssigning: isAssigningToZone
  } = useStorageAssignment(
    // Success callback
    (result) => {
      console.log('Successfully assigned pallet to zone:', result);
      setAssignmentComplete(true);
      
      // Call the onAssign callback
      if (onAssign) {
        onAssign({
          zoneId: selectedZone.id,
          palletData
        });
      }
      
      // Close the modal after a delay
      setTimeout(() => {
        onClose();
      }, 3000);
    }, 
    // Error callback
    (error) => {
      console.error('Error assigning pallet to zone:', error);
      setErrorMessage(`Failed to assign pallet: ${error.message || 'Unknown error'}`);
      setIsLoading(false);
    }
  );
  
  useEffect(() => {
    let isMounted = true;
    
    const fetchSuitableZones = async () => {
      if (!isOpen || !palletData?.type) return;
      
      setIsLoading(true);
      setErrorMessage('');
      
      try {
        // Get suitable zones for the tomato type
        const zones = await storageAssignmentService.getSuitableZones(palletData.type, currentLocationId);
        
        if (!isMounted) return;
        
        setSuitableZones(zones);
        
        // Clear selected zone when opening modal
        setSelectedZone(null);
      } catch (error) {
        if (!isMounted) return;
        setErrorMessage('Failed to load zones: ' + error.message);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    fetchSuitableZones();
    
    return () => {
      isMounted = false;
      setSuitableZones([]);
      setSelectedZone(null);
      setAssignmentComplete(false);
      setIsLoading(false);
      setErrorMessage('');
    };
  }, [isOpen, palletData, currentLocationId]);
  
  const handleZoneSelect = async (zone) => {
    setSelectedZone(zone);
    
    // Fetch occupancy data for the selected zone with force refresh
    try {
      // Force refresh this data to ensure it's up-to-date
      const occupancyData = await storageAssignmentService.getZoneOccupancy(
        zone.id, 
        currentLocationId, 
        { forceRefresh: true, timestamp: Date.now() }
      );
    
      // Update the zone's occupancy data
      if (occupancyData) {
        const updatedZones = [...suitableZones];
        const zoneIndex = updatedZones.findIndex(z => z.id === zone.id);
        
        if (zoneIndex !== -1) {
          // Create a new object reference to ensure React detects changes
          updatedZones[zoneIndex] = {
            ...updatedZones[zoneIndex],
            occupancy: { ...occupancyData },
            occupiedCount: Object.keys(occupancyData).length,
            occupancyPercentage: Math.round(
              (Object.keys(occupancyData).length / calculateZoneCapacity(zone)) * 100
            )
          };
          setSuitableZones(updatedZones);
        }
      }
    } catch (error) {
      console.error(`Error fetching occupancy for zone ${zone.id}:`, error);
    }
  };
  
  const handleAssignToZone = async () => {
    if (!selectedZone) return;
    
    setIsLoading(true);
    
    try {
      // Use the new zone-only assignment method
      await assignPalletToZone(
        selectedZone.id,
        palletData,
        currentLocationId
      );
      
      // Emit zone assignment event for UI updates
      zoneUpdateService.eventEmitter.emit(
        zoneUpdateService.ZONE_UPDATE_EVENTS.ZONE_ASSIGNMENT, 
        {
          palletId: palletData.id,
          zoneId: selectedZone.id,
          success: true
        }
      );
      
      // Force refresh the state
      try {
        const storageStateManager = await import('@lib/services/storage/StorageStateManager').then(m => m.default);
        await storageStateManager.refreshZone(selectedZone.id, true);
        await storageStateManager.forceRefreshAllZones();
      } catch (refreshError) {
        console.error('Failed to refresh zone data after assignment:', refreshError);
      }
    } catch (error) {
      setErrorMessage('Failed to assign pallet: ' + error.message);
      setIsLoading(false);
    }
  };
  
  // Don't render if not open
  if (!isOpen) return null;
  
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            {assignmentComplete 
              ? 'Pallet Successfully Assigned'
              : 'Assign Pallet to Storage Zone'
            }
          </ModalTitle>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        
        <ModalContent>
          <PalletInfo>
            <InfoRow>
              <span className="label">Pallet ID:</span>
              <span className="value">{palletData?.id || 'N/A'}</span>
            </InfoRow>
            <InfoRow>
              <span className="label">Type:</span>
              <span className="value">{palletData?.type || 'N/A'}</span>
            </InfoRow>
            {palletData?.option && (
              <InfoRow>
                <span className="label">Option:</span>
                <span className="value">{palletData.option}</span>
              </InfoRow>
            )}
            <InfoRow>
              <span className="label">Status:</span>
              <span className="value">{palletData?.status || 'N/A'}</span>
            </InfoRow>
            {palletData?.customer && (
              <InfoRow>
                <span className="label">Customer:</span>
                <span className="value">{palletData.customer}</span>
              </InfoRow>
            )}
          </PalletInfo>
          
          {isLoading || isAssigningToZone ? (
            <LoadingMessage>
              <LoadingSpinner />
              <div>Loading storage zones or processing assignment...</div>
            </LoadingMessage>
          ) : errorMessage ? (
            <NoZonesMessage>
              {errorMessage}
            </NoZonesMessage>
          ) : assignmentComplete ? (
            <div>
              <PreferredZoneMessage>
                Pallet has been successfully assigned to Zone {selectedZone.name}.
                Please check the kleinpak dashboard for pallet location within the zone.
              </PreferredZoneMessage>
            </div>
          ) : (
            <ZoneSelectionContainer>
              <ZoneSelectionTitle>Select Storage Zone</ZoneSelectionTitle>
              
              {errorMessage ? (
                <NoZonesMessage>
                  {errorMessage}
                </NoZonesMessage>
              ) : suitableZones.length > 0 ? (
                <ZoneGrid>
                  {suitableZones.map(zone => {
                    const capacity = calculateZoneCapacity(zone);
                    const occupiedCount = zone.occupiedCount || 0;
                    const occupancyPercentage = Math.round((occupiedCount / capacity) * 100);
                    
                    return (
                      <ZoneCard 
                        key={zone.id}
                        type={zone.type}
                        occupancy={occupancyPercentage}
                        $isSelected={selectedZone?.id === zone.id}
                        onClick={() => handleZoneSelect(zone)}
                        tabIndex={0}
                        role="button"
                        aria-pressed={selectedZone?.id === zone.id}
                      >
                        <ZoneName>{zone.name}</ZoneName>
                        <ZoneDetails>
                          <div>{zone.dimensions.rows} rows × {zone.dimensions.cols} columns</div>
                          <div>{capacity - occupiedCount} positions available</div>
                        </ZoneDetails>
                        <OccupancyBar>
                          <OccupancyFill percentage={occupancyPercentage} />
                        </OccupancyBar>
                        <ZoneTypeTag>{zone.section}</ZoneTypeTag>
                      </ZoneCard>
                    );
                  })}
                </ZoneGrid>
              ) : (
                <NoZonesMessage>
                  No suitable storage zones found for this tomato type. Please contact a supervisor.
                </NoZonesMessage>
              )}
            </ZoneSelectionContainer>
          )}
        </ModalContent>
        
        <ModalFooter>
          {!assignmentComplete && (
            <>
              <CancelButton onClick={onClose}>Cancel</CancelButton>
              <ConfirmButton 
                onClick={handleAssignToZone}
                disabled={!selectedZone}
              >
                {selectedZone ? 'Assign to Zone' : 'Select a Zone'}
              </ConfirmButton>
            </>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default StorageAssignmentModal;

import { api } from '../../../lib/api';
import { getWeekStart, getDayIndex, formatDateToYYYYMMDD } from '@lib/utils/dateUtils';

export const fetchWeeklyTallyData = async (locationId, startDate, tomatoType) => {
  try {
    const response = await api.get(`/api/weekly-tally/location/${locationId}/data`, {
      params: { startDate, tomatoType }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching weekly tally data:', error);
    throw error;
  }
};

export const saveWeeklyTallyCard = async (locationId, weekStartDate, tomatoType, tallyData) => {
  try {
    const response = await api.post(`/api/weekly-tally/location/${locationId}/save`, {
      weekStartDate,
      tomatoType,
      tallyData
    });
    return response.data.data;
  } catch (error) {
    console.error('Error saving weekly tally card:', error);
    throw error;
  }
};

export const fetchHistoricalTallyCards = async (locationId, limit = 10) => {
  try {
    const response = await api.get(`/api/weekly-tally/location/${locationId}/history`, {
      params: { limit }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching historical tally cards:', error);
    throw error;
  }
};

export const fetchTallyCardById = async (cardId) => {
  try {
    const response = await api.get(`/api/weekly-tally/card/${cardId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching tally card:', error);
    throw error;
  }
};

export const exportTallyToExcel = async (locationId, startDate, tomatoType) => {
  try {
    const response = await api.get(
      `/api/weekly-tally/location/${locationId}/export`,
      {
        params: { startDate, tomatoType },
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      }
    );

    // Create and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `tally_${tomatoType}_${startDate}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    console.error('Error exporting tally to Excel:', error);
    throw error;
  }
};

// Helper function to get the start of the current week
export const getCurrentWeekStart = () => {
  const monday = getWeekStart(new Date());
  return monday ? formatDateToYYYYMMDD(monday) : null;
};

// Helper function to format tally data for API
export const formatTallyData = (data) => {
  return {
    sections: data
  };
};

// Helper function to parse API response into component format
export const parseTallyData = (apiData) => {
  const result = {};
  
  // Debug tally data
  console.log('WeeklyTally: Parsing API data with length:', apiData?.length || 0);
  
  if (!Array.isArray(apiData) || apiData.length === 0) {
    console.warn('WeeklyTally: No data to parse, returning empty result');
    return result;
  }
  
  apiData.forEach(record => {
    // Use our safe date utility to get the day index
    const dayIndex = getDayIndex(record.date);
    
    // Use tomato option if available, otherwise sorting grade
    const sortKey = record.tomatoOption || record.sorting_grade || record.tomatoType;
    
    // Skip if no sorting key
    if (!sortKey) {
      console.warn('WeeklyTally: Record missing sort key:', record);
      return;
    }
    
    console.log(`WeeklyTally: Processing record for day ${dayIndex}, type ${record.tomatoType}, option ${record.tomatoOption}, grade ${record.sorting_grade}`);
    
    if (!result[sortKey]) {
      result[sortKey] = {
        licht: Array(6).fill(0),
        midden: Array(6).fill(0),
        rood: Array(6).fill(0)
      };
    }
    
    // Determine which row (licht, midden, rood) based on sorting grade and weight
    const rowType = determineRowType(record);
    
    // Only process valid day indices (Monday-Saturday)
    if (dayIndex >= 0 && dayIndex < 6) {
      // Add to existing count if there are multiple entries for the same combination
      // The count may now be a decimal value for partial pallets
      result[sortKey][rowType][dayIndex] += (parseFloat(record.count) || 0);
    }
  });

  return result;
};

// Helper function to determine row type based on the pallet's characteristics
const determineRowType = (record) => {
  if (!record) return 'midden'; // Default to middle if no record

  // Extract relevant characteristics from the record
  const { sorting_grade = '', weight = 0 } = record;
  
  // Standardize the grade to lowercase for consistent matching
  const grade = String(sorting_grade || '').toLowerCase();
  
  // Debug the grade values we're getting
  console.log('WeeklyTally: Determining row type for grade:', {
    original_grade: sorting_grade,
    normalized_grade: grade
  });
  
  // Direct mappings from database values to display rows
  if (grade === 'light' || grade.includes('licht')) {
    return 'licht';
  } else if (grade === 'red' || grade.includes('rood')) {
    return 'rood';
  } else if (grade === 'middle' || grade.includes('midden')) {
    return 'midden';
  }
  
  // Additional heuristics for sorting if no direct matches
  if (weight < 100) {
    return 'licht';
  }
  
  // Default to midden if we can't determine
  return 'midden';
};

// Blacklist management functions
export const fetchBlacklist = async () => {
  try {
    const response = await api.get('/api/weekly-tally/blacklist');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blacklist:', error);
    throw error;
  }
};

export const addToBlacklist = async (boxType, palletType) => {
  try {
    const response = await api.post('/api/weekly-tally/blacklist', {
      boxType,
      palletType
    });
    return response.data.data;
  } catch (error) {
    console.error('Error adding to blacklist:', error);
    throw error;
  }
};

export const removeFromBlacklist = async (boxType, palletType) => {
  try {
    const response = await api.delete('/api/weekly-tally/blacklist', {
      data: { boxType, palletType }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error removing from blacklist:', error);
    throw error;
  }
};

// Daily snapshot functions
export const getDailySnapshots = async (locationId, startDate, endDate, tomatoType) => {
  try {
    const response = await api.get(`/api/weekly-tally/location/${locationId}/snapshots`, {
      params: { startDate, endDate, tomatoType }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching daily snapshots:', error);
    throw error;
  }
};

export const saveDailySnapshot = async (locationId, tomatoType) => {
  try {
    const response = await api.post(`/api/weekly-tally/location/${locationId}/snapshot`, {
      tomatoType
    });
    return response.data.data;
  } catch (error) {
    console.error('Error saving daily snapshot:', error);
    throw error;
  }
};

import React from 'react';
import styled from 'styled-components';

// Function to determine color based on progress percentage
const getProgressColor = (percentage) => {
  if (percentage <= 30) return '#f44336'; // red for early stages
  if (percentage <= 70) return '#ff9800'; // orange for middle stages
  return '#4caf50'; // green for near completion
};

const ProgressContainer = styled.div`
  width: ${({ width }) => width || '100%'};
  margin: ${({ theme }) => theme.spacing.xs} 0;
`;

const ProgressBarTrack = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.background.lighter};
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ percentage }) => getProgressColor(percentage)};
  transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1), 
              background-color 0.3s ease;
  position: relative;
  
  /* Processing animation for active orders */
  ${({ isProcessing }) => isProcessing && `
    background-image: linear-gradient(45deg, 
      rgba(255,255,255,0.15) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(255,255,255,0.15) 50%, 
      rgba(255,255,255,0.15) 75%, 
      transparent 75%, 
      transparent);
    background-size: 20px 20px;
    animation: progress-bar-stripes 1s linear infinite;
    
    @keyframes progress-bar-stripes {
      from { background-position: 20px 0; }
      to { background-position: 0 0; }
    }
  `}
  
  /* Pulse effect when hovering over the container */
  ${ProgressContainer}:hover & {
    filter: brightness(1.1);
  }
`;

const ProgressMetrics = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-top: 4px;
`;

/**
 * EnhancedProgressBar Component
 * 
 * A visually improved progress bar with animations and color transitions
 * based on completion percentage.
 * 
 * @param {Object} props - Component props
 * @param {number} props.percentage - The completion percentage (0-100)
 * @param {string} props.width - CSS width value (e.g., '75%', '300px')
 * @param {boolean} props.isProcessing - Whether to show processing animation
 * @param {string} props.leftLabel - Text to show on the left side
 * @param {string} props.rightLabel - Text to show on the right side
 */
const EnhancedProgressBar = ({ 
  percentage = 0, 
  width = '75%', 
  isProcessing = false,
  leftLabel,
  rightLabel
}) => {
  // Ensure percentage is between 0 and 100
  const safePercentage = Math.max(0, Math.min(100, percentage));
  
  return (
    <ProgressContainer style={{ width }}>
      <ProgressBarTrack>
        <ProgressBarFill 
          percentage={safePercentage}
          isProcessing={isProcessing}
        />
      </ProgressBarTrack>
      
      {(leftLabel || rightLabel) && (
        <ProgressMetrics>
          {leftLabel && <span>{leftLabel}</span>}
          {rightLabel && <span>{rightLabel}</span>}
        </ProgressMetrics>
      )}
    </ProgressContainer>
  );
};

export default EnhancedProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VisuallyHidden } from '../../utils/accessibility';

const SortIcon = styled.span`
  display: inline-flex;
  margin-left: ${({ theme }) => theme.spacing.xs};
  opacity: ${props => props.active ? 1 : 0.3};
  transition: ${({ theme }) => theme.transitions.default};
  transform: ${props => props.direction === 'desc' ? 'rotate(180deg)' : 'none'};
  
  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme, active }) => active ? theme.colors.primary.main : theme.colors.text.secondary};
  }
`;

const StyledTableHeader = styled.th`
  padding: ${({ theme }) => theme.spacing.md};
  text-align: left;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: 2px solid ${({ theme }) => theme.colors.border.light};
  background-color: ${({ theme }) => theme.colors.background.paper};
  cursor: ${props => props.sortable ? 'pointer' : 'default'};
  user-select: none;
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;
  height: 3.5rem;
  vertical-align: middle;

  &.checkbox-header {
    width: 48px;
    padding: 0;
    text-align: center;

    /* Remove default table header styles when used for checkbox */
    &::before {
      display: none;
    }
  }

  /* Add subtle highlight for sortable columns */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.primary.alpha10};
    opacity: 0;
    transition: ${({ theme }) => theme.transitions.default};
  }

  &:hover {
    ${props => props.sortable && `
      color: ${props.theme.colors.primary.main};
      &::before {
        opacity: 1;
      }
    `}
  }

  /* Active sort state */
  ${props => props.active && `
    color: ${props.theme.colors.primary.main};
    border-bottom-color: ${props.theme.colors.primary.main};
  `}

  /* Focus styles for keyboard navigation */
  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary.main};
    outline-offset: -2px;
  }
`;

const TableHeader = ({ 
  children, 
  sortable,
  sortDirection,
  active,
  onClick,
  className,
  columnId,
  scope = 'col',
  ...props
}) => {
  // Handle keyboard events for accessibility
  const handleKeyDown = (e) => {
    if (!sortable) return;
    
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick && onClick(e);
    }
  };

  // Get the appropriate ARIA attributes for sorting
  const getSortAttributes = () => {
    if (!sortable) return {};
    
    return {
      'aria-sort': active 
        ? sortDirection === 'asc' 
          ? 'ascending' 
          : 'descending'
        : 'none',
      role: 'columnheader',
      'aria-label': `${children}${active 
        ? sortDirection === 'asc' 
          ? ', sorted ascending' 
          : ', sorted descending' 
        : ', select to sort'}`
    };
  };

  return (
    <StyledTableHeader
      sortable={sortable}
      active={active}
      onClick={sortable ? onClick : undefined}
      onKeyDown={handleKeyDown}
      className={className}
      scope={scope}
      tabIndex={sortable ? 0 : -1}
      data-column-id={columnId}
      {...getSortAttributes()}
      {...props}
    >
      {children}
      {sortable && (
        <>
          <SortIcon 
            active={active} 
            direction={sortDirection}
            aria-hidden="true"
          >
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M8 12L12 8L16 12" />
            </svg>
          </SortIcon>
          <VisuallyHidden>
            {active 
              ? sortDirection === 'asc' 
                ? 'sorted ascending' 
                : 'sorted descending' 
              : 'select to sort'}
          </VisuallyHidden>
        </>
      )}
    </StyledTableHeader>
  );
};

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  columnId: PropTypes.string,
  scope: PropTypes.oneOf(['col', 'row', 'colgroup', 'rowgroup']),
};

TableHeader.defaultProps = {
  sortable: false,
  active: false,
  sortDirection: 'asc',
  scope: 'col'
};

export default TableHeader;

// Also export the styled component for flexibility
export { StyledTableHeader };

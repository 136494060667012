import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext, useLocation } from 'react-router-dom';
import { FinishMenu } from './components/FinishMenu';
import { Selection } from './components/Selection';
import { Settings } from './components/Settings';
import { DownloadPage } from '@features/shared/components/DownloadPage';
import { useProcessing } from '@lib/contexts';
import { useToast } from '@lib/contexts';
import { usePwaStatus } from '@lib/hooks/usePwaStatus';
import ScanTab from '@features/shared/components/ScanTab';
import { SORTING_GRADES } from '@features/shared/constants/processStages';
import {
  loadSavedSelections,
  saveSelections,
  loadRecentlyFinished,
  saveRecentlyFinished,
  handleShipPallet,
  handleDeletePallet,
  handleAssignOrder,
  handleReturnPallet,
  handleSplitPallet
} from '@features/shared/services/palletService';
import { fetchFilterRanges } from '@features/operator/services/operatorService';
import MobileOrderAssignmentModal from '../../MobileOrderAssignmentModal';
import { api } from '@lib/api';
import {
  Container,
  ScrollableMenu,
  ContentArea,
  MenuButton,
  ModalOverlay,
  ModalContent,
  LoadingContainer,
  LoadingSpinner,
  ErrorContainer,
  RetryButton
} from './styles';

export const SortingConsole = () => {
  const { locationId, userId } = useOutletContext();
  const { showProcessing, hideProcessing } = useProcessing();
  const { showToast } = useToast();
  const location = useLocation();
  const [selectedPallets, setSelectedPallets] = useState({});
  const [tomatoType, setTomatoType] = useState('Round');
  const [activeMenu, setActiveMenu] = useState('selection');
  
  // Add menuRef for scroll handling
  const menuRef = useRef(null);
  
  // Check for tab query parameter to set active tab
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam && ['selection', 'finish', 'settings', 'scan'].includes(tabParam)) {
      setActiveMenu(tabParam);
    }
  }, [location.search]);
  const [recentlyFinished, setRecentlyFinished] = useState({});
  const [filterRanges, setFilterRanges] = useState({
    [SORTING_GRADES.LIGHT]: { min: 0, max: 0 },
    [SORTING_GRADES.MIDDLE]: { min: 0, max: 0 },
    [SORTING_GRADES.RED]: { min: 0, max: 0 }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // State for order assignment modal
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [palletIdsToAssign, setPalletIdsToAssign] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersError, setOrdersError] = useState(null);
  
  const { isStandalone } = usePwaStatus();

  useEffect(() => {
    // Load saved data
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        setSelectedPallets(loadSavedSelections(userId));
        setRecentlyFinished(loadRecentlyFinished());
        
        if (locationId) {
          const ranges = await fetchFilterRanges(locationId);
          setFilterRanges(ranges || {
            light: { min: 0, max: 0 },
            middle: { min: 0, max: 0 },
            red: { min: 0, max: 0 }
          });
        }
      } catch (err) {
        console.error('Error loading console data:', err);
        setError(err.message || 'Failed to load console data');
      } finally {
        setLoading(false);
      }
    };

    loadData();

    // Clean up old recently finished pallets
    const interval = setInterval(cleanupRecentlyFinished, 60000);
    return () => clearInterval(interval);
  }, [userId, locationId]);

  useEffect(() => {
    saveSelections(userId, selectedPallets);
  }, [selectedPallets, userId]);

  const cleanupRecentlyFinished = () => {
    const now = Date.now();
    setRecentlyFinished(prev => {
      const updated = { ...prev };
      let hasChanges = false;
      
      Object.entries(updated).forEach(([key, timestamp]) => {
        if (now - timestamp > 600000) { // 10 minutes
          delete updated[key];
          hasChanges = true;
        }
      });

      if (hasChanges) {
        saveRecentlyFinished(updated);
      }
      return updated;
    });
  };

  // Handle scroll animation for menu
  const handleScroll = () => {
    if (!menuRef.current) return;

    const menuButtons = menuRef.current.children;
    const containerWidth = menuRef.current.offsetWidth;
    const scrollPosition = menuRef.current.scrollLeft;

    Array.from(menuButtons).forEach(button => {
      const buttonPosition = button.offsetLeft - scrollPosition;
      const buttonWidth = button.offsetWidth;

      button.style.opacity = 
        buttonPosition < 0 || buttonPosition + buttonWidth > containerWidth
          ? "0.5"
          : "1";
    });
  };

  const handlePalletSelect = (palletData) => {
    setSelectedPallets(prev => ({
      ...prev,
      [palletData.position]: { ...palletData, tomatoType }
    }));
  };

  const handleFinishPallet = (position, success) => {
    if (success) {
      const now = Date.now();
      setRecentlyFinished(prev => {
        const updated = { ...prev, [position]: now };
        saveRecentlyFinished(updated);
        return updated;
      });
    }
  };

  const toggleTomatoType = () => {
    const newType = tomatoType === 'Round' ? 'Plum' : 'Round';
    if (window.confirm(`Switch to ${newType}? This will clear current ${tomatoType} selections.`)) {
      setTomatoType(newType);
      setSelectedPallets(prev => {
        const newSelections = {};
        Object.entries(prev).forEach(([key, value]) => {
          if (value.tomatoType === newType) {
            newSelections[key] = value;
          }
        });
        return newSelections;
      });
    }
  };

  const handleRetry = () => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        setSelectedPallets(loadSavedSelections(userId));
        setRecentlyFinished(loadRecentlyFinished());
        
        if (locationId) {
          const ranges = await fetchFilterRanges(locationId);
          setFilterRanges(ranges || {
            light: { min: 0, max: 0 },
            middle: { min: 0, max: 0 },
            red: { min: 0, max: 0 }
          });
        }
      } catch (err) {
        console.error('Error loading console data:', err);
        setError(err.message || 'Failed to load console data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  };

  // Fetch orders for the order assignment modal
  const fetchOrders = async () => {
    if (!locationId) {
      setOrdersError('Location ID is missing');
      return;
    }

    setOrdersLoading(true);
    setOrdersError(null);

    try {
      const response = await api.get(`/api/orders/active?locationId=${locationId}`);
      if (Array.isArray(response.data)) {
        setOrders(response.data);
      } else {
        console.warn('API response is not an array:', response.data);
        setOrders([]);
        setOrdersError('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setOrdersError(error.message || 'Failed to load orders');
      setOrders([]);
    } finally {
      setOrdersLoading(false);
    }
  };

  // Pallet action handlers
  const handleShipPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Shipping pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleShipPallet(id, locationId)));
      showToast({ message: 'Pallets shipped successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error shipping pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleDeletePallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Deleting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleDeletePallet(id, locationId)));
      showToast({ message: 'Pallets deleted successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error deleting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleAssignPalletsToOrder = async (palletIds) => {
    // Store the pallet IDs to assign
    setPalletIdsToAssign(palletIds);
    
    // Fetch orders before opening the modal
    showProcessing({ message: 'Loading orders...', showOverlay: true });
    try {
      await fetchOrders();
      
      // Check if we have orders before opening the modal
      if (orders.length === 0 && !ordersError) {
        showToast({ 
          message: 'No active orders available for assignment. Create a new order first.', 
          variant: 'warning' 
        });
        return;
      }
      
      // Open the order assignment modal
      setIsOrderModalOpen(true);
    } catch (error) {
      showToast({ 
        message: `Error loading orders: ${error.message}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
    }
  };

  const handleOrderAssignment = async (orderId, extraBoxCount) => {
    showProcessing({ message: 'Assigning pallets to order...', showOverlay: true });
    try {
      // Use the stored pallet IDs to assign to the selected order
      await Promise.all(palletIdsToAssign.map(id => handleAssignOrder(id, orderId)));
      showToast({ message: 'Pallets assigned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error assigning pallets: ${error.message}`, variant: 'error' });
      throw error; // Re-throw to let the modal handle the error
    } finally {
      hideProcessing();
    }
  };

  const handleReturnPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Returning pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleReturnPallet(id, locationId)));
      showToast({ message: 'Pallets returned successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error returning pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };

  const handleSplitPallets = async (palletIds) => {
    try {
      showProcessing({ message: 'Splitting pallets...', showOverlay: true });
      await Promise.all(palletIds.map(id => handleSplitPallet(id, locationId)));
      showToast({ message: 'Pallets split successfully', variant: 'success' });
    } catch (error) {
      showToast({ message: `Error splitting pallets: ${error.message}`, variant: 'error' });
    } finally {
      hideProcessing();
    }
  };
  
  // Helper function to set the console type for pallets
  const setConsoleTypeForPallets = (palletIds) => {
    // This function would be used to update pallets with the 'sorting' console type
    // when they are processed in this console
    return palletIds.map(id => ({
      id,
      consoleType: 'sorting'
      // No default process stage for sorting console as it depends on the specific sorting result
    }));
  };

  const renderContent = () => {
    switch (activeMenu) {
      case 'selection':
        return (
          <Selection
            selectedPallets={selectedPallets}
            onPalletSelect={handlePalletSelect}
            tomatoType={tomatoType}
            onToggleTomatoType={toggleTomatoType}
          />
        );
      case 'finish':
        return (
          <FinishMenu 
            selectedPallets={selectedPallets}
            onFinishPallet={handleFinishPallet}
            locationId={locationId}
            tomatoType={tomatoType}
            filterRanges={filterRanges}
            recentlyFinished={recentlyFinished}
          />
        );
      case 'settings':
        return (
          <Settings 
            locationId={locationId}
            onSettingsUpdate={fetchFilterRanges}
          />
        );
      case 'scan':
        return (
          <ScanTab
            onShipPallet={handleShipPallets}
            onDeletePallet={handleDeletePallets}
            onAssignOrder={handleAssignPalletsToOrder}
            onReturnPallet={handleReturnPallets}
            onSplitPallet={handleSplitPallets}
            locationId={locationId}
            consoleType="sorting"
          />
        );
      case 'download':
        return <DownloadPage />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container>
        <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
          {['selection', 'finish', 'settings', 'scan'].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <LoadingContainer>
          <LoadingSpinner />
          <div>Loading console data...</div>
        </LoadingContainer>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
          {['selection', 'finish', 'settings', 'scan'].map(menu => (
            <MenuButton 
              key={menu}
              active={false}
              disabled
            >
              {menu.charAt(0).toUpperCase() + menu.slice(1)}
            </MenuButton>
          ))}
        </ScrollableMenu>
        <ContentArea>
          <ErrorContainer>
            <div>Error: {error}</div>
            <RetryButton onClick={handleRetry}>
              Retry
            </RetryButton>
          </ErrorContainer>
        </ContentArea>
      </Container>
    );
  }

  return (
    <Container>
      <ScrollableMenu ref={menuRef} onScroll={handleScroll}>
        <MenuButton
          active={activeMenu === 'selection'}
          onClick={() => setActiveMenu('selection')}
        >
          Selection
        </MenuButton>
        <MenuButton
          active={activeMenu === 'finish'}
          onClick={() => setActiveMenu('finish')}
        >
          Finish
        </MenuButton>
        <MenuButton
          active={activeMenu === 'settings'}
          onClick={() => setActiveMenu('settings')}
        >
          Settings
        </MenuButton>
        <MenuButton
          active={activeMenu === 'scan'}
          onClick={() => setActiveMenu('scan')}
        >
          Scan
        </MenuButton>
        {!isStandalone && (
          <MenuButton
            active={activeMenu === 'download'}
            onClick={() => setActiveMenu('download')}
          >
            Download
          </MenuButton>
        )}
      </ScrollableMenu>
      <ContentArea>
        {renderContent()}
      </ContentArea>

      {/* Order Assignment Modal */}
      {isOrderModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <MobileOrderAssignmentModal
              orders={orders}
              loading={ordersLoading}
              error={ordersError}
              onClose={() => setIsOrderModalOpen(false)}
              onAssign={handleOrderAssignment}
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default SortingConsole;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.background.default};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Header = styled.header`
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  z-index: ${({ theme }) => theme.zIndex.appBar};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  position: sticky;
  top: 0;
`;

export const HeaderTitle = styled.h1`
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
  color: ${({ theme }) => theme.colors.text.inverse};
`;

export const ConsoleArea = styled.main`
  flex: 1;
  overflow-y: auto;
  position: relative;
  background: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacing.md};
  padding-bottom: calc(${({ theme }) => theme.spacing.xl} * 3); /* Space for the navigation bar */

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[400]};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;
`;

export const LoadingSpinner = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  border: 2px solid ${({ theme }) => theme.colors.background.hover};
  border-top: 2px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.error.alpha};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border-left: 3px solid ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.error.main};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  margin: ${({ theme }) => theme.spacing.md} 0;
  min-height: 150px;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const RetryButton = styled.button`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.inverse};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: 500;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    box-shadow: ${({ theme }) => theme.shadows.md};
  }
  
  &:active {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;

import React, { useState } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { EventsTimeline } from '../EventsTimeline';
import { PalletTimeline } from '../PalletTimeline';
import { EventDetails } from '../EventDetails';
import { InterventionForm } from '../InterventionForm';
import { AnalyticsFilters } from '../AnalyticsFilters';
import { AggregateStats } from '../AggregateStats';
import { UserActivity } from '../UserActivity';
import { RefreshCw } from 'lucide-react';
import { 
  Container, 
  Content, 
  MainSection, 
  SideSection,
  LoadingOverlay,
  LoadingSpinner,
  HeaderControls,
  RefreshButton
} from './styles';

export const AnalyticsDashboard = ({ locationId }) => {
  const [selectedPalletId, setSelectedPalletId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showInterventionForm, setShowInterventionForm] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const {
    events,
    stats,
    userActivity,
    eventTypes,
    isLoading,
    filters,
    updateFilters,
    clearFilters,
    fetchEvents,
    fetchStats,
    fetchUserActivity,
    addIntervention
  } = useAnalytics(locationId);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handlePalletSelect = (palletId) => {
    setSelectedPalletId(palletId);
    setSelectedEvent(null);
  };

  const handleBackToEvents = () => {
    setSelectedPalletId(null);
    setSelectedEvent(null);
  };

  const handleAddIntervention = async (notes) => {
    if (!selectedEvent) return;

    await addIntervention(selectedEvent.id, { notes });
    setShowInterventionForm(false);
    setSelectedEvent(null);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      await Promise.all([
        fetchEvents(),
        fetchStats(), 
        fetchUserActivity()
      ]);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <Container>
      <HeaderControls>
        <AnalyticsFilters
          filters={filters}
          onFilterChange={updateFilters}
          onClearFilters={clearFilters}
          eventTypes={eventTypes}
        />
        <RefreshButton onClick={handleRefresh} disabled={isLoading || refreshing}>
          <RefreshCw size={16} className={refreshing ? 'spinning' : ''} />
          Refresh
        </RefreshButton>
      </HeaderControls>

      <Content>
        <MainSection>
          {selectedPalletId ? (
            <PalletTimeline
              palletId={selectedPalletId}
              onEventSelect={handleEventSelect}
              onBack={handleBackToEvents}
            />
          ) : (
            <EventsTimeline
              events={events}
              onEventSelect={handleEventSelect}
              onPalletSelect={handlePalletSelect}
            />
          )}
        </MainSection>

        <SideSection>
          <AggregateStats stats={stats} />
          <UserActivity activity={userActivity} />
        </SideSection>
      </Content>

      {selectedEvent && (
        <EventDetails
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onIntervene={() => setShowInterventionForm(true)}
        />
      )}

      {showInterventionForm && (
        <InterventionForm
          eventId={selectedEvent?.id}
          onSubmit={handleAddIntervention}
          onCancel={() => setShowInterventionForm(false)}
        />
      )}

      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </Container>
  );
};

export default AnalyticsDashboard;

import { Haptics, ImpactStyle } from '@capacitor/haptics';

export const useHapticFeedback = () => {
  const vibrate = async (style = ImpactStyle.Medium) => {
    try {
      await Haptics.impact({ style });
    } catch (error) {
      console.warn('Haptic feedback not available:', error);
    }
  };

  const successVibration = async () => {
    try {
      // Double tap feedback for success
      await Haptics.impact({ style: ImpactStyle.Light });
      setTimeout(async () => {
        await Haptics.impact({ style: ImpactStyle.Light });
      }, 100);
    } catch (error) {
      console.warn('Haptic feedback not available:', error);
    }
  };

  const errorVibration = async () => {
    try {
      // Heavy impact for error
      await Haptics.impact({ style: ImpactStyle.Heavy });
    } catch (error) {
      console.warn('Haptic feedback not available:', error);
    }
  };

  const notificationVibration = async () => {
    try {
      await Haptics.notification({
        type: 'SUCCESS'
      });
    } catch (error) {
      console.warn('Haptic feedback not available:', error);
    }
  };

  return {
    vibrate,
    successVibration,
    errorVibration,
    notificationVibration
  };
};

import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
`;

export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a202c;
`;

export const ModalContent = styled.div`
  padding: 16px;
  overflow-y: auto;
  flex: 1;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid #e2e8f0;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #4a5568;
  border-radius: 4px;

  &:hover {
    background-color: #f7fafc;
  }
`;

export const CancelButton = styled.button`
  padding: 8px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  color: #4a5568;
  cursor: pointer;

  &:hover {
    background-color: #f7fafc;
  }
`;

export const PrimaryButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #2563eb;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #1d4ed8;
  }

  &:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
  }
`;

export const PalletInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: #f8fafc;
  border-radius: 8px;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoLabel = styled.span`
  font-size: 0.875rem;
  color: #64748b;
`;

export const InfoValue = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #1e293b;
`;

export const ZoneList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

export const ZoneCard = styled.div`
  padding: 16px;
  border: 2px solid ${props => 
    props.$isSelected ? '#2563eb' : 
    props.$isRecommended ? '#0ea5e9' : '#e2e8f0'};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background-color: ${props => 
    props.$isSelected ? '#eff6ff' : 
    props.$isRecommended ? '#f0f9ff' : 'white'};
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.$isSelected ? '#2563eb' : '#0ea5e9'};
    background-color: ${props => props.$isSelected ? '#eff6ff' : '#f0f9ff'};
  }
`;

export const ZoneName = styled.h3`
  margin: 0 0 8px 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
`;

export const ZoneDetails = styled.div`
  margin-bottom: 12px;
  font-size: 0.875rem;
  color: #64748b;
`;

export const OccupancyBar = styled.div`
  height: 4px;
  background-color: #e2e8f0;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
`;

export const OccupancyFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background-color: ${props => {
    if (props.percentage >= 90) return '#ef4444';
    if (props.percentage >= 75) return '#f59e0b';
    return '#22c55e';
  }};
  transition: width 0.3s ease;
`;

export const ZoneTag = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 8px;
  background-color: #f1f5f9;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #64748b;
`;

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background-color: #fef2f2;
  border-radius: 8px;
  color: #b91c1c;
  text-align: center;
`;

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background-color: #f0fdf4;
  border-radius: 8px;
  color: #15803d;
  text-align: center;
`;

export const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: #f0f9ff;
  border-radius: 8px;
  color: #0369a1;
  font-size: 0.875rem;
  margin: 16px 0;
`;

export const SectionTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1e293b;
`;

export const OrientationIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  background-color: #f8fafc;
  border-radius: 6px;
  color: #64748b;
  font-size: 0.875rem;

  svg {
    color: #0369a1;
  }

  .orientation-text {
    font-weight: 500;
  }

  .orientation-hint {
    margin-left: auto;
    font-style: italic;
    color: #94a3b8;
  }
`;

export const GridViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const ActiveRowContainer = styled.div`
  padding: 16px;
  background-color: #ecfdf5;
  border-radius: 8px;
  margin: 16px 0;
`;

export const ActiveRowHeader = styled.h4`
  margin: 0 0 8px 0;
  font-size: 1rem;
  font-weight: 600;
  color: #047857;
`;

export const ActiveRowDetails = styled.div`
  font-size: 0.875rem;
  color: #065f46;
`;

export const ProgressBar = styled.div`
  height: 4px;
  background-color: #d1fae5;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;

  .fill {
    height: 100%;
    width: ${props => props.$percentage}%;
    background-color: #059669;
    transition: width 0.3s ease;
  }
`;

export const ViewToggle = styled.div`
  display: flex;
  gap: 8px;
  margin: 16px 0;
`;

export const ViewToggleButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid ${props => props.$active ? '#2563eb' : '#e2e8f0'};
  border-radius: 4px;
  background-color: ${props => props.$active ? '#eff6ff' : 'white'};
  color: ${props => props.$active ? '#2563eb' : '#64748b'};
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    border-color: #2563eb;
    color: #2563eb;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: auto;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 16px;
`;

export const MapContent = styled.div`
  position: relative;
  width: 1200px;
  height: 800px;
  transform-origin: top left;
`;

export const Zone = styled.div`
  position: absolute;
  border: 2px solid ${props => 
    props.$selected ? '#2563eb' : 
    props.$recommended ? '#0ea5e9' : '#e2e8f0'};
  border-radius: 4px;
  background-color: ${props => 
    props.$selected ? '#eff6ff' : 
    props.$recommended ? '#f0f9ff' : 'white'};
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: visible;

  &:hover {
    border-color: ${props => props.$selected ? '#2563eb' : '#0ea5e9'};
    background-color: ${props => props.$selected ? '#eff6ff' : '#f0f9ff'};
  }

  &[data-custom-layout="true"] {
    border: none;
    background: none;
    pointer-events: none;

    &:hover {
      border: none;
      background: none;
    }

    & > * {
      pointer-events: auto;
    }
  }
`;

export const ZoneSegment = styled.div`
  position: absolute;
  border: 2px solid ${props => 
    props.$selected ? '#2563eb' : 
    props.$recommended ? '#0ea5e9' : '#e2e8f0'};
  border-radius: 4px;
  background-color: ${props => 
    props.$selected ? '#eff6ff' : 
    props.$recommended ? '#f0f9ff' : 'white'};
  transition: all 0.2s ease;
  overflow: hidden;

  &:hover {
    border-color: ${props => props.$selected ? '#2563eb' : '#0ea5e9'};
    background-color: ${props => props.$selected ? '#eff6ff' : '#f0f9ff'};
  }
`;

export const ZoneHeader = styled.div`
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #e2e8f0;
`;

export const ZoneLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: #1e293b;
`;

export const ZoneFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid #e2e8f0;
`;

export const ZoneCapacity = styled.span`
  font-size: 0.75rem;
  color: #64748b;
`;

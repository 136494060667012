import { useState } from 'react';
import { api } from '@lib/api';
import kleinpakSSEManager from '../../../../../lib/services/sse/KleinpakSSEManager';

const usePalletActions = (locationId, updateOrders, showToast, showProcessing, hideProcessing) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);
  const [newBoxCount, setNewBoxCount] = useState('');
  const [updateError, setUpdateError] = useState(null);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const [scanTargetOrderId, setScanTargetOrderId] = useState(null);

  // Handle update box count
  const handleUpdateBoxCount = (pallet) => {
    setSelectedPallet(pallet);
    setNewBoxCount(pallet.boxCount?.toString() || '');
    setUpdateError(null);
    setShowUpdateModal(true);
  };
  
  // Save updated box count
  const handleSaveBoxCount = async () => {
    if (!selectedPallet) return;
    
    // Validate input
    const boxCount = parseInt(newBoxCount, 10);
    if (isNaN(boxCount) || boxCount < 0) {
      setUpdateError('Please enter a valid box count');
      return;
    }
    
    showProcessing({ message: 'Updating box count...', showOverlay: true });
    
    try {
      // Update pallet box count
      await api.patch(`/api/pallets/${selectedPallet.id}`, {
        boxCount,
        locationId
      });
      
      // Update local state via callback
      updateOrders(prevOrders => {
        return prevOrders.map(order => {
          if (order.pallets.some(p => p.id === selectedPallet.id)) {
            return {
              ...order,
              pallets: order.pallets.map(p => 
                p.id === selectedPallet.id ? { ...p, boxCount } : p
              )
            };
          }
          return order;
        });
      });
      
      showToast({ 
        message: `Box count updated successfully for pallet ${selectedPallet.id}`, 
        variant: 'success' 
      });
      
      // Close modal
      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating box count:', error);
      setUpdateError(error.message || 'Failed to update box count');
    } finally {
      hideProcessing();
    }
  };
  
  // Handle starting the scan process
  const handleScanToAssign = (orderId) => {
    if (!orderId) return;
    
    // Note: We no longer need to manually enable scanning mode here
    // because the QRScanner component will handle this via onMount
    
    setScanTargetOrderId(orderId);
    setShowScannerModal(true);
  };
  
  // Handle QR code scan result
  const handleScanResult = async (scanData) => {
    setShowScannerModal(false);
    
    // Note: We no longer need to manually disable scanning mode here
    // because the QRScanner component will handle this via onUnmount
    
    if (!scanData || !scanData.id || !scanTargetOrderId) {
      showToast({
        message: 'Invalid scan data or target order',
        variant: 'error'
      });
      return;
    }
    
    showProcessing({ message: 'Assigning scanned pallet to order...', showOverlay: true });
    
    try {
      // Get current orders state to check for allowed pallet IDs
      let currentOrders = [];
      updateOrders(prevOrders => {
        currentOrders = prevOrders;
        return prevOrders;
      });
      
      const order = currentOrders.find(o => o.id === scanTargetOrderId);
      
      if (order && order.allowed_pallet_ids && order.allowed_pallet_ids.length > 0) {
        // Convert scanned ID to string to ensure consistent comparison
        const palletId = scanData.id.toString();
        const allowedIds = order.allowed_pallet_ids.map(id => id.toString());
        
        // Check if the scanned pallet is allowed for this order
        if (!allowedIds.includes(palletId)) {
          showToast({
            message: `Error: Pallet #${palletId} is not allowed for this order. Please use an approved pallet.`,
            variant: 'error'
          });
          hideProcessing();
          setScanTargetOrderId(null);
          return;
        }
      }
      
      // Assign the scanned pallet to the order and update to 'processing' stage
      await api.post(`/api/pallets/${scanData.id}/assign`, {
        orderId: scanTargetOrderId,
        locationId,
        processStage: 'processing' // Consistent lowercase value
      });
      
      // Update the pallet directly to ensure process stage is set
      await api.patch(`/api/pallets/${scanData.id}`, {
        processStage: 'processing',
        locationId
      });
      
      // Trigger a pallet assignment event for other components that might be listening
      const palletAssignedEvent = new CustomEvent('palletAssigned', {
        detail: { palletId: scanData.id, orderId: scanTargetOrderId }
      });
      window.dispatchEvent(palletAssignedEvent);
      
      showToast({ 
        message: `Pallet #${scanData.id} assigned to order #${scanTargetOrderId} and set to processing`, 
        variant: 'success' 
      });
    } catch (error) {
      console.error('Error assigning scanned pallet to order:', error);
      showToast({ 
        message: `Error: ${error.message || 'Something went wrong'}`, 
        variant: 'error' 
      });
    } finally {
      hideProcessing();
      setScanTargetOrderId(null);
    }
  };
  
  // Handle manually closing the scanner
  const handleCloseScanner = () => {
    setShowScannerModal(false);
    setScanTargetOrderId(null);
    
    // Re-enable SSE when scanner is closed
    setTimeout(() => {
      kleinpakSSEManager.disableScanningMode();
      console.log('usePalletActions: Re-enabled SSE after scanner closed manually');
    }, 500);
  };
  
  // Handle manual pallet ID input
  const handleManualPalletInput = () => {
    const palletId = prompt('Enter pallet ID:');
    if (palletId && scanTargetOrderId) {
      handleScanResult({ id: palletId });
    } else {
      handleCloseScanner();
    }
  };

  return {
    // State
    showUpdateModal,
    selectedPallet,
    newBoxCount,
    updateError,
    showScannerModal,
    scanTargetOrderId,
    
    // State setters
    setShowUpdateModal,
    setSelectedPallet,
    setNewBoxCount,
    setUpdateError,
    setShowScannerModal,
    setScanTargetOrderId,
    
    // Handlers
    handleUpdateBoxCount,
    handleSaveBoxCount,
    handleScanToAssign,
    handleScanResult,
    handleManualPalletInput,
    handleCloseScanner
  };
};

export default usePalletActions;

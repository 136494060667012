import styled from 'styled-components';
import { theme } from '@styles/theme';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background-color: ${theme.colors.background.default};
  border-radius: ${theme.borderRadius.md};
  margin: 0 1rem;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 180px;
  flex: 1;
`;

export const Label = styled.label`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${theme.colors.text.secondary};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const BaseInput = styled.input`
  height: 36px;
  padding: 0 0.75rem;
  border: 1px solid ${theme.colors.border.light};
  border-radius: ${theme.borderRadius.sm};
  font-size: 0.875rem;
  color: ${theme.colors.text.primary};
  background-color: ${theme.colors.background.paper};
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary.main};
  }

  &:hover:not(:disabled) {
    border-color: ${theme.colors.border.dark};
  }

  &:disabled {
    background-color: ${theme.colors.background.disabled};
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  ${BaseInput};
  padding-right: 2rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1rem;
`;

export const DateInput = styled(BaseInput).attrs({ type: 'date' })`
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 36px;
  padding: 0 1rem;
  border-radius: ${theme.borderRadius.sm};
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const FilterButton = styled(BaseButton)`
  background-color: ${theme.colors.primary.main};
  color: white;
  border: none;
  margin-left: auto;

  &:hover:not(:disabled) {
    background-color: ${theme.colors.primary.dark};
  }
`;

export const ClearButton = styled(BaseButton)`
  background: none;
  border: 1px solid ${theme.colors.border.light};
  color: ${theme.colors.text.secondary};

  &:hover:not(:disabled) {
    background-color: ${theme.colors.background.hover};
    border-color: ${theme.colors.border.dark};
    color: ${theme.colors.text.primary};
  }
`;

import React, { useState, useEffect, useCallback } from 'react';
import { getEnhancedPalletsInScheduledRows } from '../../../../../lib/utils/rowStorageUtils';
import { transformToActualRowIndex, transformToVisualRowIndex } from '../../../../../lib/utils/zoneTransformUtils';
import { DEFAULT_ZONES } from '../../../../../features/map/constants/zoneConfig.v2';
import { Package, MapPin, CheckCircle, ClipboardCheck, LayoutGrid, ChevronDown, ChevronUp } from 'lucide-react';
import { ZoneGroupsContainer, EmptyPalletsMessage, PalletCount, ZoneName } from '../styles';
import {
  CompactZoneCard,
  CompactZoneHeader,
  RowsContainer,
  RowCard,
  RowHeader,
  RowPalletList,
  PalletItem,
  ExpandButton,
  RowName,
  RowStatus,
  CompletedZoneMessage,
  ZoneSummary,
  ZoneStats,
  ShowMoreButton,
} from './RowGroupedPallets.styles';

/**
 * RowGroupedPallets component - A variant of ZoneGroupedPallets that enables row-level navigation
 * This component is specifically designed for use with row-scheduled orders
 */
const RowGroupedPallets = ({ order, onZoneClick, onRowClick }) => {
  // State to temporarily keep completed zones visible for fade out animation
  const [completedZoneNames, setCompletedZoneNames] = useState([]);
  const [visibleCompletedZones, setVisibleCompletedZones] = useState([]);
  // State to track which zone's row list is expanded
  const [expandedZone, setExpandedZone] = useState(null);
  // State to track which rows are expanded to show pallet IDs
  const [expandedRows, setExpandedRows] = useState(new Set());
  // State to track zone and row completion status
  const [zoneCompletionStatus, setZoneCompletionStatus] = useState({});
  const [rowCompletionStatus, setRowCompletionStatus] = useState({});
  // State to store filtered pallets
  const [filteredPallets, setFilteredPallets] = useState([]);
  // State to store sorted zone entries
  const [sortedZoneEntries, setSortedZoneEntries] = useState([]);
  // State to store display zone entries
  const [displayZoneEntries, setDisplayZoneEntries] = useState([]);
  // State to store completed zones count
  const [completedZonesCount, setCompletedZonesCount] = useState(0);

  // Helper functions for data normalization
  const normalizeZoneId = useCallback((zoneId) => {
    if (!zoneId) return undefined;
    return String(zoneId)
      .trim()
      .toLowerCase()
      .replace(/^zone[-_]?/, '')
      .replace(/[^a-z0-9]/g, '');
  }, []);

  const normalizeRowIndex = useCallback((rowIndex) => {
    if (rowIndex === undefined || rowIndex === null) return undefined;
    const num = Number(rowIndex);
    return isNaN(num) ? undefined : num;
  }, []);

  // Toggle expansion of a zone's row list
  const toggleZoneExpansion = useCallback((zoneName, event) => {
    event.stopPropagation();
    setExpandedZone(prev => prev === zoneName ? null : zoneName);
  }, []);

  // Toggle expansion of a row's pallet list
  const toggleRowExpansion = useCallback((rowId, event) => {
    event.stopPropagation();
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(rowId)) {
        newSet.delete(rowId);
      } else {
        newSet.add(rowId);
      }
      return newSet;
    });
  }, []);

  // Get zone configuration for a given row
  const getZoneConfig = useCallback((row) => {
    if (!row?.zoneId) return null;
    
    // Try to find zone by ID first
    let zoneConfig = DEFAULT_ZONES.find(zone => 
      zone.id?.toLowerCase() === row.zoneId?.toString().toLowerCase()
    );
    
    // If not found by ID, try by name
    if (!zoneConfig && row.zoneName) {
      zoneConfig = DEFAULT_ZONES.find(zone => 
        zone.name?.toLowerCase() === row.zoneName?.toString().toLowerCase()
      );
    }
    
    // If still not found, try matching zone number
    if (!zoneConfig && row.zoneId) {
      const zoneMatch = row.zoneId.toString().match(/zone[-_]?(\d+(?:\.\d+)?)/i);
      if (zoneMatch) {
        zoneConfig = DEFAULT_ZONES.find(zone => {
          const configMatch = zone.id?.match(/zone[-_]?(\d+(?:\.\d+)?)/i);
          return configMatch && configMatch[1] === zoneMatch[1];
        });
      }
    }
    
    return zoneConfig;
  }, []);

  // Format the scheduled rows data into a zone->rows hierarchical structure
  const formatScheduledRows = useCallback(() => {
    if (!order || !order.scheduled_rows || !Array.isArray(order.scheduled_rows) || order.scheduled_rows.length === 0) {
      return {};
    }
    
    // Group rows by zone
    const zoneRowsMap = {};
    
    order.scheduled_rows.forEach(row => {
      // Get zone configuration for this row
      const zoneConfig = getZoneConfig(row);
      if (!zoneConfig) {
        console.warn('No zone config found for row:', row);
        return;
      }
      
      // Normalize row data
      const normalizedZoneId = normalizeZoneId(row.zoneId);
      let normalizedRowIndex = normalizeRowIndex(row.rowIndex);
      
      // If this is an inverted zone, transform the row index
      if (zoneConfig.rowViewConfig?.invertRowsAndColumns) {
        normalizedRowIndex = transformToActualRowIndex(normalizedRowIndex, zoneConfig);
      }
      
      // Skip invalid rows
      if (!normalizedZoneId || normalizedRowIndex === undefined) {
        console.warn('Skipping invalid row:', {
          original: { zoneId: row.zoneId, rowIndex: row.rowIndex },
          normalized: { zoneId: normalizedZoneId, rowIndex: normalizedRowIndex }
        });
        return;
      }
      
      const zoneName = row.zoneName || normalizedZoneId;
      
      if (!zoneRowsMap[zoneName]) {
        zoneRowsMap[zoneName] = [];
      }
      
      // Create normalized row object
      const normalizedRow = {
        id: row.rowId || `${normalizedZoneId}-row-${normalizedRowIndex}`,
        zoneId: normalizedZoneId,
        zoneName,
        rowIndex: normalizedRowIndex,
        originalRowIndex: row.rowIndex, // Keep original for display
        zoneConfig, // Attach zone config for future reference
        ...row
      };
      
      zoneRowsMap[zoneName].push(normalizedRow);
    });
    
    // Sort rows within each zone by index
    Object.keys(zoneRowsMap).forEach(zoneName => {
      zoneRowsMap[zoneName].sort((a, b) => a.rowIndex - b.rowIndex);
    });
    
    return zoneRowsMap;
  }, [order, getZoneConfig, normalizeZoneId, normalizeRowIndex]);

  // Filter pallets based on scheduled rows
  const filterPalletsByScheduledRows = useCallback((pallets, scheduledRows) => {
    if (!pallets || !scheduledRows || !Array.isArray(scheduledRows) || scheduledRows.length === 0) {
      console.log('No valid pallets or scheduledRows provided');
      return [];
    }

    // Use the enhanced matching logic with normalized data
    return getEnhancedPalletsInScheduledRows(pallets, scheduledRows, true);
  }, []);

  // Initialize with values from props for immediate display
  useEffect(() => {
    if (!order || !order.scheduled_rows || !Array.isArray(order.scheduled_rows)) {
      return;
    }
    
    // Initialize completion status based on pallets assigned to rows
    if (order.pallets && Array.isArray(order.pallets)) {
      const zoneStatus = {};
      const rowStatus = {};
      
      // Format the scheduled rows
      const zoneRowsMap = formatScheduledRows();
      
      // Collect ALL relevant pallets from both scheduling methods
      let relevantPallets = new Set();
      
      // PART 1: First, process pallets from scheduled rows (primary method for this component)
      if (order.scheduled_rows && Array.isArray(order.scheduled_rows) && order.scheduled_rows.length > 0) {
        // Get pallets from scheduled rows
        const rowPallets = filterPalletsByScheduledRows(order.pallets, order.scheduled_rows);
        
        // Add all row pallets to our set by ID (to avoid duplicates)
        rowPallets.forEach(pallet => {
          relevantPallets.add(pallet);
        });
      }
      
      // PART 2: Also include individually scheduled pallets (allowed_pallet_ids)
      if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
        // Find the pallets that match allowed_pallet_ids
        const allowedPallets = order.pallets.filter(pallet => 
          order.allowed_pallet_ids.includes(pallet.id) || 
          order.allowed_pallet_ids.includes(String(pallet.id))
        );
        
        // Add all individually allowed pallets to our set
        allowedPallets.forEach(pallet => {
          // Check if this pallet isn't already in our set
          if (!Array.from(relevantPallets).some(p => p.id === pallet.id)) {
            relevantPallets.add(pallet);
          }
        });
      }
      
      // Convert back to array 
      const combinedPallets = Array.from(relevantPallets);
      setFilteredPallets(combinedPallets);
      
      // For each zone, check if all rows are complete
      const zoneEntries = Object.entries(zoneRowsMap);
      const sortedEntries = [...zoneEntries].sort((a, b) => a[0].localeCompare(b[0]));
      setSortedZoneEntries(sortedEntries);
      
      Object.entries(zoneRowsMap).forEach(([zoneName, rows]) => {
        const zoneComplete = rows.every(row => {
          // Get pallets in this row using the enhanced matching logic
          const palletsInRow = getEnhancedPalletsInScheduledRows(combinedPallets, [row], true);
          
          // Row is complete if it has at least one pallet and all are processed
          const rowComplete = palletsInRow.length > 0 && 
            palletsInRow.every(p => p.isScanned || p.processStage === 'Processing');
          
          // Store row completion status
          rowStatus[row.id] = rowComplete;
          
          return rowComplete;
        });
        
        // Store zone completion status
        zoneStatus[zoneName] = zoneComplete;
        
        // If newly completed, add to visible completed zones
        if (zoneComplete && !completedZoneNames.includes(zoneName)) {
          setCompletedZoneNames(prev => [...prev, zoneName]);
          setVisibleCompletedZones(prev => [...prev, zoneName]);
        }
      });
      
      setZoneCompletionStatus(zoneStatus);
      setRowCompletionStatus(rowStatus);
      
      // Update completed zones count
      const completedCount = Object.values(zoneStatus).filter(Boolean).length;
      setCompletedZonesCount(completedCount);
      
      // Update display entries
      const displayEntries = sortedEntries.filter(([zoneName, _]) => 
        !zoneStatus[zoneName] || visibleCompletedZones.includes(zoneName)
      );
      setDisplayZoneEntries(displayEntries);
    }
  }, [
    order,
    formatScheduledRows,
    filterPalletsByScheduledRows,
    completedZoneNames,
    visibleCompletedZones
  ]);
  
  // Remove completed zones from visible list after animation
  useEffect(() => {
    const timeoutIds = visibleCompletedZones.map(zoneName => 
      setTimeout(() => {
        setVisibleCompletedZones(prev => 
          prev.filter(name => name !== zoneName)
        );
      }, 500) // Match this to the animation duration
    );
    
    return () => timeoutIds.forEach(id => clearTimeout(id));
  }, [visibleCompletedZones]);

  if (sortedZoneEntries.length === 0) {
    return (
      <EmptyPalletsMessage>
        <Package size={24} style={{ opacity: 0.5, marginBottom: '8px' }} />
        <p style={{ margin: '0' }}>No rows scheduled for this order.</p>
        <p style={{ margin: '4px 0 0' }}>Use "Schedule Rows" in the order form to assign storage rows.</p>
      </EmptyPalletsMessage>
    );
  }
  
  // Show a message instead if all zones are completed
  if (displayZoneEntries.length === 0) {
    return (
      <>
        <ZoneSummary>
          <ZoneStats>
            <span>{completedZonesCount}</span> of <span>{sortedZoneEntries.length}</span> zones completed
          </ZoneStats>
        </ZoneSummary>
        
        <CompletedZoneMessage>
          <ClipboardCheck size={18} />
          All zones are complete! No more rows to scan.
        </CompletedZoneMessage>
      </>
    );
  }

  return (
    <>
      <ZoneSummary>
        <ZoneStats>
          <span>{completedZonesCount}</span> of <span>{sortedZoneEntries.length}</span> zones completed
        </ZoneStats>
      </ZoneSummary>
      
      <ZoneGroupsContainer>
        {displayZoneEntries.map(([zoneName, rows]) => {
          const isZoneCompleted = zoneCompletionStatus[zoneName] || false;
          const isExpanded = expandedZone === zoneName || rows.length <= 3;
          
          // Get zone configuration
          const zoneConfig = rows[0]?.zoneConfig;
          const isInvertedZone = zoneConfig?.rowViewConfig?.invertRowsAndColumns;
          
          return (
            <CompactZoneCard 
              key={zoneName}
              isCompleted={isZoneCompleted}
              onClick={(e) => {
                e.stopPropagation();
                if (!isZoneCompleted) {
                  onZoneClick(zoneName, rows, order);
                }
              }}
            >
              <CompactZoneHeader isCompleted={isZoneCompleted}>
                <ZoneName>
                  <MapPin size={14} />
                  {zoneName}
                  {isInvertedZone && (
                    <span style={{ fontSize: '0.8em', opacity: 0.7 }}> (Inverted)</span>
                  )}
                </ZoneName>
                <PalletCount>
                  {rows.filter(row => rowCompletionStatus[row.id]).length}/{rows.length} rows
                </PalletCount>
              </CompactZoneHeader>
              
              {isZoneCompleted ? (
                <CompletedZoneMessage>
                  <CheckCircle size={14} />
                  All rows complete
                </CompletedZoneMessage>
              ) : (
                <RowsContainer>
                  {(isExpanded ? rows : rows.slice(0, 3)).map(row => {
                    const isRowCompleted = rowCompletionStatus[row.id] || false;
                    
                    // Get the display row index (original) and actual row index
                    const displayRowIndex = isInvertedZone ? 
                      transformToVisualRowIndex(row.rowIndex, zoneConfig) : 
                      row.rowIndex;
                    
                    return (
                      <RowCard 
                        key={row.id}
                        isCompleted={isRowCompleted}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isRowCompleted) {
                            // Create an enhanced row object with both indices and inverted flag
                            const enhancedRow = {
                              ...row,
                              displayRowIndex,
                              actualRowIndex: row.rowIndex,
                              isInvertedZone
                            };
                            onRowClick(zoneName, enhancedRow, order);
                          }
                        }}
                      >
                        <RowHeader>
                          <RowName isCompleted={isRowCompleted}>
                            <LayoutGrid size={14} />
                            Row {displayRowIndex + 1}
                          </RowName>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <RowStatus isCompleted={isRowCompleted}>
                              {isRowCompleted ? (
                                <>
                                  <CheckCircle size={12} />
                                  Complete
                                </>
                              ) : (
                                'Pending'
                              )}
                            </RowStatus>
                            <ExpandButton 
                              onClick={(e) => toggleRowExpansion(row.id, e)}
                              title={expandedRows.has(row.id) ? "Hide pallet IDs" : "Show pallet IDs"}
                            >
                              {expandedRows.has(row.id) ? <ChevronUp /> : <ChevronDown />}
                            </ExpandButton>
                          </div>
                        </RowHeader>

                        {expandedRows.has(row.id) && (
                          <RowPalletList isCompleted={isRowCompleted}>
                            {(() => {
                              // Use the same normalization helpers for consistency
                              const normalizedRow = {
                                ...row,
                                zoneId: normalizeZoneId(row.zoneId),
                                rowIndex: row.rowIndex // Use actual row index, not display index
                              };
                              
                              return getEnhancedPalletsInScheduledRows(filteredPallets, [normalizedRow], true)
                                .map(pallet => (
                                  <PalletItem 
                                    key={pallet.id}
                                    isProcessed={pallet.isScanned || pallet.processStage === 'Processing'}
                                  >
                                    <Package size={14} />
                                    {pallet.id}
                                  </PalletItem>
                                ));
                            })()}
                          </RowPalletList>
                        )}
                      </RowCard>
                    );
                  })}
                  
                  {rows.length > 3 && !isExpanded && (
                    <ShowMoreButton onClick={(e) => toggleZoneExpansion(zoneName, e)}>
                      Show all {rows.length} rows
                    </ShowMoreButton>
                  )}
                  
                  {rows.length > 3 && isExpanded && (
                    <ShowMoreButton onClick={(e) => toggleZoneExpansion(zoneName, e)}>
                      Show fewer rows
                    </ShowMoreButton>
                  )}
                </RowsContainer>
              )}
            </CompactZoneCard>
          );
        })}
      </ZoneGroupsContainer>
    </>
  );
};

export default RowGroupedPallets;

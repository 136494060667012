import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Map as MapIcon, ChevronLeft } from 'lucide-react';
import { DEFAULT_ZONES, ZONE_TYPES, calculateZoneCapacity } from '@features/map/constants/zoneConfig.v2';
import { getZoneGradient, getZoneBorderColor } from '@features/map/constants/colors/zoneColors';
import { zoneUpdateService, ZONE_UPDATE_EVENTS } from '@features/map/services/zoneUpdateService';
import { useToast } from '@lib/contexts';

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  flex: 1;
  background: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-top: 8px;
  padding: 4px;
  touch-action: pan-x pan-y; /* Enable touch scrolling on mobile */
`;

const MapContent = styled.div`
  position: relative;
  width: 800px; /* Match the grid system (50 columns * 16px for enough space) */
  height: 800px; /* Provide sufficient area for all zones */
  transform-origin: 0 0;
`;

const Zone = styled.div`
  position: absolute;
  background: ${({ theme, type, occupancy }) => getZoneGradient(theme, type, occupancy)};
  border: 2px solid ${({ theme, type, $selected }) => getZoneBorderColor(theme, type, $selected)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};
  opacity: ${({ $selected }) => ($selected ? 1 : 0.9)};
  box-shadow: ${({ theme, $selected }) => $selected ? theme.shadows.lg : theme.shadows.sm};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ZoneHeader = styled.div`
  padding: 6px 8px 4px;
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const ZoneLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ZoneFooter = styled.div`
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.85);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`;

const ZoneCapacity = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const OccupancyBar = styled.div`
  height: 3px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 1.5px;
  overflow: hidden;
`;

const OccupancyFill = styled.div`
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  background: ${({ theme, percentage }) => {
    if (percentage > 90) return theme.colors.error.main;
    if (percentage > 70) return theme.colors.warning.main;
    return theme.colors.success.main;
  }};
  transition: width 0.3s ease;
`;

const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.$cols}, 1fr);
  grid-template-rows: repeat(${props => props.$rows}, 1fr);
  gap: 1px;
  flex: 1;
  background: rgba(0, 0, 0, 0.05);
  z-index: 0;
`;

const PalletCell = styled.div`
  background: ${({ $occupied, theme }) => 
    $occupied ? theme.colors.primary.light : 'rgba(255, 255, 255, 0.5)'};
  min-height: 5px;
  min-width: 5px;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.background.default};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
`;

const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: ${({ theme, type }) => {
    switch (type) {
      case ZONE_TYPES.ROUND_TOMATO:
        return `linear-gradient(135deg, ${theme.colors.primary[300]} 0%, ${theme.colors.primary.main} 100%)`;
      case ZONE_TYPES.PLUM_TOMATO:
        return `linear-gradient(135deg, ${theme.colors.secondary[300]} 0%, ${theme.colors.secondary.main} 100%)`;
      case ZONE_TYPES.BAKKER:
        return `linear-gradient(135deg, ${theme.colors.success[300]} 0%, ${theme.colors.success.main} 100%)`;
      case ZONE_TYPES.VAN_GELDER:
        return `linear-gradient(135deg, ${theme.colors.warning[300]} 0%, ${theme.colors.warning.main} 100%)`;
      default:
        return theme.colors.background.default;
    }
  }};
`;

const ZoneDetail = styled.div`
  margin-top: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 14px;
  cursor: pointer;
  padding: 4px 0;
  
  &:hover {
    text-decoration: underline;
  }
`;

const DetailTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const DetailValue = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const DetailGrid = styled.div`
  margin-top: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.light};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
`;

const DetailGridRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  
  &:last-child {
    border-bottom: none;
  }
`;

const DetailGridRowLabel = styled.div`
  width: 40px;
  padding: 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.secondary};
  background: ${({ theme }) => theme.colors.background.default};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.border.light};
`;

const DetailGridCells = styled.div`
  display: flex;
  flex: 1;
`;

const DetailGridCell = styled.div`
  flex: 1;
  height: 30px;
  background: ${({ $occupied, theme }) => 
    $occupied ? theme.colors.primary.light : theme.colors.background.paper};
  border-right: 1px solid ${({ theme }) => theme.colors.border.light};
  
  &:last-child {
    border-right: none;
  }
`;

const LoadingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const MobileStorageMap = () => {
  const { locationId } = useOutletContext();
  const { showToast } = useToast();
  const [selectedZone, setSelectedZone] = useState(null);
  const [zonePalletCounts, setZonePalletCounts] = useState({});
  const [zoneOccupancyData, setZoneOccupancyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scale, setScale] = useState(0.9); // Slightly adjusted scale
  const [showDetailView, setShowDetailView] = useState(false);

  useEffect(() => {
    if (!locationId) return;

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Initialize the zone update service
        zoneUpdateService.initialize(locationId);
        
        // Fetch zone data from the service
        const data = await zoneUpdateService.fetchZoneData(locationId);
        
        // Extract pallet counts and occupancy data for each zone
        const counts = {};
        const occupancy = {};
        
        Object.entries(data).forEach(([zoneId, zoneInfo]) => {
          counts[zoneId] = zoneInfo.palletCount || 0;
          occupancy[zoneId] = zoneInfo.occupancy || {};
        });
        
        setZonePalletCounts(counts);
        setZoneOccupancyData(occupancy);
      } catch (err) {
        console.error('Error fetching zone data:', err);
        setError('Failed to load storage zone data');
        
        // Fallback to simulated data if API fails
        const simulatedData = zoneUpdateService.getSimulatedZoneData();
        
        setZonePalletCounts(
          Object.keys(simulatedData).reduce((acc, key) => {
            acc[key] = simulatedData[key].palletCount;
            return acc;
          }, {})
        );
        
        setZoneOccupancyData(
          Object.keys(simulatedData).reduce((acc, key) => {
            acc[key] = simulatedData[key].occupancy || {};
            return acc;
          }, {})
        );
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
    
    // Set up event listener for zone updates
    const unsubscribe = zoneUpdateService.subscribe(
      ZONE_UPDATE_EVENTS.ZONES_UPDATED, 
      (updatedData) => {
        // Update pallet counts and occupancy data
        const counts = {};
        const occupancy = {};
        
        Object.entries(updatedData).forEach(([zoneId, zoneInfo]) => {
          counts[zoneId] = zoneInfo.palletCount || 0;
          occupancy[zoneId] = zoneInfo.occupancy || {};
        });
        
        setZonePalletCounts(counts);
        setZoneOccupancyData(occupancy);
      }
    );
    
    return () => {
      unsubscribe();
      zoneUpdateService.cleanup();
    };
  }, [locationId, showToast]);

  const handleZoneClick = async (zone) => {
    setSelectedZone(zone);
    setShowDetailView(true);
    
    // Fetch detailed occupancy data for the selected zone
    try {
      const occupancyData = await zoneUpdateService.getZoneOccupancy(zone.id, locationId);
      
      // Update the occupancy data for this zone
      setZoneOccupancyData(prevData => ({
        ...prevData,
        [zone.id]: occupancyData
      }));
    } catch (error) {
      console.error('Error fetching zone occupancy data:', error);
      showToast({ 
        message: 'Failed to load position data for this zone', 
        variant: 'error' 
      });
    }
  };

  const handleBackToMap = () => {
    setShowDetailView(false);
  };

  const getOccupancyPercentage = (zoneId, capacity) => {
    const count = zonePalletCounts[zoneId] || 0;
    return Math.round((count / capacity) * 100);
  };

  // Render the detail view
  if (showDetailView && selectedZone) {
    const capacity = calculateZoneCapacity(selectedZone);
    const occupiedCount = zonePalletCounts[selectedZone.id] || 0;
    const occupancyPercentage = getOccupancyPercentage(selectedZone.id, capacity);
    const zoneOccupancy = zoneOccupancyData[selectedZone.id] || {};
    
    // Organize occupancy data by rows for the detailed grid view
    const rowData = Array.from({ length: selectedZone.dimensions.rows }).map((_, rowIndex) => {
      const cells = Array.from({ length: selectedZone.dimensions.cols }).map((_, colIndex) => {
        const posKey = `${rowIndex}-${colIndex}`;
        return {
          position: posKey,
          occupied: !!zoneOccupancy[posKey]
        };
      });
      
      return {
        row: rowIndex,
        cells
      };
    });
    
    return (
      <Container>
        <Header>
          <MapIcon size={20} />
          <Title>Storage Map - Zone Detail</Title>
        </Header>
        
        <ZoneDetail>
          <DetailHeader>
            <BackButton onClick={handleBackToMap}>
              <ChevronLeft size={16} />
              Back to map
            </BackButton>
            <DetailTitle>{selectedZone.name}</DetailTitle>
          </DetailHeader>
          
          <DetailRow>
            <DetailLabel>Type</DetailLabel>
            <DetailValue>
              {selectedZone.type === ZONE_TYPES.ROUND_TOMATO ? 'Round Tomato' :
               selectedZone.type === ZONE_TYPES.PLUM_TOMATO ? 'Plum Tomato' :
               selectedZone.type === ZONE_TYPES.BAKKER ? 'Bakker' :
               selectedZone.type === ZONE_TYPES.VAN_GELDER ? 'Van Gelder' : 'Other'}
            </DetailValue>
          </DetailRow>
          
          <DetailRow>
            <DetailLabel>Dimensions</DetailLabel>
            <DetailValue>{selectedZone.dimensions.rows} × {selectedZone.dimensions.cols}</DetailValue>
          </DetailRow>
          
          <DetailRow>
            <DetailLabel>Occupancy</DetailLabel>
            <DetailValue>
              {occupiedCount} / {capacity} pallets ({occupancyPercentage}%)
            </DetailValue>
          </DetailRow>
          
          <DetailGrid>
            {rowData.map(row => (
              <DetailGridRow key={`row-${row.row}`}>
                <DetailGridRowLabel>R{row.row + 1}</DetailGridRowLabel>
                <DetailGridCells>
                  {row.cells.map((cell, idx) => (
                    <DetailGridCell
                      key={`cell-${cell.position}`}
                      $occupied={cell.occupied}
                      title={`Position: ${cell.position}, ${cell.occupied ? 'Occupied' : 'Empty'}`}
                    />
                  ))}
                </DetailGridCells>
              </DetailGridRow>
            ))}
          </DetailGrid>
        </ZoneDetail>
      </Container>
    );
  }

  // Render the map view
  return (
    <Container>
      <Header>
        <MapIcon size={20} />
        <Title>Storage Map</Title>
      </Header>

      <Legend>
        <LegendItem>
          <LegendColor type={ZONE_TYPES.ROUND_TOMATO} />
          <span>Round</span>
        </LegendItem>
        <LegendItem>
          <LegendColor type={ZONE_TYPES.PLUM_TOMATO} />
          <span>Plum</span>
        </LegendItem>
        <LegendItem>
          <LegendColor type={ZONE_TYPES.BAKKER} />
          <span>Bakker</span>
        </LegendItem>
        <LegendItem>
          <LegendColor type={ZONE_TYPES.VAN_GELDER} />
          <span>Van Gelder</span>
        </LegendItem>
      </Legend>

      <MapContainer>
        {isLoading ? (
          <LoadingText>Loading storage map...</LoadingText>
        ) : (
          <MapContent style={{ transform: `scale(${scale})` }}>
            {DEFAULT_ZONES.map((zone) => {
              const capacity = calculateZoneCapacity(zone);
              const occupancyPercentage = getOccupancyPercentage(zone.id, capacity);
              const zoneOccupancy = zoneOccupancyData[zone.id] || {};
              
              return (
                <Zone
                  key={zone.id}
                  type={zone.type}
                  occupancy={occupancyPercentage}
                  style={{
                    // Use the same 16px grid system as desktop for consistent positioning
                    left: `${zone.position.x * 16}px`,
                    top: `${zone.position.y * 16}px`,
                    width: `${zone.dimensions.cols * 16}px`,
                    height: `${zone.dimensions.rows * 16}px`,
                  }}
                  onClick={() => handleZoneClick(zone)}
                  $selected={false}
                >
                  <ZoneHeader>
                    <ZoneLabel>{zone.name}</ZoneLabel>
                  </ZoneHeader>
                  
                  {/* Pallet grid showing occupied positions */}
                  <PalletGrid $rows={zone.dimensions.rows} $cols={zone.dimensions.cols}>
                    {Array.from({ length: zone.dimensions.rows * zone.dimensions.cols }).map((_, index) => {
                      const row = Math.floor(index / zone.dimensions.cols);
                      const col = index % zone.dimensions.cols;
                      const positionKey = `${row}-${col}`;
                      const isOccupied = !!zoneOccupancy[positionKey];
                      
                      return (
                        <PalletCell 
                          key={`${zone.id}-${positionKey}`} 
                          $occupied={isOccupied}
                        />
                      );
                    })}
                  </PalletGrid>
                  
                  <ZoneFooter>
                    <ZoneCapacity>
                      {zonePalletCounts[zone.id] || 0} / {capacity}
                    </ZoneCapacity>
                    <OccupancyBar>
                      <OccupancyFill percentage={occupancyPercentage} />
                    </OccupancyBar>
                  </ZoneFooter>
                </Zone>
              );
            })}
          </MapContent>
        )}
      </MapContainer>
    </Container>
  );
};

export default MobileStorageMap;

import React from 'react';
import styled from 'styled-components';
import { X } from 'lucide-react';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  animation: slideUp 0.3s ease;

  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ModalTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ModalContent = styled.div`
  margin-bottom: 20px;
`;

const RouteDescription = styled.p`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const RouteOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RouteOption = styled.button`
  padding: 14px 16px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
    border-color: ${({ theme }) => theme.colors.primary.light};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  &:active {
    transform: translateY(0);
  }
`;

const OptionTitle = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const OptionDescription = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const RouteSelectionModal = ({ isOpen, onClose, onSelectRoute, onUpdatePallet }) => {
  if (!isOpen) return null;

  const handleSelectRoute = async (routeId) => {
    // Update the pallet with the selected route
    if (onUpdatePallet) {
      try {
        console.log(`Updating pallet route to: ${routeId}`);
        // Only update the route property while preserving all other pallet details
        await onUpdatePallet({ route: routeId });
      } catch (error) {
        console.error('Error updating pallet route:', error);
      }
    }
    
    // Notify parent component about the selection
    if (onSelectRoute) {
      console.log(`Notifying parent component of selected route: ${routeId}`);
      onSelectRoute(routeId);
    }
    
    // Close the modal
    onClose();
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>Select Pallet Route</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={24} />
          </CloseButton>
        </ModalHeader>
        
        <ModalContent>
          <RouteDescription>
            Select where this pallet should be processed.
          </RouteDescription>
          
          <RouteOptions>
            <RouteOption onClick={() => handleSelectRoute('standard')}>
              <OptionTitle>Standard Processing</OptionTitle>
              <OptionDescription>
                For normal pallet processing operations.
              </OptionDescription>
            </RouteOption>
            
            <RouteOption onClick={() => handleSelectRoute('kleinpak')}>
              <OptionTitle>Kleinpak Processing</OptionTitle>
              <OptionDescription>
                For pallets that need specialized Kleinpak processing.
              </OptionDescription>
            </RouteOption>
          </RouteOptions>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default RouteSelectionModal;

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import kleinpakSSEManager from '../../../../../lib/services/sse/KleinpakSSEManager';
import { getEnhancedPalletsInScheduledRows } from '../../../../../lib/utils/rowStorageUtils';
import { CheckCircle, AlertTriangle, AlertCircle, QrCode, Layers } from 'lucide-react';
import {
  OrderCard as StyledOrderCard,
  OrderHeader,
  OrderTitle,
  OrderStatus,
  StatusBadge,
  OrderMeta,
  ActionButton
} from '../styles';
import ProgressTracker from './ProgressTracker';
import ZoneGroupedPallets from './ZoneGroupedPallets';
import RowGroupedPallets from './RowGroupedPallets';

// Get the order status badge
const getStatusBadge = (status) => {
  switch (status) {
    case 'processing':
      return (
        <StatusBadge status="processing">
          <AlertCircle size={14} />
          Processing
        </StatusBadge>
      );
    case 'ready':
      return (
        <StatusBadge status="ready">
          <CheckCircle size={14} />
          Ready
        </StatusBadge>
      );
    case 'problem':
      return (
        <StatusBadge status="problem">
          <AlertTriangle size={14} />
          Issue
        </StatusBadge>
      );
    default:
      return (
        <StatusBadge status="default">
          <AlertCircle size={14} />
          {status}
        </StatusBadge>
      );
  }
};

const OrderCard = ({ 
  order, 
  isActive,
  onZoneClick, 
  onScanToAssign, 
  onMarkReadyForShipping
}) => {
  // State to track the current number of processed pallets
  const [processedPallets, setProcessedPallets] = useState(0);
  
  // State to track the progress percentage
  const [progressPercentage, setProgressPercentage] = useState(0);
  
  // Maintain a record of pallet IDs assigned to this order with their process stage
  const [assignedPallets, setAssignedPallets] = useState({});

  // Enhanced helper function to get pallets located in scheduled rows
  const getPalletsInScheduledRows = useCallback((pallets, scheduledRows) => {
    if (!pallets || !scheduledRows || !Array.isArray(scheduledRows) || scheduledRows.length === 0) {
      console.log('OrderCard: No valid pallets or scheduledRows provided');
      return [];
    }
    
    // Add debug logs to see what data we're working with
    console.log('OrderCard: Debugging scheduled rows data:', scheduledRows.slice(0, 2).map(row => ({
      zoneId: row.zoneId,
      rowIndex: row.rowIndex,
      rowId: row.rowId || 'unknown'
    })));
    
    console.log('OrderCard: Sample pallet data:', pallets.slice(0, 2).map(p => ({
      id: p.id,
      storageZone: p.storageZone || 'none',
      zoneId: p.zoneId,
      rowIndex: p.rowIndex,
      position: p.position ? {
        zoneId: p.position.zoneId,
        row: p.position.row,
        rowIndex: p.position.rowIndex
      } : 'none'
    })));
    
    // Use our enhanced approach with standardized row keys and enable debug mode
    console.log('OrderCard: Using enhanced pallet-to-row matching with normalized keys...');
    const enhancedResults = getEnhancedPalletsInScheduledRows(pallets, scheduledRows, true);
    
    // If we didn't find any pallets with the standard approach, try a more permissive fallback
    if (enhancedResults.length === 0) {
      console.log('OrderCard: Standard matching found no pallets, trying more permissive matching...');
      
      // Custom direct fallback to manually match by zone and row
      const manualMatches = pallets.filter(pallet => {
        if (!pallet) return false;
        
        return scheduledRows.some(row => {
          // Try all possible ways the zone and row might be represented
          const zoneMatches = (
            (pallet.zoneId !== undefined && String(pallet.zoneId) === String(row.zoneId)) ||
            (pallet.position && pallet.position.zoneId !== undefined && 
             String(pallet.position.zoneId) === String(row.zoneId))
          );
          
          const rowMatches = (
            (pallet.rowIndex !== undefined && Number(pallet.rowIndex) === Number(row.rowIndex)) ||
            (pallet.position && 
             ((pallet.position.rowIndex !== undefined && Number(pallet.position.rowIndex) === Number(row.rowIndex)) ||
              (pallet.position.row !== undefined && Number(pallet.position.row) === Number(row.rowIndex))))
          );
          
          return zoneMatches && rowMatches;
        });
      });
      
      console.log(`OrderCard: Permissive matching found ${manualMatches.length} pallets in scheduled rows`);
      
      if (manualMatches.length > 0) {
        return manualMatches;
      }
    }
    
    console.log(`OrderCard: Enhanced matching found ${enhancedResults.length} pallets in scheduled rows out of ${pallets.length} total`);
    return enhancedResults;
  }, []);

  // Calculate the total scheduled pallets - combine row scheduling and allowed_pallet_ids
  const totalScheduledPallets = useMemo(() => {
    // Track unique pallets scheduled through any method
    const scheduledPalletIds = new Set();
    let totalCount = 0;
    
    // Determine if this is a row-scheduled order
    const hasScheduledRows = order.scheduled_rows && 
                             Array.isArray(order.scheduled_rows) && 
                             order.scheduled_rows.length > 0;
    
    // 1. First, get all pallets from scheduled rows
    if (hasScheduledRows && order.pallets && Array.isArray(order.pallets)) {
      // Count pallets actually in scheduled rows
      const palletsInRows = getPalletsInScheduledRows(order.pallets, order.scheduled_rows);
      
      console.log(`OrderCard: Row-scheduled order has ${palletsInRows.length} pallets in scheduled rows`);
      
      // Add these pallet IDs to our set of scheduled pallets
      palletsInRows.forEach(pallet => {
        scheduledPalletIds.add(String(pallet.id));
      });
      
      // If no pallets found in scheduled rows AND order.total_pallets is available, use it
      // This prevents inaccurate progress tracking when rows are empty
      if (palletsInRows.length === 0) {
        if (order.total_pallets) {
          console.log(`OrderCard: No pallets found in rows yet, using order.total_pallets: ${order.total_pallets}`);
          totalCount += order.total_pallets;
        } else {
          // Fallback to using row count, but with a minimum to prevent quick completion
          const minimumPalletsPerRow = 3;  // Set a reasonable minimum per row
          const estimatedCount = order.scheduled_rows.length * minimumPalletsPerRow;
          console.log(`OrderCard: No pallets in rows and no total_pallets, estimating: ${estimatedCount} (${minimumPalletsPerRow} per row)`);
          totalCount += estimatedCount;
        }
      }
    }
    
    // 2. Next, add individually scheduled pallets (allowed_pallet_ids)
    if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
      console.log(`OrderCard: Order has ${order.allowed_pallet_ids.length} individually allowed pallets`);
      
      // Track how many new pallets were added (not already counted from rows)
      let newIndividualPallets = 0;
      
      order.allowed_pallet_ids.forEach(palletId => {
        // Only count pallets not already in scheduled rows
        if (!scheduledPalletIds.has(String(palletId))) {
          scheduledPalletIds.add(String(palletId));
          newIndividualPallets++;
        }
      });
      
      console.log(`OrderCard: Added ${newIndividualPallets} new individually scheduled pallets`);
      totalCount += newIndividualPallets;
    }
    
    // If we have any scheduled pallets (from rows or individually allowed), use that count
    if (scheduledPalletIds.size > 0 || totalCount > 0) {
      const finalCount = Math.max(scheduledPalletIds.size, totalCount);
      console.log(`OrderCard: Combined scheduled pallets count: ${finalCount}`);
      return finalCount;
    }
    
    // Only if no scheduling information is available, fall back to total_pallets or order's pallets
    if (order.total_pallets) {
      console.log(`OrderCard: Using order.total_pallets: ${order.total_pallets}`);
      return order.total_pallets;
    } else if (order.pallets && Array.isArray(order.pallets)) {
      // Only count pallets that are assigned to THIS order
      const validPallets = order.pallets.filter(pallet => 
        !pallet.order_id || String(pallet.order_id) === String(order.id)
      );
      console.log(`OrderCard: Using count of valid pallets: ${validPallets.length}`);
      return validPallets.length;
    }
    
    console.log(`OrderCard: No scheduling information found, defaulting to 0 pallets`);
    return 0;
  }, [order.scheduled_rows, order.allowed_pallet_ids, order.total_pallets, order.pallets, order.id, getPalletsInScheduledRows]);
  
  // Initialize with values from props for immediate display
  useEffect(() => {
    // Initial calculation
    if (order.pallets && Array.isArray(order.pallets)) {
      // First, filter to only include pallets actually assigned to this order
      let orderPallets = order.pallets.filter(pallet => 
        !pallet.order_id || String(pallet.order_id) === String(order.id)
      );
      
      // If we have allowed_pallet_ids, further filter to only include pallets in that list
      let relevantPallets = orderPallets;
      if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
        relevantPallets = orderPallets.filter(pallet => 
          order.allowed_pallet_ids.includes(pallet.id) || 
          order.allowed_pallet_ids.includes(String(pallet.id))
        );
      }
      
      const processingPallets = relevantPallets.filter(pallet => 
        pallet.isScanned || pallet.processStage === 'Processing'
      );
      
      setProcessedPallets(processingPallets.length);
      
      // Create initial assigned pallets map
      const initialAssignedPallets = {};
      processingPallets.forEach(pallet => {
        // Handle potential inconsistencies in field names (colli_count, box_count, boxCount)
        const boxCount = pallet.colli_count !== undefined ? 
                        pallet.colli_count : 
                        (pallet.box_count !== undefined ? 
                            pallet.box_count : 
                            (pallet.boxCount !== undefined ? 
                                pallet.boxCount : 0));
        
        initialAssignedPallets[pallet.id] = {
          processStage: pallet.processStage || 'Unprocessed',
          orderId: String(order.id),
          box_count: boxCount,
          colli_count: boxCount // Add both field names for consistency
        };
      });
      
      setAssignedPallets(initialAssignedPallets);
      
      // Calculate initial progress percentage based on scheduled pallets
      setProgressPercentage(totalScheduledPallets > 0 ? (processingPallets.length / totalScheduledPallets) * 100 : 0);
    }
  }, [order.id, order.allowed_pallet_ids, totalScheduledPallets, order.pallets]);
  
  // Subscribe to SSE updates when the component mounts
  useEffect(() => {
    if (!order || !order.id) return;
    
    // Get the location ID from order if available
    const locationId = order.location_id || order.locationId;
    if (!locationId) {
      console.error('LocationId not available in order data');
      return;
    }
    
    // Initialize the SSE connection for this location
    kleinpakSSEManager.initialize(locationId);
    
    // Subscribe to order updates
    const orderSubscriptionId = kleinpakSSEManager.subscribe('orderUpdate', (data) => {
      if (!data || !data.orders) return;
      
      // Find updates for this specific order
      const orderUpdate = data.orders.find(o => String(o.id) === String(order.id));
      if (!orderUpdate) return;
      
      console.log('Received order update for OrderCard:', orderUpdate);
      
      // If the order has updated progress or pallet count information, update our state
      if (orderUpdate.processing_pallets !== undefined || orderUpdate.assigned_pallets !== undefined) {
        const processingCount = orderUpdate.processing_pallets || 0;
        setProcessedPallets(processingCount);
        
        // Update progress percentage based on scheduled pallets
        setProgressPercentage(totalScheduledPallets > 0 ? (processingCount / totalScheduledPallets) * 100 : 0);
      }
    });
    
    // Subscribe to pallet assignment updates
    const assignmentSubscriptionId = kleinpakSSEManager.subscribe('assignmentUpdate', (data) => {
      if (!data || (!data.palletIds && !data.palletId)) return;
      
      // Ensure we have an array of pallet IDs
      const palletIds = data.palletIds || [data.palletId];
      const orderId = String(data.orderId);
      
      // Only process if the assignment is for this order and pallets are in allowed_pallet_ids (if any)
      if (orderId === String(order.id)) {
        console.log(`Received assignment update for OrderCard: ${palletIds.length} pallets assigned to order ${orderId}`);
        
        // Filter pallets to only include allowed ones (if applicable)
        let relevantPalletIds = palletIds;
        if (order.allowed_pallet_ids && Array.isArray(order.allowed_pallet_ids) && order.allowed_pallet_ids.length > 0) {
          relevantPalletIds = palletIds.filter(id => 
            order.allowed_pallet_ids.includes(id) || 
            order.allowed_pallet_ids.includes(String(id))
          );
        }
        
        // Update our record of assigned pallets
        setAssignedPallets(prev => {
          const updated = { ...prev };
          relevantPalletIds.forEach(palletId => {
            updated[palletId] = {
              processStage: 'Processing', // Assume Processing state for newly assigned pallets
              orderId
            };
          });
          return updated;
        });
        
        // Update our processed pallets count
        if (relevantPalletIds.length > 0) {
          setProcessedPallets(prev => {
            const newCount = prev + relevantPalletIds.length;
            
            // Also update the progress percentage based on scheduled pallets
            setProgressPercentage(totalScheduledPallets > 0 ? (newCount / totalScheduledPallets) * 100 : 0);
            
            return newCount;
          });
        }
      }
    });
    
    // Subscribe to pallet updates for status changes
    const palletSubscriptionId = kleinpakSSEManager.subscribe('palletUpdate', (data) => {
      if (!data || !data.pallets || !Array.isArray(data.pallets)) return;
      
      // Check if any of the updated pallets belong to this order
      const relevantPallets = data.pallets.filter(pallet => 
        String(pallet.orderId) === String(order.id) || 
        String(pallet.order_id) === String(order.id)
      );
      
      if (relevantPallets.length === 0) return;
      
      console.log(`Received pallet updates for OrderCard: ${relevantPallets.length} pallets updated for order ${order.id}`);
      
      // Process each relevant pallet update
      let needsProgressUpdate = false;
      
      setAssignedPallets(prev => {
        const updated = { ...prev };
        
        relevantPallets.forEach(pallet => {
          const palletId = pallet.id;
          const processStage = pallet.processStage;
          const wasProcessing = prev[palletId]?.processStage === 'Processing';
          const isNowProcessing = processStage === 'Processing';
          
          // Update our record
          updated[palletId] = {
            processStage,
            orderId: String(order.id)
          };
          
          // If processing state has changed, we need to update our counts
          if (wasProcessing !== isNowProcessing) {
            needsProgressUpdate = true;
          }
        });
        
        return updated;
      });
      
      // If any pallet's processing state changed, update our progress metrics
      if (needsProgressUpdate) {
        setAssignedPallets(currentAssigned => {
          // Count pallets that are in Processing state
          const processingCount = Object.values(currentAssigned)
            .filter(p => p.orderId === String(order.id) && p.processStage === 'Processing')
            .length;
          
          setProcessedPallets(processingCount);
          
          // Update progress percentage based on scheduled pallets
          setProgressPercentage(totalScheduledPallets > 0 ? (processingCount / totalScheduledPallets) * 100 : 0);
          
          return currentAssigned;
        });
      }
    });
    
    // Clean up subscriptions when component unmounts
    return () => {
      kleinpakSSEManager.unsubscribe('orderUpdate', orderSubscriptionId);
      kleinpakSSEManager.unsubscribe('assignmentUpdate', assignmentSubscriptionId);
      kleinpakSSEManager.unsubscribe('palletUpdate', palletSubscriptionId);
    };
  }, [order.id, order.location_id, order.locationId, order.total_pallets, order.pallets]);
  // Determine if this order uses scheduled rows
  const hasScheduledRows = useMemo(() => {
    return order.scheduled_rows && Array.isArray(order.scheduled_rows) && order.scheduled_rows.length > 0;
  }, [order.scheduled_rows]);

  // Handle row selection for row-based navigation
  const handleRowClick = (zoneName, row, order) => {
    // First navigate to the zone, then we'll handle row selection in the map view
    if (onZoneClick) {
      // Log what's being sent to make debugging easier
      console.log('OrderCard: Navigating to row', {
        zoneName,
        rowIndex: row.rowIndex,
        zoneId: row.zoneId,
        rowId: row.id
      });
      
      onZoneClick(zoneName, [row], order, { 
        selectedRow: row,
        // Send a lookup key consistent with our new filtering approach
        lookupKey: `${row.zoneId}-${row.rowIndex}`,
        // Add a flag to indicate this is a row-based navigation
        isRowBasedNavigation: true
      });
    }
  };

  return (
    <StyledOrderCard>
      <OrderHeader>
        <OrderTitle>
          Order #{order.orderNumber || String(order.id).substring(0, 8)}
        </OrderTitle>
        <OrderStatus>
          {getStatusBadge(order.status)}
        </OrderStatus>
      </OrderHeader>
      
      <OrderMeta>
        <div>
          <span>Customer: </span>
          <strong>{order.customer_name || order.customerName || 'N/A'}</strong>
        </div>
        <div>
          <span>Scheduled Pallets: </span>
          <strong>{totalScheduledPallets}</strong>
        </div>
        {order.processingLine && (
          <div>
            <span>Line: </span>
            <strong>{order.processingLine}</strong>
          </div>
        )}
        {hasScheduledRows && (
          <div>
            <span>Storage Type: </span>
            <strong>Row-Based</strong>
          </div>
        )}
        {order.additional_information && (
          <div>
            <span>Additional Info: </span>
            <strong>{order.additional_information}</strong>
          </div>
        )}
      </OrderMeta>

      {/* Use our calculated values based on scheduled pallets */}
      <ProgressTracker 
        processedPallets={processedPallets} 
        totalPallets={totalScheduledPallets} 
        progressPercentage={progressPercentage} 
      />
      
      {isActive && (
        <>
          {/* Scheduled Storage View - either Row-based or Pallet-based */}
          <div style={{ marginTop: '16px' }}>
            <div style={{ 
              marginBottom: '12px',
              padding: '0 4px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <h4 style={{ margin: '0' }}>
                {hasScheduledRows ? (
                  <>
                    <Layers size={16} style={{ marginRight: '6px', verticalAlign: 'text-bottom' }} />
                    Scheduled Storage Rows
                  </>
                ) : (
                  <>
                    <QrCode size={16} style={{ marginRight: '6px', verticalAlign: 'text-bottom' }} />
                    Scheduled Pallets by Zone
                  </>
                )}
              </h4>
            </div>

            {/* Show either row-based or pallet-based view */}
            {hasScheduledRows ? (
              <RowGroupedPallets 
                order={order} 
                onZoneClick={onZoneClick}
                onRowClick={handleRowClick}
              />
            ) : (
              <ZoneGroupedPallets 
                order={order} 
                onZoneClick={onZoneClick} 
              />
            )}
            
            {/* Add debug info in development mode */}
            {process.env.NODE_ENV === 'development' && (
              <div style={{
                marginTop: '16px',
                padding: '8px',
                backgroundColor: '#f8f9fa',
                borderRadius: '4px',
                fontSize: '12px',
                color: '#6c757d'
              }}>
                <details>
                  <summary style={{ cursor: 'pointer', fontWeight: 'bold' }}>Debug Info</summary>
                  <div style={{ marginTop: '8px' }}>
                    <p>Order ID: {order.id}</p>
                    <p>Has Scheduled Rows: {hasScheduledRows ? 'Yes' : 'No'}</p>
                    <p>Scheduled Rows: {order.scheduled_rows?.length || 0}</p>
                    <p>Allowed Pallet IDs: {order.allowed_pallet_ids?.length || 0}</p>
                    <p>Total Pallets in Location: {order.pallets?.length || 0}</p>
                  </div>
                </details>
              </div>
            )}
          </div>
          
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
            <ActionButton 
              variant="primary"
              onClick={(e) => {
                e.stopPropagation();
                onScanToAssign(order.id);
              }}
            >
              <QrCode size={16} />
              Scan Pallet
            </ActionButton>
            
            <ActionButton 
              variant="success"
              onClick={(e) => {
                e.stopPropagation();
                console.log("Mark ready button clicked with order:", order);
                onMarkReadyForShipping(order.id, order);
              }}
            >
              <CheckCircle size={16} />
              Mark Ready for Shipping
            </ActionButton>
          </div>
        </>
      )}
    </StyledOrderCard>
  );
};

export default OrderCard;

/**
 * API Error Handler and Circuit Breaker Utility
 * 
 * This module provides centralized error handling, circuit breaker pattern,
 * and advanced retry mechanisms for API requests.
 */

// Circuit breaker states
const CIRCUIT_STATES = {
  CLOSED: 'CLOSED',      // Normal operation - requests flow through
  OPEN: 'OPEN',          // Circuit is open - requests fail fast
  HALF_OPEN: 'HALF_OPEN' // Testing if service has recovered
};

// Error categories for better user feedback
export const ERROR_CATEGORIES = {
  NETWORK: 'NETWORK',           // Network connectivity issues
  SERVER: 'SERVER',             // Server errors (500 range)
  AUTHENTICATION: 'AUTH',       // Authentication issues (401, 403)
  VALIDATION: 'VALIDATION',     // Bad request, validation errors (400)
  RESOURCE: 'RESOURCE',         // Resource not found (404)
  CONFLICT: 'CONFLICT',         // Conflict with current state (409)
  TIMEOUT: 'TIMEOUT',           // Request timeout
  UNKNOWN: 'UNKNOWN'            // Uncategorized errors
};

// In-memory storage fallback for environments without localStorage
const createInMemoryStorage = () => {
  const store = {};
  return {
    getItem: (key) => store[key] || null,
    setItem: (key, value) => { store[key] = value; },
    removeItem: (key) => { delete store[key]; }
  };
};

// Check if localStorage is available
const isLocalStorageAvailable = () => {
  try {
    const testKey = '__storage_test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

// Use localStorage if available, otherwise use in-memory fallback
const getStorage = () => {
  return isLocalStorageAvailable() ? localStorage : createInMemoryStorage();
};

// Default circuit breaker settings
const DEFAULT_CIRCUIT_SETTINGS = {
  failureThreshold: 5,           // Number of failures before opening circuit
  resetTimeout: 30000,           // Time to wait before trying again (ms)
  halfOpenMaxRequests: 3,        // Number of requests allowed in half-open state
  requestTimeout: 10000,         // Request timeout (ms)
  healthCheckInterval: 10000,    // How often to check API health (ms)
  storage: getStorage(),         // Where to store circuit state - safely handles when localStorage isn't available
  storageKey: 'api_circuit_data' // Key for storing circuit state
};

// Default retry settings
const DEFAULT_RETRY_SETTINGS = {
  maxRetries: 3,
  retryStatusCodes: [408, 429, 500, 502, 503, 504],
  retryMethods: ['GET', 'HEAD', 'OPTIONS', 'PUT', 'DELETE', 'PATCH'],
  // Don't retry POST by default to avoid duplicate creation
  retryDelay: attempt => Math.min(1000 * Math.pow(2, attempt), 10000) // Exponential backoff with 10s max
};

// In-memory storage fallback for cache when sessionStorage isn't available
const createInMemoryCacheStorage = () => {
  const store = {};
  return {
    getItem: (key) => store[key] || null,
    setItem: (key, value) => { store[key] = value; },
    removeItem: (key) => { delete store[key]; }
  };
};

// Check if sessionStorage is available
const isSessionStorageAvailable = () => {
  try {
    const testKey = '__storage_test__';
    sessionStorage.setItem(testKey, testKey);
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

// Use sessionStorage if available, otherwise use in-memory fallback
const getCacheStorage = () => {
  return isSessionStorageAvailable() ? sessionStorage : createInMemoryCacheStorage();
};

// Cache settings with TTL (time to live)
const DEFAULT_CACHE_SETTINGS = {
  enabled: true,
  storage: getCacheStorage(),
  defaultTTL: 5 * 60 * 1000, // 5 minutes in milliseconds
  bypassQueryParams: ['timestamp', 'no_cache', 'nocache', 'refresh']
};

// State tracking for the circuit breaker
let circuitState = {
  state: CIRCUIT_STATES.CLOSED,
  failures: 0,
  lastFailure: null,
  lastTest: null,
  successfulTestAttempts: 0
};

/**
 * Initialize the circuit breaker from saved state
 * @param {Object} settings - Circuit breaker settings
 */
const initCircuit = (settings = DEFAULT_CIRCUIT_SETTINGS) => {
  try {
    // Verify storage is available before using it
    if (!settings.storage) {
      console.warn('Storage not available for circuit breaker, using defaults');
      resetCircuit(settings);
      return;
    }
    
    const savedState = settings.storage.getItem(settings.storageKey);
    if (savedState) {
      const parsed = JSON.parse(savedState);
      // Only restore if the state isn't too old
      const maxAge = 24 * 60 * 60 * 1000; // 24 hours
      if (parsed.lastFailure && (Date.now() - parsed.lastFailure < maxAge)) {
        circuitState = parsed;
        console.log(`API Circuit state restored: ${circuitState.state} with ${circuitState.failures} failures`);
      } else {
        // Reset if the state is too old
        resetCircuit(settings);
      }
    }
  } catch (error) {
    console.error('Error initializing circuit breaker:', error);
    resetCircuit(settings);
  }
};

/**
 * Reset the circuit breaker to closed state
 * @param {Object} settings - Circuit breaker settings
 */
const resetCircuit = (settings = DEFAULT_CIRCUIT_SETTINGS) => {
  circuitState = {
    state: CIRCUIT_STATES.CLOSED,
    failures: 0,
    lastFailure: null,
    lastTest: null,
    successfulTestAttempts: 0
  };
  
  try {
    // Verify storage is available before using it
    if (!settings.storage) {
      console.warn('Storage not available for circuit breaker, state will not persist');
      return;
    }
    
    settings.storage.setItem(settings.storageKey, JSON.stringify(circuitState));
  } catch (error) {
    console.error('Error saving circuit state:', error);
  }
};

/**
 * Update the circuit breaker state based on request results
 * @param {boolean} success - Whether the request was successful
 * @param {Object} settings - Circuit breaker settings
 */
const updateCircuitState = (success, settings = DEFAULT_CIRCUIT_SETTINGS) => {
  if (success) {
    switch (circuitState.state) {
      case CIRCUIT_STATES.CLOSED:
        // Reset failures counter on success in closed state
        if (circuitState.failures > 0) {
          circuitState.failures = 0;
          saveCircuitState(settings);
        }
        break;
        
      case CIRCUIT_STATES.HALF_OPEN:
        // Track successful test attempts in half-open state
        circuitState.successfulTestAttempts += 1;
        if (circuitState.successfulTestAttempts >= settings.halfOpenMaxRequests) {
          // Service has recovered, close the circuit
          circuitState.state = CIRCUIT_STATES.CLOSED;
          circuitState.failures = 0;
          circuitState.successfulTestAttempts = 0;
          console.log('API Circuit closed - service has recovered');
        }
        saveCircuitState(settings);
        break;
    }
  } else {
    switch (circuitState.state) {
      case CIRCUIT_STATES.CLOSED:
        // Increment failure counter
        circuitState.failures += 1;
        circuitState.lastFailure = Date.now();
        
        if (circuitState.failures >= settings.failureThreshold) {
          // Too many failures, open the circuit
          circuitState.state = CIRCUIT_STATES.OPEN;
          console.warn(`API Circuit opened after ${circuitState.failures} failures`);
        }
        saveCircuitState(settings);
        break;
        
      case CIRCUIT_STATES.HALF_OPEN:
        // Failed in testing state, reopen the circuit
        circuitState.state = CIRCUIT_STATES.OPEN;
        circuitState.lastFailure = Date.now();
        circuitState.successfulTestAttempts = 0;
        console.warn('API Circuit reopened after failed test');
        saveCircuitState(settings);
        break;
    }
  }
};

/**
 * Save the circuit state to storage
 * @param {Object} settings - Circuit breaker settings
 */
const saveCircuitState = (settings = DEFAULT_CIRCUIT_SETTINGS) => {
  try {
    // Verify storage is available before using it
    if (!settings.storage) {
      console.warn('Storage not available for circuit breaker, state will not persist');
      return;
    }
    
    settings.storage.setItem(settings.storageKey, JSON.stringify(circuitState));
  } catch (error) {
    console.error('Error saving circuit state:', error);
  }
};

/**
 * Check if the circuit should transition from open to half-open
 * @param {Object} settings - Circuit breaker settings
 */
const checkCircuitTransition = (settings = DEFAULT_CIRCUIT_SETTINGS) => {
  if (circuitState.state === CIRCUIT_STATES.OPEN) {
    const now = Date.now();
    if ((now - circuitState.lastFailure) > settings.resetTimeout) {
      // Time has passed, allow a test request
      circuitState.state = CIRCUIT_STATES.HALF_OPEN;
      circuitState.lastTest = now;
      circuitState.successfulTestAttempts = 0;
      console.log('API Circuit half-open - testing service recovery');
      saveCircuitState(settings);
    }
  }
};

/**
 * Categorize errors for better user feedback and logging
 * @param {Error} error - The error to categorize
 * @returns {string} Error category
 */
export const categorizeError = (error) => {
  // Network errors
  if (!error.response && error.message === 'Network Error') {
    return ERROR_CATEGORIES.NETWORK;
  }
  
  // Request cancelled or timeout
  if (error.code === 'ECONNABORTED' || error.message?.includes('timeout')) {
    return ERROR_CATEGORIES.TIMEOUT;
  }
  
  // Server provided a response with an error status
  if (error.response) {
    const { status } = error.response;
    
    if (status >= 500) return ERROR_CATEGORIES.SERVER;
    if (status === 401 || status === 403) return ERROR_CATEGORIES.AUTHENTICATION;
    if (status === 400) return ERROR_CATEGORIES.VALIDATION;
    if (status === 404) return ERROR_CATEGORIES.RESOURCE;
    if (status === 409) return ERROR_CATEGORIES.CONFLICT;
  }
  
  return ERROR_CATEGORIES.UNKNOWN;
};

/**
 * Get a user-friendly error message based on the error category
 * @param {string} category - Error category
 * @param {Error} error - The original error
 * @returns {string} User-friendly error message
 */
export const getUserFriendlyErrorMessage = (category, error) => {
  const defaultMessages = {
    [ERROR_CATEGORIES.NETWORK]: 'Network connection issue. Please check your internet connection and try again.',
    [ERROR_CATEGORIES.SERVER]: 'The server is experiencing issues. Our team has been notified.',
    [ERROR_CATEGORIES.AUTHENTICATION]: 'Your session may have expired. Please log in again.',
    [ERROR_CATEGORIES.VALIDATION]: error.response?.data?.message || 'Please check your input and try again.',
    [ERROR_CATEGORIES.RESOURCE]: 'The requested item could not be found.',
    [ERROR_CATEGORIES.CONFLICT]: 'This operation conflicts with the current state.',
    [ERROR_CATEGORIES.TIMEOUT]: 'The request timed out. Please try again.',
    [ERROR_CATEGORIES.UNKNOWN]: 'An unexpected error occurred. Please try again.'
  };
  
  // Storage-specific error messages
  if (error.message?.includes('already occupied')) {
    return 'This storage position is already occupied by another pallet.';
  }
  
  if (error.message?.includes('not found') && category === ERROR_CATEGORIES.RESOURCE) {
    return 'The requested storage location or pallet could not be found.';
  }
  
  // Position-specific errors
  if (error.code === 'POSITION_OCCUPIED') {
    return `Position is already occupied in zone ${error.zoneId}.`;
  }
  
  return defaultMessages[category] || defaultMessages[ERROR_CATEGORIES.UNKNOWN];
};

/**
 * Generate a cache key for a request
 * @param {string} url - Request URL
 * @param {Object} params - Request parameters
 * @param {string} method - HTTP method
 * @returns {string} Cache key
 */
const generateCacheKey = (url, params, method) => {
  if (method !== 'GET') return null; // Only cache GET requests
  
  const queryString = params ? `?${new URLSearchParams(params).toString()}` : '';
  return `${url}${queryString}`;
};

/**
 * Check if a request should bypass cache
 * @param {Object} params - Request parameters
 * @param {Object} cacheSettings - Cache settings
 * @returns {boolean} Whether to bypass cache
 */
const shouldBypassCache = (params, cacheSettings = DEFAULT_CACHE_SETTINGS) => {
  if (!params) return false;
  
  return cacheSettings.bypassQueryParams.some(param => {
    return params[param] !== undefined;
  });
};

/**
 * Execute a request with circuit breaker, retry, and caching
 * @param {Function} requestFn - Function that performs the request
 * @param {Object} options - Options for circuit breaker, retry, and caching
 * @returns {Promise} Request result
 */
export const executeWithCircuitBreaker = async (
  requestFn,
  {
    circuitSettings = DEFAULT_CIRCUIT_SETTINGS,
    retrySettings = DEFAULT_RETRY_SETTINGS,
    cacheSettings = DEFAULT_CACHE_SETTINGS,
    url = '',
    method = 'GET',
    params = {},
    payload = {},
    onError = null
  } = {}
) => {
  // Initialize circuit if needed
  initCircuit(circuitSettings);
  
  // Check if circuit should transition from open to half-open
  checkCircuitTransition(circuitSettings);
  
  // Check if circuit is open and should fail fast
  if (circuitState.state === CIRCUIT_STATES.OPEN) {
    // Allow health check requests to go through even when circuit is open
    const isHealthCheck = url.includes('/health') || url.includes('/ping');
    
    if (!isHealthCheck) {
      console.warn('API Circuit is open, failing fast');
      const error = new Error('Service unavailable - circuit breaker is open');
      error.category = ERROR_CATEGORIES.SERVER;
      error.circuitOpen = true;
      throw error;
    }
  }
  
  // Check cache for GET requests
  if (cacheSettings.enabled && method === 'GET' && !shouldBypassCache(params, cacheSettings)) {
    const cacheKey = generateCacheKey(url, params, method);
    if (cacheKey) {
      const cachedData = cacheSettings.storage.getItem(cacheKey);
      if (cachedData) {
        try {
          const { data, timestamp, ttl } = JSON.parse(cachedData);
          const now = Date.now();
          
          // Check if cache is still valid
          if (timestamp && now - timestamp < (ttl || cacheSettings.defaultTTL)) {
            console.log(`Using cached data for ${cacheKey}`);
            return data;
          } else {
            // Cache expired, remove it
            cacheSettings.storage.removeItem(cacheKey);
          }
        } catch (error) {
          console.error('Error parsing cached data:', error);
        }
      }
    }
  }
  
  // Implement retry logic
  let attempt = 0;
  let lastError = null;
  
  while (attempt <= retrySettings.maxRetries) {
    try {
      const result = await requestFn();
      
      // Update circuit breaker state
      updateCircuitState(true, circuitSettings);
      
      // Cache successful GET responses
      if (cacheSettings.enabled && method === 'GET' && !shouldBypassCache(params, cacheSettings)) {
        const cacheKey = generateCacheKey(url, params, method);
        if (cacheKey) {
          try {
            // Support custom TTL via Cache-Control header
            let ttl = cacheSettings.defaultTTL;
            if (result.headers && result.headers['cache-control']) {
              const maxAge = result.headers['cache-control'].match(/max-age=(\d+)/);
              if (maxAge && maxAge[1]) {
                ttl = parseInt(maxAge[1], 10) * 1000; // Convert seconds to milliseconds
              }
            }
            
            const cacheData = JSON.stringify({
              data: result,
              timestamp: Date.now(),
              ttl
            });
            
            cacheSettings.storage.setItem(cacheKey, cacheData);
          } catch (error) {
            console.error('Error caching response:', error);
          }
        }
      }
      
      return result;
      
    } catch (error) {
      lastError = error;
      
      // Categorize the error
      const category = categorizeError(error);
      lastError.category = category;
      
      // Update circuit breaker state
      updateCircuitState(false, circuitSettings);
      
      // Call onError callback if provided
      if (onError) {
        try {
          onError(lastError, attempt);
        } catch (callbackError) {
          console.error('Error in onError callback:', callbackError);
        }
      }
      
      // Determine if we should retry
      const status = error.response?.status;
      const shouldRetry = (
        attempt < retrySettings.maxRetries &&
        (
          // Retry based on status code
          (status && retrySettings.retryStatusCodes.includes(status)) ||
          // Retry network errors
          (!status && category === ERROR_CATEGORIES.NETWORK) ||
          // Retry timeouts
          (category === ERROR_CATEGORIES.TIMEOUT)
        ) &&
        // Only retry for specified methods
        retrySettings.retryMethods.includes(method.toUpperCase())
      );
      
      if (shouldRetry) {
        // Wait before retrying with exponential backoff
        const delay = retrySettings.retryDelay(attempt);
        console.warn(`Retrying request (attempt ${attempt + 1}/${retrySettings.maxRetries + 1}) after ${delay}ms`);
        await new Promise(resolve => setTimeout(resolve, delay));
        attempt += 1;
      } else {
        // Don't retry
        break;
      }
    }
  }
  
  // If we get here, all retries failed
  if (lastError) {
    // Add user-friendly message
    lastError.userMessage = getUserFriendlyErrorMessage(lastError.category, lastError);
    throw lastError;
  }
  
  // This should never happen
  throw new Error('Unknown error in circuit breaker');
};

// Export default settings to allow customization
export const settings = {
  circuit: DEFAULT_CIRCUIT_SETTINGS,
  retry: DEFAULT_RETRY_SETTINGS,
  cache: DEFAULT_CACHE_SETTINGS
};

// Initialize the circuit
initCircuit();

export default {
  executeWithCircuitBreaker,
  categorizeError,
  getUserFriendlyErrorMessage,
  resetCircuit,
  settings,
  ERROR_CATEGORIES
};

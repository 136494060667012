import React, { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { 
  Clipboard, 
  Package, 
  AlertTriangle,
  RefreshCw 
} from 'lucide-react';
import MarkOrderReadyModal from './components/MarkOrderReadyModal';
import { useToast } from '@lib/contexts';
import { useProcessing } from '@lib/contexts';
import QRScanner from '@features/shared/components/QRScanner';
import PalletLocationWorkflow from './PalletLocationWorkflow';
import RowLocationWorkflow from './RowLocationWorkflow';
import useScrollPosition from '@lib/hooks/useScrollPosition';
import kleinpakSSEManager from '../../../../lib/services/sse/KleinpakSSEManager';

// Import custom hooks
import useOrderData from './hooks/useOrderData';
import usePalletActions from './hooks/usePalletActions';
import useOrderActions from './hooks/useOrderActions';

// Import components
import OrderCard from './components/OrderCard';

import {
  Container,
  Header,
  Title,
  SubTitle,
  ErrorMessage,
  DashboardGrid,
  OrderSection,
  EmptyState,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  SaveButton,
  CancelButton
} from './styles';

const KleinpakDashboard = () => {
  const { locationId, userId } = useOutletContext();
  const { showToast } = useToast();
  const { showProcessing, hideProcessing } = useProcessing();
  const dashboardRef = useRef(null);
  
  // Use scroll position preservation
  const { restoreScrollPosition } = useScrollPosition(
    `kleinpak_dashboard_${locationId}_${userId}`, 
    true
  );
  
  // Use data fetching hook with enhanced persistence features
  const {
    orders,
    setOrders,
    loading,
    error,
    isRefreshing,
    activeOrderId,
    setActiveOrderId,
    fetchOrders,
    scannedPallets,
    addScannedPallet,
    clearScannedPalletsForOrder
  } = useOrderData(locationId, userId);
  
  // Restore scroll position after data loading or refresh
  useEffect(() => {
    if (!loading && !isRefreshing) {
      // Short timeout to ensure DOM has updated
      setTimeout(restoreScrollPosition, 100);
    }
  }, [loading, isRefreshing, restoreScrollPosition]);
  
  // Use pallet actions hook
  const {
    showUpdateModal,
    selectedPallet,
    newBoxCount,
    updateError,
    showScannerModal,
    scanTargetOrderId,
    setShowUpdateModal,
    setNewBoxCount,
    setShowScannerModal,
    setScanTargetOrderId,
    handleUpdateBoxCount,
    handleSaveBoxCount,
    handleScanToAssign,
    handleScanResult,
    handleManualPalletInput,
    handleCloseScanner
  } = usePalletActions(locationId, setOrders, showToast, showProcessing, hideProcessing);
  
  // Use order actions hook
  const {
    showZoneWorkflow,
    selectedZone,
    selectedZonePallets,
    selectedOrder,
    showMarkReadyModal,
    orderToMarkReady,
    lastScannedPallet,
    otherOrdersWithSamePallet,
    isRowBasedNavigation,
    selectedRow,
    setShowZoneWorkflow,
    setShowMarkReadyModal,
    handleZoneClick,
    handleOrderReadyForShipping,
    handleMarkReadyConfirm,
    handleWorkflowScanComplete
  } = useOrderActions(locationId, showToast, showProcessing, hideProcessing);
  
  // Handle successful scan completion
  const onWorkflowScanComplete = async (palletId) => {
    const success = await handleWorkflowScanComplete(palletId);
    if (success) {
      // Track the scanned pallet
      addScannedPallet(palletId);
      // Refresh the dashboard to show the assigned pallet
      await fetchOrders();
    }
  };
  
  // Handle order ready shipping modal confirmation
  const onMarkReadyConfirm = async (formData) => {
    const success = await handleMarkReadyConfirm(formData);
    if (success) {
      // Update local state - remove the order from the list
      setOrders(prevOrders => 
        prevOrders.filter(order => order.id !== orderToMarkReady.id)
      );
    }
  };
  
  // Prepare to mark order as ready (collect information first)
  const onOrderReadyForShipping = async (orderId, orderData) => {
    console.log("KleinpakDashboard: Preparing to mark order ready:", { orderId, orderData });
    
    // Make sure we're passing both orderId and orderData
    await handleOrderReadyForShipping(orderId, orderData);
    // Note: The modal showing is now handled inside handleOrderReadyForShipping
  };

  if (loading) {
    return (
      <Container>
        <Header>
          <div>
            <Title>
              <Clipboard size={20} />
              Kleinpak Order Dashboard
            </Title>
            <SubTitle>Track and manage your in-process orders</SubTitle>
          </div>
        </Header>
        <EmptyState>
          <div className="loading-spinner"></div>
          <p>Loading your active orders...</p>
        </EmptyState>
      </Container>
    );
  }
  
  if (error) {
    return (
      <Container>
        {/* Only display the title when there are no orders */}
        <Header>
          <div>
            <Title>
              <Clipboard size={20} />
              Kleinpak Order Dashboard
            </Title>
            <SubTitle>
              Track and manage your in-process orders
              <span style={{ 
                fontSize: '0.8rem', 
                opacity: 0.7, 
                marginLeft: '12px', 
                fontStyle: 'italic' 
              }}>
                (Real-time updates enabled)
              </span>
            </SubTitle>
          </div>
        </Header>
        <ErrorMessage>
          <AlertTriangle size={18} />
          {error}
        </ErrorMessage>
      </Container>
    );
  }
  
  if (orders.length === 0) {
    return (
      <Container>
        <Header>
          <div>
            <Title>
              <Clipboard size={20} />
              Kleinpak Order Dashboard
            </Title>
            <SubTitle>
              Track and manage your in-process orders
              <span style={{ 
                fontSize: '0.8rem', 
                opacity: 0.7, 
                marginLeft: '12px', 
                fontStyle: 'italic' 
              }}>
                (Real-time updates enabled)
              </span>
            </SubTitle>
          </div>
        </Header>
        <EmptyState>
          <Package size={48} />
          <p>No active orders found.</p>
          <p>Your orders will appear here.</p>
        </EmptyState>
      </Container>
    );
  }
  
  return (
    <Container ref={dashboardRef}>
      {/* Removing header when orders are present so user can focus on orders */}
      
      <DashboardGrid>
        {orders.map(order => (
          <OrderSection 
            key={order.id}
            active={order.id === activeOrderId}
            onClick={() => setActiveOrderId(order.id)}
          >
            <OrderCard 
              order={order}
              isActive={order.id === activeOrderId}
              onZoneClick={handleZoneClick}
              onScanToAssign={handleScanToAssign}
              onMarkReadyForShipping={onOrderReadyForShipping}
            />
          </OrderSection>
        ))}
      </DashboardGrid>
      
      {/* Box Count Update Modal */}
      {showUpdateModal && selectedPallet && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              Update Box Count
              <ModalCloseButton onClick={() => setShowUpdateModal(false)}>
                ×
              </ModalCloseButton>
            </ModalHeader>
            <ModalBody>
              <p>Update the number of boxes for pallet #{String(selectedPallet.id).substring(0, 8)}</p>
              
              <Label>
                Current Box Count: {selectedPallet.boxCount || 'Not set'}
              </Label>
              
              <Label>
                New Box Count:
                <Input
                  type="number"
                  min="0"
                  value={newBoxCount}
                  onChange={(e) => setNewBoxCount(e.target.value)}
                  placeholder="Enter new box count"
                />
              </Label>
              
              {updateError && (
                <ErrorMessage>
                  <AlertTriangle size={16} />
                  {updateError}
                </ErrorMessage>
              )}
            </ModalBody>
            <ModalFooter>
              <CancelButton onClick={() => setShowUpdateModal(false)}>
                Cancel
              </CancelButton>
              <SaveButton onClick={handleSaveBoxCount}>
                Save Changes
              </SaveButton>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      )}
      
      {/* QR Scanner Modal - Now with enhanced scanning mode */}
      {showScannerModal && (
        <ModalOverlay>
          <ModalContent style={{ maxWidth: '95vw', width: '100%', maxHeight: '95vh', height: '100%' }}>
            <ModalHeader>
              Scan Pallet to Assign
              <ModalCloseButton onClick={handleCloseScanner}>
                ×
              </ModalCloseButton>
            </ModalHeader>
            <ModalBody style={{ padding: 0, flex: 1 }}>
              {/* Enable scanning mode when scanner component mounts */}
              <QRScanner 
                onScan={handleScanResult}
                onManualInputRequest={handleManualPalletInput}
                onClose={handleCloseScanner}
                onMount={() => {
                  // Completely disable SSE when scanner opens
                  kleinpakSSEManager.enableScanningMode();
                  console.log('KleinpakDashboard: Enabled scanning mode (QR Scanner)');
                }}
                onUnmount={() => {
                  // Re-enable SSE when scanner closes
                  kleinpakSSEManager.disableScanningMode();
                  console.log('KleinpakDashboard: Disabled scanning mode (QR Scanner)');
                }}
              />
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      )}
      
      {/* Conditional Zone/Row Workflow Modal based on navigation type */}
      {isRowBasedNavigation ? (
        <RowLocationWorkflow
          isOpen={showZoneWorkflow}
          onClose={() => setShowZoneWorkflow(false)}
          zoneName={selectedZone}
          rows={selectedOrder?.scheduled_rows || []}
          pallets={selectedZonePallets}
          order={selectedOrder}
          locationId={locationId}
          onScanComplete={onWorkflowScanComplete}
          selectedRow={selectedRow}
        />
      ) : (
        <PalletLocationWorkflow
          isOpen={showZoneWorkflow}
          onClose={() => setShowZoneWorkflow(false)}
          zoneName={selectedZone}
          pallets={selectedZonePallets}
          order={selectedOrder}
          locationId={locationId}
          onScanComplete={onWorkflowScanComplete}
        />
      )}
      
      {/* Mark Order Ready Modal */}
      <MarkOrderReadyModal
        isOpen={showMarkReadyModal}
        onClose={() => setShowMarkReadyModal(false)}
        onConfirm={onMarkReadyConfirm}
        order={orderToMarkReady}
        lastScannedPallet={lastScannedPallet}
        otherOrdersWithSamePallet={otherOrdersWithSamePallet}
      />
    </Container>
  );
};

export default KleinpakDashboard;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Filter, Search, Map, List, Check, X, AlertTriangle, AlertCircle } from 'lucide-react';
import { api } from '@lib/api';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.lg};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 18px;
  font-weight: 600;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const ModalBody = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const ModalFooter = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  margin-bottom: 16px;
`;

const Tab = styled.button`
  padding: 12px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.main : theme.colors.text.secondary};
  border-bottom: 2px solid ${({ isActive, theme }) => 
    isActive ? theme.colors.primary.main : 'transparent'};
  font-weight: ${({ isActive }) => isActive ? '600' : '400'};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 16px 10px 40px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.lighter};
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const PalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 16px;
`;

const PalletCard = styled.div`
  border: 1px solid ${({ isSelected, theme }) => 
    isSelected ? theme.colors.primary.main : theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: 16px;
  cursor: pointer;
  background-color: ${({ isSelected, theme }) => 
    isSelected ? theme.colors.primary.lighter : theme.colors.background.paper};
  transition: all 0.2s ease;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const PalletHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const PalletTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const PalletProperties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PalletProperty = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const PropertyLabel = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const PropertyValue = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 500;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background-color: ${theme.colors.primary.main};
          color: white;
          border: none;
          
          &:hover {
            background-color: ${theme.colors.primary.dark};
          }
          
          &:disabled {
            background-color: ${theme.colors.gray[300]};
            cursor: not-allowed;
          }
        `;
      case 'secondary':
        return `
          background-color: transparent;
          color: ${theme.colors.text.primary};
          border: 1px solid ${theme.colors.border.main};
          
          &:hover {
            background-color: ${theme.colors.background.hover};
          }
        `;
      default:
        return '';
    }
  }}
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  h4 {
    margin: 16px 0 8px;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  
  p {
    margin: 0;
    max-width: 400px;
    margin: 0 auto;
  }
`;

const ErrorState = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.error.lighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.error.main};
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectedCount = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.main};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

// Main Component
const PalletSelectionModal = ({ 
  isOpen, 
  onClose, 
  locationId, 
  initiallySelectedPalletIds = [],
  onPalletsSelected
}) => {
  const [activeTab, setActiveTab] = useState('storage');
  const [searchQuery, setSearchQuery] = useState('');
  const [pallets, setPallets] = useState([]);
  const [filteredPallets, setFilteredPallets] = useState([]);
  const [selectedPalletIds, setSelectedPalletIds] = useState(new Set(initiallySelectedPalletIds));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch pallets from API
  useEffect(() => {
    const fetchPallets = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Only get pallets without an order (available pallets)
        const response = await api.get('/api/pallets', {
          params: {
            locationId,
            available: true // Only fetch available pallets (not assigned to orders)
          }
        });
        
        const fetchedPallets = Array.isArray(response.data.data) 
          ? response.data.data 
          : (Array.isArray(response.data) ? response.data : []);
          
        // Additional client-side filtering to ensure shipped pallets are excluded
        const availablePallets = fetchedPallets.filter(pallet => 
          pallet.status !== 'shipped'
        );
        
        setPallets(availablePallets);
        setFilteredPallets(availablePallets);
      } catch (error) {
        console.error('Error fetching pallets:', error);
        setError('Failed to load pallets. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
    if (isOpen) {
      fetchPallets();
    }
  }, [isOpen, locationId]);
  
  // Filter pallets based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredPallets(pallets);
      return;
    }
    
    const query = searchQuery.toLowerCase();
    const filtered = pallets.filter(pallet => 
      pallet.id?.toString().includes(query) ||
      pallet.tomatoType?.toLowerCase().includes(query) ||
      pallet.palletType?.toLowerCase().includes(query) ||
      pallet.boxType?.toLowerCase().includes(query)
    );
    
    setFilteredPallets(filtered);
  }, [searchQuery, pallets]);
  
  // Toggle pallet selection
  const handlePalletSelect = (palletId) => {
    setSelectedPalletIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);
      
      if (newSelectedIds.has(palletId)) {
        newSelectedIds.delete(palletId);
      } else {
        newSelectedIds.add(palletId);
      }
      
      return newSelectedIds;
    });
  };
  
  // Select or deselect all filtered pallets
  const handleSelectAll = () => {
    // Check if all filtered pallets are already selected
    const allSelected = filteredPallets.every(pallet => 
      selectedPalletIds.has(pallet.id)
    );
    
    if (allSelected) {
      // Deselect all filtered pallets
      setSelectedPalletIds(prevSelectedIds => {
        const newSelectedIds = new Set(prevSelectedIds);
        filteredPallets.forEach(pallet => {
          newSelectedIds.delete(pallet.id);
        });
        return newSelectedIds;
      });
    } else {
      // Select all filtered pallets
      setSelectedPalletIds(prevSelectedIds => {
        const newSelectedIds = new Set(prevSelectedIds);
        filteredPallets.forEach(pallet => {
          newSelectedIds.add(pallet.id);
        });
        return newSelectedIds;
      });
    }
  };
  
  // Save selected pallets
  const handleSave = () => {
    onPalletsSelected(Array.from(selectedPalletIds));
    onClose();
  };
  
  if (!isOpen) return null;
  
  return (
    <ModalOverlay>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>Schedule Pallets for Order</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={24} />
          </CloseButton>
        </ModalHeader>
        
        <ModalBody>
          <Tabs>
            <Tab 
              isActive={activeTab === 'storage'} 
              onClick={() => setActiveTab('storage')}
            >
              <Map size={16} />
              Available Pallets
            </Tab>
            <Tab 
              isActive={activeTab === 'selected'} 
              onClick={() => setActiveTab('selected')}
            >
              <Check size={16} />
              Selected Pallets
              <span>({selectedPalletIds.size})</span>
            </Tab>
          </Tabs>
          
          <SearchContainer>
            <SearchIcon>
              <Search size={16} />
            </SearchIcon>
            <SearchInput 
              placeholder="Search pallets by ID, type..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </SearchContainer>
          
          {error && (
            <ErrorState>
              <AlertTriangle size={18} />
              {error}
            </ErrorState>
          )}
          
          {loading ? (
            <EmptyState>
              <div className="loading-spinner"></div>
              <h4>Loading pallets...</h4>
            </EmptyState>
          ) : (
            <>
              {activeTab === 'storage' && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                    <Button 
                      variant="secondary" 
                      onClick={handleSelectAll}
                    >
                      {filteredPallets.every(pallet => selectedPalletIds.has(pallet.id)) 
                        ? 'Deselect All' 
                        : 'Select All'}
                    </Button>
                    
                    {selectedPalletIds.size > 0 && (
                      <SelectedCount>
                        <Check size={16} />
                        {selectedPalletIds.size} pallets selected
                      </SelectedCount>
                    )}
                  </div>
                  
                  {filteredPallets.length === 0 ? (
                    <EmptyState>
                      <AlertCircle size={48} />
                      <h4>No pallets found</h4>
                      <p>
                        {searchQuery 
                          ? 'Try adjusting your search query.' 
                          : 'No available pallets found in this location.'}
                      </p>
                    </EmptyState>
                  ) : (
                    <PalletGrid>
                      {filteredPallets.map(pallet => (
                        <PalletCard 
                          key={pallet.id}
                          isSelected={selectedPalletIds.has(pallet.id)}
                          onClick={() => handlePalletSelect(pallet.id)}
                        >
                          <PalletHeader>
                            <PalletTitle>Pallet #{pallet.id.toString().substring(0, 8)}</PalletTitle>
                            {selectedPalletIds.has(pallet.id) && <Check size={18} color="#4CAF50" />}
                          </PalletHeader>
                          
                          <PalletProperties>
                            <PalletProperty>
                              <PropertyLabel>Tomato Type:</PropertyLabel>
                              <PropertyValue>{pallet.tomatoType || 'N/A'}</PropertyValue>
                            </PalletProperty>
                            <PalletProperty>
                              <PropertyLabel>Tomato Option:</PropertyLabel>
                              <PropertyValue>{pallet.tomatoOption || 'N/A'}</PropertyValue>
                            </PalletProperty>
                            <PalletProperty>
                              <PropertyLabel>Box Type:</PropertyLabel>
                              <PropertyValue>{pallet.boxType || 'N/A'}</PropertyValue>
                            </PalletProperty>
                            <PalletProperty>
                              <PropertyLabel>Pallet Type:</PropertyLabel>
                              <PropertyValue>{pallet.palletType || 'N/A'}</PropertyValue>
                            </PalletProperty>
                            <PalletProperty>
                              <PropertyLabel>Storage Zone:</PropertyLabel>
                              <PropertyValue>{pallet.storageZone || pallet.zone_id || 'Unassigned'}</PropertyValue>
                            </PalletProperty>
                            <PalletProperty>
                              <PropertyLabel>Status:</PropertyLabel>
                              <PropertyValue>{pallet.status || 'Available'}</PropertyValue>
                            </PalletProperty>
                          </PalletProperties>
                        </PalletCard>
                      ))}
                    </PalletGrid>
                  )}
                </>
              )}
              
              {activeTab === 'selected' && (
                <>
                  {selectedPalletIds.size === 0 ? (
                    <EmptyState>
                      <AlertCircle size={48} />
                      <h4>No pallets selected</h4>
                      <p>
                        Go to the Available Pallets tab to select pallets to schedule for this order.
                      </p>
                    </EmptyState>
                  ) : (
                    <PalletGrid>
                      {pallets
                        .filter(pallet => selectedPalletIds.has(pallet.id))
                        .map(pallet => (
                          <PalletCard 
                            key={pallet.id}
                            isSelected={true}
                            onClick={() => handlePalletSelect(pallet.id)}
                          >
                            <PalletHeader>
                              <PalletTitle>Pallet #{pallet.id.toString().substring(0, 8)}</PalletTitle>
                              <X size={18} color="#F44336" />
                            </PalletHeader>
                            
                            <PalletProperties>
                              <PalletProperty>
                                <PropertyLabel>Tomato Type:</PropertyLabel>
                                <PropertyValue>{pallet.tomatoType || 'N/A'}</PropertyValue>
                              </PalletProperty>
                              <PalletProperty>
                                <PropertyLabel>Tomato Option:</PropertyLabel>
                                <PropertyValue>{pallet.tomatoOption || 'N/A'}</PropertyValue>
                              </PalletProperty>
                              <PalletProperty>
                                <PropertyLabel>Box Type:</PropertyLabel>
                                <PropertyValue>{pallet.boxType || 'N/A'}</PropertyValue>
                              </PalletProperty>
                              <PalletProperty>
                                <PropertyLabel>Pallet Type:</PropertyLabel>
                                <PropertyValue>{pallet.palletType || 'N/A'}</PropertyValue>
                              </PalletProperty>
                              <PalletProperty>
                                <PropertyLabel>Storage Zone:</PropertyLabel>
                                <PropertyValue>{pallet.storageZone || pallet.zone_id || 'Unassigned'}</PropertyValue>
                              </PalletProperty>
                              <PalletProperty>
                                <PropertyLabel>Status:</PropertyLabel>
                                <PropertyValue>{pallet.status || 'Available'}</PropertyValue>
                              </PalletProperty>
                            </PalletProperties>
                          </PalletCard>
                        ))}
                    </PalletGrid>
                  )}
                </>
              )}
            </>
          )}
        </ModalBody>
        
        <ModalFooter>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading}
          >
            <Check size={16} />
            Schedule {selectedPalletIds.size} Pallets
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PalletSelectionModal;

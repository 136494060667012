import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '@styles/theme';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  border: none;
  border-radius: ${theme.borderRadius.md};
  font-size: ${theme.typography.body1.fontSize};
  font-weight: ${theme.typography.body1.fontWeight};
  cursor: pointer;
  transition: ${theme.transitions.default};
  background-color: ${props => 
    props.active 
      ? theme.colors.primary.main 
      : theme.colors.background.hover
  };
  color: ${props => 
    props.active 
      ? theme.colors.background.paper 
      : theme.colors.text.primary
  };
  box-shadow: ${props => 
    props.active 
      ? theme.shadows.sm 
      : 'none'
  };

  &:hover {
    background-color: ${props => 
      props.active 
        ? theme.colors.primary.dark 
        : theme.colors.gray[200]
    };
    transform: ${props => 
      props.active 
        ? 'translateY(-1px)' 
        : 'none'
    };
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${theme.colors.primary.light}40;
  }

  &:disabled {
    background-color: ${theme.colors.gray[200]};
    color: ${theme.colors.text.disabled};
    cursor: not-allowed;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const TabButton = ({ 
  active, 
  onClick, 
  children, 
  disabled = false,
  className = '' 
}) => {
  return (
    <StyledButton 
      active={active} 
      onClick={onClick}
      disabled={disabled}
      className={className}
      aria-pressed={active}
    >
      {children}
    </StyledButton>
  );
};

TabButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

TabButton.defaultProps = {
  active: false,
  disabled: false,
  className: ''
};

export default TabButton;

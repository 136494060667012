import React, { useState } from 'react';
import { Container, ButtonContainer, TopGrid, MiddleGrid, BottomGrid, Button, Divider, Typography } from './styles';
import { OperatorPalletModal } from '../OperatorPalletModal';

export const Selection = ({ selectedPallets, onPalletSelect, tomatoType, onToggleTomatoType }) => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const getPositionInfo = (position) => {
    const positions = getPositionsForTomatoType(tomatoType);
    return positions.find(pos => pos.label === position);
  };

  const handlePositionClick = (position) => {
    setSelectedPosition(position);
  };

  const handleModalConfirm = (data) => {
    onPalletSelect(data);
    setSelectedPosition(null);
  };

  const handleModalCancel = () => {
    setSelectedPosition(null);
  };

  const renderPositionButton = (position) => {
    const isSelected = !!selectedPallets[position.label];

    return (
      <Button
        key={position.label}
        onClick={() => handlePositionClick(position.label)}
        selected={isSelected}
      >
        {position.label}
      </Button>
    );
  };

  const positions = getPositionsForTomatoType(tomatoType);

  return (
    <Container>
      {selectedPosition && (
        <OperatorPalletModal
          position={selectedPosition}
          processStage={getPositionInfo(selectedPosition)?.processStage}
          tomatoType={tomatoType}
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
        />
      )}
      <ButtonContainer>
        <Button onClick={onToggleTomatoType}>
          {tomatoType}
        </Button>
      </ButtonContainer>

      <TopGrid>
        {positions.slice(0, 6).map(renderPositionButton)}
      </TopGrid>
      
      <MiddleGrid>
        {positions.slice(6, 8).map(renderPositionButton)}
      </MiddleGrid>

      <Divider />
      <Typography>Automaat</Typography>
      
      <BottomGrid>
        {positions.slice(8).map(renderPositionButton)}
      </BottomGrid>
    </Container>
  );
};

const getPositionsForTomatoType = (tomatoType) => {
  return tomatoType === 'Round' 
    ? [
        { label: 'Light A grof +', processStage: 'Light' },
        { label: 'Middle A grof +', processStage: 'Middle' },
        { label: 'Red A grof +', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'A grof Red', processStage: 'Red' },
        { label: 'A grof Middle', processStage: 'Middle' },
        { label: 'A grof Light', processStage: 'Light' },
      ]
    : [
        { label: 'Light 6 Pack', processStage: 'Light' },
        { label: 'Middle 6 Pack', processStage: 'Middle' },
        { label: 'Red 6 Pack', processStage: 'Red' },
        { label: 'Light B', processStage: 'Light' },
        { label: 'Middle B', processStage: 'Middle' },
        { label: 'Red B', processStage: 'Red' },
        { label: 'A fijn Light', processStage: 'Light' },
        { label: 'A fijn Middle', processStage: 'Middle' },
        { label: 'Red 5 Pack', processStage: 'Red' },
        { label: 'Middle 5 Pack', processStage: 'Middle' },
        { label: 'Light 5 Pack', processStage: 'Light' },
      ];
};

/**
 * Service for tracking and suggesting pallet storage assignments
 * Remembers operator storage patterns to streamline the workflow
 * 
 * Enhanced with server-side persistence for sharing across devices.
 * This service uses a hybrid approach:
 * - Patterns are stored in localStorage for performance and offline access
 * - Patterns are synced with the server for persistence across devices
 */

import { normalizePosition, createPosition, addLegacyProperties } from '@lib/utils/positionUtils';
import { executeWithCircuitBreaker } from '@lib/utils/apiErrorHandler';

// Local storage keys
const STORAGE_KEY = 'pallet_assignment_history';
const SYNC_METADATA_KEY = 'pallet_pattern_sync_metadata';

// Default API endpoints
const API_ENDPOINTS = {
  GET_PATTERNS: '/api/operator/patterns',
  SAVE_PATTERN: '/api/operator/patterns',
  GET_SYNC_METADATA: '/api/operator/sync',
  SYNC_PATTERNS: '/api/operator/sync'
};

// The history is stored per date and operator
class PalletAssignmentHistoryService {
  constructor() {
    // Whether server sync is enabled
    this.serverSyncEnabled = true;
    
    // Track sync state
    this.syncMetadata = this.loadSyncMetadata();
    
    // Last sync time
    this.lastSyncTime = this.syncMetadata.lastSyncTimestamp || 0;
    
    // Initialize the history from local storage
    this.history = this.loadFromStorage();
    
    // Queue for pending pattern saves (to avoid race conditions)
    this.saveQueue = [];
    
    // Flag to track if sync is in progress
    this.isSyncing = false;
    
    // Start background sync if enabled
    this.initBackgroundSync();
  }

  /**
   * Initialize background sync
   */
  initBackgroundSync() {
    if (this.serverSyncEnabled) {
      // Initial sync after a short delay to allow app to initialize
      setTimeout(() => this.syncWithServer(), 5000);
      
      // Then sync periodically (every 5 minutes)
      setInterval(() => this.syncWithServer(), 5 * 60 * 1000);
      
      // Also sync on window focus
      window.addEventListener('focus', () => this.syncWithServer());
    }
  }

  /**
   * Load assignment history from local storage
   */
  loadFromStorage() {
    try {
      const storedData = localStorage.getItem(STORAGE_KEY);
      return storedData ? JSON.parse(storedData) : {};
    } catch (error) {
      console.error('Error loading pallet assignment history:', error);
      return {};
    }
  }

  /**
   * Load sync metadata from local storage
   */
  loadSyncMetadata() {
    try {
      const storedData = localStorage.getItem(SYNC_METADATA_KEY);
      return storedData ? JSON.parse(storedData) : {
        lastSyncTimestamp: 0,
        version: 0
      };
    } catch (error) {
      console.error('Error loading sync metadata:', error);
      return {
        lastSyncTimestamp: 0,
        version: 0
      };
    }
  }

  /**
   * Update sync metadata
   * @param {Object} metadata - Sync metadata
   */
  updateSyncMetadata(metadata) {
    this.syncMetadata = {
      ...this.syncMetadata,
      ...metadata
    };
    
    try {
      localStorage.setItem(SYNC_METADATA_KEY, JSON.stringify(this.syncMetadata));
    } catch (error) {
      console.error('Error saving sync metadata:', error);
    }
    
    this.lastSyncTime = this.syncMetadata.lastSyncTimestamp || 0;
  }

  /**
   * Save assignment history to local storage
   */
  saveToStorage() {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.history));
    } catch (error) {
      console.error('Error saving pallet assignment history:', error);
    }
  }
  
  /**
   * Get API client for pattern operations
   * @returns {Object} API client
   */
  async getApiClient() {
    try {
      // Import API client dynamically
      return await import('@lib/api/axios').then(m => m.default);
    } catch (error) {
      console.error('Error loading API client:', error);
      throw error;
    }
  }

  /**
   * Generate a unique key for a pallet based on its characteristics
   * @param {Object} pallet - The pallet object
   * @returns {String} A unique key for this pallet type
   */
  generatePalletKey(pallet, simplifiedKey = false) {
    if (!pallet) return null;

    const {
      tomatoType,
      tomatoOption,
      sortingGrade,
      processStage,
      palletType,
      boxType,
      position
    } = pallet;

    // Ensure position is treated as a primary identifier
    if (!position) {
      console.warn('PalletAssignmentHistory: No position provided for pattern key generation');
      return null;
    }

    // Create a position-specific prefix that will be unique for each button in the finish menu
    const positionPrefix = `pos:${String(position).toLowerCase().trim().replace(/\s+/g, '_')}`;
    
    // For position-based matching, we only care about the position
    if (simplifiedKey) {
      return positionPrefix;
    }
    
    // For full matching, include all characteristics
    const characteristics = [
      tomatoType,
      tomatoOption,
      sortingGrade,
      processStage,
      palletType,
      boxType
    ];
    
    // Create a unique key that prioritizes position and characteristics
    const key = [
      positionPrefix,
      characteristics
        .filter(val => val)
        .map(val => String(val).toLowerCase().trim().replace(/\s+/g, '_'))
        .join(':')
    ].join('::');

    // Log detailed key generation for debugging
    console.log('Pattern key generation:', {
      input: {
        position,
        tomatoType,
        tomatoOption,
        sortingGrade,
        processStage,
        palletType,
        boxType
      },
      processing: {
        positionPrefix,
        normalizedPosition: String(position).toLowerCase().trim().replace(/\s+/g, '_'),
        characteristics: characteristics
          .filter(val => val)
          .map(val => String(val).toLowerCase().trim().replace(/\s+/g, '_'))
          .join(':'),
        mode: simplifiedKey ? 'simplified' : 'full'
      },
      result: key
    });

    return key;
  }

  /**
   * Get today's date in YYYY-MM-DD format
   * @returns {String} Today's date string
   */
  getTodayDateString() {
    return new Date().toISOString().split('T')[0];
  }

  /**
   * Get pattern record for a specific operator and day
   * @param {String} operatorId - The operator's ID
   * @param {String} dateString - Date string in YYYY-MM-DD format
   * @returns {Object} The operator's daily pattern record
   */
  getOperatorDailyPatterns(operatorId, dateString) {
    if (!operatorId) return null;

    // Use today's date if not specified
    const date = dateString || this.getTodayDateString();

    // Initialize the structure if it doesn't exist
    if (!this.history[date]) {
      this.history[date] = {};
    }
    
    if (!this.history[date][operatorId]) {
      this.history[date][operatorId] = {
        patterns: []
      };
    }

    return this.history[date][operatorId];
  }


  /**
   * Find a matching pattern for a given pallet
   * @param {Object} pallet - The pallet to match
   * @param {String} operatorId - The operator's ID
   * @returns {Object|null} The matching pattern or null if not found
   */
  findMatchingPattern(pallet, operatorId) {
    if (!pallet || !operatorId) return null;

    // Position must be present for position-specific matching
    if (!pallet.position) {
      console.warn('PalletAssignmentHistory: No position provided for pattern matching');
      return null;
    }

    // Normalize position for consistent matching
    const normalizedPosition = String(pallet.position).toLowerCase().trim();

    // Generate the position-specific key for matching
    const palletKey = this.generatePalletKey(pallet, false);
    const positionOnlyKey = this.generatePalletKey(pallet, true);
    if (!positionOnlyKey) return null;

    // Try to find in local patterns
    const todayPatterns = this.getOperatorDailyPatterns(operatorId);
    if (!todayPatterns) return null;

    // First filter patterns by position
    const positionPatterns = todayPatterns.patterns.filter(pattern => {
      // Extract position from pattern key
      const keyParts = pattern.key.split('::');
      const posPrefix = keyParts[0];
      // Check if the position prefix matches
      return posPrefix === `pos:${normalizedPosition.replace(/\s+/g, '_')}`;
    });

    console.log('Searching for position-specific pattern match:', {
      palletKey,
      position: pallet.position,
      normalizedPosition,
      availablePatterns: positionPatterns.length,
      totalPatterns: todayPatterns.patterns.length,
      patterns: positionPatterns.map(p => ({
        key: p.key,
        zoneId: p.zoneId,
        row: p.row,
        col: p.col
      }))
    });

    // First try to find a pattern with exact position and characteristics match
    const exactPattern = positionPatterns.find(pattern => pattern.key === palletKey);
    
    if (exactPattern) {
      console.log('Found exact pattern match:', {
        position: pallet.position,
        pattern: exactPattern
      });
      return exactPattern;
    }
    
    // If no exact match, find the most recent pattern for this position
    const mostRecentPattern = positionPatterns
      .sort((a, b) => new Date(b.lastUsed) - new Date(a.lastUsed))
      .find(pattern => pattern.key.startsWith(positionOnlyKey));
    
    if (mostRecentPattern) {
      console.log('Found position-based pattern match:', {
        position: pallet.position,
        pattern: mostRecentPattern
      });
      return mostRecentPattern;
    }
    

    console.log('No direct pattern match found, checking storage zone:', {
      position: pallet.position,
      storageZone: pallet.storageZone
    });
    
    // Check if this pallet has row storage information in storageZone field
    if (pallet.storageZone && pallet.storageZone.includes('-row-')) {
      // Extract zone ID and row index directly from the formatted string
      // Format: {zoneId}-row-{rowIndex}
      const match = pallet.storageZone.match(/^(.+)-row-(\d+)$/);
      
      if (match) {
        const zoneId = match[1];
        const rowIndex = parseInt(match[2], 10);
        
        // Create a pattern based on the extracted information
        const position = createPosition(rowIndex, 0); // Use column 0 as default
        return {
          key: palletKey,
          zoneId,
          ...position,
          ...addLegacyProperties(position),
          fromStorageZone: true
        };
      }
    }
    
    // If we have server-sync enabled and no pattern was found locally,
    // trigger a server sync to see if there are patterns from other devices
    if (this.serverSyncEnabled && !this.isSyncing) {
      // Don't wait for the sync, just trigger it in the background
      this.syncWithServer().catch(error => {
        console.warn('Background sync failed after pattern not found:', error);
      });
    }
    
    return null;
  }

  /**
   * Clear history older than the specified number of days
   * @param {Number} days - Number of days to keep
   */
  clearOldHistory(days = 7) {
    const today = new Date();
    
    // Get all date keys
    const dates = Object.keys(this.history);
    
    dates.forEach(dateString => {
      const date = new Date(dateString);
      const diffTime = today - date;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays > days) {
        delete this.history[dateString];
      }
    });
    
    this.saveToStorage();
  }

  /**
   * Clear all patterns to handle format changes
   * This should be called when the pattern format changes
   */
  clearAllPatterns() {
    console.log('Clearing all stored patterns due to format change');
    this.history = {};
    this.saveToStorage();
    
    // Also clear sync metadata to force a fresh sync
    this.updateSyncMetadata({
      lastSyncTimestamp: 0,
      version: 0
    });
  }
  
  /**
   * Clear patterns for a specific position
   * @param {String} position - Position to clear patterns for
   */
  clearPositionPatterns(position) {
    if (!position) {
      console.warn('No position specified for clearPositionPatterns');
      return;
    }
    
    const normalizedPosition = String(position).toLowerCase().trim();
    const positionPrefix = `pos:${normalizedPosition.replace(/\s+/g, '_')}`;
    
    console.log(`Clearing patterns for position: ${position} (prefix: ${positionPrefix})`);
    
    // Iterate through all dates and operators
    Object.keys(this.history).forEach(dateString => {
      Object.keys(this.history[dateString] || {}).forEach(operatorId => {
        const operatorData = this.history[dateString][operatorId];
        
        if (operatorData && operatorData.patterns) {
          // Filter out patterns for the specified position
          const beforeCount = operatorData.patterns.length;
          operatorData.patterns = operatorData.patterns.filter(pattern => {
            // Check if this pattern belongs to the specified position
            const keyParts = pattern.key.split('::');
            const patternPrefix = keyParts[0];
            return patternPrefix !== positionPrefix;
          });
          const afterCount = operatorData.patterns.length;
          
          console.log(`Cleared ${beforeCount - afterCount} patterns for position ${position} for operator ${operatorId} on ${dateString}`);
        }
      });
    });
    
    // Save updated history
    this.saveToStorage();
  }
  
  /**
   * Extract patterns from local history for a specific operator in a server-ready format
   * @param {String} operatorId - The operator's ID
   * @returns {Array} Array of patterns in a format ready for server sync
   */
  extractPatternsForSync(operatorId) {
    if (!operatorId) return [];
    
    const patterns = [];
    
    // Iterate through all dates
    Object.keys(this.history).forEach(dateString => {
      const operatorData = this.history[dateString][operatorId];
      
      if (operatorData && operatorData.patterns) {
        // Add all patterns for this operator on this date
        operatorData.patterns.forEach(pattern => {
          // Create storageZone from zoneId and row
          const storageZone = pattern.row !== undefined ? 
            `${pattern.zoneId}-row-${pattern.row}` : 
            pattern.zoneId;

          patterns.push({
            palletKey: pattern.key,
            storageZone,
            lastUsed: pattern.lastUsed || new Date().toISOString()
          });
        });
      }
    });
    
    return patterns;
  }
  
  /**
   * Save an assignment pattern to the server
   * @param {Object} pattern - The pattern to save
   * @param {String} operatorId - The operator's ID
   * @returns {Promise<Object>} Result of the save operation
   */
  async savePatternToServer(pattern, operatorId) {
    if (!this.serverSyncEnabled || !pattern || !operatorId) {
      return null;
    }
    
    try {
      const api = await this.getApiClient();
      
      // Create storageZone from zoneId and row
      const storageZone = pattern.row !== undefined ? 
        `${pattern.zoneId}-row-${pattern.row}` : 
        pattern.zoneId;
      
      // Use circuit breaker pattern for resilience
      const response = await executeWithCircuitBreaker(
        () => api.post(API_ENDPOINTS.SAVE_PATTERN, {
          palletKey: pattern.key,
          storageZone
        }),
        {
          method: 'POST',
          url: API_ENDPOINTS.SAVE_PATTERN,
          retrySettings: {
            maxRetries: 2,
            retryStatusCodes: [408, 429, 500, 502, 503, 504],
            retryDelay: attempt => Math.min(200 * Math.pow(2, attempt), 2000)
          },
          onError: (error) => {
            console.warn('Error saving pattern to server:', error);
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('Error saving pattern to server:', error);
      // Return null but don't throw, so we can continue with local operations
      return null;
    }
  }
  
  /**
   * Merge patterns from server with local patterns
   * @param {Array} serverPatterns - Patterns from server
   * @param {String} operatorId - The operator's ID
   */
  mergeServerPatterns(serverPatterns, operatorId) {
    if (!Array.isArray(serverPatterns) || !operatorId) return;
    
    // Get today's patterns container
    const todayPatterns = this.getOperatorDailyPatterns(operatorId);
    
    // Process each server pattern
    serverPatterns.forEach(serverPattern => {
      // Skip invalid patterns
      if (!serverPattern.palletKey || !serverPattern.storageZone) return;
      
      // Check if pattern already exists locally
      const existingIndex = todayPatterns.patterns.findIndex(
        p => p.key === serverPattern.palletKey
      );
      
      // Extract zoneId and row from storageZone
      let zoneId = serverPattern.storageZone;
      let row = undefined;
      
      const match = serverPattern.storageZone.match(/^(.+)-row-(\d+)$/);
      if (match) {
        zoneId = match[1];
        row = parseInt(match[2], 10);
      }
      
      // Create standardized pattern object
      const position = createPosition(
        row || 0,
        serverPattern.colIndex || 0
      );
      
      const mergedPattern = {
        key: serverPattern.palletKey,
        zoneId,
        storageZone: serverPattern.storageZone,
        ...position,
        lastUsed: serverPattern.lastUsed || new Date().toISOString(),
        fromServer: true
      };
      
      // If server pattern exists locally, update it only if server version is newer
      if (existingIndex >= 0) {
        const localPattern = todayPatterns.patterns[existingIndex];
        const localLastUsed = new Date(localPattern.lastUsed || 0).getTime();
        const serverLastUsed = new Date(serverPattern.lastUsed || 0).getTime();
        
        // If server pattern is newer, update local pattern
        if (serverLastUsed > localLastUsed) {
          todayPatterns.patterns[existingIndex] = mergedPattern;
        }
      } else {
        // Pattern doesn't exist locally, add it
        todayPatterns.patterns.push(mergedPattern);
      }
    });
    
    // Save merged patterns to local storage
    this.saveToStorage();
  }
  
  /**
   * Get server sync metadata
   * @returns {Promise<Object>} Sync metadata
   */
  async getServerSyncMetadata() {
    if (!this.serverSyncEnabled) return null;
    
    try {
      const api = await this.getApiClient();
      
      const response = await executeWithCircuitBreaker(
        () => api.get(API_ENDPOINTS.GET_SYNC_METADATA),
        {
          method: 'GET',
          url: API_ENDPOINTS.GET_SYNC_METADATA,
          retrySettings: {
            maxRetries: 1,
            retryStatusCodes: [408, 429, 500, 502, 503, 504]
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('Error getting sync metadata:', error);
      return null;
    }
  }
  
  /**
   * Synchronize patterns with the server
   * This is the main synchronization method that:
   * 1. Gets latest patterns from server
   * 2. Merges them with local patterns
   * 3. Sends local patterns to server
   * @returns {Promise<Object>} Sync result
   */
  async syncWithServer() {
    if (!this.serverSyncEnabled || this.isSyncing) {
      return null;
    }
    
    this.isSyncing = true;
    
    try {
      const api = await this.getApiClient();
      
      // Step 1: Get current user ID (from auth service)
      let userId;
      try {
        const authModule = await import('@lib/services/authService');
        const authService = authModule.default || authModule;
        userId = authService.getCurrentUser()?.username;
        
        if (!userId) {
          console.warn('Cannot sync patterns - user not logged in');
          this.isSyncing = false;
          return null;
        }
      } catch (error) {
        console.error('Error getting current user:', error);
        this.isSyncing = false;
        return null;
      }
      
      // Step 2: Get latest server sync metadata
      const serverMeta = await this.getServerSyncMetadata();
      
      if (!serverMeta) {
        this.isSyncing = false;
        return null;
      }
      
      // Step 3: Get latest patterns from server (only if server version is newer)
      if (serverMeta.version > this.syncMetadata.version) {
        const getResponse = await executeWithCircuitBreaker(
          () => api.get(`${API_ENDPOINTS.GET_PATTERNS}?since=${this.lastSyncTime}`),
          {
            method: 'GET',
            url: API_ENDPOINTS.GET_PATTERNS,
            retrySettings: {
              maxRetries: 2,
              retryStatusCodes: [408, 429, 500, 502, 503, 504]
            }
          }
        );
        
        if (getResponse.data && Array.isArray(getResponse.data)) {
          // Merge patterns from server with local patterns
          this.mergeServerPatterns(getResponse.data, userId);
          
          // Update sync metadata
          this.updateSyncMetadata({
            lastSyncTimestamp: Date.now(),
            version: serverMeta.version
          });
        }
      }
      
      // Step 4: Extract local patterns for sync
      const patternsToSync = this.extractPatternsForSync(userId);
      
      // Step 5: Send local patterns to server if we have any
      if (patternsToSync.length > 0) {
        const syncResponse = await executeWithCircuitBreaker(
          () => api.post(API_ENDPOINTS.SYNC_PATTERNS, {
            patterns: patternsToSync
          }),
          {
            method: 'POST',
            url: API_ENDPOINTS.SYNC_PATTERNS,
            payload: { patterns: patternsToSync },
            retrySettings: {
              maxRetries: 2,
              retryStatusCodes: [408, 429, 500, 502, 503, 504]
            }
          }
        );
        
        if (syncResponse.data) {
          // Update sync metadata
          this.updateSyncMetadata({
            lastSyncTimestamp: syncResponse.data.timestamp || Date.now(),
            version: serverMeta.version
          });
          
          console.log(`Synced ${syncResponse.data.synced} of ${patternsToSync.length} patterns with server`);
          
          this.isSyncing = false;
          return syncResponse.data;
        }
      }
      
      // Successful sync with no patterns to send
      this.isSyncing = false;
      return { success: true, synced: 0, total: 0 };
    } catch (error) {
      console.error('Error syncing with server:', error);
      this.isSyncing = false;
      return { success: false, error: error.message };
    }
  }
  
  /**
   * Override the original saveAssignmentPattern to also save to server
   * @param {Object} pallet - The pallet that was assigned
   * @param {String} zoneId - The zone ID where the pallet was assigned
   * @param {Number} row - The row within the zone
   * @param {Number} col - The column within the zone
   * @param {String} operatorId - The operator's ID
   * @returns {Object} The saved pattern
   */
  async saveAssignmentPattern(pallet, zoneId, row, col, operatorId) {
    if (!pallet || !zoneId || !operatorId) return null;

    // Save pattern with simplified key for consistent matching
    const palletKey = this.generatePalletKey(pallet, true);
    if (!palletKey) return null;

    const todayPatterns = this.getOperatorDailyPatterns(operatorId);
    if (!todayPatterns) return null;

    // Check if a pattern already exists
    const existingPatternIndex = todayPatterns.patterns.findIndex(
      pattern => pattern.key === palletKey
    );

    // Ensure position is normalized and stored
    const normalizedPosition = pallet.position ? String(pallet.position).toLowerCase().trim() : '';
    
    // Create standardized position and add legacy properties for backward compatibility
    const position = createPosition(row || 0, col || 0);
    // Create storageZone from zoneId and row
    const storageZone = row !== undefined ? 
      `${zoneId}-row-${row}` : 
      zoneId;

    const newPattern = {
      key: palletKey,
      zoneId,
      storageZone,
      originalPosition: normalizedPosition, // Store position explicitly
      positionLabel: pallet.position, // Store original position label
      ...position, // Use standardized position properties (row, col)
      ...addLegacyProperties(position), // Add legacy properties (column, rowIndex, colIndex) for backward compatibility
      lastUsed: new Date().toISOString()
    };

    // Update existing or add new pattern
    if (existingPatternIndex >= 0) {
      todayPatterns.patterns[existingPatternIndex] = newPattern;
    } else {
      todayPatterns.patterns.push(newPattern);
    }

    // Save to local storage
    this.saveToStorage();
    
    // Save to server in the background (don't block UI)
    if (this.serverSyncEnabled) {
      this.savePatternToServer(newPattern, operatorId).catch(error => {
        console.warn('Background pattern save to server failed:', error);
      });
    }

    return newPattern;
  }
}

// Create a singleton instance
const palletAssignmentHistoryService = new PalletAssignmentHistoryService();

export default palletAssignmentHistoryService;

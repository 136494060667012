import React, { useState } from 'react';
import { Calendar, Box, Package, Palette, AlertCircle, Truck, Edit, X, User, CheckCircle, Layers, ChevronDown, Info } from 'lucide-react';
import EnhancedProgressBar from './EnhancedProgressBar';

// Import styled components from styles.js
import {
  Card,
  CardContent,
  Header,
  InfoRow,
  StatusSection,
  DetailsSection,
  Details,
  Badge,
  PriorityBadge,
  ProcessingBadge,
  OperatorBadge,
  MetricsRow,
  PalletsSection,
  ScheduledPallets,
  PalletItem,
  Actions,
  ActionBtn,
  ExpandButton,
  ExpandableSection
} from './styles';

/**
 * OrderCard Component
 * 
 * Redesigned with a compact layout and collapsible sections for a more
 * professional and efficient appearance.
 */
const OrderCard = ({
  order,
  onUpdatePriority,
  onShip,
  onEdit,
  onDelete
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  
  // Calculate completion percentage for progress visualization
  const completionPercentage = order.colli_completion || 0;
  
  // Count processing pallets for better status indication
  const processingPalletCount = order.scheduled_pallets ? 
    order.scheduled_pallets.filter(p => p && p.processStage === 'Processing').length : 0;

  // Determine order stage
  const getOrderStage = () => {
    if (order.is_completed) return 'shipped';
    if (order.ready_for_shipping) return 'ready_for_shipping';
    return 'unprocessed';
  };

  // Get stage display text
  const getStageText = (stage) => {
    switch (stage) {
      case 'shipped':
        return 'Shipped';
      case 'ready_for_shipping':
        return 'Ready';
      default:
        return 'Unprocessed';
    }
  };
  
  const orderStage = getOrderStage();
  
  return (
    <Card 
      priority={order.priority || "medium"} 
      completed={order.is_completed}
      is_processing={order.is_processing === 1}
      readyForShipping={order.ready_for_shipping}
      isExpanded={isExpanded}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <CardContent>
        {/* Header with improved customer info */}
        <Header isExpanded={isExpanded}>
          <h4>
            {order.customer_name}
            {order.isKleinpakOrder && (
              <Badge 
                compact
                style={{ 
                  backgroundColor: '#1976d2', 
                  color: 'white',
                  fontSize: '0.65rem',
                  fontWeight: 'bold',
                  marginLeft: '4px'
                }}
              >
                KP
              </Badge>
            )}
          </h4>
        </Header>
        
        {/* Enhanced Progress Bar - 75% of width as requested */}
        <EnhancedProgressBar 
          percentage={completionPercentage}
          width="75%"
          isProcessing={order.is_processing === 1}
          rightLabel={`${Math.round(completionPercentage)}%`}
        />
        
        {/* Essential Info Row - Always Visible */}
        <InfoRow>
          {/* Due date with edit functionality */}
          <div 
            style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '4px',
              cursor: 'pointer' 
            }}
            onClick={() => {
              if (!order.is_completed) {
                // Create date picker input
                const datePicker = document.createElement('input');
                datePicker.type = 'date';
                datePicker.value = order.due_date || '';
                datePicker.style.position = 'absolute';
                datePicker.style.opacity = '0';
                
                // Handle date selection
                datePicker.onchange = (e) => {
                  if (e.target.value) {
                    onEdit({
                      ...order,
                      due_date: e.target.value
                    });
                  }
                  document.body.removeChild(datePicker);
                };
                
                // Handle cancellation
                datePicker.onblur = () => {
                  document.body.removeChild(datePicker);
                };
                
                document.body.appendChild(datePicker);
                datePicker.click();
              }
            }}
            title={order.is_completed ? "Cannot edit due date for completed orders" : "Click to edit due date"}
          >
            <Calendar size={14} />
            <span style={{ 
              fontSize: '0.8rem', 
              color: 'var(--text-secondary)',
              textDecoration: !order.is_completed ? 'underline dotted' : 'none'
            }}>
              {order.due_date || "Not set"}
            </span>
          </div>
          
          {/* Order status indicators */}
          <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            <PriorityBadge 
              compact
              priority={order.priority || "medium"}
              processStage={orderStage}
              isExpanded={isExpanded}
              onClick={() => !order.is_completed && onUpdatePriority(order.id, order.priority === 'urgent' ? 'normal' : 'urgent')}
            >
              {getStageText(orderStage)}
            </PriorityBadge>
            
            {order.is_processing === 1 && (
              <ProcessingBadge compact>
                <AlertCircle size={12} />
              </ProcessingBadge>
            )}
            
            {/* Progress percentage shown as text */}
            <span style={{ 
              fontSize: '0.75rem', 
              fontWeight: 'bold', 
              color: `${completionPercentage === 100 ? '#4caf50' : completionPercentage > 0 ? '#2196f3' : '#757575'}`
            }}>
              {completionPercentage}%
            </span>
          </div>
        </InfoRow>
        
        {/* Operator Assignment - Collapsible */}
        <StatusSection isExpanded={isExpanded}>
          {order.operator_name && (
            <OperatorBadge>
              <User size={12} />
              {order.operator_name}
            </OperatorBadge>
          )}
          
          {order.is_completed && order.completed_by && (
            <OperatorBadge>
              <CheckCircle size={12} />
              {order.completed_by}
            </OperatorBadge>
          )}
        </StatusSection>
        
        {/* Order Details - Collapsible */}
        <DetailsSection isExpanded={isExpanded}>
          <Details>
            {/* Expand/Collapse Toggle for Additional Details */}
            <ExpandButton 
              onClick={() => setDetailsExpanded(!detailsExpanded)}
              expanded={detailsExpanded}
            >
              {detailsExpanded ? 'Less' : 'More'}
              <ChevronDown size={12} />
            </ExpandButton>
            
            {/* Expandable Additional Details */}
            <ExpandableSection expanded={detailsExpanded}>
              {order.palletType && (
                <p>
                  <Palette size={14} />
                  <strong>Pallet Type:</strong> {order.palletType}
                </p>
              )}
              
              {order.isKleinpakOrder && order.processingLine && (
                <p>
                  <AlertCircle size={14} />
                  <strong>Processing Line:</strong> {order.processingLine}
                </p>
              )}
              
              {order.notes && (
                <p>
                  <Info size={14} />
                  <strong>Notes:</strong> {order.notes}
                </p>
              )}
            </ExpandableSection>
          </Details>
        </DetailsSection>
        
        {/* Metrics - Collapsible */}
        <MetricsRow isExpanded={isExpanded}>
          <div>
            <span>Order Size:</span>
            <span>{order.total_pallets} pallets</span>
          </div>
          <div>
            <span>Input:</span>
            <span>{order.assigned_pallets || 0} pallets</span>
          </div>
          <div>
            <span>Output:</span>
            <span>{order.completed_colli || 0} / {order.output_colli || 0}</span>
          </div>
          {order.extra_boxes !== null && (
            <div>
              <span>Extra:</span>
              <span>{order.extra_boxes} boxes</span>
            </div>
          )}
        </MetricsRow>
        
        {/* Scheduled Pallets - Collapsible */}
        {Array.isArray(order.scheduled_pallets) && order.scheduled_pallets.length > 0 && (
          <PalletsSection isExpanded={isExpanded}>
            <h4>
              <Layers size={12} />
              Pallets ({order.scheduled_pallets.length})
            </h4>
            <ScheduledPallets>
              {order.scheduled_pallets.map((pallet, index) => (
                pallet && (
                  <PalletItem 
                    key={pallet.id || index} 
                    processStage={pallet.processStage}
                  >
                    <span>#{pallet.id}</span>
                  </PalletItem>
                )
              ))}
            </ScheduledPallets>
          </PalletsSection>
        )}
        
        {/* Action Buttons - Always Visible */}
        {!order.is_completed && (
          <Actions>
            <ActionBtn
              variant="ship"
              onClick={() => onShip(order.id)}
              title="Ship Order"
            >
              <Truck size={16} />
            </ActionBtn>
            <ActionBtn
              variant="edit"
              onClick={() => onEdit(order)}
              title="Edit Order"
            >
              <Edit size={16} />
            </ActionBtn>
            <ActionBtn
              variant="delete"
              onClick={() => onDelete(order.id)}
              title="Delete Order"
            >
              <X size={16} />
            </ActionBtn>
          </Actions>
        )}
      </CardContent>
    </Card>
  );
};

export default OrderCard;
